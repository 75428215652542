var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "border bg-light mt-13 fg-black w-100-sm w-33-xl w-50-lg w-50-md w-100-sm",
      attrs: {
        id: "fileSystemSidebar",
        "data-role": "charms",
        "data-position": "right",
      },
    },
    [
      _c("div", { staticClass: "m-4" }, [
        _vm.loading
          ? _c("span", { staticClass: "mif-spinner2 fg-primary ani-spin mx-2" })
          : _vm._e(),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tree != null,
                expression: "tree != null",
              },
            ],
            staticClass: "caption text-bold mt-4",
          },
          [
            _vm._v(
              _vm._s(
                _vm.config.multipleChoice
                  ? _vm.$t("Select one or more items")
                  : _vm.$t("Select a path")
              )
            ),
          ]
        ),
        _c(
          "button",
          {
            staticClass: "button primary float-right",
            attrs: { title: _vm.$t("Create new folder") },
            on: {
              click: function ($event) {
                return _vm.openCreateNewFolder(_vm.clickedNode)
              },
            },
          },
          [_c("span", { staticClass: "mif-folder-plus" })]
        ),
      ]),
      _vm.tree != null
        ? _c("div", { staticClass: "bd-default no-border-top" }, [
            _c("div", { staticStyle: { overflow: "scroll", height: "80vh" } }, [
              _c("div", { staticClass: "pt-2" }, [
                _c("div", [
                  _c(
                    "ul",
                    {
                      staticStyle: { "font-size": "small" },
                      attrs: { id: "treeview", "data-role": "treeview" },
                    },
                    [
                      _c(
                        "li",
                        {
                          staticClass: "tree-node",
                          attrs: {
                            id: "node",
                            path: _vm.tree.path,
                            "data-collapsed": _vm.tree && !_vm.tree.visible,
                          },
                        },
                        [
                          _c("input", {
                            attrs: {
                              disabled: "",
                              "data-style": "1",
                              type: "checkbox",
                              "data-role": "checkbox",
                              id: "checkbox-node",
                            },
                          }),
                          _c("label", [
                            _vm.config.type.toString().startsWith("1")
                              ? _c("span", {
                                  staticClass: "mif-cloud mx-2 mif-2x",
                                })
                              : _vm.config.type ==
                                _vm.$PlatformsTypes.FileFolder
                              ? _c("span", {
                                  staticClass: "mif-laptop mif-2x mx-2",
                                })
                              : _vm.config.type == _vm.$PlatformsTypes.Network
                              ? _c("span", {
                                  staticClass: "mif-earth2 mif-2x mx-1",
                                })
                              : _vm.config.type == _vm.$PlatformsTypes.FTP
                              ? _c("span", {
                                  staticClass: "mif-folder-open mif-2x mx-2",
                                })
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                staticClass: "mx-1",
                                staticStyle: { "line-height": "18px" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.config.rootPath != null &&
                                      _vm.config.rootPath != ""
                                      ? _vm.tree.path
                                      : _vm.config.type === 8
                                      ? _vm.$session.get("ROOMPC").name
                                      : _vm.$platformsTypesInfo[_vm.config.type]
                                          .name
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "row d-flex flex-justify-end p-5",
          staticStyle: { position: "absolute", bottom: "0", right: "0" },
        },
        [
          _c(
            "button",
            {
              staticClass: "button primary",
              on: { click: _vm.closeFileSystemSidebar },
            },
            [_vm._v(_vm._s(_vm.$t("Close")))]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }