<template>
    <div class="w-100 overflow">
        <Navigation pageName="users" />

        
         <div class="row m-5 pt-5" v-if="users == null" >
            <div class="h-100 w-100 z-2">
                    <span class="ani-spin fg-primary mif-5x mif-spinner2 pos-center"></span>
            </div>
        </div>

        <NoDataAvailable v-else-if="users.length == 0" :message="$t('No users available')" />

        <div v-else class="p-5">

            <table class="table border striped  bg-white m-0 compact" :data-check=false data-check-name="chkBox"
                :data-table-search-title='$t("Search Users")' data-cls-search="w-100-sm w-25-xl w-25-lg w-50-md"
                data-show-activity="true" data-cell-wrapper="false" data-role="table" data-rows="20"
                data-show-rows-steps="false"  :data-show-pagination="users.length > 20 ? 'true' : 'false'" data-horizontal-scroll="true">
                <thead>
                    <tr>

                        <th class="sortable-column">{{$t("Username")}}</th>
                        <th class="sortable-column">{{$t("Role")}}</th>
                        <th class="sortable-column">{{$t("Firstname")}}</th>
                        <th class="sortable-column">{{$t("Lastname")}}</th>
                        <th class="text-center" data-cls="text-center" data-cls-column="text-center">{{$t("Groups")}} </th>
                        <th class="text-center" data-cls-column="text-center w-10">{{$t("2FA")}}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>

                    <tr v-for="(user) in users" class="mx-8 p-4  rounded m-6" v-bind:key="user.id" >
                        <td>
                            <a :href="'#'+user.id" class="button text-bold text-left m-0" :class="{ 'flat-button': $root.checkAuth().id_user != user.id }" > 
                                <span >{{user.username}}</span>
                                <span v-if="$root.checkAuth().id_user == user.id " class="badge bg-primary fg-white text-normal">{{$t("Logged")}}</span>
                            </a>
                        </td>
                        <td class="">
                            <label class="text-left m-0"><span class="badge inline bg-cobalt fg-white p-1  mr-1">{{user.role.toUpperCase()}}</span></label>
                        </td>
                        <td>
                            {{user.firstname}}
                        </td>
                        <td>
                            {{user.lastname}}
                        </td>
                        <td class="text-center text-bold">
                            <!-- <button class="flat-button" data-role="hint"
                                :data-hint-text="$root.createhintGroups(user.groups)">{{ user.groups.length}}
                            </button> -->
                              <router-link class="button fg-dark mr-2 small" v-for="group in user.groups" :to="'groups#' + group.id" :key="group.id">{{ group.name }}</router-link>
                        </td>
                        <td class=" text-center">
                            <span class=" text-center mif-2x " :class="user.mfa_enabled?'mif-security':''"></span>
                        </td>
                        <td>
                            <ul class="listview flex-justify-end d-flex ">
                                <router-link :to="'#'+user.id" class="">
                                    <li class="button flat-button">
                                        <span class="mif-pencil"></span>
                                    </li>
                                </router-link>

                                <router-link :disabled="$root.checkAuth().id_user==user.id || user.level=='2'" :to="($root.checkAuth().id_user == user.id || user.level == '2')?'#':'#' + user.id + '/delete'">
                                    <li class="button flat-button"> 
                                        <span class="mif-bin "></span>
                                    </li>
                                </router-link>
                            </ul>

                        </td>



                    </tr>
                </tbody>
            </table>

        </div>
    </div>
</template>
<script>
import Navigation from './utils/Navigation.vue';
import NoDataAvailable from "./utils/NoDataAvailable.vue";
export default {
    name: "Users",
    props: {
        idGroup: String
    },
    components: {
        Navigation,
         NoDataAvailable,
    },
    watch: { 
        '$route': {
            handler: function(to) {
                if (to.hash && to.hash.endsWith("/delete")) {
                    var iduser = to.hash.split("/")[0].replace("#", "");
                    var user = this.users.find((user) => user.id == iduser)
                    this.deleteUser(user.id, user.username);
                    return;
                }
                if(to.hash!=""){
                    var iduser = to.hash.replace("#","");
                    this.openDialogUser(iduser)
                }
            },
            deep: true,
            immediate: true
        }
    },
    data() { return { 
        users: null,
        checkTFA: {
                step1: null,
                step2: null
            },
        treeViewHtml: "",
        filteredusers:[],
     } },
    computed:{
         userEmail() {
           return this.$root.checkAuth().email;
        },
    },
    mounted: async function () {
        this.$session.setRoomPC( null);
        this.$root.socket("SETPC", null);  
        let self = this;
        if(this.$root.checkAuth()){
            self.users = await self.$api.getUsers();
            self.$session.setUsers(self.users);
        }
        this.$root.$on("refreshUsers", async () => {
            self.users = await self.$api.getUsers();
            self.$session.setUsers(self.users);
            /* self.$router.replace({ path: '/users' }).catch((err) => {
                // Ignora l'errore NavigationDuplicated
                if (err.name !== 'NavigationDuplicated') {
                    console.error(err);
                }
            }); */
            window.location.reload();
        });
    },

   
    methods:{
        async openDialogUser(id_user) {

            this.selecteduser = await this.$api.getUser(id_user);
             this.$root.$emit("OPENUSERVIEWDIALOG", this.selecteduser, this.checkTFA);
          
        },

        async deleteUser(id_user) {
            this.selecteduser = await this.$api.getUser(id_user);
            //Personalizzo la dialog da mostrare
            var dialogID = Math.floor(100000 + Math.random() * 900000).toString();
            var dialogTitle = this.$t("Delete User");
            var dialogContent = this.$t('deleteConfirmation', { 0: this.selecteduser.username });

            let self = this;
            this.$root.$on(dialogID, async ok => {
                if (ok) {

                    var ok = await self.$api.deleteUser(this.selecteduser.id, this.selecteduser.username);
                    if (ok) {
                        this.$root.$emit("refreshUsers");  
                    }
                }
                
                self.$root.$off(dialogID);

            });

            //Avvio la richiesta che mi risponderà con un $emit
            this.$root.openYesNoDialog(dialogID, dialogTitle, dialogContent)
        },
      
        

    }
}
</script>
