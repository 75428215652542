<template>
    <div class="w-100 overflow">
        <Navigation pageName="customclient" />
        <div v-if="iframeLoaded" class="d-flex flex-justify-center  p-5">
            <span class="mif-spinner2 fg-primary ani-spin mif-3x " />
        </div>

        <!--NoDataAvailable v-else-if="groups.length == 0" :message="$t('No groups available')" /-->

        <div class="row p-4">
                <div class="col col-12">
                    
                        <iframe allowfullscreen sandbox="allow-popups allow-top-navigation allow-same-origin allow-scripts allow-modals allow-forms allow-downloads"
                            :src="parentIframeSrc" width="100%" loading="lazy" v-show="!iframeLoaded" @load="onIframeLoad" style="height: calc(100vh - 190px);overflow:auto;border: none; display: block;"></iframe>
                    
                </div>
        </div>

    </div>

</template>
<script>

import Navigation from './utils/Navigation.vue';
import NoDataAvailable from "./utils/NoDataAvailable.vue";
export default {
    name: "CustomClient",
    components: {
        Navigation,
        NoDataAvailable
    },
    data() {
        return {
            urlircustombuild: process.env.VUE_APP_IR_BUILD_BASE_URL,
            parentIframeSrc: '',
            iframeLoaded: true
        }
    },
    mounted: function() {
        this.$session.setRoomPC(null);
        this.$root.socket("SETPC", null);


    },
    created: function() {
        this.getAgentIframeUrl();
    },
    methods: {

        onIframeLoad() {
            this.iframeLoaded=false; // Cambia lo stato quando il contenuto è caricato
        },

        formatDate(date) {
            return formatDate(date, this.$moment);
        },

        // [TG - 18/02/2025 - generazione link apertura iframe build iperius remote custom]
        async getAgentIframeUrl() {
            let self=this;

            // libreria crypto nativa di node.js
            const crypto=require('crypto');

            if (self.$root.checkAuth()==null) {
                return;
            }

            let ima=self.$root.checkAuth().id_master_account;

            let cryLic=await self.$api.getCoCod(ima);

            if (cryLic!=null) {


                const currentDate=new Date();
                const formattedDate=this.$moment(currentDate).format('YYYYMMDD');

                const ragione_sociale=self.$root.checkAuth().company;
                const ma_username=self.$root.checkAuth().ma_username;
                const id_utente_ecomm=self.$root.checkAuth().id_utente_ecomm;
                const vb_lang=localStorage.getItem('lang')??$i18n.locale??"en";

                const control_code_sha256=crypto.createHash('md5').update(id_utente_ecomm+'$$'+formattedDate).digest('hex');

                const ma_username_md5_sha256=crypto.createHash('md5').update(ma_username).digest('hex');

                const query_string=id_utente_ecomm+'|'+ragione_sociale+'||'+formattedDate+'||'+vb_lang+'|'+control_code_sha256+'|'+ma_username_md5_sha256;

                const param=await self.$api.getCrio(query_string);

                const trustedSrc=self.urlircustombuild+'?pl='+encodeURIComponent(param.result);

                self.parentIframeSrc=trustedSrc;

            }

        },

        // [TG - 18/02/2025 - gestione finiestra generazione custom version di iperius remote]
        updateParentIframeSrc(newIframeSrc) {
            this.parentIframeSrc=newIframeSrc;
        },

    }
}
</script>
