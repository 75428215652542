var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.friendlyObj
    ? _c(
        "div",
        _vm._l(Object.keys(_vm.friendlyObj), function (prop) {
          return _c("div", { staticClass: "row" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t(prop)) + " :")]),
            _vm._v(" "),
            _c("span", { staticClass: "text-bold pl-2" }, [
              _vm._v(_vm._s(_vm.friendlyObj[prop])),
            ]),
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }