<template>

  <!---------------------------------------PANNELLO PRINCIPALE---------------------------------------------------->
                <div id="panel" data-role="panel"  :data-title-caption="$t(title)"
                    data-title-icon="<span class='mif-chart-bars'></span>" class="m-6" data-collapsible="true" data-collapsed="true">
                    <div v-if="loading" class="h-100 m-0 op-dark-hi p-0 pos-absolute pos-center w-100 z-2">
                        <span class="ani-spin fg-white mif-5x mif-spinner2 mt-13-minus pos-center"></span>
                    </div>
                    <div class="overflow-sm p-6">
                        

                        
                
                        <div class="d-flex flex-justify-end pb-6 pr-6">
                            <select class="bg-graywhite pl-2 select text-bold text-small" v-model="filters.id_group">
                                <option value="">{{ $t("All Groups") }}</option>
                                <option v-for="group in groups" :value="group.id_group" v-bind:key="group.id_group">{{ group.name_group }}
                                </option>
                            </select>
                            <select class="bg-graywhite pl-2 select text-bold text-small" v-model="filters.id_user">
                                <option value="">{{ $t("All Users") }}</option>
                                <option v-for="user in users" :value="user.id" v-bind:key="user.id">{{ user.username  }}  </option>   
                            </select>
                                    
                            <input type="date" v-model="filters.dt_inf" :max="Date.now()| moment('YYYY-MM-DD')" class="cell-2 date select bg-graywhite p-4 text-bold text-small text-center"  >
                            <div class="cell-auto"></div>
                            <input type="date" v-model="filters.dt_sup" :max="Date.now()| moment('YYYY-MM-DD')" class="cell-2 date select bg-graywhite p-4 text-bold text-small text-center"  >
                            <div class="cell-auto"></div>
                            <input type="button" @click="getStats" :value="$t('Search')" class="button"  >
                        </div>       
                        
                        
                        
                        <div class="d-flex flex-wrap pt-4">
                            <div :class="item"  v-for="(item, index) in graph" :id="index"></div>

                            
                        </div>
                    </div>
                </div>

</template>
<script>
import { mConvertToMB } from '../../utils/utilitiesmodule';
 import ApexCharts from 'apexcharts'
  export default {
     name:"StatsCollector",
     props: {
      
      
        title :{
            type : String,
            required:false
        },
       
    },
    data() {
      return {
        graph:null,
        size_graph:null,
        size: 200,
        loading:false,
        sessions:null,
        fullStats:null,
        groups:this.$session.getGroups(),
        users:this.$session.getUsers(),
        filters: {
                numrows: 25,
                offset: 0,
                lastresult: '',
                dt_inf:  this.$moment().subtract(5, 'days').format('YYYY-MM-DD'),
                dt_sup:  this.$moment().format('YYYY-MM-DD'),
                id_group: '',
                id_host: '',
                id_user: '',

            },
      }
    },
    components: {

    },
    created() {
        this.$session.setRoomPC( null);
        this.$root.socket("SETPC", null);  
        this.graph = {
            'data-backup-chart':'cell-12',
            'session-per-date-chart':'cell-6',
            'session-per-user-chart':'cell-6',
            'session-per-user-cake':'cell-3'
        };
    
        this.getStats();
      
    },

    methods:{

            async getStats() {
                let self = this;
                self.loading = true;
                self.fullStats = await this.$api.getStatsFromLicense(self.filters.dt_inf,self.filters.dt_sup);
                self.sessions =  await this.$api.getSessions(self.filters);
                self.loading = false;
                if (self.sessions.length != 0)  self.statsPerDateChart(self.sessions.stats_per_date);
                if (self.sessions.length != 0)  self.statsPerUserChart(self.sessions.stats_per_user);
                if (self.sessions.length != 0)  self.statsPerUserCake(self.sessions.stats_per_user);
                if (self.fullStats.dataStats.length != 0)  self.dataBackupChart(self.fullStats.dataStats);
                //if (self.fullStats!=null && self.fullStats.nOfServers + self.fullStats.nOfWorkstations != 0)  self.computerTypeChart();
                //if (self.fullStats.nPcDanger + self.fullStats.nPcOk + self.fullStats.nPcWarning != 0)  self.computerHealtChart();
            },

            statsPerUserCake(dataValues) {
                let self = this;

                var options = {
                    series:   Object.keys(dataValues).map((x)=>Math.round(dataValues[x].total_time / 60)),
                    chart: {
                        toolbar: {
                            show: true,
                        },
                        id: 'session-per-user-cake',
                        height:350,
                        type: 'pie',
                    },
                   legend: {
                        position: 'bottom'
                    },
                    labels: Object.keys(dataValues).map((x)=>x.split("|")[1]),
                    responsive: [{
                        breakpoint: 280,
                        options: {
                            chart: {
                                width: 400,
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                };

                $("#session-per-user-cake").html('');
                var chart = new ApexCharts(document.querySelector("#session-per-user-cake"), options);
                chart.render();
            },
           


            computerTypeChart() {
                let self = this;

                var options = {
                    series: [self.fullStats.nOfWorkstations, self.fullStats.nOfServers],
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        id: 'computer-type-cake',
                        height:300,
                        type: 'pie',
                    },
                   legend: {
                        position: 'bottom'
                    },
                    labels: ['Workstation', 'Server'],
                    responsive: [{
                        breakpoint: 280,
                        options: {
                            chart: {
                                width: 400,
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                };

                $("#computer-type-cake").html('');
                var chart = new ApexCharts(document.querySelector("#computer-type-cake"), options);
                chart.render();
            },

            dataBackupChart(dataValues) {
                
               //dataValues.map((x)=>mConvertToMB(parseInt(x.size_sum))
                var options = {
                series: [{
                name: 'Size Data',
                data: dataValues.map((x)=>mConvertToMB(parseInt(x.size_sum)))
                },
                {
                name: 'Data',
                data: dataValues.map((x)=>parseInt(x.files_sum))
                },
                {
                name: 'Failed Data',
                data: dataValues.map((x)=>parseInt(x.failed_sum))
                }],
                chart: {
                     toolbar: {
                            show: false,
                        },
                type: 'bar',
                height: 350
                },
                plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '50%',
                    endingShape: 'rounded'
                },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: dataValues.map((x)=>this.$moment(x.data).format("DD/MM/YY")),
                },
                yaxis: {
                title: {
                    text: 'Files'
                }
                },
                fill: {
                opacity: 1
                },
                tooltip: {
                y: {
                    formatter: function (val) {
                    return  val 
                    }
                }
                }
            };

      


            $('#data-backup-chart').html('');
            var chart = new ApexCharts(document.querySelector("#data-backup-chart"), options);
            chart.render();




            },

            statsPerDateChart(dataValues) {
                
                var options = {
                series: [
                {
                name: 'Num. Sessions',
                data: Object.keys(dataValues).map((x)=>dataValues[x].count)
                },
                {
                name: 'Total time (min)',
                data: Object.keys(dataValues).map((x)=> Math.round(dataValues[x].total_time / 60))
                }],
                chart: {
                     toolbar: {
                            show: false,
                        },
                type: 'bar',
             
                    height: 350
                },
                plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '50%',
                    endingShape: 'rounded'
                },
                },
                dataLabels: {
                enabled: false
                },
                stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
                },
                xaxis: {
                    categories: Object.keys(dataValues).map((x)=>x),
                },
                yaxis: {
                title: {
                    text: 'Sessions / Day'
                }
                },
                fill: {
                opacity: 1
                },
                tooltip: {
                y: {
                    formatter: function (val) {
                    return  val 
                    }
                }
                }
                };

      


                $('#session-per-date-chart').html('');
                var chart = new ApexCharts(document.querySelector("#session-per-date-chart"), options);
                chart.render();




            },

            statsPerUserChart(dataValues) {
                
                var options = {
                series: [
                {
                name: 'Count',
                data: Object.keys(dataValues).map((x)=>dataValues[x].count)
                },
                {
                name: 'Total time (min)',
                data: Object.keys(dataValues).map((x)=> Math.round(dataValues[x].total_time / 60))
                }],
                chart: {
                     toolbar: {
                            show: false,
                        },
                type: 'bar',
                    height: 350
                },
                plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '50%',
                    endingShape: 'rounded'
                },
                },
                dataLabels: {
                enabled: false
                },
                stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
                },
                xaxis: {
                    categories: Object.keys(dataValues).map((x)=>x.split("|")[1]),
                },
                yaxis: {
                title: {
                    text: 'Sessions / User'
                }
                },
                fill: {
                opacity: 1
                },
                tooltip: {
                y: {
                    formatter: function (val) {
                    return  val 
                    }
                }
                }
                };

      


                $('#session-per-user-chart').html('');
                var chart = new ApexCharts(document.querySelector("#session-per-user-chart"), options);
                chart.render();




            },

            computerHealtChart() {

                var options = {
                    tooltip: {

                        theme: 'light',
                        x: {
                            show: false
                        },
                        y: {
                            title: {
                                formatter: function() {
                                    return ''
                                }
                            }
                        }
                    },
                    series: [{
                        data: [parseInt(this.fullStats.nPcDanger), parseInt(this.fullStats.nPcWarning), parseInt(this.fullStats.nPcOk)]
                    }],
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        height: 350,
                        type: 'bar',
                        events: {
                            click: function(chart, w, e) {
                                console.log(chart, w, e)
                            }
                        }
                    },
                    plotOptions: {
                        bar: {
                            
                        distributed: true,
                        horizontal: true,
                        columnWidth: '50%',
                        endingShape: 'rounded'
                        }
                    },
                    dataLabels: {
                        enabled: true
                    },
                    legend: {
                        show: false
                    },
                    xaxis: {
                        categories: [
                            ['ERROR', 'PC '],
                            ['WARNING', 'PC '],
                            ['SUCCESS', 'PC '],
                        ],
                        labels: {
                            formatter: function(value) {
                                return parseInt(value);
                            },
                            style: {
                                fontSize: '10px'
                            }
                        }
                    }
                };
                $('#computer-health-chart').html('');

                var chart = new ApexCharts(document.querySelector("#computer-health-chart"), options);
                chart.render();

            },

            
    }
  }
</script> 