<template>
<div class="w-100 d-flex flex-column" >

        <!-- charms -->
       <!--  <div data-role="charms" id="notifications-statsremote" data-position="left"
            data-cls-charms="bd-gray bg-white border">
            <div class="row p-3">
                <h3 class="text-left fg-black">{{ $t("Notifications") }}</h3>
                <div class="cell d-flex flex-justify-end">
                    <button type="submit" class="button primary">{{ $t("Save") }}</button>
                    <button onclick="$('#notifications-statsremote').data('charms').toggle()" class="button bg-white"><span class="icon mif mif-close mif-bin"> </span></button>
                </div>
            </div>                       
        </div> -->

        <Navigation pageName="backupstats" />
    
        <!-- spinner -->
        <div v-if="loading" class="h-100 m-0 op-dark-hi p-0 pos-absolute pos-center w-100 z-2">
           <span class="ani-spin fg-white mif-5x mif-spinner2 mt-13-minus pos-center"></span>
        </div>

        <!-- graph container -->
        <div class="w-100 rounded p-3 overflow" >
            <div class="row">
                <div class="col-lg-12 col-sm-12 bg-transparent">
                    <!--sezione filtri--> 
                    <form class="form-inline" @submit.prevent="getStats()">
                        <div class="d-flex flex-justify-left mb-1 pr-6">
                            <select class="pl-4 select text-small mr-2 pl-3 pr-3 w-auto" v-model="filters.id_group">
                                <option value="">{{ $t("All Groups") }}</option>
                                <option v-for="group in groups" :value="group.id_group" v-bind:key="group.id_group">{{ group.name_group }}
                                </option>
                            </select>
                            
                            <input type="date" v-model="filters.dt_inf" :max="Date.now()| moment('YYYY-MM-DD')" class=" pl-4 select text-small mr-2 pl-3 pr-3 w-auto"  >
                            <input type="date" v-model="filters.dt_sup" :max="Date.now()| moment('YYYY-MM-DD')" class=" pl-4 select text-small mr-2 pl-3 pr-3 w-auto "  >
                            <input type="button" @click="getStats" :value="$t('Search')" class="button primary">
                        </div>       
                    </form> 
                        
                        <div class="info-box-content" >
                            <div class="panel scroll p-4 mb-3" style="height: 78vh;">
    
                            <div class="overflow">
                              
                          <!--sezione grafici-->                        
                            <div class="grid">
                                <div class="row">
                                    <div class="cell-12">
                                        <label class="mr-1">{{ $t("File number and amount per")}}</label>

                                        <select id="select_chart1" class="d-inline-flex select text-small mr-2 pl-3 pr-3 w-auto" v-model="selected" @change="onSelBarUnoChange()">
                                        <option disabled value="">{{ $t("Please select one")}}</option>
                                        <option value="1">{{ $t("Month")}}</option>
                                        <option value="2" selected>{{ $t("Day")}}</option>
                                        </select>
                                        <!--div :class="item"  v-for="(item, index) in graph" :id="index"></div-->    
                                        
                                        <div id="chart1">
                                            <apexchart type="area" height="350" :options="chartOptions" :series="series"></apexchart>
                                        </div>     
                                    </div>
                                
                                </div>  
                                <div class="row">
                                    <div class="cell-4 text-center">
                                        <label>{{ $t("Health status")}}</label>                                                                            
                                        <div id="chart3">
                                            <apexchart type="donut" height="380" :options="chartOptionsHealthStatsPieChart" :series="seriesHealthStatsPieChart"></apexchart>
                                        </div>     
                                    </div>
                                    <div class="cell-4  text-center">
                                        <label>{{ $t("Backup Job results")}}</label>                                  
                                        <div id="chart4">
                                            <apexchart type="donut" height="380" :options="chartOptionsJobsResultsPiePieChart" :series="seriesJobsResultsPieChart"></apexchart>
                                        </div>    
                                    </div>
                                    <div class="cell-4  text-center">
                                        <label>{{ $t("Type of backups jobs")}}</label>                               
                                        <div id="chart5">
                                            <apexchart type="donut" height="380" :options="chartOptionsSourcesTypePieChart" :series="seriesSourcesTypePieChart"></apexchart>
                                        </div>    
                                    </div>
                                </div>  
                                          
                            </div>
                                                                
                        </div>

                    </div> 
                </div>
            </div>
        </div>  
    </div>



</div>



</template>
<script>
import Navigation from "../utils/Navigation";
import VueApexCharts from 'vue-apexcharts';
import { prettyBytes } from '../../utils/utilitiesmodule';
 export default {
    name:"StatsBackup",
    components: {           
            Navigation,    
            apexchart: VueApexCharts
        },
    props: {
          
       title :{
           type : String,
           required:false
       },
      
   },
   data() {
     return {
       size: 200,
       loading:false,
       groups:[],
       users:[],
    stats_for_date: {}, 
       filters: {
               dt_inf:  this.$moment().subtract(10, 'days').format('YYYY-MM-DD'),
               dt_sup:  this.$moment().format('YYYY-MM-DD'),
               id_group: ""
           },
     
        maxDate: new Date().toISOString().split('T')[0], // Imposta la data massima come oggi in formato YYYY-MM-DD

        sessions: [],
        selected:"2",  

        // PRIMO GRAFICO - AREA CURVED LINE (SIZE SUM E FILE SUM) -------
        series: [{
                data: [0, 0, 0, 0, 0]
              },
              {data: [0, 0, 0, 0, 0]}
            ],
        chartOptions: {
        chart: {
          type: 'area',
          height: 350,
          zoom: {
            enabled: true
          }
        },
        fill: {
                colors: ['#0366d6'], // Colore dell'area sotto la linea
                opacity: 0.3 // Opacità dell'area
              },
              stroke: {
                curve: 'smooth', // Tipo di curva della linea
                colors: ['#0366d6'], // Colore della linea
                width: 2 // Spessore della linea
              },
        xaxis: {
          categories: [], // Sarà popolato con le date
          labels: {
            style: {
              fontSize: '12px'
            }
          }
        },
        yaxis: [
          {
            title: {
              text:  this.$t('Files Sum')
            }
          },
          {
            opposite: true,
            title: {
              text: this.$t('Size Sum')
            }
          }
        ],
        tooltip: {
          enabled: true,
          y: {
            formatter: function (value) {
              return value + ' files'; // Aggiunge l'unità di misura al tooltip
            }
          }
        }
        },
        // ------------------------------------------
        // PRIMO GRAFICO A TORTA - PIE CHART LEFT (STATO MEDIO DEI BACKUP JOB  - HEALTH) -------
        seriesHealthStatsPieChart: [],
        chartOptionsHealthStatsPieChart: {
        labels: [],
        legend: {
          position: 'bottom'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
        },
        // ------------------------------------------
        // SECONDO GRAFICO - PIE CHART MIDDLE (NUMERI DEI RISULTATI DEI JOBS)-------
        seriesJobsResultsPieChart: [],
        chartOptionsJobsResultsPiePieChart: {
        labels: [],
        legend: {
          position: 'bottom'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
        },      
        // TERZO GRAFICO A TORTA - PIE CHART RIGHT (PERCENTUALI DELLE SORGENTI/TIPO DI BACKUP)-------
        seriesSourcesTypePieChart: [],
        chartOptionsSourcesTypePieChart: {
        labels: [],
        legend: {
          position: 'bottom'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
        },          
      }
    },


   created() {

      let self = this;

      self.groups = this.$session.getGroups();
      self.users = this.$session.getUsers();

   
      
     
   },
   mounted:function(){
       this.getStats();
   },
 
   methods:{


    

        // Funzione per generare colori varianti dello stesso colore base
        generateBlueShades(baseColor, numberOfShades) {
            const colors = [];
            for (let i = 0; i < numberOfShades; i++) {
            const lightness = i * (100 / numberOfShades); // Calcola la luminosità
            const color = `hsl(${baseColor}, 100%, ${lightness}%)`; // Imposta la tonalità del blu a 210 (blu) e varia solo la luminosità
            colors.push(color);
            }
            return colors;
        },    

         // Funzione per ottenere il mese in formato "YYYY-MM"
         getMonth(dateString) {
            const date = new Date(dateString);
            return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
            },

           async getStats() {
               let self = this;
               self.stats_for_date = {};

               //self.sessions = [];
               /*self.stats_for_date_tmp = {};
               self.stats_for_date_tmp_line = {};
               self.stats_for_date_line = {};  */          
               self.loading = true;
               //self.filteredSessions=[];

               const baseColor = '210';

               // Numero di tonalità desiderate
               const numberOfShades = 5;


               self.loading = true;
               //self.fullStats = await this.$api.getStatsFromLicense(self.filters.dt_inf,self.filters.dt_sup);
               //self.sessions =  await this.$api.getRemoteStats( self.filters);
               
               this.$api.getBackupStats(self.filters)
                 .then(function(response) {
                    if (response){

                    self.stats_for_date = response;

                    // PRIMO REPORT 
                    if (self.selected=='2'){ // per giorno
                        const categories = self.stats_for_date.logs_stats.map(log => log.data);
                        const filesSumData = self.stats_for_date.logs_stats.map(log => parseInt(log.files_sum, 10));
                        const sizeSumData = self.stats_for_date.logs_stats.map(log => prettyBytes(parseInt(log.size_sum, 10)));

                        // Popola le serie e le categorie
                        self.series = [
                            {
                                name: self.$t("Files Sum"),
                                data: filesSumData
                            },
                            {
                                name: self.$t("Size Sum (MB)"),
                                data: sizeSumData
                            }
                        ];
                        self.aggiornaCategorieAreaChart(categories);
                        self.chartOptions.xaxis = {
                          categories: categories,
                          labels: {
                              formatter: function (value) {
                                  return new Date(value).toLocaleDateString(); // Mostra la data in formato leggibile
                              }
                          }
                      };
                      self.chartOptions.yaxis = [
                          {
                              labels: {
                                  formatter: function (value) {
                                      return value; // Lascia i valori della prima serie come sono (Files Sum)
                                  }
                              },
                              title: {
                                  text: self.$t("Files Sum")
                              }
                          },
                          {
                              opposite: true,
                              labels: {
                                  formatter: function (value) {
                                      return `${value.toFixed(2)} MB`; // Formatta Size Sum in MB
                                  }
                              },
                              title: {
                                  text: self.$t("Size Sum (MB)")
                              }
                          }
                      ];
                      self.chartOptions.tooltip = {
                          y: {
                              formatter: function (value, { seriesIndex }) {
                                  return seriesIndex === 1 ? `${value.toFixed(2)} MB` : value; // Formatta Size Sum in MB
                              }
                          }
                      };

                    }else{ // per mese
                        const logsStats = self.stats_for_date.logs_stats;
                     
                        // Raggruppa i dati per mese
                        const monthlyData = logsStats.reduce((acc, log) => {
                            const month = self.getMonth(log.data);
                            if (!acc[month]) {
                                acc[month] = { files_sum: 0, size_sum: 0 };
                            }
                            acc[month].files_sum += parseInt(log.files_sum, 10);
                            acc[month].size_sum += parseInt(log.size_sum, 10);
                            return acc;
                        }, {});

                        // Crea le categorie e le serie mensili
                        const categories = Object.keys(monthlyData);
                        const filesSumData = categories.map(month => monthlyData[month].files_sum);
                        const sizeSumData = categories.map(month => prettyBytes(monthlyData[month].size_sum));

                        // Popola le serie e le categorie
                        self.series = [
                            {
                                name: self.$t("Files Sum"),
                                data: filesSumData
                            },
                            {
                                name: self.$t("Size Sum (MB)"),
                                data: sizeSumData
                            }
                        ];
                        self.aggiornaCategorieAreaChart(categories);
                        self.chartOptions.xaxis = {
                          categories: categories,
                          labels: {
                              formatter: function (value) {
                                  return new Date(value).toLocaleDateString(); // Mostra la data in formato leggibile
                              }
                          }
                      };
                      self.chartOptions.yaxis = [
                          {
                              labels: {
                                  formatter: function (value) {
                                      return value; // Lascia i valori della prima serie come sono (Files Sum)
                                  }
                              },
                              title: {
                                  text: self.$t("Files Sum")
                              }
                          },
                          {
                              opposite: true,
                              labels: {
                                  formatter: function (value) {
                                      return `${value.toFixed(2)} MB`; // Formatta Size Sum in MB
                                  }
                              },
                              title: {
                                  text: self.$t("Size Sum (MB)")
                              }
                          }
                      ];
                      self.chartOptions.tooltip = {
                          y: {
                              formatter: function (value, { seriesIndex }) {
                                  return seriesIndex === 1 ? `${value.toFixed(2)} MB` : value; // Formatta Size Sum in MB
                              }
                          }
                      };

                    }
                    // ---------------------------------------------------------------------------------
                    
                    // SECONDO REPORT - PIE REPORT LEFT ---------------------------------------------------
                    
                        const relevantStats = {
                            "Average Error": self.stats_for_date.jobs_stats.nOfJobs_averageError,
                            "Average Ok": self.stats_for_date.jobs_stats.nOfJobs_averageOk,
                            "Average Warning": self.stats_for_date.jobs_stats.nOfJobs_averageWarning,
                            "Never Started": self.stats_for_date.jobs_stats.nOfJobs_neverStarted
                        };

                        const labels = Object.keys(relevantStats).map(p => self.$t(p));
                        const seriesData = Object.values(relevantStats);
  

                        // Popola le serie e le etichette
                        //self.seriesHealthStatsPieChart = seriesData;
                        //self.chartOptionsHealthStatsPieChart.labels = labels;//.map(label => label);

                        self.chartOptionsHealthStatsPieChart = {
                            theme: {
                                monochrome: {
                                    enabled: true,
                                    color: '#0e72cf',
                                    shadeTo: 'light',
                                    shadeIntensity: 0.65
                                },
                            },
                            labels: labels,
                          };
                        self.seriesHealthStatsPieChart = seriesData;
                    // ------------------------------------------------------------------------------------
                    
                    // SECONDO REPORT - PIE REPORT middle ---------------------------------------------------
                    const jobsResultMiddlePie = {
                            "Errors": self.stats_for_date.jobs_results.nOfErrors,
                            "Ok": self.stats_for_date.jobs_results.nOfOks,
                            "Warnings": self.stats_for_date.jobs_results.nOfWarnings,
                        };

                        const labelsjobsResult = Object.keys(jobsResultMiddlePie).map(p => self.$t(p));
                        const seriesDatajobsResult = Object.values(jobsResultMiddlePie);


                        // Popola le serie e le etichette
                       
                        //self.chartOptionsJobsResultsPiePieChart.labels = labelsjobsResult;//.map(label => label);

                        self.chartOptionsJobsResultsPiePieChart = {
                            theme: {
                                monochrome: {
                                    enabled: true,
                                    color: '#0e72cf',
                                    shadeTo: 'light',
                                    shadeIntensity: 0.65
                                },
                            },
                            labels: labelsjobsResult,
                          };
                          self.seriesJobsResultsPieChart = seriesDatajobsResult;
                    // -------------------------------------------------------------------------------------   

                    
                        const relevantStatsPieTwo = {
                            "Database": self.stats_for_date.jobs_stats.nOfDbSources,
                            "Drive image": self.stats_for_date.jobs_stats.nOfDriveImageSources,
                            "Esxi": self.stats_for_date.jobs_stats.nOfEsxiSources,
                            "Exchange": self.stats_for_date.jobs_stats.nOfExchangeSources,
                            "Local File/Folder": self.stats_for_date.jobs_stats.nOfFileFolderNetworkSources,
                            "HyperV": self.stats_for_date.jobs_stats.nOfHyperVSources,
                        };

                        const labelsPieTwo = Object.keys(relevantStatsPieTwo);
                        const seriesDataPieTwo = Object.values(relevantStatsPieTwo);

                        self.chartOptionsSourcesTypePieChart = {
                            theme: {
                                monochrome: {
                                    enabled: true,
                                    color: '#0e72cf',
                                    shadeTo: 'light',
                                    shadeIntensity: 0.65
                                },
                            },
                            labels: labelsPieTwo,
                          };
                        self.seriesSourcesTypePieChart = seriesDataPieTwo;
                    // -------------------------------------------------------------------------------------       

                

                }})
                .catch(e => {
                    //self.errors.push(e)
                    //self.toastr["info"](self.errors);
                    app.loading = false;
                })

               self.loading = false;

           },

       // METODI PER I REPORT CHART ###########################################################################
        // eseguita al change della combo month/day del grafico a colonne verticali
        onSelBarUnoChange() {

            var self = this;

            // PRIMO REPORT 
            if (self.stats_for_date){
                    if (self.selected=='2'){ // per giorno
                        const categories = self.stats_for_date.logs_stats.map(log => log.data);
                        const filesSumData = self.stats_for_date.logs_stats.map(log => parseInt(log.files_sum, 10));
                        const sizeSumData = self.stats_for_date.logs_stats.map(log => prettyBytes(parseInt(log.size_sum, 10)));

                        // Popola le serie e le categorie
                        self.series = [
                        {
                            name: self.$t("Files Sum"),
                            data: filesSumData
                        },
                        {
                            name: self.$t("Size Sum (MB)"),
                            data: sizeSumData
                        }
                        ];
                        self.aggiornaCategorieAreaChart(categories);
                        self.chartOptions.xaxis = {
                            categories: categories,
                            labels: {
                                formatter: function (value) {
                                    return new Date(value).toLocaleDateString(); // Mostra la data in formato leggibile
                                }
                            }
                        };
                        self.chartOptions.yaxis = [
                            {
                                labels: {
                                    formatter: function (value) {
                                        return value; // Lascia i valori della prima serie come sono (Files Sum)
                                    }
                                },
                                title: {
                                    text: self.$t("Files Sum")
                                }
                            },
                            {
                                opposite: true,
                                labels: {
                                    formatter: function (value) {
                                        return `${value.toFixed(2)} MB`; // Formatta Size Sum in MB
                                    }
                                },
                                title: {
                                    text: self.$t("Size Sum (MB)")
                                }
                            }
                        ];
                        self.chartOptions.tooltip = {
                            y: {
                                formatter: function (value, { seriesIndex }) {
                                    return seriesIndex === 1 ? `${value.toFixed(2)} MB` : value; // Formatta Size Sum in MB
                                }
                            }
                        };
                     
                    }else{ // per mese
                        const logsStats = self.stats_for_date.logs_stats;
                     
                        // Raggruppa i dati per mese
                        const monthlyData = logsStats.reduce((acc, log) => {
                            const month = self.getMonth(log.data);
                            if (!acc[month]) {
                                acc[month] = { files_sum: 0, size_sum: 0 };
                            }
                            acc[month].files_sum += parseInt(log.files_sum, 10);
                            acc[month].size_sum += parseInt(log.size_sum, 10);
                            return acc;
                        }, {});

                        // Crea le categorie e le serie mensili
                        const categories = Object.keys(monthlyData);
                        const filesSumData = categories.map(month => monthlyData[month].files_sum);
                        const sizeSumData = categories.map(month => prettyBytes(monthlyData[month].size_sum));

                        // Popola le serie e le categorie
                        self.series = [
                            {
                                name: self.$t("Files Sum"),
                                data: filesSumData
                            },
                            {
                                name: self.$t("Size Sum (MB)"),
                                data: sizeSumData
                            }
                        ];
                        self.aggiornaCategorieAreaChart(categories);
                        self.chartOptions.xaxis = {
                          categories: categories,
                          labels: {
                              formatter: function (value) {
                                  return new Date(value).toLocaleDateString(); // Mostra la data in formato leggibile
                              }
                          }
                      };
                      self.chartOptions.yaxis = [
                          {
                              labels: {
                                  formatter: function (value) {
                                      return value; // Lascia i valori della prima serie come sono (Files Sum)
                                  }
                              },
                              title: {
                                  text: self.$t("Files Sum")
                              }
                          },
                          {
                              opposite: true,
                              labels: {
                                  formatter: function (value) {
                                      return `${value.toFixed(2)} MB`; // Formatta Size Sum in MB
                                  }
                              },
                              title: {
                                  text: self.$t("Size Sum (MB)")
                              }
                          }
                      ];
                      self.chartOptions.tooltip = {
                          y: {
                              formatter: function (value, { seriesIndex }) {
                                  return seriesIndex === 1 ? `${value.toFixed(2)} MB` : value; // Formatta Size Sum in MB
                              }
                          }
                      };
                    }
                    // ---------------------------------------------------------------------------------
                }    
            },

        aggiornaCategorieAreaChart(categorieNuove) {

          let self = this;

          self.chartOptions = {
              ...self.chartOptions,
              xaxis: { ...self.chartOptions.xaxis, categories: categorieNuove }
            };
          }

                
   }
 }
</script> 