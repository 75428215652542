import { archiveTypes, selectValues } from "./selectvalues";
import { getValueOrDefault } from "./utilitiesmodule";

export const labels = {
    "name": "NAME",
    "type": "TYPE",

    "useArchiveCompression": 'ARCHIVE - USE ARCHIVE COMPRESSION',
    "useArchiveFileCustomName": 'ARCHIVE - USE FILE CUSTOM NAME',
    "archiveFileCustomName": 'ARCHIVE - FILE CUSTOM NAME',
    "useArchivePassword": 'ARCHIVE - USE PASSWORD',
    "archivePassword": 'ARCHIVE - PASSWORD',
    "protectionType": "ARCHIVE - PROTECTION TYPE",
    "pathsInArchive": "ARCHIVE - PATHS IN ARCHIVE",

    "backupMode": 'BACKUP - MODE',
    "backupType": 'BACKUP - TYPE',
    "nCopies": 'BACKUP - NUMBER OF COPIES',
    "nOfCopies": 'BACKUP - NUMBER OF COPIES',
    "verifyBackup": 'BACKUP - VERIFY BACKUP',

    "specifiedExtensions": "FILTERS - SPECIFIED EXTENSIONS",
    "extensionsFilterAction": "FILTERS - INCLUDE/EXCLUDE EXTENSIONS",
    "useFileSizeFilter": "FILTERS - FILE SIZE FILTER - ENABLED",
    "fileSizeFilterCompare": "FILTERS - FILE SIZE FILTER - COMPARATOR",
    "fileSizeFilterAction": "FILTERS - FILE SIZE FILTER - ACTION",
    "fileSize": "FILTERS - FILE SIZE FILTER - FILE SIZE (in MB)",

    "excludedPaths": "EXCLUSIONS - EXCLUDED PATHS",
    "excludeSubFolders": "EXCLUSIONS - EXCLUDE SUB FOLDERS",

    "disableDasdIo": "ENABLE I/O BOUNDARY CHECKS BY THE FILE SYSTEM",
    "badItemsLimit": "BAD ITEMS LIMIT",
    "outputFormat": "OUTPUT FORMAT",
    "rtc": "RTC",
    "server": "SERVER",

    "autoAddNewItems": "ADD NEW ITEMS AUTOMATICALLY",
    "useNativeComp": "USE NATIVE COMPRESSION",
    "replicaName": "REPLICA NAME",
    "cbtPort": "CBT PORT",
    "includePublicFolders": "INCLUDE PUBLIC FOLDERS",
    "truncateExchangeLogs": "TRUNCATE EXCHANGE LOGS",
    "useCopyOnly": "USE COPY ONLY",
    "enableAppend": "APPEND TEXT AND/OR DYNAMIC VARIABLES TO THE BACKUP FILE NAME",
    "appendTextBkp": "APPEND TEXT BACKUP",
    "singleComp": "CREATE .ZIP FILE FOR EACH SINGLE DATABASE",
    "backupLog": "BACKUP THE TRANSACTION LOG",
    "shrinkLog": "SHRINK THE TRANSACTION LOG AFTER THE BACKUP",
    "alterDbRecModel": "ALTER THE DATABASE RECOVERY MODE TO SIMPLE IF NECESSARY",
    "appName": "APPLICATION NAME",
    "tenant_id": "TENANT ID",
    "useModernAuth": "USE MODERN AUTHENTICATION",
    "useWinAuth": "USE WINDOWS AUTHENTICATION",
    "useTcpIpNetLib": "USE 'TCP/IP NET LIBRARY'",
    "usePersistSecInfo": "USE 'PERSIST SECURITY INFO'",
    "useIntSecSSPI": "USE 'INTEGRATED SECURITY SSPI'",
    "useTrustConn": "USE TRUSTED CONNECTION",
    "useEncConn": "USE ENCRYPTED CONNECTION",
    "senderAddress": "SENDER ADDRESS",
    "usePop3": "POP3 - ENABLED",
    "popUsername": "POP3 - USERNAME",
    "popPassword": "POP3 - PASSWORD",
    "popServer": "POP3 - SERVER",
    "popPort": "POP3 - PORT",

    "copySecurityPermissions": "COPY SECURITY PERMISSIONS",
    "nThreads": "NUMBER OF THREADS",
    "recreateCompletePath": "RECREATE COMPLETE PATH",
    "restoreCustomFolder": "RESTORE CUSTOM FOLDER",
    "syncronize": "SYNCRONIZE",
    "bucket_name": "BUCKET NAME",
    "client_id": "CLIENT ID",
    "port": "PORT",
    "region": "REGION",
    "type_name": "TYPE",
    "url": "URL",
    "use_ssl": "USE SSL",
    "username": "USERNAME",
    "signatureversion": "SIGNATURE VERSION",
    "path": "PATH",
    "connectionType": "CONNECTION TYPE",
    "passiveMode": "USE PASSIVE MODE",
    "domain": "DOMAIN",
    "archiveType": "Archive type",
    "nDownloadThreads": "Number of download threads",
    "buffer": "Buffer size",
    "includeCalendar": "Include Calendar",
    "includeContacts": "Include Contacts",
    "includeArchive": "Include Archive",
    "includePublicFolders": "Include Public Folders",
    "includeDrafts": "Include Drafts",
    "includeDeletedItems": "Include Deleted Items",
    "includeInbox": "Include Inbox",
    "includeJunkeMails": "Include Junke Mails",
    "includeOutbox": "Include Outbox",
    "includeSentItems": "Include Sent Items",
    "includeTasks": "Include Tasks",
    "createPCNameFolder": "Create folder with PC name",
    "isCBT": "Is CBT",
    "resetCBT": "Reset CBT",
    "rct": "Backup RCT (Resilient Change Tracking)"
}

//Dall'oggetto passato ritorna un oggetto le cui proprietà solo le label e i valori rimangono tali. Si modificano solamente i booleani in yes/no
export function getFriendlyObject(obj) {

    if ([undefined, null, "undefined", "null", ""].includes(obj)){

        return [
            ["Warning"], { "Warning": "Data not found" }
        ];
    }

    var friendlyObj = {};
    var propsNames = Object.keys(obj);
    var propLabel, propValue;
    propsNames.forEach(p => {

        if (propsToExclude.includes(p))
            return;
        //ricavo la label
        propLabel = labels[p];
        if (propLabel == undefined) {
            console.log("NESSUNA LABEL CORRISPONDENTE A " + p);
            propLabel = p;
            //continue;
        }

        propValue = getValueOrDefault(obj[p], null);
        if (propValue == null){
            return;
        }

        //Devo sistemare alcuni valori
        if (propValue.toString().toLowerCase() == "true"){
            propValue = "Yes";

        }
        if (propValue.toString().toLowerCase() == "false"){
            propValue = "No";
        }


        switch (p) {
            case "protectionType":
                if (!obj.useArchiveCompression) return;
                propValue = selectValues.protectionTypes[propValue]; // propValue è l'indice del valore
                break;

            case "pathsInArchive":
                if (!obj.useArchiveCompression) return;
                propValue = selectValues.pathsInArchive[propValue];
                break;

            case "backupType":
                propValue = selectValues.backupTypes[propValue];
                break;

            case "fileSizeFilterAction":
                if (!obj.useFileSizeFilter) return;
                propValue = selectValues.fileSizeFilterAction[propValue];
                break;

            case "fileSizeFilterCompare":
                if (!obj.useFileSizeFilter) return;
                propValue = selectValues.fileSizeFilterCompare[propValue];
                break;

            case "fileSize":
                if (!obj.useFileSizeFilter) return;
                break;

            case "extensionsFilterAction":
                if (obj.specifiedExtensions == "") return;
                propValue = selectValues.extensionsFilterAction[propValue];
                break;

            case "excludedPaths":
                // propValue -> path?type|path?type|... -> prendo solo i percorsi e li divido con un ;
                propValue = propValue.split("|").map(path_type => path_type.split("?")[0]).join("; ");
                break;

            case "archiveType":
                if (!obj.useArchiveCompression)
                    return;
                propValue = archiveTypes[propValue];
                break;

            case "useArchiveFileCustomName":
                return;
            case "useArchivePassword":
                return;
            default:
                // Se non è stata abilitata la compressione zip, non mostro le sue informazioni
                if (!obj["useArchiveCompression"] && [
                        "archiveFileCustomName",
                        "useArchivePassword",
                    ].includes(p)) {
                    return;
                }

                // if (!obj["useArchiveFileCustomName"] && p == "archiveFileCustomName")
                //     return;

                // if (!obj["useArchivePassword"] && p == "archivePassword")
                //     return;


                if (p == "port" && obj.port == 0)
                    return;
                break;
        }


    if(propValue!=null){

        friendlyObj[propLabel.titolize()] = propValue;
    }
    });

    var orderedProperties = Object.keys(friendlyObj);
    orderedProperties.sort((a, b) => a.localeCompare(b));
    return [orderedProperties, friendlyObj];
}

const propsToExclude = [
    "id",
    "id_master_account",
    "access_token",
    "refresh_token",
    "status",
    "token",
    "type",
    "options",
    "accountSessionId",
    "id_service_account",
    "password",
    "archivePassword",
    "popPassword",
    "objID",
    "driveID",
    "userID",
    "lastTestPC",
    "client_secret",
    "id_repository",
    "id_license",
    "id_job",
    "dbSource",
    "downloadSource",
    "driveImgSource",
    "esxiSource",
    "exchangeSource",
    "folderSource",
    "hvSource",
    "networkSource",
    "archivePassword_repeat"
];