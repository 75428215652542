var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "w-100 d-flex flex-column",
      staticStyle: { height: "90vh" },
    },
    [
      _c("Navigation", { attrs: { pageName: "sessions" } }),
      _c(
        "div",
        {
          staticClass: "p-5 w-100 mt-4 overflow",
          attrs: { id: "all_sessions" },
        },
        [
          _c(
            "form",
            {
              staticClass: "inline-form",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.getSessions()
                },
              },
            },
            [
              _c("div", { staticClass: "d-flex flex-justify-start" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.id_group,
                        expression: "filters.id_group",
                      },
                    ],
                    staticClass: "pl-4 select text-small",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.filters,
                          "id_group",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v(_vm._s(_vm.$t("All Groups"))),
                    ]),
                    _vm._l(_vm.groups, function (group) {
                      return _c(
                        "option",
                        {
                          key: group.id_group,
                          domProps: { value: group.id_group },
                        },
                        [_vm._v(_vm._s(group.name_group) + " ")]
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.id_user,
                        expression: "filters.id_user",
                      },
                    ],
                    staticClass: "pl-4 select text-small",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.filters,
                          "id_user",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v(_vm._s(_vm.$t("All Users"))),
                    ]),
                    _vm._l(_vm.users, function (user) {
                      return _c(
                        "option",
                        { key: user.id, domProps: { value: user.id } },
                        [
                          _vm._v(
                            _vm._s(user.firstname) +
                              " " +
                              _vm._s(user.lastname) +
                              " (" +
                              _vm._s(user.username) +
                              ") "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.dt_inf,
                      expression: "filters.dt_inf",
                    },
                  ],
                  staticClass: "date select p-4 text-small text-center",
                  attrs: {
                    type: "date",
                    min: "2018-01-01",
                    max: _vm._f("moment")(Date.now(), "YYYY-MM-DD"),
                  },
                  domProps: { value: _vm.filters.dt_inf },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.filters, "dt_inf", $event.target.value)
                    },
                  },
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.dt_sup,
                      expression: "filters.dt_sup",
                    },
                  ],
                  staticClass: "date select p-4 text-small text-center",
                  attrs: {
                    type: "date",
                    min: "2018-01-01",
                    max: _vm._f("moment")(Date.now(), "YYYY-MM-DD"),
                  },
                  domProps: { value: _vm.filters.dt_sup },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.filters, "dt_sup", $event.target.value)
                    },
                  },
                }),
                _c(
                  "button",
                  {
                    staticClass: "button primary",
                    on: {
                      click: function ($event) {
                        return _vm.getSessions()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("Search")) + " ")]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "h-25 m-0 p-0 w-100 z-2",
                },
                [
                  _c("span", {
                    staticClass:
                      "ani-spin mif-5x mif-spinner2 mt-13-minus pos-center",
                  }),
                ]
              ),
              _vm.sessions.length == 0
                ? _c("NoDataAvailable", {
                    attrs: {
                      message: _vm.$t(
                        "No sessions available for the selected dates"
                      ),
                    },
                  })
                : _vm._e(),
              _vm.sessions.length != 0
                ? _c(
                    "table",
                    {
                      staticClass: "border bg-white compact table w-100 mt-4",
                      attrs: { id: "session-list" },
                    },
                    [
                      _c("thead", { staticClass: "mb-0" }, [
                        _c("tr", { staticClass: "mb-3" }, [
                          _c(
                            "th",
                            {
                              staticClass: "text-left no-wrap",
                              staticStyle: { "vertical-align": "left" },
                            },
                            [_vm._v(_vm._s(_vm.$t("From Device")))]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-left no-wrap",
                              staticStyle: {
                                "vertical-align": "middle",
                                "white-space": "nowrap",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("From Device ID")))]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-left no-wrap",
                              staticStyle: { "vertical-align": "middle" },
                            },
                            [_vm._v(_vm._s(_vm.$t("To Device")))]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-left no-wrap",
                              staticStyle: {
                                "vertical-align": "middle",
                                "white-space": "nowrap",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("To Device ID")))]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-center no-wrap",
                              staticStyle: { "vertical-align": "middle" },
                            },
                            [_vm._v(_vm._s(_vm.$t("Organization")))]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-right no-wrap",
                              staticStyle: { "vertical-align": "middle" },
                            },
                            [_vm._v(_vm._s(_vm.$t("Started at")))]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-right no-wrap",
                              staticStyle: { "vertical-align": "middle" },
                            },
                            [_vm._v(_vm._s(_vm.$t("Ended at")))]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-center no-wrap",
                              staticStyle: { "vertical-align": "middle" },
                            },
                            [_vm._v(_vm._s(_vm.$t("Duration")))]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-center no-wrap",
                              staticStyle: { "vertical-align": "middle" },
                            },
                            [_vm._v(_vm._s(_vm.$t("Notes")))]
                          ),
                          _c("th", {
                            staticClass: "text-center no-wrap",
                            staticStyle: { "vertical-align": "middle" },
                          }),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        [
                          !_vm.sessions
                            ? _c("tr", { staticClass: "pt-10" }, [
                                _c("td", {
                                  staticClass: "p-6",
                                  attrs: {
                                    colspan: "7",
                                    "data-role": "activity",
                                    "data-type": "metro",
                                    "data-style": "dark",
                                  },
                                }),
                              ])
                            : _vm._e(),
                          _vm._l(_vm.sessions, function (session, index) {
                            return [
                              _vm.shouldBeAddedAfter(session) &&
                              index != 0 &&
                              _vm.sessionrow != 0
                                ? _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "text-bold bg-light border border-top-none",
                                        attrs: { colspan: "11" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("Sessions")) +
                                            " : " +
                                            _vm._s(_vm.sessionrow)
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.shouldBeAddedBefore(session)
                                ? _c("tr", { staticClass: "bg-primary" }, [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "fg-white text-bold",
                                        attrs: { colspan: "11" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(session.name_computer_host) +
                                            " (" +
                                            _vm._s(session.custom_name) +
                                            ")"
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _c("tr", [
                                _c("td", { staticClass: "no-wrap" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        session.firstname +
                                          " " +
                                          session.lastname +
                                          " (" +
                                          session.name_computer_control +
                                          ")"
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(session.id_remote_control)),
                                ]),
                                _c("td", { staticClass: "text-center" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(session.name_computer_host) +
                                      " "
                                  ),
                                ]),
                                _c("td", { staticClass: "text-center" }, [
                                  _vm._v(
                                    " " + _vm._s(session.id_remote_host) + " "
                                  ),
                                ]),
                                _c("td", { staticClass: "text-center" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(session.organization_name) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "td",
                                  { staticClass: "text-right no-wrap" },
                                  [
                                    session.dt_start != "" &&
                                    session.dt_start != null
                                      ? _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatDate(session.dt_start)
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "text-right no-wrap" },
                                  [
                                    session.dt_end != "" &&
                                    session.dt_end != null
                                      ? _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatDate(session.dt_end)
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                                _c("td", { staticClass: "text-right" }, [
                                  session.dt_end != "" && session.dt_end != null
                                    ? _c(
                                        "label",
                                        { staticClass: "text-bold" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.diffDate(
                                                session.dt_start,
                                                session.dt_end
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                                _c("td", { staticClass: "text-center" }, [
                                  session.notes[0]
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value: session.notes[0].note,
                                            expression: "session.notes[0].note",
                                            modifiers: { trim: true },
                                          },
                                        ],
                                        attrs: {
                                          type: "text",
                                          "data-role": "input",
                                        },
                                        domProps: {
                                          value: session.notes[0].note,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              session.notes[0],
                                              "note",
                                              $event.target.value.trim()
                                            )
                                          },
                                          blur: function ($event) {
                                            return _vm.$forceUpdate()
                                          },
                                        },
                                      })
                                    : _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value: session.notedesc,
                                            expression: "session.notedesc",
                                            modifiers: { trim: true },
                                          },
                                        ],
                                        attrs: {
                                          type: "text",
                                          "data-role": "input",
                                        },
                                        domProps: { value: session.notedesc },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              session,
                                              "notedesc",
                                              $event.target.value.trim()
                                            )
                                          },
                                          blur: function ($event) {
                                            return _vm.$forceUpdate()
                                          },
                                        },
                                      }),
                                ]),
                                _c("td", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "button small",
                                      on: {
                                        click: function ($event) {
                                          return _vm.saveNote(session)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Save")))]
                                  ),
                                ]),
                              ]),
                            ]
                          }),
                          _vm.sessionrow != 0
                            ? _c("tr", [
                                _c("td", { attrs: { colspan: "11" } }, [
                                  _c("div", { staticClass: "text-bold" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("Sessions")) +
                                        " : " +
                                        _vm._s(_vm.sessionrow)
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }