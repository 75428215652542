var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.pc
      ? _c("div", { staticClass: "split-button m-1" }, [
          _c(
            "button",
            { staticClass: "button m-0 p-0 bg-transparent" },
            [
              _c(
                "router-link",
                {
                  staticClass: "flat-button p-0 m-0",
                  attrs: { to: "/computers/computer/" + _vm.pc.id },
                },
                [
                  _vm.pc.description == ""
                    ? _c("div", [
                        _c(
                          "span",
                          {
                            staticClass: "mif-2x",
                            class: {
                              "fg-green":
                                _vm.pc.av_alert + _vm.pc.disk_alert == 0,
                              "fg-orange":
                                _vm.pc.av_alert + _vm.pc.disk_alert == 1,
                              "fg-red": _vm.pc.av_alert + _vm.pc.disk_alert > 1,
                            },
                            attrs: {
                              title:
                                _vm.$t("Device Health") +
                                " : " +
                                _vm.$t(
                                  _vm.pc.av_alert + _vm.pc.disk_alert == 0
                                    ? _vm.$t("Good")
                                    : _vm.pc.av_alert + _vm.pc.disk_alert == 1
                                    ? _vm.$t("Some Warning")
                                    : _vm.$t("Errors")
                                ),
                            },
                          },
                          [_vm._v(" ● ")]
                        ),
                        _c("span", { staticClass: "text-bold" }, [
                          _vm._v(_vm._s(_vm.pc.name)),
                        ]),
                      ])
                    : _c("div", { staticClass: "text-left" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "row m-0 d-flex flex-nowrap m-0 mt-2 row",
                          },
                          [
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "mif-2x",
                                  class: {
                                    "fg-green":
                                      _vm.pc.av_alert + _vm.pc.disk_alert == 0,
                                    "fg-orange":
                                      _vm.pc.av_alert + _vm.pc.disk_alert == 1,
                                    "fg-red":
                                      _vm.pc.av_alert + _vm.pc.disk_alert > 1,
                                  },
                                  attrs: {
                                    title:
                                      _vm.$t("Device Health") +
                                      " : " +
                                      _vm.$t(
                                        _vm.pc.av_alert + _vm.pc.disk_alert == 0
                                          ? _vm.$t("Good")
                                          : _vm.pc.av_alert +
                                              _vm.pc.disk_alert >
                                            2
                                          ? _vm.$t("Some Warning")
                                          : _vm.$t("Errors")
                                      ),
                                  },
                                },
                                [_vm._v(" ● ")]
                              ),
                            ]),
                            _c("div", { staticClass: "col" }, [
                              _c("span", { staticClass: "ml-1 text-bold" }, [
                                _vm._v(_vm._s(_vm.pc.name)),
                              ]),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "badge inline bg-transparent ml-0",
                                  staticStyle: { display: "block" },
                                },
                                [_vm._v(_vm._s(_vm.pc.description))]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                ]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }