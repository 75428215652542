var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex flex-align-center flex-column flex-justify-center py-4",
    },
    [
      _c("div", {}, [
        _vm.iconType == "shield"
          ? _c(
              "svg",
              {
                attrs: {
                  width: "48",
                  height: "48",
                  viewBox: "0 0 24 24",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M3 5.75A.75.75 0 0 1 3.75 5c2.663 0 5.258-.943 7.8-2.85a.75.75 0 0 1 .9 0C14.992 4.057 17.587 5 20.25 5a.75.75 0 0 1 .75.75V11c0 5.001-2.958 8.676-8.725 10.948a.75.75 0 0 1-.55 0C5.958 19.676 3 16 3 11V5.75Zm1.5.728V11c0 4.256 2.453 7.379 7.5 9.442 5.047-2.063 7.5-5.186 7.5-9.442V6.478c-2.577-.152-5.08-1.09-7.5-2.8-2.42 1.71-4.923 2.648-7.5 2.8Z",
                    fill: "#222F3D",
                  },
                }),
              ]
            )
          : _vm._e(),
        _vm.iconType == "perf"
          ? _c(
              "svg",
              {
                attrs: {
                  width: "48",
                  height: "48",
                  fill: "none",
                  viewBox: "0 0 24 24",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M8.5 5.247A2.249 2.249 0 0 1 10.75 3h2.5a2.249 2.249 0 0 1 2.25 2.247v1.769h3.25A2.249 2.249 0 0 1 21 9.263v10.988a.75.75 0 0 1-.75.749H3.75a.75.75 0 0 1-.75-.75v-7.99a2.249 2.249 0 0 1 2.25-2.248H8.5V5.247ZM10 19.503h4V5.247a.75.75 0 0 0-.75-.749h-2.5a.75.75 0 0 0-.75.75v14.254ZM8.5 11.51H5.25a.75.75 0 0 0-.75.749v7.242h4V11.51Zm7 7.99h4V9.264a.75.75 0 0 0-.75-.75H15.5v10.989Z",
                    fill: "#222F3D",
                  },
                }),
              ]
            )
          : _vm._e(),
        _vm.iconType == "policy"
          ? _c(
              "svg",
              {
                attrs: {
                  width: "48",
                  height: "48",
                  fill: "none",
                  viewBox: "0 0 24 24",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M18.5 20.002a.5.5 0 0 1-.5.5H9v1.5h9a2 2 0 0 0 2-2V9.83c0-.53-.211-1.04-.586-1.414l-5.829-5.829a.49.49 0 0 0-.049-.04.63.63 0 0 1-.036-.03 2.072 2.072 0 0 0-.219-.18.65.65 0 0 0-.08-.044c-.017-.008-.033-.015-.048-.024l-.05-.029c-.054-.031-.109-.063-.166-.087a1.977 1.977 0 0 0-.624-.138c-.02-.001-.04-.004-.059-.007A.605.605 0 0 0 12.172 2H6a2 2 0 0 0-2 2v7.257a4.49 4.49 0 0 1 1.5-.256v-7a.5.5 0 0 1 .5-.5h6V8a2 2 0 0 0 2 2h4.5v10.001Zm-5-15.38L17.378 8.5H14a.5.5 0 0 1-.5-.5V4.62ZM9 15.501a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-6 3.742v3.044a.71.71 0 0 0 1.212.503L5.5 21.503l1.288 1.288A.71.71 0 0 0 8 22.288v-3.044a4.479 4.479 0 0 1-2.5.758c-.925 0-1.785-.279-2.5-.758Z",
                    fill: "#222F3D",
                  },
                }),
              ]
            )
          : _vm._e(),
        _vm.iconType == "default"
          ? _c(
              "svg",
              {
                staticClass: "icon mif-4x fg-gray",
                attrs: {
                  width: "48",
                  height: "48",
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 24 24",
                  fill: "currentColor",
                },
              },
              [
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    d: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm-1 14v-4h2v4h-2Zm0-6V8h2v2h-2Z",
                    "clip-rule": "evenodd",
                  },
                }),
              ]
            )
          : _vm._e(),
      ]),
      _c("h2", { staticClass: "fg-gray-500 text-leader" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
      _c("p", { staticClass: "fg-gray-500 mt-2 text-center" }, [
        _vm._v(" " + _vm._s(_vm.description) + " "),
      ]),
      _vm.hinted != null
        ? _c(
            "router-link",
            {
              staticClass: "button primary mt-4",
              attrs: {
                "data-role": "hint",
                "data-hint-position": "top",
                "data-hint-text": _vm.hinted,
                to: _vm.link,
              },
            },
            [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
          )
        : _c(
            "router-link",
            {
              staticClass: "button primary mt-4",
              attrs: {
                onclick: "window.Metro.charms.toggle('#plugin');",
                to: "#",
              },
            },
            [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }