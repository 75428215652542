var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "body",
    {
      staticClass:
        "h-vh-100 d-flex flex-justify-center flex-align-center bg-light",
    },
    [
      _c("div", [
        _c(
          "form",
          {
            staticClass:
              "bg-white p-6 border-radius border-radius-12 border text-center",
            attrs: {
              action: "javascript:",
              "data-role": "validator,Validator",
              "data-clear-invalid": "2000",
              "data-on-error-form": "invalidForm",
              "data-role-validator": "true",
              novalidate: "novalidate",
            },
          },
          [
            _c("img", {
              attrs: {
                src: "/assets/img/brand/iperius_one_v6.svg",
                width: "270",
                alt: "Iperius One Logo",
              },
            }),
            _c("p", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.$t("Two-factor authentication required"))),
            ]),
            _c("div", { staticClass: "form-group pb-4" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.TFAcode,
                    expression: "TFAcode",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "form-group input mb-4 mt-4 p-4",
                attrs: {
                  type: "password",
                  placeholder: _vm.$t(
                    "Enter the code generated by your mobile app"
                  ),
                },
                domProps: { value: _vm.TFAcode },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.TFAcode = $event.target.value.trim()
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _c("span", { staticClass: "invalid_feedback" }, [
                _vm._v(_vm._s(_vm.$t("Please enter a valid code"))),
              ]),
            ]),
            _c("div", { staticClass: "text-center" }, [
              _c(
                "button",
                {
                  staticClass: "button primary text-uppercase w-75",
                  on: {
                    click: function ($event) {
                      return _vm.loginTFA()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Login")))]
              ),
              _c(
                "button",
                {
                  staticClass: "flat-button",
                  on: {
                    click: function ($event) {
                      return _vm.$root.goBack()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Close")))]
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            attrs: {
              "data-role": "collapse",
              "data-toggle-element": "#recovery_collapse",
              "data-collapsed": "true",
            },
          },
          [
            _c(
              "form",
              {
                staticClass: "bg-white p-2",
                attrs: {
                  action: "javascript:",
                  "data-role": "validator,Validator",
                  "data-clear-invalid": "2000",
                  "data-on-error-form": "invalidForm",
                  "data-role-validator": "true",
                  novalidate: "novalidate",
                },
              },
              [
                _c("div", { staticClass: "text-muted mt-4 text-upper" }, [
                  _vm._v(_vm._s(_vm.$t("Recovery code verification"))),
                ]),
                _c("div", { staticClass: "form-group pb-4" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.TFAcode_recovery,
                        expression: "TFAcode_recovery",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "metro-input",
                    attrs: {
                      "data-role": "input",
                      type: "password",
                      placeholder: _vm.$t("Insert your code"),
                      "data-prepend": "<span class='mif-key'></span>",
                    },
                    domProps: { value: _vm.TFAcode_recovery },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.TFAcode_recovery = $event.target.value.trim()
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.$t("Required"))),
                  ]),
                  _c("span", { staticClass: "text-muted text-small" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "This login will not remembered until you provide an authenticator app code at login or disable 2FA and login again"
                        )
                      )
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "form-group d-flex flex-align-center flex-justify-end",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "button alert rounded text-upper",
                        on: {
                          click: function ($event) {
                            return _vm.loginTFArecovery()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Login with recovery code")))]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }