<template :key="componentKey">

    <header v-cloak data-role="appbar"  class="bd-primary border border-3 border-left-none border-right-none border-top-none w-100" data-expand-point="xl">
        
        <div class="app-bar-container w-100 flex-justify-between" style="user-select: none;">
            <div>
                
                <router-link to="/dashboard">
                    <img src="/assets/img/brand/iperius_one_v6.svg" height="100" alt="Iperius One" class="ml-7 mt-3">
                </router-link>
            </div>

            <ul class="bg-transparent h-menu" >
                    <!-- <li v-if="user" >
                        <a href="#" :title="$t('Last jobs executed')" class="app-bar-item ">
                            <span class="icon">
                                <span class="mif-chat"></span>
                                <span class="badge bg-red rounded fg-white mt-2 mr-1">4</span>
                            </span>
                        </a>
                    </li>
                    <li v-if="user">
                        <a href="#" class="app-bar-item">
                            <span class="mif-envelop"></span>
                            <span class="badge  bg-red rounded  fg-white mt-2 mr-1">4</span>
                        </a>
                    </li>
                    <li v-if="user">
                        <a href="#" class="app-bar-item ">
                            <span class="mif-flag "></span>
                            <span class="badge bg-red rounded  fg-white mt-2 mr-1">{{ events.length }}</span>
                        </a>
                        <div class="d-menu border  bg-white mt-1" data-role="dropdown">
                            <div class="d-flex flex-justify-end">
                                <div class="flat-button">{{ $t('Set all read') }}</div>
                            </div>
                            <ul class="m-0 p-0">
                                <li class="text-small" v-for="(event) in events" :key="event.id"
                                    :class="{ 'text-bold': event.is_read, 'fg-gray': !event.is_read }">
                                    <a>{{ event.msg }}<span class="mr-3 text-small">{{ event.subtitle }}</span> </a>
                                </li>
                            </ul>
                        </div>
                    </li> -->
                    <li v-if="user">
                        <a href="#" class="dropdown-toggle app-bar-item"  >
                         <label class=" pl-4">{{ $root.checkAuth()?.firstname + ' ' + $root.checkAuth()?.lastname + ' (' + $root.checkAuth()?.username + ')' }}</label> 
                        </a>
                        <ul class="d-menu border  w-100 bg-white"  id="userdropdown" data-role="dropdown" >
                            <li class="bg-light">
                                <router-link :to="'/users#' + $root.checkAuth()?.id_user" class="pl-5">
                                <svg class="va-middle" width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M6.25 3A3.25 3.25 0 0 0 3 6.25v8.5A3.25 3.25 0 0 0 6.25 18h8.5A3.25 3.25 0 0 0 18 14.75v-8.5A3.25 3.25 0 0 0 14.75 3h-8.5Zm6.5 4.5a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm.75 3.25a1.5 1.5 0 0 1 1.5 1.5v.5c0 .496-.086.86-.254 1.16-.168.303-.43.562-.818.83-.733.503-1.858.76-3.428.76-1.32 0-2.445-.257-3.24-.76-.419-.266-.73-.524-.938-.83-.205-.3-.322-.664-.322-1.16v-.5a1.5 1.5 0 0 1 1.5-1.5h6Z" fill="#212121"/><path d="M6.01 19a3.248 3.248 0 0 0 2.74 1.5h6.5c2.9 0 5.25-2.35 5.25-5.25v-6.5c0-1.15-.598-2.162-1.5-2.74v9.24A3.75 3.75 0 0 1 15.25 19H6.01Z" fill="#212121"/></svg>
                                {{ $t('User Profile') }} <span class="badge bg-primary fg-white inline ml-3">{{ $root.checkAuth()?.roles }}</span>
                                </router-link>
                            </li>
                            <li class="">
                                
                                <router-link to="/licenses" class="pl-5">
                                <svg class="va-middle" width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 5.75V11c0 5.001 2.958 8.676 8.725 10.948a.75.75 0 0 0 .55 0C18.042 19.676 21 16 21 11V5.75a.75.75 0 0 0-.75-.75c-2.663 0-5.258-.943-7.8-2.85a.75.75 0 0 0-.9 0C9.008 4.057 6.413 5 3.75 5a.75.75 0 0 0-.75.75ZM13.995 11a2 2 0 0 1-1.245 1.852v2.398a.75.75 0 0 1-1.5 0v-2.394A2 2 0 1 1 13.995 11Z" fill="#212121"/></svg>
                                {{ $t('License') }}
                                </router-link>
                            </li>
                            <li class=""><a @click="$parent.$parent.logout()" class="pl-5">
                                <svg class="va-middle" width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M2.75 4.504a.75.75 0 0 1 .743.648l.007.102v13.499a.75.75 0 0 1-1.493.101L2 18.753v-13.5a.75.75 0 0 1 .75-.75Zm12.46 1.883.083-.094a1 1 0 0 1 1.32-.083l.094.083 4.997 4.998a1 1 0 0 1 .083 1.32l-.083.093-4.996 5.004a1 1 0 0 1-1.499-1.32l.083-.094L18.581 13H6a1 1 0 0 1-.993-.883L5 12a1 1 0 0 1 .883-.993L6 11h12.584l-3.291-3.293a1 1 0 0 1-.083-1.32l.083-.094-.083.094Z" fill="#212121"/></svg>
                                {{ $t("Logout") }}</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <locale-changer />
                    </li>
                
                </ul>
        </div>
    </header>

</template>

<script>
import LocaleChangerVue from '../components/LocaleChanger.vue';

export default {
    name: 'Appbar',
    components: {
        'locale-changer': LocaleChangerVue,
    },
    props: {
        user: {
            type: Boolean,
            required: false
        },
    },
    data() {
        return {
            componentKey: 0,
            events: []
        };
    },
    methods: {
        forceRerender() {
            this.componentKey += 1;
        }
    },
    mounted() {
        var self = this;
        if (self.user) {


        }

    },
}
</script>