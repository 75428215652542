var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "dialog light center text-small w-100-sm w-100-md w-75-lg w-50-xl draggable-item",
    },
    [
      _c("DialogTitle", {
        attrs: {
          title:
            _vm.serviceAccount.id == null
              ? _vm.$t("Add Service Account")
              : _vm.serviceAccount.name,
          defaultTitle:
            _vm.serviceAccount.id == null
              ? _vm.$t("Add Service Account")
              : _vm.$t("Edit/Test Service Account"),
          subTitle: _vm.serviceAccount.type_name,
        },
        on: {
          closeDialog: function ($event) {
            return _vm.$root.$emit("CLOSESERVICEACCOUNTDIALOG")
          },
        },
      }),
      _c("div", { staticClass: "dialog-content overflow bg-light" }, [
        _c("div", { staticClass: "form-group d-flex" }, [
          _c("div", { staticClass: "d-flex flex-column w-40" }, [
            _c("input", {
              attrs: {
                type: "radio",
                "data-caption": _vm.$t("Register an Azure application"),
                "data-role": "radio",
                name: "r1",
              },
              domProps: { checked: _vm.registerApp },
              on: {
                click: function ($event) {
                  _vm.registerApp = true
                },
              },
            }),
            _c("input", {
              attrs: {
                type: "radio",
                "data-caption": _vm.$t("Use an existing Azure application"),
                "data-role": "radio",
                name: "r1",
              },
              domProps: { checked: !_vm.registerApp },
              on: {
                click: function ($event) {
                  _vm.registerApp = false
                },
              },
            }),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.registerApp,
                  expression: "!registerApp",
                },
              ],
              staticClass:
                "text-secondary w-60 border bg-white border-1 bd-black p-4",
            },
            [
              _c("h5", { staticClass: "fg-cobalt" }, [
                _vm._v(_vm._s(_vm.$t("Choose an Azure application"))),
              ]),
              _c("p", [
                _c("span", [_vm._v(_vm._s(_vm.$t("1 - Access ")))]),
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.chooseAppURL,
                      target: "_blank",
                      title: _vm.chooseAppURL,
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("this link")))]
                ),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(" with your organisation's administrator account")
                    )
                  ),
                ]),
              ]),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "2 - Click on the name of the Azure application you want to use"
                    )
                  )
                ),
              ]),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "3 - Fill in the following fields with the information of chosen application"
                    )
                  )
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.registerApp,
                  expression: "registerApp",
                },
              ],
              staticClass:
                "text-secondary w-60 border bg-white border-1 bd-black p-4",
            },
            [
              _c("h5", { staticClass: "fg-cobalt" }, [
                _vm._v(_vm._s(_vm.$t("Register an Azure application"))),
              ]),
              _c("p", [
                _c("span", [_vm._v(_vm._s(_vm.$t("1 - Access ")))]),
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.registerAppURL,
                      target: "_blank",
                      title: _vm.registerAppURL,
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("this link")))]
                ),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(" with your organisation's administrator account")
                    )
                  ),
                ]),
              ]),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "2 - Enter a name for your application (e.g. IperiusONE Azure App)"
                    )
                  )
                ),
              ]),
              _c("p", [
                _vm._v(
                  _vm._s(_vm.$t("3 - Choose the type of account supported"))
                ),
              ]),
              _c("p", [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "4 - Enter a Public client/Native redirect URI with the value: "
                      )
                    )
                  ),
                ]),
                _c("span", [
                  _c("span", [_vm._v(_vm._s(_vm.redirectURI))]),
                  _c("span", {
                    staticClass: "badge mif-copy",
                    on: {
                      click: function ($event) {
                        return _vm.$root.copy(_vm.redirectURI)
                      },
                    },
                  }),
                ]),
              ]),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "5 - Click on the ‘Register’ button to confirm your application"
                    )
                  )
                ),
              ]),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "6 - Fill in the following fields with the information of your newly registered application"
                    )
                  )
                ),
              ]),
            ]
          ),
        ]),
        _vm.serviceAccount.type == _vm.$PlatformsTypes.Cloud_OneDrive
          ? _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v(_vm._s(_vm.$t("User email")))]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.serviceAccount.username,
                    expression: "serviceAccount.username",
                    modifiers: { trim: true },
                  },
                ],
                attrs: { "data-role": "input", type: "text", required: "" },
                domProps: { value: _vm.serviceAccount.username },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.serviceAccount,
                      "username",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(_vm._s(_vm.$t("Required"))),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("Application (client) ID")))]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.serviceAccount.client_id,
                expression: "serviceAccount.client_id",
                modifiers: { trim: true },
              },
            ],
            attrs: { "data-role": "input", type: "text", required: "" },
            domProps: { value: _vm.serviceAccount.client_id },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(
                  _vm.serviceAccount,
                  "client_id",
                  $event.target.value.trim()
                )
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(_vm._s(_vm.$t("Required"))),
          ]),
        ]),
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("Directory (tenant) ID")))]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.serviceAccount.options.tenant_id,
                expression: "serviceAccount.options.tenant_id",
                modifiers: { trim: true },
              },
            ],
            attrs: { "data-role": "input", type: "text", required: "" },
            domProps: { value: _vm.serviceAccount.options.tenant_id },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(
                  _vm.serviceAccount.options,
                  "tenant_id",
                  $event.target.value.trim()
                )
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(_vm._s(_vm.$t("Required"))),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "form-group",
            attrs: {
              disabled:
                _vm.serviceAccount.id == null && _vm.serviceAccount.name == "",
            },
          },
          [
            _c("label", [_vm._v(_vm._s(_vm.$t("Name")))]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.serviceAccount.name,
                  expression: "serviceAccount.name",
                  modifiers: { trim: true },
                },
              ],
              attrs: {
                "data-role": "input",
                type: "text",
                maxlength: "50",
                placeholder: _vm.$t("enter name"),
                "data-prepend": "<span class='mif-pencil'></span>",
              },
              domProps: { value: _vm.serviceAccount.name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.serviceAccount,
                    "name",
                    $event.target.value.trim()
                  )
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.$t("Required"))),
            ]),
          ]
        ),
        _vm.serviceAccount.type.isExchangeOnPremises() &&
        _vm.$session.getRoomPC() == null
          ? _c("div", { staticClass: "form-group" }, [
              _c("label", [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("Select PC to Test Service Account"))),
                ]),
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.testPC,
                      expression: "testPC",
                    },
                  ],
                  staticClass: "select cell-9",
                  attrs: { disabled: _vm.$session.getPCList().length == 0 },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.testPC = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.$session.getPCList(), function (pc) {
                  return _c(
                    "option",
                    {
                      key: pc.id,
                      attrs: {
                        "data-append": pc.note,
                        disabled: !_vm.$root.connectionsStates[pc.id],
                      },
                      domProps: { value: pc },
                    },
                    [_vm._v(" " + _vm._s(pc.name) + " ")]
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-actions d-flex",
          class: _vm.step == null ? "flex-justify-end" : "flex-justify-between",
        },
        [
          _vm.step != null
            ? _c("div", { staticClass: "text-light fg-cobalt" }, [
                _vm._v(_vm._s(_vm.$t(_vm.step))),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex flex-justify-end flex-align-center" },
            [
              _vm.serviceAccount.id != null
                ? _c(
                    "button",
                    {
                      staticClass: "button primary",
                      attrs: { disabled: _vm.testState == _vm.WAIT },
                      on: {
                        click: function ($event) {
                          return _vm.$root.duplicateServiceAccount(
                            _vm.serviceAccount
                          )
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("Duplicate")))]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.testState == _vm.OK,
                      expression: "testState == OK",
                    },
                  ],
                  staticClass: "button primary",
                  attrs: { disabled: "" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("Test account")) + " ")]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.testState == _vm.WAIT,
                      expression: "testState == WAIT",
                    },
                  ],
                },
                [_c("span", { staticClass: "mif-spinner2 ani-spin mr-2" })]
              ),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.testState == _vm.NOTOK,
                      expression: "testState == NOTOK",
                    },
                  ],
                  staticClass: "button primary",
                  attrs: { disabled: !_vm.enableTest },
                  on: { click: _vm.test },
                },
                [_vm._v(" " + _vm._s(_vm.$t("Test account")) + " ")]
              ),
              _c(
                "button",
                {
                  staticClass: "button primary",
                  attrs: { disabled: !_vm.enableSave },
                  on: {
                    click: function ($event) {
                      return _vm.$root.saveServiceAccount(_vm.serviceAccount)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
              ),
              _c(
                "button",
                {
                  staticClass: "button alert",
                  on: {
                    click: function ($event) {
                      return _vm.$root.$emit("CLOSESERVICEACCOUNTDIALOG")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Close")))]
              ),
              _vm.serviceAccount.id != null
                ? _c(
                    "button",
                    {
                      staticClass: "button alert",
                      attrs: { disabled: _vm.testState == _vm.WAIT },
                      on: {
                        click: function ($event) {
                          return _vm.$root.deleteServiceAccount(
                            _vm.serviceAccount
                          )
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("Delete")))]
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }