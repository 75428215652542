<template>
    <div v-on:keyup.esc="$root.$emit('CLOSERESTOREDIALOG')" class="dialog light w-100-sm w-100-md w-75-lg  w-50-xl"
        v-cloak>

        <DialogTitle :title="$t('Restore of database backup')" :defaultTitle="$t('Restore of database backup')"
            :subTitle="$t('SQLServer restore')" @closeDialog="$root.$emit('CLOSERESTOREDIALOG')" />

        <div class="dialog-content p-4">

            <!-- SELEZIONE DELL'ACCOUNT PER RESTORE-->
            <div v-show="step==0" class="container-fluid">

                <!------------------------------SCELTA DEL SERVICE ACCOUNT--------------------------------------------------------------->

                <!--Questa pagina deve implementare il metodo "sendSATestResult"-->
                <SARepoSelect :type=source.type :idSA=source.id_service_account :idRepo="-2"
                    @sendSATestResult="sendSATestResult" />
            </div>

            <!--VISUALIZZA SOLO SPINNER CONNESSIONE E AL FINE CONNECTION SUCCESSFULLY-->
            <div v-show="step==0 && (typeof source.id_service_account) == 'string'" class="container-fluid">
                <div class="row mt-4" v-if="source.dbSource">
                    <span class="mif-done mif-lg fg-green"></span><span>{{ $t('Connected successfully to') }}&nbsp;
                    </span>
                    <span class="small-text">{{ source.dbSource.serverName }}&nbsp;[{{ 'v.' +
                        source.dbSource.serverVersion
                        }}] via {{ source.dbSource.serverId }}</span>
                </div>

                <span v-if="source.dbSource == null" class="mt-4 mif-spinner2 ani-spin mr-1"></span><span
                    v-if="source.dbSource == null">{{ $t('Connection in progress, please wait') }}.</span>
            </div>

            <div class="row mt-4" v-if="source.dbSource"
                v-show="step==1 && selectedAccount!=null && selectedAccount.id!='' ">
                <div class="row mt-4 ml-2">
                    <div class="cell-12">
                        <p>{{ $t('Select the backup file') }}:</p>
                    </div>
                    <div class="cell-8">
                        <input type="text" v-if="customCopyFilePath.length == 0" data-role="input">
                        <input type="text" v-else data-role="input" v-model="customCopyFilePath[0].path" v-path="$PlatformType.FileFolder">
                    </div>
                    <div class="cell-4">
                        <button class="button primary ml-2" v-on:click="toggleFileSystemSidebar()">
                            {{ $t('Select path')}}</button>
                    </div>
                    <div class="cell-8">
                        <input type="radio" data-role="radio" id="NewDatabase" value="NewDatabase" v-model="picked"
                            :data-caption="$t('Make the restore creating a new database with the following name')" />
                    </div>
                    <div class="cell-8">
                        <input type="text" data-role="input" v-model="userDbName" :class="{ 'disabled': picked != 'NewDatabase' }" v-fsitem>
                    </div>
                    <div class="cell-8">
                        <input type="radio" data-role="radio" id="OverwriteDatabase" value="OverwriteDatabase"
                            v-model="picked" :data-caption="$t('Make the restore overwriting an existing database')" />
                    </div>
                    <div class="cell-8">
                        <select data-role="select" data-filter="false" :disabled="picked != 'OverwriteDatabase'"
                            v-model="selectedDB">
                            <option v-for="db in source.dbSource.databases" :key="db.id" :value="db">{{ db.name }}
                            </option>
                        </select>
                    </div>
                </div>

            </div>


            <div v-if="step==2 && restore" class="container-fluid">

                <div class="row mt-4 ml-2">
                    <div class="cell-8">
                        <label>{{ $t("Database") }}: <b>{{ restore.restorename }}</b></label>
                    </div>
                    <div class="cell-8">
                        <label v-if="restore.dt_start_utc != '' && restore.dt_start_utc != null">{{ $t("Start") }}:
                            <b>{{
                                formatDate(restore.dt_start_utc) }}</b></label>
                    </div>
                    <div class="cell-8">
                        <LastResult :lastresult="restore.lastresult" />
                    </div>
                    <!--div class="cell-8">{{ restore.lastresult }}</div-->
                    <div class="cell-8">
                        <div v-show="restore.progress != null && restore.progress != 0">
                            <div class="clear pb-3">
                                <small class="place-left" data-role="hint" data-hint-position="top"
                                    :data-hint-text="restore.current_operation">{{
                                    restore.current_operation
                                    }}</small>
                                <!--small v-else-if="restore.current_operation.length <= 100" class="place-left" data-role="hint"
                                    data-hint-position="top" :data-hint-text="restore.current_operation">{{
                                        restore.current_operation.substr(0, 50) + "..."
                                    }}</small>
                                <small v-else class="place-left" data-role="hint" data-hint-position="top"
                                    :data-hint-text="restore.current_operation">{{
                                        restore.current_operation.substr(0,
                                            50) + "..." + restore.current_operation.substr(-50, 50)
                                    }}</small-->
                            </div>
                            <div data-role="progress" data-type="line" :data-value="restore.progress" data-small="true"
                                data-cls-bar="bg-cobalt" data-role-progress="true" class="progress small">
                                <div class="bar ribbed-cobalt" :style="{ width: restore.progress + '%' }">
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="cell-4">
                        <small v-if="restore.isRunning"
                            class="place-right text-bold enlarge-1">{{restore.progress}}%</small>
                    </div>
                </div>

            </div>




        </div>
        <div class="dialog-actions">
            <div class="ml-auto">
                <button class="button alert" @click="$root.$emit('CLOSERESTOREDIALOG')">{{ $t("Close") }}</button>
                <button class="button" v-if="step < 2" :class="{ 'disabled': step == 0 }" @click="back"><span
                        class="mif-arrow-left"></span> {{ $t("Prev") }}</button>
                <button class="button" v-if="step == 0"
                    :class="{ 'disabled': step == 3 || source.dbSource == null || selectedAccount == null }"
                    @click="next">{{$t("Next") }}<span class="mif-arrow-right" /></button>
                <button class="button primary" v-if="step == 1"
                    :class="{ 'disabled': source.dbSource == null || selectedAccount == null || !enableRestoreButton }"
                    @click="beginRestore">{{ $t("Start restore") }}</button>
            </div>
        </div>
        <!--div class="dialog-actions">
            <div class="ml-auto">
                <button class="button  mr-2" :class="{ 'disabled': steps[0] || steps }" @click="back"><span
                        class="mif-arrow-left"></span> Prev</button>
                <button class="button "
                    :class="{ 'disabled': steps[2] || steps[3] || this.jobselected == '' }"
                    @click="next">Next <span class="mif-arrow-right"> </span> </button>
                <button class="button error float-right ml-2"
                    @click="$root.$emit('CLOSERESTOREDIALOG')">{{ $t('Close') }}</button>
                <button class="button primary ml-2 float-right" v-show="(steps[0] && customCopyFilePath.length > 0) && ((picked == 'NewDatabase' && userDbName != '') || (picked == 'OverwriteDatabase' && selectedDB != null))"
                    @click="beginRestore"><span class="mif-checkmark pr-2"></span>
                    {{ $t('Restore') }}</button>
            </div>
        </div-->

    </div>
</template>
<script>
import DialogTitle from "../../utils/DialogTitle";
import LastResult from "../../utils/LastResult";
import SARepoSelect from "../../utils/SARepoSelect";
import { formatDate } from '../../../utils/utilitiesmodule';

export default {
    name: "DatabaseManualRestore",
    props: {
        source: {
            type: Object,
            required: true
        }

    },
    components: {
        "DialogTitle": DialogTitle,
        "LastResult": LastResult,
        SARepoSelect
    },
    filters: {
       
    },

    data() {
        return {            
            step: 0,

            virtualMachines: [],
            wait: true,
            waitvm: true,
            jobselected: '',
            checkedNames: [],
            id_service_account: -2,
            selectedAccount: {},

            go: false,
            justOneGuid: '',

            restores: null, // socket [GT - 06/06/2023]
            restore: null,

            DatabaseRestoreModel: {
                Id: '',
                JobId: '',
                Version: '',
                IdServiceAccount: '',
                DatabaseToRestorePath: '',
                CreateNewDatabase: false,
                DatabaseToCreateName: '',
                OverWriteExistingDatabase: false,
                DatabaseToOverwriteName: '',
                type: 6,
                type_name: 'Database',
            },

            picked: 'NewDatabase',
            customCopyFilePath: [{
                path :'',
            }],
            userDbName: '',
            selectedDB: null,

            job: {},
            logs: [],

            dataStoreList: null,
            dataStore: null,
            esxiselected: null,

            folder: null,

            connection: null,

            pc_isconnected: '',
            portchech: null,
            port: null,
            virtualMachineName: ''
        }
    },

    /*{
    "bucket_name": null,
    "client_id": null,
    "client_secret": "",
    "id": "46c70ba9-466c-4706-ab7b-5183aa48d1cf",
    "id_master_account": "1d64e95e-2179-48f9-bcd1-f47dba8722df",
    "name": "PC-GIANCARLO\\SQLEXPRESS",
    "options": {
        "lastTestPC": "d60ca0fe-18c1-45f1-bb45-866af21da820",
        "useEncConn": false,
        "useIntSecSSPI": false,
        "usePersistSecInfo": false,
        "useTcpIpNetLib": false,
        "useTrustConn": false,
        "useWinAuth": false
    },
    "password": "sa1234!",
    "port": 1433,
    "refresh_token": "",
    "region": null,
    "status": 1,
    "token": "",
    "type": 60,
    "type_name": "SQL Server",
    "url": "PC-GIANCARLO\\SQLEXPRESS",
    "use_ssl": false,
    "username": "sa"
}*/

    methods: {
        async sendSATestResult(testResult) {
            if (testResult.ok && testResult.okNumber > 0){
                this.selectedAccount = testResult.serviceAccount;
                this.source.dbSource = testResult.list;
            }
        },

        formatDate(date) {
            if(date==null || date =="")return ""
            return formatDate(date, this.$moment);
        },

        enableRestoreButton(){

            if (self.customCopyFilePath[0].path != ''){
                if (self.picked == 'NewDatabase') {
                    if (self.userDbName != '')
                       return true;                                                    
                } else {
                    if (self.selectedDB.name!='')
                        return true;                    
                };
            };

            return false;


        },
       

        async beginRestore() {
            var next = true;
            let self = this;

            self.go = confirm(this.$t("Restore the selected backup?"));

            if (self.go) {

                self.step++;

                self.justOneGuid = self.uuidv4();

                self.DatabaseRestoreModel.Id = self.justOneGuid;
                self.DatabaseRestoreModel.JobId = '';
                self.DatabaseRestoreModel.IdServiceAccount = self.selectedAccount.id;
                self.DatabaseRestoreModel.Version = '';

                if (self.customCopyFilePath.length > 0){
                    self.DatabaseRestoreModel.DatabaseToRestorePath = self.customCopyFilePath[0].path;
                }



                if (self.picked == 'NewDatabase') {
                    self.DatabaseRestoreModel.CreateNewDatabase = true;
                    self.DatabaseRestoreModel.DatabaseToCreateName = self.userDbName
                    self.DatabaseRestoreModel.backupName = self.userDbName;
                } else //OverwriteDatabase
                {
                    self.DatabaseRestoreModel.OverWriteExistingDatabase = true;
                    self.DatabaseRestoreModel.DatabaseToOverwriteName = self.selectedDB.name;
                    self.DatabaseRestoreModel.backupName = self.selectedDB.name;
                }

                self.DatabaseRestoreModel.type = self.selectedAccount.type;
                self.DatabaseRestoreModel.type_name = self.selectedAccount.type_name;


                console.log("[DatabaseManualRestore.beginRestore]");
                // runexchangerestore
                this.$root.socket("RUNDATABASERESTORE", self.DatabaseRestoreModel);

                // invoca agent per dare start ad invio delle informazioni sul restore
                self.idPC = this.$session.getRoomPC().id;
                //###DA INSERIRE IN OGNI COMPONENTE CHE NECESSITA DI CHIAMATE SOCKET###
                // "checkSocket" avvia la connessione socket se ferma
                // "socketEventConnected" viene emesso quando la connessione socket è attiva, quindi nella sua callback si possono avviare tutte le istruzioni che riguardano le chiamate socket
                // "socketEventDisconnected" viene emesso quando la connessione socket non è attiva
                this.$root.$on("socketEventConnected", () => {
                    self.$root.socketConnected = true;
                    self.pc_isconnected = true;
                    /*self.$root.socket("subscribe_pc", null, self.idPC).then(() => 
                        self.$root.socket("STARTSENDINFO")
                    );*/
                });
                this.$root.$on("socketEventDisconnected", () =>{
                    self.$root.socketConnected = false;
                    self.pc_isconnected = false;
                });
                //this.$root.checkSocket(); // avvia la connessione se non è avviata
                // -------------------------------------------------------------------- 


                // subscrive per ricevere il progress del restore ---------------------
                this.$root.$on("addRestoreToPage", restores => {
                    // TODO
                    console.log('Restore in popup');
                    if (restores){
                        console.log(restores);
                        self.restore = restores[self.justOneGuid];
                    }
                    //self.restores = restores;                   

                });
                // --------------------------------------------------------------------
            }
        },

        uuidv4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
                .replace(/[xy]/g, function (c) {
                    const r = Math.random() * 16 | 0,
                        v = c == 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
        },

        /*********************************UTILS**************************************/

        next() {
            this.step++;
        },
        back() {
            this.step--;
        },
        

        toggleFileSystemSidebar() {

            this.$root.$emit("closeFileSystemSidebar");

            var config = {
                type: this.$PlatformsTypes.FileFolder, // sempre numero 8 per selezionare Local File/Folder
                includeFiles: true,
                multipleChoice: false,
                client: null
            };

            this.$root.$emit(
                "toggleFileSystemSidebar",
                this.customCopyFilePath,
                config
            );

        },

    },
    beforeDestroy: function () {
        this.$root.$off("socketEventConnected");
        this.$root.$off("socketEventDisconnected");
        if (this.connection != null) this.connection.stop();
    },
}
</script>