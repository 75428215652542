<template>
    <div class="dialog light w-auto" id="scheduling_dialog">
        <div class="dialog-title pl-10">
             {{$t('Create Scheduling')}}
            <span class="button square closer" @click="$root.$emit('CLOSESCHEDULATIONVIEWDIALOG','')"></span>
        </div>


        <div class="dialog-content overflow bg-light">


            <div class="p-4">

                <div class="row">

                    <div class=" w-100 overflow">
                        <ul data-role="tabs" data-tabs-type="" data-expand="false" data-cls-tabs="bg-gray w-100"
                            style="z-index: 1;">
                            <li class="w-100">
                                <a href="#minutes" @click="toggleTab(cronOptions.minutes)">{{$t("Minutes") }}</a>
                            </li>
                            <li class="w-100">
                                <a href="#hourly" @click="toggleTab(cronOptions.hourly)">{{ $t("Hourly")}}</a>
                            </li>
                            <li class="w-100">
                                <a href="#daily" @click="toggleTab(cronOptions.daily)">{{ $t("Daily") }}</a>
                            </li>
                            <li class="w-100">
                                <a href="#weekly" @click="toggleTab(cronOptions.weekly)">{{ $t("Weekly")}}</a>
                            </li>
                            <li class="w-100">
                                <a href="#montly" @click="toggleTab(cronOptions.monthly)">{{$t("Monthly") }}</a>
                            </li>
                            <li class="w-100">
                                <a href="#yearly" @click="toggleTab(cronOptions.yearly)">{{ $t("Yearly")}} </a>
                            </li>
                        </ul>
                        <div class="border bd-default no-border-top p-4  bg-white">
                            <div id="minutes" class="p-4">
                                <div class="form-row align-items-center">
                                    <div class="col-lg-1">
                                        <label class="col-form-label inline">{{ $t("Every") }}</label>
                                        <select v-model="cronOptions.minutes.value" class="form-control select w-auto"
                                            style="display: inline;">
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="10">10</option>
                                            <option value="15">15</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                        </select>
                                        <label class="col-form-label">{{ $t("minute(s)") }}</label>
                                    </div>


                                </div>
                            </div>
                            <div id="hourly" class="p-4">
                                <div class="card-body" id="idb">

                                    <div class="form-inline ">
                                        <label class="form-check-label">
                                            <input data-role="radio" class="form-check-input mb-1" type="radio"
                                                v-model="cronOptions.hourly.radio"
                                                name="pnlDetails:pnlHourly:radioGroup" value="every/">
                                            {{ $t("Every") }}
                                            <select style="display: inline;" v-model="cronOptions.hourly.value"
                                                class="form-control ml-1 mr-1 select w-auto"
                                                name="pnlDetails:pnlHourly:radioGroup:ddHours">
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="6">6</option>
                                                <option value="12">12</option>
                                            </select> {{ $t("hour(s)") }}</label>
                                    </div>

                                    <div class="form-inline mt-3">
                                        <label class="form-check-label">
                                            <input data-role="radio" class="form-check-input mb-1"
                                                v-model="cronOptions.hourly.radio" type="radio"
                                                name="pnlDetails:pnlHourly:radioGroup" value="at?">
                                            {{ $t("Starts at") }}


                                            <div class="form-inline ">
                                                <select style="display: inline;" v-model="cronOptions.hourly.at.hour"
                                                    class="form-control mr-1 ml-1 select w-auto"
                                                    name="pnlDetails:pnlHourly:radioGroup:pnlTime:hourPart">
                                                    <option v-for="n in 24" :value="n - 1" v-bind:key="n - 1">
                                                        {{ (n - 1).toString().padStart(2, "0") }}
                                                    </option>
                                                </select>
                                                :
                                                <select style="display: inline;" v-model="cronOptions.hourly.at.minute"
                                                    class="form-control ml-1 select w-auto"
                                                    name="pnlDetails:pnlHourly:radioGroup:pnlTime:minutePart">
                                                    <option v-for="n in 60" :value="n - 1" v-bind:key="n - 1">
                                                        {{ (n - 1).toString().padStart(2, "0") }}
                                                    </option>
                                                </select>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div id="daily">
                                <div class="card-body" id="idb">
                                    <div class="form-inline ">
                                        <label class="form-check-label">
                                            <input data-role="radio" v-model="cronOptions.daily.radio"
                                                class="form-check-input mb-1 " type="radio"
                                                name="pnlDetails:pnlDaily:radioGroup" value="everyDay?"
                                                checked="checked">
                                            {{ $t("Everyday") }}</label>
                                    </div>

                                    <div class="form-inline mt-3">
                                        <label class="form-check-input">
                                            <input data-role="radio" v-model="cronOptions.daily.radio"
                                                class="form-check-input mb-1" type="radio"
                                                name="pnlDetails:pnlDaily:radioGroup" value="weekdays?">
                                            {{ $t("Every weekday") }}</label>
                                    </div>


                                    <div class="form-inline mt-4">
                                        {{ $t("Starts at") }} :

                                        <div class="form-inline ">
                                            <select style="display: inline;" v-model="cronOptions.daily.at.hour"
                                                class="form-control mr-1 ml-1 select w-auto"
                                                name="pnlDetails:pnlDaily:pnlTime:hourPart">
                                                <option v-for="n in 24" :value="n - 1" v-bind:key="n - 1">
                                                    {{ (n - 1).toString().padStart(2, "0") }}
                                                </option>
                                            </select>
                                            :
                                            <select style="display: inline;" v-model="cronOptions.daily.at.minute"
                                                class="form-control ml-1 select w-auto"
                                                name="pnlDetails:pnlDaily:pnlTime:minutePart">
                                                <option v-for="n in 60" :value="n - 1" v-bind:key="n - 1">
                                                    {{ (n - 1).toString().padStart(2, "0") }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="weekly">
                                <div class="card-body" id="idb">

                                    <div class="row">
                                        <div class="col-4">
                                            <div class="form-inline ">
                                                <label class="form-check-label">
                                                    <input data-role="checkbox" :data-caption="$t('Monday')"
                                                        v-model="cronOptions.weekly.days" type="checkbox"
                                                        class="form-check-input"
                                                        name="pnlDetails:pnlWeekly:pnlDaysSelected" value="Mon">

                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-4">
                                            <div class="form-inline ">
                                                <label class="form-check-label">
                                                    <input data-role="checkbox" :data-caption="$t('Tuesday')"
                                                        v-model="cronOptions.weekly.days" type="checkbox"
                                                        class="form-check-input"
                                                        name="pnlDetails:pnlWeekly:pnlDaysSelected" value="Tue">

                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-inline ">
                                                <label class="form-check-label">
                                                    <input data-role="checkbox" :data-caption="$t('Wednesday')"
                                                        v-model="cronOptions.weekly.days" type="checkbox"
                                                        class="form-check-input ml-4"
                                                        name="pnlDetails:pnlWeekly:pnlDaysSelected" value="Wed">

                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-4">
                                            <div class="form-inline ">
                                                <label class="form-check-label">
                                                    <input data-role="checkbox" :data-caption="$t('Thursday')"
                                                        v-model="cronOptions.weekly.days" type="checkbox"
                                                        class="form-check-input"
                                                        name="pnlDetails:pnlWeekly:pnlDaysSelected" value="Thu">

                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-inline ">
                                                <label class="form-check-label">
                                                    <input data-role="checkbox" :data-caption="$t('Friday')"
                                                        v-model="cronOptions.weekly.days" type="checkbox"
                                                        class="form-check-input"
                                                        name="pnlDetails:pnlWeekly:pnlDaysSelected" value="Fri">

                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-inline ">
                                                <label class="form-check-label">
                                                    <input data-role="checkbox" :data-caption="$t('Saturday')"
                                                        v-model="cronOptions.weekly.days" type="checkbox"
                                                        class="form-check-input ml-4"
                                                        name="pnlDetails:pnlWeekly:pnlDaysSelected" value="Sat">

                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-4">
                                            <div class="form-inline ">
                                                <label class="form-check-label">
                                                    <input data-role="checkbox" :data-caption="$t('Sunday')"
                                                        v-model="cronOptions.weekly.days" type="checkbox"
                                                        class="form-check-input"
                                                        name="pnlDetails:pnlWeekly:pnlDaysSelected" value="Sun">

                                                </label>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="form-inline mt-2">
                                        {{ $t("Starts at") }} :

                                        <div class="form-inline ">
                                            <select style="display: inline;" v-model="cronOptions.weekly.at.hour"
                                                class="form-control mr-1 ml-1 select w-auto inline"
                                                name="pnlDetails:pnlWeekly:pnlTime:hourPart">
                                                <option v-for="n in 24" :value="n - 1" v-bind:key="n - 1">
                                                    {{ (n - 1).toString().padStart(2, "0") }}
                                                </option>
                                            </select>
                                            :
                                            <select style="display: inline;" v-model="cronOptions.weekly.at.minute"
                                                class="form-control ml-1 select w-auto"
                                                name="pnlDetails:pnlWeekly:pnlTime:minutePart">
                                                <option v-for="n in 60" :value="n - 1" v-bind:key="n - 1">
                                                    {{ (n - 1).toString().padStart(2, "0") }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="montly">
                                <div class="card-body" id="idb">
                                    <div class="form-inline">
                                        <label class="form-check-label">
                                            <input data-role="radio" v-model="cronOptions.monthly.radio"
                                                class="form-check-input mb-1" type="radio"
                                                name="pnlDetails:pnlMonthly:radioGroup" value="fixed" checked="checked">
                                            {{ $t("Day") }}
                                            <input style="display: inline;" v-model="cronOptions.monthly.day"
                                                type="text" class="form-control ml-1 mr-1 input w-auto text-center"
                                                size="2" name="pnlDetails:pnlMonthly:radioGroup:txtDay">
                                            {{ $t("of every") }}
                                            <select style="display: inline;" v-model="cronOptions.monthly.ofEvery"
                                                class="form-control ml-1 mr-1 select w-auto"
                                                name="pnlDetails:pnlMonthly:radioGroup:ddMonth">
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="6">6</option>
                                            </select>
                                            {{ $t("month(s)") }}</label>
                                    </div>
                                    <div class="form-inline mt-3">
                                        <label class="form-check-label">
                                            <input data-role="radio" v-model="cronOptions.monthly.radio"
                                                class="form-check-input mb-1" type="radio"
                                                name="pnlDetails:pnlMonthly:radioGroup" value="recurrent">
                                            {{ $t("The") }}
                                            <select style="display: inline;" v-model="cronOptions.monthly.ddRank"
                                                class="form-control ml-1 mr-1 select w-auto"
                                                name="pnlDetails:pnlMonthly:radioGroup:ddRank">
                                                <option value="first">{{ $t("First") }}</option>
                                                <option value="second">{{ $t("Second") }}</option>
                                                <option value="third">{{ $t("Third") }}</option>
                                                <option value="fourth">{{ $t("Fourth") }}</option>
                                            </select>
                                            <select style="display: inline;" v-model="cronOptions.monthly.ddDay"
                                                class="form-control  mr-1 select w-auto"
                                                name="pnlDetails:pnlMonthly:radioGroup:ddDay">
                                                <option value="mon">{{ $t("Monday") }}</option>
                                                <option value="tue">{{ $t("Tuesday") }}</option>
                                                <option value="wed">{{ $t("Wednesday") }}</option>
                                                <option value="thu">{{ $t("Thursday") }}</option>
                                                <option value="fri">{{ $t("Friday") }}</option>
                                                <option value="sat">{{ $t("Saturday") }}</option>
                                                <option value="sun">{{ $t("Sunday") }}</option>
                                            </select>
                                            {{ $t("of every") }}
                                            <select style="display: inline;" v-model="cronOptions.monthly.ddMonth"
                                                class="form-control ml-1 mr-1 select w-auto"
                                                name="pnlDetails:pnlMonthly:radioGroup:ddMonth">
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="6">6</option>
                                            </select>
                                            {{ $t("month(s)") }}</label>

                                    </div>

                                    <div class="form-inline mt-2">
                                        {{ $t("Starts at") }}:

                                        <div class="form-inline ">
                                            <select style="display: inline;" v-model="cronOptions.monthly.at.hour"
                                                class="form-control mr-1 ml-1 select w-auto"
                                                name="pnlDetails:pnlMonthly:pnlTime:hourPart">
                                                <option v-for="n in 24" :value="n - 1" v-bind:key="n - 1">
                                                    {{ (n - 1).toString().padStart(2, "0") }}
                                                </option>
                                            </select>
                                            :
                                            <select style="display: inline;" v-model="cronOptions.monthly.at.minute"
                                                class="form-control ml-1 select w-auto"
                                                name="pnlDetails:pnlMonthly:pnlTime:minutePart">
                                                <option v-for="n in 60" :value="n - 1" v-bind:key="n - 1">
                                                    {{ (n - 1).toString().padStart(2, "0") }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="yearly">
                                <div class="card-body" id="idb">


                                    <div class="form-inline ">
                                        <label class="form-check-label mr-1">
                                            <input data-role="radio" :data-caption="$t('Every')"
                                                v-model="cronOptions.yearly.radio" class="form-check-input mb-1"
                                                type="radio" name="pnlDetails:pnlYearly:radioGroup" value="fixed"
                                                checked="checked">
                                        </label>
                                        <select style="display: inline;" v-model="cronOptions.yearly.ofEvery"
                                            class="form-control select w-auto"
                                            name="pnlDetails:pnlYearly:radioGroup:ddMonth">
                                            <option value="1">{{ $t("January") }}</option>
                                            <option value="2">{{ $t("February") }}</option>
                                            <option value="3">{{ $t("March") }}</option>
                                            <option value="4">{{ $t("April") }}</option>
                                            <option value="5">{{ $t("May") }}</option>
                                            <option value="6">{{ $t("June") }}</option>
                                            <option value="7">{{ $t("July") }}</option>
                                            <option value="8">{{ $t("August") }}</option>
                                            <option value="9">{{ $t("September") }}</option>
                                            <option value="10">{{ $t("October") }}</option>
                                            <option value="11">{{ $t("November") }}</option>
                                            <option value="12">{{ $t("December") }}</option>
                                        </select>
                                        <input style="display: inline-grid;" v-model="cronOptions.yearly.day"
                                            type="text" class="form-control ml-1 mr-1 input w-auto text-center" size="2"
                                            name="pnlDetails:pnlYearly:radioGroup:txtDay">

                                    </div>

                                    <div class="form-inline mt-3">
                                        <label class="form-check-label">
                                            <input data-role="radio" :data-caption="$t('The')"
                                                v-model="cronOptions.yearly.radio" class="form-check-input mb-1"
                                                type="radio" name="pnlDetails:pnlYearly:radioGroup" value="recurrent">

                                            <select style="display: inline;" v-model="cronOptions.yearly.ddRank"
                                                class="form-control ml-1 select w-auto"
                                                name="pnlDetails:pnlYearly:radioGroup:ddRank">
                                                <option value="first">{{ $t("First") }}</option>
                                                <option value="second">{{ $t("Second") }}</option>
                                                <option value="third">{{ $t("Third") }}</option>
                                                <option value="fourth">{{ $t("Fourth") }}</option>
                                            </select>
                                            <select style="display: inline;" v-model="cronOptions.yearly.ddDay"
                                                class="form-control ml-1 mr-1 select w-auto"
                                                name="pnlDetails:pnlYearly:radioGroup:ddDay">
                                                <option value="mon">{{ $t("Monday") }}</option>
                                                <option value="tue">{{ $t("Tuesday") }}</option>
                                                <option value="wed">{{ $t("Wednesday") }}</option>
                                                <option value="thu">{{ $t("Thursday") }}</option>
                                                <option value="fri">{{ $t("Friday") }}</option>
                                                <option value="sat">{{ $t("Saturday") }}</option>
                                                <option value="sun">{{ $t("Sunday") }}</option>
                                            </select>
                                            {{ $t("of") }}
                                        </label>
                                        <select style="display: inline;" v-model="cronOptions.yearly.ddMonth"
                                            class="form-control ml-1 select w-auto"
                                            name="pnlDetails:pnlYearly:radioGroup:ddMonth">
                                            <option value="1">{{ $t("January") }}</option>
                                            <option value="2">{{ $t("February") }}</option>
                                            <option value="3">{{ $t("March") }}</option>
                                            <option value="4">{{ $t("April") }}</option>
                                            <option value="5">{{ $t("May") }}</option>
                                            <option value="6">{{ $t("June") }}</option>
                                            <option value="7">{{ $t("July") }}</option>
                                            <option value="8">{{ $t("August") }}</option>
                                            <option value="9">{{ $t("September") }}</option>
                                            <option value="10">{{ $t("October") }}</option>
                                            <option value="11">{{ $t("November") }}</option>
                                            <option value="12">{{ $t("December") }}</option>
                                        </select>

                                    </div>


                                    <div class="form-inline mt-2">
                                        {{ $t("Starts at") }} :

                                        <div class="form-inline ">
                                            <select style="display: inline;" v-model="cronOptions.yearly.at.hour"
                                                class="form-control mr-1 ml-1 select w-auto"
                                                name="pnlDetails:pnlYearly:pnlTime:hourPart">
                                                <option v-for="n in 24" :value="n - 1" v-bind:key="n - 1">
                                                    {{ (n - 1).toString().padStart(2, "0") }}
                                                </option>
                                            </select>
                                            :
                                            <select style="display: inline;" v-model="cronOptions.yearly.at.minute"
                                                class="form-control ml-1 select w-auto"
                                                name="pnlDetails:pnlYearly:pnlTime:minutePart">
                                                <option v-for="n in 60" :value="n - 1" v-bind:key="n - 1">
                                                    {{ (n - 1).toString().padStart(2, "0") }}
                                                </option>
                                            </select>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <ul class="listview">
                    <li v-for="(sched, index) in scheduling.filter(s => s != '')" v-bind:key="index"
                        class="bg-dark fg-white m-4 p-4 rounded">
                        <span class=" mif-alarm "></span>
                        {{ cronstrue.toString(sched, { locale:$i18n.locale, dayOfWeekStartIndexZero: false}) }}
                        <!--  <span class="second-label">Next :{{cronParser.parseExpression('*/2 * * * *').next().toString()}} </span> -->
                        <a @click="removeSched(index)" class="second-action mif-bin "></a>
                    </li>

                </ul>
            </div>

        </div>
        <div class="dialog-actions">
            <button class="button mr-3 primary" @click="refreshSched()">{{ $t("Save Scheduling") }}</button>
        </div>

    </div>
</template>
<script>


export default {
    name: "SchedulationDialog",
    props: {
        //cronSchedulation: String,
    },
    components: {
        
    },
    data() {
        return {
            scheduling:[],
             //Scheduling
            cronstrue: null,
            cronParser: null,
            cronOptions: {
                minutes: {
                    istab: "minutes/",
                    active: true,
                    value: 3, //il valore sono i minuti di intervallo
                },
                hourly: {
                    istab: "hourly/",
                    active: false,
                    value: 1,
                    radio: "every/", // every/ o at? 
                    at: {
                        hour: 12,
                        minute: 0,
                    }
                },
                daily: {
                    istab: "daily/",
                    active: false,
                    radio: "everyDay?", // everyDay? o weekdays?
                    at: {
                        hour: 12,
                        minute: 0,
                    }
                },
                weekly: {
                    istab: "weekly?",
                    active: false,
                    days: [],
                    at: {
                        hour: 12,
                        minute: 0,
                    }
                },
                monthly: {
                    istab: "monthly/",
                    active: false,
                    radio: "fixed", // fixed o recurrent
                    day: 1,
                    ofEvery: 1,
                    ddRank: "first",
                    ddDay: "mon",
                    ddMonth: 1,
                    at: {
                        hour: 12,
                        minute: 0,
                    }
                },
                yearly: {
                    istab: "yearly/",
                    active: false,
                    radio: "fixed",
                    day: 1,
                    ofEvery: 1,
                    ddRank: "first",
                    ddDay: "mon",
                    ddMonth: 1,
                    // es: /first/tue/ofEvery/3
                    at: {
                        hour: 12,
                        minute: 0,
                    }
                },
            },
        }
    },

    created() {
        self.cronstrue = require('cronstrue/i18n');
        self.cronParser = require('cron-parser');
    },
    mounted(){
        self.cronstrue = require('cronstrue/i18n');
        self.cronParser = require('cron-parser');
    },
    methods:{
        toggleTab(objectToActivate) {
            //li resetto tutti...
            this.cronOptions.minutes.active = false;
            this.cronOptions.hourly.active = false;
            this.cronOptions.daily.active = false;
            this.cronOptions.weekly.active = false;
            this.cronOptions.monthly.active = false;
            this.cronOptions.yearly.active = false;
            //attivo quello selezionato
            objectToActivate.active = !objectToActivate.active;

        },

        generateCronFromRestString(restString) {
            // Rimuoviamo eventuali slash iniziali
            restString = restString.startsWith('/') ? restString.slice(1) : restString;

            // Suddividiamo l'URL in parti prima di "?"
            const urlParts = restString.split('?');
            const path = urlParts[0]; // La parte prima del "?"
            const query = urlParts[1] ? new URLSearchParams(urlParts[1]) : null;

            // Suddividiamo il path in parti
            const parts = path.split('/');
            const type = parts[0]; // Il tipo (yearly, monthly, daily, hourly, etc.)

            switch (type) {
                case 'minutes':
                    const minuteInterval = parts[1]; // es: every/2
                    return `0 0/${minuteInterval} * 1/1 * ? *`;

                case 'hourly':
                    if (parts[1] === 'at') {
                        // Estraiamo i parametri della query string (hour e minute)
                        const hour = query.get('hour');
                        const minute = query.get('minute');
                        return `0 ${minute} ${hour} 1/1 * ? *`;
                    } else if (parts[1] === 'every') {
                        const hourInterval = parts[2]; // es: every/1
                        return `0 0 0/${hourInterval} 1/1 * ? *`;
                    }
                    break;

                case 'daily':
                    if (parts[1] === 'everyDay') {
                        const hour = query.get('hour');
                        const minute = query.get('minute');
                        return `0 ${minute} ${hour} 1/1 * ? *`;
                    } else if (parts[1] === 'weekdays') {
                        const hour = query.get('hour');
                        const minute = query.get('minute');
                        return `0 ${minute} ${hour} ? * MON-FRI *`; // Settimana lavorativa
                    }
                    break;

                case 'weekly':
                    const days = query.get('days');
                    return `0 0 12 ? * ${days.toUpperCase()} *`;

                case 'monthly':
                    if (parts[1] === 'day') {
                        const day = parts[2];
                        const frequency = parts[4];
                        return `0 0 12 ${day} 1/${frequency} ? *`;
                    } else if (['first', 'second', 'third', 'fourth'].includes(parts[1])) {
                        const weekPosition = { 'first': 1, 'second': 2, 'third': 3, 'fourth': 4 }[parts[1]];
                        const dayOfWeek = parts[2];
                        const frequency = parts[4];
                        const hour = query.get('hour');
                        const minute = query.get('minute');
                        return `0 ${minute} ${hour} ? 1/${frequency} ${dayOfWeek.toUpperCase()}#${weekPosition} *`;
                    }
                    break;

                case 'yearly':
                    if (parts[1] === 'atDay') {
                        // Gestiamo il caso specifico /yearly/atDay/X/ofEvery/Y
                        const dayOfMonth = parts[2];
                        const frequency = parts[4];
                        const hour = query.get('hour');
                        const minute = query.get('minute');
                        return `0 ${minute} ${hour} ${dayOfMonth} ${frequency} ? *`;
                    } else if (['first', 'second', 'third', 'fourth'].includes(parts[1])) {
                        const weekPosition = { 'first': 1, 'second': 2, 'third': 3, 'fourth': 4 }[parts[1]];
                        const dayOfWeek = parts[2];
                        const month = parts[4];
                        const hour = query.get('hour');
                        const minute = query.get('minute');
                        return `0 ${minute} ${hour} ? ${month} ${dayOfWeek.toUpperCase()}#${weekPosition} *`;
                    }
                    break;

                default:
                    throw new Error('Tipo di cron non supportato.');
            }
        },

        removeSched(index) {
            this.scheduling.splice(index, 1);
        },

        createStringRest() {
            //return "monthly/day/21/ofEvery/3";
            for (const property in this.cronOptions) {
                console.log(property + " : " + this.cronOptions[property]);
                var string = "";
                if (this.cronOptions[property]["active"]) {

                    if (this.cronOptions[property]["istab"] == "minutes/") {
                        string = this.cronOptions[property]["istab"] + this.cronOptions[property]["value"];
                        return string;
                    }
                    if (this.cronOptions[property]["istab"] == "hourly/") {
                        string = this.cronOptions[property]["istab"] +
                            (this.cronOptions[property]["radio"] == "every/" ? "every/" + this.cronOptions[property]["value"] : "at?hour=" + this.cronOptions[property]["at"]["hour"] + "&minute=" + this.cronOptions[property]["at"]["minute"]);
                        return string;
                    }
                    if (this.cronOptions[property]["istab"] == "daily/") {
                        string = this.cronOptions[property]["istab"] + this.cronOptions[property]["radio"] + "hour=" + this.cronOptions[property]["at"]["hour"] + "&minute=" + this.cronOptions[property]["at"]["minute"];
                        return string;
                    }
                    if (this.cronOptions[property]["istab"] == "weekly?") {
                        string = this.cronOptions[property]["istab"] + "days=" + this.cronOptions[property]["days"] + "&hour=" + this.cronOptions[property]["at"]["hour"] + "&minute=" + this.cronOptions[property]["at"]["minute"];
                        return string;
                    }
                    if (this.cronOptions[property]["istab"] == "monthly/") {
                        string = this.cronOptions[property]["istab"] +
                            (this.cronOptions[property]["radio"] == "fixed" ? "day/" + this.cronOptions[property]["day"] + "/ofEvery/" + this.cronOptions[property]["ofEvery"] + "?hour=" + this.cronOptions[property]["at"]["hour"] + "&minute=" + this.cronOptions[property]["at"]["minute"] :
                                this.cronOptions[property]["ddRank"] + "/" + this.cronOptions[property]["ddDay"] + "/ofEvery/" + this.cronOptions[property]["ddMonth"] + "?hour=" + this.cronOptions[property]["at"]["hour"] + "&minute=" + this.cronOptions[property]["at"]["minute"]);
                        console.log(string);
                        return string;
                    }
                    if (this.cronOptions[property]["istab"] == "yearly/") {
                        string = this.cronOptions[property]["istab"] +
                            (this.cronOptions[property]["radio"] == "fixed" ? "atDay/" + this.cronOptions[property]["day"] + "/ofEvery/" + this.cronOptions[property]["ofEvery"] + "?hour=" + this.cronOptions[property]["at"]["hour"] + "&minute=" + this.cronOptions[property]["at"]["minute"] :
                                this.cronOptions[property]["ddRank"] + "/" + this.cronOptions[property]["ddDay"] + "/ofEvery/" + this.cronOptions[property]["ddMonth"] + "?hour=" + this.cronOptions[property]["at"]["hour"] + "&minute=" + this.cronOptions[property]["at"]["minute"]);
                        console.log(string);
                        return string;
                    }
                }
            }
        },

        async refreshSched() {
            var self = this;
            self.cronstrue??= require('cronstrue/i18n');
            self.cronParser??= require('cron-parser');
            var stringpath = this.createStringRest();
            console.log(stringpath);
            var newSched = this.generateCronFromRestString("/" + stringpath)
            console.log(newSched);
            self.$root.$emit('CLOSESCHEDULATIONVIEWDIALOG', newSched);
            /*if (newSched != null) {
                self.scheduling.push(newSched);
            } */

        },
    }
}
</script>