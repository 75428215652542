<template>
    <div class="w-100 overflow">
        <Navigation pageName="policies" />
        <div v-if="policies == null" class="d-flex flex-justify-center  p-5">
            <span class="mif-spinner2 fg-primary ani-spin mif-3x " />
        </div>

        <NoDataAvailable v-else-if="policies.length == 0" :message="$t('No policies selected')" />

        <div v-else class="p-5">
            <table class="table table-border compact striped bg-white m-0" :data-check=false data-check-name="chkBox"
                :data-locale="$i18n.locale"
                :data-table-search-title='$t("Search Policy")' data-cls-search="w-100-sm w-25-xl w-25-lg w-50-md"
                data-show-activity="true" data-cell-wrapper="false" data-role="table" data-rows="20"
                data-show-rows-steps="false"  :data-show-pagination="policies.length > 20 ? 'true' : 'false'" data-horizontal-scroll="true">
                <thead>
                    <tr>
                        <th class="sortable-column">{{ $t("Name") }}</th>
                        <th class="sortable-column">{{ $t("Type") }}</th>
                        <th data-cls-column="text-center  no-wrap" class="text-center no-wrap">{{ $t("Created at") }}
                        </th>
                        <th data-cls-column="text-center  no-wrap" class="text-center no-wrap">{{ $t("Last Update") }}
                        </th>
                       
                        <th></th>

                    </tr>
                </thead>
                <tbody>

                    <tr v-for="policy in policies" class="mx-8 p-4  rounded m-6" v-bind:key="policy.id">
                        <td>
                            <a class="text-bold text-left m-0 button flat-button" :href="'#' + policy.id">
                                {{ policy.name }}
                            </a>
                        </td>
                        <td>
                            <label class="badge inline bg-cobalt fg-white p-1  mr-1">{{ policy.name_type }}</label>
                        </td>

                        <td>
                            {{ formatDate(policy.dt_creation) }}
                        </td>
                        <td>
                            {{ formatDate(policy.dt_last_edit) }}
                        </td>
                      <!--   <td class="no-wrap">
                            <span class="mif-2x mif-email mr-4" :class="{ 'fg-gray': policy.enabled_email==false }"></span> 
                            <span class="mif-2x mif-mobile" :class="{ 'fg-gray': policy.enabled_push==false }"></span>
                        </td> -->
                        <td>
                            <ul class="listview flex-justify-end d-flex">
                                <router-link :to="'#' + policy.id" class="fg-dark">
                                    <li class="button flat-button">
                                        <span class="mif-pencil"></span>
                                    </li>
                                </router-link>
                                <router-link :to="'#' + policy.id + '/delete'" class="fg-dark">
                                    <li class="button flat-button "> 
                                        <span class="mif-bin"></span>
                                    </li>
                                </router-link>
                            </ul>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</template>
<script>

import Navigation from './utils/Navigation.vue';
import NoDataAvailable from "./utils/NoDataAvailable.vue";
import {
    formatDate,
} from "../utils/utilitiesmodule";
export default {
    name: "Policies",
    components: {
        Navigation,
        NoDataAvailable,
    },
    watch: {
        '$route': {
            handler: function(to, from) {
                if (to.hash&&to.hash.endsWith("/delete")) {
                    var polID=to.hash.split("/")[0].replace("#", "");
                    var policy=this.policies.find(p => p.id==polID);
                    if (policy!=null) this.deletePolicy(policy);
                    return;
                }
                if (to.hash) {
                    var polID=to.hash.replace("#", "");
                    this.openDialogPolicy(polID)
                }
            },
            deep: false,
            immediate: true
        }
    },
    data() {
        return {
            policies: null,
            filteredpolicies: null
        }
    },
    mounted: async function() {
        this.$session.setRoomPC(null);
        this.$root.socket("SETPC", null);
        let self=this;
        if (this.$root.checkAuth()) {
            self.policies=await self.$api.getPolicies();
            self.$session.setPolicies(self.policies);
        }
        this.$root.$on('deletePolicy', policy => self.deletePolicy(policy));
        this.$root.$on("refreshPolicies", self.refreshPolicies);
    },
    unmounted: function() {
        this.$root.$off('deletePolicy');
        this.$root.$off("refreshPolicies");
    },


    methods: {
        refreshPolicies(){
            let self = this;
            this.$api.getPolicies().then(policies => {
                self.policies = policies;
                self.$session.setPolicies(self.policies);
                window.location.reload();
            });
        },
        logNavigation(event) {
            console.log('Navigating to:', { name: 'Policies', params: { idPolicy: this.policy.id } });
        },

        formatDate(date) {
            return formatDate(date, this.$moment);
        },

        async openDialogPolicy(id_policy) {
            var policy=await this.$api.getPolicy(id_policy);
            this.$root.$emit("OPENPOLICYVIEWDIALOG", policy, this.$session.getPCList());
        },

        /*  filteredList(data) {
             this.filteredpolicies = this.policies;
             this.filteredpolicies = this.policies.filter((group) => {
                 return group.name_group.toLowerCase().includes(data.toLowerCase())
             });
         }, */

        async deletePolicy(policy) {
            //Personalizzo la dialog da mostrare
            var dialogID=Math.floor(100000+Math.random()*900000).toString();
            var dialogTitle=this.$t("Delete Policy");
            var dialogContent=this.$t('deleteConfirmation', { 0: policy.name });

            let self=this;
            this.$root.$on(dialogID, async ok => {
                var closeDialog=true;
                try {
                    if (!ok) {
                        return;
                    }

                    ok=await self.$api.deletePolicy(policy.id);

                    if (!ok) {
                        closeDialog=false;
                        return;
                    }

                    self.$session.deletePolicy(policy.id);
                    this.$root.$emit('CLOSEPOLICYVIEWDIALOG');
                    self.refreshPolicies();

                } finally {
                    if (closeDialog) {
                        self.$root.$off(dialogID);
                        self.$router.removeHash(); // prototype implementato in main.js
                        //location.reload();
                    }
                }
            });

            //Avvio la richiesta che mi risponderà con un $emit
            this.$root.openYesNoDialog(dialogID, dialogTitle, dialogContent)
        }


    }
}
</script>
