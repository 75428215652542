import { cookieManager, createErrorObject, isValid, waitSeconds } from "../utils/utilitiesmodule";
import "../utils/messages";
import { BACKUP_MESSAGES, EMAILPROVIDERS_NOT_RETRIEVED, LOGIN_FAILED, LOGOUT_FAILED, PC_MESSAGES, PRICING_NOT_RETRIEVED, REGIONS_NOT_RETRIEVED, ROLES_NOT_RETRIEVED, SERVICEACCOUNT_MESSAGES, STATS_NOT_RETRIEVED, USER_MESSAGES, REPOSITORY_MESSAGES, GROUP_MESSAGES, SESSIONS_MESSAGES, TFA_MESSAGES, CONTROLS_NOT_RETRIEVED, COCOD_ERROR_CRYPT, STRING_ERROR_CRYPT, LIC_EXT_INFO_ERR, GET_BACKUP_STATS_ERR, POLICIES_NOT_RETRIEVED, POLICY_MESSAGES, IPERIUSREMOTE_MESSAGE, TYPES_NOT_RETRIEVED } from "../utils/messages";
import qs from 'querystring';
import moment from "moment";
// Importa il modulo crypto
/******************************************SESSIONE****************************************************************************/
export function assignPrototypesToAPI(api) {

    //-------------------------LOGIN--------------------------------------
    api.login=async function(username, password) {
        var config={
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        };

        var response=await this.post('/oauth/token', qs.stringify({ "username": username, "password": password }), config)
            .then(res => {
                if (res.status==400) {
                    console.log(res.data.message);
                    this.createToastFunction(this.translateFunction(LOGIN_FAILED), 3000, "alert");
                    return null;
                }
                return res.data;
            }).catch(e => {
                this.createToastFunction(this.translateFunction(LOGIN_FAILED), 3000, "alert");
                console.log(e);
                return null;
            });

        return response;
    }


    /*
    {
        "access_token": "xzrVbMM9LswKd5-ixRtzfQDXGjAjELAKuA0N5WW_AfU",
        "dataora_scadenza": "2033-04-07T00:00:00Z",
        "email": "a.foresi@entersrl.it",
        "firstname": "Andrea",
        "id_master_account": "1d64e95e-2179-48f9-bcd1-f47dba8722df",
        "id_user": "3c5b0568-73e7-47cd-9cd5-109349608e0b",
        "lastname": "Foresi",
        "level": "2",
        "ma_username": "m_test",
        "nome_licenza": "Small",
        "roles": "Admin",
        "username": "op_test"
    }
    */
    // login= async function(username, password) {

    //     var config = {
    //         withCredentials: true,
    //         credentials: 'include',
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded',
    //         }
    //     };

    //     var response = await api.post('/oauth/token', qs.stringify({ "username": username, "password": password }), config)
    //         .then(res => {
    //             if (res.status == 400) {
    //                 console.log(res.data.message);
    //                 return null;
    //             }
    //             return res.data;
    //         }).catch(e => {
    //             console.log(e);
    //             return null;
    //         });

    //     return response;
    // }

    api.logout=async function(config) {
        var response=await api.post('/auth/logout', config)
            .then(() => {
                return true
            })
            .catch(e => {
                this.createToastFunction(this.translateFunction(LOGOUT_FAILED), 3000, "alert");
                console.log(e);
                return false;
            });
        return response;
    }
    api.loginTFA=async function(TFACode) {
        var config={
            headers: {
                'Content-Type': 'application/json',
            }
        }

        var body={
            "totp_code": TFACode

        };

        var response=await api.post('/oauth/mfa/login', body, config)
            .then(res => {
                if (res.status==400) {
                    console.log(res.data.message);
                    this.createToastFunction(this.translateFunction(LOGIN_FAILED), 3000, "alert");
                    return null;
                }
                return res.data;
            }).catch(e => {
                console.log(LOGIN_FAILED);
                console.log(e);
                this.createToastFunction(this.translateFunction(LOGIN_FAILED), 3000, "alert");
                return null;
            });

        return response;
    }

    api.loginTFARecovery=async function(TFACodeRecovery) {
        var config={
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        };

        var body=qs.stringify({ "RecoveryCode": TFACodeRecovery });

        var response=await api.post('/auth/recovery', body, config)
            .then(res => {
                if (res.status==400) {
                    console.log(res.data.message);
                    this.createToastFunction(this.translateFunction(LOGIN_FAILED), 3000, "alert");
                    return null;
                }
                return res.data;
            }).catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(LOGIN_FAILED), 3000, "alert");
                return null;
            });

        return response;
    }

    api.getEvents=async function() {
        var response=await api.get('/enterprise/events', this.buildConfig())
            .then(res => {
                if (res.status==400) {
                    console.log(res.data.message);
                    this.createToastFunction(this.translateFunction(SESSIONS_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                    return [];
                }

                return res.data;
            }).catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(SESSIONS_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                return [];
            });

        return response;


    }
    // sessioni e statistiche di Iperius Remote
    api.getSessions=async function(stats) {

        var body={
            'id_group': stats.id_group,
            'id_user': stats.id_user, //stats["id_user"],
            'dt_inf': moment(stats.dt_inf).format("YYYYMMDDhhmmss"),
            'dt_sup': moment(stats.dt_sup).format("YYYYMMDDhhmmss"),
            'id_host': "",
            "include_stats": stats.include_stats? stats.include_stats:"1"
        };

        var response=await api.put('/sessions', body, this.buildConfig())
            .then(res => {
                if (res.status==400) {
                    console.log(res.data.message);
                    this.createToastFunction(this.translateFunction(SESSIONS_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                    return [];
                }

                return res.data;
            }).catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(SESSIONS_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                return [];
            });

        return response;
    }

    api.getSessionsLight=async function() {


        var response=await api.get('/sessions?limit=30', this.buildConfig())
            .then(res => {
                if (res.status==400) {
                    console.log(res.data.message);
                    this.createToastFunction(this.translateFunction(SESSIONS_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                    return [];
                }

                return res.data;
            }).catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(SESSIONS_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                return [];
            });

        return response;



    }
    api.getBackupStats=async function(stats) {
        var url='enterprise/stats/backup';
        url+='?dt_start='+moment(stats.dt_inf).format("YYYYMMDDhhmmss");
        url+='&dt_end='+moment(stats.dt_sup).format("YYYYMMDDhhmmss");
        url+='&idg='+stats.id_group;
        var response=await api.get(url, this.buildConfig())
            .then(res => {
                if (res.status==400) {
                    console.log(res.data.message);
                    return null;
                }

                return res.data;
            }).catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(GET_BACKUP_STATS_ERR), 3000, "alert");
                return null;
            });

        return response;

    }

    /*************************************************TFA*********************************************************************/
    api.toggleTFA=async function() {
        var response=await api.get('/accounts/auth/mfa', this.buildConfigTFA())
            .then(res => {
                console.log(res.headers['Cookie']);
                return res.data.auth_uri;
            })
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(TFA_MESSAGES.NOT_ENABLED), 3000, "alert");
                return false;
            });

        return response;
    }

    api.enableTFA=async function(code) {
        var data={
            'validation_totp': code,
        };

        var response=await api.post('/accounts/auth/mfa/enable', data, this.buildConfigTFA())
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(TFA_MESSAGES.NOT_ENABLED), 3000, "alert");
                return false;
            });

        return response;
    }

    api.disableTFA=async function() {
        var response=await api.get('/accounts/auth/mfa/disable', this.buildConfigTFA())
            .then(res => {
                console.log(res);
                return true;
            })
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(TFA_MESSAGES.NOT_DISABLED), 3000, "alert");
                return false;
            });

        return response;
    }

    api.confirmTFA=async function(TFACode) {
        var config=this.buildConfig();
        config.headers['Content-Type']='application/x-www-form-urlencoded';
        var response=await api.post(
            '/accounts/auth/options/mfa',
            qs.stringify({ Code: TFACode }),
            config
        ).then(res => res.data)
            .catch(e => {
                console.log(e);
                return null;
            });

        return response;
    }

    api.getLocalPublicIP=async function() {
        var response=await api.get("https://api.ipify.org?format=json")
            .then(res => res.data.ip)
            .catch(e => {
                console.log(e);
                return null;
            });

        return response;
        /*$.getJSON("https://api.ipify.org?format=json",
            function(data) {
     
                    // Displayin IP address on screen
                alert(data.ip);
        });*/
    }
    //}








    /******************************************SERVICE ACCOUNTS******************************************************************/
    //export const serviceAccountAPI = {
    api.getServiceAccounts=async function() {
        var response=await api.get('/enterprise/svcaccounts', this.buildConfig())
            .then(res => res.data.map(x => {
                if (!isValid(x.type_name)||x.type_name.toLowerCase()=="unknown")
                    x.type_name=this.$platformsTypesInfo[x.type].name;
                return x;
            }))
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(SERVICEACCOUNT_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                return [];
            });

        return response;
    }
    api.createServiceAccount=async function(serviceAccount) {
        if (!serviceAccount.port)
            serviceAccount.port=0;
        if (!serviceAccount.status)
            serviceAccount.status=1;
        var response=await api.post('/enterprise/svcaccounts/', serviceAccount, this.buildConfig())
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                return null;
            });
        if (response==null)
            this.createToastFunction(this.translateFunction(SERVICEACCOUNT_MESSAGES.NOT_CREATED, { 0: serviceAccount.name }), 3000, "alert");
        else
            this.createToastFunction(this.translateFunction(SERVICEACCOUNT_MESSAGES.CREATED, { 0: serviceAccount.name }), 3000, "success");

        return response;
    }
    api.editServiceAccount=async function(serviceAccount) {
        if (!serviceAccount.port)
            serviceAccount.port=0;
        if (!serviceAccount.status)
            serviceAccount.status=1;
        var response=await api.put('/enterprise/svcaccounts/', serviceAccount, this.buildConfig())
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                return null;
            });
        if (response==null)
            this.createToastFunction(this.translateFunction(SERVICEACCOUNT_MESSAGES.NOT_MODIFIED, { 0: serviceAccount.name }), 3000, "alert");
        else
            console.log(this.translateFunction(SERVICEACCOUNT_MESSAGES.MODIFIED, { 0: serviceAccount.name }), 3000, "success");

        return response;
    }
    api.deleteServiceAccount=async function(saID, saName) {
        var response=await api.delete('/enterprise/svcaccounts/'+saID, this.buildConfig())
            .then(() => true)
            .catch(e => {
                console.log(e);
                if (e.status==404)
                    return true;
                return false;
            });
        if (response)
            this.createToastFunction(this.translateFunction(SERVICEACCOUNT_MESSAGES.DELETED, { 0: saName }), 3000, "success");
        else
            this.createToastFunction(this.translateFunction(SERVICEACCOUNT_MESSAGES.NOT_DELETED, { 0: saName }), 3000, "alert");

        return response;
    }

    /**********************************************REPOSITORIES*****************************************************************/

    //export const repositoryAPI = {
    api.getRepositories=async function() {
        var response=await api.get('/enterprise/repositories', this.buildConfig())
            .then(res => res.data.map(x => {
                if (!isValid(x.type_name)||x.type_name.toLowerCase()=="unknown")
                    x.type_name=this.$platformsTypesInfo[x.type].name;
                return x;
            }))
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(REPOSITORY_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                return [];
            });

        return response;
    }

    api.createRepository=async function(repository) {

        var response=await api.post('/enterprise/repositories', repository, this.buildConfig())
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                return null;
            });
        if (response==null)
            this.createToastFunction(this.translateFunction(REPOSITORY_MESSAGES.NOT_CREATED, { 0: repository.name }), 3000, "alert");
        else
            this.createToastFunction(this.translateFunction(REPOSITORY_MESSAGES.CREATED, { 0: repository.name }), 3000, "success");

        return response;
    }

    api.editRepository=async function(repository) {
        var response=await api.put('/enterprise/repositories', repository, this.buildConfig())
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                return null;
            });
        if (response==null)
            this.createToastFunction(this.translateFunction(REPOSITORY_MESSAGES.NOT_MODIFIED, { 0: repository.name }), 3000, "alert");
        else
            this.createToastFunction(this.translateFunction(REPOSITORY_MESSAGES.MODIFIED, { 0: repository.name }), 3000, "success");

        return response;
    }

    api.deleteRepository=async function(repoID, repoName) {
        var response=await api.delete('/enterprise/repositories/'+repoID, this.buildConfig())
            .then(() => true)
            .catch(e => {
                if (e.status==404)
                    return true;
                console.log(e);
                return false;
            });
        if (response)
            this.createToastFunction(this.translateFunction(REPOSITORY_MESSAGES.DELETED, { 0: repoName }), 3000, "success");
        else
            this.createToastFunction(this.translateFunction(REPOSITORY_MESSAGES.NOT_DELETED, { 0: repoName }), 3000, "alert");

        return response;
    }

    api.getAmazonRegions=async function() {
        var response=await api.get('/enterprise/awsregions', this.buildConfig())
            .then(response => {
                var regions=response.data.filter(region => region.ForType==undefined);

                return regions;
            })
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(REGIONS_NOT_RETRIEVED), 3000, "alert");
                return null;
            });

        return response;
    }

    api.getExchangeRegions=async function() {
        var response=await api.get('/enterprise/awsregions', this.buildConfig())
            .then(response => {
                var regions=response.data.filter(region => isValid(region.ForType));

                return regions;
            })
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(REGIONS_NOT_RETRIEVED), 3000, "alert");
                return null;
            });

        return response;
    }
    //}


    /****************************************PC*********************************************************/
    //export const pcAPI = {

    // Funzione per convertire una stringa in UTF-8
    api.toUtf8=function(str) {
        return unescape(encodeURIComponent(str));
    }

    api.controlConnectHost=async function(control, remoteId) {
        const data={
            id_remote: control.idRemote.value,
            password_remote: control.passwordRemote,
            gateway_id: '1',
            target_host_id: remoteId,
            version_remote: control.version_remote,
            country: control.country,
            language: control.language,
            ipaddress_lan: control.ipaddress_lan,
            ipaddress_public: control.ipaddress_public,
            os_version_number: control.os_version_number,
            os_version_full: control.os_version_full,
            computer_name: control.computer_name,
            computer_descr: control.descr,
            organization_name: control.organization_name,
            device: control.device,
            device_guid: control.device_guid,
            remote_guid: control.remote_guid,
            master_account_id: control.operatorid,
            username: control.username,
            id_user: control.id_user,
        };

        try {
            const response=await api.post('/computers/controlconnecthost', data, this.buildConfig());
            return response;
        } catch (error) {
            this.logger.error('Failed to connect to host', error);
            this.createToastFunction(this.translateFunction(USER_MESSAGES.NOT_CONNECTED), 3000, "alert");
            return error.response;
        }
    }

    api.controlDisconnectHost=async function(peer, hostConnectedID) {
        const data={
            id_remote: peer.idRemote.value,
            target_host_id: peer.searchInHosts(hostConnectedID).peerId,
            session_id: peer.searchInHosts(hostConnectedID).idsession,
            effective_time: peer.searchInHosts(hostConnectedID).lastSessionTime.inSeconds.toString(),
            guid_remote: peer.remote_guid,
        };

        try {
            const response=await api.post('/api/controldisconnecthost', JSON.stringify(data));
            return response;
        } catch (error) {
            this.logger.error('Failed to disconnect from host', error);
            return error.response;
        }
    }

    api.getPCList=async function() {
        var response=await api.get("/computers?gLite=true", this.buildConfig())
            .then(res => res.data.computers.map(pc => { pc.connected=false; return pc }))
            .catch(e => {
                console.log(PC_MESSAGES.LIST_NOT_RETRIEVED);
                console.log(e);
                this.createToastFunction(this.translateFunction(PC_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                return [];
            });

        return response;
    }

    api.getPCPoliciesList=async function(idPC) {
        var response=await api.get("/enterprise/policies?pc="+idPC, this.buildConfig())
            .catch(e => {
                console.log(PC_MESSAGES.LIST_NOT_RETRIEVED);
                console.log(e);
                this.createToastFunction(this.translateFunction(PC_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                return [];
            });

        return response.data;
    }
    api.getJobPoliciesList=async function(idPC) {
        var response=await api.get("/enterprise/policies/jobs/"+idPC, this.buildConfig())
            .catch(e => {
                console.log(PC_MESSAGES.LIST_NOT_RETRIEVED);
                console.log(e);
                this.createToastFunction(this.translateFunction(PC_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                return [];
            });

        return response.data;
    }
    api.setDevicePoliciesList = async function (idPC, policiesList) {
        var body = {
            "id_computer": idPC,
            "ids_policies": policiesList
        };
        var response = await api.put("/enterprise/policies/computers", body, this.buildConfig())
            .catch(e => {
                console.log(PC_MESSAGES.POLICY_NOT_RETRIEVED);
                console.log(e);
                this.createToastFunction(this.translateFunction(PC_MESSAGES.POLICY_NOT_RETRIEVED), 3000, "alert");
                return [];
            });

        return response;
    }
    api.refreshDevicePolicies = async function (idPC) {
        var body = {
            "pc_ids": [idPC]
        };
        var response = await api.put("/enterprise/policies/computers", body, this.buildConfig())
            .catch(e => {
                console.log(PC_MESSAGES.POLICY_NOT_RETRIEVED);
                console.log(e);
                this.createToastFunction(this.translateFunction(PC_MESSAGES.POLICY_NOT_RETRIEVED), 3000, "alert");
                return [];
            });

        return response.data;
    }
    api.getPCRemoteConnected=async function(PClist) {
        var body={
            id_list: PClist.map((obj) => obj.id_remote).join("|")
        };
        var remoteConnected=await api.put("/computers/isonline", body, this.buildConfig())
            .then(res => res.data)
            .catch(e => {
                console.log(PC_MESSAGES.LIST_NOT_RETRIEVED);
                console.log(e);
                this.createToastFunction(this.translateFunction(PC_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                var notConnectedMap={};
                PClist.forEach(pc => {
                    notConnectedMap[pc.id_remote]=false
                });
                return notConnectedMap;
            });

        //remoteConnected = {id_remote: true/false ...}
        //pcsConnected = {idPC: true/false ...}
        var pcsConnected=PClist.reduce((obj, pc) => ({ ...obj, [pc.id]: remoteConnected[pc.id_remote] }), {});
        return [remoteConnected, pcsConnected];
    }

    api.removeFromLicense=async function(idPC) {
        var response=await api.put("/enterprise/license/disconnect", { "id": idPC }, this.buildConfig(process.env.VUE_APP_BASE_URL_ENT))
            .then(res => res.data)
            .catch(e => {
                console.log(PC_MESSAGES.ERROR);
                console.log(e);
                this.createToastFunction(this.translateFunction(PC_MESSAGES.ERROR), 3000, "alert");
                return false;
            });

        return response;
    }

    api.addToLicense=async function(idPC) {
        var response=await api.put("/enterprise/license/restore?id", { "id": idPC }, this.buildConfig())
            .then(res => res.statusText)
            .catch(e => {
                console.log(PC_MESSAGES.ERROR);
                console.log(e);
                this.createToastFunction(this.translateFunction(PC_MESSAGES.ERROR), 3000, "alert");
                return false;
            });

        return response;
    }

    /** Rimuove un computer da un modulo, chiamato da charms license.js
     * Author TG
     * Date 25/03/2025
     * @param {*} idPC 
     * @param {*} idf 
     * @returns response completa
     */
    api.removeComputerFromModule = async function(idc,idf) {
        var response = await api.put("/enterprise/licenses/features/disable", { "idc": idc, "idf": idf }, this.buildConfig())
            .then(res => res.statusText)
            .catch(e => {
                console.log(PC_MESSAGES.REMOVE_FROM_MODULE_ERR);
                console.log(e);
                this.createToastFunction(this.translateFunction(PC_MESSAGES.REMOVE_FROM_MODULE_ERR), 3000, "alert");
                return false;
            });

        return response;
    }
    /** Associare un computer ad un modulo. chiamato da backup.js
     * Author TG
     * Date 28/03/2025
     * @param {*} idPC 
     * @param {*} idf 
     * @returns response completa
     */
    api.associateComputerToModule = async function(idc,idf) {
        var response = await api.put("/enterprise/licenses/features/enable", { "idc": idc, "idf": idf }, this.buildConfig())
            .then(res => res.statusText)
            .catch(e => {
                console.log(PC_MESSAGES.ADD_TO_MODULE_ERR);
                console.log(e);
                this.createToastFunction(this.translateFunction(PC_MESSAGES.ADD_TO_MODULE_ERR), 3000, "alert");
                return false;
            });

        return response;
    }    

    api.getPcListByGroupID=async function(idGroup) {
        var response=await api.get("/enterprise/computers/list/lite?idg="+idGroup, this.buildConfig())
            .then(res => res.data.computers)
            .catch(e => {
                console.log(PC_MESSAGES.LIST_NOT_RETRIEVED);
                console.log(e);
                this.createToastFunction(this.translateFunction(PC_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                return [];
            });
        return response;
    }

    api.getPCListLite=async function() { // TODO DA CORREGGERE
        var response=await api.get("/enterprise/computers/list/lite/", this.buildConfig())
            .then(res => res.data.computers)
            .catch(e => {
                console.log(PC_MESSAGES.LIST_NOT_RETRIEVED);
                console.log(e);
                this.createToastFunction(this.translateFunction(PC_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                return [];
            });

        return response;
    }

    api.getOTP=async function(pcId) { // TODO DA CORREGGERE
        var response=await api.get("/enterprise/remote/connection/otp?pcId="+pcId, this.buildConfig())
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                return null;
            });

        return response;
    }

    api.getPCListWithSubdirectories=async function(idLicense) { //TODO SERVE?
        var response=await api
            .get('/directories/'+idLicense, this.buildConfig())
            .then(response => {

                return {
                    pcList: response.data.computers,
                    subDirectories: response.data.subDirectories
                };
            })
            .catch(e => {
                console.log(PC_MESSAGES.LIST_NOT_RETRIEVED);
                console.log(e);
                this.createToastFunction(this.translateFunction(PC_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                return [];
            });

        return response;
    }

    api.getPC=async function(idPC) {
        var response=await api.get('/enterprise/computers/'+idPC+"?wg=true", this.buildConfig())
            .then(res => res.data.computer)
            .catch(e => {
                console.log(PC_MESSAGES.NOT_RETRIEVED);
                console.log(e);
                this.createToastFunction(this.translateFunction(PC_MESSAGES.NOT_RETRIEVED), 3000, "alert");
                return null;
            });

        return response;
    }



    api.savePCPassword=async function(pc) {

        const crypto=require('crypto');
        // Convertire la password in UTF-8
        const strUtf8=this.toUtf8(pc.custom_password);

        var data={
            "id": pc.id_setting,
            "custom_password": crypto.createHash('sha256').update(strUtf8).digest('hex').toUpperCase()
        };

        var response=await api.put('/settings/setpwd', data, this.buildConfig())
            .then(resp => resp)
            .catch(e => {
                console.log(PC_MESSAGES.PASSWORD_ERROR);
                console.log(e);
                return null;
            });

        return response;
    }


    api.removePCPassword=async function(pc) {

        var data={
            "id": pc.id_setting,

        };

        var response=await api.put('/settings/removepwd', data, this.buildConfig())
            .then(resp => resp)
            .catch(e => {
                console.log(PC_MESSAGES.PASSWORD_ERROR);
                console.log(e);
                return null;
            });

        return response;
    }

    api.editPC=async function(pc) {


        var data={
            "computer_id": pc.id,
            "id_user": pc.id_user,
            "setting_attribute": "2",
            "pc_name": pc.name,
            "description": pc.description,
            "organization": pc.organization_name,
            "group_attribute": pc.attribute,
            "is_protected": pc.is_protected,
            "pc_status": pc.pc_status,
            "id": pc.id_setting,
            "ids_groups": pc.groups
        };

        var body=JSON.stringify(data);



        if (pc.attribute=="3") {

            var response=await api.post(
                '/settings',
                body,
                this.buildConfig()
            ).then(res => {
                if (res.status==400) return null;
                return true;
            }).catch(e => {
                console.log(PC_MESSAGES.NOT_MODIFIED);
                console.log(e);
                /*    Object.keys(e.data.errors).forEach(key => {
                       console.log(key + ":" + e.data.errors[key]);
                   }); */
                return null; //e.data.errors;
            })

        } else {

            var response=await api.put(
                '/settings',
                body,
                this.buildConfig()
            ).then(res => {
                if (res.status==400) return null;
                return true;
            }).catch(e => {
                console.log(PC_MESSAGES.NOT_MODIFIED);
                console.log(e);
                /*    Object.keys(e.data.errors).forEach(key => {
                       console.log(key + ":" + e.data.errors[key]);
                   }); */

                return null //e.data.errors;
            });
        }

        if (response==null)
            this.createToastFunction(this.translateFunction(PC_MESSAGES.NOT_MODIFIED), 3000, "alert");

        return response;
    }

    api.getStatsFromPC=async function(idPC) {
        var endDate=moment(new Date()).format("YYYYMMDDhhmmss");

        var response=await api.get('/enterprise/stats/computer?id='+idPC+'&dt_start=20200101000000&dt_end='+endDate+'&stepped=true',
            this.buildConfig())
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(STATS_NOT_RETRIEVED), 3000, "alert");
                return [];
            });

        return response;
    }

    api.getStatsFromLicense=async function(startDate, endDate) {
        var startDate=moment(startDate).format("YYYYMMDDhhmmss");
        var endDate=moment(endDate).format("YYYYMMDDhhmmss");
        var response=await api.get(
            '/enterprise/stats/license?dt_start='+startDate+'&dt_end='+endDate+'&stepped=true',
            this.buildConfig())
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(STATS_NOT_RETRIEVED), 3000, "alert");
                return [];
            });

        return response;
    }

    api.getStatsFromLicenseLight=async function(idgroup) {
        var response=await api.get(
            '/enterprise/stats/mainV2'+(idgroup!=null? '?idg='+idgroup:''),
            this.buildConfig())
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                api.createToastFunction(api.translateFunction(STATS_NOT_RETRIEVED), 3000, "alert");
                return [];
            });

        return response;
    }
    //Restituisce un array di oggetti di tipo:
    //{
    //  id_group:...
    //  name_group:...
    //  num_computers:...
    //}
    api.getGroups=async function() {
        var response=await api.get('/groupslite', this.buildConfig())
            .then(res => res.data.groups.map(g => {
                //g.pcs = [];
                return g;
            }))
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(GROUP_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                return [];
            });

        return response;
    }
    api.saveNote=async function(note) {
        var config=this.buildConfig();
        var noteString=JSON.stringify(note);
        if (note.id==null) {
            var response=await api.post(
                '/notes',
                noteString,
                config
            ).then(res => {
                if (res.status==400) return null;
                return res.data;
            }).catch(e => {
                console.log(e);
                return null;
            });
        } else {

            var response=await api.put(
                '/notes',
                noteString,
                config
            ).then(res => {
                if (res.status==400) return null;
                return res.data;
            }).catch(e => {
                console.log(e);
                return null;
            });
        }
        if (response==null) {
            this.createToastFunction(this.translateFunction(PC_MESSAGES.NOTE_NOT_SAVED), 3000, "alert");
        }
        return response;
    }
    api.getControls=async function() {
        var response=await api.get('/controls', this.buildConfig())
            .then(res => {
                return res.data;
            })
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(CONTROLS_NOT_RETRIEVED), 3000, "alert");
                return [];
            });

        return response;
    }
    api.getGroup=async function(idgroup) {
        var response=await api.get('/groupswu/'+idgroup, this.buildConfig())
            .catch(e => {
                console.log(e);
                return null;
            });
        response.data.computers=response.data.computers.map(pc => { pc.connected=false; return pc })
        response.data.ids_computers=response.data.computers.map(pc => { return pc.id })
        response.data.ids_users=response.data.users.map(user => { return user.id })
        return response.data;
    }

    api.createGroup=async function(group) {

        var config=this.buildConfig();

        var response=await api.post(
            '/shgroups?full=true',
            JSON.stringify(group),
            config
        ).then(res => {
            if (res.status==400) return null;
            return res.data;
        }).catch(e => {
            console.log(e);
            /*  Object.keys(e.data.user.errors).forEach(key => {
                 e.data.user.errors[key].forEach(message => console.log(key + " : " + message))
             }); */
            return null;
        });
        if (response==null)
            this.createToastFunction(this.translateFunction(GROUP_MESSAGES.NOT_CREATED), 3000, "alert");
        else
            this.createToastFunction(this.translateFunction(GROUP_MESSAGES.CREATED), 3000, "success");
        return response;
    }

    api.editGroup=async function(group) {
        var config=this.buildConfig();
        var groupString=JSON.stringify(group);
        //config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        var response=await api.put(
            '/groups?full=true',
            groupString,
            config
        ).then(res => {
            if (res.status==400) return null;
            return res.data;
        }).catch(e => {
            console.log(GROUP_MESSAGES.NOT_MODIFIED);
            console.log(e);
            /*   Object.keys(e.data.errors).forEach(key => {
                  console.log(key + ":" + e.data.errors[key]);
              }); */
            return null;
        });
        if (response==null)
            this.createToastFunction(this.translateFunction(GROUP_MESSAGES.NOT_MODIFIED), 3000, "alert");
        else
            this.createToastFunction(this.translateFunction(GROUP_MESSAGES.MODIFIED), 3000, "success");
        return response;
    }

    api.deleteGroup=async function(groupid) {
        var response=await api.delete('/groups/'+groupid, this.buildConfig())
            .then(res => res.status!=400)
            .catch(e => {
                console.log(GROUP_MESSAGES.NOT_DELETED);
                console.log(e);
                this.createToastFunction(this.translateFunction(GROUP_MESSAGES.NOT_DELETED), 3000, "alert");
                return false;
            });

        return response;
    }

    api.updateIperiusRemote4=async function(id_remote) {

        var data={ "id_remote": id_remote }

        var customConfig=this.buildConfig();
        customConfig.baseURL=process.env.VUE_APP_BASE_URL_REMOTE; // chiamata alle rest api del server socket di remote

        var response=await api.post(
            '/remote/update',
            data,
            customConfig // Passa la configurazione personalizzata
        ).then(res => {
            if (res.status==400) return null;
            return res;
        }).catch(e => {
            console.log(IPERIUSREMOTE_MESSAGE.NOT_UPDATE);
            console.log(e);
            /*  Object.keys(e.data.errors).forEach(key => {
                 console.log(key + ":" + e.data.errors[key]);
             }); */
            return null;
        });

        if (response==null)
            this.createToastFunction(this.translateFunction(IPERIUSREMOTE_MESSAGE.NOT_UPDATE), 3000, "alert");
        return response;

    }
    api.restartIperiusRemote4=async function(id_remote) {

        var data={ "id_remote": id_remote }

        var customConfig=this.buildConfig();
        customConfig.baseURL=process.env.VUE_APP_BASE_URL_REMOTE; // chiamata alle rest api del server socket di remote       

        var response=await api.post(
            '/remote/restart',
            data,
            customConfig // Passa la configurazione personalizzata
        ).then(res => {
            if (res.status==400) return null;
            return res;
        }).catch(e => {
            console.log(IPERIUSREMOTE_MESSAGE.NOT_RESTART);
            console.log(e);
            /*   Object.keys(e.data.errors).forEach(key => {
                  console.log(key + ":" + e.data.errors[key]);
              }); */
            return null;
        });

        if (response==null)
            this.createToastFunction(this.translateFunction(IPERIUSREMOTE_MESSAGE.NOT_RESTART), 3000, "alert");
        return response;

    }
    api.closeIperiusRemote4=async function(id_remote) {

        var data={ "id_remote": id_remote }

        var customConfig=this.buildConfig();
        customConfig.baseURL=process.env.VUE_APP_BASE_URL_REMOTE; // chiamata alle rest api del server socket di remote        

        var response=await api.post(
            '/remote/terminate',
            data,
            customConfig // Passa la configurazione personalizzata
        ).then(res => {
            if (res.status==400) return null;
            return res;
        }).catch(e => {
            console.log(IPERIUSREMOTE_MESSAGE.NOT_CLOSE);
            console.log(e);
            // Object.keys(e.data.errors).forEach(key => {
            //     console.log(key + ":" + e.data.errors[key]);
            // });
            return null;
        });

        if (response==null)
            this.createToastFunction(this.translateFunction(IPERIUSREMOTE_MESSAGE.NOT_CLOSE), 3000, "alert");
        return response;

    }

    api.latestIperiusRemote4Version=async function() {

        var data={ "ver": 'x64' }

        var customConfig=this.buildConfig();
        customConfig.baseURL=process.env.VUE_APP_BASE_URL_REMOTE; // chiamata alle rest api del server socket di remote        

        var response=await api.post(
            '/getversion',
            data,
            customConfig // Passa la configurazione personalizzata
        ).then(res => {
            if (res.status==400) return null;
            return res;
        }).catch(e => {
            console.log(IPERIUSREMOTE_MESSAGE.NOT_VER);
            console.log(e);
            /*Object.keys(e.data.errors).forEach(key => {
                console.log(key + ":" + e.data.errors[key]);
            });*/
            return null;
        });

        if (response==null) {
            this.createToastFunction(this.translateFunction(IPERIUSREMOTE_MESSAGE.NOT_VER), 3000, "alert");
        }
        return response;

    }

    api.getIperiusRemote4ClientStatus=async function(ids_remote) {

        var data=ids_remote;

        var customConfig=this.buildConfig();
        customConfig.baseURL=process.env.VUE_APP_BASE_URL_REMOTE; // chiamata alle rest api del server socket di remote

        var response=await api.put(
            '/remote/isonline',
            data,
            customConfig // Passa la configurazione personalizzata
        ).then(res => {
            if (res.status==400) return null;
            return res;
        }).catch(e => {
            console.log(e);
            /*Object.keys(e.data.errors).forEach(key => {
                console.log(key + ":" + e.data.errors[key]);
            });*/
            return null;
        });

        if (response==null) {
            this.createToastFunction(this.translateFunction(IPERIUSREMOTE_MESSAGE.NOT_STATUS), 3000, "alert");
        }
        return response;

    }

    /*********************************POLICIES*********************************************/
    //export const policiesAPI = {

    api.refreshPolicies=async function(computers) {

        var idComputers=computers.map(computer => computer.id);


        var body=JSON.stringify({
            "pc_ids": idComputers
        });
        var response=await api.put('/enterprise/refresh/policies', body, this.buildConfig(process.env.VUE_APP_BASE_URL_ENT))
            .then(res => {
                return res.data;
            })
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(POLICIES_NOT_RETRIEVED), 3000, "alert");
                return [];
            });

        return response;
    }

    api.getPolicies=async function() {
        var response=await api.get('/enterprise/policies', this.buildConfig())
            .then(res => {
                return res.data;
            })
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(POLICIES_NOT_RETRIEVED), 3000, "alert");
                return [];
            });

        return response;
    }
    api.getPolicyTypes=async function() {
        var response=await api.get('/enterprise/policytypes', this.buildConfig())
            .then(res => {
                return res.data;
            })
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(POLICIES_NOT_RETRIEVED), 3000, "alert");
                return [];
            });

        return response;
    }
    api.deletePolicy=async function(idPol) {
        var response=await api.delete('/enterprise/policies?id='+idPol, this.buildConfig())
            .then(response => 200<=response.status&&response.status<=299)
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(POLICY_NOT_DELETED), 3000, "alert");

                return false;
            });

        return response;
    }
    api.getPolicy=async function(idPol) {
        var response=await api.get('/enterprise/policies?id='+idPol, this.buildConfig())
            .then(res => {
                return res.data;
            })
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(POLICY_NOT_RETRIEVED), 3000, "alert");
                return null;
            });

        return response;
    }
    api.createPolicy=async function(policyObj) {
        var config=this.buildConfig();
        var body=JSON.stringify(policyObj);


        var response=await api.post('/enterprise/policies', body, config)
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                e=createErrorObject(e);
                console.log(e);
                return null;
            });
        if (response==null)
            this.createToastFunction(this.translateFunction(POLICY_MESSAGES.NOT_CREATED), 3000, "alert");
        else
            this.createToastFunction(this.translateFunction(POLICY_MESSAGES.CREATED), 3000, "success");
        return response;
    }
    api.editPolicy=async function(policyObj) {
        var config=this.buildConfig();
        var body=JSON.stringify(policyObj);


        var response=await api.put('/enterprise/policies', body, config)
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                e=createErrorObject(e);
                console.log(e);
                return null;
            });
        if (response==null)
            this.createToastFunction(this.translateFunction(POLICY_MESSAGES.NOT_CREATED), 3000, "alert");
        else
            this.createToastFunction(this.translateFunction(POLICY_MESSAGES.CREATED), 3000, "success");
        return response;
    }
    //}

    /****************************************BACKUP**************************************************************************************/
    //export const backupAPI = {
    //TODO DA MODIFICARE
    //STEP 0 = async function creare i vari id (job, sources, destinations)
    //vedere con andrea
    api.createBackup=async function(backupObj) {
        var config=this.buildConfig();
        var body=JSON.stringify(backupObj);

        var response=await api.post('/enterprise/jobs', body, config)
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                e=createErrorObject(e);
                console.log(e);
                this.createToastFunction(this.translateFunction(BACKUP_MESSAGES.NOT_CREATED), 3000, "alert");
                return null;
            });

        return response;
    }

    api.editBackup=async function(backupObj) {
        var config=this.buildConfig();
        var body=JSON.stringify(backupObj);

        var response=await api.put('/enterprise/jobs', body, config)
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                e=createErrorObject(e);
                console.log(e);
                this.createToastFunction(this.translateFunction(BACKUP_MESSAGES.NOT_MODIFIED), 3000, "alert");
                return null;
            });

        return response;
    }

    api.deleteBackup=async function(id, name) {
        var config=this.buildConfig();

        var response=await api.delete('/enterprise/jobs/'+id, config)
            .then(res => 200<=res.status&&res.status<=299)
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(BACKUP_MESSAGES.NOT_DELETED, { 0: name }), 3000, "alert");
                return false;
            });

        return response;
    }

    api.renameBackup=async function(idBackup, oldName, newName) {
        var config=this.buildConfig();
        var body=JSON.stringify({ name: newName });

        var response=await api.put('/enterprise/jobs/rename/'+idBackup, body, config)
            .then(res => 200<=res.status&&res.status<=299)
            .catch(e => {
                console.log(e);
                e=createErrorObject(e);
                console.log(e);
                return false;
            });

        if (!response)
            this.createToastFunction(this.translateFunction(BACKUP_MESSAGES.NOT_RENAMED, { 0: oldName, 1: newName }), 3000, "alert");
        return response;
    }
    /*
    Ritorna un oggetto del tipo
    {
        id: ...,
        name : ... , 
        sources : [],
        destinations : [],
        scheduling : []
    }*/
    api.getBackupWithID=async function(idBackup) {
        var response=await api.get('/enterprise/jobs/'+idBackup+"?fview=true", this.buildConfig())
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(BACKUP_MESSAGES.NOT_RETRIEVED), 3000, "alert");
                return null;
            });

        return response;
    }

    api.getBackupLogs=async function(idBackup) {

        //[TG - 14/10/2024 ?result=1 filtra solo i log terminati con successo, 
        // quindi ciò determina che il restore dei log che sono parzialmente in errore dovrà essere fatto col restore manuale]

        var response=await api.get('/enterprise/logs/job/'+idBackup+'?result=1', this.buildConfig())
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(BACKUP_MESSAGES.LOGS_NOT_RETRIEVED), 3000, "alert");
                return [];
            });
        return response;
    }

    api.getBackups=async function(idPC, level, numRows, offset, excludeNeverExecJobs=0, nameLike='') { //offset -> punto di inizio, index -> quanti elementi prendere
        //numRows = 300; //TODO RICORDATI CHE VA UTILIZZATO PER LA PAGINAZIONE...
        var params="index="+numRows+"&offset="+offset+"&excludeNeverExec="+excludeNeverExecJobs+"&searchPhrase="+nameLike;
        if (idPC!=null) {
            params+="&pc="+idPC
        }
        //se level è 99, restituisce tutti i backup e non serve inserire il filtro nei parametri
        if (level!=99) params+="&level="+level;
        var response=await api.get('/enterprise/jobs?'+params, this.buildConfig())
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(BACKUP_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");

                return [];
            });
        return response;
    }


    api.getIdleBackups=async function() {
        var response=await api.get('/enterprise/jobs/zombies/1', this.buildConfig())
            .then(res => res.data.map(x => {
                if (!isValid(x.type_name)||x.type_name.toLowerCase()=="unknown")
                    x.type_name=this.$platformsTypesInfo[x.type].name;
                return x;
            }))
            .catch(e => {
                console.log(BACKUP_MESSAGES.LIST_NOT_RETRIEVED);
                console.log(e);
                this.createToastFunction(this.translateFunction(BACKUP_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");

                return [];
            });

        return response;
    }
    api.getBackupTree=async function() {
        var response=await api.get('/enterprise/groupstree?excludeGroups=1', this.buildConfig())
            .then(res => res.data)
            .catch(e => {
                console.log(BACKUP_MESSAGES.LIST_NOT_RETRIEVED);
                console.log(e);
                this.createToastFunction(this.translateFunction(BACKUP_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                return null;
            });

        return response;
    }
    //[TG - legge la configurazione del backup dal singolo log]
    api.getBackupLogConfiguration=async function(logId) {
        var response=await api.get('/enterprise/backupconfigurations?lid='+logId, this.buildConfig())
            .then(res => res.data)
            .catch(e => {
                console.log(BACKUP_MESSAGES.LOG_DETAIL_NOT_RETRIEVED);
                console.log(e);
                return null;
            });
        return response;
    }

    //}


    /*****************************************USERS***************************************************************************************/
    //export const userAPI = {
    api.getUser=async function(idUser) {
        var response=await api.get('/accounts/users/'+idUser, this.buildConfig())
            .catch(e => {
                console.log(USER_MESSAGES.NOT_RETRIEVED);
                console.log(e);
                this.createToastFunction(this.translateFunction(USER_MESSAGES.NOT_RETRIEVED), 3000, "alert");
                return null;
            });
        if (response!=null) {
            response.data.ids_groups=response.data.groups.map(group => { return group.id })
            return response.data;
        } else {
            console.log(USER_MESSAGES.NOT_RETRIEVED);
            this.createToastFunction(this.translateFunction(USER_MESSAGES.NOT_RETRIEVED), 3000, "alert");
            return null;
        }
    }
    api.getUsers=async function() {
        var response=await api.get('/accounts/users', this.buildConfig())
            .then(res => res.data.users)
            .catch(e => {
                console.log(USER_MESSAGES.LIST_NOT_RETRIEVED);
                console.log(e);
                this.createToastFunction(this.translateFunction(USER_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                return [];
            });

        return response;
    }

    /*
     
    {
        "data": {
            "user": {
                "errors": {
                    "email": [
                        "must be a valid email address"
                    ],
                    "password": [
                        "at least one upper case character"
                    ],
                    "username": [
                        "can't be blank"
                    ]
                }
            }
        }
        "status": 422,
        "statusText": "Unprocessable Entity",
        
    }
    */
    api.createUser=async function(user) {

        var config=this.buildConfig();
        var response=response=await api.post(
            '/accounts/users/',
            JSON.stringify(user),
            config
        ).then(res => {
            if (res.status==400) return null;
            return res.data;
        }).catch(e => {
            console.log(e);
            /*     Object.keys(e.data.user.errors).forEach(key => {
                    e.data.user.errors[key].forEach(message => console.log(key + " : " + message))
                }); */
            return null;
        });
        if (response==null)
            this.createToastFunction(this.translateFunction(USER_MESSAGES.NOT_CREATED), 3000, "alert");
        else
            this.createToastFunction(this.translateFunction(USER_MESSAGES.CREATED), 3000, "success");

        return response;
    }

    api.editUser=async function(user) {
        var response=await api.put(
            '/accounts/users/',
            JSON.stringify(user),
            this.buildConfig()
        ).then(res => {
            if (res.status==400) return null;
            return user;
        }).catch(e => {
            console.log(USER_MESSAGES.NOT_MODIFIED);
            console.log(e);
            /*    Object.keys(e.data.user.errors).forEach(key => {
                   console.log(e.data.user.errors[key]);
               }); */
            return null;
        });
        if (response==null)
            this.createToastFunction(this.translateFunction(USER_MESSAGES.NOT_MODIFIED), 3000, "alert");
        else
            this.createToastFunction(this.translateFunction(USER_MESSAGES.MODIFIED), 3000, "success");
        return response;
    }

    api.deleteUser=async function(id, username) {
        var response=await api.delete('/accounts/users/'+idUser, this.buildConfig())
            .then(res => res.status!=400)
            .catch(e => {
                console.log(USER_MESSAGES.NOT_DELETED);
                console.log(e);
                return false;
            });
        if (response)
            this.createToastFunction(this.translateFunction(USER_MESSAGES.DELETED, { 0: username }), 3000, "success");
        else
            this.createToastFunction(this.translateFunction(USER_MESSAGES.NOT_DELETED, { 0: username }), 3000, "alert");

        return response;
    }
    //}


    /********************************************ALTRO**********************************************************************/



    api.getLicenses=async function() {
        var response=await api.get("/licenses/", this.buildConfig())
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(LICENSES_NOT_RETRIEVED), 3000, "alert");
                return [];
            });

        return response;
    }

    api.getRoles=async function() {
        var response=await api.get("/roles/", this.buildConfig())
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(ROLES_NOT_RETRIEVED), 3000, "alert");
                return null;
            });

        return response;
    }

    api.getTypes=async function() {
        var response=await api.get("enterprise/events/types", this.buildConfig())
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(TYPES_NOT_RETRIEVED), 3000, "alert");
                return null;
            });

        return response;
    }

    api.getPricing=async function(license) {

        var response=await api.post('enterprise/license/configure/', license, this.buildConfig())
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(PRICING_NOT_RETRIEVED), 3000, "alert");
                return null;
            });

        return response;
    }

    api.getEmailProviders=async function() {

        var response=await api.get('/enterprise/smtp', this.buildConfig())
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(EMAILPROVIDERS_NOT_RETRIEVED), 3000, "alert");
                return [];
            });

        return response;
    }
    //export const agentAPI = {
    //[TG - 18/06/2024 - hash stringa codice controllo]
    api.getCoCod=async function(valore) {
        var data={
            'str': valore,
        };

        var response=await api.put('/enterprise/utils/hash', data, this.buildConfig())
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(COCOD_ERROR_CRYPT), 3000, "alert");
                return null;
            });

        return response;
    }

    //[TG - 18/06/2024 - crypt stringa per parametro]
    api.getCrio=async function(valore) {
        var data={
            'str': valore,
        };

        var response=await api.put('/enterprise/utils/crypt', data, this.buildConfig())
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(STRING_ERROR_CRYPT), 3000, "alert");
                return null;
            });

        return response;
    }

    //[TG - 18/06/2024 - informazioni aggiuntive sulla licenza corrente]
    //Veronica - 16/01/2025 : L'id della licenza viene preso direttamente dal token
    //altrimenti veniva recuperato this.$session.getLicense().id.
    //Per sicurezza, ho creato il metodo getLicenseExtendedInfoByID
    api.getLicenseExtendedInfo=async function() {
        var response=await api.get('/enterprise/license', this.buildConfig())
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(LIC_EXT_INFO_ERR), 3000, "alert");
                return null;
            });

        return response;
    }

    api.getLicenseExtendedInfoByID=async function(idLicense) {

        var response=await api.get('/enterprise/license?id='+idLicense, this.buildConfig())
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                this.createToastFunction(this.translateFunction(LIC_EXT_INFO_ERR), 3000, "alert");
                return null;
            });

        return response;
    }

    api.buildConfig=function(baseurl=process.env.VUE_APP_BASE_URL) {

        var config={
            baseURL: baseurl
        }

        var accessToken=this.vueEnvironment.$root.checkAuth().access_token

        if (accessToken!=null)
            config.headers={
                //"Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer "+accessToken
            }

        return config;
    }


    api.buildConfigTFA=function() {

        return {
            headers: {
                //"Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer "+this.vueEnvironment.$root.checkAuth().access_token,
                "redirect": 1,
                "Content-Type": "application/json",
            }
        };
    }

    /***********************CUSTOM REQUEST TO SERVER******************************************************/
    api.sendRequestToServer=async function(method, url, headers, body=null, message_before=null, message_ok=null, message_failed=null, waitNSeconds=null) {
        var serverURL=process.env.VUE_APP_BASE_URL+"/iperius/ccall";
        var serverHeaders=api.buildConfig()
        var serverBody={
            "crc": "",// in futuro conterrà un codice di sicurezza
            "url": url,
            "method": method,
            "headers": headers,
            "body": body//??defaultHeaders
        };

        //if (body!=null) {
        //    serverBody.body=body;
        //}

        var ok=false, response=null, realResp;
        if (message_before!=null) {
            console.log(message_before);
            cookieManager.setCookie("step", message_before);
        }

        [ok, response]=await api.post(serverURL, serverBody, serverHeaders)
            .then(serverResp => {
                serverResp=serverResp.data;

                if (method=="HEAD") {
                    return [true, serverResp.headers];
                }

                ok=200<=serverResp.status_code&&serverResp.status_code<300;
                if (!ok&&serverResp.body.error!=undefined) {
                    if (serverResp.body.error.code!=undefined&&serverResp.body.error.message!=undefined) {
                        console.error(serverResp.body.error.code+" : "+serverResp.body.error.message);
                    }
                    return [false, serverResp.body.error];
                }
                if (!ok&&serverResp.body.error==undefined) {
                    return [false, serverResp.body];
                }


                realResp=serverResp.body;
                if (typeof realResp=="string") {
                    return [true, realResp];
                }

                if (realResp.status_code==undefined) {
                    return [true, realResp];
                }

                ok=200<=realResp.status_code&&realResp.status_code<300;
                if (!ok) {
                    console.error(realResp.body);
                    return [false, null];
                }

                return [true, realResp.body];
            }).catch(e => {
                console.error(e);
                return [false, e];
            });

        if (!ok&&message_failed!=null) {
            console.log(message_failed);
            cookieManager.setCookie("step", message_failed);
        }

        if (ok&&message_before!=null&&waitNSeconds!=null) {
            for (var s=waitNSeconds; s>=0; s--) {
                cookieManager.setCookie("step", "{0}. Wait {1} seconds".format(message_before, s));
                await waitSeconds(1);
            }
        }

        if (ok&&message_ok!=null) {
            console.log(message_ok);
            cookieManager.setCookie("step", message_ok);
        }

        return [ok, response];
    }
    //non serve
    /* api.getSavedAccessToken = function() {
        var accessToken = isValid(this.accessToken) ?
            this.accessToken :
            JSON.parse(localStorage.getItem("AUTH")) != null ?
            JSON.parse(localStorage.getItem("AUTH")).access_token :
            this.vueEnvironment.$session.exists() && this.vueEnvironment.$session.has("AUTH") ?
            this.vueEnvironment.$session.getAuth().access_token :
            null;
 
        if (accessToken != null) {
            this.accessToken = accessToken;
        } else {
            console.log("ATTENZIONE : nessun accessToken salvato!!!")
        }
        return accessToken;
    } */
}


/* export async function testGET(url) {

    var config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYW1laWQiOiI1NTVjM2Q3ZS1hNTgxLTQ4MDMtYWZiYy05NTg3ODBlMjRlZWYiLCJ1bmlxdWVfbmFtZSI6Implc3NpY2F2aW51ZXphXzMxN2ZhIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS9hY2Nlc3Njb250cm9sc2VydmljZS8yMDEwLzA3L2NsYWltcy9pZGVudGl0eXByb3ZpZGVyIjoiQVNQLk5FVCBJZGVudGl0eSIsIkFzcE5ldC5JZGVudGl0eS5TZWN1cml0eVN0YW1wIjoiOWUxMDgxNjctZTQyZS00ZDAyLWE4NjktYTA2OWRkZjZmOTM1Iiwicm9sZSI6IkFkbWluIiwiTGljZW5zZUlkIjoiMzE3RkFBNzAwMzU4MUM3NDg4NjNDMjExOTJDMTg2RTUiLCJNYXhOdW1Vc2VycyI6IjEwMCIsIk1heE51bUNvbXB1dGVycyI6IjM1MCIsImlzcyI6Imh0dHBzOi8vaXBlcml1c2MxLmNvbSIsImF1ZCI6IjQxNGUxOTI3YTM4ODRmNjhhYmM3OWY3MjgzODM3ZmQxIiwiZXhwIjoxNjgzMjgxMTA5LCJuYmYiOjE2ODMyNzc1MDl9.JAcv246gOXSA_I12UWrlQCOLWVdmXsq9xGQO7Dnbkqc"
        }
    };
    var response = await api.get(url, config)
        .then(res => res.data)
        .catch(e => {
            console.log(e);
            return null;
        });

    console.log(response)
    return response;

} */