var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog light w-100-sm w-50 w-50-lg w-50-xl pos-center h-75",
      attrs: { id: "destinationBackup" },
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.close()
        },
      },
    },
    [
      _c("DialogTitle", {
        attrs: {
          title: _vm.destination.name ?? "Configure Destination",
          defaultTitle: _vm.$t("Configure Destination"),
          subTitle: _vm.$t("Destination") + " " + _vm.destination.type_name,
        },
        on: {
          closeDialog: function ($event) {
            return _vm.close()
          },
        },
      }),
      _c(
        "div",
        { staticClass: "dialog-content overflow bg-light" },
        [
          _vm.destination.type != _vm.$PlatformsTypes.FileFolder
            ? _c("div", { staticClass: "form-group d-flex flex-column" }, [
                _c("label", { staticClass: "text-bold" }, [
                  _vm._v(_vm._s(_vm.$t("Choose credentials"))),
                ]),
                _c("input", {
                  attrs: {
                    type: "radio",
                    "data-caption": _vm.$t("Create or select a repository"),
                    "data-role": "radio",
                    name: "r1",
                  },
                  domProps: { checked: _vm.credentialMode == "repo" },
                  on: {
                    click: function ($event) {
                      return _vm.changeCredentialMode("repo")
                    },
                  },
                }),
                _c("input", {
                  attrs: {
                    type: "radio",
                    "data-caption": _vm.$t(
                      "Create or select a service account"
                    ),
                    "data-role": "radio",
                    name: "r1",
                  },
                  domProps: { checked: _vm.credentialMode == "sa" },
                  on: {
                    click: function ($event) {
                      return _vm.changeCredentialMode("sa")
                    },
                  },
                }),
              ])
            : _vm._e(),
          _vm.destination.type != _vm.$PlatformsTypes.FileFolder &&
          _vm.credentialMode == "repo"
            ? _c(
                "div",
                [
                  _c("SARepoSelect", {
                    key: "selectRepo",
                    attrs: {
                      type: _vm.destination.type,
                      idRepo: _vm.destination.id_repository,
                      idSA: _vm.destination.id_service_account,
                      selectRepo: true,
                    },
                    on: { sendSATestResult: _vm.sendSATestResult },
                  }),
                ],
                1
              )
            : _vm.destination.type != _vm.$PlatformsTypes.FileFolder &&
              _vm.credentialMode == "sa"
            ? _c(
                "div",
                [
                  _c("SARepoSelect", {
                    key: "selectSA",
                    attrs: {
                      type: _vm.destination.type,
                      idSA: _vm.destination.id_service_account,
                      selectRepo: false,
                      idRepo: -2,
                    },
                    on: { sendSATestResult: _vm.sendSATestResult },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.credentialMode != "repo"
            ? _c("SelectedResourcesLayout", {
                attrs: {
                  buttonText: _vm.$t("Select destination folder"),
                  singleItem: true,
                  isDisabled:
                    _vm.destination.type != _vm.$PlatformsTypes.FileFolder &&
                    !_vm.testResult.ok,
                  list: _vm.selectedNodes,
                  heightPercentage: 0,
                  canCancel: true,
                  type: _vm.destination.type,
                },
                on: {
                  clickFunction: _vm.toggleFileSystemSidebar,
                  setNewPath: _vm.setPathAndName,
                },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "form-group",
              attrs: {
                disabled:
                  _vm.destination.type == _vm.$PlatformsTypes.FileFolder &&
                  _vm.destination.path == "",
              },
            },
            [
              _c("label", { staticClass: "text-bold" }, [
                _vm._v(_vm._s(_vm.$t("Backup Destination name"))),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.destination.name,
                    expression: "destination.name",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "input",
                attrs: { "data-role": "input" },
                domProps: { value: _vm.destination.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.destination,
                      "name",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "dialog-actions" }, [
        _c("div", { staticClass: "ml-auto" }, [
          _c(
            "button",
            {
              staticClass: "button primary",
              attrs: { disabled: !_vm.enableSave },
              on: { click: _vm.save },
            },
            [_vm._v(" " + _vm._s(_vm.$t("Save")))]
          ),
          _c(
            "button",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            },
            [
              _c("span", { staticClass: "mif-close" }),
              _vm._v(" " + _vm._s(_vm.$t("Close"))),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }