var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass: "p-1 ml-4",
      attrs: { title: _vm.disk.path, "data-collapsed": "true" },
    },
    [
      _c("span", { staticClass: "mif-drive1 mif-lg" }),
      _c("span", { staticClass: "ml-3" }, [
        _vm._v(
          _vm._s(_vm.disk.name) + " [" + _vm._s(_vm.disk.size_string) + "]"
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }