<template>
    <div class="shifted-content">

        <Dialogs />
        <Appbar :user=true />

        <div data-role="charms" id="agentbuilding" data-position="right" data-cls-charms="bd-gray bg-white border border-top-none border-bottom-none border-right-none" 
            v-show="iframeLoaded" v-cloak>
            <div class="row p-3 mb-4">
                <h3 class="cell-8 text-left fg-black">{{ $t("Generate and Download Agent Setup") }}</h3>
                <div class="cell-4 d-flex flex-justify-end">

                    <!--button onclick="$('#notifications').data('charms').toggle()" class="button bg-white"><span class="icon mif mif-close mif-bin"> </span></button-->
                    <button @click="toggleAgent" class="button bg-white"><span class="icon mif mif-close mif-cross">
                        </span></button>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div>
                        <iframe allowfullscreen sandbox="allow-scripts allow-modals allow-forms allow-downloads"
                            :src="parentIframeSrc" width="500" height="600" loading="lazy" @load="onIframeLoad"></iframe>
                    </div>
                </div>
            </div>

        </div>


        <aside class="sidebar z-2 pt-20 no-shadow bg-white" data-role="sidebar" data-toggle="#menu-toggle" id="sb4"
            data-shift=".docs-content" data-static-shift=".docs-content" data-static="lg" style="padding-top: 76px;">

            <!-- data-role="html-container" data-html-source="sidenav.html" data-insert-mode="content" data-role-htmlcontainer="true"-->
            <ul class="sidebar-menu ">

                <li class="">
                    <router-link to="/dashboard">
                        <span class="icon p-0 rounded"><svg style="width: 26;height: 26;" width="26" height="26"
                                fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.499 9.5 11.5 21H6.25a3.25 3.25 0 0 1-3.245-3.065L3 17.752V9.499h8.499Zm1.5 5.999H21.5v2.253a3.25 3.25 0 0 1-3.25 3.25L13 21l-.001-5.502Zm5.252-13a3.25 3.25 0 0 1 3.245 3.065l.005.184-.001 8.251h-8.501L13 2.498h5.251ZM11.5 2.497 11.499 8H3V5.748a3.25 3.25 0 0 1 3.25-3.25h5.25Z"
                                    fill="#212121" />
                            </svg></span>
                        <span>{{ $t("Dashboard") }}</span>
                    </router-link>
                </li>

                <li class="">
                    <router-link to="/groups">
                        <span class="icon p-0 rounded">
                            <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M18.25 3A2.75 2.75 0 0 1 21 5.75v12.5A2.75 2.75 0 0 1 18.25 21H5.75A2.75 2.75 0 0 1 3 18.25V5.75A2.75 2.75 0 0 1 5.75 3h12.5Zm0 1.5H5.75c-.69 0-1.25.56-1.25 1.25v12.5c0 .69.56 1.25 1.25 1.25h12.5c.69 0 1.25-.56 1.25-1.25V5.75c0-.69-.56-1.25-1.25-1.25Zm-8.498 8c.966 0 1.75.784 1.75 1.75v2A1.75 1.75 0 0 1 9.752 18h-2a1.75 1.75 0 0 1-1.75-1.75v-2c0-.966.783-1.75 1.75-1.75h2Zm6.497 0c.967 0 1.75.784 1.75 1.75v2A1.75 1.75 0 0 1 16.25 18h-2a1.75 1.75 0 0 1-1.75-1.75v-2c0-.966.784-1.75 1.75-1.75h2ZM9.751 6c.966 0 1.75.784 1.75 1.75v2a1.75 1.75 0 0 1-1.75 1.75h-2A1.75 1.75 0 0 1 6 9.75v-2C6 6.784 6.784 6 7.75 6h2Zm6.497 0c.967 0 1.75.784 1.75 1.75v2a1.75 1.75 0 0 1-1.75 1.75h-2a1.75 1.75 0 0 1-1.75-1.75v-2c0-.966.784-1.75 1.75-1.75h2Z" fill="#212121"/></svg>
                        </span>
                        <span class="">{{ $t("Groups") }}</span>
                    </router-link>
                </li>

               <li>
                    <router-link to="/computers">
                        <span class="icon p-0 rounded">
                            <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M6.75 22a.75.75 0 0 1-.102-1.493l.102-.007h1.749v-2.498H4.25a2.25 2.25 0 0 1-2.245-2.096L2 15.752V5.25a2.25 2.25 0 0 1 2.096-2.245L4.25 3h15.499a2.25 2.25 0 0 1 2.245 2.096l.005.154v10.502a2.25 2.25 0 0 1-2.096 2.245l-.154.005h-4.25V20.5h1.751a.75.75 0 0 1 .102 1.494L17.25 22H6.75Zm7.248-3.998h-4l.001 2.498h4l-.001-2.498Z" fill="#212121"/></svg>
                        </span>
                        <span class="">{{ $t("Devices") }}</span>
                    </router-link>
                </li>
                

                <li>
                    <router-link to="/users">
                        <span class="icon p-0 rounded"><svg style="width: 26;height: 26;" width="24" height="24"
                                fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14.75 15c.966 0 1.75.784 1.75 1.75l-.001.962c.117 2.19-1.511 3.297-4.432 3.297-2.91 0-4.567-1.09-4.567-3.259v-1c0-.966.784-1.75 1.75-1.75h5.5Zm-11-5h4.376a4.007 4.007 0 0 0 1.067 3.85l.162.151L9.25 14a2.75 2.75 0 0 0-2.649 2.008l-.034.001C3.657 16.009 2 14.919 2 12.75v-1c0-.966.784-1.75 1.75-1.75Zm16.5 0c.966 0 1.75.784 1.75 1.75l-.001.962c.117 2.19-1.511 3.297-4.432 3.297l-.169-.002a2.756 2.756 0 0 0-2.451-2L14.75 14l-.105.001a3.99 3.99 0 0 0 1.229-4L20.25 10ZM12 8a3 3 0 1 1 0 6 3 3 0 0 1 0-6ZM6.5 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm11 0a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z"
                                    fill="#212121" />
                            </svg></span>
                        <span>{{ $t("Users") }}</span>
                    </router-link>
                </li>
                <li>
                    <a class="dropdown-toggle" href="#" @click="subMenuOpen= (subMenuOpen !='backup'?'backup':'')"
                        @blur="subMenuOpen =''">
                        <span class="icon p-0">
                            <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h6a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3H9Zm-.5 4.75A.75.75 0 0 1 9.25 6h5.5a.75.75 0 0 1 0 1.5h-5.5a.75.75 0 0 1-.75-.75Zm0 11a.75.75 0 0 1 .75-.75h5.5a.75.75 0 0 1 0 1.5h-5.5a.75.75 0 0 1-.75-.75Zm0-3a.75.75 0 0 1 .75-.75h5.5a.75.75 0 0 1 0 1.5h-5.5a.75.75 0 0 1-.75-.75Z" fill="#212121"/></svg>
                        </span>
                        <span>{{ $t("Backup") }}</span>
                        <span :class="{'arrow': true, 'open': subMenuOpen == 'backup'}"></span>
                    </a>
                    <ul class="d-menu no-shadow bg-light" data-role="dropdown" id="backupmenu">
                        <li>
                            <router-link to="/backupstats">
                                <span class=" icon p-0 pl-5">
                                    <svg style="width: 26;height: 26;" width="24" height="24" fill="none"
                                        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M10.25 4.25A.75.75 0 0 1 11 5v8h8a.75.75 0 0 1 .743.648l.007.102c0 4.97-4.03 8.5-9 8.5a9 9 0 0 1-9-9c0-4.97 3.53-9 8.5-9Zm3-2.5a9 9 0 0 1 9 9 .75.75 0 0 1-.75.75h-8.25a.75.75 0 0 1-.75-.75V2.5a.75.75 0 0 1 .75-.75Z"
                                            fill="#212121" />
                                    </svg>
                                </span>
                                <span class="pl-5">{{ $t("Statistics") }}</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/accounts">
                                <span class=" icon p-0  pl-5">

                                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg" style="width: 26px; height: 26px;">
                                        <path
                                            d="M4.249 13.995h5.876c-.349.423-.574.952-.62 1.53l-.009.22v4.5c0 .665.236 1.275.63 1.75l-.13.001c-3.42 0-5.943-1.072-7.486-3.236A2.75 2.75 0 0 1 2 17.164v-.92a2.249 2.249 0 0 1 2.249-2.249Zm10.497-2.5h3c.648 0 1.18.492 1.244 1.123l.007.127-.001 1.25h1.25c.967 0 1.75.784 1.75 1.75v4.5a1.75 1.75 0 0 1-1.75 1.75h-8a1.75 1.75 0 0 1-1.75-1.75v-4.5c0-.966.784-1.75 1.75-1.75h1.25v-1.25c0-.647.492-1.18 1.123-1.243l.127-.007h3-3Zm2.75 1.5h-2.5v1h2.5v-1ZM9.997 2a5 5 0 1 1 0 10 5 5 0 0 1 0-10Z"
                                            fill="#212121"></path>
                                    </svg>

                                </span>
                                <span class="pl-5">{{ $t("Credentials") }}</span>
                            </router-link>
                        </li>

                        <li><router-link to="/repo">
                                <span class=" icon p-0  pl-5">
                                    <svg style="width: 26;height: 26;" width="24" height="24" fill="none"
                                        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M10 2a4 4 0 0 1 4 4v2h2.5A1.5 1.5 0 0 1 18 9.5V11c-.319 0-.637.11-.896.329l-.107.1c-.812.845-1.656 1.238-2.597 1.238-.783 0-1.4.643-1.4 1.416v2.501c0 2.374.924 4.22 2.68 5.418L3.5 22A1.5 1.5 0 0 1 2 20.5v-11A1.5 1.5 0 0 1 3.5 8H6V6a4 4 0 0 1 4-4Zm8.284 10.122c.992 1.036 2.091 1.545 3.316 1.545.193 0 .355.143.392.332l.008.084v2.501c0 2.682-1.313 4.506-3.873 5.395a.385.385 0 0 1-.253 0c-2.476-.86-3.785-2.592-3.87-5.13L14 16.585v-2.5c0-.23.18-.417.4-.417 1.223 0 2.323-.51 3.318-1.545a.389.389 0 0 1 .566 0ZM10 13.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM10 4a2 2 0 0 0-2 2v2h4V6a2 2 0 0 0-2-2Z"
                                            fill="#212121"></path>

                                    </svg></span>
                                <span class="pl-5">{{ $t("Repositories") }}</span>
                            </router-link>
                        </li>
                
                    </ul>
                </li>
                <li>
                    <a class="dropdown-toggle" href="#" @click="subMenuOpen= (subMenuOpen!='remote'?'remote':'')"
                        @blur="subMenuOpen =''">
                        <span class="icon p-0 ">
                            <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M4.25 4A2.25 2.25 0 0 0 2 6.25v11.5A2.25 2.25 0 0 0 4.25 20h15.5A2.25 2.25 0 0 0 22 17.75V6.25A2.25 2.25 0 0 0 19.75 4H4.25Zm1.496 4.994A7.254 7.254 0 0 1 13 16.248a.75.75 0 1 1-1.5 0 5.754 5.754 0 0 0-5.754-5.754.75.75 0 0 1 0-1.5Zm-.75 3.757a.75.75 0 0 1 .75-.75 4.247 4.247 0 0 1 4.247 4.247.75.75 0 0 1-1.5 0A2.747 2.747 0 0 0 5.746 13.5a.75.75 0 0 1-.75-.75Zm2 3.245a.998.998 0 1 1-1.996 0 .998.998 0 0 1 1.996 0Z" fill="#212121"/></svg>
                        </span>
                        <span>{{ $t("Remote Access") }}</span>
                        <span class="arrow" :class="{'open': subMenuOpen == 'remote'}"></span>
                    </a>
                    <ul class="d-menu no-shadow bg-light" data-role="dropdown" id="remotemenu">
                        <li>
                            <router-link to="/remotestats">
                                <span class=" icon p-0 pl-5"><svg style="width: 26;height: 26;" width="24" height="24"
                                        fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M10.25 4.25A.75.75 0 0 1 11 5v8h8a.75.75 0 0 1 .743.648l.007.102c0 4.97-4.03 8.5-9 8.5a9 9 0 0 1-9-9c0-4.97 3.53-9 8.5-9Zm3-2.5a9 9 0 0 1 9 9 .75.75 0 0 1-.75.75h-8.25a.75.75 0 0 1-.75-.75V2.5a.75.75 0 0 1 .75-.75Z"
                                            fill="#212121" />
                                    </svg></span>
                                <span class="pl-5">{{ $t("Statistics") }}</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/sessions" active-class="active">
                                <span class=" icon p-0 pl-5">
                                    <svg style="width: 26;height: 26;" width="24" height="24" fill="none"
                                        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M4.25 4A2.25 2.25 0 0 0 2 6.25v2.5A2.25 2.25 0 0 0 4.25 11h2.5A2.25 2.25 0 0 0 9 8.75v-2.5A2.25 2.25 0 0 0 6.75 4h-2.5Zm7 1a.75.75 0 0 0 0 1.5h10a.75.75 0 0 0 0-1.5h-10Zm0 3a.75.75 0 0 0 0 1.5h7a.75.75 0 0 0 0-1.5h-7Zm-7 5A2.25 2.25 0 0 0 2 15.25v2.5A2.25 2.25 0 0 0 4.25 20h2.5A2.25 2.25 0 0 0 9 17.75v-2.5A2.25 2.25 0 0 0 6.75 13h-2.5Zm7 1a.75.75 0 0 0 0 1.5h10a.75.75 0 0 0 0-1.5h-10Zm0 3a.75.75 0 0 0 0 1.5h7a.75.75 0 0 0 0-1.5h-7Z"
                                            fill="#212121" />
                                    </svg>
                                </span>
                                <span class="pl-5">{{ $t("sessions") }}</span></router-link>
                        </li>

                        <li>
                            <router-link to="/activesessions">
                                <span class=" icon p-0 pl-5">
                                    <svg style="width: 26;height: 26;" width="24" height="24" fill="none"
                                        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="m15.207 2.292 4 3.995a1 1 0 0 1 .084 1.32l-.083.094-4 4.006a1 1 0 0 1-1.498-1.32l.083-.094L16.083 8H5.5a1 1 0 0 1-.994-.883L4.5 7a1 1 0 0 1 .883-.993L5.5 6h10.59l-2.296-2.293a1 1 0 0 1-.084-1.32l.083-.095a1 1 0 0 1 1.32-.084l.094.084 4 3.995-4-3.995Zm4.283 14.591.007.117a1 1 0 0 1-.883.993l-.117.007H7.913l2.294 2.293a1 1 0 0 1 .084 1.32l-.083.094a1 1 0 0 1-1.32.084l-.095-.084-4-3.996a1 1 0 0 1-.083-1.32l.083-.094 4-4.004a1 1 0 0 1 1.498 1.32l-.083.094L7.918 16h10.579a1 1 0 0 1 .993.883l.007.117-.007-.117Z"
                                            fill="#212121" />
                                    </svg>
                                </span>
                                <span class="pl-5">{{ $t("Active sessions") }}</span>
                            </router-link>
                        </li>

                        <li>
                            <router-link to="/customclient">
                                <span class=" icon p-0 pl-5">
                                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m17.75 3 .184.005a3.25 3.25 0 0 1 3.062 3.06l.005.185v11.5l-.005.184a3.25 3.25 0 0 1-3.06 3.06L17.75 21H6.25a3.25 3.25 0 0 1-3.245-3.066L3 17.75V6.25a3.25 3.25 0 0 1 3.066-3.245L6.25 3h11.5ZM19 8H5.001L5 17.75c0 .647.492 1.18 1.122 1.244L6.25 19h11.501l.128-.006a1.25 1.25 0 0 0 1.116-1.116L19 17.75 19 8Zm-8.75 1.5a.75.75 0 0 1 .743.648l.007.102v7a.75.75 0 0 1-.648.743L10.25 18h-3.5a.75.75 0 0 1-.743-.648L6 17.25v-7a.75.75 0 0 1 .648-.743L6.75 9.5h3.5ZM9.5 11h-2v5.5h2V11Zm6.75 1.503a.75.75 0 0 1 .102 1.493l-.102.007h-3.496a.75.75 0 0 1-.101-1.493l.101-.007h3.496Zm1-3.003a.75.75 0 0 1 .102 1.493L17.25 11h-4.496a.75.75 0 0 1-.101-1.493l.101-.007h4.496Z" fill="#212121"/></svg>
                                </span>
                                <span class="pl-5">{{ $t("Custom client") }}</span>
                            </router-link>
                        </li>
                    </ul>
                </li>

                 <li>
                    <router-link to="/policies">
                        <span class="icon p-0 ">
                            <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M18.5 20.002a.5.5 0 0 1-.5.5H9v1.5h9a2 2 0 0 0 2-2V9.83c0-.53-.211-1.04-.586-1.414l-5.829-5.829a.49.49 0 0 0-.049-.04.63.63 0 0 1-.036-.03 2.072 2.072 0 0 0-.219-.18.65.65 0 0 0-.08-.044c-.017-.008-.033-.015-.048-.024l-.05-.029c-.054-.031-.109-.063-.166-.087a1.977 1.977 0 0 0-.624-.138c-.02-.001-.04-.004-.059-.007A.605.605 0 0 0 12.172 2H6a2 2 0 0 0-2 2v7.257a4.49 4.49 0 0 1 1.5-.256v-7a.5.5 0 0 1 .5-.5h6V8a2 2 0 0 0 2 2h4.5v10.001Zm-5-15.38L17.378 8.5H14a.5.5 0 0 1-.5-.5V4.62ZM9 15.501a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-6 3.742v3.044a.71.71 0 0 0 1.212.503L5.5 21.503l1.288 1.288A.71.71 0 0 0 8 22.288v-3.044a4.479 4.479 0 0 1-2.5.758c-.925 0-1.785-.279-2.5-.758Z" fill="#222F3D"/></svg>
                        </span>
                        <span class="">{{ $t("Policies") }}</span>
                    </router-link>
                </li>

                <!-- <li class="">
                    <a class="dropdown-toggle" href="#" @click="subMenuOpen= (subMenuOpen!='settings'?'settings':'')"
                        @blur="subMenuOpen =''">
                        <span class="icon p-0 ">
                            <svg width="24" height="24" fill="none" viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12.012 2.25c.734.008 1.465.093 2.182.253a.75.75 0 0 1 .582.649l.17 1.527a1.384 1.384 0 0 0 1.927 1.116l1.401-.615a.75.75 0 0 1 .85.174 9.792 9.792 0 0 1 2.204 3.792.75.75 0 0 1-.271.825l-1.242.916a1.381 1.381 0 0 0 0 2.226l1.243.915a.75.75 0 0 1 .272.826 9.797 9.797 0 0 1-2.204 3.792.75.75 0 0 1-.848.175l-1.407-.617a1.38 1.38 0 0 0-1.926 1.114l-.169 1.526a.75.75 0 0 1-.572.647 9.518 9.518 0 0 1-4.406 0 .75.75 0 0 1-.572-.647l-.168-1.524a1.382 1.382 0 0 0-1.926-1.11l-1.406.616a.75.75 0 0 1-.849-.175 9.798 9.798 0 0 1-2.204-3.796.75.75 0 0 1 .272-.826l1.243-.916a1.38 1.38 0 0 0 0-2.226l-1.243-.914a.75.75 0 0 1-.271-.826 9.793 9.793 0 0 1 2.204-3.792.75.75 0 0 1 .85-.174l1.4.615a1.387 1.387 0 0 0 1.93-1.118l.17-1.526a.75.75 0 0 1 .583-.65c.717-.159 1.45-.243 2.201-.252ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
                                    fill="#212121" />
                            </svg>
                        </span>
                        <span class="">{{ $t("Settings") }}</span>
                        <span class="arrow" :class="{'open': subMenuOpen == 'settings'}"></span>
                    </a>
                    <ul class="d-menu no-shadow" data-role="dropdown">
                        <li>
                            <router-link to="/settings">
                                <span class="icon p-0 pl-5">
                                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3.5 13h9.309A6.478 6.478 0 0 0 11 17.5c0 1.087.267 2.112.739 3.013-1.05.35-2.208.487-3.239.487-2.722 0-6.335-.956-6.495-4.27L2 16.5v-2a1.5 1.5 0 0 1 1.356-1.493L3.5 13ZM21 7.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0ZM8.5 2a4.5 4.5 0 1 1 0 9 4.5 4.5 0 0 1 0-9Zm5.777 11.975a2 2 0 0 1-1.441 2.497l-.584.144a5.729 5.729 0 0 0 .006 1.807l.54.13a2 2 0 0 1 1.45 2.51l-.187.632c.44.386.94.699 1.485.921l.493-.518a2 2 0 0 1 2.899 0l.499.525a5.282 5.282 0 0 0 1.482-.913l-.198-.686a2 2 0 0 1 1.442-2.496l.583-.144a5.729 5.729 0 0 0-.006-1.808l-.54-.13a2 2 0 0 1-1.449-2.51l.186-.63a5.278 5.278 0 0 0-1.484-.923l-.493.519a2 2 0 0 1-2.9 0l-.498-.525c-.544.22-1.044.53-1.483.912l.198.686ZM17.5 19c-.8 0-1.45-.672-1.45-1.5 0-.829.65-1.5 1.45-1.5.8 0 1.45.671 1.45 1.5 0 .828-.65 1.5-1.45 1.5Z" fill="#212121"/></svg>
                                </span>
                                <span class="pl-5">{{ $t("Privacy Settings") }}</span>
                            </router-link>
                        </li>

                    </ul>
                </li> -->

                <footer class="footer p-6">
                  
                    
                    <div class="footer__row bg-light mt-5">
                    <div>
                        <div class="row align-items-center">
                        <div class="col-12 col-md-6 mb-3 mb-md-0 text-muted">
                            <span>© 2021 iperiusbackup<sup>Ⓡ</sup> Software</span><span class="mx-2">|</span><a target="_blank"
                            class="text-muted" href="https://www.iperiusbackup.com/eula.html">EULA</a><span
                            class="mx-2">|</span><a target="_blank" class="text-muted"
                            href="https://www.iperiusbackup.com/privacy-policy.html">Privacy Policy &amp; Cookie</a>
                        </div>

                        </div>
                    </div>
                    </div>
                </footer>

            </ul>

        </aside>

        <div class="docs-content bg-light pt-13 h-100">

            <transition name="slide-fade" v-cloak>
                <router-view />
            </transition>

        </div>
        <FileSystemSidebar />
        <addLicenseDialog :license="license" :pricing="pricing"></addLicenseDialog>


    </div>
</template>

<script>


import Appbar from '../layouts/Appbar';
import FileSystemSidebar from '../components/FileSystemSidebar';
import Dialogs from '../components/Dialogs';
import addLicenseDialog from '../components/dialogs/AddLicenseDialog.vue'
import { formatDate } from '../utils/utilitiesmodule';
export default {
    name: 'nav-layout',
    
    components: {
        Appbar,
        FileSystemSidebar,
        Dialogs,
        addLicenseDialog
    },
    created: function () {
        //console.log("CREATED Nav Layout!!!")
        this.getAgentIframeUrl();
        
    },
    watch: {
        '$route': {
            handler: function (to) {
             console.log(to);
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            iframeLoaded: false,
            subMenuOpen: '',
            loader: false,
            licenses: {},
            pricing: null,
            license: {
                num_users: 1,
                num_workstations: 0,
                num_servers: 0,
                num_vms: 0,
                plugin_exchange: 0,
                plugin_database: 0,
                plugin_tape: 0,
                subscription_duration: 1,
                discount_code: null,
                currency: 'EUR'
            },
            parentIframeSrc: '',
            trustedSrc: "",
            urlagentbuild: process.env.VUE_APP_AGENT_BUILD_BASE_URL,
            

        }
    },
    methods: {

        onIframeLoad() {
            this.iframeLoaded = true; // Cambia lo stato quando il contenuto è caricato
        },

            toggleAgent() {
                window.Metro.charms.close('#agentbuilding');
            },

             formatDate(date) {
                return formatDate(date, this.$moment);
            },

            // [TG - 18/06/2024 - generazione link apertura iframe build agent]
            async getAgentIframeUrl() {
                let self = this;

                //const div = document.getElementById('notifications');
                //const isOpened = div.hasAttribute('data-opened');
                //console.log('Notifications div is ' + (isOpened ? 'open' : 'closed'));
                if (self.$root.checkAuth() ==null){
                    return;
                }

                let ima = self.$root.checkAuth().id_master_account;
                let doa = self.formatDate(self.$root.checkAuth().dataora_attivazione);
                let dos = self.formatDate(self.$root.checkAuth().dataora_scadenza);
                let sc = '|';

                let cryLic = await self.$api.getCoCod(ima);

                if (cryLic != null) {

                    let ext_lic_info = await self.$api.getLicenseExtendedInfo();

                    if (ext_lic_info != null) {
                        this.$session.setLicenses(ext_lic_info);

                        // normalizza il JSON delle caratteristiche aggiuntive della licenza in una unica stringa
                        // come da esempio: "nome_licenza: WorkstationBackup, max_control_connections: 0, max_host_list: 0, units: 10 + nome_licenza: ServerBackup, max_control_connections: 0, max_host_list: 0, units: 10 + nome_licenza: ExchangeModule, max_control_connections: 0, max_host_list: 0, units: 1 + nome_licenza: Backup, max_control_connections: 0, max_host_list: 0, units: 5 + nome_licenza: Small, max_control_connections: 2, max_host_list: 50, units: 1"
                        let nl = Object.keys(ext_lic_info)
                        .filter(category => category !== 'remote' && category !== 'general') // Escludi "remote" e "general"
                        .map(category => {
                            return ext_lic_info[category].map(license => {
                                // Verifica se la licenza ha i campi `max_control_connections` e `max_host_list`
                                if ('max_control_connections' in license && 'max_host_list' in license) {
                                    return `nome_licenza: ${license.description}, max_control_connections: ${license.max_control_connections}, max_host_list: ${license.max_host_list}, units: ${license.units || 'N/A'}`;
                                }
                                // Altrimenti serializza solo i campi comuni
                                return `nome_licenza: ${license.description}, units: ${license.units || 'N/A'}`;
                            }).join(' + ');
                        }).join(' + ');

                        let currentDate = new Date();
                        let formattedDate = this.$moment(currentDate).format('YYYYMMDD');

                        let qry_string = ima + sc + doa + sc + dos + sc + formattedDate + sc + nl + sc + cryLic.result;

                        let param = await self.$api.getCrio(qry_string);

                        var trustedSrc = self.urlagentbuild + '?pl=' + encodeURIComponent(param.result);
                        //console.log(trustedSrc);
                        self.parentIframeSrc = trustedSrc;
                        // il componente padre (Dashboard.vue) ascolterà gli eventi emessi dal componente figlio (Navigation.vue)
                        // va dunque a cambiare la proprietà src dell'iframe (iframe si apre su Dashboard.vue, mentre il link viene creato in Navigation.vue)
                        //this.$emit('update-iframesrc', self.trustedSrc);

                    }

                }

            },

            // [TG - 18/06/2024 - gestione finiestra generazione agent]
            updateParentIframeSrc(newIframeSrc) {
                this.parentIframeSrc = newIframeSrc;
            },

            
            // --------------------------------------------------------------------------

    },
    watch: {
        license: {
            async handler(val) {
                if(val!=null && this.$root.checkAuth() != null){
                    this.pricing = await this.$api.getPricing(val);
                }
            },
            deep: true,
            immediate:true
        }

    },
}
</script>

