<template>
    <div v-on:keyup.esc="$root.$emit('CLOSERESTOREDIALOG')" class="dialog light w-100-sm w-100-md w-75-lg  w-50-xl" v-cloak>

        <DialogTitle :title="$t('Configure ESXI Restore')" :defaultTitle="$t('Configure ESXI Restore')"
            :subTitle="$t('ESXi Backups')" @closeDialog="$root.$emit('CLOSERESTOREDIALOG')" />

        <div class="dialog-content p-4">
            <!-- SELEZIONE DEI BACKUP E DEL SINGOLO RESTORE RELATO AL JOB-->
            <div class="row" v-show="steps[0]">

                <div v-if="wait" data-role="activity" data-type="metro" data-style="dark"></div>
                <div id="esxilogslist" class="pb-4 w-100" style="overflow: scroll;">

                    <table data-role="sorter" data-sort-dir="desc" class="table striped text-small " v-if="!wait"
                        data-show-search="true" data-show-rows-steps="false" data-search-min-length="3"
                        data-search-threshold="300">

                        <thead>
                            <tr>
                                <td style="text-align: center;">Sel</td>
                                <td data-name="dt_start_utc" data-sortable="true" data-format="int">{{ $t('Start Date')
                                    }}</td>
                                <td data-name="dt_end_utc" data-sortable="true">{{ $t('End Date') }}</td>
                                <td data-name="backup_size" data-sortable="true">{{ $t('Backup Size') }}</td>
                                <td data-name="copied_files" data-sortable="true" data-sort-dir="desc">
                                    {{ $t('Copied files') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(forlog, index) in logs" :key="forlog.id" track-by="log.id">
                                <td>
                                    <input class="ml-5" name="r1" type='radio' data-role='radio'
                                        @click="getJobConfiguration(forlog, index)">
                                </td>
                                <td>{{ convertDate(forlog.dt_start_utc) }}</td>
                                <td>{{ convertDate(forlog.dt_end_utc) }}</td>
                                <td>{{ forlog.backup_size | prettyBytes }}</td>
                                <td>{{ forlog.copied_files }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


            </div>
            <!-- SELEZIONE DELLA VM PER RESTORE-->
            <div class="row" v-show="steps[1]">
                <div v-if="waitvm" data-role="activity" data-type="metro" data-style="dark"></div>
                <div id="esxivmslist" class="pb-4 w-100" style="overflow: scroll;">

                    <table data-role="sorter" data-sort-dir="desc" class="table striped text-small" v-if="!waitvm"
                        data-show-search="true" data-show-rows-steps="false" data-search-min-length="3"
                        data-search-threshold="300">

                        <thead>
                            <tr>
                                <!--th style="text-align: center;"><input class="ml-5" name="r3" type='checkbox'
                                                    data-role='checkbox'></th-->
                                <td></td>
                                <td data-name="name" data-sortable="true" data-format="int">{{ $t('VM Name') }}</td>
                                <td data-name="os" data-sortable="true">{{ $t('Operating System') }}</td>
                                <!--th></td>
                                            <td></th-->
                                <!--th data-name="backup_size" data-sortable="true">Backup Size</th-->
                                <!--th data-name="copied_files" data-sortable="true" data-sort-dir="desc">Copied Files</th-->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(vm) in virtualMachines" :key="vm.name" track-by="vm.name">
                                <td>
                                    <input class="ml-5" name="r2" type='radio' data-role='radio'
                                        @click="onRadioChange(vm)">
                                </td>
                                <td>{{ vm.Name }}</td>
                                <td>{{ vm.OS }}</td>
                                <!-- th></th--> <!--quin ci deve andare il progress con subscribe da SignalR-->
                                <!--td><button class="button"><span class="mif-layers"></span> Restore</button></td-->
                                <!--td>{{ log.backup_size | prettyBytes }}</td-->
                                <!--td>{{ log.copied_files  }}</td-->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- SELEZIONE DELL'ACCOUNT PER RESTORE-->
            <div v-show="steps[2]" class="container-fluid">

                <!-- div class="row mb-3"-->
                <div class="row"><b>{{ $t('Select a Vmware ESXi / VCenter Account') }}:</b></div>
                <div class="row">
                    <select class="select w-100" v-model="id_service_account" @change="setIdServiceAccount">
                        <option :value="-1">{{ $t('Create custom service account') }}</option>
                        <option v-for="s in $session.getServiceAccounts().filter(s => s.type == 4)" :value="s.id"
                            v-bind:key="s.id">{{s.name}}</option>
                    </select>
                </div>
                <!--/div-->

            </div>

            <div v-show="steps[2] && (typeof id_service_account) == 'string'" class="container-fluid">
                <div class="row mt-4" v-if="source.esxiSource">
                    <span class="mif-done mif-lg fg-green"></span><span>{{ $t('Connected successfully') }}</span>
                    <span class="small-text"> ({{ source.esxiSource.url }}-{{ source.esxiSource.hostVersion }})</span>
                </div>
                <div v-if="dataStoreList" class="row mt-4"><b>{{ $t('Select a Datastore') }}:</b></div>

                <span v-if="source.esxiSource == null" class="mt-4 mif-spinner2 ani-spin mr-1"></span><span
                    v-if="source.esxiSource == null">{{ $t('Reading data from server') }}...</span>

                <div class="row">

                    <select data-role="select" data-filter="false" v-if="dataStoreList" v-model="dataStore">
                        <option v-for="(datastore, index) in dataStoreList"
                            v-bind:key="datastore.datastore.name + '_' + index" :value="datastore">
                            [{{ datastore.datacentername }}]/[{{ datastore.resourcename }}] {{
                            datastore.datastore.name
                            }}
                            [{{ datastore.datastore.freeSpace_string }}/{{ datastore.datastore.capacity_string }}]
                        </option>
                    </select>

                </div>
                <div class="row mt-4" v-if="dataStore">
                    <label><b>{{ $t('Virtual machine name (the name must not already exist)') }}:</b></label>
                </div>
                <div class="row" v-if="dataStore">
                    <input type="text" class="w-50" data-role="textbox" v-model="suggestedRestoredVmName" v-fsitem/>
                </div>
                <!--div class="row mt-4" v-if="dataStore">
                                <input type="checkbox" data-role="checkbox" data-caption="Port (CBT)" v-model="portchech"/>
                            </div-->
                <div class="row mt-4" v-if="dataStore">
                    <label><b>{{ $t('Port (CBT)') }}:</b></label>
                </div>
                <div class="row" v-if="dataStore">
                    <input type="number" v-number  minlength="2" maxlength="5" placeholder="Port" v-model.number="port" v-range="{min:10, max:65535}"/>
                </div>
            </div>


            <!--SUMMARY END BEFORE START RESTORE -->
            <div v-show="steps[3]" class="container-fluid">

                <div v-if="dataStoreList" class="row mt-4 mb-2"><b>{{ $t('Summary') }}:</b></div>

                <div class="row mb-2">
                    <div v-if="source.esxiSource != null" class="cell-3">{{ $t('Server') }}</div>
                    <div v-if="source.esxiSource != null" class="cell">{{ source.esxiSource.url
                        }}-{{source.esxiSource.hostVersion }}</div>
                </div>

                <div class="row mb-2" v-if="job.destinations">
                    <div v-if="job" class="cell-3">{{ $t('Backup path') }}</div>
                    <div v-if="job" class="cell">{{ job.destinations[0].path }}</div>
                </div>

                <div class="row mb-2" v-if="selectedlog.dt_start_utc && job">
                    <div class="cell-3">{{ $t('Selected backup') }}</div>
                    <div class="cell">{{ convertDate(selectedlog.dt_start_utc) }} {{ selectedlog.backup_number }}
                        {{selectedlog.backup_size | prettyBytes }} {{backupTypeToName(job.options.backupType,
                        selectedlog.backup_number) }}</div>
                </div>
                <div class="row mb-2" v-if="dataStore">
                    <div class="cell-3">{{ $t('Destination datastore') }}</div>
                    <div class="cell">[{{ dataStore.datacentername }}]/[{{ dataStore.resourcename }}] {{
                        dataStore.datastore.name }}
                        [{{ dataStore.datastore.freeSpace_string }}/{{ dataStore.datastore.capacity_string  }}]</div>
                </div>
                <div class="row mb-2">
                    <div class="cell-3">{{ $t('Name of the target VM') }}</div>
                    <div class="cell">{{ suggestedRestoredVmName }}</div>
                </div>

            </div>

            <div v-if="steps[1] && restore" class="container-fluid">

                <div class="row mt-4 ml-2">
                    <div class="cell-8">
                        <label>{{ restore.vmname }}</label>
                        <br />
                        <!--  <span class='badge inline bg-black fg-white m-2 p-1 w-33'>{{backup.scheduling==null || backup.scheduling==''?"On Demand":"Queued"}}</span> -->
                        <span class='badge inline bg-cobalt fg-white p-1 mt-2 mr-1'>{{restore.type_name}}</span>
                        <span class='badge inline bg-cobalt fg-white p-1 mt-2 mr-1'>{{restore.datacenter}}</span>
                        <span class='badge inline bg-cobalt fg-white p-1 mt-2 mr-1'>n. {{restore.backupnumber}}</span>
                        <span class='badge inline bg-cobalt fg-white p-1 mt-2 mr-1'>{{restore.datastore + '-' +
                            restore.host}}</span>
                    </div>

                    <div class="cell-8">
                        <label
                            v-if="restore.dt_start_utc != '' && restore.dt_start_utc != null">{{restore.dt_start_utc.substr(0,
                            8) + "T" + restore.dt_start_utc.substr(8) | utcAsLocal | moment("LLLL")
                            }}</label>
                    </div>
                    <div class="cell-8">
                        <LastResult :lastresult="restore.lastresult" />
                    </div>
                    <div class="cell-6">
                        <div v-show="restore.progress != null && restore.progress != 0">
                            <div class="clear">
                                <small v-if="restore.current_operation.length <= 50" class="place-left" data-role="hint"
                                    data-hint-position="top"
                                    :data-hint-text="restore.current_operation">{{restore.current_operation}}</small>
                                <small v-else-if="restore.current_operation <= 100" class="place-left" data-role="hint"
                                    data-hint-position="top"
                                    :data-hint-text="restore.current_operation">{{restore.current_operation.substr(0,
                                    50) + "..."}}</small>
                                <small v-else class="place-left" data-role="hint" data-hint-position="top"
                                    :data-hint-text="restore.current_operation">{{restore.current_operation.substr(0,50)
                                    + "..." + restore.current_operation.substr(-50, 50)}}</small>
                            </div>
                            <div data-role="progress" data-type="line" :data-value="restore.progress" data-small="true"
                                data-cls-bar="bg-cobalt" data-role-progress="true" class="progress small">
                                <div class="bar ribbed-cobalt" :style="{ width: restore.progress + '%' }">
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="cell-2">
                        <small v-if="restore.isRunning"
                            class="place-right text-bold enlarge-1">{{restore.progress}}%</small>
                    </div>
                </div>

            </div>



        </div>

        <div class="dialog-actions">
            <div class="ml-auto">
                <button class="button error float-right ml-2" @click="$root.$emit('CLOSERESTOREDIALOG')">{{ $t('Close')
                    }}</button>
                <button class="button" :class="{ 'disabled': steps[0] }" @click="back"><span
                        class="mif-arrow-left"></span>{{ $t('Prev') }}</button>
                <button class="button"
                    :class="{ 'disabled': (steps[0] && this.jobselected == '') || (steps[1] && this.vmtorestore == '') || (steps[2] && (this.dataStore == null || suggestedRestoredVmName == '')) || (steps[3]) || (steps[4]) }"
                    @click="next">{{ $t('Next') }}<span class="mif-arrow-right"> </span> </button>
                <button class="button primary float-right" v-show="steps[3]" @click="beginRestore"><span
                        class="mif-checkmark pr-2"></span>{{ $t('Restore') }}</button>
            </div>
        </div>

    </div>
</template>
<script>
import DialogTitle from "../../utils/DialogTitle";
import api from "../../../router/api";
import LastResult from "../../utils/LastResult";


export default {
    name: "ESXiDestinationRestore",
    props: {
        idPC: String,
        idJob: {
            type: String,
            required: true
        }
    },
    components: {
        "DialogTitle": DialogTitle,
        "LastResult": LastResult,
    },
    filters: {
      
    },

    data() {
        return {
            steps: [1, 0, 0],

            virtualMachines: [],
            wait: true,
            waitvm: true,
            jobselected: '',
            checkedNames: [],
            id_service_account: -2,

            go: false,
            justOneGuid: '',

            restores: null, // sochet [GT - 06/06/2023]
            restore: null,

            // model to send for restore
            ESXiRestoreModel:
            {
                Id: '',
                JobId: '', // questo lo manda l'interfaccia client, da recuperare dal job del backup
                LogId: '', // questo lo manda l'interfaccia client
                esxiAccount: null,
                ImpersonationUsername: '',
                ImpersonationPassword: '',
                NetworkUsername: '',
                NetworkPassword: '',
                Command: 'restore',
                UseVDDK: true,
                VDDKDllConfigFile: '',
                BackupNumber: 0,
                VirtualMachines: '',// "ESXi8»192.168.0.121»datastore1»Ubuntu20_restored_24173739",                    
                VmFolderPath: '',// @"D:\VM_TEST_MAREK\Ubuntu20\",
                //ExternalDllLogFile = Path.Combine(currentJobLogPath, DateTime.Now.ToString("yyyyMMddHHmmss") + "_Restore-ESXI.log"),// @"G:\IPERIUS_DEV_FILES\ESXiRestore\20230224173805\Restore-01896-ESXi.log",
                //LogFilePath = currentJobLogPath,
            },
            job: {},
            selectedlog: {},
            logs: [],

            vmtorestore: '',
            source: {},

            destination: {
                id: null,
                type: 4,
                type_name: "ESXi",
                port: 21,
                id_service_account: -2,
                id_repository: -2,
                region: "",
                options: {
                    signatureversion: 2,
                    connectionType: 'ftps'
                },
                icon: "assets/img/icons/disk.png",
                path: "",
            },

            ESXiVmPowerState: [
                "OFF", "ON", "SUSPENDED"
            ],

            suggestedRestoredVmName: '',

            dataStoreList: null,
            dataStore: null,
            esxiselected: null,

            folder: null,

            connection: null,

            pc_isconnected: '',
            portchech: null,
            port: null,
            virtualMachineName: ''
        }
    },

    created: function () {
        //if (Object.keys(this.inputSource).length != 0)
        //this.source = Object.assign(this.source, this.inputSource);
        //this.createConnection(); // connessione a SignalR per la lettura di host,datastore, virtualmachines
        this.getLogsList();
        //this.getESXIBackupList();
        //this.getBackup();
        //this.createTreeview();
    },

    methods: {

        // primo metodo ad essere eseguito
        // mostra tutte le esecuzioni con relativa configurazione
        async getLogsList() {
            let self = this;

            await this.$api.getBackupLogs(self.idJob).then(response => {
                self.logs = response;
                self.wait = false;
            });

        },

        // mostra la configurazione completa del singolo backup esxi
        async getJobConfiguration(log) {

            let self = this;
            self.jobselected = ''; // svuoto la variabile che attiva pulsante next

            self.selectedlog = log;

            await this.$api.getBackupLogConfiguration(log.id).then(response => {

                console.log('[ESXiDestinationRestore.getJobConfiguration] - api get backupconfigurations');
                console.log('[ESXiDestinationRestore.getJobConfiguration] - ' + response);

                self.job = {}

                if (!self.job) {
                    self.job = response;
                }
                else {
                    self.job = Object.assign(self.job, response)
                }

                if (self.job.options) {
                    self.options = self.job.options
                }

                //valorizza l'array con le virtual machine incluse nel singolo backup
                self.populateVMArray();

                self.jobselected = log.id; // attiva il pulsante next           

            });


        },

        //api/jobs/{id}?fview=true
        getESXIBackupList() {
            let self = this;
            api.get('/jobs/' + self.idJob + '?fview=true', {
                headers: {
                    Authorization: 'Bearer ' + self.$root.checkAuth().access_token
                }
            })
                .then(
                    (response => { self.source = response.data })
                )
        },

        onRadioChange(vm) {
            let self = this;

            self.vmtorestore = vm.Name;
            vm.selectedRestore = true;

            console.log('[onRadioChange] --> vm name to restore: ' + vm.Name)
            const uniquestringdata = new Date().getDate() + '' + new Date().getHours() + '' + new Date().getMinutes() + '' + new Date().getSeconds();
            self.suggestedRestoredVmName = vm.Name + '_restored_' + uniquestringdata;

        },

        backupTypeToName(tipo) {

            if (this.number == '1') return this.$t('Complete (Full)');

            switch (tipo.toLowerCase().trim()) {
                case "f":
                    return this.$t('Complete (Full)');
                case "i":
                    return this.$t('Incremental');
                case "d":
                    return this.$t('Differential');
                default:
                    return '';
            }

        },

        async beginRestore() {
            var next = true;
            let self = this;

            self.go = confirm(this.$t("Restore the selected backup?"));


            if (self.go) {

                // faccio vedere la sezione del progress
                this.steps.forEach((step, index) => {
                    if (step == 1) {
                        if (next) {
                            this.$set(this.steps, index + 1, true)
                            this.$set(this.steps, index, false)
                            next = false;

                        }
                    }
                });

                self.justOneGuid = self.uuidv4();

                self.ESXiRestoreModel.Id = self.justOneGuid;
                self.ESXiRestoreModel.ID_LOG = self.selectedlog.id;
                self.ESXiRestoreModel.ID_JOB = self.selectedlog.id_job;
                self.ESXiRestoreModel.id_service_account = self.id_service_account;
                self.ESXiRestoreModel.Command = 'Restore';
                self.ESXiRestoreModel.BackupNumber = parseInt(self.selectedlog.backup_number);
                self.ESXiRestoreModel.VirtualMachines = self.dataStore.datacentername + '»' + self.dataStore.resourcename + '»' + self.dataStore.datastore.name + '»' + self.suggestedRestoredVmName
                self.ESXiRestoreModel.BackupFolderPath = self.job.destionations[0].path + '\\' + self.vmtorestore;
                self.ESXiRestoreModel.NetworkUsername = '';
                self.ESXiRestoreModel.NetworkPassword = '';
                self.ESXiRestoreModel.ImpersonationUsername = '';
                self.ESXiRestoreModel.ImpersonationPassword = '';
                self.ESXiRestoreModel.UseVDDK = true,
                    self.ESXiRestoreModel.VDDKDllConfigFile = '',
                    self.ESXiRestoreModel.type = self.job.options.backupType;
                self.ESXiRestoreModel.type_name = self.backupTypeToName(self.source.options.backupType, self.selectedlog.backup_number);

                console.log("[ESXIDestinationRestore.beginRestore]");

                this.$root.socket("RESTOREVIRTUALMACHINE", self.ESXiRestoreModel, self.id_service_account);

                // invoca agent per dare start ad invio delle informazioni sul restore
                self.idPC = await this.$root.getPC().id;
                //###DA INSERIRE IN OGNI COMPONENTE CHE NECESSITA DI CHIAMATE SOCKET###
                // "checkSocket" avvia la connessione socket se ferma
                // "socketEventConnected" viene emesso quando la connessione socket è attiva, quindi nella sua callback si possono avviare tutte le istruzioni che riguardano le chiamate socket
                // "socketEventDisconnected" viene emesso quando la connessione socket non è attiva
                this.$root.$on("socketEventConnected", () => {
                    self.$root.socketConnected = true;
                    self.pc_isconnected = true;
                    self.$root.socket("subscribe_pc", self.idPC).then(() => 
                        self.$root.socket("STARTSENDINFO")
                    );
                });
                this.$root.$on("socketEventDisconnected", () =>{
                    self.$root.socketConnected = false;
                    self.pc_isconnected = false;
                });
                this.$root.checkSocket();
                // -------------------------------------------------------------------- 


                // subscrive per ricevere il progress del restore ---------------------
                this.$root.$on("addRestoreToPage", restores => {
                    // TODO
                    console.log('Restore in popup');
                    console.log(restores);
                    //self.restores = restores;

                    self.restore = restores.find(obj => {
                        return obj.id == self.justOneGuid
                    })

                });
                // --------------------------------------------------------------------
            }
        },


        uuidv4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
                .replace(/[xy]/g, function (c) {
                    const r = Math.random() * 16 | 0,
                        v = c == 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
        },

        //valorizza l'array con le virtual machine incluse nel singolo backup
        populateVMArray() {
            let self = this;
            self.waitvm = true;
            var dc, cl, ds, vm; // , idsToClose = [];

            // svuoto array
            self.virtualMachines.splice(0);

            console.log('[ESXiDestinationRestore.populateVMArray] - self.job.backupSources: ' + self.job.sources);


            if (self.job.sources)
                if (self.job.sources.length > 0)
                    for (var idc = 0; idc < self.job.sources[0].esxiSource.DataCenters.length; idc++) { // ciclo datacenters
                        dc = self.job.sources[0].esxiSource.DataCenters[idc];
                        for (var icl = 0; icl < dc.ComputerResourceList.length; icl++) { // ciclo gli host che ospitano i datastore
                            cl = dc.ComputerResourceList[icl];
                            for (var ids = 0; ids < cl.Datastores.length; ids++) { // ciclo datastore 
                                ds = cl.Datastores[ids];
                                for (var ivm = 0; ivm < ds.VirtualMachines.length; ivm++) { // ciclo virtual machines
                                    vm = ds.VirtualMachines[ivm];
                                    vm.selectedRestore = false;
                                    if (!vm.Selected) {
                                        ds.VirtualMachines.splice(ivm, 1); // rimuove dall'array la vm non selezionata
                                        ivm--;
                                    } else {
                                        //idsToClose.push(vm.name.replace(" ", ""));
                                        self.virtualMachines.push(vm);
                                    }
                                }
                                if (ds.VirtualMachines.length == 0) {
                                    cl.Datastores.splice(ids, 1); // se non ho trovato nessuna virtual machines elimino il datastore dall'array
                                    ids--;
                                }
                            }
                            if (cl.Datastores.length == 0) {
                                dc.ComputerResourceList.splice(icl, 1); // se non ho trovato nessun datastore elimino l'host dall'array
                                icl--;
                            }
                        }
                        if (dc.ComputerResourceList.length == 0) {
                            self.job.sources[0].esxiSource.DataCenters.splice(idc, 1); // se non ho trovato nessun host elimino il datacenter dall'array
                            idc--;
                        }
                    } // first for


            setTimeout(function () {
                //var treeview = window.Metro.get$el("#esxiTreeview").data("treeview");
                //console.log(treeview)
                /*idsToClose.forEach(id => {
                    console.log(treeview);
                    console.log(treeview.toggleNode);
                    console.log(id);
                    console.log($("#" + id));

                    treeview.toggleNode($("#" + id)); // espande tutti i nodi 

                });*/
                self.waitvm = false;

            }, 1000);

        },
       
        convertDate(date) {
            date = this.$moment(date.substr(0, 8) + "T" + date.substr(8), "YYYYMMDD HH:mm:ss");
            return this.$moment.utc(date).format("ddd DD/MM/YYYY HH:mm:ss");
        },

        /*********************METODI***********************************************/


        /*********************************UTILS**************************************/
        async setIdServiceAccount() {

            let self = this;

            this.$root.$emit('setIdServiceAccount', this.id_service_account);
            if ((typeof this.id_service_account) == 'string')
                await this.$root.socket("GetVirtualMachinesESXi", this.id_service_account).then(response => {

                    console.log("setIdServiceAccount");
                    console.log(response);

                    self.source.esxiSource = response;

                    self.dataStoreList = self.parseDataStoreList(response);

                });
            //this.connection.invoke("GetVirtualMachinesESXi", this.id_service_account);
        },

        next() {
            var next = true;
            let self = this;

            // se è stata selezionata la macchina da ripristinare ne prendo il nome per mostrarlo nel campo di testo    
            if (self.virtualMachines != null && self.steps[1]) {
                for (var idc = 0; idc < self.virtualMachines.length; idc++) {
                    if (self.virtualMachines[idc].selected) {
                        const uniquestringdata = new Date().getHours() + '' + new Date().getMinutes() + '' + new Date().getSeconds() + '' + new Date().getMilliseconds()
                        self.virtualMachineName = self.virtualMachines[idc].name.replace(' ', '_') + '_restore_' + uniquestringdata;
                    }
                }
            }
            this.steps.forEach((step, index) => {
                if (step == 1) {
                    if (next) {
                        this.$set(this.steps, index + 1, true)
                        this.$set(this.steps, index, false)
                        next = false;

                    }
                }
            });

        },
        back() {
            var next = true;
            this.steps.forEach((step, index) => {
                if (step == 1) {
                    if (next) {
                        this.$set(this.steps, index - 1, true)
                        this.$set(this.steps, index, false)
                        next = false;

                    }
                }
            });

        },
      

        /************************CONNECTION SIGNAlR ********************************************************/
        parseDataStoreList(esxiObj) {

            //console.log(esxiObj);
            let dataStoreListArray = [];

            esxiObj.dataCenters.forEach(datacenter => {
                this.datacentername = datacenter.name;
                datacenter.computerResourceList.forEach(resource => {
                    this.resourcename = resource.name;
                    resource.datastores.forEach(datastore => {
                        var temp = {
                            "datacentername": this.datacentername,
                            "resourcename": this.resourcename,
                            "datastore": datastore
                        }
                        dataStoreListArray.push(temp);

                    })
                })
            });
            return dataStoreListArray;

        },

    },
    beforeDestroy: function () {
        this.$root.$off("socketEventConnected");
        this.$root.$off("socketEventDisconnected");
        if (this.connection != null) this.connection.stop();
    },
}
</script>