var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.pc && _vm.backupJob
    ? _c(
        "div",
        { staticClass: "h-100 overflow" },
        [
          _vm.pc && _vm.backupJob
            ? _c("Navigation", {
                attrs: {
                  pageName: "backup",
                  data: {
                    editing: _vm.editing,
                    backupName: _vm.backupJob.name,
                    pc: _vm.pc,
                  },
                },
              })
            : _vm._e(),
          !_vm.pc || !_vm.backupJob
            ? _c("div", { staticClass: "d-flex flex-justify-center p-10" }, [
                _c("span", {
                  staticClass: "mif-spinner2 fg-primary mif-3x ani-spin",
                }),
              ])
            : _c("div", {}, [
                _c("div", { staticClass: "p-2" }, [
                  _c(
                    "div",
                    { staticClass: "row d-flex flex-justify-end p-2" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "button mr-2 large",
                          on: {
                            click: function ($event) {
                              return _vm.setWizardStep(_vm.step - 1)
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "mif-arrow-left" }),
                          _vm._v(_vm._s(_vm.$t("Prev")) + " "),
                        ]
                      ),
                      _vm.step != 4
                        ? _c(
                            "button",
                            {
                              staticClass: "button large",
                              on: {
                                click: function ($event) {
                                  return _vm.setWizardStep(_vm.step + 1)
                                },
                              },
                            },
                            [
                              _vm._v(_vm._s(_vm.$t("Next"))),
                              _c("span", { staticClass: "mif-arrow-right" }),
                            ]
                          )
                        : _vm._e(),
                      _vm.step == 4
                        ? _c(
                            "button",
                            {
                              staticClass: "button primary large",
                              attrs: {
                                disabled:
                                  _vm.backupJob.sources.length == 0 ||
                                  _vm.backupJob.destinations.length == 0,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.sendBackupJob()
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("Save backup")) + " ")]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "ul",
                    {
                      attrs: {
                        "data-role": "tabs",
                        "data-update-uri": "true",
                        "data-on-tab": "console.log('tab', arguments)",
                        id: "wizardTabs",
                        "data-tabs-type": "pills",
                        "data-expand": "true",
                        "data-cls-tabs": "bg-transparent",
                      },
                    },
                    [
                      _c(
                        "li",
                        {
                          staticClass: "m-2 p-10 bg-lightgray",
                          class: _vm.saveBackupResult == 1 ? "disabled" : "",
                          staticStyle: { "min-width": "200px" },
                          on: {
                            click: function ($event) {
                              return _vm.openTab(1)
                            },
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "d-flex flex-justify-start m-0 p-0 h5 text-bold pb-3",
                              attrs: { href: "#source" },
                            },
                            [
                              _c("span", { staticClass: "mr-2" }, [
                                _vm._v("1."),
                              ]),
                              _vm._v(" " + _vm._s(_vm.$t("Source")) + " "),
                              _c("span", {
                                staticClass: "mif-chevron-thin-right",
                              }),
                            ]
                          ),
                          _c(
                            "a",
                            { staticClass: "p-0", attrs: { href: "#source" } },
                            [
                              _c("span", { staticClass: "mt-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "Select a source is the first step to creating your backup"
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "li",
                        {
                          staticClass: "m-2 p-10 bg-lightgray",
                          class:
                            _vm.backupJob.sources.length == 0 ? "disabled" : "",
                          staticStyle: { "min-width": "200px" },
                          on: {
                            click: function ($event) {
                              return _vm.openTab(2)
                            },
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "d-flex flex-justify-start m-0 p-0 h5 text-bold pb-3",
                              attrs: { href: "#destinations" },
                            },
                            [
                              _c("span", { staticClass: "mr-2" }, [
                                _vm._v("2."),
                              ]),
                              _vm._v(
                                " " + _vm._s(_vm.$t("Destinations")) + " "
                              ),
                              _c("span", {
                                staticClass: "mif-chevron-thin-right",
                              }),
                            ]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "p-0",
                              attrs: { href: "#destinations" },
                            },
                            [
                              _vm.backupJob.sources.length == 0
                                ? _c("span", { staticClass: "mt-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "Select a source path before selecting destination path"
                                        )
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.backupJob.sources.length != 0
                                ? _c("span", { staticClass: "mt-2" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("Select destination path"))
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "li",
                        {
                          staticClass: "m-2 p-10 bg-lightgray",
                          staticStyle: { "min-width": "200px" },
                          on: {
                            click: function ($event) {
                              return _vm.openTab(3)
                            },
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "d-flex flex-justify-start m-0 p-0 h5 text-bold pb-3",
                              attrs: { href: "#scheduling " },
                            },
                            [
                              _c("span", { staticClass: "mr-2" }, [
                                _vm._v("3."),
                              ]),
                              _vm._v(" " + _vm._s(_vm.$t("Schedulings")) + " "),
                              _c("span", {
                                staticClass: "mif-chevron-thin-right",
                              }),
                            ]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "p-0",
                              attrs: { href: "#scheduling" },
                            },
                            [
                              _c("span", { staticClass: "mt-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "Create a schedule for repeatedly running the backup"
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "li",
                        {
                          staticClass: "m-2 p-10 bg-lightgray",
                          class:
                            _vm.backupJob.destinations.length == 0 ||
                            _vm.backupJob.sources.length == 0
                              ? "disabled"
                              : "",
                          staticStyle: { "min-width": "200px" },
                          on: {
                            click: function ($event) {
                              return _vm.openTab(4)
                            },
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "d-flex flex-justify-start m-0 p-0 h5 text-bold pb-3",
                              attrs: { href: "#result" },
                            },
                            [
                              _c("span", { staticClass: "mr-2" }, [
                                _vm._v("4."),
                              ]),
                              _vm._v(" " + _vm._s(_vm.$t("Summary")) + " "),
                            ]
                          ),
                          _c(
                            "a",
                            { staticClass: "p-0", attrs: { href: "#result" } },
                            [
                              _c("span", { staticClass: "mt-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "Give your backup a name and save it"
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "p-2" }, [
                    _c("div", { attrs: { id: "source" } }, [
                      _c("section", { staticClass: "page-content p-4" }, [
                        _c(
                          "div",
                          { staticClass: "row d-flex flex-align-center" },
                          [
                            _c("span", [
                              _vm._v(
                                " " + _vm._s(_vm.$t("Add a backup source"))
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "ul",
                          {
                            attrs: {
                              "data-role": "listview",
                              "data-view": "icons-medium",
                              "data-select-node": "true",
                              "data-selectable": "false",
                            },
                          },
                          _vm._l(_vm.backupSourcesTypes, function (sourceType) {
                            return _c("li", {
                              attrs: {
                                "data-icon":
                                  _vm.$platformsTypesInfo[sourceType].icon,
                                "data-caption":
                                  _vm.$platformsTypesInfo[sourceType].name,
                                disabled: !_vm.isEnabled_source(sourceType),
                                id:
                                  sourceType == _vm.$PlatformsTypes.Microsoft
                                    ? "microsoft_toggle"
                                    : "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.openSource(sourceType)
                                },
                              },
                            })
                          }),
                          0
                        ),
                        _c("div", { staticClass: "pos-relative" }, [
                          _c(
                            "div",
                            {
                              attrs: {
                                "data-role": "collapse",
                                "data-toggle-element": "#microsoft_toggle",
                                "data-collapsed": "true",
                              },
                            },
                            [
                              _c(
                                "ul",
                                {
                                  attrs: {
                                    "data-role": "listview",
                                    "data-view": "icons-medium",
                                  },
                                },
                                [
                                  _c(
                                    "li",
                                    {
                                      attrs: {
                                        "data-caption":
                                          _vm.$platformsTypesInfo[
                                            _vm.$PlatformsTypes.Microsoft
                                          ].name,
                                      },
                                    },
                                    [
                                      _c(
                                        "ul",
                                        _vm._l(
                                          _vm.$root.getMicrosoftTypes(),
                                          function (t) {
                                            return _c("li", {
                                              key: t,
                                              attrs: {
                                                "data-icon":
                                                  _vm.$platformsTypesInfo[t]
                                                    .icon,
                                                "data-caption":
                                                  _vm.$platformsTypesInfo[t]
                                                    .name,
                                                disabled:
                                                  _vm.$platformsTypesInfo[t]
                                                    .needSocketConnection &&
                                                  !_vm.$root.connectionsStates[
                                                    _vm.pc.id
                                                  ],
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openSource(t)
                                                },
                                              },
                                            })
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _vm.backupJob.sources.length != 0
                          ? _c(
                              "table",
                              {
                                staticClass:
                                  "table striped d-table-lg mx-3 my-6 bg-white table-bordered border",
                              },
                              [
                                _c("thead", [
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          "data-sortable": "true",
                                          "data-sort-dir": "asc",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Type")))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          "data-sortable": "true",
                                          "data-sort-dir": "asc",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Selected Items")))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-center",
                                        attrs: {
                                          "data-sortable": "true",
                                          "data-sort-dir": "asc",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Options")))]
                                    ),
                                    _c("td"),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.backupJob.sources,
                                    function (source, index) {
                                      return _c("tr", { key: index }, [
                                        _c("td", { staticClass: "va-top" }, [
                                          _c("label", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "badge inline bg-black fg-white p-1 mt-2",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$platformsTypesInfo[
                                                        source.type
                                                      ].name
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                        _c(
                                          "td",
                                          [
                                            _c("SourceView", {
                                              attrs: { source: source },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "va-top text-center" },
                                          [
                                            source.options.rct
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "badge inline fg-white m-1",
                                                    class: {
                                                      "bg-dark":
                                                        source.options.rct ==
                                                        "1",
                                                      "bg-lightGray":
                                                        source.options.rct ==
                                                        "2",
                                                    },
                                                  },
                                                  [_vm._v(" RCT")]
                                                )
                                              : _vm._e(),
                                            source.enableCompression !=
                                              undefined &&
                                            source.enableCompression != false
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "badge inline fg-white m-1",
                                                    class:
                                                      source.enableCompression
                                                        ? "bg-dark"
                                                        : "bg-lightGray",
                                                  },
                                                  [_vm._v("ZIP ")]
                                                )
                                              : _vm._e(),
                                            source.options.disableDasdIo !=
                                            undefined
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "badge inline fg-white m-1",
                                                    class: source.options
                                                      .disableDasdIo
                                                      ? "bg-dark"
                                                      : "bg-lightGray",
                                                  },
                                                  [_vm._v("DASD")]
                                                )
                                              : _vm._e(),
                                            source.options.backupMode
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "badge inline fg-white bg-dark m-1",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          source.options
                                                            .backupMode
                                                        )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            source.options.backupType !=
                                            undefined
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "badge inline fg-white m-1",
                                                    class: {
                                                      "bg-dark":
                                                        source.options
                                                          .backupType,
                                                      "bg-lightGray":
                                                        !source.options
                                                          .backupType,
                                                    },
                                                    attrs: {
                                                      title:
                                                        _vm.$root.selectValues
                                                          .backupTypes[
                                                          source.options
                                                            .backupType
                                                        ],
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          source.options.backupType.toUpperCase()
                                                        )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            source.options.outputFormat
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "badge inline fg-white m-1 bg-dark",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          source.options.outputFormat.toUpperCase()
                                                        )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            source.options.backupLog
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "badge inline fg-white m-1 bg-dark",
                                                  },
                                                  [_vm._v("LOG")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-right va-top" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "button flat-button",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$root.$emit(
                                                      "OPENSOURCEDIALOG",
                                                      source
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "mif-pencil",
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "button flat-button",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteSource(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "mif-bin fg-red",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { attrs: { id: "destinations" } }, [
                      _vm.backupJob.sources.length != 0
                        ? _c("section", { staticClass: "page-content p-4" }, [
                            _c(
                              "div",
                              { staticClass: "row d-flex flex-align-center" },
                              [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "Add one or more backup destinations"
                                        )
                                      )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "ul",
                              {
                                attrs: {
                                  "data-role": "listview",
                                  "data-view": "icons-medium",
                                  "data-select-node": "true",
                                  "data-selectable": "false",
                                },
                              },
                              _vm._l(
                                _vm.$platformsTypesInfo[
                                  _vm.backupJob.sources[0].type
                                ].backupDestinationTypes,
                                function (destType) {
                                  return _c("li", {
                                    attrs: {
                                      "data-icon":
                                        _vm.$platformsTypesInfo[destType].icon,
                                      "data-caption":
                                        _vm.$platformsTypesInfo[destType].name,
                                      id:
                                        destType == _vm.$PlatformsTypes.Cloud
                                          ? "cloud_toggle"
                                          : "",
                                      disabled:
                                        _vm.$platformsTypesInfo[destType]
                                          .needSocketConnection &&
                                        !_vm.$root.connectionsStates[_vm.pc.id],
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openDestination(destType)
                                      },
                                    },
                                  })
                                }
                              ),
                              0
                            ),
                            _c("div", { staticClass: "pos-relative" }, [
                              _c(
                                "div",
                                {
                                  attrs: {
                                    "data-role": "collapse",
                                    "data-toggle-element": "#cloud_toggle",
                                    "data-collapsed": "true",
                                  },
                                },
                                [
                                  _c(
                                    "ul",
                                    {
                                      attrs: {
                                        "data-role": "listview",
                                        "data-view": "tiles",
                                      },
                                    },
                                    _vm._l(
                                      _vm.$root.getCloudTypes(),
                                      function (t) {
                                        return _c("li", {
                                          key: t,
                                          attrs: {
                                            "data-icon":
                                              _vm.$platformsTypesInfo[t].icon,
                                            "data-caption":
                                              _vm.$platformsTypesInfo[t].name,
                                            disabled:
                                              _vm.$platformsTypesInfo[t]
                                                .needSocketConnection &&
                                              !_vm.$root.connectionsStates[
                                                _vm.pc.id
                                              ],
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$root.$emit(
                                                "OPENDESTINATIONDIALOG",
                                                t
                                              )
                                            },
                                          },
                                        })
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ]),
                            _vm.backupJob.destinations.length != 0
                              ? _c(
                                  "table",
                                  {
                                    staticClass:
                                      "table striped d-table-lg mx-3 my-6 bg-white table-bordered border",
                                  },
                                  [
                                    _c("thead", [
                                      _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            attrs: {
                                              "data-sortable": "true",
                                              "data-sort-dir": "asc",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("Type")))]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            attrs: {
                                              "data-sortable": "true",
                                              "data-sort-dir": "asc",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("Name")))]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            attrs: {
                                              "data-sortable": "true",
                                              "data-sort-dir": "asc",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("Selected Items"))
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            attrs: {
                                              "data-sortable": "true",
                                              "data-sort-dir": "asc",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("Options")))]
                                        ),
                                        _c("td"),
                                      ]),
                                    ]),
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        _vm.backupJob.destinations,
                                        function (destination, index) {
                                          return _c("tr", { key: index }, [
                                            _c("td", [
                                              _c("label", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "badge inline bg-black fg-white p-1 mt-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm
                                                            .$platformsTypesInfo[
                                                            destination.type
                                                          ].name
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(destination.name) +
                                                  " "
                                              ),
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(destination.path) +
                                                  " "
                                              ),
                                            ]),
                                            _c("td", [
                                              destination.options.rct
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "badge inline fg-white m-1",
                                                      class: {
                                                        "bg-dark":
                                                          destination.options
                                                            .rct == "1",
                                                        "bg-lightGray":
                                                          destination.options
                                                            .rct == "2",
                                                      },
                                                    },
                                                    [_vm._v(" RCT")]
                                                  )
                                                : _vm._e(),
                                              destination.enableCompression !=
                                              undefined
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "badge inline fg-white m-1",
                                                      class:
                                                        destination.enableCompression
                                                          ? "bg-dark"
                                                          : "bg-lightGray",
                                                    },
                                                    [_vm._v("ZIP ")]
                                                  )
                                                : _vm._e(),
                                              destination.options
                                                .disableDasdIo != undefined
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "badge inline fg-white m-1",
                                                      class: destination.options
                                                        .disableDasdIo
                                                        ? "bg-dark"
                                                        : "bg-lightGray",
                                                    },
                                                    [_vm._v(" DASD")]
                                                  )
                                                : _vm._e(),
                                              destination.options.backupMode
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "badge inline fg-white bg-dark m-1",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            destination.options
                                                              .backupMode
                                                          )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              destination.options.backupType !=
                                              undefined
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "badge inline fg-white m-1",
                                                      class: {
                                                        "bg-dark":
                                                          _vm.source.options
                                                            .backupType,
                                                        "bg-lightGray":
                                                          !destination.options
                                                            .backupType,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            destination.options.backupType.toUpperCase()
                                                          )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              destination.options.outputFormat
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "badge inline fg-white m-1 bg-dark",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            destination.options.outputFormat.toUpperCase()
                                                          )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              destination.options.backupLog
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "badge inline fg-white m-1 bg-dark",
                                                    },
                                                    [_vm._v("LOG")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "button flat-button",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.$root.$emit(
                                                          "OPENDESTINATIONDIALOG",
                                                          destination
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass: "mif-pencil",
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "button flat-button",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteDestination(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass: "mif-bin",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", { attrs: { id: "scheduling" } }, [
                      _c("section", { staticClass: "overflow w-100" }, [
                        _c("div", { staticClass: "page-content p-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group d-flex flex-column" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.schedulationType,
                                    expression: "schedulationType",
                                  },
                                ],
                                attrs: {
                                  name: "schedulationType",
                                  type: "radio",
                                  "data-role": "radio",
                                  "data-caption": _vm.$t("No scheduling"),
                                },
                                domProps: {
                                  value: 0,
                                  checked: _vm._q(_vm.schedulationType, 0),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.schedulationType = 0
                                  },
                                },
                              }),
                              _vm.$session.getSchedulingPolicies().length != 0
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.schedulationType,
                                        expression: "schedulationType",
                                      },
                                    ],
                                    attrs: {
                                      name: "schedulationType",
                                      type: "radio",
                                      "data-role": "radio",
                                      "data-caption": _vm.$t(
                                        "Use an existing scheduling policy"
                                      ),
                                    },
                                    domProps: {
                                      value: 1,
                                      checked: _vm._q(_vm.schedulationType, 1),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.schedulationType = 1
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.schedulationType,
                                    expression: "schedulationType",
                                  },
                                ],
                                attrs: {
                                  name: "schedulationType",
                                  type: "radio",
                                  "data-role": "radio",
                                  "data-caption": _vm.$t(
                                    "Create a custom scheduling"
                                  ),
                                },
                                domProps: {
                                  value: 2,
                                  checked: _vm._q(_vm.schedulationType, 2),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.schedulationType = 2
                                  },
                                },
                              }),
                            ]
                          ),
                          _c("div", [
                            _vm.schedulationType == 1 &&
                            _vm.$session.getSchedulingPolicies().length != 0
                              ? _c("div", [
                                  _c("div", { staticClass: "content" }, [
                                    _c("div", { staticClass: "p-4 d-flex" }, [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.backupJob
                                                  .id_scheduling_policy,
                                              expression:
                                                "backupJob.id_scheduling_policy",
                                            },
                                          ],
                                          staticClass:
                                            "select input w-auto p-1",
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.backupJob,
                                                "id_scheduling_policy",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        _vm._l(
                                          _vm.$session.getSchedulingPolicies(),
                                          function (policy) {
                                            return _c(
                                              "option",
                                              {
                                                domProps: { value: policy.id },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(policy.name) +
                                                    " (" +
                                                    _vm._s(
                                                      _vm.cronstrue.toString(
                                                        policy.policy
                                                          .scheduling[0],
                                                        {
                                                          locale:
                                                            _vm.$i18n.locale,
                                                          dayOfWeekStartIndexZero: false,
                                                        }
                                                      )
                                                    ) +
                                                    ")"
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.schedulationType == 2
                              ? _c("div", [
                                  _c("div", { staticClass: "content" }, [
                                    _vm.backupJob.scheduling.length == 0
                                      ? _c("div", { staticClass: "row P-2" }, [
                                          _c("div", { staticClass: "w-100" }, [
                                            _c(
                                              "ul",
                                              {
                                                staticStyle: { "z-index": "1" },
                                                attrs: {
                                                  "data-role": "tabs",
                                                  "data-tabs-type": "",
                                                  "data-expand": "true",
                                                  "data-cls-tabs":
                                                    "bg-transparent",
                                                },
                                              },
                                              [
                                                _c("li", [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "#minutes",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.toggleTab(
                                                            _vm.cronOptions
                                                              .minutes
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("Minutes")
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "#hourly",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.toggleTab(
                                                            _vm.cronOptions
                                                              .hourly
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("Hourly")
                                                        ) + " "
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: { href: "#daily" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.toggleTab(
                                                            _vm.cronOptions
                                                              .daily
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.$t("Daily"))
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "#weekly",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.toggleTab(
                                                            _vm.cronOptions
                                                              .weekly
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.$t("Weekly"))
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "#montly",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.toggleTab(
                                                            _vm.cronOptions
                                                              .monthly
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("Monthly")
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "#yearly",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.toggleTab(
                                                            _vm.cronOptions
                                                              .yearly
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("Yearly")
                                                        ) + " "
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "bg-white border no-border-top p-4",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "p-4",
                                                    attrs: { id: "minutes" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-row align-items-center",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-lg-1",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "col-form-label inline",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "Every"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "select",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .cronOptions
                                                                        .minutes
                                                                        .value,
                                                                    expression:
                                                                      "cronOptions.minutes.value",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "form-control select w-auto",
                                                                staticStyle: {
                                                                  display:
                                                                    "inline",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      var $$selectedVal =
                                                                        Array.prototype.filter
                                                                          .call(
                                                                            $event
                                                                              .target
                                                                              .options,
                                                                            function (
                                                                              o
                                                                            ) {
                                                                              return o.selected
                                                                            }
                                                                          )
                                                                          .map(
                                                                            function (
                                                                              o
                                                                            ) {
                                                                              var val =
                                                                                "_value" in
                                                                                o
                                                                                  ? o._value
                                                                                  : o.value
                                                                              return val
                                                                            }
                                                                          )
                                                                      _vm.$set(
                                                                        _vm
                                                                          .cronOptions
                                                                          .minutes,
                                                                        "value",
                                                                        $event
                                                                          .target
                                                                          .multiple
                                                                          ? $$selectedVal
                                                                          : $$selectedVal[0]
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "1",
                                                                    },
                                                                  },
                                                                  [_vm._v("1")]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "2",
                                                                    },
                                                                  },
                                                                  [_vm._v("2")]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "3",
                                                                    },
                                                                  },
                                                                  [_vm._v("3")]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "4",
                                                                    },
                                                                  },
                                                                  [_vm._v("4")]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "5",
                                                                    },
                                                                  },
                                                                  [_vm._v("5")]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "6",
                                                                    },
                                                                  },
                                                                  [_vm._v("6")]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "10",
                                                                    },
                                                                  },
                                                                  [_vm._v("10")]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "15",
                                                                    },
                                                                  },
                                                                  [_vm._v("15")]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "20",
                                                                    },
                                                                  },
                                                                  [_vm._v("20")]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "30",
                                                                    },
                                                                  },
                                                                  [_vm._v("30")]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "col-form-label",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "minute(s)"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "p-4",
                                                    attrs: { id: "hourly" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card-body",
                                                        attrs: { id: "idb" },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-inline",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "form-check-label",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .cronOptions
                                                                          .hourly
                                                                          .radio,
                                                                      expression:
                                                                        "cronOptions.hourly.radio",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-check-input mb-1",
                                                                  attrs: {
                                                                    "data-role":
                                                                      "radio",
                                                                    type: "radio",
                                                                    name: "pnlDetails:pnlHourly:radioGroup",
                                                                    value:
                                                                      "every/",
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      _vm._q(
                                                                        _vm
                                                                          .cronOptions
                                                                          .hourly
                                                                          .radio,
                                                                        "every/"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm
                                                                            .cronOptions
                                                                            .hourly,
                                                                          "radio",
                                                                          "every/"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Every"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .cronOptions
                                                                              .hourly
                                                                              .value,
                                                                          expression:
                                                                            "cronOptions.hourly.value",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control ml-1 mr-1 select w-auto",
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "inline",
                                                                      },
                                                                    attrs: {
                                                                      name: "pnlDetails:pnlHourly:radioGroup:ddHours",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal =
                                                                            Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                          _vm.$set(
                                                                            _vm
                                                                              .cronOptions
                                                                              .hourly,
                                                                            "value",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "1",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "1"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "2",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "2"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "3",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "3"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "4",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "4"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "6"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "12",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "12"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "hour(s)"
                                                                      )
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-inline mt-3",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "form-check-label",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .cronOptions
                                                                          .hourly
                                                                          .radio,
                                                                      expression:
                                                                        "cronOptions.hourly.radio",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-check-input mb-1",
                                                                  attrs: {
                                                                    "data-role":
                                                                      "radio",
                                                                    type: "radio",
                                                                    name: "pnlDetails:pnlHourly:radioGroup",
                                                                    value:
                                                                      "at?",
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      _vm._q(
                                                                        _vm
                                                                          .cronOptions
                                                                          .hourly
                                                                          .radio,
                                                                        "at?"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm
                                                                            .cronOptions
                                                                            .hourly,
                                                                          "radio",
                                                                          "at?"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Starts at"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-inline",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "select",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                _vm
                                                                                  .cronOptions
                                                                                  .hourly
                                                                                  .at
                                                                                  .hour,
                                                                              expression:
                                                                                "cronOptions.hourly.at.hour",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "form-control mr-1 ml-1 select w-auto",
                                                                        staticStyle:
                                                                          {
                                                                            display:
                                                                              "inline",
                                                                          },
                                                                        attrs: {
                                                                          name: "pnlDetails:pnlHourly:radioGroup:pnlTime:hourPart",
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              var $$selectedVal =
                                                                                Array.prototype.filter
                                                                                  .call(
                                                                                    $event
                                                                                      .target
                                                                                      .options,
                                                                                    function (
                                                                                      o
                                                                                    ) {
                                                                                      return o.selected
                                                                                    }
                                                                                  )
                                                                                  .map(
                                                                                    function (
                                                                                      o
                                                                                    ) {
                                                                                      var val =
                                                                                        "_value" in
                                                                                        o
                                                                                          ? o._value
                                                                                          : o.value
                                                                                      return val
                                                                                    }
                                                                                  )
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .cronOptions
                                                                                  .hourly
                                                                                  .at,
                                                                                "hour",
                                                                                $event
                                                                                  .target
                                                                                  .multiple
                                                                                  ? $$selectedVal
                                                                                  : $$selectedVal[0]
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      _vm._l(
                                                                        24,
                                                                        function (
                                                                          n
                                                                        ) {
                                                                          return _c(
                                                                            "option",
                                                                            {
                                                                              key:
                                                                                n -
                                                                                1,
                                                                              domProps:
                                                                                {
                                                                                  value:
                                                                                    n -
                                                                                    1,
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    (
                                                                                      n -
                                                                                      1
                                                                                    )
                                                                                      .toString()
                                                                                      .padStart(
                                                                                        2,
                                                                                        "0"
                                                                                      )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                      0
                                                                    ),
                                                                    _vm._v(
                                                                      " : "
                                                                    ),
                                                                    _c(
                                                                      "select",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                _vm
                                                                                  .cronOptions
                                                                                  .hourly
                                                                                  .at
                                                                                  .minute,
                                                                              expression:
                                                                                "cronOptions.hourly.at.minute",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "form-control ml-1 select w-auto",
                                                                        staticStyle:
                                                                          {
                                                                            display:
                                                                              "inline",
                                                                          },
                                                                        attrs: {
                                                                          name: "pnlDetails:pnlHourly:radioGroup:pnlTime:minutePart",
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              var $$selectedVal =
                                                                                Array.prototype.filter
                                                                                  .call(
                                                                                    $event
                                                                                      .target
                                                                                      .options,
                                                                                    function (
                                                                                      o
                                                                                    ) {
                                                                                      return o.selected
                                                                                    }
                                                                                  )
                                                                                  .map(
                                                                                    function (
                                                                                      o
                                                                                    ) {
                                                                                      var val =
                                                                                        "_value" in
                                                                                        o
                                                                                          ? o._value
                                                                                          : o.value
                                                                                      return val
                                                                                    }
                                                                                  )
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .cronOptions
                                                                                  .hourly
                                                                                  .at,
                                                                                "minute",
                                                                                $event
                                                                                  .target
                                                                                  .multiple
                                                                                  ? $$selectedVal
                                                                                  : $$selectedVal[0]
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      _vm._l(
                                                                        60,
                                                                        function (
                                                                          n
                                                                        ) {
                                                                          return _c(
                                                                            "option",
                                                                            {
                                                                              key:
                                                                                n -
                                                                                1,
                                                                              domProps:
                                                                                {
                                                                                  value:
                                                                                    n -
                                                                                    1,
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    (
                                                                                      n -
                                                                                      1
                                                                                    )
                                                                                      .toString()
                                                                                      .padStart(
                                                                                        2,
                                                                                        "0"
                                                                                      )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                      0
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "p-4",
                                                    attrs: { id: "daily" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card-body",
                                                        attrs: { id: "idb" },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-inline",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "form-check-label",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .cronOptions
                                                                          .daily
                                                                          .radio,
                                                                      expression:
                                                                        "cronOptions.daily.radio",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-check-input mb-1",
                                                                  attrs: {
                                                                    "data-role":
                                                                      "radio",
                                                                    type: "radio",
                                                                    name: "pnlDetails:pnlDaily:radioGroup",
                                                                    value:
                                                                      "everyDay?",
                                                                    checked:
                                                                      "checked",
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      _vm._q(
                                                                        _vm
                                                                          .cronOptions
                                                                          .daily
                                                                          .radio,
                                                                        "everyDay?"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm
                                                                            .cronOptions
                                                                            .daily,
                                                                          "radio",
                                                                          "everyDay?"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Everyday"
                                                                      )
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-inline mt-3",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "form-check-input",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .cronOptions
                                                                          .daily
                                                                          .radio,
                                                                      expression:
                                                                        "cronOptions.daily.radio",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-check-input mb-1",
                                                                  attrs: {
                                                                    "data-role":
                                                                      "radio",
                                                                    type: "radio",
                                                                    name: "pnlDetails:pnlDaily:radioGroup",
                                                                    value:
                                                                      "weekdays?",
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      _vm._q(
                                                                        _vm
                                                                          .cronOptions
                                                                          .daily
                                                                          .radio,
                                                                        "weekdays?"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm
                                                                            .cronOptions
                                                                            .daily,
                                                                          "radio",
                                                                          "weekdays?"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Every weekday"
                                                                      )
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-inline mt-4",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Starts at"
                                                                  )
                                                                ) +
                                                                " : "
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-inline",
                                                              },
                                                              [
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .cronOptions
                                                                              .daily
                                                                              .at
                                                                              .hour,
                                                                          expression:
                                                                            "cronOptions.daily.at.hour",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control mr-1 ml-1 select w-auto",
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "inline",
                                                                      },
                                                                    attrs: {
                                                                      name: "pnlDetails:pnlDaily:pnlTime:hourPart",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal =
                                                                            Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                          _vm.$set(
                                                                            _vm
                                                                              .cronOptions
                                                                              .daily
                                                                              .at,
                                                                            "hour",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    24,
                                                                    function (
                                                                      n
                                                                    ) {
                                                                      return _c(
                                                                        "option",
                                                                        {
                                                                          key:
                                                                            n -
                                                                            1,
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                n -
                                                                                1,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                (
                                                                                  n -
                                                                                  1
                                                                                )
                                                                                  .toString()
                                                                                  .padStart(
                                                                                    2,
                                                                                    "0"
                                                                                  )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                                _vm._v(" : "),
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .cronOptions
                                                                              .daily
                                                                              .at
                                                                              .minute,
                                                                          expression:
                                                                            "cronOptions.daily.at.minute",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control ml-1 select w-auto",
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "inline",
                                                                      },
                                                                    attrs: {
                                                                      name: "pnlDetails:pnlDaily:pnlTime:minutePart",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal =
                                                                            Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                          _vm.$set(
                                                                            _vm
                                                                              .cronOptions
                                                                              .daily
                                                                              .at,
                                                                            "minute",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    60,
                                                                    function (
                                                                      n
                                                                    ) {
                                                                      return _c(
                                                                        "option",
                                                                        {
                                                                          key:
                                                                            n -
                                                                            1,
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                n -
                                                                                1,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                (
                                                                                  n -
                                                                                  1
                                                                                )
                                                                                  .toString()
                                                                                  .padStart(
                                                                                    2,
                                                                                    "0"
                                                                                  )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "p-4",
                                                    attrs: { id: "weekly" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card-body",
                                                        attrs: { id: "idb" },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "row",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-3",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-inline",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "form-check-label",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    _vm
                                                                                      .cronOptions
                                                                                      .weekly
                                                                                      .days,
                                                                                  expression:
                                                                                    "cronOptions.weekly.days",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "form-check-input",
                                                                            attrs:
                                                                              {
                                                                                "data-role":
                                                                                  "checkbox",
                                                                                "data-caption":
                                                                                  _vm.$t(
                                                                                    "Monday"
                                                                                  ),
                                                                                type: "checkbox",
                                                                                name: "pnlDetails:pnlWeekly:pnlDaysSelected",
                                                                                value:
                                                                                  "Mon",
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                checked:
                                                                                  Array.isArray(
                                                                                    _vm
                                                                                      .cronOptions
                                                                                      .weekly
                                                                                      .days
                                                                                  )
                                                                                    ? _vm._i(
                                                                                        _vm
                                                                                          .cronOptions
                                                                                          .weekly
                                                                                          .days,
                                                                                        "Mon"
                                                                                      ) >
                                                                                      -1
                                                                                    : _vm
                                                                                        .cronOptions
                                                                                        .weekly
                                                                                        .days,
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  var $$a =
                                                                                      _vm
                                                                                        .cronOptions
                                                                                        .weekly
                                                                                        .days,
                                                                                    $$el =
                                                                                      $event.target,
                                                                                    $$c =
                                                                                      $$el.checked
                                                                                        ? true
                                                                                        : false
                                                                                  if (
                                                                                    Array.isArray(
                                                                                      $$a
                                                                                    )
                                                                                  ) {
                                                                                    var $$v =
                                                                                        "Mon",
                                                                                      $$i =
                                                                                        _vm._i(
                                                                                          $$a,
                                                                                          $$v
                                                                                        )
                                                                                    if (
                                                                                      $$el.checked
                                                                                    ) {
                                                                                      $$i <
                                                                                        0 &&
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .cronOptions
                                                                                            .weekly,
                                                                                          "days",
                                                                                          $$a.concat(
                                                                                            [
                                                                                              $$v,
                                                                                            ]
                                                                                          )
                                                                                        )
                                                                                    } else {
                                                                                      $$i >
                                                                                        -1 &&
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .cronOptions
                                                                                            .weekly,
                                                                                          "days",
                                                                                          $$a
                                                                                            .slice(
                                                                                              0,
                                                                                              $$i
                                                                                            )
                                                                                            .concat(
                                                                                              $$a.slice(
                                                                                                $$i +
                                                                                                  1
                                                                                              )
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                  } else {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .cronOptions
                                                                                        .weekly,
                                                                                      "days",
                                                                                      $$c
                                                                                    )
                                                                                  }
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-3",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-inline",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "form-check-label",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    _vm
                                                                                      .cronOptions
                                                                                      .weekly
                                                                                      .days,
                                                                                  expression:
                                                                                    "cronOptions.weekly.days",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "form-check-input ml-4",
                                                                            attrs:
                                                                              {
                                                                                "data-role":
                                                                                  "checkbox",
                                                                                "data-caption":
                                                                                  _vm.$t(
                                                                                    "Tuesday"
                                                                                  ),
                                                                                type: "checkbox",
                                                                                name: "pnlDetails:pnlWeekly:pnlDaysSelected",
                                                                                value:
                                                                                  "Tue",
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                checked:
                                                                                  Array.isArray(
                                                                                    _vm
                                                                                      .cronOptions
                                                                                      .weekly
                                                                                      .days
                                                                                  )
                                                                                    ? _vm._i(
                                                                                        _vm
                                                                                          .cronOptions
                                                                                          .weekly
                                                                                          .days,
                                                                                        "Tue"
                                                                                      ) >
                                                                                      -1
                                                                                    : _vm
                                                                                        .cronOptions
                                                                                        .weekly
                                                                                        .days,
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  var $$a =
                                                                                      _vm
                                                                                        .cronOptions
                                                                                        .weekly
                                                                                        .days,
                                                                                    $$el =
                                                                                      $event.target,
                                                                                    $$c =
                                                                                      $$el.checked
                                                                                        ? true
                                                                                        : false
                                                                                  if (
                                                                                    Array.isArray(
                                                                                      $$a
                                                                                    )
                                                                                  ) {
                                                                                    var $$v =
                                                                                        "Tue",
                                                                                      $$i =
                                                                                        _vm._i(
                                                                                          $$a,
                                                                                          $$v
                                                                                        )
                                                                                    if (
                                                                                      $$el.checked
                                                                                    ) {
                                                                                      $$i <
                                                                                        0 &&
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .cronOptions
                                                                                            .weekly,
                                                                                          "days",
                                                                                          $$a.concat(
                                                                                            [
                                                                                              $$v,
                                                                                            ]
                                                                                          )
                                                                                        )
                                                                                    } else {
                                                                                      $$i >
                                                                                        -1 &&
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .cronOptions
                                                                                            .weekly,
                                                                                          "days",
                                                                                          $$a
                                                                                            .slice(
                                                                                              0,
                                                                                              $$i
                                                                                            )
                                                                                            .concat(
                                                                                              $$a.slice(
                                                                                                $$i +
                                                                                                  1
                                                                                              )
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                  } else {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .cronOptions
                                                                                        .weekly,
                                                                                      "days",
                                                                                      $$c
                                                                                    )
                                                                                  }
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-3",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-inline",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "form-check-label",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    _vm
                                                                                      .cronOptions
                                                                                      .weekly
                                                                                      .days,
                                                                                  expression:
                                                                                    "cronOptions.weekly.days",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "form-check-input ml-4",
                                                                            attrs:
                                                                              {
                                                                                "data-role":
                                                                                  "checkbox",
                                                                                "data-caption":
                                                                                  _vm.$t(
                                                                                    "Wednesday"
                                                                                  ),
                                                                                type: "checkbox",
                                                                                name: "pnlDetails:pnlWeekly:pnlDaysSelected",
                                                                                value:
                                                                                  "Wed",
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                checked:
                                                                                  Array.isArray(
                                                                                    _vm
                                                                                      .cronOptions
                                                                                      .weekly
                                                                                      .days
                                                                                  )
                                                                                    ? _vm._i(
                                                                                        _vm
                                                                                          .cronOptions
                                                                                          .weekly
                                                                                          .days,
                                                                                        "Wed"
                                                                                      ) >
                                                                                      -1
                                                                                    : _vm
                                                                                        .cronOptions
                                                                                        .weekly
                                                                                        .days,
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  var $$a =
                                                                                      _vm
                                                                                        .cronOptions
                                                                                        .weekly
                                                                                        .days,
                                                                                    $$el =
                                                                                      $event.target,
                                                                                    $$c =
                                                                                      $$el.checked
                                                                                        ? true
                                                                                        : false
                                                                                  if (
                                                                                    Array.isArray(
                                                                                      $$a
                                                                                    )
                                                                                  ) {
                                                                                    var $$v =
                                                                                        "Wed",
                                                                                      $$i =
                                                                                        _vm._i(
                                                                                          $$a,
                                                                                          $$v
                                                                                        )
                                                                                    if (
                                                                                      $$el.checked
                                                                                    ) {
                                                                                      $$i <
                                                                                        0 &&
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .cronOptions
                                                                                            .weekly,
                                                                                          "days",
                                                                                          $$a.concat(
                                                                                            [
                                                                                              $$v,
                                                                                            ]
                                                                                          )
                                                                                        )
                                                                                    } else {
                                                                                      $$i >
                                                                                        -1 &&
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .cronOptions
                                                                                            .weekly,
                                                                                          "days",
                                                                                          $$a
                                                                                            .slice(
                                                                                              0,
                                                                                              $$i
                                                                                            )
                                                                                            .concat(
                                                                                              $$a.slice(
                                                                                                $$i +
                                                                                                  1
                                                                                              )
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                  } else {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .cronOptions
                                                                                        .weekly,
                                                                                      "days",
                                                                                      $$c
                                                                                    )
                                                                                  }
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-auto",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-inline",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "form-check-label",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    _vm
                                                                                      .cronOptions
                                                                                      .weekly
                                                                                      .days,
                                                                                  expression:
                                                                                    "cronOptions.weekly.days",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "form-check-input ml-4",
                                                                            attrs:
                                                                              {
                                                                                "data-role":
                                                                                  "checkbox",
                                                                                "data-caption":
                                                                                  _vm.$t(
                                                                                    "Thursday"
                                                                                  ),
                                                                                type: "checkbox",
                                                                                name: "pnlDetails:pnlWeekly:pnlDaysSelected",
                                                                                value:
                                                                                  "Thu",
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                checked:
                                                                                  Array.isArray(
                                                                                    _vm
                                                                                      .cronOptions
                                                                                      .weekly
                                                                                      .days
                                                                                  )
                                                                                    ? _vm._i(
                                                                                        _vm
                                                                                          .cronOptions
                                                                                          .weekly
                                                                                          .days,
                                                                                        "Thu"
                                                                                      ) >
                                                                                      -1
                                                                                    : _vm
                                                                                        .cronOptions
                                                                                        .weekly
                                                                                        .days,
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  var $$a =
                                                                                      _vm
                                                                                        .cronOptions
                                                                                        .weekly
                                                                                        .days,
                                                                                    $$el =
                                                                                      $event.target,
                                                                                    $$c =
                                                                                      $$el.checked
                                                                                        ? true
                                                                                        : false
                                                                                  if (
                                                                                    Array.isArray(
                                                                                      $$a
                                                                                    )
                                                                                  ) {
                                                                                    var $$v =
                                                                                        "Thu",
                                                                                      $$i =
                                                                                        _vm._i(
                                                                                          $$a,
                                                                                          $$v
                                                                                        )
                                                                                    if (
                                                                                      $$el.checked
                                                                                    ) {
                                                                                      $$i <
                                                                                        0 &&
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .cronOptions
                                                                                            .weekly,
                                                                                          "days",
                                                                                          $$a.concat(
                                                                                            [
                                                                                              $$v,
                                                                                            ]
                                                                                          )
                                                                                        )
                                                                                    } else {
                                                                                      $$i >
                                                                                        -1 &&
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .cronOptions
                                                                                            .weekly,
                                                                                          "days",
                                                                                          $$a
                                                                                            .slice(
                                                                                              0,
                                                                                              $$i
                                                                                            )
                                                                                            .concat(
                                                                                              $$a.slice(
                                                                                                $$i +
                                                                                                  1
                                                                                              )
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                  } else {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .cronOptions
                                                                                        .weekly,
                                                                                      "days",
                                                                                      $$c
                                                                                    )
                                                                                  }
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "row mt-2",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-3",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-inline",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "form-check-label",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    _vm
                                                                                      .cronOptions
                                                                                      .weekly
                                                                                      .days,
                                                                                  expression:
                                                                                    "cronOptions.weekly.days",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "form-check-input",
                                                                            attrs:
                                                                              {
                                                                                "data-role":
                                                                                  "checkbox",
                                                                                "data-caption":
                                                                                  _vm.$t(
                                                                                    "Friday"
                                                                                  ),
                                                                                type: "checkbox",
                                                                                name: "pnlDetails:pnlWeekly:pnlDaysSelected",
                                                                                value:
                                                                                  "Fri",
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                checked:
                                                                                  Array.isArray(
                                                                                    _vm
                                                                                      .cronOptions
                                                                                      .weekly
                                                                                      .days
                                                                                  )
                                                                                    ? _vm._i(
                                                                                        _vm
                                                                                          .cronOptions
                                                                                          .weekly
                                                                                          .days,
                                                                                        "Fri"
                                                                                      ) >
                                                                                      -1
                                                                                    : _vm
                                                                                        .cronOptions
                                                                                        .weekly
                                                                                        .days,
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  var $$a =
                                                                                      _vm
                                                                                        .cronOptions
                                                                                        .weekly
                                                                                        .days,
                                                                                    $$el =
                                                                                      $event.target,
                                                                                    $$c =
                                                                                      $$el.checked
                                                                                        ? true
                                                                                        : false
                                                                                  if (
                                                                                    Array.isArray(
                                                                                      $$a
                                                                                    )
                                                                                  ) {
                                                                                    var $$v =
                                                                                        "Fri",
                                                                                      $$i =
                                                                                        _vm._i(
                                                                                          $$a,
                                                                                          $$v
                                                                                        )
                                                                                    if (
                                                                                      $$el.checked
                                                                                    ) {
                                                                                      $$i <
                                                                                        0 &&
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .cronOptions
                                                                                            .weekly,
                                                                                          "days",
                                                                                          $$a.concat(
                                                                                            [
                                                                                              $$v,
                                                                                            ]
                                                                                          )
                                                                                        )
                                                                                    } else {
                                                                                      $$i >
                                                                                        -1 &&
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .cronOptions
                                                                                            .weekly,
                                                                                          "days",
                                                                                          $$a
                                                                                            .slice(
                                                                                              0,
                                                                                              $$i
                                                                                            )
                                                                                            .concat(
                                                                                              $$a.slice(
                                                                                                $$i +
                                                                                                  1
                                                                                              )
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                  } else {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .cronOptions
                                                                                        .weekly,
                                                                                      "days",
                                                                                      $$c
                                                                                    )
                                                                                  }
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-3",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-inline",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "form-check-label",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    _vm
                                                                                      .cronOptions
                                                                                      .weekly
                                                                                      .days,
                                                                                  expression:
                                                                                    "cronOptions.weekly.days",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "form-check-input ml-4",
                                                                            attrs:
                                                                              {
                                                                                "data-role":
                                                                                  "checkbox",
                                                                                "data-caption":
                                                                                  _vm.$t(
                                                                                    "Saturday"
                                                                                  ),
                                                                                type: "checkbox",
                                                                                name: "pnlDetails:pnlWeekly:pnlDaysSelected",
                                                                                value:
                                                                                  "Sat",
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                checked:
                                                                                  Array.isArray(
                                                                                    _vm
                                                                                      .cronOptions
                                                                                      .weekly
                                                                                      .days
                                                                                  )
                                                                                    ? _vm._i(
                                                                                        _vm
                                                                                          .cronOptions
                                                                                          .weekly
                                                                                          .days,
                                                                                        "Sat"
                                                                                      ) >
                                                                                      -1
                                                                                    : _vm
                                                                                        .cronOptions
                                                                                        .weekly
                                                                                        .days,
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  var $$a =
                                                                                      _vm
                                                                                        .cronOptions
                                                                                        .weekly
                                                                                        .days,
                                                                                    $$el =
                                                                                      $event.target,
                                                                                    $$c =
                                                                                      $$el.checked
                                                                                        ? true
                                                                                        : false
                                                                                  if (
                                                                                    Array.isArray(
                                                                                      $$a
                                                                                    )
                                                                                  ) {
                                                                                    var $$v =
                                                                                        "Sat",
                                                                                      $$i =
                                                                                        _vm._i(
                                                                                          $$a,
                                                                                          $$v
                                                                                        )
                                                                                    if (
                                                                                      $$el.checked
                                                                                    ) {
                                                                                      $$i <
                                                                                        0 &&
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .cronOptions
                                                                                            .weekly,
                                                                                          "days",
                                                                                          $$a.concat(
                                                                                            [
                                                                                              $$v,
                                                                                            ]
                                                                                          )
                                                                                        )
                                                                                    } else {
                                                                                      $$i >
                                                                                        -1 &&
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .cronOptions
                                                                                            .weekly,
                                                                                          "days",
                                                                                          $$a
                                                                                            .slice(
                                                                                              0,
                                                                                              $$i
                                                                                            )
                                                                                            .concat(
                                                                                              $$a.slice(
                                                                                                $$i +
                                                                                                  1
                                                                                              )
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                  } else {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .cronOptions
                                                                                        .weekly,
                                                                                      "days",
                                                                                      $$c
                                                                                    )
                                                                                  }
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-inline",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "form-check-label",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    _vm
                                                                                      .cronOptions
                                                                                      .weekly
                                                                                      .days,
                                                                                  expression:
                                                                                    "cronOptions.weekly.days",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "form-check-input ml-4",
                                                                            attrs:
                                                                              {
                                                                                "data-role":
                                                                                  "checkbox",
                                                                                "data-caption":
                                                                                  _vm.$t(
                                                                                    "Sunday"
                                                                                  ),
                                                                                type: "checkbox",
                                                                                name: "pnlDetails:pnlWeekly:pnlDaysSelected",
                                                                                value:
                                                                                  "Sun",
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                checked:
                                                                                  Array.isArray(
                                                                                    _vm
                                                                                      .cronOptions
                                                                                      .weekly
                                                                                      .days
                                                                                  )
                                                                                    ? _vm._i(
                                                                                        _vm
                                                                                          .cronOptions
                                                                                          .weekly
                                                                                          .days,
                                                                                        "Sun"
                                                                                      ) >
                                                                                      -1
                                                                                    : _vm
                                                                                        .cronOptions
                                                                                        .weekly
                                                                                        .days,
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  var $$a =
                                                                                      _vm
                                                                                        .cronOptions
                                                                                        .weekly
                                                                                        .days,
                                                                                    $$el =
                                                                                      $event.target,
                                                                                    $$c =
                                                                                      $$el.checked
                                                                                        ? true
                                                                                        : false
                                                                                  if (
                                                                                    Array.isArray(
                                                                                      $$a
                                                                                    )
                                                                                  ) {
                                                                                    var $$v =
                                                                                        "Sun",
                                                                                      $$i =
                                                                                        _vm._i(
                                                                                          $$a,
                                                                                          $$v
                                                                                        )
                                                                                    if (
                                                                                      $$el.checked
                                                                                    ) {
                                                                                      $$i <
                                                                                        0 &&
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .cronOptions
                                                                                            .weekly,
                                                                                          "days",
                                                                                          $$a.concat(
                                                                                            [
                                                                                              $$v,
                                                                                            ]
                                                                                          )
                                                                                        )
                                                                                    } else {
                                                                                      $$i >
                                                                                        -1 &&
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .cronOptions
                                                                                            .weekly,
                                                                                          "days",
                                                                                          $$a
                                                                                            .slice(
                                                                                              0,
                                                                                              $$i
                                                                                            )
                                                                                            .concat(
                                                                                              $$a.slice(
                                                                                                $$i +
                                                                                                  1
                                                                                              )
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                  } else {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .cronOptions
                                                                                        .weekly,
                                                                                      "days",
                                                                                      $$c
                                                                                    )
                                                                                  }
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-inline mt-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Starts at"
                                                                  )
                                                                ) +
                                                                " : "
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-inline",
                                                              },
                                                              [
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .cronOptions
                                                                              .weekly
                                                                              .at
                                                                              .hour,
                                                                          expression:
                                                                            "cronOptions.weekly.at.hour",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control mr-1 ml-1 select w-auto inline",
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "inline",
                                                                      },
                                                                    attrs: {
                                                                      name: "pnlDetails:pnlWeekly:pnlTime:hourPart",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal =
                                                                            Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                          _vm.$set(
                                                                            _vm
                                                                              .cronOptions
                                                                              .weekly
                                                                              .at,
                                                                            "hour",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    24,
                                                                    function (
                                                                      n
                                                                    ) {
                                                                      return _c(
                                                                        "option",
                                                                        {
                                                                          key:
                                                                            n -
                                                                            1,
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                n -
                                                                                1,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                (
                                                                                  n -
                                                                                  1
                                                                                )
                                                                                  .toString()
                                                                                  .padStart(
                                                                                    2,
                                                                                    "0"
                                                                                  )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                                _vm._v(" : "),
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .cronOptions
                                                                              .weekly
                                                                              .at
                                                                              .minute,
                                                                          expression:
                                                                            "cronOptions.weekly.at.minute",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control ml-1 select w-auto",
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "inline",
                                                                      },
                                                                    attrs: {
                                                                      name: "pnlDetails:pnlWeekly:pnlTime:minutePart",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal =
                                                                            Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                          _vm.$set(
                                                                            _vm
                                                                              .cronOptions
                                                                              .weekly
                                                                              .at,
                                                                            "minute",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    60,
                                                                    function (
                                                                      n
                                                                    ) {
                                                                      return _c(
                                                                        "option",
                                                                        {
                                                                          key:
                                                                            n -
                                                                            1,
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                n -
                                                                                1,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                (
                                                                                  n -
                                                                                  1
                                                                                )
                                                                                  .toString()
                                                                                  .padStart(
                                                                                    2,
                                                                                    "0"
                                                                                  )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "p-4",
                                                    attrs: { id: "montly" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card-body",
                                                        attrs: { id: "idb" },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-inline",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "form-check-label",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .cronOptions
                                                                          .monthly
                                                                          .radio,
                                                                      expression:
                                                                        "cronOptions.monthly.radio",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-check-input mb-1",
                                                                  attrs: {
                                                                    "data-role":
                                                                      "radio",
                                                                    type: "radio",
                                                                    name: "pnlDetails:pnlMonthly:radioGroup",
                                                                    value:
                                                                      "fixed",
                                                                    checked:
                                                                      "checked",
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      _vm._q(
                                                                        _vm
                                                                          .cronOptions
                                                                          .monthly
                                                                          .radio,
                                                                        "fixed"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm
                                                                            .cronOptions
                                                                            .monthly,
                                                                          "radio",
                                                                          "fixed"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Day"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model.number",
                                                                      value:
                                                                        _vm
                                                                          .cronOptions
                                                                          .monthly
                                                                          .day,
                                                                      expression:
                                                                        "cronOptions.monthly.day",
                                                                      modifiers:
                                                                        {
                                                                          number: true,
                                                                        },
                                                                    },
                                                                    {
                                                                      name: "number",
                                                                      rawName:
                                                                        "v-number",
                                                                    },
                                                                    {
                                                                      name: "range",
                                                                      rawName:
                                                                        "v-range",
                                                                      value: {
                                                                        min: 1,
                                                                        max: 30,
                                                                      },
                                                                      expression:
                                                                        "{ min: 1, max: 30}",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control ml-1 mr-1 input w-10 text-center",
                                                                  staticStyle: {
                                                                    display:
                                                                      "inline",
                                                                  },
                                                                  attrs: {
                                                                    type: "number",
                                                                    size: "2",
                                                                    min: "1",
                                                                    max: "30",
                                                                    name: "pnlDetails:pnlMonthly:radioGroup:txtDay",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      _vm
                                                                        .cronOptions
                                                                        .monthly
                                                                        .day,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.$set(
                                                                          _vm
                                                                            .cronOptions
                                                                            .monthly,
                                                                          "day",
                                                                          _vm._n(
                                                                            $event
                                                                              .target
                                                                              .value
                                                                          )
                                                                        )
                                                                      },
                                                                    blur: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$forceUpdate()
                                                                    },
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "of every"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .cronOptions
                                                                              .monthly
                                                                              .ofEvery,
                                                                          expression:
                                                                            "cronOptions.monthly.ofEvery",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control ml-1 mr-1 select w-auto",
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "inline",
                                                                      },
                                                                    attrs: {
                                                                      name: "pnlDetails:pnlMonthly:radioGroup:ddMonth",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal =
                                                                            Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                          _vm.$set(
                                                                            _vm
                                                                              .cronOptions
                                                                              .monthly,
                                                                            "ofEvery",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "1",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "1"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "2",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "2"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "3",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "3"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "4",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "4"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "6"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "month(s)"
                                                                      )
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-inline mt-3",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "form-check-label",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .cronOptions
                                                                          .monthly
                                                                          .radio,
                                                                      expression:
                                                                        "cronOptions.monthly.radio",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-check-input mb-1",
                                                                  attrs: {
                                                                    "data-role":
                                                                      "radio",
                                                                    type: "radio",
                                                                    name: "pnlDetails:pnlMonthly:radioGroup",
                                                                    value:
                                                                      "recurrent",
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      _vm._q(
                                                                        _vm
                                                                          .cronOptions
                                                                          .monthly
                                                                          .radio,
                                                                        "recurrent"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm
                                                                            .cronOptions
                                                                            .monthly,
                                                                          "radio",
                                                                          "recurrent"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "The"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .cronOptions
                                                                              .monthly
                                                                              .ddRank,
                                                                          expression:
                                                                            "cronOptions.monthly.ddRank",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control ml-1 mr-1 select w-auto",
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "inline",
                                                                      },
                                                                    attrs: {
                                                                      name: "pnlDetails:pnlMonthly:radioGroup:ddRank",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal =
                                                                            Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                          _vm.$set(
                                                                            _vm
                                                                              .cronOptions
                                                                              .monthly,
                                                                            "ddRank",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "first",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "First"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "second",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "Second"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "third",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "Third"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "fourth",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "Fourth"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .cronOptions
                                                                              .monthly
                                                                              .ddDay,
                                                                          expression:
                                                                            "cronOptions.monthly.ddDay",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control mr-1 select w-auto",
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "inline",
                                                                      },
                                                                    attrs: {
                                                                      name: "pnlDetails:pnlMonthly:radioGroup:ddDay",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal =
                                                                            Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                          _vm.$set(
                                                                            _vm
                                                                              .cronOptions
                                                                              .monthly,
                                                                            "ddDay",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "mon",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "Monday"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "tue",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "Tuesday"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "wed",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "Wednesday"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "thu",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "Thursday"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "fri",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "Friday"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "sat",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "Saturday"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "sun",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "Sunday"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "of every"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .cronOptions
                                                                              .monthly
                                                                              .ddMonth,
                                                                          expression:
                                                                            "cronOptions.monthly.ddMonth",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control ml-1 mr-1 select w-auto",
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "inline",
                                                                      },
                                                                    attrs: {
                                                                      name: "pnlDetails:pnlMonthly:radioGroup:ddMonth",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal =
                                                                            Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                          _vm.$set(
                                                                            _vm
                                                                              .cronOptions
                                                                              .monthly,
                                                                            "ddMonth",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "1",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "1"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "2",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "2"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "3",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "3"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "4",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "4"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "6"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "month(s)"
                                                                      )
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-inline mt-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Starts at"
                                                                  )
                                                                ) +
                                                                ": "
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-inline",
                                                              },
                                                              [
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .cronOptions
                                                                              .monthly
                                                                              .at
                                                                              .hour,
                                                                          expression:
                                                                            "cronOptions.monthly.at.hour",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control mr-1 ml-1 select w-auto",
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "inline",
                                                                      },
                                                                    attrs: {
                                                                      name: "pnlDetails:pnlMonthly:pnlTime:hourPart",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal =
                                                                            Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                          _vm.$set(
                                                                            _vm
                                                                              .cronOptions
                                                                              .monthly
                                                                              .at,
                                                                            "hour",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    24,
                                                                    function (
                                                                      n
                                                                    ) {
                                                                      return _c(
                                                                        "option",
                                                                        {
                                                                          key:
                                                                            n -
                                                                            1,
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                n -
                                                                                1,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                (
                                                                                  n -
                                                                                  1
                                                                                )
                                                                                  .toString()
                                                                                  .padStart(
                                                                                    2,
                                                                                    "0"
                                                                                  )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                                _vm._v(" : "),
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .cronOptions
                                                                              .monthly
                                                                              .at
                                                                              .minute,
                                                                          expression:
                                                                            "cronOptions.monthly.at.minute",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control ml-1 select w-auto",
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "inline",
                                                                      },
                                                                    attrs: {
                                                                      name: "pnlDetails:pnlMonthly:pnlTime:minutePart",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal =
                                                                            Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                          _vm.$set(
                                                                            _vm
                                                                              .cronOptions
                                                                              .monthly
                                                                              .at,
                                                                            "minute",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    60,
                                                                    function (
                                                                      n
                                                                    ) {
                                                                      return _c(
                                                                        "option",
                                                                        {
                                                                          key:
                                                                            n -
                                                                            1,
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                n -
                                                                                1,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                (
                                                                                  n -
                                                                                  1
                                                                                )
                                                                                  .toString()
                                                                                  .padStart(
                                                                                    2,
                                                                                    "0"
                                                                                  )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "p-4",
                                                    attrs: { id: "yearly" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card-body",
                                                        attrs: { id: "idb" },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-inline",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "form-check-label mr-1",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .cronOptions
                                                                          .yearly
                                                                          .radio,
                                                                      expression:
                                                                        "cronOptions.yearly.radio",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-check-input mb-1",
                                                                  attrs: {
                                                                    "data-role":
                                                                      "radio",
                                                                    "data-caption":
                                                                      _vm.$t(
                                                                        "Every"
                                                                      ),
                                                                    type: "radio",
                                                                    name: "pnlDetails:pnlYearly:radioGroup",
                                                                    value:
                                                                      "fixed",
                                                                    checked:
                                                                      "checked",
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      _vm._q(
                                                                        _vm
                                                                          .cronOptions
                                                                          .yearly
                                                                          .radio,
                                                                        "fixed"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm
                                                                            .cronOptions
                                                                            .yearly,
                                                                          "radio",
                                                                          "fixed"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _c(
                                                              "select",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .cronOptions
                                                                        .yearly
                                                                        .ofEvery,
                                                                    expression:
                                                                      "cronOptions.yearly.ofEvery",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "form-control select w-auto",
                                                                staticStyle: {
                                                                  display:
                                                                    "inline",
                                                                },
                                                                attrs: {
                                                                  name: "pnlDetails:pnlYearly:radioGroup:ddMonth",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      var $$selectedVal =
                                                                        Array.prototype.filter
                                                                          .call(
                                                                            $event
                                                                              .target
                                                                              .options,
                                                                            function (
                                                                              o
                                                                            ) {
                                                                              return o.selected
                                                                            }
                                                                          )
                                                                          .map(
                                                                            function (
                                                                              o
                                                                            ) {
                                                                              var val =
                                                                                "_value" in
                                                                                o
                                                                                  ? o._value
                                                                                  : o.value
                                                                              return val
                                                                            }
                                                                          )
                                                                      _vm.$set(
                                                                        _vm
                                                                          .cronOptions
                                                                          .yearly,
                                                                        "ofEvery",
                                                                        $event
                                                                          .target
                                                                          .multiple
                                                                          ? $$selectedVal
                                                                          : $$selectedVal[0]
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "1",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "January"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "2",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "February"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "3",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "March"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "4",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "April"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "5",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "May"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "June"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "7",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "July"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "8",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "August"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "9",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "September"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "10",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "October"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "11",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "November"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "December"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .cronOptions
                                                                      .yearly
                                                                      .day,
                                                                  expression:
                                                                    "cronOptions.yearly.day",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control ml-1 mr-1 input w-auto text-center",
                                                              staticStyle: {
                                                                display:
                                                                  "inline-grid",
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                size: "2",
                                                                name: "pnlDetails:pnlYearly:radioGroup:txtDay",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm
                                                                    .cronOptions
                                                                    .yearly.day,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      _vm
                                                                        .cronOptions
                                                                        .yearly,
                                                                      "day",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-inline mt-3",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "form-check-label",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .cronOptions
                                                                          .yearly
                                                                          .radio,
                                                                      expression:
                                                                        "cronOptions.yearly.radio",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-check-input mb-1",
                                                                  attrs: {
                                                                    "data-role":
                                                                      "radio",
                                                                    "data-caption":
                                                                      _vm.$t(
                                                                        "The"
                                                                      ),
                                                                    type: "radio",
                                                                    name: "pnlDetails:pnlYearly:radioGroup",
                                                                    value:
                                                                      "recurrent",
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      _vm._q(
                                                                        _vm
                                                                          .cronOptions
                                                                          .yearly
                                                                          .radio,
                                                                        "recurrent"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm
                                                                            .cronOptions
                                                                            .yearly,
                                                                          "radio",
                                                                          "recurrent"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .cronOptions
                                                                              .yearly
                                                                              .ddRank,
                                                                          expression:
                                                                            "cronOptions.yearly.ddRank",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control ml-1 select w-auto",
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "inline",
                                                                      },
                                                                    attrs: {
                                                                      name: "pnlDetails:pnlYearly:radioGroup:ddRank",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal =
                                                                            Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                          _vm.$set(
                                                                            _vm
                                                                              .cronOptions
                                                                              .yearly,
                                                                            "ddRank",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "first",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "First"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "second",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "Second"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "third",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "Third"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "fourth",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "Fourth"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .cronOptions
                                                                              .yearly
                                                                              .ddDay,
                                                                          expression:
                                                                            "cronOptions.yearly.ddDay",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control ml-1 mr-1 select w-auto",
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "inline",
                                                                      },
                                                                    attrs: {
                                                                      name: "pnlDetails:pnlYearly:radioGroup:ddDay",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal =
                                                                            Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                          _vm.$set(
                                                                            _vm
                                                                              .cronOptions
                                                                              .yearly,
                                                                            "ddDay",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "mon",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "Monday"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "tue",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "Tuesday"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "wed",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "Wednesday"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "thu",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "Thursday"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "fri",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "Friday"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "sat",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "Saturday"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "sun",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "Sunday"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "of"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "select",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .cronOptions
                                                                        .yearly
                                                                        .ddMonth,
                                                                    expression:
                                                                      "cronOptions.yearly.ddMonth",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "form-control ml-1 select w-auto",
                                                                staticStyle: {
                                                                  display:
                                                                    "inline",
                                                                },
                                                                attrs: {
                                                                  name: "pnlDetails:pnlYearly:radioGroup:ddMonth",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      var $$selectedVal =
                                                                        Array.prototype.filter
                                                                          .call(
                                                                            $event
                                                                              .target
                                                                              .options,
                                                                            function (
                                                                              o
                                                                            ) {
                                                                              return o.selected
                                                                            }
                                                                          )
                                                                          .map(
                                                                            function (
                                                                              o
                                                                            ) {
                                                                              var val =
                                                                                "_value" in
                                                                                o
                                                                                  ? o._value
                                                                                  : o.value
                                                                              return val
                                                                            }
                                                                          )
                                                                      _vm.$set(
                                                                        _vm
                                                                          .cronOptions
                                                                          .yearly,
                                                                        "ddMonth",
                                                                        $event
                                                                          .target
                                                                          .multiple
                                                                          ? $$selectedVal
                                                                          : $$selectedVal[0]
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "1",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "January"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "2",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "February"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "3",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "March"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "4",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "April"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "5",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "May"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "June"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "7",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "July"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "8",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "August"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "9",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "September"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "10",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "October"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "11",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "November"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "December"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-inline mt-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Starts at"
                                                                  )
                                                                ) +
                                                                " : "
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-inline",
                                                              },
                                                              [
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .cronOptions
                                                                              .yearly
                                                                              .at
                                                                              .hour,
                                                                          expression:
                                                                            "cronOptions.yearly.at.hour",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control mr-1 ml-1 select w-auto",
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "inline",
                                                                      },
                                                                    attrs: {
                                                                      name: "pnlDetails:pnlYearly:pnlTime:hourPart",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal =
                                                                            Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                          _vm.$set(
                                                                            _vm
                                                                              .cronOptions
                                                                              .yearly
                                                                              .at,
                                                                            "hour",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    24,
                                                                    function (
                                                                      n
                                                                    ) {
                                                                      return _c(
                                                                        "option",
                                                                        {
                                                                          key:
                                                                            n -
                                                                            1,
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                n -
                                                                                1,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                (
                                                                                  n -
                                                                                  1
                                                                                )
                                                                                  .toString()
                                                                                  .padStart(
                                                                                    2,
                                                                                    "0"
                                                                                  )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                                _vm._v(" : "),
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .cronOptions
                                                                              .yearly
                                                                              .at
                                                                              .minute,
                                                                          expression:
                                                                            "cronOptions.yearly.at.minute",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control ml-1 select w-auto",
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "inline",
                                                                      },
                                                                    attrs: {
                                                                      name: "pnlDetails:pnlYearly:pnlTime:minutePart",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal =
                                                                            Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                          _vm.$set(
                                                                            _vm
                                                                              .cronOptions
                                                                              .yearly
                                                                              .at,
                                                                            "minute",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    60,
                                                                    function (
                                                                      n
                                                                    ) {
                                                                      return _c(
                                                                        "option",
                                                                        {
                                                                          key:
                                                                            n -
                                                                            1,
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                n -
                                                                                1,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                (
                                                                                  n -
                                                                                  1
                                                                                )
                                                                                  .toString()
                                                                                  .padStart(
                                                                                    2,
                                                                                    "0"
                                                                                  )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "row p-2" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "button mr-3",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.refreshSched()
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "Create Scheduling"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.backupJob.scheduling.length != 0
                                      ? _c("div", { staticClass: "p-4" }, [
                                          _c(
                                            "h6",
                                            { staticClass: "text-bold mb-3" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("Selected Scheduling")
                                                ) + ": "
                                              ),
                                            ]
                                          ),
                                          _c("div", {}, [
                                            _c(
                                              "h6",
                                              { staticClass: "text-bold" },
                                              [
                                                _c("span", {
                                                  staticClass: "mif-alarm",
                                                }),
                                                _vm._v(" "),
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        _vm.activeCronOption
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.cronstrue.toString(
                                                        _vm.backupJob
                                                          .scheduling[0],
                                                        {
                                                          locale:
                                                            _vm.$i18n.locale,
                                                          dayOfWeekStartIndexZero: false,
                                                        }
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "button",
                                                    attrs: { href: "#" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeSched(
                                                          _vm.backupJob
                                                            .scheduling[0]
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass:
                                                        "mif-cross-light",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              " (" +
                                                _vm._s(_vm.$t("Next Run")) +
                                                ": " +
                                                _vm._s(
                                                  _vm._f("moment")(
                                                    _vm.cronParser
                                                      .parseExpression(
                                                        _vm.convertCronTo6Fields(
                                                          _vm.backupJob
                                                            .scheduling[0]
                                                        )
                                                      )
                                                      .next()
                                                      .toString(),
                                                    "LLLL"
                                                  )
                                                ) +
                                                ") "
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { attrs: { id: "result" } }, [
                      _vm.backupJob.destinations.length != 0
                        ? _c("section", { staticClass: "overflow" }, [
                            _c("div", { staticClass: "page-content" }, [
                              _c("div", { staticClass: "w-100" }, [
                                _c("div", {}, [
                                  _vm.backupJob.sources.length == 1
                                    ? _c(
                                        "div",
                                        { staticClass: "p-6" },
                                        [
                                          _c("h5", { staticClass: "mb-4" }, [
                                            _vm._v(
                                              "1. " +
                                                _vm._s(_vm.$t("Source")) +
                                                ": "
                                            ),
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.backupJob.sources[0]
                                                    .type_name
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c("SourceView", {
                                            staticClass: "mt-4",
                                            attrs: {
                                              source: _vm.backupJob.sources[0],
                                            },
                                          }),
                                          !["", "-2", -2].includes(
                                            _vm.backupJob.sources[0]
                                              .id_service_account
                                          )
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "panel p-4 mt-6",
                                                },
                                                [
                                                  _c(
                                                    "h5",
                                                    {
                                                      staticClass: "fg-primary",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("Source")
                                                        ) +
                                                          " - " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "Service account"
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("ObjectView", {
                                                    staticClass: "mt-4",
                                                    attrs: {
                                                      obj: _vm.$session.getServiceAccountWithID(
                                                        _vm.backupJob.sources[0]
                                                          .id_service_account
                                                      ),
                                                      withCopyButton: false,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "bg-white border rounded p-4 mt-6 w-50",
                                            },
                                            [
                                              _c(
                                                "h5",
                                                {
                                                  staticClass:
                                                    "text-bold c-pointer dropdown-toggle",
                                                  attrs: {
                                                    id: "collapse_toggle_1",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("Options"))
                                                  ),
                                                ]
                                              ),
                                              _c("ObjectView", {
                                                staticClass: "mt-4",
                                                attrs: {
                                                  "data-role": "collapse",
                                                  "data-collapsed": "true",
                                                  "data-toggle-element":
                                                    "#collapse_toggle_1",
                                                  obj: _vm.backupJob.sources[0]
                                                    .options,
                                                  withCopyButton: false,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.backupJob.destinations.length == 1
                                    ? _c(
                                        "div",
                                        { staticClass: "p-6" },
                                        [
                                          _c("h5", { staticClass: "mb-4" }, [
                                            _vm._v(
                                              "2. " +
                                                _vm._s(_vm.$t("Destination")) +
                                                ": "
                                            ),
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.backupJob.destinations[0]
                                                    .type_name
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c("ObjectView", {
                                            attrs: {
                                              obj: _vm.backupJob
                                                .destinations[0],
                                              withCopyButton: false,
                                            },
                                          }),
                                          !["", "-2", -2].includes(
                                            _vm.backupJob.destinations[0]
                                              .id_repository
                                          )
                                            ? _c(
                                                "h5",
                                                {
                                                  staticClass:
                                                    "mt-4 fg-primary",
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass:
                                                      "mif-folder-open mr-2",
                                                  }),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("Destination")
                                                      ) +
                                                        " - " +
                                                        _vm._s(
                                                          _vm.$t("Repository")
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                          !["", "-2", -2].includes(
                                            _vm.backupJob.destinations[0]
                                              .id_repository
                                          )
                                            ? _c("ObjectView", {
                                                staticClass: "mt-4",
                                                attrs: {
                                                  obj: _vm.$session.getRepositoryWithID(
                                                    _vm.backupJob
                                                      .destinations[0]
                                                      .id_repository
                                                  ),
                                                  withCopyButton: false,
                                                },
                                              })
                                            : _vm._e(),
                                          !["", "-2", -2].includes(
                                            _vm.backupJob.destinations[0]
                                              .id_service_account
                                          )
                                            ? _c(
                                                "h5",
                                                {
                                                  staticClass:
                                                    "mt-4 fg-primary",
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass: "mif-key mr-2",
                                                  }),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("Destination")
                                                      ) +
                                                        " - " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "Service account"
                                                          )
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                          !["", "-2", -2].includes(
                                            _vm.backupJob.destinations[0]
                                              .id_service_account
                                          )
                                            ? _c("ObjectView", {
                                                staticClass: "mt-4",
                                                attrs: {
                                                  obj: _vm.$session.getServiceAccountWithID(
                                                    _vm.backupJob
                                                      .destinations[0]
                                                      .id_service_account
                                                  ),
                                                  withCopyButton: false,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.backupJob.id_scheduling_policy != "" ||
                                  _vm.backupJob.scheduling.length != 0
                                    ? _c("div", { staticClass: "p-6" }, [
                                        _c("h5", { staticClass: "mb-4" }, [
                                          _vm._v(
                                            "3. " + _vm._s(_vm.$t("Scheduling"))
                                          ),
                                        ]),
                                        _vm.backupJob.id_scheduling_policy == ""
                                          ? _c(
                                              "ul",
                                              { staticClass: "listview" },
                                              _vm._l(
                                                _vm.backupJob.scheduling,
                                                function (schedulation) {
                                                  return _c("li", [
                                                    _c("b", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.cronstrue.toString(
                                                              schedulation,
                                                              {
                                                                locale:
                                                                  _vm.$i18n
                                                                    .locale,
                                                                dayOfWeekStartIndexZero: false,
                                                              }
                                                            )
                                                          )
                                                      ),
                                                    ]),
                                                    _c("br"),
                                                    _vm._v(
                                                      " (" +
                                                        _vm._s(
                                                          _vm.$t("Next Run")
                                                        ) +
                                                        ": " +
                                                        _vm._s(
                                                          _vm._f("moment")(
                                                            _vm.cronParser
                                                              .parseExpression(
                                                                _vm.convertCronTo6Fields(
                                                                  _vm.backupJob
                                                                    .scheduling[0]
                                                                )
                                                              )
                                                              .next()
                                                              .toString(),
                                                            "LLLL"
                                                          )
                                                        ) +
                                                        ") "
                                                    ),
                                                  ])
                                                }
                                              ),
                                              0
                                            )
                                          : _c("span", [
                                              _c("label", [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$session.getPolicyByID(
                                                        _vm.backupJob
                                                          .id_scheduling_policy
                                                      ).name
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                              _c("br"),
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.cronstrue.toString(
                                                        _vm.$session.getPolicyByID(
                                                          _vm.backupJob
                                                            .id_scheduling_policy
                                                        ).policy.scheduling[0],
                                                        {
                                                          locale:
                                                            _vm.$i18n.locale,
                                                          dayOfWeekStartIndexZero: false,
                                                        }
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }