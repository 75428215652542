var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex flex-align-center flex-column flex-justify-center py-10",
    },
    [
      _c("span", { staticClass: "mif-info mif-4x fg-gray p-3" }),
      _c("h6", { staticClass: "text-center text-muted" }, [
        _vm._v(_vm._s(_vm.message)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }