<template>
    <div>
       <div class="dropdown-button place-right">
            <a  class="button dropdown-toggle bg-backup fg-black pl-5" >
                <div class="text-left">
                 {{$t('Backup')}}  
                </div>
                
            </a>
            <ul class="d-menu border place-right mt-2" data-role="dropdown">
                <li>
                    <router-link to="/backup" class=" text-bold" :disabled="!$root.connectionsStates[pc.id]">
                      <span class="mif-add icon text-bold"></span>{{$t("New Backup").toUpperCase()}}
                    </router-link>
                </li>
               
                        
                                
                <template v-if="$platformsTypesInfo!=null" v-for="t in restoreTypes"  :disabled="!$root.connectionsStates[pc.id]" >

                    
                
                    <li  v-if="t == $PlatformsTypes.Microsoft" v-for="microsoft_t in $root.getMicrosoftTypes()" :key="microsoft_t" >
                        <a @click="$root.$emit('OPENRESTOREDIALOG', microsoft_t, null)">RESTORE <b>{{ $platformsTypesInfo[microsoft_t].name }}</b></a>
                    </li>
                   
                    <li  v-else>
                        <a @click="$root.$emit('OPENRESTOREDIALOG', t, null)">RESTORE <b>{{ $platformsTypesInfo[t].name }}</b></a>
                    </li>
                </template>
             
            </ul> 
        </div>
    </div>
</template>
<script>
export default {
    name: "PCBackupLink",
     data() {
        return {
            restoreTypes: [this.$PlatformsTypes.Microsoft, this.$PlatformsTypes.HyperV, this.$PlatformsTypes.ESXi, this.$PlatformsTypes.FileFolder, this.$PlatformsTypes.Network],
     }
    },
    props: {
        pc: {
            type: Object,
            required: true
        }
    }
}
</script>