var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "dialog light w-100-sm w-100-md w-75-lg w-50-xl h-75 draggable-item",
      attrs: { id: "user_dialog" },
    },
    [
      _c("div", { staticClass: "dialog-title pl-10" }, [
        _vm._v(" " + _vm._s(_vm.$t("Add Policies to Device")) + " "),
        _c("span", {
          staticClass: "button square closer",
          on: {
            click: function ($event) {
              return _vm.$root.$emit("CLOSEDEVICEPOLICYVIEWDIALOG")
            },
          },
        }),
      ]),
      _c("div", { staticClass: "dialog-content overflow bg-light" }, [
        _c("div", { staticClass: "row h-100" }, [
          _c(
            "ul",
            { staticClass: "sidebar-menu col-5 pl-2 pt-5" },
            _vm._l(
              _vm.$session.getPolicyTypes("POLICY_TYPES"),
              function (policyType, index) {
                return policyType.related_entity == "PC"
                  ? _c(
                      "li",
                      {
                        staticClass: "pb-5",
                        class: {
                          "bg-white pl-3 rounded":
                            _vm.policyTypeselected == policyType.id,
                        },
                        on: {
                          click: function ($event) {
                            _vm.policyTypeselected = policyType.id
                          },
                        },
                      },
                      [
                        _c("a", { staticClass: "pl-0 text-bold" }, [
                          _vm._v(_vm._s(policyType.name)),
                        ]),
                        _c("small", { staticClass: "text-muted" }, [
                          _vm._v(_vm._s(policyType.description)),
                        ]),
                      ]
                    )
                  : _vm._e()
              }
            ),
            0
          ),
          _c("div", { staticClass: "col-7 p-4" }, [
            _c(
              "ul",
              _vm._l(_vm.$session.getPolicies(), function (policy) {
                return policy.id_type == _vm.policyTypeselected &&
                  policy.related_entity == "PC"
                  ? _c(
                      "li",
                      {
                        staticClass:
                          "p-2 m-1 d-flex flex-align-center flex-justify-start no-border",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.policiesSelected,
                              expression: "policiesSelected",
                            },
                          ],
                          attrs: {
                            type: "checkbox",
                            "data-caption-position": "right",
                            "data-role": "checkbox",
                            "data-on": true,
                          },
                          domProps: {
                            value: policy.id,
                            checked: Array.isArray(_vm.policiesSelected)
                              ? _vm._i(_vm.policiesSelected, policy.id) > -1
                              : _vm.policiesSelected,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.policiesSelected,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = policy.id,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.policiesSelected = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.policiesSelected = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.policiesSelected = $$c
                              }
                            },
                          },
                        }),
                        _c("label", {}, [_vm._v(_vm._s(policy.name))]),
                      ]
                    )
                  : _vm._e()
              }),
              0
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "dialog-actions" }, [
        _c(
          "button",
          { staticClass: "button primary", on: { click: _vm.savePolicies } },
          [_vm._v(_vm._s(_vm.$t("Save")))]
        ),
        _c(
          "button",
          {
            staticClass: "button ml-2",
            on: {
              click: function ($event) {
                return _vm.$root.$emit("CLOSEDEVICEPOLICYVIEWDIALOG")
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("Close")))]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }