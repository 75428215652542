<template>
    <div class="dialog  light center text-small overflow w-100-sm w-100-md w-75-lg  w-50-xl draggable-item" v-cloak>

        <DialogTitle :title="serviceAccount.id == null ? $t('Add Service Account') : serviceAccount.name"
            :defaultTitle="serviceAccount.id == null ? $t('Add Service Account') : $t('Edit/Test Service Account')"
            :subTitle="serviceAccount.type_name" @closeDialog="$root.$emit('CLOSESERVICEACCOUNTDIALOG')" />

        <!--FINE DIALOG TITLE-->

        <div class="dialog-content overflow bg-light ">

            <div class="form-group">
                <label>{{ $t("Name") }}</label>
                <input data-role="input" type="text" v-model.trim="serviceAccount.name" maxlength="50"
                    :placeholder="$t('enter name')" data-prepend="<span class='mif-pencil'></span>" />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>

            <!--Client ID ----------------------------------------------------------------------->
            <div v-if="needClientIDandClientSecret" class="form-group">
                <label
                    v-if="serviceAccount.type.isCloudS3()">Access
                    Key</label>
                <label
                    v-else-if="[$PlatformsTypes.Cloud_AzureBlob, $PlatformsTypes.Cloud_AzureFile].includes(serviceAccount.type)">Account
                    Name</label>
                <label v-else-if="[$PlatformsTypes.Cloud_Dropbox].includes(serviceAccount.type)">App
                    Key</label>
                <label v-else>Client ID</label>
                <input data-role="input" v-model.trim="serviceAccount.client_id" type="text" autocomplete="off"
                    data-default-value="" data-autocomplete="" required data-prepend="<span class='mif-lock'></span>" />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>

            <!--Client Secret ----------------------------------------------------------------------->
            <div v-if="needClientIDandClientSecret" class="form-group">
                <label
                    v-if="serviceAccount.type.isCloudS3()">Secret Key</label>
                <label
                    v-else-if="[$PlatformsTypes.Cloud_AzureBlob, $PlatformsTypes.Cloud_AzureFile].includes(serviceAccount.type)">Access
                    Key</label>
                <label v-else-if="[$PlatformsTypes.Cloud_Dropbox].includes(serviceAccount.type)">App
                    Secret</label>
                <label v-else>Client Secret</label>
                <input data-role="input" class="metro-input" type="password" v-model.trim="serviceAccount.client_secret"
                    data-prepend="<span class='mif-lock'></span>" />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>

            <!--Region ----------------------------------------------------------------------->
            <div v-if="[$PlatformsTypes.Cloud_AmazonS3Storage].includes(serviceAccount.type)"
                class="form-group">
                <label>{{ $t("Region") }}</label>
                <select class="select w-100" v-model="serviceAccount.region">
                    <option v-for="region in regions" :value="region.Url" :key="region.Url">{{
                        region.Name
                    }}</option>
                </select>
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>

            <!--URL ----------------------------------------------------------------------->
            <div v-if="[$PlatformsTypes.Cloud_S3CompatibleStorage].includes(serviceAccount.type)"
                class="form-group">
                <label>{{ $t("URL") }}</label>
                <input data-role="input" class="metro-input" type="text" v-model="serviceAccount.url" />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>
            <div v-if="[$PlatformsTypes.Cloud_IperiusS3Storage, $PlatformsTypes.Cloud_S3CompatibleStorage].includes(serviceAccount.type)"
                class="form-group">

                <input data-role="checkbox" type="checkbox" :data-caption="$t('Use secure connection (SSL/TLS)')"
                    v-model="serviceAccount.use_ssl" required data-prepend="<span class='mif-lock'></span>" :disabled="serviceAccount.type == $PlatformsTypes.Cloud_IperiusS3Storage"/>
            </div>

            <!--App name ----------------------------------------------------------------------->
            <div v-if="serviceAccount.type == $PlatformsTypes.Cloud_OneDrive" class="form-group">
                <label>{{ $t("Application Name") }}</label>
                <!--
                    Se il client ID è già valorizzato, significa che è stata aperta la dialog in modifica
                    Nel caso di Onedrive, non devo permettere la modifica del nome dell'applicazione registrata. Sarebbe illogico.
                -->
                <input data-role="input" class="metro-input" type="text" v-model.trim="serviceAccount.options.appName"
                    data-prepend="<span class='mif-lock'></span>" :disabled="serviceAccount.client_id != ''" />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>

            <!--Signature version---------------------------------------------------->
            <div v-if="[$PlatformsTypes.Cloud_S3CompatibleStorage].includes(serviceAccount.type)"
                class="form-group">
                <label>{{ $t("Signature version") }}</label>
                <select class="select w-25" v-model="serviceAccount.options.signatureversion">
                    <option :value="2" :key="2">2</option>
                    <option :value="4" :key="4">4</option>
                </select>
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>

            <!--DATI EXTRA PER AUTENTICAZIONE ------------------------------------------------------->
            <!--Se durante l'autenticazione a Dropbox è necessaria l'authorization code, verrà mostrata quasta parte-->
            <div class="form-group" v-if="serviceAccount.options.authCode != undefined && serviceAccount.options.authCode != null">
                <label>{{ $t("AuthCode") }}</label>
                <input data-role="input" class="metro-input" type="password" v-model.trim="serviceAccount.options.authCode"
                    :placeholder="$t('Enter auth code')" data-prepend="<span class='mif-lock'></span>" />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>

            <!--Se durante l'autenticazione a Google Drive è necessaria l'abilitazione di Google Drive API nella console di Google-->
            <div class="form-group" v-if="googledrive_url == ''">
                {{ $t("Click on the following URL and enable the Google Drive API, then try the test again") }}:<br />
                <a :href="googledrive_url" target="_blank">{{ googledrive_url }}</a>
            </div>
        </div>

        <!-------------------------------TEST ACCOUNT----------------------------------------->
        <div class="dialog-actions d-flex" :class="onedrive_step == null ? 'flex-justify-end' : 'flex-justify-between'">
            <!--Durante l'autenticazione a Onedrive, verrà mostrato un messaggio che indica il corrente step -->
            <div v-if="onedrive_step != null" class="text-light fg-cobalt">{{ $t(onedrive_step) }}</div>
            <button v-if="serviceAccount.id !=null" class="button primary" :disabled="tested == TEST_WAIT" @click="$root.duplicateServiceAccount(serviceAccount)">{{ $t("Duplicate") }}</button><!---->
            <div v-if="tested == TEST_WAIT"><span class="mif-spinner2 ani-spin" /></div>
            <button class="button primary" @click="test" :disabled="!enableTest">
                {{ $t("Test account") }}
            </button>
            <button class="button primary" :disabled="!enableSave" @click="$root.saveServiceAccount(serviceAccount)">{{ $t("Save") }}</button>
            <button class="button primary" @click="$root.$emit('CLOSESERVICEACCOUNTDIALOG')">{{ $t("Close") }}</button>
            <button v-if="serviceAccount.id !=null" class="button alert" :disabled="tested == TEST_WAIT" @click="$root.deleteServiceAccount(serviceAccount)">{{ $t("Delete") }}</button>
        </div>

    </div>
</template>

<script>
import { SERVICEACCOUNT_MESSAGES } from "../../../utils/messages";
import { cookieManager, isValid } from "../../../utils/utilitiesmodule";
import DialogTitle from "../../utils/DialogTitle.vue";

import { draggableMixin } from '../../../mixins/draggable-mixin'


export default {

    mixins: [draggableMixin],
    name: "CloudServiceAccount",
    components: {
        "DialogTitle": DialogTitle
    },

    props: {
        serviceAccount: Object,
    },

    data() {
        return {
            regions: [],

            // -1 : test in corso
            //  0 : test fallito o in attesa di un test 
            //  1 : test andato a buon fine
            tested: 0,

            TEST_NOTOK: 0,
            TEST_WAIT: -1,
            TEST_OK: 1,

            googledrive_url: null,// caso particolare di Google Drive,


            onedrive_step: null,
            onedrive_readStepCookie: null,
        }
    },
    computed: {
        needClientIDandClientSecret() {
            switch (this.serviceAccount.type) {
                case this.$PlatformsTypes.Cloud_GoogleDrive:
                case this.$PlatformsTypes.Cloud_S3CompatibleStorage:
                case this.$PlatformsTypes.Cloud_IperiusS3Storage:
                case this.$PlatformsTypes.Cloud_AmazonS3Storage:
                case this.$PlatformsTypes.Cloud_Dropbox:
                case this.$PlatformsTypes.Cloud_AzureBlob:
                case this.$PlatformsTypes.Cloud_AzureFile:
                    return true;
                default:
                    return false;
            }
        },
        allInputCompleted() {
            var specificInputsCompleted;
            switch (this.serviceAccount.type) {
                case this.$PlatformsTypes.Cloud_S3CompatibleStorage:
                case this.$PlatformsTypes.Cloud_IperiusS3Storage:
                    specificInputsCompleted=this.serviceAccount.client_id!=""&&this.serviceAccount.client_secret!=""&&this.serviceAccount.url!="";
                    break;
                case this.$PlatformsTypes.Cloud_AmazonS3Storage:
                    specificInputsCompleted=this.serviceAccount.client_id!=""&&this.serviceAccount.client_secret!=""&&this.serviceAccount.region!="";
                    break;
                case this.$PlatformsTypes.Cloud_AzureBlob:
                case this.$PlatformsTypes.Cloud_AzureFile:
                case this.$PlatformsTypes.Cloud_Dropbox:
                case this.$PlatformsTypes.Cloud_GoogleDrive:
                    specificInputsCompleted=this.serviceAccount.client_id!=""&&this.serviceAccount.client_secret!="";
                    break;
                case this.$PlatformsTypes.Cloud_OneDrive:
                    specificInputsCompleted=isValid(this.serviceAccount.options.appName);
                    break;
            }
            return this.serviceAccount.name!=""&&specificInputsCompleted;
        },
        enableTest() {
            return this.allInputCompleted&&this.tested==this.TEST_NOTOK;
        },
        enableSave() {
            return this.allInputCompleted&&(this.tested==this.TEST_OK||this.tested==this.TEST_NOTOK&&this.serviceAccount.id!=null);
        }

    },
    created() {

        if (this.serviceAccount.type==this.$PlatformsTypes.Cloud_AmazonS3Storage) {
            let self=this;
            this.$api.getAmazonRegions().then(regions => {
                self.regions=regions;
            });
        }

        //this.tested = this.serviceAccount.id == "" ? this.TEST_NOTOK : this.TEST_OK;
    },

    methods: {

        async test() {
            let self=this;

            this.tested=this.TEST_WAIT;

            if (self.serviceAccount.type==this.$PlatformsTypes.Cloud_IperiusS3Storage) {
                self.serviceAccount.url="https://{0}-s3.iperiusstorage.com".format(this.serviceAccount.client_id);
            }

            if (self.serviceAccount.type==this.$PlatformsTypes.Cloud_S3CompatibleStorage) {
                self.serviceAccount.region=this._extractRegionFromURL();
            }

            //Se il service account è di tipo Onedrive, avvio un timer che legge il cookie che conterrà un messaggio che descrive il corrente step
            if (self.serviceAccount.type==this.$PlatformsTypes.Cloud_OneDrive) {
                cookieManager.setCookie("step", "");

                self.onedrive_readStepCookie=setInterval(
                    () => { self.onedrive_step=cookieManager.getCookie("step"); },
                    2000
                );
            }

            /**
             * {
             *  tested : //bool,
             *  serviceAccount : ,
             *  client .
             * }
             */
            var testResult=await this.$root.testServiceAccount(self.serviceAccount);
            self.tested=testResult.okNumber;

            //Se è Onedrive, fermo il timer per leggere il cookie dello step
            if (self.serviceAccount.type==this.$PlatformsTypes.Cloud_OneDrive) {
                clearInterval(self.onedrive_readStepCookie);
                self.onedrive_readStepCookie=null;
                self.onedrive_step=cookieManager.getCookie("step");
            }

            //Caso 1 : il test è andato a buon fine
            if (testResult.ok) {
                this.$root.toast(this.$t(SERVICEACCOUNT_MESSAGES.TEST_OK, { 0: this.serviceAccount.name }), 3000, "success");
                return;
            }

            //Caso 2: Il test non è andato a buon fine e:
            // - è Dropbox
            // - non è stato aggiunto un authorization code
            if (self.serviceAccount.type==this.$PlatformsTypes.Cloud_Dropbox&&self.serviceAccount.options.authCode==null) {
                self.serviceAccount.options.authCode=""; // fa apparire la parte per inserire l'authorization code
                return;
            }

            //Caso 3 : Il test non è andato a buon fine e:
            // - è Google Drive
            // - il risultato ha restituito un url
            if (self.serviceAccount.type==this.$PlatformsTypes.Cloud_GoogleDrive&&testResult.url!=undefined) {
                this.googledrive_url=testResult.url;
                return;
            }

            //Caso 4 : Il test non è andato a buon fine
            this.$root.toast(this.$t(SERVICEACCOUNT_MESSAGES.TEST_FAILED, { 0: this.serviceAccount.name }), 3000, "alert");
        },


        isDisabled() {
            switch (this.serviceAccount.type) {
                case this.$PlatformsTypes.Cloud_OneDrive:
                    return !isValid(this.serviceAccount.options.appName);
                //case this.$PlatformsTypes.Cloud_S3CompatibleStorage:
                //case this.$PlatformsTypes.Cloud_IperiusS3Storage:
                case this.$PlatformsTypes.Cloud_AmazonS3Storage:
                    return this.serviceAccount.client_id==''||this.serviceAccount.client_secret==''||this.serviceAccount.region=='';
                default:
                    return this.serviceAccount.client_id==''||this.serviceAccount.client_secret=='';
            }

        },

        //Alcuni account s3 prevedono la region.
        //Ho mappato gli s3 principali
        _extractRegionFromURL() {
            this.serviceAccount.url=this.serviceAccount.url.trimEnd("/");

            //In tutti i seguenti s3, la region è la prima parte dell'URL
            if (
                //DIGITALOCEAN : https://<bucket-name>.<region-code>.digitaloceanspaces.com
                this.serviceAccount.url.contains(".digitaloceanspaces.com")||
                //Linode Object Storage : https://<bucket-name>.<region-code>.linodeobjects.com
                this.serviceAccount.url.contains(".linodeobjects.com")||

                //Ceph Object Storage : https://<bucket-name>.<region-code>.ceph.example.com
                this.serviceAccount.url.contains(".ceph.")||

                //MinIO : https://<bucket-name>.<region-code>.minio.example.com
                this.serviceAccount.url.contains(".minio.")
            ) {
                return this.serviceAccount.url.split(".")[0].replace("https://", "");
            }


            //In tutti i seguenti s3, la region è la seconda parte dell'URL
            if (
                //WASABI : https://s3.<region-code>.wasabisys.com
                this.serviceAccount.url.contains(".wasabisys.com")||

                //BACKBLAZE B2 : https://s3.<region-code>.backblazeb2.com
                this.serviceAccount.url.contains(".backblazeb2.com")||

                //SCALEWAY : https://s3.<region-code>.scw.cloud
                this.serviceAccount.url.contains(".scw.cloud")||

                //IBM Cloud Object Storage : https://s3.<region-code>.cloud-object-storage.appdomain.cloud
                this.serviceAccount.url.contains(".cloud-object-storage.appdomain.cloud")
            ) {
                return this.serviceAccount.url.split(".")[1];
            }

            //Alibaba Cloud Object Storage Service (OSS):
            //https://<bucket-name>.oss-<region-code>.aliyuncs.com
            if (this.serviceAccount.url.contains(".aliyuncs.com")) {
                return this.serviceAccount.url.spit(".")[1].replace("oss-", "");
            }

            //Se l'esecuzione arriva qua, l'account S3 non prevede la region, quindi restituisco stringa vuota
            return "";
        },
    }
}
</script>