<template>

    <div id="destinationBackup" v-on:keyup.esc="close()"
        class="dialog  light w-100-sm w-50 w-50-lg w-50-xl pos-center h-75 ">

        <DialogTitle :title="destination.name ?? 'Configure Destination'"
            :defaultTitle="$t('Configure Destination')" :subTitle="$t('Destination') + ' ' + destination.type_name"
            @closeDialog="close()" />

        <div class="dialog-content overflow bg-light">

            <!--SCELTA DELLE CREDENZIALI-->
            <div v-if="destination.type != $PlatformsTypes.FileFolder" class="form-group d-flex flex-column">
                <label class="text-bold">{{ $t("Choose credentials") }}</label>
                <input type="radio" :data-caption="$t('Create or select a repository')" @click="changeCredentialMode('repo')" data-role="radio" name="r1" :checked="credentialMode == 'repo'">
                <input type="radio" :data-caption="$t('Create or select a service account')" @click="changeCredentialMode('sa')" data-role="radio" name="r1" :checked="credentialMode == 'sa'">
            </div>

            <!------------------------------SCELTA DEL REPOSITORY--------------------------------------------------------------->
            <div v-if="destination.type != $PlatformsTypes.FileFolder && credentialMode == 'repo'">
                <SARepoSelect 
                    :type=destination.type :idRepo="destination.id_repository" :idSA=destination.id_service_account :selectRepo="true" 
                    @sendSATestResult="sendSATestResult" key="selectRepo"
                />
            </div>
            

            <!------------------------------SCELTA DEL SERVICE ACCOUNT--------------------------------------------------------------->
            <!--Questa pagina deve implementare il metodo "sendSATestResult"-->
            <div v-else-if="destination.type != $PlatformsTypes.FileFolder && credentialMode == 'sa'">
                <SARepoSelect 
                    :type=destination.type :idSA=destination.id_service_account :selectRepo="false" :idRepo="-2"
                    @sendSATestResult="sendSATestResult" key="selectSA"/>
            </div>
            

            <!--SCELTA DEL PERCORSO-->
            <SelectedResourcesLayout :buttonText="$t('Select destination folder')" :singleItem="true"
                :isDisabled="destination.type != $PlatformsTypes.FileFolder && !testResult.ok"
                :list="selectedNodes" :heightPercentage="0" :canCancel="true" :type="destination.type"
                @clickFunction="toggleFileSystemSidebar" @setNewPath="setPathAndName"
                v-if="credentialMode != 'repo'"
                />

            <!--SCELTA DEL NOME-->
            <div class="form-group" :disabled="destination.type == $PlatformsTypes.FileFolder && destination.path==''">
                <label class="text-bold">{{ $t("Backup Destination name") }}</label>
                <input class="input" data-role="input" v-model.trim="destination.name" />
            </div>
        </div>
        <!--BOTTONI-->
        <div class="dialog-actions">
            <div class="ml-auto">
                <button class="button primary" :disabled="!enableSave" @click="save"> {{ $t("Save") }}</button>
                <button class="button" @click="close()"><span class="mif-close"></span> {{ $t("Close") }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import DialogTitle from "../../utils/DialogTitle";
import SelectedResourcesLayout from "../../utils/SelectedResourcesLayout";
import SARepoSelect from "../../utils/SARepoSelect.vue";
import { getNodeType, isValid } from "../../../utils/utilitiesmodule";
import { FileSystemSidebarConfig } from "../../../models/TreeNode";

export default {
    name: "Destination",
    props: {
        destination: Object
    },
    components: {
        "DialogTitle": DialogTitle,
        SARepoSelect,
        SelectedResourcesLayout
    },

    data() {
        return {
            paths: [],
            credentialMode: '',
            selectedNodes: [],
            testResult: {
                ok: false,
                okNumber: 0,
                serviceAccount: { id: -2, type: this.destination.type },
                client: null,// il client della piattaforma che effettuerà le richieste dei list
                list: null,
            }
        }
    },
    computed: {
        enableSave() {
            return this.destination.name!=""&&this.destination.path!="";
            /*var partialValue=(this.destination.type==this.$PlatformsTypes.FileFolder&&this.destination.path!="")||
                (this.destination.type!=this.$PlatformsTypes.FileFolder&&this.credentialMode=="repo"&&this.destination.path!="")||
                (this.destination.type!=this.$PlatformsTypes.FileFolder&&this.credentialMode=="sa"&&this.selectedNodes.length==1)
            return this.destination.name!=""&&partialValue;*/
        }
    },
    watch: {
        selectedNodes: {
            handler: function(value) {
                if (this.credentialMode=='repo'||value.length==0) {
                    return;
                }

                this.setPathAndName(value[0].path);
            }
        }
    },

    created() {

        this.selectedNodes.push({
            path: this.destination.path,
            type: getNodeType(this.destination.type, false)
        });

        this.credentialMode=this.destination.type==this.$PlatformsTypes.FileFolder
            ? ''
            :this.destination.id==null||this.destination.id_repository!=-2
                ? 'repo'
                :'sa';
    },

    async beforeDestroy() {
        this.$root.$off("socketEventConnected");
        this.$root.$off("socketEventDisconnected");
        //In caso di ftp o network, eseguo la disconnessione
        if (this.testResult.client!=null&&[this.$PlatformsTypes.FTP, this.$PlatformsTypes.Network].includes(this.destination.type)) {
            var method=this.destination.type==2? "disconnectftp":"disconnectnetwork";
            this.$root.socket(method, this.testResult.client.serviceAccount.options.accountSessionId);
        }
    },
    methods: {
        sendSATestResult(testResult) {
            this.testResult=testResult;
            this.destination.id_service_account=testResult.serviceAccount.id;
            if (isValid(testResult.repository)) {
                this.destination.name=testResult.repository.name;
                this.destination.id_repository=testResult.repository.id;
                this.destination.path=testResult.repository.path;
                //this.selectedNodes[0].path = this.destination.path;
                return;
            }

            this.setPathAndName(this.$root.getSARootPath(testResult.serviceAccount));

        },

        setPathAndName(path) {
            var config={
                path: path,
                type: this.destination.type
            };

            if ([this.$PlatformsTypes.Network, this.$PlatformsTypes.FTP].includes(this.destination.type)) {
                config.prefix=this.$root.getSARootPath(this.testResult.serviceAccount);
            }

            if (this.destination.type!=this.$PlatformsTypes.FileFolder) {
                config.saName=this.testResult.serviceAccount.name;
            }

            if (this.destination.id_repository!=-2) {
                config.repoName=this.testResult.repository.name;
            }

            [this.destination.path, this.destination.name]=this.$root.getPathAndName(config);

            if (this.credentialMode=='sa') {
                this.selectedNodes[0].path=this.destination.path;
            }

        },

        changeCredentialMode(value) {
            this.credentialMode=value;
            if (value=="sa") {
                this.destination.id_repository=-2
            } else {
                this.destination.id_service_account=-2
            }
        },
        async toggleFileSystemSidebar() {

            var config=new FileSystemSidebarConfig(
                this.destination.type,
                false, //si vuole non includere file
                false, // si vuole la scelta singola
                this.testResult.client,
                this.$root.getSARootPath(this.testResult.serviceAccount),
                false, //un solo livello
                null//tree
            );

            this.$root.$emit("toggleFileSystemSidebar", this.selectedNodes, config);
        },

        async save() {
            this.$root.$emit("closeFileSystemSidebar");
            if (this.selectedNodes.length==1)
                this.destination.path=this.selectedNodes[0].path;

            if (this.destination.type==this.$PlatformsTypes.FTP&&!this.destination.path.startsWith(this.testResult.client.serviceAccount.url))
                this.destination.path=this.testResult.client.serviceAccount.url+"/"+this.destination.path;

            //salva la destinazione nel job e chiude la dialog
            this.$root.$emit("saveBackupDestination", this.destination);
        },

        cancel() {
            this.$root.$emit("uncheckNode", this.selectedNodes.pop());
        },

        async close() {
            //In caso di ftp o network, eseguo la disconnessione
            if (this.testResult.client!=null&&[this.$PlatformsTypes.FTP, this.$PlatformsTypes.Network].includes(this.destination.type)) {
                var method=this.destination.type==this.$PlatformsTypes.FTP? "disconnectftp":"disconnectnetwork";
                this.$root.socket(method, this.testResult.client.serviceAccount.options.accountSessionId);
            }
            this.$root.$emit('CLOSEDESTINATIONDIALOG');
            this.$root.$emit("closeFileSystemSidebar");
        },
    }
}
</script>