var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c(
      "a",
      {
        staticClass: "button dropdown-toggle",
        class:
          _vm.connectionsStates[_vm.pc.id_remote] == true
            ? "bg-remote fg-white"
            : "",
        attrs: {
          title: !_vm.connectionsStates[_vm.pc.id_remote]
            ? _vm.$t("Start Iperius Remote (automatically downloads if needed)")
            : _vm.$t("Open a Remote connection"),
        },
      },
      [
        _c("span", {
          class: _vm.wait
            ? "mif-spinner3 ani-spin"
            : _vm.connectionsStates[_vm.pc.id_remote]
            ? "icon mif-2x mif-cast-connected"
            : "icon mif-2x mif-cast",
        }),
        _vm.complete == "true"
          ? _c("div", { staticClass: "text-left" }, [
              _vm._v(" " + _vm._s(_vm.$t("New Connection")) + " "),
            ])
          : _vm._e(),
      ]
    ),
    _c(
      "ul",
      {
        staticClass: "d-menu border place-right text-left mt-2",
        attrs: { "data-role": "dropdown" },
      },
      [
        _vm.connectionsStates[_vm.pc.id_remote]
          ? _c("li", { staticClass: "text-bold" }, [
              _c(
                "a",
                {
                  class:
                    _vm.connectionsStates[_vm.pc.id_remote] == true
                      ? "secondary"
                      : "fg-gray disabled",
                  attrs: {
                    title: _vm.$t(
                      "The Iperius Remote client will launch and initiate a remote session to the host.",
                      { 0: _vm.pc.id_remote.replace(/(\d{3})(?=\d)/g, "$1 ") }
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$root.getOTP(_vm.pc.id)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "icon mif-2x mif-screen_share" }),
                  _vm._v(
                    _vm._s(
                      _vm.$t("Connect to {0}", {
                        0: _vm.pc.id_remote.replace(/(\d{3})(?=\d)/g, "$1 "),
                      })
                    ) + " "
                  ),
                ]
              ),
            ])
          : _vm._e(),
        !_vm.connectionsStates[_vm.pc.id_remote] &&
        _vm.pc.backup_license_status !== 0
          ? _c("li", [
              _c(
                "a",
                {
                  staticClass: "fg-dark",
                  attrs: {
                    title: _vm.$t(
                      "The Iperius Remote client will be started on the remote host (automatically downloads if needed)"
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.downloadandRunIR4(_vm.pc)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "icon mif-2x mif-cast-connected" }),
                  _vm._v(
                    " " + _vm._s(_vm.$t("Enable remote connection")) + " "
                  ),
                ]
              ),
            ])
          : _vm._e(),
        !_vm.connectionsStates[_vm.pc.id_remote] &&
        _vm.pc.backup_license_status === 0
          ? _c("li", { staticClass: "text-center p-4" }, [
              _c("span", { staticClass: "mif-lock fg-red mif-2x mb-2" }),
              _c("span", { staticClass: "d-block" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "To automatically install and start Iperius Remote on this device, the Iperius One Agent must be installed first."
                      )
                    ) +
                    " "
                ),
              ]),
              _c(
                "a",
                {
                  staticClass: "fg-primary text-bold mt-2",
                  attrs: { href: "https://www.iperius.com", target: "_blank" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("Click here to purchase Iperius One")) +
                      " "
                  ),
                ]
              ),
            ])
          : _vm._e(),
        _vm.connectionsStates[_vm.pc.id_remote]
          ? _c("li", [
              _c(
                "a",
                {
                  class:
                    _vm.connectionsStates[_vm.pc.id_remote] == true
                      ? "fg-dark"
                      : "fg-gray disabled",
                  attrs: {
                    title: _vm.$t(
                      "The Iperius Remote client will restart on the remote host."
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.restartIR4(_vm.pc)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "icon mif-redo" }),
                  _vm._v(
                    " " + _vm._s(_vm.$t("Restart the remote client")) + " "
                  ),
                ]
              ),
            ])
          : _vm._e(),
        _vm.connectionsStates[_vm.pc.id_remote]
          ? _c("li", [
              _c(
                "a",
                {
                  class:
                    _vm.connectionsStates[_vm.pc.id_remote] == true
                      ? "fg-dark"
                      : "fg-gray disabled",
                  attrs: {
                    title: _vm.$t(
                      "The Iperius Remote client will close on the remote host."
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.closeIR4(_vm.pc)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "icon mif-2x mif-cast" }),
                  _vm._v(
                    " " + _vm._s(_vm.$t("Terminate the remote client")) + " "
                  ),
                ]
              ),
            ])
          : _vm._e(),
        _vm.connectionsStates[_vm.pc.id_remote] &&
        _vm.pc.version_remote < _vm.$session.getLastRemoteVersion()
          ? _c("li", [
              _c(
                "a",
                {
                  class:
                    _vm.connectionsStates[_vm.pc.id_remote] == true
                      ? "fg-dark"
                      : "fg-gray disabled",
                  attrs: {
                    title: _vm.$t(
                      "The Iperius Remote client will update on the remote host."
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.updateIR4(_vm.pc)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "icon mif-2x mif-refresh" }),
                  _vm._v(
                    " " + _vm._s(_vm.$t("Update the remote client")) + " "
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }