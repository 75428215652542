<template>
    <div v-cloak>
        <div class="loader overflow" v-if="nOpenDialogs != 0" />

        <!-- GENERIC DIALOGS-->
        <GroupDialog v-if="group" :computers="computers" :group="group" :users="users" />
        <PolicyDialog v-if="policy" :computers="computers" :policy="policy" />
        <SchedulationDialog v-if="openSched" />
        <UserDialog v-if="user" :selecteduser="user" :checkTFA=checkTFA />
        <PolicyDeviceDialog v-if="device" :device="device" :policiesList="policiesList" />
        <!-- <ComputerAgentDialog  v-if="createagent"  /> -->
        
        <!--------------------------------BACKUP - DESTINAZIONI--------------------------------------------------------------->
        <Destination v-if="destination != null" :destination="destination" class="destinationDialog" />
        <!--------------------------------BACKUP - SORGENTI--------------------------------------------------------------->
        <FolderSource v-if="source != null && source.type == $PlatformsTypes.FileFolder" :source="source"
            class="sourceDialog" />

        <NetworkSource v-if="source != null && source.type == $PlatformsTypes.Network" :source="source"
            class="sourceDialog" />

        <!--Vengono nascoste se la finestra del service account viene aperta-->
        <DriveImageSource v-if="source != null && source.type == $PlatformsTypes.DriveImage"
            :source="source" />

        <HyperVSource v-if="source != null && source.type == $PlatformsTypes.HyperV"
            v-show="serviceAccount == null" :source="source" />

        <ESXiSourceDestination v-if="source != null && source.type == $PlatformsTypes.ESXi"
            v-show="serviceAccount == null" :source="source" class="sourceDialog" />

        <ExchangeSource v-if="source != null && source.type.isExchange()" v-show="serviceAccount == null"
            :source="source" class="sourceDialog" />

        <DatabaseSource v-if="source != null && source.type.isDatabase()" v-show="serviceAccount == null"
            :source="source" class="sourceDialog" />

        <MicrosoftSource v-if="source != null && source.type.isMicrosoft365()"
            v-show="serviceAccount == null" :source="source" class="sourceDialog" />


        <!--------------------------RESTORE------------------------------------------------------------------------------->
        <RestoreFile v-if="restore != null && restore.type.isSimpleFileNFolderType()"
            v-show="serviceAccount == null && repository == null" :restore="restore" class="restoreDialog" />

        <!------------------------RESTORE - MANUAL------------------------------------------------------------->
        <div v-if="restore != null && !isValid(restore.idBackup)" v-show="serviceAccount == null">
            <HyperVManualRestore v-if="restore.type == $PlatformsTypes.HyperV" :source="restore"
                class="restoreDialog" />

            <ESXiManualRestore v-if="restore.type == $PlatformsTypes.ESXi" :source="restore"
                class="restoreDialog" />


            <DatabaseManualRestore v-if="restore.type == $PlatformsTypes.Database_SQLServer" :source="restore"
                class="restoreDialog" />
        </div>

        <ExchangeManualRestore v-if="restore != null && restore.type.isExchange()" :restore="restore"
            class="restoreDialog" />

        <!------------------------RESTORE - AUTOMATIC------------------------------------------------------------->
        <div v-if="restore != null && isValid(restore.idBackup)" v-show="serviceAccount == null">
            <ESXiDestinationRestore v-if="restore.type == $PlatformsTypes.ESXi" :idJob="selectedJobId" :idPC="$session.getRoomPC().id" class="restoreDialog" />
            <!--ExchangeDestinationRestore v-if="restore.type.isExchange()" :idJob="selectedJobId"
                :idPC="$session.getRoomPC().id" class="restoreDialog" /-->
            <HyperVDestinationRestore v-if="restore.type == $PlatformsTypes.HyperV" :idJob="restore.idBackup" :idPC="$session.getRoomPC().id" class="restoreDialog" />
            <DatabaseDestinationRestore v-if="restore.type == $PlatformsTypes.Database_SQLServer" :idJob="restore.idBackup" :idPC="$session.getRoomPC().id" class="restoreDialog" />
        </div>

        <!--------------------------VISTE----------------------------------------------------------------->
        <BackupView v-if="idBackupForView != null" :idBackup="idBackupForView" />


        <!------------------------REPOSITORY--------------------------------------------->
        <Repository v-if="isValid(repository)" :repository="repository" class="repositoryDialog" />

        <!--------------------------SERVICE ACCOUNTS-------------------------------------->
        <div v-if="isValid(serviceAccount)">
            <CloudServiceAccount v-if="serviceAccount.type.isCloud() && serviceAccount.type != $PlatformsTypes.Cloud_OneDrive" :serviceAccount="serviceAccount" class="saDialog" />
            <FTPServiceAccount v-if="serviceAccount.type == $PlatformsTypes.FTP" :serviceAccount="serviceAccount" class="saDialog" />
            <NetworkServiceAccount v-if="serviceAccount.type == $PlatformsTypes.Network" :serviceAccount="serviceAccount" class="saDialog" />
            <ESXIServiceAccount v-if="serviceAccount.type == $PlatformsTypes.ESXi" :serviceAccount="serviceAccount" class="saDialog" />
            <!--Exchange + onedrive + sharepoint + teams-->
            <MicrosoftServiceAccount v-if="serviceAccount.type.isMicrosoft() || serviceAccount.type == $PlatformsTypes.Cloud_OneDrive" :serviceAccount="serviceAccount" class="saDialog" />
            <DatabaseServiceAccount v-if="serviceAccount.type.isDatabase()" :serviceAccount="serviceAccount" class="saDialog" />
            <EmailServiceAccount v-if="serviceAccount.type.isEmail()" :serviceAccount="serviceAccount" class="saDialog" />
        </div>

        <SARepoView v-if="sarepo != null" :repository="sarepo.repository" :serviceAccount="sarepo.serviceAccount" />
        <!-------------------------MINI DIALOGS---------------------------------------------------------------------------------------------->

        <CreateFolder v-if="createFolderObj!=null" :createFolderObj="createFolderObj" />


        <!--------------------------FINESTRA PER RINOMINARE UNA CARTELLA O UN FILE-------------------------------->
        <div v-if="renameItemDialog != null" class="dialog light">
            <div class="dialog-title pl-10">{{ $t("Rename") }}</div>
            <div class="d-flex flex-column flex-align-center flex-justify-center mb-2 text-small px-4">
                <div class="d-flex flex-align-center flex-justify-center mb-2 row">
                    <span :class="renameItemDialog.resource.type.getMetroIcon(renameItemDialog.resource.path)" class="mr-2" />
                    <span>{{ renameItemDialog.parentPath }}</span>
                </div>
                <input type="text" class="input" v-model="renameItemDialog.name" v-fsitem/>
            </div>
            <div class="dialog-actions">
                <button class="button js-dialog-close primary my-3" @click="renameItem"
                    :disabled="renameItemDialog.name == ''">{{ $t("Save") }}</button>
                <button class="button js-dialog-close" @click="closeRenameItemDialog">{{ $t("Close") }}</button>
            </div>
        </div>

        <!--------------------------FINESTRA PER CANCELLARE UNA CARTELLA O UN FILE-------------------------------->
        <div v-if="deleteItemDialog != null" class="dialog h-auto" style="min-height: fit-content;">
            <div class="dialog-title pl-10">{{ $t("Do you want delete selected item?") }}</div>
            <div class=" text-small p-4">
                <span :class="deleteItemDialog.resource.type.getMetroIcon(deleteItemDialog.resource.path)" class="mr-2" />
                <span>{{ deleteItemDialog.resource.path }}</span>
            </div>
            <div class="dialog-actions">
                <button class="button js-dialog-close primary my-3" @click="deleteItem">{{ $t("Yes") }}</button>
                <button class="button js-dialog-close alert" @click="closeDeleteItemDialog">{{ $t("No") }}</button>
            </div>
        </div>

        <!--------------------------FINESTRA PER RINOMINARE UN JOB-------------------------------->
        <div v-if="renameJobDialog != null" class="dialog light"
            style="min-height: fit-content; padding-top: 8px !important;padding-bottom:8px !important;">
            <div class="dialog-title pl-10">{{ $t("Rename Job") }}</div>
            <div class="dialog-content d-flex flex-column dialog-content flex-justify-center flex-align-center">
                <s class="mb-4">{{ renameJobDialog.oldName }}</s>
                <input type="text" v-model="renameJobDialog.newName" class="input w-75 mb-4" :placeholder="$t('Enter the new name of the backup')" v-fsitem />
            </div>
            <div class="dialog-actions">
                <button class="button js-dialog-close primary my-3" @click="renameJob"
                    :disabled="renameJobDialog.newName == ''">{{ $t("Save") }}</button>
                <button class="button js-dialog-close" @click="closeRenameJobDialog">{{ $t("Close") }}</button>
            </div>
        </div>

        <!------------------------------FINESTRA VISUALIZZAZIONE LOG DEI BACKUP IN HTML------------------------------>
        <div v-if="backupLog" v-on:keyup.esc="closeBackupLog()" class="dialog center overflow w-33">
            <div>
                <span class="button square closer" @click="closeBackupLog()"></span>
            </div>
            <div class="dialog-content py-5 px-8" v-html="backupLog"></div>
        </div>
    </div>
</template>
<script>
import GroupDialog from "../components/dialogs/view/GroupDialog"
import UserDialog from "../components/dialogs/view/UserDialog"
import PolicyDialog from "./dialogs/view/PolicyDialog.vue";
import SchedulationDialog from "./dialogs/view/SchedulationDialog.vue";
//SERVICEACCOUNTS
import CloudServiceAccount from "./dialogs/serviceaccount/CloudServiceAccount";
import DatabaseServiceAccount from "./dialogs/serviceaccount/DatabaseServiceAccount";
import EmailServiceAccount from "./dialogs/serviceaccount/EmailServiceAccount";
import ESXIServiceAccount from "./dialogs/serviceaccount/ESXIServiceAccount";
import MicrosoftServiceAccount from "./dialogs/serviceaccount/MicrosoftServiceAccount";
import FTPServiceAccount from "./dialogs/serviceaccount/FTPServiceAccount";
import NetworkServiceAccount from "./dialogs/serviceaccount/NetworkServiceAccount";
//REPOSITORY
import Repository from "./dialogs/repository/Repository";
//BACKUP - SOURCE
import FolderSource from "./dialogs/backup/FolderSource";
import NetworkSource from "./dialogs/backup/NetworkSource";
import DriveImageSource from './dialogs/backup/DriveImageSource';
import DatabaseSource from './dialogs/backup/DatabaseSource';
import HyperVSource from './dialogs/backup/HyperVSource';
import ExchangeSource from './dialogs/backup/ExchangeSource';
import ESXiSourceDestination from './dialogs/backup/ESXiSourceDestination';
import MicrosoftSource from './dialogs/backup/MicrosoftSource';
//BACKUP - DESTINATION
import Destination from "./dialogs/backup/Destination";
//RESTORE
import RestoreFile from "./dialogs/restore/RestoreFile";
import DatabaseDestinationRestore from "./dialogs/restore/DatabaseDestinationRestore";
import DatabaseManualRestore from "./dialogs/restore/DatabaseManualRestore";
import ESXiDestinationRestore from "./dialogs/restore/ESXiDestinationRestore";
import ESXiManualRestore from "./dialogs/restore/ESXiManualRestore";
import ExchangeDestinationRestore from "./dialogs/restore/ExchangeDestinationRestore";
import ExchangeManualRestore from "./dialogs/restore/ExchangeManualRestore";
import HyperVDestinationRestore from "./dialogs/restore/HyperVDestinationRestore";
import HyperVManualRestore from "./dialogs/restore/HyperVManualRestore";
//VISTE
import BackupView from './dialogs/view/BackupView';
import SARepoView from './dialogs/view/SARepoView';
//UTILS
import { DefaultObjectBuilder } from '../models/DefaulfObjectBuilder';
import { isValid } from '../utils/utilitiesmodule';

import $ from 'jquery';
import CreateFolder from "./utils/CreateFolder.vue";
import PolicyDeviceDialog from "./dialogs/view/PolicyDeviceDialog.vue";

export default {
    name: "Dialogs",
    components: {
        //SERVICEACCOUNTS
        CloudServiceAccount,
        DatabaseServiceAccount,
        EmailServiceAccount,
        ESXIServiceAccount,
        MicrosoftServiceAccount,
        FTPServiceAccount,
        NetworkServiceAccount,
        //REPOSITORY
        Repository,
        //BACKUP -SOURCE
        FolderSource,
        NetworkSource,
        DriveImageSource,
        DatabaseSource,
        HyperVSource,
        ExchangeSource,
        ESXiSourceDestination,
        MicrosoftSource,
        //BACKUP - DESTINATION
        Destination,
        //RESTORE
        RestoreFile,
        DatabaseDestinationRestore,
        DatabaseManualRestore,
        ESXiDestinationRestore,
        ESXiManualRestore,
        ExchangeDestinationRestore,
        ExchangeManualRestore,
        HyperVDestinationRestore,
        HyperVManualRestore,
        //VISTE
        BackupView,
        SARepoView,
        GroupDialog,
        PolicyDialog,
        UserDialog,
        SchedulationDialog,
        CreateFolder,
        PolicyDeviceDialog
    },
    created: function() {

        /*if(this.$route.hash != ""){
            this.$router.push(this.$route.path);
        }*/

        this.$root.$on("OPENSERVICEACCOUNTDIALOG", type_or_serviceAccount => this.openServiceAccountDialog(type_or_serviceAccount));
        this.$root.$on("CLOSESERVICEACCOUNTDIALOG", () => this.closeServiceAccountDialog());

        this.$root.$on("OPENREPOSITORYDIALOG", type_or_repository => this.openRepositoryDialog(type_or_repository));
        this.$root.$on("CLOSEREPOSITORYDIALOG", () => this.closeRepositoryDialog());

        this.$root.$on("OPENSOURCEDIALOG", type_or_source => this.openSourceDialog(type_or_source));
        this.$root.$on("CLOSESOURCEDIALOG", () => this.closeSourceDialog());

        this.$root.$on("OPENDESTINATIONDIALOG", type_or_destination => this.openDestinationDialog(type_or_destination));
        this.$root.$on("CLOSEDESTINATIONDIALOG", () => this.closeDestinationDialog());

        this.$root.$on("OPENRESTOREDIALOG", (type, job/*backupID, backupName*/) => this.openRestoreDialog(type, job /*backupID, backupName*/));
        this.$root.$on("CLOSERESTOREDIALOG", () => this.closeRestoreDialog());

        this.$root.$on("OPENBACKUPVIEW", idBackup => this.openBackupView(idBackup));
        this.$root.$on("CLOSEBACKUPVIEW", () => this.closeBackupView());

        this.$root.$on("OPENUSERVIEWDIALOG", (user, checkTFA) => this.openUserViewDialog(user, checkTFA));
        this.$root.$on("CLOSEUSERVIEWDIALOG", () => this.closeUserViewDialog());

        this.$root.$on("OPENDEVICEPOLICYVIEWDIALOG", (device, policiesList) => this.openDevicePoliciesViewDialog(device,policiesList));
        this.$root.$on("CLOSEDEVICEPOLICYVIEWDIALOG", () => this.closeDevicePoliciesViewDialog());

        this.$root.$on("OPENGROUPVIEWDIALOG", (group, computers, users) => this.openGroupViewDialog(group, computers, users));
        this.$root.$on("CLOSEGROUPVIEWDIALOG", () => this.closeGroupViewDialog());

        this.$root.$on("OPENCOMPUTERVIEWDIALOG", () => this.openComputerViewDialog());//TODO
        this.$root.$on("CLOSECOMPUTERVIEWDIALOG", () => this.closeComputerViewDialog());//TODO

        this.$root.$on("OPENPOLICYVIEWDIALOG", (policy, computers) => this.openPolicyViewDialog(policy, computers));
        this.$root.$on("CLOSEPOLICYVIEWDIALOG", () => this.closePolicyViewDialog());

        this.$root.$on("OPENSCHEDULATIONVIEWDIALOG", (policy) => this.openSchedulationViewDialog(policy));
        this.$root.$on("CLOSESCHEDULATIONVIEWDIALOG", (schedulationString) => this.closeSchedulationViewDialog(schedulationString));

        this.$root.$on("OPENCREATEFOLDERDIALOG", createFolderObj => this.openCreateFolderDialog(createFolderObj));
        this.$root.$on("CLOSECREATEFOLDERDIALOG", () => this.closeCreateFolderDialog());

        this.$root.$on("OPENRENAMEITEMDIALOG", (resource, parentPath) => this.openRenameItemDialog(resource, parentPath));
        this.$root.$on("CLOSERENAMEITEMDIALOG", () => this.closeRenameItemDialog());

        this.$root.$on("OPENDELETEITEMDIALOG", (resource) => this.openDeleteItemDialog(resource));
        this.$root.$on("CLOSEDELETEITEMDIALOG", () => this.closeDeleteItemDialog());

        this.$root.$on("OPENRENAMEJOBDIALOG", (backupID, backupName) => this.openRenameJobDialog(backupID, backupName));
        this.$root.$on("CLOSERENAMEJOBDIALOG", () => this.closeRenameJobDialog());

        this.$root.$on("OPENSERVICEACCOUNTVIEW", serviceAccount => this.openSARepoView(serviceAccount, null));
        this.$root.$on("CLOSESERVICEACCOUNTVIEW", () => this.closeSARepoView());
        this.$root.$on("OPENREPOSITORYVIEW", repository => this.openSARepoView(null, repository));
        this.$root.$on("CLOSEREPOSITORYVIEW", () => this.closeSARepoView());
        this.$root.$on("OPENSAREPOVIEW", (serviceAccount, repository) => this.openSARepoView(serviceAccount, repository));
        this.$root.$on("CLOSESAREPOVIEW", () => this.closeSARepoView());

        this.$root.$on("OPENBACKUPLOG", backupLog => this.openBackupLog(backupLog));
        this.$root.$on("CLOSEBACKUPLOG", () => this.closeBackupLog());
    },
    data() {
        return {
            users: [],
            computers: [],
            group: null,
            policy: null,
            openSched: false, //apre la maschera di scelta della stringa CRON
            cronSchedulation: "",
            backupTree: null,
            user: null,
            checkTFA: {
                step1: null,
                step2: null
            },

            serviceAccount: null,

            repository: null,

            source: null,
            destination: null,


            restore: null,

            idBackupForView: null,

            createFolderObj: null,

            renameItemDialog: null,

            renameJobDialog: null,

            deleteItemDialog: null,

            sarepo: null,

            device:null,
            policiesList:null,

            backupLog: null,

            nOpenDialogs: 0,

            wait: false


        }
    },
    methods: {

        async openServiceAccountDialog(type_or_serviceAccount) {
            this.nOpenDialogs+=1;

            if (typeof type_or_serviceAccount!="number") {
                this.serviceAccount=type_or_serviceAccount;

            }
            else if (!type_or_serviceAccount.isEmail()) {
                this.serviceAccount=new DefaultObjectBuilder().getServiceAccount(type_or_serviceAccount);

            } else {
                //Se il service account è di tipo email, per costruire l'oggetto ho bisogno di alcuni dati relativi al provider selezionato
                var emailProviders=await this.$api.getEmailProviders();
                var selectedEmailProvider=emailProviders.find(ep => ep.Id.toString()==type_or_serviceAccount.toString());

                var app=new DefaultObjectBuilder().getServiceAccount(type_or_serviceAccount, selectedEmailProvider);
                app.type=selectedEmailProvider.Id;
                app.type_name=selectedEmailProvider.Name;
                app.url=selectedEmailProvider.Url;
                //app.username="";
                //app.password="";
                app.use_ssl=selectedEmailProvider.UseSSL;
                app.port=selectedEmailProvider.Port;
                this.serviceAccount=app;
            }


        },

        closeServiceAccountDialog() {
            this.nOpenDialogs-=1; if (this.nOpenDialogs<0) this.nOpenDialogs=0;
            this.$root.$emit("closeFileSystemSidebar");

            this.serviceAccount=null;

            $(".sourceDialog").removeClass("background");
            $(".destinationDialog").removeClass("background");
            $(".repositoryDialog").removeClass("background");
            $(".restoreDialog").removeClass("background");

            /* this.$router.push(this.$route.path); */
            this.$router.replace({ path: this.$route.path }).catch((err) => {
                // Ignora l'errore NavigationDuplicated
                if (err.name!=='NavigationDuplicated') {
                    console.error(err);
                }
            });

        },

        openRepositoryDialog(type_or_repository) {
            this.nOpenDialogs+=1;

            if (typeof type_or_repository=="number") {
                this.repository=new DefaultObjectBuilder().getRepository(type_or_repository);
            } else {
                this.repository=type_or_repository;
            }

            $(".destinationDialog").addClass("background");
        },

        closeRepositoryDialog() {
            this.nOpenDialogs-=1; if (this.nOpenDialogs<0) this.nOpenDialogs=0;
            this.$root.$emit("closeFileSystemSidebar");

            this.repository=null;
            $(".destinationDialog").removeClass("background");
            this.$root.$emit("closeFileSystemSidebar");
            this.$router.replace({ path: this.$route.path }).catch((err) => {
                // Ignora l'errore NavigationDuplicated
                if (err.name!=='NavigationDuplicated') {
                    console.error(err);
                }
            });

        },

        openSourceDialog(type_or_source) {
            this.nOpenDialogs+=1;

            if (typeof type_or_source=="number") {
                this.source=new DefaultObjectBuilder().getSource(type_or_source);
            } else {
                this.source=type_or_source;
            }
        },
        closeSourceDialog() {
            this.nOpenDialogs-=1; if (this.nOpenDialogs<0) this.nOpenDialogs=0;
            this.$root.$emit("closeFileSystemSidebar");
            this.source=null;

        },

        openDestinationDialog(type_or_destination) {
            this.nOpenDialogs+=1;
            if (typeof type_or_destination=="number") {
                this.destination=new DefaultObjectBuilder().getDestination(type_or_destination);
            } else {
                this.destination=type_or_destination;
            }
        },
        closeDestinationDialog() {
            this.nOpenDialogs-=1; if (this.nOpenDialogs<0) this.nOpenDialogs=0;
            this.$root.$emit("closeFileSystemSidebar");

            this.destination=null;
        },

        openRestoreDialog(type, backup) {
            this.nOpenDialogs+=1;
            //Caso 1 : restore di file
            if (type.isRestoreFile()) {
                this.closeBackupView();
                this.restore=new DefaultObjectBuilder().getRestoreFile(type, backup);
                return;
            }

            //Caso 2 : restore automatico no file
            if (isValid(backup)) {
                this.selectedJobId=backup.id;
                if (type==this.$PlatformsTypes.Microsoft_Exchange365) {
                    this.restore=new DefaultObjectBuilder().getRestoreSource(type, backup);
                } else {
                    this.restore={
                        type: type,
                        idBackup: backup.id,
                    }
                }

                this.closeBackupView();
                return;
            }

            //Caso 3 : restore manuale no file
            this.restore=new DefaultObjectBuilder().getRestoreSource(type);

        },

        closeRestoreDialog() {
            this.nOpenDialogs-=1;
            if (this.nOpenDialogs<0) {
                this.nOpenDialogs=0;
            }
            this.$root.$emit("closeFileSystemSidebar");

            this.restore=null;
        },

        openBackupView(idBackup) {
            this.nOpenDialogs+=1;
            this.idBackupForView=idBackup;
        },

        closeBackupView() {

            if (this.idBackupForView==null)
                return;

            this.nOpenDialogs-=1; if (this.nOpenDialogs<0) this.nOpenDialogs=0;
            this.$root.$emit("closeFileSystemSidebar");

            this.idBackupForView=null;
        },

        openDevicePoliciesViewDialog(device,policiesList) {
            console.log("openDevicePoliciesViewDialog", device, policiesList);
            this.nOpenDialogs+=1;
            this.device=device;
            this.policiesList = policiesList;
            
        },

        closeDevicePoliciesViewDialog() {
            this.nOpenDialogs-=1;
            if (this.nOpenDialogs<0) this.nOpenDialogs=0;
            this.device=null;
        },

        openUserViewDialog(user, checkTFA) {
            this.nOpenDialogs+=1;


            this.computers=this.$session.getPCList();
            this.groups=this.$session.getGroups();
            if (isValid(user)) {
                this.user=user;
            } else {
                this.user=new DefaultObjectBuilder().getUser()
                this.user.id_master_account=this.$root.checkAuth().id_master_account;
            }

            if (isValid(checkTFA)) this.checkTFA=checkTFA;

        },

        closeUserViewDialog() {
            this.nOpenDialogs-=1;
            if (this.nOpenDialogs<0) this.nOpenDialogs=0;
            this.$root.$emit("closeFileSystemSidebar");

            this.user=null;
            this.checkTFA={
                step1: null,
                step2: null
            };
            this.$router.replace({ path: this.$route.path }).catch((err) => {
                // Ignora l'errore NavigationDuplicated
                if (err.name!=='NavigationDuplicated') {
                    console.error(err);
                }
            });
        },

        openGroupViewDialog(group, computers, users) {
            this.nOpenDialogs+=1;

            if (isValid(group)) {
                this.group=group;
            } else {
                this.group=new DefaultObjectBuilder().getGroup();
            }

            if (isValid(computers)) {
                this.computers=computers;

            } else {
                this.computers=this.$session.getPCList();
            }

            if (isValid(users)) {
                this.users=users;
            } else {
                this.users=this.$session.getUsers()
            }

        },
        closeGroupViewDialog() {
            this.nOpenDialogs-=1;
            if (this.nOpenDialogs<0) this.nOpenDialogs=0;
            this.$root.$emit("closeFileSystemSidebar");

            this.group=null;
            this.computers=null;
            this.users=null;

            this.$router.replace({ path: this.$route.path }).catch((err) => {
                // Ignora l'errore NavigationDuplicated
                if (err.name!=='NavigationDuplicated') {
                    console.error(err);
                }
            });
        },

        async openPolicyViewDialog(policy, computers) {
            this.nOpenDialogs+=1;

            if (isValid(policy)) {
                this.policy=policy;
            } else {
                this.policy=new DefaultObjectBuilder().getPolicy();
            }

            if (isValid(computers)) {
                this.computers=computers;
            } else {
                this.computers=this.$session.getPCList();
            }
        },


        closePolicyViewDialog() {
            /*  this.nOpenDialogs -= 1; 
             if (this.nOpenDialogs < 0) this.nOpenDialogs = 0; */
            this.nOpenDialogs>0? this.nOpenDialogs-=1:null;
            this.$root.$emit("closeFileSystemSidebar");

            this.policy=null;
            this.computers=null;

            this.$router.replace({ path: this.$route.path }).catch((err) => {
                // Ignora l'errore NavigationDuplicated
                if (err.name!=='NavigationDuplicated') {
                    console.error(err);
                }
            });
        },

        openSchedulationViewDialog() {
            this.nOpenDialogs+=1;

            this.openSched=true;
        },
        closeSchedulationViewDialog(schedulationString) {
            /*  this.nOpenDialogs -= 1; 
             if (this.nOpenDialogs < 0) this.nOpenDialogs = 0; */
            this.nOpenDialogs>0? this.nOpenDialogs-=1:null;
            if (this.policy.id_type==2) {
                this.policy.policy.update_time=schedulationString;
            }
            if (this.policy.id_type==1) {
                this.policy.policy.scheduling[0]=schedulationString;
                this.policy.refreshID+=1;
            }
            this.openSched=false;
        },

        openComputerViewDialog() {
            this.nOpenDialogs+=1;
            alert("DA IMPLEMENTARE");
        },

        closeComputerViewDialog() {
            this.nOpenDialogs-=1;
            if (this.nOpenDialogs<0) {
                this.nOpenDialogs=0;
            }
            this.$root.$emit("closeFileSystemSidebar");
            alert("DA IMPLEMENTARE");

            this.$router.replace({ path: this.$route.path }).catch((err) => {
                // Ignora l'errore NavigationDuplicated
                if (err.name!=='NavigationDuplicated') {
                    console.error(err);
                }
            });
        },

        openCreateFolderDialog(createFolderObj) {
            this.nOpenDialogs+=1;
            this.createFolderObj=createFolderObj;
        },

        
        closeCreateFolderDialog() {
            this.nOpenDialogs-=1;
            if (this.nOpenDialogs<0) {
                this.nOpenDialogs=0;
            }
            this.createFolderObj=null;

        },

        openRenameItemDialog(resource, parentPath) {
            this.nOpenDialogs+=1;
            this.renameItemDialog={
                resource: resource,
                parentPath: parentPath,
                name: resource.path.lastPartOfPath()
            };
        },

        async renameItem() {
            var oldPath=this.renameItemDialog.resource.path;
            var newPath=this.renameItemDialog.parentPath+"\\"+this.renameItemDialog.name;
            var renamed=await this.$root.socket("RenameItem", oldPath, newPath);

            this.$root.$emit("renameitemfeedback", renamed);
            if (renamed)
                this.closeRenameItemDialog();
        },
        closeRenameItemDialog() {
            this.nOpenDialogs-=1;
            if (this.nOpenDialogs<0) this.nOpenDialogs=0;
            this.renameItemDialog=null;
        },

        openDeleteItemDialog(resource) {
            this.nOpenDialogs+=1;
            this.deleteItemDialog={
                resource: resource
            };
        },
        async deleteItem() {
            var deleted=await this.$root.socket("DeleteItem", this.deleteItemDialog.resource.path);
            this.$root.$emit("deleteitemfeedback", deleted);
            if (deleted)
                this.closeDeleteItemDialog();
        },
        closeDeleteItemDialog() {
            this.nOpenDialogs-=1;
            if (this.nOpenDialogs<0) {
                this.nOpenDialogs=0;
            }
            this.deleteItemDialog=null;
        },

        openRenameJobDialog(backupID, backupName) {
            this.nOpenDialogs+=1;
            this.renameJobDialog={
                id: backupID,
                oldName: backupName,
                newName: ""
            };

        },

        async renameJob() {
            var renamed=await this.$api.renameBackup(this.renameJobDialog.id, this.renameJobDialog.oldName, this.renameJobDialog.newName);
            if (!renamed) return;

            renamed=await this.$root.socket("ChangeJobName", this.renameJobDialog.id, this.renameJobDialog.newName);

            if (renamed) this.closeRenameJobDialog();

        },
        closeRenameJobDialog() {
            this.nOpenDialogs-=1;
            if (this.nOpenDialogs<0) {
                this.nOpenDialogs=0;
            }
            this.renameJobDialog=null;
        },

        openSARepoView(serviceAccount, repository) {
            this.sarepo={
                repository: repository,
                serviceAccount: serviceAccount
            }
        },

        closeSARepoView() {

            this.sarepo=null;
            //this.$router.removeHash(); // prototype implementato in main.js
        },

        openBackupLog(backupLog) {
            this.nOpenDialogs++;
            this.backupLog=backupLog;
        },

        closeBackupLog() {
            this.nOpenDialogs--;
            this.backupLog=null;
        },

        /**********************************UTILS**********************************************************/
        isValid(value) {
            return isValid(value);
        },

    }

}
</script>