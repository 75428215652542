import { isValid } from "../../utilitiesmodule";
import { FileFolderEntityType, PlatformsTypes } from "../../objects";
import api from "../../../router/api";
import { ClientTreeNode } from "../../../models/TreeNode";

export class GraphClient {
    constructor(serviceAccount) {
        this.serviceAccount=serviceAccount;
        this.accessToken=serviceAccount.token;
        this.nodes=[];
    }

    async getUserByEmail(email) {
        var url="https://graph.microsoft.com/v1.0/users?$filter={0}".format(encodeURIComponent("mail eq '"+email+"'"));
        var user=await _Get(url, this.accessToken);
        if (user==null)
            return null;
        user=user[0];
        return new ClientTreeNode(null, user.id, user.mail, FileFolderEntityType.CloudAccount);
    }

    async getUsers(type) {
        var url="https://graph.microsoft.com/v1.0/users?$select=id,displayName,mail,assignedPlans"
        var users=await _Get(url, this.accessToken);
        if (users==null)
            return null;
        var drive;
        var size=users.length;
        for (var i=0; i<size; i++) {
            if (users[i].mail==null) {
                users.splice(i, 1);
                size--;
                i--;
                continue;
            }

            //Se ho richiesto gli utenti per onedrive o teams, ho bisogno del loro drive
            switch (type) {
                case PlatformsTypes.Cloud_OneDrive:
                case PlatformsTypes.Microsoft_OneDriveForBusiness:
                case PlatformsTypes.Microsoft_Teams:
                    drive=await _GetDrive(users[i].id, this.accessToken);
                    if (drive==null) {
                        users.splice(i, 1);
                        size--;
                        i--;
                        continue;
                    }
            }


            users[i]=new ClientTreeNode(null, users[i].id, users[i].mail, FileFolderEntityType.CloudAccount)
        }

        return users;
    }



    async getDriveByUserID(userID) {
        var url="https://graph.microsoft.com/v1.0/users/"+userID+"/drive";
        var drive=await _Get(url, this.accessToken);
        if (drive!=null&&drive.driveType=="business")
            return drive;
        return null;
    }

    async getOneDriveRootNodes() {
        var children=this.nodes.length==0? []:
            this.nodes.filter(n => n.isChildOf(null));

        if (children.length!=0)
            return children;

        var url="https://graph.microsoft.com/v1.0/users/"+this.serviceAccount.options.userID+"/drives/"+this.serviceAccount.options.driveID+"/root/children";
        //var url = "https://graph.microsoft.com/v1.0/users/" + this.userID + "/drive/root/children";
        this.nodes=await createRequest(url, this.accessToken);
        return this.nodes;
    }

    async getOneDriveNodesFrom(resource) {
        var children=this.nodes.length==0? []:
            this.nodes.filter(n => n.isChildOf(resource, "/"));

        if (children.length!=0)
            return children;

        if (resource.resID==null)
            resource.resID=await _getIDFromPath(resource.path, this.accessToken);
        var url="https://graph.microsoft.com/v1.0/users/"+this.serviceAccount.options.userID+"/drives/"+this.serviceAccount.options.driveID+"/items/"+resource.resID+"/children";

        children=await createRequest(url, this.accessToken);

        if (children==null) return null;

        children=children.map(r => {
            r.path=resource.path+"/"+r.path;
            return r;
        });

        this.nodes.push(...children);
        return children;
    }

    async createFolder(path, parentID) {
        if (!isValid(parentID)) {
            parentID="root";
        }

        var headers={
            "Authorization": `Bearer ${this.accessToken}`,
            "Content-Type": "application/json",
        };
        var body={
            name: path.lastPartOfPath(),
            folder: {},
            "@microsoft.graph.conflictBehavior": "rename",
        };

        var url="https://graph.microsoft.com/v1.0/users/"+this.serviceAccount.options.userID+"/drives/"+this.serviceAccount.options.driveID+"/items/"+parentID+"/children"
        var [ok, response]=await api.sendRequestToServer("POST", url, headers, body);

        if (!ok) {
            return null;
        }

        var id=response.id;

        return new ClientTreeNode(null, id, path, FileFolderEntityType.Cloud_Folder);
    }

}


async function _GetDrive(userID, accessToken) {
    var url="https://graph.microsoft.com/v1.0/users/"+userID+"/drive";
    var drive=await _Get(url, accessToken);
    if (drive!=null&&drive.name=="OneDrive"&&drive.driveType=="business")
        return drive;
    return null;
}
async function _Get(url, accessToken) {
    try {
        var headers={
            "Authorization": "Bearer "+accessToken,
            "Content-Type": "application/json"
        };
        var [ok, response]=await api.sendRequestToServer("GET", url, headers, null);

        if (!ok) {
            return null;
        }

        if (response.value!=undefined) {
            return response.value;
        }

        return response;
    } catch (ex) {
        console.error(ex);
        return null;
    }
}


async function createRequest(url, accessToken) {
    var headers={
        "Authorization": "Bearer "+accessToken,
        //"Accept-Encoding": "gzip, deflate"
    };
    var [ok, response]=await api.sendRequestToServer("GET", url, headers);
    if (!ok) {
        return null;
    }

    var resources=response.value.map(r =>
        new ClientTreeNode(
            null,
            r.id,
            r.name,
            r.folder==undefined?
                FileFolderEntityType.Cloud_File:
                FileFolderEntityType.Cloud_Folder
        )
    );

    return resources;
}

async function _getIDFromPath(path, accessToken) {
    var folders=path.getAllPaths(PlatformsTypes.Cloud_OneDrive);
    var foldersNames=path.GetFoldersNames(PlatformsTypes.Cloud_OneDrive);
    var parentID="root";
    var children=[];
    var url;
    for (var i=0; i<foldersNames.length; i++) {
        url="https://graph.microsoft.com/v1.0/me/drive/items/"+parentID+"/children";

        children=await createRequest(url, accessToken);
        if (children==null)
            return null;
        children.map(c => {
            c.path=(parentID=="root"? "":folders[i-1]+"/")+c.path;
            return c;
        });
        parentID=children.find(r => r.path==folders[i]).resID;
    }

    return parentID;
}