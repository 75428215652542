export class ClientTreeNode {

    constructor(nodeID, resID, path, type) {
        this.nodeID = nodeID;
        this.resID = resID;
        this.path = path;
        this.type = type; // FileFolderEntityType: ...\iperius-enterprise-agent\IperiusEWP\BackupSources\FileFolder\Core\Utils\Enums.cs

        var slash = path.getSeparator();

        this.nParts = path.endsWith(":/") ? 1 : path.split(slash).length;
        this.children = type.isFile() ? null : [];
        this.visible = true;

        return this;
    }
}


export class FileSystemSidebarConfig {
    constructor(type, includeFiles, multipleChoice, client, rootPath, oneLevel, tree) {
        this.type = type;
        this.includeFiles = includeFiles;
        this.multipleChoice = multipleChoice;
        this.client = client;
        this.rootPath = rootPath;
        this.oneLevel = oneLevel;
        this.tree = tree;
    }

    toString() {
        return JSON.stringify(this)
    }
}