import { isValid } from '../utilitiesmodule';
import VueRouter from 'vue-router';
import { FileFolderEntityType, platformsTypesInfo, PlatformsTypes, ResultValue } from '../objects';
/*****************************STRINGS*****************************************************************************************************/
//Trasforma la stringa nel seguente modo:
// - la mette tutta in lowercase
// - mette in uppercase la prima lettera e le parole che devono essere in maiuscole, definite nell'array uppercaseStrings
String.prototype.titolize=function() {
    var arr=this.split(" ")

    for (var i=0; i<arr.length; i++) {
        if (uppercaseStrings.includes(arr[i].toUpperCase()))
            arr[i]=arr[i].toUpperCase();
        else if (i==0)
            arr[0]=arr[0].charAt(0).toUpperCase()+arr[0].substring(1).toLowerCase();
        else
            arr[i]=arr[i].toLowerCase();
    }
    return arr.join(" ");
}

var uppercaseStrings=["CPU", "SSL", "POP", "PC", "ESXI", "FTP", "OS", "TFA", "URL", "2FA", "ID", "RAM", "RCT", "CBT", "SSPI", "POP3", "I/O", "MB", "KB", "GB", "TB", "B"];

String.prototype.contains=function(str) {
    return this.indexOf(str)!=-1;
}

String.prototype.getSeparator=function() {
    if (this.includes("/")) return "/";
    if (this.includes("\\")) return "\\";
    return "/";
}

String.prototype.lastPartOfPath=function() {
    var separator=this.getSeparator();

    if (this.contains(separator))
        return this.split(separator).at(-1);

    return this;
}

String.prototype.trimEnd=function(endChars) {
    var str=this;
    while (str.endsWith(endChars))
        str=str.slice(0, -(endChars.length));
    return str;
}

String.prototype.trimStart=function(startChars) {
    var str=this;
    while (str.startsWith(startChars))
        str=this.slice(startChars.length);
    return str;
}

String.prototype.getParent=function() {
    var slash=this.getSeparator();
    var str=this.trimStart(slash).trimEnd(slash);
    if (str.indexOf(slash)==-1)
        return "";
    console.log(str.slice(0, str.lastIndexOf(slash)));
    return str.slice(0, str.lastIndexOf(slash));
}

String.prototype.GetFoldersNames=function(platformType=null) {
    var slash=platformType==null?
        this.getSeparator():
        platformType.getSeparator();

    var newPath=this.trimEnd(slash);

    if (!newPath.includes(slash))
        return [newPath];

    return newPath.split(slash);
}

String.prototype.getAllPaths=function(platformType) {
    var folders=this.GetFoldersNames(platformType);
    if (folders.length==1) {
        return folders;
    }

    var paths=[];
    var slash=platformType.getSeparator();

    folders.forEach(f => {
        if (paths.length==0) {
            paths.push(f);
        } else {
            paths.push(paths.at(-1)+slash+f);
        }
    })

    return paths;
}

String.prototype.format=function(...params) {
    var str=this;
    for (var i=0; i<params.length; i++) {
        str=str.replaceAll("{"+i+"}", params[i]);
    }
    return str;
}



String.prototype.applyBucketsRules = function() {
    var pathArray = this.split("/");
    var nParts = pathArray.length;
    var mainFolder = pathArray[0];
    var subPath = nParts==1 ? "" : this.split(mainFolder+"/")[1];

    mainFolder = mainFolder.toLowerCase();
    var ascii;
    for (var i = 0; i < mainFolder.length; i++) {
        ascii = mainFolder.charCodeAt(i);
        if (47 < ascii && ascii < 58) continue; // (0-9)
        if (96 < ascii && ascii < 123) continue; // (a-z)
        if (ascii == 46 || ascii == 45) continue; // 46  -> punto , 45 -> trattino
        mainFolder = mainFolder.replace(mainFolder.at(i), "-");
    }

    var path = mainFolder;
    if(subPath != ""){
        path+="/" + subPath
    }

    return [
        path,
        mainFolder,
        subPath,
        nParts
    ];
}

String.prototype.validatePath = function(type, slash){
    var value = this;
    //Tolgo gli spazi
    value = value.trim();
    //tolgo gli slash all'inizio e alla fine
    value = value.trimStart('/').trimStart('\\').trimEnd('/').trimEnd('\\');
    //tolgo i caratteri non ammessi
    value = value.replace(/[<>:|?*"']/g, '');
    //sostituisco gli slash sbagliati
    if (slash == "/" && value.indexOf('\\') != -1) {
        value = value.replace("\\", "/")
    } else if (slash == "\\" && value.indexOf('/') != -1) {
        value = value.replace("/", "\\");
    }

    if(slash== "\\" && type == PlatformsTypes.FileFolder){
        value = value.substring(0,1)+ ":"+value.substring(1);
    }

    return value;
}

/***********************************DATE********************************************************************/
Date.prototype.ddMMyyyyHHmmss = function(){
    var day =  this.getDate();
    var month = (this.getMonth() + 1);
    var year = this.getFullYear();
    var hours = this.getHours();
    var minutes = this.getMinutes();
    var seconds = this.getSeconds();
    return [day, month, year, hours, minutes, seconds].join("");
}

/*****************************ARRAY*****************************************************************************************************/
Array.prototype.removeIf=function(condition) {
    var i=this.length;
    while (i--) {
        if (condition(this[i], i)) {
            this.splice(i, 1);
        }
    }
}

Array.prototype.update=function(newArray) {
    if (this.length!=0) {
        this.clear();
    }

    if (newArray.length!=0) {
        this.push(...newArray);
    }

}

Array.prototype.clear=function() {
    if (this.length!=0) {
        this.splice(0, this.length);
    }
}

//https://stackoverflow.com/questions/15125920/how-to-get-distinct-values-from-an-array-of-objects-in-javascript
Array.prototype.distinctBy=function(propertyName) {
    return [...new Map(this.map(item => [propertyName==null? item:item[propertyName], item])).values()]
}

Array.prototype.Equal=function(b) {
    if (this===b) return true;
    if (this==null||b==null) return false;
    if (this.length!==b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (var i=0; i<this.length; ++i) {
        if (this[i]!==b[i]) return false;
    }
    return true;
}



/*****************************INTEGER - PLATFORMSTYPES*****************************************************************************************************/

Number.prototype.isCloud=function() {
    return platformsTypesInfo[this].mainType==1;
}

Number.prototype.isCloudS3=function() {
    return [
        PlatformsTypes.Cloud_AmazonS3Storage,
        PlatformsTypes.Cloud_IperiusS3Storage,
        PlatformsTypes.Cloud_S3CompatibleStorage
    ].includes(this);
}


Number.prototype.isMicrosoft=function() {
    return platformsTypesInfo[this].mainType==PlatformsTypes.Microsoft;
}

Number.prototype.isMicrosoft365=function() {
    return this.isMicrosoft()&&!this.isExchangeOnPremises();
}
Number.prototype.isExchangeOnPremises=function() {
    return [
        PlatformsTypes.Microsoft_ExchangeOnPremises,
        PlatformsTypes.Microsoft_ExchangeOnPremisesEWS,
    ].includes(this);
}

Number.prototype.isExchange=function() {
    return [
        PlatformsTypes.Microsoft_ExchangeOnPremises,
        PlatformsTypes.Microsoft_ExchangeOnPremisesEWS,
        PlatformsTypes.Microsoft_Exchange365
    ].includes(this);
}

Number.prototype.isEmail=function() {
    return platformsTypesInfo[this].mainType==PlatformsTypes.Email;
}
Number.prototype.isDatabase=function() {
    return [PlatformsTypes.Database_SQLServer, PlatformsTypes.Database_MySQL].includes(this);
}
Number.prototype.isSimpleFileNFolderType=function() {

    switch (this) {
        case PlatformsTypes.FileFolder:
        case PlatformsTypes.Network:
        case PlatformsTypes.FTP:

        case PlatformsTypes.Cloud_GoogleDrive:
        case PlatformsTypes.Cloud_S3CompatibleStorage:
        case PlatformsTypes.Cloud_IperiusS3Storage:
        case PlatformsTypes.Cloud_AmazonS3Storage:
        case PlatformsTypes.Cloud_Dropbox:
        case PlatformsTypes.Cloud_OneDrive:
        case PlatformsTypes.Cloud_AzureBlob:
        case PlatformsTypes.Cloud_AzureFile:

        case PlatformsTypes.Microsoft_SharePoint:
        case PlatformsTypes.Microsoft_Teams:
        case PlatformsTypes.Microsoft_OneDriveForBusiness:
            return true;
    }
    return false;
}

//this deve essere la destinazione del restore/sorgente di backup
Number.prototype.isRestoreFile=function() {
    switch (this) {
        case PlatformsTypes.FileFolder:
        case PlatformsTypes.Network:
        case PlatformsTypes.Microsoft_SharePoint:
        case PlatformsTypes.Microsoft_Teams:
        case PlatformsTypes.Microsoft_OneDriveForBusiness:
            return true;
    }
    return false;
}

Number.prototype.getSeparator=function() {
    switch (this) {
        case PlatformsTypes.FileFolder:
        case PlatformsTypes.Network:
            return "\\"
        default:
            return "/"
    }
}

/**********************************INTEGER - FILEFOLDERENTITYTYPE**********************************************************************************/
Number.prototype.getMetroIcon=function(path) {
    if (isValid(path)) {
        var slash=path.getSeparator();

        //Caso 1 : questo nodo corrisponde ad un drive di filefolder (C:\, L:\) ecc...
        if (slash==="\\"&&path.length===3)
            return "mif-drive2";
    }
    switch (this) {
        case FileFolderEntityType.FN_File:
            return "mif-file-empty";
        case FileFolderEntityType.Cloud_File: //CLOUD_FILE
            return "mif-file-empty";
        case FileFolderEntityType.SP_File: //SP_FILE
            return "mif-file-empty";
        case FileFolderEntityType.FTP_File: //FTP_FILE
            return "mif-file-empty";
        case FileFolderEntityType.FN_Folder: //FN_FOLDER
            return "mif-folder";
        case FileFolderEntityType.Cloud_Folder: //CLOUD_FOLDER
            return "mif-folder";
        case FileFolderEntityType.SP_Folder: //SP_FOLDER
            return "mif-folder";
        case FileFolderEntityType.FTP_Folder: //FTP_FOLDER
            return "mif-folder";
        case FileFolderEntityType.Cloud_Root: //cloud root
            return "mif-cloud";
        case FileFolderEntityType.SP_Site_All: // sp_root
            return "mif-cloud";
        case FileFolderEntityType.Cloud_Bucket: //cloud bucket
            return "mif-drive2";
        case FileFolderEntityType.SP_Site: //sp_site
            return "mif-earth";
        case FileFolderEntityType.SP_Library: //sp_library
            return "mif-books";
        case FileFolderEntityType.MT_Team_AllTeams:
            return "mif-groups";
        case FileFolderEntityType.MT_Team:
            return "mif-users";
        case FileFolderEntityType.MT_Channel:
            return "mif-chat-bubble-outline";
        case FileFolderEntityType.CloudAccount:
            return "mif-user";
        case FileFolderEntityType.CloudAccount_All:
            return "mif-groups";
        case FileFolderEntityType.Mailbox_User:
        case FileFolderEntityType.Mailbox_Impersonation:
            return "mif-user";
        case FileFolderEntityType.Mailbox_Shared:
            return "mif-share-circle";
        case FileFolderEntityType.Mailbox_Group:
            return "mif-users";

        case FileFolderEntityType.MT_Team_All:
            return "mif-earth";

        case FileFolderEntityType.Database:
            return "mif-database";
        case FileFolderEntityType.VirtualMachine:
            return "mif-computer";

        case FileFolderEntityType.Disk:
            return "mif-drive";
        case FileFolderEntityType.DiskPartition:
            return "mif-database";
        case FileFolderEntityType.Mailbox_All:
            return "mif-groups";
        default:
            return 'mif-question';
    }
}

Number.prototype.isFile=function() {

    switch (this) {
        case FileFolderEntityType.FN_File:
            return true;
        case FileFolderEntityType.Cloud_File:
            return true;
        case FileFolderEntityType.SP_File:
            return true;
        case FileFolderEntityType.FTP_File:
            return true;
        default:
            return false;

    }
}

/****************************************************NUMBER - RESULTVALUE****************************/
Number.prototype.getResultValueInfo=function() {
    var info={
        color: "fg-gray",
        label: ""
    };
    switch (this) {
        case ResultValue.OK:
            info.color="fg-green";
            info.label="Ok";
            break;
        case ResultValue.SomeWarnings:
            info.color="fg-orange";
            info.label="Some Warnings";
            break;
        case ResultValue.TerminatedAbnormally:
            info.color="fg-red";
            info.label="Abnormally terminated"
            break;
        case ResultValue.Aborted:
            info.color="fg-red";
            info.label="Aborted by user";
            break;
        case ResultValue.Error:
            info.color="fg-red";
            info.label="Error";
            break;
        case ResultValue.Running:
            info.color="fg-black";
            info.label="Running";
            break;
        case ResultValue.NeverExecuted:
            info.color="fg-gray";
            info.label="Never executed";
            break;
    }

    return info;
}


/****************************************************MISCELLANEOUS ***************************************************************/
VueRouter.prototype.removeHash=function() {
    var currentURL=this.history.current.path;
    var originalURL=currentURL.split("#")[0];
    this.replace({ path: originalURL }).catch((err) => {
        // Ignora l'errore NavigationDuplicated
        if (err.name!=='NavigationDuplicated') {
            console.error(err);
        }
    });;
}