import Navigation from "../components/utils/Navigation";
import NoDataAvailable from '../components/utils/NoDataAvailable';
import PCLink from '../components/utils/PCLink';
import SARepoView from '../components/dialogs/view/SARepoView';

export default {
    name: "Accounts",
    data() {
        return {
            serviceAccounts: null,
            selectedType: -1,
            selectedIndex: -1,
            accselected: null,
            accountselected: null,
            computers: [],

            microsoftTypes: [],
            emailTypes: [],
        };
    },
    props: {
        msg: String,
    },
    components: {

        NoDataAvailable,
        Navigation,
        SARepoView,
        PCLink
    },
    watch: {
        '$route': {
            handler: function(to) {
                if (to.hash == "")
                    return;

                //Si vuole cancellare
                if (to.hash.startsWith("#delete:")) {
                    this.$root.deleteServiceAccount(to.hash.replace("#delete:", ""));
                    return;
                }

                if (to.hash.startsWith("#details:")) {
                    this.$root.$emit("OPENSAREPOVIEW", this.$session.getServiceAccountWithID(to.hash.replace("#details:", "")));
                    return;
                }

                if (to.hash.startsWith("#duplicate:")) {
                    this.$root.duplicateServiceAccount(to.hash.replace("#duplicate:", ""));
                    return;
                }

                if (to.hash != "") {
                    var idServiceAccount = to.hash.replace("#", "");
                    this.$root.$emit('OPENSERVICEACCOUNTDIALOG', this.$session.getServiceAccountWithID(idServiceAccount));
                }
            },
            deep: true,
            immediate: true
        }
    },
    beforeDestroy: function() {
        this.$root.$off("socketEventConnected");
        this.$root.$off("socketEventDisconnected");
        this.$root.$off("REFRESHSERVICEACCOUNTS");
        this.serviceAccounts = null; // Pulire i dati memorizzati
        this.computers = null;
        //alert("destroy accounts.JS");
        //this.$root.checkConnectionTimerAction("stop");
        //this.$root.newPage("accounts", "destroy");
    },
    created: function() {
        //alert("CREATED COMPUTERS.JS");
        //this.$root.newPage("accounts", "created");
        this.$session.setRoomPC(null);
        this.$root.socket("SETPC", null);
        this.serviceAccounts = this.$session.getServiceAccounts();
        let self = this;
        this.$root.$on("REFRESHSERVICEACCOUNTS", () => {
            self.serviceAccounts.update(self.$session.getServiceAccounts());
            location.reload();
        });

        this.microsoftTypes = this.$root.getMicrosoftTypes();
        this.emailTypes = this.$root.getEmailTypes();
        this.$root.$on("socketEventConnected", () => {
            self.$root.socketConnected = true;
            self.$root.checkConnectionTimerLight();
        });
        //"socketEventDisconnected" viene emesso se la connessione socket non è attiva
        this.$root.$on("socketEventDisconnected", () => {
            self.$root.socketConnected = false;
        });

        this.$root.checkSocket();

    },
    mounted: function() {
        //this.$root.newPage("accounts", "mounted");
        this.$session.setRoomPC(null);
        this.computers = this.$session.getPCList();
        var remoteMenu = Metro.getPlugin('#remotemenu', 'dropdown');
        if (remoteMenu)
            remoteMenu.open();

    },

    methods: {
        /***************************************PC********************************/
        checkClick() {
            console.log("checkbox clicked....")
        },

        see(sa) {
            this.accountselected = sa;
            window.Metro.charms.toggle("#accountselected");
        },



        closeCharm() {
            window.Metro.charms.toggle("#reposelected");
        }
    },
}