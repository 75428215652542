var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group p-2" }, [
    !_vm.selectRepo
      ? _c("div", { staticClass: "d-flex flex-row flex-align-baseline p-2" }, [
          _c("span", { staticClass: "mif-key mr-1" }),
          _c("span", [
            _vm._v(
              _vm._s(_vm.$t("Select a service account and connect")) + ":"
            ),
          ]),
        ])
      : _c("div", { staticClass: "d-flex flex-row flex-align-baseline p-2" }, [
          _c("span", { staticClass: "mif-folder-open mr-1" }),
          _c("span", [
            _vm._v(" " + _vm._s(_vm.$t("Select a repository")) + ":"),
          ]),
        ]),
    !_vm.selectRepo
      ? _c("div", { staticClass: "row m-0" }, [
          _c(
            "select",
            {
              staticClass: "w-50",
              attrs: {
                "data-role": "select",
                "data-filter": "false",
                model: _vm.idSA,
                disabled: _vm.serviceAccounts.length == 0,
              },
              on: { change: _vm.onChange },
            },
            [
              _c("option", { domProps: { value: -1 } }),
              _vm._l(_vm.serviceAccounts, function (sa, index) {
                return _c(
                  "option",
                  {
                    key: sa.id,
                    staticClass: "p-0",
                    domProps: { value: index, selected: _vm.index_SA == index },
                  },
                  [_vm._v(" " + _vm._s(sa.name) + " ")]
                )
              }),
            ],
            2
          ),
          _vm.wait
            ? _c("div", { staticClass: "p-2" }, [
                _c("span", {
                  staticClass: "mif-spinner2 fg-primary ani-spin ml-2",
                }),
              ])
            : _c(
                "a",
                {
                  staticClass: "button primary ml-2",
                  attrs: {
                    disabled:
                      _vm.index_SA == -1 || _vm.testState == _vm.SA_TESTED,
                    title: _vm.$t("Connect"),
                  },
                  on: { click: _vm.test },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "24",
                        height: "24",
                        fill: "none",
                        viewBox: "0 0 24 24",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M21.707 3.707a1 1 0 0 0-1.414-1.414L18.496 4.09a4.252 4.252 0 0 0-5.251.604l-1.068 1.069a1.75 1.75 0 0 0 0 2.474l3.585 3.586a1.75 1.75 0 0 0 2.475 0l1.068-1.068a4.252 4.252 0 0 0 .605-5.25l1.797-1.798ZM10.707 11.707a1 1 0 0 0-1.414-1.414l-1.47 1.47-.293-.293a.75.75 0 0 0-1.06 0l-1.775 1.775a4.252 4.252 0 0 0-.605 5.25l-1.797 1.798a1 1 0 1 0 1.414 1.414l1.798-1.797a4.252 4.252 0 0 0 5.25-.605l1.775-1.775a.75.75 0 0 0 0-1.06l-.293-.293 1.47-1.47a1 1 0 0 0-1.414-1.414l-1.47 1.47-1.586-1.586 1.47-1.47Z",
                          fill: "#fff",
                        },
                      }),
                    ]
                  ),
                ]
              ),
          _c("a", { staticClass: "button ml-2" }, [
            _c("span", {
              staticClass: "mif-plus mif-2x m-2",
              attrs: { title: _vm.$t("Create new service account") },
              on: {
                click: function ($event) {
                  return _vm.$root.$emit(
                    "OPENSERVICEACCOUNTDIALOG",
                    _vm.types[0]
                  )
                },
              },
            }),
            _vm.index_SA != -1
              ? _c("span", {
                  staticClass: "mif-pencil mif-2x m-2",
                  attrs: { title: _vm.$t("Edit service account") },
                  on: {
                    click: function ($event) {
                      return _vm.$root.$emit(
                        "OPENSERVICEACCOUNTDIALOG",
                        _vm.serviceAccounts[_vm.index_SA]
                      )
                    },
                  },
                })
              : _vm._e(),
            _c("span", {
              staticClass: "mif-loop2 mif-2x m-2",
              on: { click: _vm.reloadSAREPO },
            }),
          ]),
        ])
      : _vm.selectRepo
      ? _c("div", { staticClass: "row m-0" }, [
          _c(
            "select",
            {
              staticClass: "w-50",
              attrs: {
                "data-role": "select",
                "data-filter": "false",
                model: _vm.index_Repo,
                disabled: _vm.repositories.length == 0,
              },
              on: { change: _vm.onChange },
            },
            _vm._l(_vm.repositories, function (repo, index) {
              return _c(
                "option",
                {
                  key: repo.id,
                  staticClass: "p-0",
                  domProps: { value: index, selected: _vm.index_Repo == index },
                },
                [
                  _vm._v(" " + _vm._s(repo.name) + " "),
                  _c("span", { staticClass: "p-0 mif-info float-right" }),
                ]
              )
            }),
            0
          ),
          _vm.wait
            ? _c("div", { staticClass: "p-2" }, [
                _c("span", {
                  staticClass: "mif-spinner2 fg-primary ani-spin ml-2",
                }),
              ])
            : _c(
                "a",
                {
                  staticClass: "button primary ml-2",
                  attrs: {
                    title: _vm.$t(
                      "Test the service account associated with the repository"
                    ),
                    disabled:
                      _vm.index_SA == -1 || _vm.testState == _vm.SA_TESTED,
                  },
                  on: { click: _vm.test },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "24",
                        height: "24",
                        fill: "none",
                        viewBox: "0 0 24 24",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M21.707 3.707a1 1 0 0 0-1.414-1.414L18.496 4.09a4.252 4.252 0 0 0-5.251.604l-1.068 1.069a1.75 1.75 0 0 0 0 2.474l3.585 3.586a1.75 1.75 0 0 0 2.475 0l1.068-1.068a4.252 4.252 0 0 0 .605-5.25l1.797-1.798ZM10.707 11.707a1 1 0 0 0-1.414-1.414l-1.47 1.47-.293-.293a.75.75 0 0 0-1.06 0l-1.775 1.775a4.252 4.252 0 0 0-.605 5.25l-1.797 1.798a1 1 0 1 0 1.414 1.414l1.798-1.797a4.252 4.252 0 0 0 5.25-.605l1.775-1.775a.75.75 0 0 0 0-1.06l-.293-.293 1.47-1.47a1 1 0 0 0-1.414-1.414l-1.47 1.47-1.586-1.586 1.47-1.47Z",
                          fill: "#fff",
                        },
                      }),
                    ]
                  ),
                ]
              ),
          _c("a", { staticClass: "button ml-2" }, [
            _c("span", {
              staticClass: "mif-plus mif-2x m-2",
              attrs: { title: _vm.$t("Create new repository") },
              on: {
                click: function ($event) {
                  return _vm.$root.$emit("OPENREPOSITORYDIALOG", _vm.type)
                },
              },
            }),
            _vm.index_Repo != -1
              ? _c("span", {
                  staticClass: "mif-pencil mif-2x m-2",
                  attrs: { title: _vm.$t("Edit service account") },
                  on: {
                    click: function ($event) {
                      return _vm.$root.$emit(
                        "OPENREPOSITORYDIALOG",
                        _vm.repositories[_vm.index_Repo]
                      )
                    },
                  },
                })
              : _vm._e(),
            _c("span", {
              staticClass: "mif-loop2 mif-2x m-2",
              on: { click: _vm.reloadSAREPO },
            }),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }