<template>
    <div v-on:keyup.esc="$root.$emit('CLOSERESTOREDIALOG')" 
    class="dialog overflow w-100-sm w-100-md w-75-lg  w-50-xl" v-cloak>

        <DialogTitle :title="$t('Configure Exchange Restore')" :defaultTitle="$t('Configure Exchange restore')"
            :subTitle="$t('Exchange Backups')" @closeDialog="$root.$emit('CLOSERESTOREDIALOG')" />

        <div class="dialog-content p-4">
            <!-- SELEZIONE DEI BACKUP E DEL SINGOLO RESTORE RELATO AL JOB-->
            <div class="row" v-show="steps[0]">

                <div v-if="wait" data-role="activity" data-type="metro" data-style="dark"></div>
                <div id="esxilogslist" class="pb-4 w-100" style="overflow: scroll;">

                    <table data-role="sorter" data-sort-dir="desc" class="table striped text-small " v-if="!wait"
                        data-show-search="true" data-show-rows-steps="false" data-search-min-length="3"
                        data-search-threshold="300">

                        <thead>
                            <tr>
                                <td style="text-align: center;">Sel</td>
                                <td data-name="dt_start_utc" data-sortable="true" data-format="int">{{ $t('Start Date') }}
                                </td>
                                <td data-name="dt_end_utc" data-sortable="true">{{ $t('End Date') }}</td>
                                <td data-name="backup_size" data-sortable="true">{{ $t('Backup Size') }}</td>
                                <td data-name="copied_files" data-sortable="true" data-sort-dir="desc">{{ $t('Copied files')
                                }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(log, index) in logs" :key="log.id" track-by="log.id">
                                <td>
                                    <input class="ml-5" name="r1" type='radio' data-role='radio'
                                        @click="getJobConfiguration(log, index)">
                                </td>
                                <td>{{ convertDate(log.dt_start_utc) }}</td>
                                <td>{{ convertDate(log.dt_end_utc) }}</td>
                                <td>{{ log.backup_size | prettyBytes }}</td>
                                <td>{{ log.copied_files }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


            </div>
            <!-- SELEZIONE DELLA MAILBOXES PER RESTORE-->
            <div class="row" v-show="steps[1]">
                <div v-if="waitvm" data-role="activity" data-type="metro" data-style="dark"></div>
                <div id="esxivmslist" class="pb-4 w-100" style="overflow: scroll;">

                    <table data-role="sorter" data-sort-dir="desc" class="table striped text-small" v-if="!waitvm"
                        data-show-search="true" data-show-rows-steps="false" data-search-min-length="3"
                        data-search-threshold="300">

                        <thead>
                            <tr>

                                <td></td>
                                <td data-name="name" data-sortable="true" data-format="int">{{ $t('VM Name') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(mb) in mailboxesinbackup" :key="mb.Id" track-by="mb.Id">
                                <td>
                                    <input class="ml-5" name="r2" type='checkbox'
                                        v-model="mb.selectedtorestore" data-role='checkbox'>
                                </td>
                                <td>{{ mb.Name }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <div v-if="steps[2] && restore" class="container-fluid">

                <div class="row mt-4 ml-2">
                    <div v-if="[4, 5].includes(restore.type)" class="cell-8">
                        <label>{{ restore.vmname }}</label>
                        <br />
                        <!--  <span class='badge inline bg-black fg-white m-2 p-1 w-33'>{{backup.scheduling==null || backup.scheduling==''?"On Demand":"Queued"}}</span> -->
                        <span class='badge inline bg-cobalt fg-white p-1 mt-2 mr-1'>{{
                            restore.type_name
                        }}</span>
                        <span class='badge inline bg-cobalt fg-white p-1 mt-2 mr-1'>{{
                            restore.datacenter
                        }}</span>
                        <span class='badge inline bg-cobalt fg-white p-1 mt-2 mr-1'>n. {{
                            restore.backupnumber
                        }}</span>
                        <span class='badge inline bg-cobalt fg-white p-1 mt-2 mr-1'>{{
                            restore.datastore + '-' + restore.host
                        }}</span>
                    </div>
                    <div v-else class="cell-8">
                        <label>{{ restore.restorename }}</label>
                    </div>
                    <div class="cell-8">
                        <label v-if="restore.dt_start_utc != '' && restore.dt_start_utc != null">{{
                            restore.dt_start_utc.substr(0, 8) + "T" + restore.dt_start_utc.substr(8) |
                            utcAsLocal | moment("LLLL") }}</label>
                    </div>
                    <div class="cell-8">
                        <LastResult :lastresult="restore.lastresult" />
                    </div>
                    <div class="cell-6">
                        <div v-show="restore.progress != null && restore.progress != 0">
                            <div class="clear">
                                <small v-if="restore.current_operation.length <= 50" class="place-left" data-role="hint"
                                    data-hint-position="top" :data-hint-text="restore.current_operation">{{
                                        restore.current_operation
                                    }}</small>
                                <small v-else-if="restore.current_operation <= 100" class="place-left" data-role="hint"
                                    data-hint-position="top" :data-hint-text="restore.current_operation">{{
                                        restore.current_operation.substr(0, 50) + "..."
                                    }}</small>
                                <small v-else class="place-left" data-role="hint" data-hint-position="top"
                                    :data-hint-text="restore.current_operation">{{
                                        restore.current_operation.substr(0,
                                            50) + "..." + restore.current_operation.substr(-50, 50)
                                    }}</small>
                            </div>
                            <div data-role="progress" data-type="line" :data-value="restore.progress" data-small="true"
                                data-cls-bar="bg-cobalt" data-role-progress="true" class="progress small">
                                <div class="bar ribbed-cobalt" :style="{ width: restore.progress + '%' }">
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="cell-2">
                        <small v-if="restore.isRunning" class="place-right text-bold enlarge-1">{{
                            restore.progress
                        }}%</small>
                    </div>
                </div>

            </div>

        </div>
        <div class="dialog-actions">
            <div class="ml-auto">
                <button class="button error float-right ml-2" @click="$root.$emit('CLOSERESTOREDIALOG')">{{ $t('Close') }}</button>
                <button class="button" :class="{ 'disabled': steps[0] }" @click="back"><span class="mif-arrow-left"></span>{{ $t('Prev') }}</button>
                <button class="button" :class="{ 'disabled': steps[1] || steps[2] }" @click="next">{{ $t('Next') }}<span class="mif-arrow-right"> </span> </button>
                <button class="button primary float-right" v-show="steps[1] && (mailboxesinbackup.find(o => o.selectedtorestore == true))" @click="beginRestore"><span class="mif-checkmark pr-2"></span>{{ $t('Restore') }}</button>
            </div>
        </div>

    </div>
</template>
<script>
import DialogTitle from "../../utils/DialogTitle";
import api from "../../../router/api";


export default {
    name: "ExchangeDestinationRestore",
    props: {
        idPC: String,
        idJob: {
            type: String,
            required: true
        }
    },
    components: {
        "DialogTitle": DialogTitle,
    },
    filters: {
       
    },

    data() {
        return {
            steps: [1, 0, 0],

            wait: true,
            waitvm: true,
            jobselected: '',
            checkedNames: [],
            id_service_account: -2,

            job: {},
            logs: [],

            go: false,
            justOneGuid: '',

            restores: null, // socket [GT - 06/06/2023]
            restore: null,

            ExchangeRestoreModel: {},

            mailboxesinbackup: [
                {
                    Id: '',
                    Name: '',
                    Selected: false,
                    Size: '',
                    selectedtorestore: false,
                }],

            mailboxestorestore: [
                {
                    Id: '',
                    Name: '',
                    Selected: false,
                    Size: '',
                }],

            source: {},
            destination: {},

          

            folder: null,

            pc_isconnected: '',
            portchech: null,
            port: null,
        }
    },

    created: function () {

        this.getLogsList();

    },

    methods: {

        // primo metodo ad essere eseguito
        // mostra tutte le esecuzioni con relativa configurazione
        async getLogsList() {
            let self = this;

            await this.$api.getBackupLogs(self.idJob).then(response => {
                self.logs = response;
                self.wait = false;
            });

        },

        async getJobConfiguration(log) {

            let self = this;
            self.jobselected = ''; // svuoto la variabile che attiva pulsante next

            self.selectedlog = log;

            await this.$api.getBackupLogConfiguration(log.id).then(response => {

                console.log('[ExchangeDestinationRestore.getJobConfiguration] - api get backupconfigurations');
                console.log('[ExchangeDestinationRestore.getJobConfiguration] - ' + response);

                self.job = {}

                if (!self.job) {
                    self.job = response;
                }
                else {
                    self.job = Object.assign(self.job, response)
                }

                if (self.job.options) {
                    self.options = self.job.options
                }

                //valorizza l'array con le caselle di posta incluse nel singolo backup
                self.populateMBArray();

                self.jobselected = log.id; // attiva il pulsante next           

            });


        },

        getBackup() {

            let self = this;
            api
                .get('/jobs/' + self.idJob + "?fview=true")
                .then(response => {

                    console.log("api get jobs idbackup");
                    console.log(response.data);

                    if (!self.job) self.job = response.data;
                    else self.job = Object.assign(self.job, response.data);

                    if (self.job.options) self.options = self.job.options;

                });
        },

        //valorizza l'array con le virtual machine incluse nel singolo backup
        populateMBArray() {
            let self = this;
            self.waitvm = true;
            var sc, mb; // , idsToClose = [];

            //job.sources.0.exchangeSource.Mailboxes

            // svuoto array
            self.mailboxesinbackup.splice(0);

            if (self.job.sources)
                if (self.job.sources.length > 0)
                    for (var s = 0; s < self.job.sources.length; s++) { // ciclo sources del backup exchange
                        sc = self.job.sources[s]; // singola source
                        if (sc.exchangeSource)
                            for (var es = 0; es < sc.exchangeSource.Mailboxes.length; es++) { // ciclo gli host che ospitano i datastore
                                mb = sc.exchangeSource.Mailboxes[es];
                                if (mb.Selected)
                                    self.mailboxesinbackup.push(mb);
                            }
                    }




            setTimeout(function () {
                //var treeview = window.Metro.get$el("#esxiTreeview").data("treeview");
                //console.log(treeview)
                /*idsToClose.forEach(id => {
                    console.log(treeview);
                    console.log(treeview.toggleNode);
                    console.log(id);
                    console.log($("#" + id));

                    treeview.toggleNode($("#" + id)); // espande tutti i nodi 

                });*/
                self.waitvm = false;

            }, 1000);

        },

        /*********************METODI***********************************************/

        /*********************************UTILS**************************************/

        next() {
            var next = true;
            //let self = this;

            this.steps.forEach((step, index) => {
                if (step == 1) {
                    if (next) {
                        this.$set(this.steps, index + 1, true)
                        this.$set(this.steps, index, false)
                        next = false;

                    }
                }
            });

        },
        back() {
            var next = true;
            this.steps.forEach((step, index) => {
                if (step == 1) {
                    if (next) {
                        this.$set(this.steps, index - 1, true)
                        this.$set(this.steps, index, false)
                        next = false;

                    }
                }
            });

        },
        

        convertDate(date) {
            date = this.$moment(date.substr(0, 8) + "T" + date.substr(8), "YYYYMMDD HH:mm:ss");
            return this.$moment.utc(date).format("ddd DD/MM/YYYY HH:mm:ss");
        },

        /* SUBSCRIBE METODO SOCKET PER RESTORE EFFETTIVO*/
        async beginRestore() {
            var next = true;
            let self = this;

            self.go = confirm(this.$t("Restore the selected backup?"));


            if (self.go) {

                // faccio vedere la sezione del progress
                this.steps.forEach((step, index) => {
                    if (step == 1) {
                        if (next) {
                            this.$set(this.steps, index + 1, true)
                            this.$set(this.steps, index, false)
                            next = false;

                        }
                    }
                });

                // la generazione dell'id del restore avviene sul sito enterprise al fine poi di ricercare il processo di restore relativo
                self.justOneGuid = self.uuidv4();

                self.ExchangeRestoreModel.Id = self.justOneGuid;
                self.ExchangeRestoreModel.JobId = self.selectedlog.id_job;
                self.ExchangeRestoreModel.IdServiceAccount = self.job.sources[0].id_service_account;
                self.ExchangeRestoreModel.Version = '',
                    self.ExchangeRestoreModel.IsExchangeOnline = false;

                self.mailboxestorestore.splice(0);

                for (var s = 0; s < self.mailboxesinbackup.length; s++) {
                    if (self.mailboxesinbackup[s].selectedtorestore == true) {
                        self.mailboxestorestore.push(self.mailboxesinbackup[s]);
                    }
                }

                if (self.mailboxestorestore.length > 1) {
                    self.ExchangeRestoreModel.IsMultipleRestore = true;
                } else {
                    self.ExchangeRestoreModel.IsMultipleRestore = false;
                }

                self.ExchangeRestoreModel.Mailboxes = self.mailboxestorestore;

                self.ExchangeRestoreModel.OverWrite = false;

                self.ExchangeRestoreModel.CustomExchangeFolder = '';

                self.ExchangeRestoreModel.OutputFormat = self.job.sources[0].options.outputFormat;

                self.ExchangeRestoreModel.Destinations = self.job.destinations;

                self.ExchangeRestoreModel.type = self.job.sources[0].type;
                self.ExchangeRestoreModel.type_name = self.job.sources[0].type_name;

                console.log("[ExchangeDestinationRestore.beginRestore]");
                // runexchangerestore
                this.$root.socket("RUNEXCHANGERESTORE", self.ExchangeRestoreModel);

                // invoca agent per dare start ad invio delle informazioni sul restore
                self.idPC = await this.$root.getPC().id;
                //###DA INSERIRE IN OGNI COMPONENTE CHE NECESSITA DI CHIAMATE SOCKET###
                // "checkSocket" avvia la connessione socket se ferma
                // "socketEventConnected" viene emesso quando la connessione socket è attiva, quindi nella sua callback si possono avviare tutte le istruzioni che riguardano le chiamate socket
                // "socketEventDisconnected" viene emesso quando la connessione socket non è attiva
                this.$root.$on("socketEventConnected", () => {
                    self.$root.socketConnected = true;
                    self.pc_isconnected = true;
                    self.$root.socket("subscribe_pc", self.idPC).then(() => 
                        self.$root.socket("STARTSENDINFO")
                    );
                });
                this.$root.$on("socketEventDisconnected", () =>{
                    self.$root.socketConnected = false;
                    self.pc_isconnected = false;
                });
                this.$root.checkSocket();
                // -------------------------------------------------------------------- 


                // subscrive per ricevere il progress del restore ---------------------
                this.$root.$on("addRestoreToPage", restores => {
                    // TODO
                    console.log('Restore in popup');
                    console.log(restores);
                    //self.restores = restores;

                    self.restore = restores.find(obj => {
                        return obj.id == self.justOneGuid
                    })

                });
                // --------------------------------------------------------------------
            }
        },

        uuidv4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
                .replace(/[xy]/g, function (c) {
                    const r = Math.random() * 16 | 0,
                        v = c == 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
        },
    },
}
</script>