<template>
    <div v-on:keyup.esc="$root.$emit('CLOSESAREPOVIEW')" class="dialog center h-50 p-5">
        <div>
            <span class="button square closer" @click="$root.$emit('CLOSESAREPOVIEW')"></span>
        </div>

        <div class="dialog-content p-5 overflow h-100">
            <!--Rappresento il repository-->
            <div v-if="isValid(repository)">
                <h3 class="mt-4 fg-cobalt">{{ $t("Repository") }}</h3>
                <ObjectView :obj="repository" :withCopyButton="true" class="mt-4"/>

                <!--Se è stato passato il repository ma non il service account, aggiungo una riga-->
                <div v-if="!isValid(serviceAccount)">
                    <span class="mr-2 mif-warning fg-red"></span>
                    <span class="fg-red">{{ $t("Service account not found") }}</span>
                </div>
            </div>

            <div v-if="isValid(serviceAccount)">
                <h3 :class="isValid(repository)?'mt-4':''" class="fg-cobalt">{{ $t("Service account") }}</h3>
                <ObjectView :obj="serviceAccount" :withCopyButton="true" class="mt-4"/>
                <h4 v-if="Object.keys(serviceAccount.options).length != 0" class="mt-4 fg-cobalt">{{ $t("Service account - Options") }}</h4>
                <ObjectView v-if="Object.keys(serviceAccount.options).length != 0" :obj="serviceAccount.options" :withCopyButton="true" class="mt-4"/>
            </div>
        </div>
    </div>
</template>
<script>
import { isValid } from "../../../utils/utilitiesmodule";
import ObjectView from "./ObjectView.vue";
import DialogTitle from "../../utils/DialogTitle.vue";

export default {
    name: "SARepoView",
    props: {
        repository: Object,
        serviceAccount: Object
    },
    components: {
        ObjectView,
        DialogTitle
    },
    methods: {
        async copy(value) {
            await navigator.clipboard.writeText(value);
            this.$root.toast(this.$t("Copied to clipboard"), 2000, "info");
        },
        isValid(value) {
            return isValid(value)
        }
    }
}
</script>
