<template>
    <!--------------------FINESTRA AGGIUNTA / MODIFICA UTENTE------------------------------------------------>
    <div class="dialog light w-100-sm w-100-md w-75-lg  w-50-xl h-75 draggable-item" id="user_dialog">
        <div class="dialog-title pl-10">
            {{$t('Add Policies to Device') }}
            <span class="button square closer" @click="$root.$emit('CLOSEDEVICEPOLICYVIEWDIALOG')"></span>
        </div>
        <div class="dialog-content overflow bg-light"> 
            <div class="row h-100">
                
                <ul class="sidebar-menu col-5 pl-2 pt-5">
                    <li @click="policyTypeselected = policyType.id" class=" pb-5"  :class="{ 'bg-white pl-3 rounded': policyTypeselected == policyType.id }" v-if="policyType.related_entity=='PC' " v-for="(policyType,index) in $session.getPolicyTypes('POLICY_TYPES') ">
                        <a class="pl-0 text-bold" >{{ policyType.name }}</a>
                        <small class="text-muted">{{ policyType.description }}</small>
                    </li>   
                </ul>
                <div  class="col-7 p-4">
                    <ul>
                        <li v-if="policy.id_type == policyTypeselected && policy.related_entity == 'PC'" v-for="policy in $session.getPolicies()" class="p-2 m-1 d-flex flex-align-center flex-justify-start no-border">
                            <input type="checkbox" :value="policy.id" v-model="policiesSelected" data-caption-position="right" data-role="checkbox" :data-on="true"     >
                            <label class="">{{ policy.name }}</label>
                        </li>   
                    </ul>
                </div>
                    
                

            </div>
        </div>
        <div class="dialog-actions">
            <button class="button primary" @click="savePolicies">{{ $t("Save") }}</button>
            <button class="button ml-2" @click="$root.$emit('CLOSEDEVICEPOLICYVIEWDIALOG')">{{ $t("Close") }}</button>
        </div>
    </div>

</template>
<script>
import { draggableMixin } from '../../../mixins/draggable-mixin'

export default {

    mixins: [draggableMixin],
    name: "PolicyDeviceDialog",
    data() { 
        return { 
            policyTypeselected: 2,
            policiesSelected: [],
        } 
    },
    props: {
        device: {
            type: Object,
            required: true
        },
        policiesList: {
            type: [],
            required: true
        },
    },
    mounted: function() {
        this.policiesSelected = this.policiesList.map(policy => policy.id);  
    },
    methods:{

         

         

        async savePolicies() {
            let self = this;

     
            this.$api.setDevicePoliciesList(self.device.id,self.policiesSelected).then((response) => {
                console.log(response);
                this.$root.$emit("refreshPolicies");
                this.$root.$emit('CLOSEDEVICEPOLICYVIEWDIALOG');
            });
        },


    }
}
</script>