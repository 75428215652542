var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c("div", [
      _vm.singleItem
        ? _c("div", { staticClass: "mb-4" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t(_vm.buttonText)))]),
            _c("div", { staticClass: "d-flex flex-row flex-align-baseline" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.singleValue,
                    expression: "singleValue",
                  },
                  {
                    name: "path",
                    rawName: "v-path",
                    value: _vm.type.getSeparator(),
                    expression: "type.getSeparator()",
                  },
                ],
                staticClass: "w-75 text-small",
                attrs: { type: "text", "data-role": "input" },
                domProps: { value: _vm.singleValue },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.singleValue = $event.target.value
                  },
                },
              }),
              _c(
                "a",
                {
                  staticClass: "button ml-2",
                  attrs: { disabled: _vm.isDisabled },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("clickFunction")
                    },
                  },
                },
                [_c("span", { staticClass: "mif-folder" })]
              ),
            ]),
          ])
        : _c("input", {
            staticClass: "button primary mb-2",
            attrs: {
              type: "button",
              value: _vm.$t(_vm.buttonText),
              disabled: _vm.isDisabled,
            },
            on: {
              click: function ($event) {
                return _vm.$emit("clickFunction")
              },
            },
          }),
    ]),
    !_vm.singleItem && _vm.list.length > 0
      ? _c(
          "div",
          { staticClass: "overflow mt-2", style: "max-height: 60vh;" },
          [
            _c(
              "table",
              {
                staticClass: "table striped compact table-compact table-border",
              },
              [
                _c("thead", { staticClass: "bg-light" }, [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("Selected resources")))]),
                    _c("th", { staticClass: "text-right" }),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.list, function (res) {
                    return _c(
                      "tr",
                      { key: res.id, attrs: { title: res.path } },
                      [
                        _c("td", {}, [
                          _c("span", { class: _vm.getClass(res) }),
                          _vm._v(" " + _vm._s(res.path.split("?")[0])),
                        ]),
                        _c("td", { staticClass: "text-right" }, [
                          _vm.canCancel
                            ? _c(
                                "a",
                                {
                                  staticClass: "button flat-button",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$root.$emit("uncheckNode", res)
                                    },
                                  },
                                },
                                [_c("span", { staticClass: "mif-bin" })]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }