/*
Metodi di autorizzazione  : https://learn.microsoft.com/en-us/rest/api/storageservices/authorize-requests-to-azure-storage
https://learn.microsoft.com/en-us/rest/api/storageservices/authorize-with-shared-key#blob-queue-and-file-services-shared-key-authorization
*/
import api from "../../../router/api";
import { createHmac } from "crypto";
import { ClientTreeNode } from "../../../models/TreeNode";
import { isValid } from "../../utilitiesmodule";
import { FileFolderEntityType, PlatformsTypes } from "../../objects";

const version="2014-02-14";
export class AzureBlobClient {
    constructor(serviceAccount) {
        this.serviceAccount=serviceAccount;
        this.accountName=serviceAccount.client_id;
        this.accessKey=serviceAccount.client_secret;
        this.nodes=[];
        this.baseURL="https://"+this.accountName+".blob.core.windows.net/"
    }

    /*
    Documentazione : 
    https://learn.microsoft.com/en-us/rest/api/storageservices/authorize-with-shared-key#blob-queue-and-file-services-shared-key-authorization
    https://techcommunity.microsoft.com/t5/azure-paas-blog/the-mac-signature-found-in-the-http-request-xxxx-is-not-the-same/bc-p/3721224#M480
    */
    //Signature=Base64(HMAC-SHA256(UTF8(StringToSign), Base64.decode(<your_azure_storage_account_shared_key>)))
    async getRootNodes() {

        var children=this.nodes.length==0? []:
            this.nodes.filter(n => n.isChildOf(null));

        if (children.length!=0)
            return children;

        //Step 1 : Preparo in dati
        var queryObj={
            comp: "list",
            marker: "" //vedi come si comporta la richiesta
        };
        var urlToRequestContainers=this.baseURL+"?";

        var now, hash, headers, ok, response;

        do {

            now=new Date().toUTCString();

            hash=buildHash(
                this.accessKey, [
                "x-ms-date:"+now,
                "x-ms-version:"+version
            ],
                "GET",
                getCanonicalizedResource(this.accountName, null, queryObj)
            );

            headers={
                "Authorization": "SharedKey "+this.accountName+":"+hash,
                "x-ms-date": now,
                "x-ms-version": version
            };

            //Step 5 : Effettuo la richiesta
            [ok, response]=await api.sendRequestToServer("GET", urlToRequestContainers+getQueryString(queryObj), headers);

            if (!ok)
                return null;

            this.nodes.push(...extractNodes(response, "", FileFolderEntityType.Cloud_Bucket));

            queryObj.marker=extractMarker(response);


        } while (queryObj.marker!=null);

        return this.nodes;
    }

    async getNodesFrom(resource, searchInResources=true) {
        var children=this.nodes.length==0? []:
            this.nodes.filter(n => n.isChildOf(resource, "/"));

        //Step 1 : Controllo se sono già state salvare in locale le risorse richieste


        if (children.length!=0)
            return children;

        //Step 3 : Altrimenti, devo richiederle al cloud

        //Step 3.1 : Preparo i dati
        var [_, container, subPath]=resource.path.applyBucketsRules();

        var queryObj={
            comp: "list",
            marker: "",
            prefix: encodeURIComponent(subPath),
            restype: "container",
        };
        var urlToGetBlobs=this.baseURL+container+"?";

        var now, hash, headers, ok, response;
        var parentsPaths=new Set();

        //Step 3.2 : Effettuo la richiesta
        do {
            now=new Date().toUTCString();
            hash=buildHash(
                this.accessKey, [
                "x-ms-date:"+now,
                "x-ms-version:"+version
            ],
                "GET",
                getCanonicalizedResource(this.accountName, container, queryObj)
            );

            headers={
                "Authorization": "SharedKey "+this.accountName+":"+hash,
                "x-ms-date": now,
                "x-ms-version": version
            };


            [ok, response]=await api.sendRequestToServer("GET", urlToGetBlobs+getQueryString(queryObj), headers);

            if (!ok)
                return [];

            extractNodes(response, container, FileFolderEntityType.Cloud_File)
                .forEach(n => {
                    this.nodes.push(n);
                    extractParentsPaths(n.path).forEach(p => parentsPaths.add(p));
                })

            queryObj.marker=extractMarker(response);

        } while (queryObj.marker!=null);

        parentsPaths.forEach(p => {
            this.nodes.push(new ClientTreeNode(null, null, p, FileFolderEntityType.Cloud_Folder));
        });

        //console.log(blobs);
        return this.nodes.filter(n => n.isChildOf(resource, "/"));
    }

    async createContainer(containerName) {
        var queryObj={
            restype: "container",
        };

        var url=this.baseURL+containerName+"?";

        var now=new Date().toUTCString();
        var hash=buildHash(
            this.accessKey, [
            //'Content-Type:application/json',
            "x-ms-date:"+now,
            "x-ms-version:"+version
        ],
            "PUT",
            getCanonicalizedResource(this.accountName, containerName, queryObj),
            //"application/json", // suggerito da un errore
            "application/octet-stream",
            "0"
            //"4" // suggerito da un errore
        );

        var headers={
            "Authorization": "SharedKey "+this.accountName+":"+hash,
            //'Content-Type': "application/json",
            "x-ms-date": now,
            "x-ms-version": version
        };

        console.log(headers);

        var [ok, _]=await api.sendRequestToServer("PUT", url+getQueryString(queryObj), headers, null)


        /*api.put(
                url + getQueryString(queryObj), null, {
                    headers: headers
                })
            .then(res => res.status == 201).catch(e => {
                console.log(e.data);
                return false;
                //return null;
            });*/

        if (!ok)
            return null;

        var newNode=new ClientTreeNode(null, null, containerName, FileFolderEntityType.Cloud_Bucket);

        return newNode;
    }

}

export class AzureFileClient {
    constructor(serviceAccount) {
        this.serviceAccount=serviceAccount;
        this.accountName=serviceAccount.client_id;
        this.accessKey=serviceAccount.client_secret;
        this.nodes=[];
        this.baseURL="https://"+this.accountName+".file.core.windows.net/";
    }

    /*
Documentazione : 
    https://learn.microsoft.com/en-us/rest/api/storageservices/authorize-with-shared-key#blob-queue-and-file-services-shared-key-authorization
    https://techcommunity.microsoft.com/t5/azure-paas-blog/the-mac-signature-found-in-the-http-request-xxxx-is-not-the-same/bc-p/3721224#M480
*/
    //Signature=Base64(HMAC-SHA256(UTF8(StringToSign), Base64.decode(<your_azure_storage_account_shared_key>)))
    async getRootNodes() {

        var children=this.nodes.length==0? []:
            this.nodes.filter(n => n.isChildOf(null));

        if (children.length!=0)
            return children;

        var urlToRequestContainers=this.baseURL+"?";
        var queryObj={
            comp: "list",
            marker: ""
        };
        var now, hash, headers, ok, response;

        do {
            now=new Date().toUTCString();

            hash=buildHash(
                this.accessKey, [
                "x-ms-date:"+now,
                "x-ms-version:"+version
            ],
                "GET",
                getCanonicalizedResource(this.accountName, null, queryObj)
            );

            headers={
                "Authorization": "SharedKey "+this.accountName+":"+hash,
                "x-ms-date": now,
                "x-ms-version": version
            };

            //Step 5 : Effettuo la richiesta
            [ok, response]=await api.sendRequestToServer("GET", urlToRequestContainers+getQueryString(queryObj), headers);

            if (!ok)
                return null;

            this.nodes.push(...extractNodes(response, "", FileFolderEntityType.Cloud_Bucket));

            queryObj.marker=extractMarker(response);

        } while (queryObj.marker!=null);

        return this.nodes;

    }

    //https://learn.microsoft.com/en-us/rest/api/storageservices/list-directories-and-files
    async getNodesFrom(resource, searchInResources=true) {

        [resource.path]=resource.path.applyBucketsRules();
        //Step 1 : Controllo se sono già state salvare in locale le risorse richieste
        var children=this.nodes.length==0? []:
            this.nodes.filter(n => n.isChildOf(resource, "/"));

        if (children.length!=0)
            return children;
        //Step 3 : Altrimenti, devo richiederle al cloud
        var now, hash, headers, ok, response;
        //var parentsPaths = new Set();
        //Step 3.1 : Preparo i dati

        var urlToGetResources=this.baseURL+encodeURI(resource.path)+"?";
        var queryObj={
            comp: "list",
            marker: "",
            restype: "directory"
        };

        do {

            now=new Date().toUTCString();

            hash=buildHash(
                this.accessKey, [
                "x-ms-date:"+now,
                "x-ms-version:"+version
            ],
                "GET",
                getCanonicalizedResource(this.accountName, resource.path, queryObj)
            );

            headers={
                "Authorization": "SharedKey "+this.accountName+":"+hash,
                "x-ms-date": now,
                "x-ms-version": version
            };

            //Step 5 : Effettuo la richiesta
            [ok, response]=await api.sendRequestToServer("GET", urlToGetResources+getQueryString(queryObj), headers);

            if (!ok)
                return [];

            response.split("</Name>")
                .filter(html => html.indexOf("<Name>")!=-1)
                .map(html =>
                    new ClientTreeNode(
                        null,
                        null,
                        resource.path+"/"+html.split("<Name>")[1],
                        html.indexOf("<Directory><Name>")==-1?
                            FileFolderEntityType.Cloud_File:
                            FileFolderEntityType.Cloud_Folder
                    )).forEach(n => {
                        this.nodes.push(n);
                        //extractParentsPaths(n.path).forEach(p => parentsPaths.add(p));
                    })

            queryObj.marker=extractMarker(response);

        } while (queryObj.marker!=null);

        //parentsPaths.forEach(p => {
        //    this.nodes.push(new ClientTreeNode(null, null, p, FileFolderEntityType.Cloud_Folder));
        //});

        return this.nodes.filter(n => n.isChildOf(resource, "/"));
    }

    async createShare(shareName) {
        var queryObj={
            restype: "share",
        };

        var url=this.baseURL+shareName+"?";

        var now=new Date().toUTCString();
        var hash=buildHash(
            this.accessKey, [
            //'Content-Type:application/json',
            "x-ms-date:"+now,
            "x-ms-version:"+version
        ],
            "PUT",
            getCanonicalizedResource(this.accountName, shareName, queryObj),
            //"application/json", // suggerito da un errore
            "application/octet-stream",
            //"4" // suggerito da un errore
            "0"
        );

        var headers={
            "Authorization": "SharedKey "+this.accountName+":"+hash,
            //'Content-Type': "application/json",
            "x-ms-date": now,
            "x-ms-version": version
        };

        console.log(headers);

        var [ok, _]=await api.sendRequestToServer("PUT", url+getQueryString(queryObj), headers, null);



        /*api.put(
                url + getQueryString(queryObj), null, {
                    headers: headers
                })
            .then(res => res.status == 201).catch(e => {
                console.log(e.data);
                return false;
                //return null;
            });*/

        if (!ok)
            return null;

        var newNode=new ClientTreeNode(null, null, shareName, FileFolderEntityType.Cloud_Bucket);
        return newNode;
    }

    async createFolder(path) {
        var queryObj={
            restype: "directory",
        };

        var url=this.baseURL+path+"?";
        //var version = "2021-08-06";
        var now=new Date().toUTCString();
        var hash=buildHash(
            this.accessKey, [
            "x-ms-date:"+now,
            "x-ms-type:Directory",
            "x-ms-version:"+version,
        ],
            "PUT",
            getCanonicalizedResource(this.accountName, path, queryObj),
            "application/octet-stream",

            //"application/json",
            //"application/x-www-form-urlencoded",
            //"", // suggerito da un errore
            //0 //0 //4 //, // suggerito da un errore
            0
            //"bytes=0-3"
        );

        var headers={
            "Authorization": "SharedKey "+this.accountName+":"+hash,
            "x-ms-date": now,
            "x-ms-type": "Directory",
            "X-Ms-Version": version,
            "content-length": '0'
        };

        var config={
            headers,
            transformRequest: () => ""
        };

        var [ok, _]=await api.sendRequestToServer("PUT", encodeURI(url+getQueryString(queryObj)), headers, null);

        /*api.put(
            encodeURI(url + getQueryString(queryObj)),
            null, 
        )
        .then(res => res.status == 201).catch(e => {
            console.log(e.data);
            return false;
        });*/

        if (!ok)
            return null;

        var newNode=new ClientTreeNode(null, null, path, FileFolderEntityType.Cloud_Folder);
        return newNode;
    }
}

function buildHash(accessKey, headers, httpVerb, canonicalizedResource, ContentType='', ContentLength='', Range='') {

    //Step 1 : Costruisco la stringa
    var VERB=httpVerb;
    var ContentEncoding="";
    var ContentLanguage="";
    //var ContentLength = "";
    var ContentMD5="";
    //var ContentType = "";
    var date="";
    var IfModifiedSince="";
    var IfMatch="";
    var IfNoneMatch="";
    var IfUnmodifiedSince="";
    var CanonicalizedHeaders=headers.join("\n");
    var CanonicalizedResource=canonicalizedResource;


    var StringToSign=VERB+"\n"+
        ContentEncoding+"\n"+
        ContentLanguage+"\n"+
        ContentLength+"\n"+
        ContentMD5+"\n"+
        ContentType+"\n"+
        date+"\n"+
        IfModifiedSince+"\n"+
        IfMatch+"\n"+
        IfNoneMatch+"\n"+
        IfUnmodifiedSince+"\n"+
        Range+"\n"+
        CanonicalizedHeaders+"\n"+
        CanonicalizedResource;

    // StringToSign = "PUT\n" +
    //     "\n" +
    //     "\n" +
    //     "4\n" +
    //     "\n" +
    //     "application/json\n" +
    //     "\n" +
    //     "\n" +
    //     "\n" +
    //     "\n" +
    //     "\n" +
    //     "\n" +
    //     "x-ms-date:Fri, 24 Jan 2025 11:46:48 GMT\n" +
    //     "x-ms-version:2014-02-14\n" +
    //     "/newiperiustest/azure-filebackupdestination/qqqqqqqqqqqq\n" +
    //     "restype:directory";
    // console.log(StringToSign);

    console.log(StringToSign);
    //Step 2 : Codifico la stringa in UTF-8
    var encodedStringToSign=Buffer.from(StringToSign).toString('utf-8');

    //Step 3 : Decodifico l'access key Decode the Base64 storage key.
    var decodedAccessKey=Buffer.from(accessKey, "base64");

    //Step 4 : Cripto encodedString utilizzando l'algoritmo HMAC-SHA256 e la chiave decodedAccessKey. Il tutto viene codificato in base 64
    var hash=createHmac('sha256', decodedAccessKey).update(encodedStringToSign).digest("base64");

    //console.log(hash);

    return hash;
}

//https://learn.microsoft.com/en-us/rest/api/storageservices/enumerating-blob-resources


function getCanonicalizedResource(accountName, rootFolder, queryObj) {
    var canonicalizedResource="/"+accountName+"/"+(isValid(rootFolder)? encodeURI(rootFolder):"");
    canonicalizedResource+="\n"+Object.keys(queryObj).map(p => p+":"+queryObj[p]).join("\n");
    console.log(canonicalizedResource);
    return canonicalizedResource;
}


function extractNodes(apiResponse, rootFolder, type) {
    //res.data è un html. I nomi dei containers sono inclusi tra i tags <Name></Name>
    return apiResponse.split("</Name>")
        .filter(html => html.indexOf("<Name>")!=-1)
        .map(html =>
            rootFolder!=""?
                new ClientTreeNode(null, null, rootFolder+"/"+html.split("<Name>")[1], type):
                new ClientTreeNode(null, null, html.split("<Name>")[1], type)
        );
}

function extractMarker(apiResponse) {
    if (apiResponse.contains("<NextMarker>")&&apiResponse.contains("</NextMarker>"))
        return apiResponse.split("</NextMarker>")[0].split("<NextMarker>")[1];
    return null;
}

function extractParentsPaths(path) {
    var parentsPaths=path.getAllPaths(PlatformsTypes.Cloud_AzureBlob);
    parentsPaths.splice(0, 1); // il primo è lo share o il container
    parentsPaths.pop();
    return parentsPaths;
}

function getQueryString(queryObj) {
    return Object.keys(queryObj)
        .map(p => p+"="+queryObj[p])
        .join("&")
}