import { BUCKETS_NOT_RETRIEVED } from "../../messages";
import { waitSeconds, isValid } from "../../utilitiesmodule";
import { FileFolderEntityType, PlatformsTypes } from "../../objects";
import { ClientTreeNode } from "../../../models/TreeNode";
import api from "../../../router/api";
import crypto from "crypto";
export class S3Client {
    constructor(serviceAccount) {
        /* this.accessKey = accessKey;
        this.secretKey = secretKey;
        this.region = region */
        this.serviceAccount=serviceAccount;
        var config={
            accessKeyId: serviceAccount.client_id,
            secretAccessKey: serviceAccount.client_secret,
            //apiVersion: "2012-10-17"
        }

        if (isValid(serviceAccount.region))
            config.region=serviceAccount.region;
        if (isValid(serviceAccount.url))
            config.endpoint=serviceAccount.url;
        //if (signatureversion != null)
        //    config.signatureVersion = signatureversion;

        var AWS=require('aws-sdk');
        this.s3=new AWS.S3(config);
        this.serviceName="sts"; // non so se è lo stesso per tutti gli S3
        this.nodes=[];
        this.bucket_region={};
        this.parser=new DOMParser();
    }

    getURLs(bucketName=null, region=null) {

        if (this.serviceAccount.type==PlatformsTypes.Cloud_AmazonS3Storage&&bucketName==null&&region==null) {
            region="us-east-1";
            return [`https://s3.${region}.amazonaws.com`];
        }

        if (this.serviceAccount.type==PlatformsTypes.Cloud_AmazonS3Storage&&bucketName!=null&&region==null) {
            return [`https://${bucketName}.s3.amazonaws.com`];
        }

        if (this.serviceAccount.type==PlatformsTypes.Cloud_AmazonS3Storage&&bucketName==null&&region!=null) {
            return [`https://s3.${region}.amazonaws.com`];
        }

        if (this.serviceAccount.type==PlatformsTypes.Cloud_AmazonS3Storage&&bucketName!=null&&region!=null) {
            return [`https://${bucketName}.s3.${region}.amazonaws.com`];
        }

        if (this.serviceAccount.type!=PlatformsTypes.Cloud_AmazonS3Storage&&bucketName==null) {
            return [this.serviceAccount.url];
        }

        var [httpType, serviceName]=this.serviceAccount.url.split("://");

        if (this.serviceAccount.type==PlatformsTypes.Cloud_IperiusS3Storage&&bucketName!=null) {
            return [`${this.serviceAccount.url}/${bucketName}`];
        }

        if (this.serviceAccount.type==PlatformsTypes.Cloud_S3CompatibleStorage&&bucketName!=null) {
            var [httpType, serviceName]=this.serviceAccount.url.split("://");
            return [`${httpType}://${bucketName}.${serviceName}`, `${this.serviceAccount.url}/${bucketName}`];
        }
        // Non dovrebbe mai andare qui
        return null;
    }


    async getRootNodes() {
        var children=this.nodes.length==0? []:this.nodes.filter(n => n.isChildOf(null));
        if (children.length!=0) {
            return children;
        }
        if (this.serviceAccount.region==null) {
            this.serviceAccount.region="";
        }
        
        var defaultRegion=this.serviceAccount.region;//this.serviceAccount.type==PlatformsTypes.Cloud_AmazonS3Storage? "us-east-1":"";
        var url=this.getURLs(null, defaultRegion)[0];
        var headers=getHeadersWithSignedRequest("GET", url, this.serviceAccount, defaultRegion);
        var [ok, response]=await api.sendRequestToServer("GET", url, headers);
        if (!ok) {
            writeError(response);
            return null;
        }
        // Parsiamo la risposta XML
        var xmlDoc=this.parser.parseFromString(response, "text/xml");
        //Per ogni tag del nome, prendo il valore e creo il nodo del bucket
        this.nodes=Array.from(xmlDoc.getElementsByTagName("Name"))
            .map(
                (nameTag, i) => new ClientTreeNode("node-"+i, null, nameTag.textContent, FileFolderEntityType.Cloud_Bucket)
            );
        return this.nodes;
    }

    async getNodesFrom(resource, searchInResources=true) {
        var children=this.nodes.filter(n => n.isChildOf(resource));
        if (children.length!=0) {
            return children;
        }
        var bucketName, subPath;
        [resource.path, bucketName, subPath]=resource.path.applyBucketsRules();
        var continuationToken=null;
        var url, headers, ok, response, xmlDoc, nextToken;
        var allParents=new Set();
        var bucketRegion=await this.getRegionOfBucket(bucketName);
        var newNode, newPath, newType;


        var urls=this.getURLs(bucketName, bucketRegion);

        /*[
            "https://{0}.cloud.iperiusstorage.com/?list-type=2",
            "https://{0}.dovaoj55.iperiusstorage.com/?list-type=2",
            "https://{0}.dovaoj55-s3.iperiusstorage.com/?list-type=2",
            "https://{0}.dovaoj55.s3.iperiusstorage.com/?list-type=2",

            "https://cloud.iperiusstorage.com/{0}/?list-type=2",
            "https://dovaoj55.iperiusstorage.com/{0}/?list-type=2",
            "https://dovaoj55-s3.iperiusstorage.com/{0}/?list-type=2",
            "https://dovaoj55.s3.iperiusstorage.com/{0}/?list-type=2",

            "https://cloud.iperiusstorage.com/{0}?list-type=2",
            "https://dovaoj55.iperiusstorage.com/{0}?list-type=2",
            "https://dovaoj55-s3.iperiusstorage.com/{0}?list-type=2",
            "https://dovaoj55.s3.iperiusstorage.com/{0}?list-type=2",

            "https://cloud.iperiusstorage.com/{0}/",
            "https://dovaoj55.iperiusstorage.com/{0}/",
            "https://dovaoj55-s3.iperiusstorage.com/{0}/",
            "https://dovaoj55.s3.iperiusstorage.com/{0}/"
        ];*/
        for (var i=0; i<urls.length; i++) {

            do {

                try {
                    //codice vero
                    url=urls[i]+"/?list-type=2";

                    //url=urls[i].format(bucketName);
                    //url=`https://${bucketName}.s3.${bucketRegion}.amazonaws.com/?list-type=2`;
                    if (continuationToken) {
                        url+=`&continuation-token=${encodeURIComponent(continuationToken)}`;
                    }

                    headers=getHeadersWithSignedRequest("GET", url, this.serviceAccount, bucketRegion, "", resource.path);
                    [ok, response]=await api.sendRequestToServer("GET", url, headers);

                    if (!ok) {
                        writeError(response);
                        break;
                        //codice vero
                        //return null;
                    }

                    xmlDoc=this.parser.parseFromString(response, "text/xml");
                    Array.from(xmlDoc.getElementsByTagName("Key")).forEach(el => {
                        //Trasformo ogni elemento in un nodo
                        newPath=bucketName+"/"+el.textContent.trimEnd("/");
                        newType=el.textContent.endsWith("/")? FileFolderEntityType.Cloud_Folder:FileFolderEntityType.Cloud_File;
                        newNode=new ClientTreeNode(null, null, newPath, newType);
                        //lo inserisco nella lista
                        this.nodes.push(newNode);
                        //ricavo i suoi parent
                        //allParents.push(...newNode.path.getAllPaths(PlatformsTypes.Cloud_AmazonS3Storage).slice(1, -1));
                        newNode.path.getAllPaths(PlatformsTypes.Cloud_AmazonS3Storage).slice(1, -1).forEach(allParents.add, allParents)

                        //allParents.push(...newNode.path.getAllPaths(PlatformsTypes.Cloud_AmazonS3Storage).slice(1, -1));
                    });

                    nextToken=xmlDoc.getElementsByTagName("NextContinuationToken")[0];
                    continuationToken=nextToken? nextToken.textContent:null;
                } catch { }


            } while (continuationToken);

            if (ok) {
                break;
            }
        }

        if (!ok) {
            return null;
        }

        allParents.forEach(p => {
            this.nodes.push(new ClientTreeNode(null, null, p, FileFolderEntityType.Cloud_Folder));
        });

        this.nodes=this.nodes.distinctBy('path');

        //this.nodes.push(...allParents.distinctBy(null).map(p => new ClientTreeNode(null, null, p, FileFolderEntityType.Cloud_Folder)).distinctBy('path'));

        return this.nodes.filter(n => n.isChildOf(resource));
    }


    async getRegionOfBucket(bucketName) {
        if (this.serviceAccount.type!=PlatformsTypes.Cloud_AmazonS3Storage) {
            return "";
        }/**/

        var region=this.bucket_region[bucketName];
        if (region!=undefined) {
            return region;
        }
        var url=this.getURLs(bucketName, null)[0];

        //var url=`https://${bucketName}.s3.amazonaws.com/`;
        var headers=getHeadersWithSignedRequest("HEAD", url, this.serviceAccount);

        var [ok, response]=await api.sendRequestToServer("HEAD", url, headers, null);
        if (ok) {
            var region=response["x-amz-bucket-region"];
            if (region) {
                console.log(`Regione del bucket: ${region}`);
                this.bucket_region[bucketName]=region;
                return region;
            }
        }

        return "eu-west-1";
    }



    async createBucket(bucketName, region) {
        //region="";//"eu-west-1";
        var urls=this.getURLs(bucketName, region);
        var body=null;
        var payload=""
        var contentType="";
        if (this.serviceAccount.type==PlatformsTypes.Cloud_AmazonS3Storage) {
            body="<CreateBucketConfiguration xmlns=\"http://s3.amazonaws.com/doc/2006-03-01/\"><LocationConstraint>"+region+"</LocationConstraint></CreateBucketConfiguration>"
            payload=body;
            contentType="application/xml";
        }

        var url, headers, ok, response;
        for (var i=0; i<urls.length; i++) {
            url=urls[i]+"/";
            headers=getHeadersWithSignedRequest("PUT", url, this.serviceAccount, region, payload, "", contentType, "");

            [ok, response]=await api.sendRequestToServer("PUT", url, headers, body);
            if (!ok) {
                writeError(response);
                continue;
            }

            this.bucket_region[bucketName]=region;
            console.log(`Bucket "${bucketName}" creato con successo`);
            var newNode=new ClientTreeNode(null, null, bucketName, FileFolderEntityType.Cloud_Bucket);
            this.nodes.push(newNode);
            return newNode;
        }

        return null;
    }


    async createFolder(path) {
        var bucketName, subPath;
        [path, bucketName, subPath]=path.applyBucketsRules();
        var region=await this.getRegionOfBucket(bucketName);
        var urls=this.getURLs(bucketName, region), url, headers, ok, response;

        for (var i=0; i<urls.length; i++) {
            url=urls[i]+`/${subPath}/`;
            headers=getHeadersWithSignedRequest("PUT", url, this.serviceAccount, region, "", path, "", "");
            [ok, response]=await api.sendRequestToServer("PUT", url, headers, null);
            if (!ok) {
                writeError(response);
                continue;
            }
            var newNode=new ClientTreeNode(null, null, path, FileFolderEntityType.Cloud_Folder);
            this.nodes.push(newNode);
            return newNode;
        }

        return null;
    }

}

function getSignatureKey(key, dateStamp, regionName, serviceName) {
    //const crypto=require('crypto');
    var kDate=crypto.createHmac("sha256", "AWS4"+key).update(dateStamp).digest();
    var kRegion=crypto.createHmac("sha256", kDate).update(regionName).digest();
    var kService=crypto.createHmac("sha256", kRegion).update(serviceName).digest();
    return crypto.createHmac("sha256", kService).update("aws4_request").digest();
}

function getHeadersWithSignedRequest(method, url, serviceAccount, region="", payload="", path="", contentType="", contentMD5="") {
    var accessKey=serviceAccount.client_id;
    var secretKey=serviceAccount.client_secret;
    var service="s3"
    //const crypto=require('crypto');
    var date=new Date();
    var signatureVersion=serviceAccount.options.signatureversion;
    if (signatureVersion==undefined) {
        signatureVersion=4;
    }

    /**/if (signatureVersion==2) {
        return signRequest_V2(method, accessKey, secretKey, date, path, contentType, contentMD5);
    } else {
        return signRequest_V4(method, url, accessKey, secretKey, date, service, region, payload, contentType);
    }
}

function signRequest_V4(method, url, accessKey, secretKey, date, service="s3", region="", payload="", contentType="") {

    //const crypto=require('crypto');
    var amzDate=date.toISOString().replace(/[:-]|\.\d{3}/g, ""); // Timestamp
    var dateStamp=amzDate.slice(0, 8); // YYYYMMDD

    var parsedUrl=new URL(url);
    var host=parsedUrl.host;
    var payloadHash;
    if (["GET", "HEAD"].includes(method)) {
        payloadHash="UNSIGNED-PAYLOAD";// suggerito da chatgpt
    } else if (payload=="") {
        payloadHash="e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855"; // SHA256("")
    } else {
        payloadHash=crypto.createHash("sha256").update(payload).digest("hex");
    }


    // Creiamo la stringa da firmare
    //devono finire con \n
    //devono essere in ordine alfabetico
    var canonicalHeaders, signedHeaders;
    if (url.contains("amazonaws")) {
        canonicalHeaders="host:{0}\nx-amz-content-sha256:{1}\nx-amz-date:{2}\n".format(host, payloadHash, amzDate);
        signedHeaders="host;x-amz-content-sha256;x-amz-date"
    } else {
        canonicalHeaders="host:{0}\nx-amz-date:{1}\n".format(host, amzDate);
        signedHeaders="host;x-amz-date"
    }

    if (contentType!="") {
        canonicalHeaders="content-type:"+contentType+"\n"+canonicalHeaders;
        signedHeaders="content-type;"+signedHeaders
    }



    if (url.contains("amazonaws")&&method!="GET") {
        //canonicalHeaders+=`x-amz-content-sha256:${}\n`
        //signedHeaders+=";";

        /*if (contentType!="") {
            canonicalHeaders+=`content-type:${contentType}\ncontent-length:0\n`
            signedHeaders+=";content-type;content-length";
        }*/
    }

    var canonicalRequest=[
        method,
        parsedUrl.pathname,
        parsedUrl.search.replace("?", ""),//canonicalQueryString,
        canonicalHeaders,
        signedHeaders,
        payloadHash
    ].join("\n");

    console.log(canonicalRequest);
    var canonicalRequest_sha256=crypto.createHash("sha256").update(canonicalRequest).digest("hex");
    console.log(canonicalRequest_sha256);

    var algorithm="AWS4-HMAC-SHA256";
    var credentialScope=`${dateStamp}/${region}/${service}/aws4_request`;
    var stringToSign=`${algorithm}\n${amzDate}\n${credentialScope}\n${canonicalRequest_sha256}`;

    console.log("\n++++++++++++++++++++++++++++stringToSign : \n"+stringToSign+"\n++++++++++++++++++++++++++++");


    // Calcoliamo la firma
    var signingKey=getSignatureKey(secretKey, dateStamp, region, service);

    console.log("\n++++++++++++++++++++++++++++signingKey : \n"+signingKey+"\n++++++++++++++++++++++++++++")
    var signature=crypto.createHmac("sha256", signingKey).update(stringToSign).digest("hex");

    // Creiamo l'Authorization header
    var authorizationHeader=`${algorithm} Credential=${accessKey}/${credentialScope}, SignedHeaders=${signedHeaders}, Signature=${signature}`;


    var headers={
        "x-amz-date": amzDate,
        "Authorization": authorizationHeader,
        "x-amz-content-sha256": payloadHash // messo sempre
    };

    if (contentType!="") {
        headers["content-type"]=contentType;
    }
    if (method=="PUT") {
        //headers["content-type"]=contentType;
        //headers["content-length"]="4";
    }
    return headers;
}


function signRequest_V2(method, accessKey, secretKey, date, path, contentType="", contentMD5="") {
    date=date.toUTCString(); // Data in formato RFC1123

    // Costruzione della stringa da firmare (Signature V2)
    const canonicalizedResource=`/${path}/`;
    const stringToSign=`${method}\n${contentMD5}\n${contentType}\n${date}\n${canonicalizedResource}`;

    // Firma la stringa con HMAC-SHA1 (Cubbit supporta SHA1 e SHA256, ma SHA1 è il default)
    const signature=crypto.createHmac("sha1", secretKey).update(stringToSign).digest("base64");

    return {
        "Date": date, // Header obbligatorio in Signature V2
        "Authorization": `AWS ${accessKey}:${signature}`
    };
}

function writeError(error) {
    if (typeof error=="string") {
        var parser=new DOMParser();
        var errorXML=parser.parseFromString(error, "text/xml");
        var errorCode=Array.from(errorXML.getElementsByTagName("Code"))[0].textContent;
        var errorMessage=Array.from(errorXML.getElementsByTagName("Message"))[0].textContent;
        console.error(errorCode+" : "+errorMessage);
        return;
    }

    console.error(error);
}

/************************************METODI CON SDK********************************************************************************************************/
//     //Lista tutti i buckets e li ritorna
//     async getRootNodes_old() {
//     var children=this.nodes.length==0? []:
//         this.nodes.filter(n => n.isChildOf(null));
//     var error=false;
//     if (children.length!=0)
//         return children;

//     var bucketsNames=null;
//     this.bucket_region={};
//     this.s3.listBuckets /*Directory*/(
//         /*{
//                         "ContinuationToken": "",
//                         "MaxDirectoryBuckets": 100
//                     },
//                     */
//         (e, data) => {

//             if (e!=null) {
//                 console.log(BUCKETS_NOT_RETRIEVED);
//                 console.log(e);
//                 bucketsNames=[];
//                 error=true;
//                 return;
//             }
//             console.log(data);
//             bucketsNames=data.Buckets.map(b => b.Name);
//         }
//     );

//     //Attendo per un massimo di 60 secondi
//     for (var i=1; i<=60; i++) {
//         if (bucketsNames!=null) break;
//         await waitSeconds(1);
//     }

//     if (error)
//         return null;

//     for (var i=0; i<bucketsNames.length; i++) {
//         this.nodes.push(new ClientTreeNode("node-"+i, null, bucketsNames[i], FileFolderEntityType.Cloud_Bucket))
//     }

//     return this.nodes;
// }


// //https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#listObjects-property
//     //Ritorna i file e le sottocartelle di resource.path
//     async getNodesFrom_old(resource, searchInResources=true) {
//     try {
//         var children=this.nodes.length==0? []:
//             this.nodes.filter(n => n.isChildOf(resource, "/")).distinctBy('path');

//         if (children.length!=0)
//             return children;

//         var marker="";
//         var isTruncated;
//         var response;
//         var newPath;
//         var newNode;
//         var bucketName, subPath;
//         [resource.path, bucketName, subPath]=resource.path.applyBucketsRules();
//         console.log(resource.nParts);


//         this.s3.region=this.bucket_region[bucketName];
//         if (this.s3.region==undefined) {
//             this.s3.region=await this.getRegionOfBucket(bucketName);
//         }

//         console.log(pathParts);
//         var request={
//             Bucket: bucketName, //+ this.s3.region + ".s3",
//             Prefix: subPath,
//             MaxKeys: 1000
//         };

//         var allParents=[];
//         do {

//             if (marker!="") {
//                 request.ContinuationToken=marker;
//             }

//             //response = await this.s3.send(new ListObjectsV2Command(request));
//             response=await this.s3.listObjectsV2(request).promise();

//             response.Contents.forEach(obj => {
//                 //Step 1 : aggiungo in nodes ogni risorsa trovata
//                 newPath=bucketName+"/"+obj.Key;
//                 newPath=newPath.trimEnd("/");
//                 newNode=new ClientTreeNode(null, null, newPath, !obj.Key.endsWith("/")? FileFolderEntityType.Cloud_File:FileFolderEntityType.Cloud_Folder);
//                 this.nodes.push(newNode);
//                 allParents.push(...newNode.path.getAllPaths(PlatformsTypes.Cloud_AmazonS3Storage).slice(1, -1));
//                 //this.nodes = newNode.insertParentsInto(this.nodes, PlatformsTypes.Cloud_AmazonS3Storage);
//             });

//             isTruncated=response.IsTruncated;
//             marker=response.NextContinuationToken;

//         } while (isTruncated);

//         /*this.nodes.forEach(n => {
//             console.log(n.path);
//         });*/

//         this.nodes.push(...allParents.distinctBy(null).map(p => new ClientTreeNode(null, null, p, FileFolderEntityType.Cloud_Folder)).distinctBy('path'));
//         this.s3.region=this.serviceAccount.region;
//         return await this.getNodesFrom(resource, false);
//     } catch (e) {
//         console.error(e);
//         try {
//             response=await this.s3.listObjects(request).promise();
//         } catch (e) {
//             console.error(e);
//         }
//     }
//     this.s3.region=this.serviceAccount.region;
//     return [];
// }

// async createBucket_old(bucketName) {

//     var response="wait";

//     this.s3.createBucket({ Bucket: bucketName },
//         function(err, data) {
//             if (err) {
//                 console.log("Error", err);
//                 response=false;
//             } else {
//                 console.log("Success", data.Location);
//                 response=true;
//             }
//         }
//     );

//     while (response=="wait")
//         await waitSeconds(1);

//     if (!response)
//         return false;

//     var newNode=new ClientTreeNode(null, null, bucketName, FileFolderEntityType.Cloud_Bucket);
//     return newNode;
// }


// async createFolder_old(path) {
//     var bucketName, objectName;
//     [path, bucketName, objectName]=path.applyBucketsRules();
//     objectName=parts.subPath+"/";
//     var params={ Bucket: bucketName, Key: objectName, Body: "" };
//     var response="wait";

//     this.s3.region=this.bucket_region[bucketName];
//     if (this.s3.region==undefined)
//         this.s3.region=await this.getRegionOfBucket(bucketName);

//     this.s3.upload(params, function(err, data) {
//         if (err) {
//             console.log("Error creating the folder: ", err);
//             response=false;
//         } else {
//             console.log("Successfully created a folder on S3");
//             response=true;
//         }
//     });

//     while (response=="wait")
//         await waitSeconds(1);

//     if (!response)
//         return false;

//     this.s3.region=this.serviceAccount.region;
//     var newNode=new ClientTreeNode(null, null, path, FileFolderEntityType.Cloud_Folder);
//     return newNode;
// }

// async getRegionOfBucket_old(bucketName) {
//     var region=this.bucket_region[bucketName];
//     if (region!=undefined) {
//         return region;
//     }
//     try {
//         this.s3.headBucket({ "Bucket": bucketName }, function(err, data) {
//             if (err) {
//                 console.log(err);
//                 region=null;
//                 return;
//             }
//             console.log(data);
//             region=data.BucketRegion;
//         });
//     } catch (e) {
//         console.error(e)
//         region=null;
//     }

//     for (var i=0; i<20; i++) {
//         if (region!="")
//             break;
//         await waitSeconds(1);
//     }

//     return region;
// }