<template>
    <li class="p-1 ml-4" :title="disk.path" data-collapsed="true">
      <span class="mif-drive1 mif-lg"></span>
      <!--input class="ml-5" type="checkbox" v-model="disk.selected"-->      
      <span class="ml-3">{{ disk.name }} [{{ disk.size_string }}]</span>
    </li>
  </template>
  
  <script>
  export default {
    name:"TreeDisk",
    props: {
      disk: Object,
    },
  };
  </script>
  
  <style scoped>
  .ml-5 {
    margin-left: 5px;
  }
  </style>
  