var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "w-100 h-100 overflow" },
    [
      _c("Navigation", { attrs: { pageName: "licenses" } }),
      _c(
        "div",
        {
          attrs: {
            "data-role": "charms",
            id: "mandevicesinlicensemodule",
            "data-position": "right",
            "data-cls-charms":
              "bd-primary bg-white border border-3 border-bottom-none border-left-none border-right-none charms h-100 right-side w-50",
          },
        },
        [
          _c("div", { staticClass: "row p-3 mb-4" }, [
            _c("h4", { staticClass: "cell-8 text-left fg-black" }, [
              _vm._v(_vm._s(_vm.$t("Devices using the module")) + ":"),
            ]),
            _c("h4", { staticClass: "cell-8 text-left fg-black" }, [
              _c("strong", [_vm._v(_vm._s(_vm.moduleExtDescription))]),
            ]),
            _c("div", { staticClass: "cell-4 d-flex flex-justify-end" }, [
              _c(
                "button",
                {
                  staticClass: "button bg-white",
                  on: { click: _vm.closeCharms },
                },
                [_c("span", { staticClass: "icon mif mif-close mif-cross" })]
              ),
            ]),
          ]),
          !_vm.computersinlic.computers ||
          _vm.computersinlic.computers.length == 0
            ? _c(
                "div",
                [
                  _c("NoDataAvailable", {
                    attrs: { message: _vm.$t("No Devices using the module") },
                  }),
                ],
                1
              )
            : _c("div", { staticClass: "p-5" }, [
                _c(
                  "div",
                  { staticClass: "d-flex flex-justify-end mt-2 mb-2" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchText,
                          expression: "searchText",
                        },
                      ],
                      staticClass: "input w-50 outline",
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t("Search computer"),
                      },
                      domProps: { value: _vm.searchText },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.searchText = $event.target.value
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "table",
                  {
                    staticClass:
                      "table table-border compact striped bg-white m-0",
                  },
                  [
                    _c("thead", [
                      _c("tr", [
                        _c("th", { staticClass: "sortable-column pl-4" }, [
                          _vm._v(_vm._s(_vm.$t("Name"))),
                        ]),
                        _c("th", { staticClass: "sortable-column" }, [
                          _vm._v(_vm._s(_vm.$t("Custom Name"))),
                        ]),
                        _c(
                          "th",
                          {
                            staticClass: "sortable-column",
                            attrs: { "data-cls-column": "text-left no-wrap" },
                          },
                          [_vm._v(_vm._s(_vm.$t("Organization")))]
                        ),
                        _c(
                          "th",
                          {
                            attrs: { "data-cls-column": "text-right no-wrap" },
                          },
                          [_vm._v(_vm._s(_vm.$t("Remove")))]
                        ),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.filteredComputers, function (computer) {
                        return _c(
                          "tr",
                          {
                            key: computer.id,
                            staticClass: "mx-8 p-4 rounded m-6",
                          },
                          [
                            _c(
                              "td",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "text-bold text-left m-0 button flat-button",
                                    attrs: {
                                      to: "/computers/computer/" + computer.id,
                                    },
                                  },
                                  [_vm._v(_vm._s(computer.name))]
                                ),
                              ],
                              1
                            ),
                            _c("td", { staticClass: "text-left" }, [
                              _vm._v(" " + _vm._s(computer.custom_name) + " "),
                            ]),
                            _c("td", { staticClass: "text-left" }, [
                              _vm._v(
                                " " + _vm._s(computer.organization_name) + " "
                              ),
                            ]),
                            _c(
                              "td",
                              { staticClass: "text-center" },
                              [
                                _vm.currNode != "monitoring"
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "button flat-button",
                                        on: {
                                          click: function ($event) {
                                            return _vm.remotePcFromLicenseModule(
                                              computer
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "mif-cancel1",
                                        }),
                                      ]
                                    )
                                  : _c("PCLicenseLink", {
                                      attrs: {
                                        computer: computer,
                                        parentName: "Licenses",
                                      },
                                      on: { updateLicense: _vm.updateLicense },
                                    }),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ]),
        ]
      ),
      _vm.licenses == null
        ? _c("div", { staticClass: "d-flex flex-justify-center p-10" }, [
            _c("span", {
              staticClass: "mif-spinner2 fg-primary ani-spin mif-3x",
            }),
          ])
        : _vm._e(),
      _c("div", { staticClass: "p-10" }, [
        _vm.licenseDetails
          ? _c("div", [
              _c("div", { staticClass: "pt-2" }, [
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("Company")) + ": "),
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.currentLicenseLogin.company) +
                        " (" +
                        _vm._s(_vm.currentLicenseLogin.email) +
                        ")"
                    ),
                  ]),
                ]),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("Master Account")) + ": "),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.currentLicenseLogin.ma_username)),
                  ]),
                ]),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("Expiration Date")) + ": "),
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.formatDate(_vm.currentLicenseLogin.dataora_scadenza)
                      )
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _c("br"),
        _c("h4", { staticClass: "pt-2" }, [
          _vm._v(_vm._s(_vm.$t("Monitoring"))),
        ]),
        _vm.licenses != null
          ? _c(
              "table",
              { staticClass: "table condensed striped bg-white mt-2" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticStyle: { width: "20%" } }, [
                      _vm._v(_vm._s(_vm.$t("Name"))),
                    ]),
                    _c(
                      "th",
                      {
                        staticClass: "text-left",
                        staticStyle: { width: "50%" },
                      },
                      [_vm._v(_vm._s(_vm.$t("Description")))]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-right",
                        staticStyle: { width: "30%" },
                      },
                      [_vm._v(_vm._s(_vm.$t("Licenses Used/Total")))]
                    ),
                    _c("th"),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.licenses.monitoring, function (license, index) {
                    return _c("tr", { key: license.index }, [
                      _c("td", [_vm._v(_vm._s(license.nome_licenza))]),
                      _c("td", { staticClass: "text-left" }, [
                        _vm._v(_vm._s(license.description)),
                      ]),
                      _c("td", { staticClass: "text-right" }, [
                        _c(
                          "a",
                          {
                            staticClass: "badge inline bg-primary fg-white p-3",
                          },
                          [
                            _vm._v(
                              _vm._s(license.used_slots) +
                                "/" +
                                _vm._s(license.total_slots)
                            ),
                          ]
                        ),
                      ]),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "button flat-button",
                            attrs: {
                              title: _vm.$t(
                                "View and/or remove the computers included in the module"
                              ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.toggleDeviceInModule(
                                  "monitoring",
                                  index,
                                  license
                                )
                              },
                            },
                          },
                          [_c("span", { staticClass: "mif-pencil mif-2x" })]
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            )
          : _c("p", [_vm._v(_vm._s(_vm.$t("No license found")))]),
        _c("br"),
        _c("h4", { staticClass: "pt-2" }, [_vm._v(_vm._s(_vm.$t("Backup")))]),
        _vm.licenses != null
          ? _c(
              "table",
              { staticClass: "table condensed striped bg-white mt-2" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticStyle: { width: "20%" } }, [
                      _vm._v(_vm._s(_vm.$t("Name"))),
                    ]),
                    _c(
                      "th",
                      {
                        staticClass: "text-left",
                        staticStyle: { width: "50%" },
                      },
                      [_vm._v(_vm._s(_vm.$t("Description")))]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-right",
                        staticStyle: { width: "30%" },
                      },
                      [_vm._v(_vm._s(_vm.$t("Licenses Used/Total")))]
                    ),
                    _c("th"),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.licenses.backup, function (license, index) {
                    return _c("tr", { key: license.id }, [
                      _c("td", [_vm._v(_vm._s(license.nome_licenza))]),
                      _c("td", { staticClass: "text-left" }, [
                        _vm._v(_vm._s(license.description)),
                      ]),
                      _c("td", { staticClass: "text-right" }, [
                        _c(
                          "a",
                          {
                            staticClass: "badge inline bg-primary fg-white p-3",
                          },
                          [
                            _vm._v(
                              _vm._s(license.used_slots) +
                                "/" +
                                _vm._s(license.total_slots)
                            ),
                          ]
                        ),
                      ]),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "button flat-button",
                            attrs: {
                              title: _vm.$t(
                                "View and/or remove the computers included in the module"
                              ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.toggleDeviceInModule(
                                  "backup",
                                  index,
                                  license
                                )
                              },
                            },
                          },
                          [_c("span", { staticClass: "mif-pencil mif-2x" })]
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            )
          : _c("p", [_vm._v(_vm._s(_vm.$t("No license found")))]),
        _c("br"),
        _c("h4", { staticClass: "pt-2" }, [_vm._v(_vm._s(_vm.$t("Remote")))]),
        _vm.licenses != null
          ? _c(
              "table",
              { staticClass: "table condensed striped bg-white mt-2" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticStyle: { width: "20%" } }, [
                      _vm._v(_vm._s(_vm.$t("Name"))),
                    ]),
                    _c(
                      "th",
                      {
                        staticClass: "text-left",
                        staticStyle: { width: "50%" },
                      },
                      [_vm._v(_vm._s(_vm.$t("Description")))]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-right",
                        staticStyle: { width: "30%" },
                      },
                      [_vm._v(_vm._s(_vm.$t("Max concurrent sessions")))]
                    ),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.licenses.remote, function (license, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(_vm._s(license.nome_licenza))]),
                      _c("td", { staticClass: "text-left" }, [
                        _vm._v(_vm._s(license.description)),
                      ]),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(license.max_control_connections)),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            )
          : _c("p", [_vm._v(_vm._s(_vm.$t("No license found")))]),
        _c("br"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }