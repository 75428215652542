<template>
   

       <div class="">
            <a :title="!connectionsStates[pc.id_remote] ? $t('Start Iperius Remote (automatically downloads if needed)') : $t('Open a Remote connection')" class="button dropdown-toggle" :class="connectionsStates[pc.id_remote] == true ? 'bg-remote fg-white' : ''" >
                <!--span :class="connectionsStates[pc.id_remote] ? 'icon mif-2x mif-cast-connected' : 'icon mif-2x mif-cast'"></span-->
                <span  :class="wait?'mif-spinner3 ani-spin':connectionsStates[pc.id_remote]? 'icon mif-2x mif-cast-connected':'icon mif-2x mif-cast'"></span>
                
                <div class="text-left" v-if="complete=='true'">
                {{ $t("New Connection") }} 
                </div>
            </a>
            <ul class="d-menu border place-right text-left mt-2" data-role="dropdown">
                <li  class="text-bold" v-if="connectionsStates[pc.id_remote]">
                    <a @click="$root.getOTP(pc.id)" :title="$t('The Iperius Remote client will launch and initiate a remote session to the host.', {0: pc.id_remote.replace(/(\d{3})(?=\d)/g, '$1 ')})" 
                        :class="connectionsStates[pc.id_remote]==true ? 'secondary' : 'fg-gray disabled'" >
                        <span class="icon mif-2x mif-screen_share"></span>{{ $t("Connect to {0}", {0: pc.id_remote.replace(/(\d{3})(?=\d)/g, '$1 ')}) }} 
                    </a>
                </li>
               
                <li v-if="!connectionsStates[pc.id_remote] && pc.backup_license_status !== 0">
                    <a 
                        @click="downloadandRunIR4(pc)" 
                        :title="$t('The Iperius Remote client will be started on the remote host (automatically downloads if needed)')" 
                        class="fg-dark"
                    >
                        <span class="icon mif-2x mif-cast-connected"></span> {{ $t("Enable remote connection") }}
                    </a>
                </li>

                <li v-if="!connectionsStates[pc.id_remote] && pc.backup_license_status === 0" class="text-center p-4">
                    <span class="mif-lock fg-red mif-2x mb-2"></span>
                    <span class="d-block">
                        {{ $t("To automatically install and start Iperius Remote on this device, the Iperius One Agent must be installed first.") }}
                    </span>
                    <a href="https://www.iperius.com" class="fg-primary text-bold mt-2" target="_blank">
                        {{ $t("Click here to purchase Iperius One") }}
                    </a>
                </li>
                <li v-if="connectionsStates[pc.id_remote]">
                    <a @click="restartIR4(pc)" :title="$t('The Iperius Remote client will restart on the remote host.')" :class="connectionsStates[pc.id_remote]==true ? 'fg-dark' : 'fg-gray disabled'" >
                        <span class="icon mif-redo"></span> {{ $t("Restart the remote client") }}                        
                    </a>
                </li>
                <li v-if="connectionsStates[pc.id_remote]">
                    <a @click="closeIR4(pc)" :title="$t('The Iperius Remote client will close on the remote host.')" :class="connectionsStates[pc.id_remote]==true ? 'fg-dark' : 'fg-gray disabled'" >
                        <span class="icon mif-2x mif-cast"></span> {{ $t("Terminate the remote client") }}
                    </a>
                </li>
                <li v-if="connectionsStates[pc.id_remote] && pc.version_remote < $session.getLastRemoteVersion()">
                    <a @click="updateIR4(pc)" :title="$t('The Iperius Remote client will update on the remote host.')" :class="connectionsStates[pc.id_remote]==true ? 'fg-dark' : 'fg-gray disabled'" >
                        <span class="icon mif-2x mif-refresh"></span> {{ $t("Update the remote client") }}
                    </a>
                </li>
            </ul> 
        </div>       
</template>
<script>

import { waitSeconds } from '../../utils/utilitiesmodule';

export default {
    name: "PCRemoteLink",
    props: {
        complete:{
            type:String,
        },
        pc: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            wait: false,
            connectionsStates: {},
        }
    },
    created() {
        this.$parent.$on("updateConnectionsStates", newState => {
            this.connectionsStates = newState;
        });

        if (Object.keys(this.connectionsStates).length === 0) {
            const sessionConnectionsStates = this.$session.getRemoteConnectionStates();
            if (sessionConnectionsStates) {
                this.connectionsStates = sessionConnectionsStates; // Assegna il valore dalla sessione
        }
    }

    },
    methods: {

        async updateIR4(pc){

            let self = this;

            self.wait = true;

            // [20-11-2024] l'update di un client iperius remote avviene con chiamata alle api come per l'area admin di remote
            // Elixir invierà poi il command di update al client tramite socket
            await this.$api.updateRemote(pc.id_remote).then(async response => {
                if (response.status == 200) {
                    
                    this.$root.toast(this.$t('Update command sent successfully.'), 3000, "success");

                    //await waitSeconds(3); // diamo il tempo ad iperius remote di riavviarsi

                    /*var pcStat = this.connectionsStates;
                    if (pcStat.hasOwnProperty(pc.id_remote)) {
                        this.$set(pcStat, pc.id_remote, true); // Aggiorna il valore
                        self.$session.setRemoteConnectionStates(pcStat);
                    }*/
                };
                self.wait = false;
            });
        },

        async restartIR4(pc){

            let self = this;

            self.wait = true;

            // [20-11-2024] il restart di un client iperius remote avviene con chiamata alle api come per l'area admin di remote
            // Elixir invierà poi il command di restart al client tramite socket
            await this.$api.restartRemote(pc.id_remote).then(async response => {
                if (response.status == 200) {
                    
                    this.$root.toast(this.$t('Restart command sent successfully.'), 3000, "success");

                    await waitSeconds(3); // diamo il tempo ad iperius remote di riavviarsi

                    /*var pcStat = this.connectionsStates;
                    if (pcStat.hasOwnProperty(pc.id_remote)) {
                        this.$set(pcStat, pc.id_remote, true); // Aggiorna il valore
                        self.$session.setRemoteConnectionStates(pcStat);
                    }*/
                };
                self.wait = false;
            });
        },

        async closeIR4(pc){

            let self = this;

            self.wait = true;

            // [20-11-2024] il close di un client iperius remote avviene con chiamata alle api come per l'area admin di remote
            // Elixir invierà poi il command di close al client tramite socket
            await this.$api.closeRemote(pc.id_remote).then(async response => {
                if (response.status == 200) {
                    
                    this.$root.toast(this.$t('Terminate command sent successfully.'), 3000, "success");

                    /*await waitSeconds(5); // diamo il tempo ad iperius remote di chiudersi

                    var pcStat = this.connectionsStates;
                    if (pcStat.hasOwnProperty(pc.id_remote)) {
                        this.$set(pcStat, pc.id_remote, false); // Aggiorna il valore
                        self.$session.setRemoteConnectionStates(pcStat);
                    }*/
                };

                self.wait = false;                
            });
        },

        async downloadandRunIR4(pc){

            let self = this;

            this.wait = true;            

            this.$root.socket("SETPC", this.pc.id) // non essendo "dentro" la gestione di un singolo pc ma sulla lista dei computer devo settare l'id del computer
                .then(() => {
                    return this.$root.socket("DOWNLOADREMOTE", pc.id); // chiamo il metodo per il download
                })
                .then(async response => {
                    if(response==true){ 

                        /*await waitSeconds(5); // diamo il tempo ad iperius remote di avviarsi e connettersi

                        var pcStat = this.connectionsStates;
                        if (pcStat.hasOwnProperty(pc.id_remote)) {
                            this.$set(pcStat, pc.id_remote, true); // Aggiorna il valore
                            self.$session.setRemoteConnectionStates(pcStat);
                        }*/

                        
                    } 
                    this.wait = false;
                    //this.$root.toast(this.$t("The download of Iperius Remote on the remote host has started successfully. Please wait a few minutes for Iperius Remote to come online."), 3000, "success");
                })
                .catch(error => {
                    self.$root.toast(this.$t("An error occurred:"), error);
                    this.wait = false;                    
                });

        },
    }
    
}
</script>