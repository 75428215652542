<template>
    <div class="d-flex flex-align-center flex-column flex-justify-center py-10"> 
        <span class="mif-info mif-4x fg-gray p-3" />
        <h6 class="text-center text-muted">{{message}}</h6>
    </div>   
</template>
<script>
export default {
    name : "NoDataAvailable",
    props : {
        message : {
            type : String,
            required : true
        }
    }
}
</script>