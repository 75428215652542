var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex flex-column", staticStyle: { height: "89vh" } },
    [
      _c("Navigation", { attrs: { pageName: "dashboard" } }),
      _vm.fullStats == null
        ? _c("div", { staticClass: "row m-5 pt-5" }, [_vm._m(0)])
        : _vm._e(),
      _vm.fullStats != null
        ? _c("div", { staticClass: "row pl-5 pr-5 w-100 overflow" }, [
            _c("div", { staticClass: "w-100" }, [
              _vm.fullStats != null
                ? _c(
                    "div",
                    {
                      staticClass: "row card rounded w-100 p-4",
                      attrs: {
                        "data-role": "collapse",
                        "data-toggle-element": "#fullstats",
                        "data-collapsed": "false",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "cell-lg-3 cell-md-4 col-sm-6" },
                        [
                          _c("div", { staticClass: "p-1 w-100" }, [
                            _c("div", { staticClass: "content row" }, [
                              _c("h6", { staticClass: "col-12 text-center" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.fullStats.computer_stats.nOfTotalPcs
                                    ) +
                                    " " +
                                    _vm._s(_vm.$t("Devices"))
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "chart" },
                                [
                                  _c("NestedDoughnutChart", {
                                    attrs: {
                                      fullStats: _vm.fullStats,
                                      typeChart: "Devices",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "cell-lg-3 cell-md-4 col-sm-6" },
                        [
                          _c("div", { staticClass: "p-1 w-100" }, [
                            _c("div", { staticClass: "content row" }, [
                              _c("h6", { staticClass: "col-12 text-center" }, [
                                _vm._v(_vm._s(_vm.$t("Backup Jobs Status"))),
                              ]),
                              _vm.fullStats?.backup_stats.result_error +
                                _vm.fullStats?.backup_stats.result_ok +
                                _vm.fullStats?.backup_stats.result_warning >
                              0
                                ? _c(
                                    "div",
                                    { staticClass: "chart" },
                                    [
                                      _c("NestedDoughnutChart", {
                                        attrs: {
                                          fullStats: _vm.fullStats,
                                          typeChart: "Backups",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "chart text-center" },
                                    [
                                      _c("NoDataAvailable", {
                                        attrs: { message: _vm.$t("No Alerts") },
                                      }),
                                    ],
                                    1
                                  ),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "cell-lg-3 cell-md-4 col-sm-6" },
                        [
                          _c("div", { staticClass: "p-1 w-100" }, [
                            _c("div", { staticClass: "content row" }, [
                              _c("h6", { staticClass: "col-12 text-center" }, [
                                _vm._v(_vm._s(_vm.$t("Disk Status"))),
                              ]),
                              _vm.fullStats?.backup_stats.result_error +
                                _vm.fullStats?.backup_stats.result_ok +
                                _vm.fullStats?.backup_stats.result_warning >
                              0
                                ? _c(
                                    "div",
                                    { staticClass: "chart" },
                                    [
                                      _c("NestedDoughnutChart", {
                                        attrs: {
                                          fullStats: _vm.fullStats,
                                          typeChart: "Disk",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "chart text-center" },
                                    [
                                      _c("NoDataAvailable", {
                                        attrs: { message: _vm.$t("No Alerts") },
                                      }),
                                    ],
                                    1
                                  ),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "cell-lg-3 cell-md-4 col-sm-6" },
                        [
                          _c("div", { staticClass: "p-1 w-100" }, [
                            _c("div", { staticClass: "content row" }, [
                              _c("h6", { staticClass: "col-12 text-center" }, [
                                _vm._v(_vm._s(_vm.$t("Security Status"))),
                              ]),
                              _vm.fullStats?.backup_stats.result_error +
                                _vm.fullStats?.backup_stats.result_ok +
                                _vm.fullStats?.backup_stats.result_warning >
                              0
                                ? _c(
                                    "div",
                                    { staticClass: "chart" },
                                    [
                                      _c("NestedDoughnutChart", {
                                        attrs: {
                                          fullStats: _vm.fullStats,
                                          typeChart: "Security",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "chart text-center" },
                                    [
                                      _c("NoDataAvailable", {
                                        attrs: { message: _vm.$t("No Alerts") },
                                      }),
                                    ],
                                    1
                                  ),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "cell-lg-3 cell-md-4 col-sm-6" },
                        [
                          _c("div", { staticClass: "p-1 w-100" }, [
                            _c("div", { staticClass: "content row" }, [
                              _c("h6", { staticClass: "col-12 text-center" }, [
                                _vm._v(_vm._s(_vm.$t("OS Patches Status"))),
                              ]),
                              _vm.fullStats?.backup_stats.result_error +
                                _vm.fullStats?.backup_stats.result_ok +
                                _vm.fullStats?.backup_stats.result_warning >
                              0
                                ? _c(
                                    "div",
                                    { staticClass: "chart" },
                                    [
                                      _c("NestedDoughnutChart", {
                                        attrs: {
                                          fullStats: _vm.fullStats,
                                          typeChart: "Patch",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "chart text-center" },
                                    [
                                      _c("NoDataAvailable", {
                                        attrs: { message: _vm.$t("No Alerts") },
                                      }),
                                    ],
                                    1
                                  ),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "cell-lg-3 cell-md-4 col-sm-6" },
                        [
                          _c("div", { staticClass: "p-1 w-100" }, [
                            _c("div", { staticClass: "content row" }, [
                              _c("h6", { staticClass: "col-12 text-center" }, [
                                _vm._v(_vm._s(_vm.$t("Stats per Date"))),
                              ]),
                              Object.values(_vm.fullStats?.remote_stats)
                                .length > 0
                                ? _c(
                                    "div",
                                    { staticClass: "chart" },
                                    [
                                      _c("apexchart", {
                                        attrs: {
                                          type: "line",
                                          options: _vm.remoteOptions,
                                          series: _vm.remoteOptions.series,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "chart text-center" },
                                    [
                                      _c("NoDataAvailable", {
                                        attrs: { message: _vm.$t("No Stats") },
                                      }),
                                    ],
                                    1
                                  ),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "cell-lg-3 cell-md-4 col-sm-6" },
                        [
                          _c("div", { staticClass: "p-1 w-100" }, [
                            _c("div", { staticClass: "content row" }, [
                              _c("h6", { staticClass: "col-12 text-center" }, [
                                _vm._v(_vm._s(_vm.$t("Stats per User"))),
                              ]),
                              Object.values(_vm.fullStats?.remote_stats)
                                .length > 0
                                ? _c(
                                    "div",
                                    { staticClass: "chart" },
                                    [
                                      _c("apexchart", {
                                        attrs: {
                                          type: "line",
                                          options: _vm.remoteOptions2,
                                          series: _vm.remoteOptions2.series,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "chart text-center" },
                                    [
                                      _c("NoDataAvailable", {
                                        attrs: { message: _vm.$t("No Stats") },
                                      }),
                                    ],
                                    1
                                  ),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "cell-lg-3 cell-md-4 col-sm-6" },
                        [
                          _c("div", { staticClass: "p-1 w-100" }, [
                            _c("div", { staticClass: "content row" }, [
                              _c("h6", { staticClass: "col-12 text-center" }, [
                                _vm._v(_vm._s(_vm.$t("Assigned Licenses"))),
                              ]),
                              Object.values(_vm.fullStats?.remote_stats)
                                .length > 0
                                ? _c(
                                    "div",
                                    { staticClass: "chart" },
                                    [
                                      _c("apexchart", {
                                        attrs: {
                                          type: "bar",
                                          options: _vm.options,
                                          series: _vm.options.series,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "chart text-center" },
                                    [
                                      _c("NoDataAvailable", {
                                        attrs: { message: _vm.$t("No Stats") },
                                      }),
                                    ],
                                    1
                                  ),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _c(
              "ul",
              {
                staticClass: "mt-5 pr-2 border-bottom-none",
                attrs: {
                  "data-role": "tabs",
                  "data-expand": "true",
                  "data-cls-tabs": "bg-transparent",
                },
              },
              [
                _c("li", { staticClass: "border-radius" }, [
                  _c("a", { attrs: { href: "#all_backups" } }, [
                    _c("label", { staticClass: "pr-1" }, [
                      _vm.jobs == null
                        ? _c("span", {
                            staticClass: "mif-spinner2 fg-primary ani-spin",
                          })
                        : _vm._e(),
                      _c("span", { staticClass: "no-wrap" }, [
                        _vm._v(" " + _vm._s(_vm.$t("Latest Backups"))),
                      ]),
                    ]),
                  ]),
                ]),
                _c("li", { staticClass: "border-radius" }, [
                  _c("a", { attrs: { href: "#all_sessions" } }, [
                    _c("label", { staticClass: "pr-1" }, [
                      _vm.sessions == null
                        ? _c("span", {
                            staticClass: "mif-spinner2 fg-primary ani-spin",
                          })
                        : _vm._e(),
                      _c("span", { staticClass: "no-wrap" }, [
                        _vm._v(" " + _vm._s(_vm.$t("Latest Remote Sessions"))),
                      ]),
                    ]),
                  ]),
                ]),
                _c("li", { staticClass: "border-radius" }, [
                  _c("a", { attrs: { href: "#activities" } }, [
                    _c("label", { staticClass: "pr-1" }, [
                      _vm.events == null
                        ? _c("span", {
                            staticClass: "mif-spinner2 fg-primary ani-spin",
                          })
                        : _vm._e(),
                      _c("span", { staticClass: "no-wrap" }, [
                        _vm._v(" " + _vm._s(_vm.$t("Activities"))),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "border overflow w-100",
                attrs: { id: "activities" },
              },
              [
                _c("div", { staticClass: "bg-white" }, [
                  _vm.events
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-justify-start p-3 bordered border border-bottom-none",
                        },
                        [
                          _c("div", { staticClass: "col-auto pr-2" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filters.activitystatus,
                                    expression: "filters.activitystatus",
                                  },
                                ],
                                staticClass:
                                  "bg-graywhite pl-2 select text-small",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.filters,
                                      "activitystatus",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v(_vm._s(_vm.$t("All Events"))),
                                ]),
                                _vm._l(_vm.types, function (value) {
                                  return _c(
                                    "option",
                                    {
                                      key: value.id,
                                      domProps: { value: value.id },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t(value.name)) + " "
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.events != null && _vm.events.length == 0
                    ? _c(
                        "div",
                        { staticClass: "p-6 text-center" },
                        [
                          _c("NoDataAvailable", {
                            attrs: {
                              message: _vm.$t("No Activities available"),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.events && _vm.types
                    ? _c(
                        "table",
                        {
                          staticClass:
                            "striped compact table w-100 bg-white bordered border border-top-none",
                          attrs: {
                            id: "event-list",
                            "data-check": false,
                            "data-check-name": "chkBox",
                            "data-show-search": "true",
                            "data-show-activity": "false",
                            "data-show-rows-steps": "false",
                            "data-show-pagination": "true",
                            "data-cell-wrapper": "false",
                            "data-table-search-title":
                              _vm.$t("Search Activity"),
                            "data-cls-search":
                              "w-100-sm w-25-xl w-25-lg w-50-md p-4",
                            "data-rows": "20",
                            "data-horizontal-scroll": "false",
                          },
                        },
                        [
                          _c("thead", { staticClass: "mb-0" }, [
                            _c("tr", { staticClass: "mb-3" }, [
                              _c("th", [_vm._v(_vm._s(_vm.$t("Message")))]),
                              _c("th", { attrs: { "data-sortable": "true" } }, [
                                _vm._v(_vm._s(_vm.$t("Event"))),
                              ]),
                              _c("th", { attrs: { "data-sortable": "true" } }, [
                                _vm._v(_vm._s(_vm.$t("Created at"))),
                              ]),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.events, function (event) {
                              return _vm.filters.activitystatus == "" ||
                                event.type == _vm.filters.activitystatus
                                ? _c(
                                    "tr",
                                    {
                                      class: { "": !event.is_read },
                                      attrs: { id: event.id },
                                    },
                                    [
                                      _c("td", [
                                        _c("b", [
                                          _vm._v(_vm._s(_vm.$t(event.msg))),
                                        ]),
                                        _c("br"),
                                        _vm._v(_vm._s(_vm.$t(event.subtitle))),
                                      ]),
                                      _c("td", { staticClass: "text-center" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "badge inline bg-cobalt fg-white p-1 mr-1",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.types.find(function (el) {
                                                    return el.id == event.type
                                                      ? el
                                                      : null
                                                  }).name
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(_vm.formatDate(event.dt_ins)) +
                                            " "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "(" +
                                            _vm._s(_vm.fromNow(event.dt_ins)) +
                                            ")"
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e()
                            }),
                            0
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "border overflow w-100",
                attrs: { id: "all_backups" },
              },
              [
                _vm.jobs
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "bg-white d-flex flex-justify-start p-3 bg-white bordered border border-bottom-none",
                      },
                      [
                        _c("div", { staticClass: "pl-0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.filters.backupName,
                                expression: "filters.backupName",
                                modifiers: { trim: true },
                              },
                              { name: "fsitem", rawName: "v-fsitem" },
                            ],
                            staticClass: "input bg-graywhite pl-4 text-small",
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("Backup Name"),
                            },
                            domProps: { value: _vm.filters.backupName },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.filters,
                                  "backupName",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "pr-2 pl-2" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filters.numrows,
                                  expression: "filters.numrows",
                                },
                              ],
                              staticClass:
                                "bg-graywhite pl-2 select text-small",
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.filters,
                                    "numrows",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "25" } }, [
                                _vm._v("25 " + _vm._s(_vm.$t("rows"))),
                              ]),
                              _c("option", { attrs: { value: "50" } }, [
                                _vm._v("50 " + _vm._s(_vm.$t("rows"))),
                              ]),
                              _c("option", { attrs: { value: "100" } }, [
                                _vm._v("100 " + _vm._s(_vm.$t("rows"))),
                              ]),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "pr-2" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filters.lastresult,
                                  expression: "filters.lastresult",
                                },
                              ],
                              staticClass:
                                "bg-graywhite pl-2 select text-small",
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.filters,
                                    "lastresult",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v(_vm._s(_vm.$t("All"))),
                              ]),
                              _vm._l(
                                _vm.filters.resultList,
                                function (key, value) {
                                  return _c(
                                    "option",
                                    { key: key, domProps: { value: key } },
                                    [_vm._v(_vm._s(_vm.$t(value)) + " ")]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]),
                        _c("div", {}, [
                          _c(
                            "button",
                            {
                              staticClass: "button primary",
                              on: {
                                click: function ($event) {
                                  return _vm.getJobs()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("Filter")) + " ")]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "table",
                  {
                    staticClass:
                      "striped compact table w-100 bg-white bordered border border-top-none overflow",
                    attrs: { id: "backup-list" },
                  },
                  [
                    _c("thead", { staticClass: "mb-0" }, [
                      _c("tr", { staticClass: "mb-3" }, [
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            staticStyle: {
                              "vertical-align": "middle",
                              width: "10px",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Average Result")))]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            staticStyle: {
                              "vertical-align": "middle",
                              width: "10px",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Last Result")))]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-left",
                            staticStyle: {
                              "vertical-align": "middle",
                              width: "100px",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Backup Name")))]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            staticStyle: {
                              "vertical-align": "middle",
                              width: "50px",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Type")))]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-left",
                            staticStyle: { "vertical-align": "middle" },
                          },
                          [_vm._v(_vm._s(_vm.$t("Device")))]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-right",
                            staticStyle: {
                              "vertical-align": "middle",
                              "white-space": "nowrap",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("Started")) +
                                "/" +
                                _vm._s(_vm.$t("Ended")) +
                                "/" +
                                _vm._s(_vm.$t("Duration"))
                            ),
                          ]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            staticStyle: {
                              "vertical-align": "middle",
                              "white-space": "nowrap",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("N. of files")) + " ")]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            staticStyle: { "vertical-align": "middle" },
                          },
                          [_vm._v(_vm._s(_vm.$t("Size")))]
                        ),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      [
                        !_vm.jobs
                          ? _c("tr", { staticClass: "pt-10" }, [_vm._m(1)])
                          : _vm._e(),
                        _vm.jobs != null && Object.keys(_vm.jobs).length == 0
                          ? _c("tr", { staticClass: "pt-10" }, [
                              _c(
                                "td",
                                {
                                  staticClass: "p-6 text-center",
                                  attrs: { colspan: "12" },
                                },
                                [
                                  _c("NoDataAvailable", {
                                    attrs: {
                                      message: _vm.$t("No Backups available"),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm._l(_vm.jobs, function (backup, index) {
                          return _c("tr", { key: backup.id + "-" + index }, [
                            _c(
                              "td",
                              { staticClass: "text-center" },
                              [
                                _c("LastResult", {
                                  attrs: { lastresult: backup.average_result },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              { staticClass: "text-center" },
                              [
                                _c("LastResult", {
                                  attrs: { lastresult: backup.last_result },
                                }),
                              ],
                              1
                            ),
                            _c("td", { staticClass: "text-left overflow" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "button flat-button text-bold",
                                  attrs: { title: backup.name },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openBackupView(
                                        backup.id,
                                        backup.id_computer
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        backup.name.length > 25
                                          ? backup.name.substring(0, 25) + "..."
                                          : backup.name
                                      )
                                  ),
                                ]
                              ),
                            ]),
                            _c("td", { staticClass: "text-right" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "badge inline bg-cobalt fg-white p-1 mr-1",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$platformsTypesInfo[
                                        backup.type
                                      ].name.toUpperCase()
                                    ) + " "
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "td",
                              { staticClass: "text-left" },
                              [
                                _c("PCLink", {
                                  attrs: {
                                    pc: _vm.computers[backup.id_computer],
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("td", { staticClass: "text-right" }, [
                              backup.dt_start_utc != "" &&
                              backup.dt_start_utc != null
                                ? _c("label", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatDate(backup.dt_start_utc)
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _c("br"),
                              backup.dt_end_utc != "" &&
                              backup.dt_end_utc != null
                                ? _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.formatDate(backup.dt_end_utc))
                                    ),
                                  ])
                                : _vm._e(),
                              _c("br"),
                              backup.dt_end_utc != "" &&
                              backup.dt_end_utc != null
                                ? _c("label", { staticClass: "text-bold" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.diffDate(
                                          backup.dt_start_utc,
                                          backup.dt_end_utc
                                        )
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                " " +
                                  _vm._s(backup.last_backup_copiedfiles) +
                                  " "
                              ),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("prettyBytes")(
                                      backup.last_backup_size
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "border overflow w-100",
                attrs: { id: "all_sessions" },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticClass:
                      "h-100 m-0 op-dark-hi p-0 pos-absolute pos-center w-100 z-2",
                  },
                  [
                    _c("span", {
                      staticClass:
                        "ani-spin fg-white mif-5x mif-spinner2 mt-13-minus pos-center",
                    }),
                  ]
                ),
                _vm.sessions
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "bg-white d-flex flex-justify-end p-3 bg-white bordered border border-bottom-none",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "col-auto" },
                          [
                            _c("router-link", { attrs: { to: "/sessions" } }, [
                              _c("button", { staticClass: "button primary" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("Show all remote sessions")) +
                                    " "
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "bg-white bordered border border-top-none" },
                  [
                    _vm.sessions != null && _vm.sessions.length == 0
                      ? _c(
                          "div",
                          { staticClass: "p-6" },
                          [
                            _c("NoDataAvailable", {
                              attrs: {
                                message: _vm.$t(
                                  "No sessions available for the selected dates"
                                ),
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "table",
                          {
                            staticClass:
                              "striped compact table w-100 bg-white bordered border border-top-none",
                            attrs: { id: "session-list" },
                          },
                          [
                            _c("thead", { staticClass: "mb-0" }, [
                              _c("tr", {}, [
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-left no-wrap",
                                    staticStyle: { "vertical-align": "left" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Operator")))]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-left no-wrap",
                                    staticStyle: { "vertical-align": "left" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("From Device")))]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-left no-wrap",
                                    staticStyle: { "vertical-align": "middle" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("To Device")))]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-center",
                                    staticStyle: { "vertical-align": "middle" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Organization")))]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-right no-wrap",
                                    staticStyle: { "vertical-align": "middle" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("Started")) +
                                        " / " +
                                        _vm._s(_vm.$t("Ended")) +
                                        " / " +
                                        _vm._s(_vm.$t("Duration"))
                                    ),
                                  ]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-center",
                                    staticStyle: { "vertical-align": "middle" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Notes")))]
                                ),
                                _c("th", {
                                  staticClass: "text-center",
                                  staticStyle: { "vertical-align": "middle" },
                                }),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              [
                                !_vm.sessions
                                  ? _c("tr", { staticClass: "pt-10" }, [
                                      _vm._m(2),
                                    ])
                                  : _vm._e(),
                                _vm._l(_vm.sessions, function (session, index) {
                                  return [
                                    _c("tr", [
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              session.firstname +
                                                " " +
                                                session.lastname
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "text-left no-wrap" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                session.name_computer_control
                                              ) +
                                              " (" +
                                              _vm._s(
                                                session.id_remote_control
                                              ) +
                                              ") "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "text-left no-wrap" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                session.name_computer_host
                                              ) +
                                              " (" +
                                              _vm._s(session.id_remote_host) +
                                              ") "
                                          ),
                                        ]
                                      ),
                                      _c("td", { staticClass: "text-center" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(session.organization_name) +
                                            " "
                                        ),
                                      ]),
                                      _c("td", { staticClass: "text-right" }, [
                                        session.dt_start != "" &&
                                        session.dt_start != null
                                          ? _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatDate(
                                                    session.dt_start
                                                  )
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                        _c("br"),
                                        session.dt_end != "" &&
                                        session.dt_end != null
                                          ? _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatDate(session.dt_end)
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                        _c("br"),
                                        session.dt_end != "" &&
                                        session.dt_end != null
                                          ? _c(
                                              "label",
                                              { staticClass: "text-bold" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.diffDate(
                                                      session.dt_start,
                                                      session.dt_end
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                      _c("td", { staticClass: "text-center" }, [
                                        session.notes[0]
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model.trim",
                                                  value: session.notes[0].note,
                                                  expression:
                                                    "session.notes[0].note",
                                                  modifiers: { trim: true },
                                                },
                                              ],
                                              attrs: {
                                                type: "text",
                                                "data-role": "input",
                                              },
                                              domProps: {
                                                value: session.notes[0].note,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    session.notes[0],
                                                    "note",
                                                    $event.target.value.trim()
                                                  )
                                                },
                                                blur: function ($event) {
                                                  return _vm.$forceUpdate()
                                                },
                                              },
                                            })
                                          : _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model.trim",
                                                  value: session.notedesc,
                                                  expression:
                                                    "session.notedesc",
                                                  modifiers: { trim: true },
                                                },
                                              ],
                                              attrs: {
                                                type: "text",
                                                "data-role": "input",
                                              },
                                              domProps: {
                                                value: session.notedesc,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    session,
                                                    "notedesc",
                                                    $event.target.value.trim()
                                                  )
                                                },
                                                blur: function ($event) {
                                                  return _vm.$forceUpdate()
                                                },
                                              },
                                            }),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "button",
                                            attrs: {
                                              title: _vm.$t("Save note"),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.saveNote(session)
                                              },
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass:
                                                "icon mif-2x mif-floppy-disk2",
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]
                                }),
                              ],
                              2
                            ),
                          ]
                        ),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "h-100 w-100 z-2" }, [
      _c("span", {
        staticClass: "ani-spin fg-primary mif-5x mif-spinner2 pos-center",
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", { staticClass: "p-6", attrs: { colspan: "8" } }, [
      _c("div", { staticClass: "d-flex flex-justify-center p-5" }, [
        _c("span", { staticClass: "mif-spinner2 fg-primary ani-spin mif-3x" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", { staticClass: "p-6", attrs: { colspan: "6" } }, [
      _c("div", { staticClass: "d-flex flex-justify-center p-5" }, [
        _c("span", { staticClass: "mif-spinner2 fg-primary ani-spin mif-3x" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }