import api from "../../../router/api";
import { isValid, waitSeconds, cookieManager } from "../../utilitiesmodule";
import { PlatformsTypes } from "../../objects";
import { createAppObj, getDefaultAzureCredentials, getDefaultScopes, requiredResourceAccess } from "../clientsConfig/azureConfig";
import { GraphClient } from "./graphClient";

//import { PublicClientApplication } from "@azure/msal-browser";

export class AzureClient {

    /**
     * il service account deve contenere : 
        - options.appName : ..., (required)
                oppure
        - options.tenant_id: ..., (required)
        - client_id: ..., (appID) (required)
        - client_secret : ... (optional)
        - refresh_token: ..., (optional)
    }
*/
    constructor(serviceAccount) {
        this.serviceAccount=serviceAccount;
    }


    async getTokens() {
        //Step 1 : Chiedo il nuovo token
        //Se è il primo avvio, si richiederà il token con l'authentication code
        //Se non è il primo avvio, è presente sicuramente un token, quindi viene provato
        //Se il token salvato fallisce ed è presente un refresh token, viene richiesto tramite quest'ultimo
        //Se il token salvato fallisce ed è presente un client secret, viene richiesto tramite quest'ultimo
        //Se il token salvato fallisce, viene richiesto con l'authentication code
        var ok=await this._getTokens();

        if (!ok) {
            return false;
        }

        /***************************TENANTID****************************************************/
        //Step 2 : Se non ho il tenantID, lo recupero
        // if (ok&&!isValid(this.serviceAccount.options.tenant_id)) {
        //     ok=await this._getTenantID(this.serviceAccount.token);
        //     // Step 2.1 : Nel caso in cui la richiesta non va a buon fine, resetto tutti i campi della richiesta e la ritorno
        //     if (!ok) {
        //         this.serviceAccount.token=null;
        //         this.serviceAccount.refresh_token=null
        //         return false;
        //     }
        // }


        //***************************CLIENTID****************************************************/
        //Step 7 : Se non è stato passato un client id, lo ricavo prendendo l'applicazione con il nome passato
        // if (ok&&!isValid(this.serviceAccount.client_id)) {
        //     ok=await this._getApp(this.serviceAccount.options["objID"], null, this.serviceAccount.options["appName"], this.serviceAccount.token);
        //     //Step 7.1 : Se non esiste l'applicazione con il nome passato, creo l'applicazione
        //     if (!ok) {
        //         ok=await this._createNewApp(this.serviceAccount.options["appName"], this.serviceAccount.token);
        //     }
        // }

        /***************************CLIENT SECRET*************************************************************************************/
        if (ok&&!isValid(this.serviceAccount.client_secret)) {
            ok=await this._createClientSecret();
            if (!ok)
                return false;
        }

        if (!ok) {
            return false;
        }

        /***************************REDIRECT URIS + PERMESSI*************************************************************************************/
        ok=await this.checkChanges();

        if (!ok) {
            return false;
        }
        
        if (this.serviceAccount.type==PlatformsTypes.Cloud_OneDrive&&!isValid(this.serviceAccount.options.userID)) {
            var graph=new GraphClient(this.serviceAccount);
            var user=await graph.getUserByEmail(this.serviceAccount.username);
            if (user==null)
                return false;
            this.serviceAccount.options["userID"]=user.resID;
            var drive=await graph.getDriveByUserID(user.resID);
            if (drive==null)
                return false;
            this.serviceAccount.options["driveID"]=drive.id;
        }

        return ok;
    }

    //Nuova versione in cui l'utente
    // 1 - Ha già registrato manualmente l'app
    // 2 - ha fornito username, appname, clientid, objID e tenantid
    async _getTokens(firstAccess=true, tryAccessToken=true, tryRefreshToken=true, tryClientSecret=true) {
        var ok=false;
        var hasTenantID=isValid(this.serviceAccount.options.tenant_id);
        var hasToken=isValid(this.serviceAccount.token);
        var hasRefreshToken=isValid(this.serviceAccount.refresh_token);
        var hasAppID=isValid(this.serviceAccount.client_id);
        var hasClientSecret=isValid(this.serviceAccount.client_secret);

        /**********************************TOKEN********************************************************/
        if (hasToken) {
            ok=await this._getTenantID(this.serviceAccount.token);
            if (ok) {
                return true;
            }
            return await this._getTokens();
        }

        /**********************************REFRESH TOKEN********************************************************/
        if (!hasToken&&hasRefreshToken) {
            ok=await this._getTokensWithRefreshToken(this.serviceAccount.options.tenant_id, this.serviceAccount.client_id, this.serviceAccount.refresh_token)
            if (ok) {
                return ok;
            }
            return await this._getTokens();
        }

        /**********************************CLIENT SECRET********************************************************/
        //Step 2 : Se non ho alcun token ma ho il client secret, provo la richiesta
        if (!hasToken&&!hasRefreshToken&&hasClientSecret) {
            ok=await this._getAccessTokenWithAppIDAndClientSecret(this.serviceAccount.options.tenant_id, this.serviceAccount.client_id, this.serviceAccount.client_secret);
            if (ok) {
                return ok;
            }
            return await this._getTokens();
        }

        /**********************************TENANT ID + APPID********************************************************/
        //Step 1 : Se non ho alcun token, lo prendo tramite l'autentication code
        if (!hasToken&&!hasRefreshToken&&!hasClientSecret&&hasTenantID&&hasAppID) {
            ok=await this._getTokensWithTenantIDAndAppID(this.serviceAccount.options.tenant_id, this.serviceAccount.client_id);
            return ok;
        }

        // if (!hasToken&&!hasRefreshToken&&!hasClientSecret&&!hasTenantID&&!hasAppID) {
        //     var azureCredentials=getDefaultAzureCredentials();
        //     ok=await this._getTokensWithTenantIDAndAppID(azureCredentials.tenantID, azureCredentials.appID);
        //     if (!ok) {
        //         this.serviceAccount.token=null;
        //         this.serviceAccount.refresh_token=null
        //         return false;
        //     }
        //     return ok;
        // }

        return false;
    }


    async _getTokensWithTenantIDAndAppID(tenantID, appID) {
        //Step 1 : Acquisisco l'authorization code
        var authCode=await this._getAuthCode(tenantID, appID);

        if (authCode==null) return false;

        var ok=await this._getTokensWithAuthorizationCode(tenantID, appID, authCode);

        return ok;
    }

    async _getAuthCode(tenantID, appID) {
        cookieManager.deleteCookie("authCode");
        var scope="openid profile email offline_access https://graph.microsoft.com/Application.ReadWrite.All";// https://graph.microsoft.com/.default" /*getDefaultScopes().join(" ")*/;
        var queryParams=[
            "client_id="+appID,
            "scope="+encodeURIComponent(scope),
            "redirect_uri="+encodeURIComponent(this.serviceAccount.url),
            "response_mode=query",
            "response_type=code"
        ];

        var codeVerifier=generateCodeVerifier();
        var codeChallenge=await generateCodeChallenge(codeVerifier);
        sessionStorage.setItem("code_verifier", codeVerifier);
        queryParams.push(...[
            "code_challenge="+codeChallenge,
            "code_challenge_method=S256"
        ]);

        //Step 2 : Richiedo l'authorization code. Questo verrà concatenato all'url del redirect uri.
        var url="https://login.microsoftonline.com/{0}/oauth2/v2.0/authorize?{1}".format(tenantID, queryParams.join("&"));

        console.log(url);
        window.open(url);

        //Step 3 : La pagina specificata nel redirect uri scriverà un cookie contenente l'authCode.
        var authCode=await cookieManager.waitAndGetCookie("authCode");
        if (authCode==null) {
            return null;
        }

        return authCode;

    }

    async _getTokensWithAuthorizationCode(tenantID, appID, authCode) {

        /**
         * 
         * 
        {
            "client_id": "93a1bf1c-a4e3-4e50-9b01-222741230fac",
            "scope": "https://graph.microsoft.com/mail.read",
            "redirect_uri": "https://one.iperiusbeta.com",
            "grant_type": "authorization_code",
            "client_secret": "JqQX2PNo9bpM0uEihUPzyrh",
            "code": "1.AToAhsL6uAnLGUa56AkprdJr7hy_oZPjpFBOmwEiJxQjD6w6AMI6AA.AgABAAIAAAABVrspeuWamRam2jAF1XRQEAwDs_wU...",
            "resource": "https://graph.microsoft.com",
            "code_verifier": "eAcGZTU2qzi2LEMcCjvHzyA0uq8HC1fsJFhIWhgENGO"
        }
         * 
         */
        var body={
            client_id: appID,
            grant_type: "authorization_code",
            code: authCode,
            redirect_uri: this.serviceAccount.url,
        };

        var codeVerifier=sessionStorage.getItem("code_verifier");
        if (!isValid(codeVerifier)) {
            return false;
        }
        body.code_verifier=codeVerifier;


        var [ok, response]=await api.sendRequestToServer(
            "POST",
            GET_TOKEN_URL.format(tenantID),
            defaultHeaders,
            body,
            messages.tryLoginWithAuthenticationCode,
            messages.tryLoginWithAuthenticationCode_ok,
            messages.tryLoginWithAuthenticationCode_failed,
            null
        );


        if (!ok) {
            return false;
        }

        //console.log(response);
        //console.log("✅ Access Token:", response.access_token);
        this.serviceAccount.token=response.access_token;
        this.serviceAccount.refresh_token=response.refresh_token;
        return true;

    }

    async _getTokensWithRefreshToken(tenantID, appID, refreshToken) {
        var body={
            "client_id": appID,
            "grant_type": "refresh_token",
            "refresh_token": refreshToken,
            "redirect_uri": this.serviceAccount.url
        };

        var [ok, response]=await api.sendRequestToServer(
            "POST",
            GET_TOKEN_URL.format(tenantID),
            defaultHeaders,
            body,
            messages.tryLoginWithRefreshToken,
            messages.tryLoginWithRefreshToken_ok,
            messages.tryLoginWithRefreshToken_failed,
            null
        );

        //console.log(response);
        if (!ok) {
            this.serviceAccount.token=null;
            this.serviceAccount.refresh_token=null
            return false;
        }

        //Caso 2 : la richiesta è andata a buon fine
        this.serviceAccount.token=response.access_token;
        this.serviceAccount.refresh_token=response.refresh_token
        return true;
    }

    //https://learn.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-client-creds-grant-flow
    async _getAccessTokenWithAppIDAndClientSecret() {
        var body={
            "client_id": this.serviceAccount.client_id,
            "client_secret": this.serviceAccount.client_secret,
            "grant_type": "client_credentials",
            "scope": "https://graph.microsoft.com/.default"
        };

        //Riprovo più volte poiché Azure non mi permette di utilizzare subito il client secret appena registrato
        var ok, response;
        for (var attempt=1; attempt<=10; attempt++) {

            [ok, response]=await api.sendRequestToServer(
                "POST",
                GET_TOKEN_URL.format(this.serviceAccount.options.tenant_id),
                defaultHeaders,
                body,
                "Attempt n.{0} : {1}".format(attempt, messages.tryLoginWithClientSecret),
                messages.tryLoginWithClientSecret_ok,
                null,
                null
            );

            if (ok) {
                this.serviceAccount.token=response.access_token;
                this.serviceAccount.refresh_token=response.refresh_token;
                return true;
            }

            await waitSeconds(1);
        }

        cookieManager.setCookie("step", messages.tryLoginWithClientSecret_failed);
        this.serviceAccount.client_secret=null;
        this.serviceAccount.token=null;
        this.serviceAccount.refresh_token=null
        return false;
    }
    //--------------------------------------------------NON UTILIZZATI--------------------------------------------------------------------------------------------
    // async _getTokensFromAzure() {
    //     var request=getDefaultAzureCredentials();
    //     var ok=await this._getTokensWithTenantIDAndAppID(request.tenantID, request.appID);

    //     if (ok) {
    //         return true;
    //     }
    //     ok=await this._getDeviceCode(request.tenantID, request.appID);
    //     if (!ok)
    //         return false;

    //     ok=await this._getTokensWithDeviceCode(request.tenantID, request.appID, this.serviceAccount.options.deviceCode);

    //     return ok;
    // }


    // async _getDeviceCode(tenantID, appID) {
    //     if (!isValid(tenantID)||!isValid(appID)) {
    //         tenantID=getDefaultAzureCredentials().tenantID;
    //         appID=getDefaultAzureCredentials().appID;
    //     }

    //     var body={
    //         "client_id": appID,
    //         "scope": tenantID=="common"? /*"openid profile offline_access"*/ "https://graph.microsoft.com/.default":getDefaultScopes().join(" ")
    //     };

    //     var [ok, response]=await api.sendRequestToServer(
    //         "POST",
    //         "https://login.microsoftonline.com/{0}/oauth2/v2.0/devicecode".format(tenantID),
    //         defaultHeaders,
    //         body,
    //         messages.deviceCode,
    //         messages.deviceCode_ok,
    //         messages.deviceCode_failed,
    //         null
    //     );

    //     if (!ok) {
    //         return false;
    //     }

    //     console.log("Go to:", response.verification_uri);
    //     console.log("Enter this code:", response.user_code);
    //     await new Promise((resolve) => setTimeout(resolve, 20000));

    //     return ok;
    // }

    // async _getTokensWithDeviceCode(tenantID, appID, deviceCode) {
    //     if (!isValid(tenantID)||!isValid(appID)) {
    //         tenantID=getDefaultAzureCredentials().tenantID;
    //         appID=getDefaultAzureCredentials().appID;
    //     }
    //     var ok=false, response=null;
    //     var body={
    //         client_id: appID,
    //         grant_type: "urn:ietf:params:oauth:grant-type:device_code",
    //         device_code: deviceCode
    //     };


    //     for (var a=1; a<=12; a++) {
    //         await new Promise((resolve) => setTimeout(resolve, 5000)); // Attendi 5 secondi tra i tentativi

    //         [ok, response]=await api.sendRequestToServer(
    //             "POST",
    //             GET_TOKEN_URL.format(tenantID),
    //             defaultHeaders,
    //             body,
    //             "Attempt n. {0} : {1}".format(a, messages.tryLoginWithDeviceCode),
    //             messages.tryLoginWithDeviceCode_ok,
    //             null,
    //             null
    //         );

    //         if (ok) {
    //             this.serviceAccount.token=response.access_token;
    //             if (response.refresh_token) {
    //                 this.serviceAccount.refresh_token=response.refresh_token;
    //             }
    //             return true;
    //         }
    //     }
    //     cookieManager.setCookie("step", messages.tryLoginWithDeviceCode_failed);

    //     return false;
    // }
    //----------------------------------------------------------------------------------------------------------------------------------------------------------------


    async checkChanges() {
        var app=await this._getApp(this.serviceAccount.options["objID"], this.serviceAccount.client_id, this.serviceAccount.options["appName"]);
        if (app==null) {
            return false;
        }
        var isChangedURIs=false, isChangedPermissions=false;

        var appChanges={
            "isFallbackPublicClient": true
        };
        //Per implemetare la retrocompatibilità, controllo se sono stati cambiati alcuni campi
        //REDIRECT URIS
        [window.location.origin, this.serviceAccount.url, "http://localhost:8080", "https://one.iperiusbeta.com"].forEach(uri => {
            if (uri==null) {
                return;
            }
            if (!app.publicClient.redirectUris.includes(uri)) {
                app.publicClient.redirectUris.push(uri);
                isChangedURIs=true;
            }
        });

        if (isChangedURIs) {
            appChanges["publicClient"]={
                "redirectUris": app.publicClient.redirectUris
            };
        }

        //PERMESSI

        if(this.serviceAccount.id==null){
            isChangedPermissions = true;
        }else{
            var newPermissions=this._extractMissingPermissions(app.requiredResourceAccess, requiredResourceAccess);
            isChangedPermissions = newPermissions.length !=0;
        }
            

        if (isChangedPermissions) {
            //Non riesco ad aggiungere solo i nuovi permessi, quindi devo passare tutta la lista di requiredResourceAccess
            appChanges.requiredResourceAccess=requiredResourceAccess;
        }

        if (!isChangedURIs&&!isChangedPermissions) {
            cookieManager.setCookie("step", messages.appUpdated);
            return true;
        }

        var headers={
            "Authorization": "Bearer "+this.serviceAccount.token,
            'Content-Type': 'application/json'
        }
        var [ok, _]=await api.sendRequestToServer(
            "PATCH",
            "https://graph.microsoft.com/v1.0/applications(appId='{0}')".format(this.serviceAccount.client_id),
            headers,
            appChanges,
            messages.checkChanges,
            null,
            messages.checkChangesFailed,
            null
        );

        if (!ok) {
            cookieManager.setCookie("step", messages.checkChangesFailed);
            return false;
        }


        if (!isChangedPermissions) {
            return true;
        }

        window.open("https://login.microsoftonline.com/{0}/adminconsent?client_id={1}&redirect_uri={2}".format(this.serviceAccount.options.tenant_id, this.serviceAccount.client_id, this.serviceAccount.url));

        var adminConsent=await cookieManager.waitAndGetCookie("admin_consent");

        ok=adminConsent=="true";
        if (ok) {
            cookieManager.setCookie("step", messages.checkChangesOk);
            return true;
        }

        cookieManager.setCookie("step", messages.checkChangesFailed);

        return false;
    }
    /***************************************************REGISTAZIONE APP***************************************************************************************/

    // async _createNewApp(appName, accessToken) {

    //     //var application = newAppObj(request.appName, ["userreadwrite", "groupuserreadwrite", "directoryuserreadwrite", "filesuserreadwrite", "offline_access", "full_access_as_app", "mailbox", "sitesuserreadwrite", "email", "openid", "profile")
    //     var application=createAppObj(appName);
    //     var headers=defaultHeaders;
    //     headers.Authorization="Bearer "+accessToken;
    //     headers["Content-Type"]="application/json";
    //     //console.log(application);
    //     var [ok, response]=await api.sendRequestToServer(
    //         "POST",
    //         "https://graph.microsoft.com/v1.0/applications",
    //         headers,
    //         JSON.stringify(application),
    //         messages.appRegistration,
    //         messages.appRegistrationOk,
    //         messages.appRegistrationFailed,
    //         60 //Devo aspettare un po' di tempo prima di effettuare qualche richiesta
    //     );

    //     if (!ok) {
    //         this.serviceAccount.client_id=null;
    //         this.serviceAccount.options["objID"]=null;
    //         return false;
    //     }

    //     this.serviceAccount.client_id=response.appId;
    //     this.serviceAccount.options["objID"]=response.id;
    //     return true;
    // }


    async _getApp(objID, appID, appName) {
        var properties="$select=id,appId,displayName,requiredResourceAccess,web,keyCredentials,passwordCredentials,publicClient";
        var url;
        var multiple=false;
        if (isValid(appID)) {
            url="https://graph.microsoft.com/v1.0/applications(appId='{0}')?{1}".format(appID, properties);
        } else if (isValid(objID)) {
            url="https://graph.microsoft.com/v1.0/applications/{0}?{1}".format(objID, properties);
        } else {
            multiple=true;
            url="https://graph.microsoft.com/v1.0/applications?{0}&$filter={1}'{2}'".format(properties, encodeURIComponent("DisplayName eq "), appName);
        }


        var [ok, response]=await api.sendRequestToServer(
            "GET",
            url,
            { "Authorization": "Bearer "+this.serviceAccount.token },
            null,
            messages.app,
            messages.appOk,
            messages.appFailed,
            null
        );

        if (!ok) {
            return null;
        }

        response=response.value??response;
        if (multiple&&response.length==0) {
            return null;
        }

        if (multiple) {
            response=response[0];
        }

        this.serviceAccount.options["appName"]=response.displayName;
        this.serviceAccount.options["objID"]=response.id;
        this.serviceAccount.client_id=response.appId;
        return response;
    }

    /****************************************RICHIESTE DI ALTRI DATI***************************************************************/

    async _getTenantID(accessToken) {
        var [ok, response]=await api.sendRequestToServer(
            "GET",
            "https://graph.microsoft.com/v1.0/organization",
            { "Authorization": "Bearer "+accessToken },
            null,
            messages.tenantID,
            messages.tenantIDOk,
            messages.tenantIDFailed,
            null
        );

        if (ok) {
            this.serviceAccount.options.tenant_id=response.value[0].id;
            return true;
        }

        /*
        {
    "code": "InvalidAuthenticationToken",
    "innerError": {
        "client-request-id": "35b3f410-dc35-4b78-930d-def341cf49a0",
        "date": "2025-03-18T15:59:32",
        "request-id": "35b3f410-dc35-4b78-930d-def341cf49a0"
    },
    "message": "Lifetime validation failed, the token is expired."
}
        
        
        */
        if (!ok&&response.code!=undefined&&response.code=="InvalidAuthenticationToken") {
            this.serviceAccount.token=null;
            return null;
        }

        return null;
    }

    async _createClientSecret() {
        if (this.serviceAccount.options["appName"]==undefined) {
            await this._getApp(null, this.serviceAccount.client_id, null);
        }
        var now=new Date();
        var startDate=now.toISOString();
        now.setUTCFullYear(now.getUTCFullYear()+100);
        var endDate=now.toISOString();

        var body={
            "passwordCredential": {
                "displayName": this.serviceAccount.options["appName"]+" "+now.ddMMyyyyHHmmss(),
                "endDateTime": endDate,
                "startDateTime": startDate
            }
        };

        var [ok, response]=await api.sendRequestToServer(
            "POST",
            "https://graph.microsoft.com/v1.0/applications(appId='{0}')/addPassword".format(this.serviceAccount.client_id),
            {
                "Authorization": "Bearer "+this.serviceAccount.token,
                'Content-Type': 'application/json'
            },
            body,
            messages.clientSecret,
            messages.clientSecretOk,
            messages.clientSecretFailed,
            30
        );

        if (!ok) {
            return false;
        }

        this.serviceAccount.client_secret=response.secretText;
        return true;
    }

    /*
    I permessi sono oggetti del tipo:
        "resourceAppId": "00000003-0000-0000-c000-000000000000", //Rappresenta i servizi principali come graph, sharepoint, exchange....
        "resourceAccess": [{ // è la lista dei permessi
                "id": "06da0dbc-49e2-44d2-8312-53f166ab848a",
                "type": "Scope"
            },
            ...
        }],
        
        
    */
    _extractMissingPermissions(oldServices, newServices) {
        if (oldServices.length==0)
            return newServices;
        var new_service, new_permission, old_service;
        var s_size=newServices.length,
            p_size;
        for (var s=0; s<s_size; s++) {
            new_service=newServices[s];
            old_service=oldServices.find(old_service => old_service.resourceAppId==new_service.resourceAppId);
            //Se i vecchi servizi non contengono già il nuovo servizio, lo mantengo e vado avanti
            if (!isValid(old_service)) {
                continue;
            }

            //Se i vecchi servizi contengono il nuovo servizio, controllo se contiene tutti i permessi
            p_size=newServices[s].resourceAccess.length;
            for (var p=0; p<p_size; p++) {
                new_permission=newServices[s].resourceAccess[p];

                //Se i vecchi permessi del servizio non contengono il nuovo permesso, lo mantengo e vado avanti
                if (!old_service.resourceAccess.some(old_permission => old_permission.id==new_permission.id)) {
                    continue;
                }

                //Se i vecchi permessi del servizio contengono già il nuovo permesso, lo rimuovo
                newServices[s].resourceAccess.splice(p, 1);
                p_size--;
                p--;
            }

            //Se i permessi del nuovo servizio sono vuoti, significa che sono già stati assegnati tutti i permessi di quel servizio, quindi lo rimuovo
            if (newServices[s].resourceAccess.length==0) {
                newServices.splice(s, 1);
                s_size--;
                s--;
                continue;
            }
        }

        return newServices;
    }
}

//"https://outlook.office365.com/EWS.AccessAsUser.All https://outlook.office365.com/Files.ReadWrite.All https://outlook.office365.com/Group.ReadWrite.All https://outlook.office365.com/User.Read https://outlook.office365.com/.default"



const messages={
    azureAccess: "I'm logging into the Azure platform",
    azureAccessOk: "I logged into the Azure platform",
    azureAccessFailed: "An error occurred while accessing the Azure platform",

    tenantID: "I'm retrieving the tenant ID",
    tenantIDOk: "I got the tenant ID",
    tenantIDFailed: "An error occurred while retrieving your tenant ID",

    app: "I'm retrieving the application",
    appOk: "I got the application",
    appFailed: "An error occurred while retrieving the application",


    appRegistration: "I am registering the new application",
    appRegistrationOk: "I have registered the new application",
    appRegistrationFailed: "An error occurred while registering the new application",

    appUpdated: "The application is updated",

    checkChanges: "I am checking the changes",
    checkChangesOk: "I have update the application",
    checkChangesFailed: "An error occurred while updating the application",

    appAccess: "I'm logging into the new application",
    appAccessOk: "I logged into the new application",
    appAccessFailed: "An error occurred while accessing the new application",

    clientSecret: "I'm registering the new client secret",
    clientSecretOk: "I have registered the new client secret",
    clientSecretFailed: "An error occurred while registering the new client secret",

    credentialsTest: "I validate the credentials",
    credentialsTestOk: "I validated the credentials",
    credentialsTestFailed: "An error occurred while validating your credentials",

    tryLoginWithAuthenticationCode: "I'm logging with authentication code",
    tryLoginWithAuthenticationCode_ok: "I'm logged with authentication code",
    tryLoginWithAuthenticationCode_failed: "An error occurred while logging with authentication code",

    tryLoginWithAccessToken: "I'm logging with saved token",
    tryLoginWithAccessToken_ok: "I'm logged with saved token",
    tryLoginWithAccessToken_failed: "An error occurred while logging with saved token",

    tryLoginWithRefreshToken: "I'm logging with refresh token",
    tryLoginWithRefreshToken_ok: "I'm logged with refresh token",
    tryLoginWithRefreshToken_failed: "An error occurred while logging with refresh token",

    tryLoginWithClientSecret: "I'm logging with client secret",
    tryLoginWithClientSecret_ok: "I'm logged with client secret",
    tryLoginWithClientSecret_failed: "An error occurred while logging with client secret",

    tryLoginWithDeviceCode: "I'm logging with device code",
    tryLoginWithDeviceCode_ok: "I'm logged with device code",
    tryLoginWithDeviceCode_failed: "An error occurred while logging with device code",

    deviceCode: "I am retrieving the device code",
    deviceCode_ok: "I retrieved the device code. Please enter it in the link provided",
    deviceCode_failed: "An error occurred while retrieving the device code"
}

const defaultHeaders={
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": "*" /*window.location.origin*/,
    "Access-Control-Allow-Credentials": false, // true e * non vanno d'accordo
}


// Funzione per generare un Code Verifier casuale (PKCE)
function generateCodeVerifier() {
    const array=new Uint8Array(32);
    window.crypto.getRandomValues(array);
    return btoa(String.fromCharCode.apply(null, array))
        .replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

// Funzione per calcolare il Code Challenge (SHA-256)
async function generateCodeChallenge(verifier) {
    const encoder=new TextEncoder();
    const data=encoder.encode(verifier);
    const digest=await window.crypto.subtle.digest("SHA-256", data);
    return btoa(String.fromCharCode.apply(null, new Uint8Array(digest)))
        .replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

const GET_TOKEN_URL="https://login.microsoftonline.com/{0}/oauth2/v2.0/token";