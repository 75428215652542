//import { MicrosoftGraphDelegatePermission, MicrosoftGraphApplicationPermission, ExchangeOnlineDelegatePermission, ExchangeOnlineApplicationPermission, } from "./azurePermissions";


export function createAppObj( /**/appName, redirectURL ) {

    //Documentazione per gli ID: https://www.rickvanrousselt.com/blog/azure-default-service-principals-reference-table/
    return {
        "displayName": appName,
        "isFallbackPublicClient": true,
        "publicClient": {
            "redirectUris": [window.location.origin, redirectURL, "http://localhost:8080"].distinctBy(null)
        },
        "signInAudience": "AzureADMultipleOrgs",
        //"requiredResourceAccess": requiredResourceAccess,
        //PRESI DAL MANIFEST DI UN'APPLICAZIONE
        //"requiredResourceAccess": requiredResourceAccess
    }
}

export function getDefaultScopes() {
    return [
        "Directory.Read.All",
        "EWS.AccessAsUser.All",
        "Group.ReadWrite.All",
        "openid",
        "profile",
        "User.Read",
        "email",
        "offline_access",
        //"userInfo.read",
        "Files.Read.All",
        "Files.ReadWrite.All",
        "Mail.Read",
        "Mail.ReadBasic",
        "Mail.ReadWrite",
        //"Mail.ReadWrite.All",
        "Sites.Read.All",
        "Sites.ReadWrite.All",
        //"Application.ReadWrite.OwnedBy",
        "Application.ReadWrite.All",

    ];
}

export function getDefaultAzureCredentials() {
    return {
        "tenantID": "common",
        "appID": "04b07795-8ddb-461a-bbee-02f9e1bf7b46"
    }
}

//PRESI DAL MANIFEST DI UN'APPLICAZIONE
export const requiredResourceAccess = [{
        "resourceAppId": "00000003-0000-0000-c000-000000000000",
        "resourceAccess": [{
                "id": "06da0dbc-49e2-44d2-8312-53f166ab848a",
                "type": "Scope"
            },
            {
                "id": "7ab1d382-f21e-4acd-a863-ba3e13f7da61",
                "type": "Role"
            },
            {
                "id": "5b567255-7703-4780-807c-7be8301ae99b",
                "type": "Role"
            },
            {
                "id": "4e46008b-f24c-477d-8fff-7bb4ec7aafe0",
                "type": "Scope"
            },
            {
                "id": "62a82d76-70ea-41e2-9197-370581804d09",
                "type": "Role"
            },
            {
                "id": "dbaae8cf-10b5-4b86-a4a1-f871c94c6695",
                "type": "Role"
            },
            {
                "id": "f81125ac-d3b7-4573-a3b2-7099cc39df9e",
                "type": "Scope"
            },
            {
                "id": "bf7b1a76-6e77-406b-b258-bf5c7720e98f",
                "type": "Role"
            },
            {
                "id": "7427e0e9-2fba-42fe-b0c0-848c9e6a8182",
                "type": "Scope"
            },
            {
                "id": "9492366f-7969-46a4-8d15-ed1a20078fff",
                "type": "Role"
            },
            {
                "id": "810c84a8-4a9e-49e6-bf7d-12d183f40d01",
                "type": "Role"
            },
            {
                "id": "6be147d2-ea4f-4b5a-a3fa-3eab6f3c140a",
                "type": "Role"
            },
            {
                "id": "693c5e45-0940-467d-9b8a-1022fb9d42ef",
                "type": "Role"
            },
            {
                "id": "e2a3a72e-5f79-4c64-b1b1-878b674786c9",
                "type": "Role"
            },
            {
                "id": "570282fd-fa5c-430d-a7fd-fc8dc98a9dca",
                "type": "Scope"
            },
            {
                "id": "a4b8392a-d8d1-4954-a029-8e668a39a170",
                "type": "Scope"
            },
            {
                "id": "024d486e-b451-40bb-833d-3e66d98c5c73",
                "type": "Scope"
            },
            {
                "id": "6931bccd-447a-43d1-b442-00a195474933",
                "type": "Role"
            },
            {
                "id": "243cded2-bd16-4fd6-a953-ff8177894c3d",
                "type": "Role"
            },
            {
                "id": "7b2449af-6ccd-4f4d-9f78-e550c193f0d1",
                "type": "Role"
            },
            {
                "id": "767156cb-16ae-4d10-8f8b-41b657c8c8c8",
                "type": "Scope"
            },
            {
                "id": "ebf0f66e-9fb1-49e4-a278-222f76911cf4",
                "type": "Scope"
            },
            {
                "id": "2b61aa8a-6d36-4b2f-ac7b-f29867937c53",
                "type": "Scope"
            },
            {
                "id": "35930dcf-aceb-4bd1-b99a-8ffed403c974",
                "type": "Role"
            },
            {
                "id": "0c3e411a-ce45-4cd1-8f30-f99a3efa7b11",
                "type": "Scope"
            },
            {
                "id": "23fc2474-f741-46ce-8465-674744c5c361",
                "type": "Role"
            },
            {
                "id": "0121dc95-1b9f-4aed-8bac-58c5ac466691",
                "type": "Role"
            },
            {
                "id": "bdd80a03-d9bc-451d-b7c4-ce7c63fe3c8f",
                "type": "Role"
            },
            {
                "id": "4437522e-9a86-4a41-a7da-e380edd4a97d",
                "type": "Role"
            },
            {
                "id": "7825d5d6-6049-4ce7-bdf6-3b8d53f4bcd0",
                "type": "Scope"
            },
            {
                "id": "4a06efd2-f825-4e34-813e-82a57b03d1ee",
                "type": "Scope"
            },
            {
                "id": "2104a4db-3a2f-4ea0-9dba-143d457dc666",
                "type": "Scope"
            },
            {
                "id": "485be79e-c497-4b35-9400-0e3fa7f2a5d4",
                "type": "Scope"
            },
            {
                "id": "dfb0dd15-61de-45b2-be36-d6a69fba3c79",
                "type": "Role"
            },
            {
                "id": "d9c48af6-9ad9-47ad-82c3-63757137b9af",
                "type": "Role"
            },
            {
                "id": "294ce7c9-31ba-490a-ad7d-97a7d075e4ed",
                "type": "Role"
            },
            {
                "id": "b9bb2381-47a4-46cd-aafb-00cb12f68504",
                "type": "Role"
            },
            {
                "id": "57257249-34ce-4810-a8a2-a03adf0c5693",
                "type": "Role"
            },
            {
                "id": "38826093-1258-4dea-98f0-00003be2b8d0",
                "type": "Scope"
            },
            {
                "id": "9ff7295e-131b-4d94-90e1-69fde507ac11",
                "type": "Scope"
            },
            {
                "id": "cdcdac3a-fd45-410d-83ef-554db620e5c7",
                "type": "Scope"
            },
            {
                "id": "116b7235-7cc6-461e-b163-8e55691d839e",
                "type": "Scope"
            },
            {
                "id": "dea13482-7ea6-488f-8b98-eb5bbecf033d",
                "type": "Scope"
            },
            {
                "id": "57257249-34ce-4810-a8a2-a03adf0c5693",
                "type": "Role"
            }
        ]
    },
    {
        "resourceAppId": "00000003-0000-0ff1-ce00-000000000000",
        "resourceAccess": [{
                "id": "56680e0d-d2a3-4ae1-80d8-3c4f2100e3d0",
                "type": "Scope"
            },
            {
                "id": "b3f70a70-8a4b-4f95-9573-d71c496a53f4",
                "type": "Scope"
            },
            {
                "id": "dd2c8d78-58e1-46d7-82dd-34d411282686",
                "type": "Scope"
            },
            {
                "id": "2cfdc887-d7b4-4798-9b33-3d98d6b95dd2",
                "type": "Scope"
            },
            {
                "id": "678536fe-1083-478a-9c59-b99265e6b0d3",
                "type": "Role"
            },
            {
                "id": "1002502a-9a71-4426-8551-69ab83452fab",
                "type": "Scope"
            },
            {
                "id": "741f803b-c850-494e-b5df-cde7c675a1ca",
                "type": "Role"
            },
            {
                "id": "82866913-39a9-4be7-8091-f4fa781088ae",
                "type": "Scope"
            }
        ]
    },
    {
        "resourceAppId": "00000002-0000-0ff1-ce00-000000000000",
        "resourceAccess": [{
                "id": "3b5f3d61-589b-4a3c-a359-5dd4b5ee5bd5",
                "type": "Scope"
            },
            {
                "id": "dc890d15-9560-4a4c-9b7f-a736ec74ec40",
                "type": "Role"
            },
            {
                "id": "810c84a8-4a9e-49e6-bf7d-12d183f40d01",
                "type": "Role"
            },
            {
                "id": "e2a3a72e-5f79-4c64-b1b1-878b674786c9",
                "type": "Role"
            },
            {
                "id": "dab085de-3e14-432f-a47f-84b6457059c4",
                "type": "Scope"
            },
            {
                "id": "185758ba-798d-4b72-9e54-429a413a2510",
                "type": "Scope"
            },
            {
                "id": "75767999-c7a8-481e-a6b4-19458e0b30a5",
                "type": "Scope"
            },
            {
                "id": "bf24470f-10c1-436d-8d53-7b997eb473be",
                "type": "Role"
            },
            {
                "id": "eb665d05-7f76-4d1b-b176-1cfc814e668d",
                "type": "Scope"
            }
        ]
    }
];
/*function getMicrosoftGraphPermissions() {

    var graphScopes = [
        MicrosoftGraphDelegatePermission.DirectoryReadAll,
        MicrosoftGraphDelegatePermission.email,
        MicrosoftGraphDelegatePermission.EWSAccessAsUserAll,
        MicrosoftGraphDelegatePermission.FilesReadAll,
        MicrosoftGraphDelegatePermission.FilesReadWriteAll,
        MicrosoftGraphDelegatePermission.GroupReadWriteAll,
        MicrosoftGraphDelegatePermission.MailRead,
        MicrosoftGraphDelegatePermission.MailReadBasic,
        MicrosoftGraphDelegatePermission.MailReadWrite,
        MicrosoftGraphDelegatePermission.offline_access,
        MicrosoftGraphDelegatePermission.openid,
        MicrosoftGraphDelegatePermission.profile,
        MicrosoftGraphDelegatePermission.SitesReadAll,
        MicrosoftGraphDelegatePermission.UserRead,
        MicrosoftGraphDelegatePermission.ApplicationReadWriteAll,

    ].map(id => {
        return {
            "id": id,
            "type": "Scope"
        }
    });

    var graphRoles = [
        MicrosoftGraphApplicationPermission.DirectoryReadAll,
        MicrosoftGraphApplicationPermission.GroupReadAll,
        MicrosoftGraphApplicationPermission.GroupReadWriteAll,
        MicrosoftGraphApplicationPermission.SitesReadWriteAll,
        MicrosoftGraphApplicationPermission.TeamSettingsReadWriteAll,
        //https://learn.microsoft.com/en-us/graph/api/application-update?view=graph-rest-1.0&tabs=http
        MicrosoftGraphApplicationPermission.ApplicationReadWriteOwnedBy,
        MicrosoftGraphApplicationPermission.ApplicationReadWriteAll

    ].map(id => {
        return {
            "id": id,
            "type": "Role"
        }
    });

    return graphRoles.concat(graphScopes);

}

function getExchange365Permissions() {
    var exchangeScopes = [
        ExchangeOnlineDelegatePermission.EWSAccessAsUserAll,
        ExchangeOnlineDelegatePermission.GroupReadWriteAll,
        ExchangeOnlineDelegatePermission.MailRead,
        ExchangeOnlineDelegatePermission.MailReadWriteAll,
        ExchangeOnlineDelegatePermission.MailReadWriteShared,
        ExchangeOnlineDelegatePermission.UserRead
    ].map(id => {
        return {
            "id": id,
            "type": "Scope"
        }
    });

    var exchangeRoles = [
        ExchangeOnlineApplicationPermission.full_access_as_app,
    ].map(id => {
        return {
            "id": id,
            "type": "Role"
        }
    });

    return exchangeScopes.concat(exchangeRoles);
}

function getAzureServiceManagementPermissions() {
    var azureServiceManagementScopes = [
        "41094075-9dad-400e-a0bd-54e686782033" // user_impersonation
    ].map(id => {
        return {
            "id": id,
            "type": "Scope"
        }
    });

    return azureServiceManagementScopes;
}

function getAzureStoragePermissions() {
    var azureStorageScopes = [
        "03e0da56-190b-40ad-a80c-ea378c433f7f" //user_impersonation
    ].map(id => {
        return {
            "id": id,
            "type": "Scope"
        }
    });

    return azureStorageScopes;
}*/