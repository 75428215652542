var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.$root.connectionsStates[_vm.pc.id]
    ? _c("div", {}, [
        _c("div", { staticClass: "pt-2 text-center" }, [
          _c("span", { staticClass: "mif-warning mif-2x primary text-muted" }),
          _c("br"),
          _c("h6", { staticClass: "m-0 mt-3 text-muted" }, [
            _vm._v(_vm._s(_vm.$t("Device is not connected"))),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }