<template>
	<div v-if="createFolderObj != null" class="dialog light h-auto">
		<div v-if="showChoices" class="dialog-title pl-10">{{ $t("Create new folder") }}</div>
		<div v-else-if="type == $PlatformsTypes.Cloud_AzureBlob" class="dialog-title pl-10">{{ $t("Create new container") }}</div>
		<div v-else-if="type == $PlatformsTypes.Cloud_AzureFile" class="dialog-title pl-10">{{ $t("Create new share") }}</div>
		<div v-else-if="type.isCloudS3()"class="dialog-title pl-10">{{ $t("Create new bucket") }}</div>

			<div class="dialog-content p-0">
			<!--SCELTA DI QUALE CARTELLA CREARE-->
			<div class="d-flex flex-column form-group" v-if="showChoices">
				<input v-if="whatCreate_showValues[IN_SELECTED_PATH]" v-model="whatCreate" :value="IN_SELECTED_PATH" name='createFolderRadio' type='radio' data-role='radio' :data-caption="$t('Create a folder in selected path')">
				<input v-if="whatCreate_showValues[IN_ANOTHER_PATH]" v-model="whatCreate" :value="IN_ANOTHER_PATH" name='createFolderRadio' type='radio' data-role='radio' :data-caption="$t('Create a folder in another path')">
				<input v-if="whatCreate_showValues[ROOT_FOLDER]" v-model="whatCreate" :value="ROOT_FOLDER" name='createFolderRadio' type='radio' data-role='radio' :data-caption="$t('Create a main folder')">
			</div>
	
			<!--ROOT NODES-->
			<div v-if="whatCreate == IN_ANOTHER_PATH && createFolderObj.mainFolders.length != 0" class="form-group">
				<label>{{ $t("Main folder") }}</label>
				<select class="select w-auto" v-model="mainFolderSelected">
					<option v-for="rootName in createFolderObj.mainFolders" :value="rootName" :key="rootName">
						{{ rootName }}
					</option>
				</select>
				<small class="text-muted">{{ $t("Required") }}</small>
			</div>
	
			<!--PARENTPATH-->
			<div v-if="parentPath != ''" class="form-group d-flex mb-2 va-baseline">
				<span class="mr-2">
				<svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
					<path d="M13.821 6.5h5.929a2.25 2.25 0 0 1 2.229 1.938l.016.158.005.154v9a2.25 2.25 0 0 1-2.096 2.245L19.75 20H4.25a2.25 2.25 0 0 1-2.245-2.096L2 17.75v-7.251l6.207.001.196-.009a2.25 2.25 0 0 0 1.088-.393l.156-.12L13.821 6.5ZM8.207 4c.46 0 .908.141 1.284.402l.156.12 2.103 1.751-3.063 2.553-.085.061a.75.75 0 0 1-.29.106L8.206 9 2 8.999V6.25a2.25 2.25 0 0 1 2.096-2.245L4.25 4h3.957Z"
					fill="#212121" />
				</svg>
				</span>
				<span>{{ parentPath.trimEnd(slash) }}{{ slash }}</span>
			</div>
	
			<!--NOME O PERCORSO DELLA NUOVA CARTELLA DA CREARE-->
			<div class="form-group">
				<input type="text" data-role="input" v-model="createFolderObj.name" class="input" v-fsitem/>
				<div v-if="nameIsInvalid" class="mt-1 fg-red text-small">
					<span>{{ $t("A filename cannot contain any of the following characters : {0}.", { 0: notValidChars.join(' ') }) }} </span><br>
					<span>{{ $t("Use {0} as a separator.", { 0: this.slash }) }} </span>
				</div>
			</div>
			<div class="form-group" v-if="whatCreate == ROOT_FOLDER && regions.length != 0 && type == $PlatformsTypes.Cloud_AmazonS3Storage">
                    <label>{{ $t("Region") }}</label>
                    <select class="select w-auto" v-model="region">
                        <option v-for="region in regions" :value="region.Url" :key="region.Url">{{
							region.Name
						}}</option>
                    </select>
                    <small class="text-muted">{{ $t("Required") }}</small>
                </div>
		</div>
	<div class="dialog-actions">
		<div v-if="wait" class="form-group text-center">
			<span class="mif-spinner2 fg-primary ani-spin"></span>
		</div>
		<button v-else class="button js-dialog-close primary my-3" @click="createFolder()" :disabled="createFolderObj.name == '' || nameIsInvalid">{{ $t("Create") }}</button>
		<button class="button js-dialog-close" @click="$root.$emit('CLOSECREATEFOLDERDIALOG')">{{ $t("Close") }}</button>
	</div>
	</div>
</template>
<script>
import { FileFolderEntityType } from '../../utils/objects';
import { ClientTreeNode } from '../../models/TreeNode';


export default {
	name: "CreateFolder",
	props: {
		/*
		{
			parentPath: ,
			name: ,
			client:,
			mainFolders
		}
		*/
		createFolderObj: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			IN_SELECTED_PATH: 0,
			IN_ANOTHER_PATH: 1,
			ROOT_FOLDER: 2,
			whatCreate: 0,
			whatCreate_showValues: [true, true, true],
			showChoices: true,
			type: this.$PlatformsTypes.FileFolder,
			parentPath: "",
			mainFolderSelected: "",
			slash: "/",
			wait: false,
			regions: [],
			region: "",
			notValidChars: [
				"<",
				">",
				":",
				//"\"",
				//"/",
				"\\",
				"|",
				"?",
				"*",
			]
		}
	},
	async created() {
		if (this.createFolderObj.client==null)
			this.type=this.$PlatformsTypes.FileFolder;
		else
			this.type=this.createFolderObj.client.serviceAccount.type;

		this.slash=this.type.getSeparator();
		this.parentPath=this.createFolderObj.parentPath.trimEnd(this.slash);
		this.whatCreate_showValues=[this.showWhatCreate(this.IN_SELECTED_PATH), this.showWhatCreate(this.IN_ANOTHER_PATH), this.showWhatCreate(this.ROOT_FOLDER)];
		//Se è attivata una sola scelta, è inutile mostrare l'unico radio button
		// Quindi, mostro i radio button solo se è attivata più di una scelta
		this.showChoices=this.whatCreate_showValues.indexOf(true)!=this.whatCreate_showValues.lastIndexOf(true);

		if (this.type.isCloudS3()) {
			this.regions=await this.$api.getAmazonRegions();
			if (this.createFolderObj.parentPath=="") {
				this.whatCreate=this.ROOT_FOLDER;
			}

		}
	},
	watch: {
		whatCreate: {
			handler: function(value) {
				switch (value) {
					case this.IN_SELECTED_PATH:
						this.parentPath=this.createFolderObj.parentPath.trimEnd(this.slash);
						break;
					case this.IN_ANOTHER_PATH:

						switch (this.type) {
							case this.$PlatformsTypes.FileFolder:
								this.mainFolderSelected=this.parentPath.split("\\")[0];
								break;
							case this.$PlatformsTypes.Network:
							case this.$PlatformsTypes.FTP:
								this.parentPath=this.$root.getSARootPath(this.createFolderObj.client.serviceAccount);
								break;

							case this.$PlatformsTypes.Cloud_S3CompatibleStorage:
							case this.$PlatformsTypes.Cloud_IperiusS3Storage:
							case this.$PlatformsTypes.Cloud_AmazonS3Storage:
							case this.$PlatformsTypes.Cloud_AzureBlob:
							case this.$PlatformsTypes.Cloud_AzureFile:
								if (this.createFolderObj.mainFolders.length==0)
									this.createFolderObj.mainFolders=this.createFolderObj.client.getRootNodes().map(node => node.path);
								this.mainFolderSelected=this.createFolderObj.parentPath==''
									? this.createFolderObj.mainFolders[0].path
									:this.createFolderObj.parentPath.split("/")[0];

								//this.parentPath=this.mainFolderSelected;
								break;

							case this.$PlatformsTypes.Cloud_GoogleDrive:
							case this.$PlatformsTypes.Cloud_Dropbox:
							case this.$PlatformsTypes.Cloud_OneDrive:
								this.parentPath="";
								break;
						}
						break;
					case this.ROOT_FOLDER:
						this.parentPath="";
						break;
				}
			}
		},

		mainFolderSelected: {
			handler: function(value) {
				this.parentPath=value;
			}
		},
	},
	computed: {
		nameIsInvalid: function() {
			//Un nome non è valido se
			//presenta alcuni caratteri speciali
			return this.createFolderObj.name.search(/[<>:|?*]/)!=-1||
				//presenta lo slash sbagliato
				(this.createFolderObj.name.search(/[/]/)!=-1&&this.slash=="\\")||
				(this.createFolderObj.name.search(/[\\]/)!=-1&&this.slash=="/")||
				this.createFolderObj.name.startsWith(this.slash);
		}
	},
	methods: {
		async createFolder() {
			this.createFolderObj.name=this.slash=='/'
				? this.createFolderObj.name.replace("\\", "/")
				:this.createFolderObj.name.replace('/', "\\")
			var newPath=this.createFolderObj.name;
			if (this.parentPath!="") {
				newPath=this.parentPath+this.slash+newPath;
			}
			var created=false;
			var newNodes=[];
			var newNode=null;

			var client=this.createFolderObj.client;
			this.wait=true;
			switch (this.type) {
				case this.$PlatformsTypes.FileFolder:
					created=await this.$root.socket("CreateFolder", newPath, null);
					if (created) {
						newNode=new ClientTreeNode("", "", newPath, FileFolderEntityType.FN_Folder);
					} else {
						console.log("Folder '{0}' NOT created".format(newPath));
					}
					break;
				case this.$PlatformsTypes.Network:
					created=await this.$root.socket("CreateFolder", newPath, this.createFolderObj.client.serviceAccount);
					if (created) {
						newNode=new ClientTreeNode("", "", newPath, FileFolderEntityType.FN_Folder);
					} else {
						console.log("Folder '{0}' NOT created".format(newPath));
					}
					break;
				case this.$PlatformsTypes.FTP:
					created=await this.$root.socket("CreateFolder", newPath, this.createFolderObj.client.serviceAccount);
					if (created) {
						newNode=new ClientTreeNode("", "", newPath, FileFolderEntityType.FTP_Folder);
					} else {
						console.log("Folder '{0}' NOT created".format(newPath));
					}
					break;
				case this.$PlatformsTypes.Cloud_AzureBlob:
					newNode=await client.createContainer(this.createFolderObj.name);
					if (newNode==null) {
						console.log("Folder '{0}' NOT created".format(this.createFolderObj.name));
					}
					break;

				case this.$PlatformsTypes.Cloud_AzureFile:
					if (newPath.indexOf(this.slash) == -1) {
						newNode=await client.createShare(newPath);
						if (newNode==null) {
							console.log("Folder '{0}' NOT created".format(this.createFolderObj.name));
						}
						break;
					} 
					
					newNodes=await this.createAllPaths(client, newPath);
					break;
				case this.$PlatformsTypes.Cloud_Dropbox:
				case this.$PlatformsTypes.Cloud_GoogleDrive:
				case this.$PlatformsTypes.Cloud_OneDrive:
					newNodes=await this.createAllPaths(client, newPath);
					if (newNode==null) {
						console.log("Folder '{0}' NOT created".format(newPath));
					}
					break;
				case this.$PlatformsTypes.Cloud_S3CompatibleStorage:
				case this.$PlatformsTypes.Cloud_IperiusS3Storage:
				case this.$PlatformsTypes.Cloud_AmazonS3Storage:
					if (newPath.indexOf(this.slash) == -1) {
						newNode=await client.createBucket(newPath, this.region);
						if (newNode==null) {
							console.log("Folder '{0}' NOT created".format(newPath));
						}
					} else {
						newNodes=await this.createAllPaths(client, newPath);
					}
					break;
				default:
					alert("Implementare chiamate API per creare cartelle sui cloud!!!");
			}
			this.wait=false;

			if (newNodes.length==0&&newNode==null) {
				this.$root.$emit("createitemfeedback", null);
				return;
			}

			if (newNode!=null) {
				console.log("Folder '{0}' created".format(newNode.path));

				var now=new Date().toISOString();
				newNode.creationDate=now;
				newNode.modificationDate=now;
				newNodes.push(newNode);
			}


			this.$root.$emit("createitemfeedback", newNodes);
			this.$root.$emit("CLOSECREATEFOLDERDIALOG");
		},

		async createAllPaths(client, completePath) {
			var parents=completePath.getAllPaths(client ? client.serviceAccount.type : this.$PlatformsTypes.FileFolder);
			var newNode, newNodes=[];
			var parentPath, parentNode;
			var now=new Date();

			for (var i=0; i<parents.length; i++) {
				parentPath=parents[i];
				newNode=client.nodes.find(n => n.path==parentPath);
				if (newNode) {
					parentNode = newNode;
					continue;
				}

				newNode=await client.createFolder(parentPath, parentNode?.resID);
				if (newNode==null) {
					console.log("Folder '{0}' NOT created".format(parentPath));
					return newNodes;
				}
				newNode.creationDate=now.toISOString();
				newNode.modificationDate=now.toISOString();
				newNodes.push(newNode);
				console.log("Folder '{0}' created".format(newNode.path));
			}
			return newNodes;
		},

		showWhatCreate(value) {
			switch (value) {
				case this.IN_SELECTED_PATH:

					switch (this.type) {
						case this.$PlatformsTypes.Cloud_AmazonS3Storage:
						case this.$PlatformsTypes.Cloud_IperiusS3Storage:
						case this.$PlatformsTypes.Cloud_S3CompatibleStorage:
						case this.$PlatformsTypes.Cloud_AzureFile:
							return this.parentPath!=''
						//In AzureBlob non è possibile creare cartelle vuote
						case this.$PlatformsTypes.Cloud_AzureBlob:
							return false;
					}
					return true;
				case this.IN_ANOTHER_PATH:
					switch (this.type) {
						//In AzureBlob non è possibile creare cartelle vuote
						case this.$PlatformsTypes.Cloud_AzureBlob:
							return false;
						case this.$PlatformsTypes.Cloud_AmazonS3Storage:
						case this.$PlatformsTypes.Cloud_IperiusS3Storage:
						case this.$PlatformsTypes.Cloud_S3CompatibleStorage:
						case this.$PlatformsTypes.Cloud_AzureFile:
						case this.$PlatformsTypes.Network:
						case this.$PlatformsTypes.FTP:
							return this.createFolderObj.parentPath!=this.$root.getSARootPath(this.createFolderObj.client.serviceAccount);
					}
					return true;
				case this.ROOT_FOLDER:
					switch (this.type) {
						case this.$PlatformsTypes.Cloud_AmazonS3Storage:
						case this.$PlatformsTypes.Cloud_IperiusS3Storage:
						case this.$PlatformsTypes.Cloud_S3CompatibleStorage:
						case this.$PlatformsTypes.Cloud_AzureBlob:
						case this.$PlatformsTypes.Cloud_AzureFile:
							return true;
					}
					return false;
			}
		}
	}
}

var notValidChars=[
	"<",
	">",
	":",
	"\"",
	"/",
	"\\",
	"|",
	"?",
	"*",
]
</script>