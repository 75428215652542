import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '@/components/Dashboard'
import Login2FA from '@/components/Login2FA'
import Computers from '@/components/Computers'
import Computer from '@/components/Computer'
import Accounts from '@/components/Accounts'
import Schedulation from '@/components/Schedulation'
import Repositories from '@/components/Repositories'
import Backup from '../components/Backup'
import PageNotFound from '../components/PageNotFound'
import Settings from '../components/Settings'
import Groups from '../components/Groups'
import Sessions from '../components/Sessions'
import ActiveSessions from '../components/ActiveSessions'
import CustomClient from '../components/CustomClient'
import Group from '../components/Group'
import Users from '../components/Users'
import Storage from '../components/Storage'
import StatsRemote from '../components/stats/StatsRemote.vue'
import StatsBackup from '../components/stats/StatsBackup.vue'
import Policies from '../components/Policies.vue'
import Licenses from '../components/Licenses.vue'

Vue.use(VueRouter)

const router = new VueRouter({
    //history: createWebHistory(),

    linkExactActiveClass: "",
    routes: [{
            path: '/',
            name: 'login',
            component: () =>
                import ('../components/Login.vue'),
            meta: {
                title: 'Login',
                keepAlive: true
            }
        },
        {
            path: '/login2FA',
            name: 'login2FA',
            component: Login2FA,
            meta: {
                title: 'Login 2FA',
                keepAlive: true
            }
        },
        {
            path: '/dashboard',
            name: 'Dashboard',
            component: Dashboard,
            meta: {
                title: 'Dashboard',
                keepAlive: true
            }
        },
        {
            path: '/storage',
            name: 'Storage Accounts',
            component: Storage,
            meta: {
                title: 'Storage',
                keepAlive: true
            }
        },
        {
            path: '/computers',
            name: 'Computers',
            component: Computers,
            props: true,
            meta: {
                title: 'Computers',
                keepAlive: true
            }
        },
        {
            path: '/computers/computer/:idPC',
            name: 'Computer',
            component: Computer,
            props: true,
            meta: {
                title: 'Computer Details',
                keepAlive: true
            }
            /*beforeEnter(to, from, next) {
                if (this.$store.state.accessToken) {
                    this.$store.dispatch('setInternalComponents', true);
                    next()
                } else
                    next('/')
            }*/
        },
        {
            path: '/computers/computer/:idPC/backup',
            name: 'Create new backup',
            component: Backup,
            props: true,
            meta: {
                title: 'Backup',
                keepAlive: true
            }
            /*beforeEnter(to, from, next) {
                if (this.$store.state.accessToken) {
                    this.$store.dispatch('setInternalComponents', true);
                    next()
                } else
                    next('/')
            }*/
        },
        {
            path: '/computers/computer/:idPC/backup/:idBackup',
            name: 'Edit backup',
            component: Backup,
            props: true,
            meta: {
                title: 'Backup',
                keepAlive: true
            }
            /*beforeEnter(to, from, next) {
                if (this.$store.state.accessToken) {
                    this.$store.dispatch('setInternalComponents', true);
                    next()
                } else
                    next('/')
            }*/
        },
        // {
        //     path: '/computer/:idPC',
        //     name: 'computer',
        //     component: Computer,
        //     props: true,
        //       meta: {
        //           title: 'Computer Details',
        //         keepAlive: true
        //     }
        //     /*beforeEnter(to, from, next) {
        //         if (this.$store.state.accessToken) {
        //             this.$store.dispatch('setInternalComponents', true);
        //             next()
        //         } else
        //             next('/')
        //     }*/
        // },

        // {
        //     path: '/computer/:idPC/backup',
        //     name: 'backupView',
        //     component: Backup,
        //     props: true,
        //       meta: {
        //           title: 'Backup',
        //         keepAlive: true
        //     }
        //     /*beforeEnter(to, from, next) {
        //         if (this.$store.state.accessToken) {
        //             this.$store.dispatch('setInternalComponents', true);
        //             next()
        //         } else
        //             next('/')
        //     }*/
        // },

        // {
        //     path: '/backup',
        //     name: 'backup',
        //     component: Backup,
        //     props: true,
        //       meta: {
        //           title: 'Backup',
        //         keepAlive: true
        //     }
        // },
        // {
        //     path: '/backup/:idBackup',
        //     name: 'backupEdit',
        //     component: Backup,
        //     props: true,
        //       meta: {
        //           title: 'Backup Edit',
        //         keepAlive: true
        //     }
        // },
        {
            path: '/accounts',
            name: 'Accounts',
            component: Accounts,
            props: true,
            meta: {
                title: 'Accounts',
                keepAlive: true
            }
        },
        {
            path: '/repo',
            name: 'Repositories',
            component: Repositories,
            props: true,
            meta: {
                title: 'Repositories',
                keepAlive: true
            }
        },
        {
            path: '/settings',
            name: 'Settings',
            component: Settings,
            props: true,
            meta: {
                title: 'Settings',
                keepAlive: true
            }
        },
        {
            path: '/groups',
            name: 'Groups',
            component: Groups,
            props: true,
            meta: {
                title: 'Groups',
                keepAlive: true
            }
        },
        {
            path: '/groups/:idGroup',
            name: 'Group Details',
            component: Groups,
            props: true,
            meta: {
                title: 'Group Details',
                keepAlive: true
            }
        },
        {
            path: '/policies',
            name: 'Policies',
            component: Policies,
            props: true,
            meta: {
                title: 'Policies',
                keepAlive: true
            }
        },

        {
            path: '/group/:idGroup',
            name: 'group',
            component: Group,
            props: true,
            meta: {
                title: 'Group Details',
                keepAlive: true
            }
        },
        {
            path: '/scheduling',
            name: 'scheduling',
            component: Schedulation,
            props: false,
            meta: {
                title: 'Schedulings',
                keepAlive: true
            }

        },
        {
            path: '/scheduling/:idbackup',
            name: 'schedulingDetail',
            component: Schedulation,
            props: true,
            meta: {
                title: 'Scheduling Detail',
                keepAlive: true
            }
        },
        {
            path: '/users',
            name: 'users',
            component: Users,
            props: true,
            meta: {
                title: 'Users',
                keepAlive: true
            }
        },
        {
            path: '/sessions',
            name: 'sessions',
            component: Sessions,
            props: true,
            meta: {
                title: 'Sessions',
                keepAlive: true
            }
        },
        {
            path: '/activesessions',
            name: 'activesessions',
            component: ActiveSessions,
            props: true,
            meta: {
                title: 'Active Sessions',
                keepAlive: true
            }
        },
        {
            path: '/remotestats',
            name: 'remotestats',
            component: StatsRemote,
            meta: {
                title: 'Remote Stats',
                keepAlive: true
            }
        },
        {
            path: '/customclient',
            name: 'customclient',
            component: CustomClient,
            props: true,
            meta: {
                title: 'Custom client',
                keepAlive: true
            }
        },
        {
            path: '/backupstats',
            name: 'backupstats',
            component: StatsBackup,
            meta: {
                title: 'Backup Stats',
                keepAlive: true
            }
        },
        {
            path: '/licenses',
            name: 'licenses',
            component: Licenses,
            meta: {
                title: 'Licenses',
                keepAlive: true
            }
        },
        {
            path: '/404',
            name: 'PageNotExist',
            component: PageNotFound,
            meta: {
                title: 'Page Not Found',
                keepAlive: true
            }
        },
        {
            path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
            redirect: '/404',
        },

    ],
    mode: "history",
    base: process.env.BASE_URL,
    //mode:'history' //https://router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations
})

window.popStateDetected = false
window.addEventListener('popstate', () => {
    window.popStateDetected = true
})

router.beforeEach((to, from, next) => {
    console.log(`Navigato da ${from.path} a ${to.path}`);
    sessionStorage.setItem('lastRoute', to.fullPath);


    if (to.hash) {
        console.log('Hash rilevato nel router:', to.hash);
        console.log('Url nel router:', to.path);
        // TODO: sarebbe da mettere qui tutta la logica per aprire le dialog di tutte le pagine evitando di metterla dentro un
        //watccher di ogni pagina....
    } else {
        console.log('Nessun hash rilevato nel router');
        // Logica per chiudere una dialog o eseguire un'altra azione 
        //posso fare un controllo che chiude la dialog se ce ne sta una aperta e l'url non ha hastag..
        //faccio quella delle policy per esempio ma si potrebbe invocare un metodo generico che in base alla pagina aperta chiude 
        //la specifica dialog SE aperta
    }
    if (to.meta.styles) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = to.meta.styles;
        document.head.appendChild(link);
    }
    const IsItABackButton = window.popStateDetected;
    window.popStateDetected = false;
    next();
})

router.afterEach((to, from) => {
    console.log(`Navigato da ${from.path} a ${to.path}`);
});

/* router.beforeResolve(to => {
    console.log(to)
    return to.fullPath;
}) */

export default router;