<template>
    <div id="microsoftsource" v-on:keyup.esc="$root.$emit('CLOSESOURCEDIALOG')"
        class="dialog light w-100-sm w-100-md w-75-lg  w-50-xl h-75">

        <DialogTitle :title="$t('Configure Backup Source')" :defaultTitle="$t('Configure Backup Source')" :subTitle="source.type_name"
            @closeDialog="$root.$emit('CLOSESOURCEDIALOG')" />

        <!--SCELTA DEL SERVICE ACCOUNT + SCELTA PERCORSI-->

        <div v-if="step == STEP_SA_AND_SOURCE" class="dialog-content d-flex flex-column overflow">
            <!------------------------------SCELTA DEL SERVICE ACCOUNT--------------------------------------------------------------->
            <!--Questa pagina deve implementare il metodo "sendSATestResult"-->
            <!--type = -1 : il componente visualizzerà la lista passata-->
            <SARepoSelect :type="$root.getMicrosoftTypes()" :idSA=source.id_service_account :idRepo="-2"
                @sendSATestResult="sendSATestResult" />

            <SelectedResourcesLayout :buttonText="buttonText" :singleItem="false" :isDisabled="!testResult.ok"
                :list="source.folderSource.paths" :heightPercentage="40" :canCancel="true" :type="source.type"
                @clickFunction="toggleFileSystemSidebar" class="flex-grow" />

        </div>

        <!--FILTRI-->
        <div v-show="step == STEP_FILTERS" class="dialog-content">
            <div class="form-group">
                <h6 class="text-bold">
                    <span>{{ $t('Filter by extensions') }} ({{ $t('Optional') }})</span>
                </h6>
            </div>

            <div class="form-group">
                <div class="d-flex flex-column">
				    <input name='includeExcludeExtensions' type='radio' data-role='radio' :data-caption="$t('Exclude specified extensions')" v-model="source.options.extensionsFilterAction" :value="0">
				    <input name='includeExcludeExtensions' type='radio' data-role='radio' :data-caption="$t('Include only specified extensions')" v-model="source.options.extensionsFilterAction" :value="1">
			    </div>

                <div class="cell-12">
                    <label class="text-bold">{{ $t("Extensions :") }}</label>
                       <select v-model="specifiedExtensionsArray" multiple data-role="select" class="input">
                            <optgroup v-for="(extensionsList, extensionsCategory) in fileExtensions" :label="$t(extensionsCategory)" :key="extensionsCategory">
                                <option v-for="extension in extensionsList" :value="extension">{{ extension }}</option>
                            </optgroup>
                       </select>
                </div>
            </div>

            <!--FILTRI - DIMENSIONE FILE-->
            <div class="form-group">
                <h6 class="text-bold">
                    <span>{{ $t('Filter by file size') }} ({{ $t('Optional') }})</span>
                </h6>
            </div>
            <div class="form-group">
                <input type="checkbox" v-model="source.options.useFileSizeFilter"
                    :data-caption="$t('Use file size filter')" data-role="checkbox" />
                <div class="w-100 d-flex flex-align-center">
                    <select v-model="source.options.fileSizeFilterAction" :disabled="!source.options.useFileSizeFilter"
                        class="mr-2 h-100">
                        <option v-for="(value, index) in $root.selectValues.fileSizeFilterAction" :value="index"
                            v-bind:key="index">{{ $t(value) }}</option>

                    </select>

                    <select v-model="source.options.fileSizeFilterCompare" :disabled="!source.options.useFileSizeFilter"
                        class="mr-2 h-100">
                        <option v-for="(value, index) in $root.selectValues.fileSizeFilterCompare" :value="index"
                            v-bind:key="index">{{ $t(value) }}</option>
                    </select>

                    <input type="number" v-range="{ min: 1, max: 999999 }" v-number v-model.number="source.options.fileSize" :disabled="!source.options.useFileSizeFilter"
                        class="mr-2" style="width:100px;" />
                    <span> MB</span>
                </div>
            </div>
        </div>


        <!--BACKUP-->
        <div v-show="step == STEP_BACKUP" class="dialog-content">
            <div class="form-group">
                <h6 class="text-bold">
                    <span class="mif-file-upload icon mr-1" />
                    <span>{{ $t('Backup') }}</span>
                </h6>
            </div>
            <div class="form-group row">
                <label>{{ $t("Type") }}:</label>
                <select v-model="source.options.backupType" data-role="select" data-filter="false">
                    <option v-for="key in Object.keys($root.selectValues.backupTypes)" :value="key" v-bind:key="key">{{
                        $t($root.selectValues.backupTypes[key]) }}</option>
                </select>
            </div>

            <div class="form-group row d-flex flex-justify-between">
                <label>{{ $t("Number of copies") }}:</label>
                <input type="number" v-range="{ min: 1, max: 365 }" v-number v-model.number="source.options.nCopies" data-role="input" />
            </div>

            <div class="form-group row d-flex flex-justify-between">
                <label>{{ $t("Number of parallel threads") }}:</label>
                <input type="number" v-range="{ min: 1, max: 10 }" v-number v-model.number="source.options.nThreads" data-role="input" />
            </div>
        </div>
        <!--COMPRESSIONE ZIP-->
        <div class="dialog-content p-4" v-if="step == STEP_COMPRESSION" :disabled="source.options.backupType == 'U'">
            <div class="form-group">
                <h6 class="text-bold">
                    <span>{{ $t('Backup compression') }} ({{ $t('Optional') }})</span>
                </h6>
            </div>
            <div class="form-group">
                <input type="checkbox" v-model="source.options.useArchiveCompression" data-role="checkbox"
                    :data-caption="$t('Enable compression')" />
                <br />
            </div>

            <div class="form-group d-flex flex-justify-between" :disabled="!source.options.useArchiveCompression">
                <div>
                    <label>{{ $t("Archive type") }}</label>
                    <select class="input" v-model="source.options.archiveType">
                        <option v-for="(value, index) in $root.selectValues.archiveTypes" :value="index"
                            v-bind:key="index">
                            {{ $t(value) }}
                        </option>
                    </select>
                </div>
                <!--
    09/09/2024 : Al momento si considera solo il percorso completo (source.options.pathsInArchive = 1) all'interno del file zip.
    Se si vorranno implementare altre modalità, basta attivare questo div
    -->
                <div class="d-none">
                    <label>{{ $t("Paths in archive") }}</label>
                    <select class="input" v-model="source.options.pathsInArchive">
                        <option v-for="(value, index) in $root.selectValues.pathsInArchive" :value="index"
                            v-bind:key="index">{{ $t(value) }}
                        </option>
                    </select>
                </div>
            </div>


            <div class="form-group" :disabled="!source.options.useArchiveCompression">
                <input type="checkbox" v-model="source.options.useArchiveFileCustomName" data-role="checkbox" />
                <label>{{ $t("Give the zip file a custom name") }}</label>
                <br />
                <input type="text" class="input" v-model="source.options.archiveFileCustomName"
                    :disabled="!source.options.useArchiveFileCustomName" required v-fsitem/>
            </div>

            <div class="form-group" :disabled="!source.options.useArchiveCompression">
                <input type="checkbox" v-model="source.options.useArchivePassword" data-role="checkbox"
                    :data-caption="$t('Protect zip files using password')" />
            </div>
            <div :disabled="!source.options.useArchiveCompression || !source.options.useArchivePassword"
                class="form-group">
                <label>{{ $t("Password") }}:</label>
                <input data-role="input" type="password" v-model.trim="source.options.archivePassword"
                    :placeholder="$t('Enter Password')" data-prepend="<span class='mif-lock'></span>"
                    :disabled="!source.options.useArchiveCompression || !source.options.useArchivePassword" />
                <small class="text-muted">{{ $t("Required") }}</small>
                <!--Al momento c'è solo un tipo di protezione-->
                <!--<br />
                <label>{{ $t("Protection type") }}:</label>
                <br />
                <select class="input" v-model="source.options.protectionType"
                    :disabled="!source.options.useArchiveCompression || !source.options.useArchivePassword">
                    <option v-for="(value, index) in $root.selectValues.protectionTypes" :value="index"
                        v-bind:key="index">{{ $t(value) }}
                    </option>
                </select>-->

            </div>
        </div>
        <div class="dialog-actions">
            <button v-on:click="setStep(step - 1)" class="button primary" :disabled="step == 0"><span class="mif-arrow-left mr-2"> </span>{{ $t('Prev') }}</button>
            <button v-if="!showSaveButton" v-on:click="setStep(step + 1)" class="button primary" :disabled="step == STEP_COMPRESSION || source.folderSource.paths.length == 0">{{ $t('Next') }} <span class="mif-arrow-right ml-2"> </span></button>
            <button v-on:click="saveSource" class="button primary" v-if="showSaveButton" >{{ $t('Save') }}</button>
            <button class="button float-right ml-2" @click="$root.$emit('CLOSESOURCEDIALOG')">{{ $t("Close") }}</button>
        </div>
    </div>
</template>
<script>
import DialogTitle from "../../utils/DialogTitle";
//import NoDataAvailable from "../../utils/NoDataAvailable.vue";
import SARepoSelect from "../../utils/SARepoSelect.vue";
import SelectedResourcesLayout from "../../utils/SelectedResourcesLayout.vue";
import { cutText, isValid } from "../../../utils/utilitiesmodule";
import { FileSystemSidebarConfig } from "../../../models/TreeNode";
import { fileExtensions } from "../../../utils/objects";

export default {
    name: "MicrosoftSource",
    props: {
        source: Object,
    },
    components: {
        "DialogTitle": DialogTitle,
        //"NoDataAvailable": NoDataAvailable,
        "SARepoSelect": SARepoSelect,
        SelectedResourcesLayout
    },
    created: async function() {
        this.buttonText=this.source.type==55
            ? this.$t('Select the accounts to be backed up')
            :this.source.type==53
                ? this.$t('Select the sites to be backed up')
                :this.source.type==54
                    ? this.$t('Select the teams and channels to be backed up')
                    :"";

                    if(isValid(this.source.id)){
            this.specifiedExtensionsArray = this.source.options.specifiedExtensions.split(";").filter(e => e!='');
        }
    },
    data() {
        return {
            step: 0,
            testResult: {
                ok: false,
                okNumber: 0,
                serviceAccount: { id: -2, type: this.source.type },
                client: null,// il client della piattaforma che effettuerà le richieste dei list
                list: null,
            },

            buttonText: null,

            STEP_SA_AND_SOURCE: 0,
            STEP_FILTERS: 1,
            STEP_BACKUP: 2,
            STEP_COMPRESSION: 3,

            specifiedExtensionsArray:[],

            fileExtensions:fileExtensions
        }
    },
    computed: {
        showSaveButton() {
            return (this.step==this.STEP_BACKUP&&this.source.options.backupType=='U')||this.step==this.STEP_COMPRESSION
        }
    },
    methods: {
        async sendSATestResult(testResult) {
            this.testResult=testResult;

        },

        setStep(nStep) {
            if (nStep!=0&&this.step==0&&this.source.folderSource.paths.length==0)
                alert("Seleziona almeno un percorso.");
            else this.step=nStep;

            this.$root.$emit("closeFileSystemSidebar");
        },

        toggleFileSystemSidebar() {


            var config=new FileSystemSidebarConfig(
                this.source.type,
                false,
                true,
                this.testResult.client,
                null,//rootpath
                [this.$PlatformsTypes.Microsoft_Teams, this.$PlatformsTypes.Microsoft_SharePoint].includes(this.source.type) //oneLevel
            );

            this.$root.$emit(
                "toggleFileSystemSidebar",
                this.source.folderSource.paths,
                config
            );
        },

        saveSource() {
            if (this.source.folderSource.paths[0].nodeID=="node")
                this.source.folderSource.paths[0].path="";

            if(this.specifiedExtensionsArray.length!=0){
                self.source.options.specifiedExtensions = this.specifiedExtensionsArray.join(";");
            }
            
            if (isValid(this.testResult))
                this.source.id_service_account=this.testResult.serviceAccount.id;
            this.$root.$emit("saveBackupSource", this.source);
        },

        cutText(text, range) {
            return cutText(text, range);
        },

        disableNextButton() {
            return (this.step==0&&(!this.testResult.ok||this.source.folderSource.paths.length==0))||
                this.step==2;
        }
    }
}
</script>
