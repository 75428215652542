import addLicenseDialog from '../components/dialogs/AddLicenseDialog.vue'
//import ManDevicesInLicenseModule from '../components/dialogs/ManDevicesInLicenseModule.vue'
import Navigation from "../components/utils/Navigation.vue";
import { formatDate } from '../utils/utilitiesmodule';
import NoDataAvailable from "../components/utils/NoDataAvailable.vue";
import { PC_MESSAGES } from '../utils/messages';
import PCLicenseLink from '../components/utils/PCLicenseLink.vue';
const qs = require('querystring')

export default {
    name: "Licenses",
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Licenses',
        // all titles will be injected into this template
        titleTemplate: '%s | Iperius One '
    },
    data() {
        return {
            tipo:'',
            computersinlic:{},
            moduleExtDescription: '',
            currLicId: 0,
            currNode:'',
            currModuleIndex: 0,
            loader: false,
            licenses: {},
            searchText: '',
            licenseDetails: null, // Dettagli licenza singola,
            pricing: null,
            currentLicenseLogin: {},
            license: {
                num_users: 1,
                num_workstations: 0,
                num_servers: 0,
                num_vms: 0,
                plugin_exchange: 0,
                plugin_database: 0,
                plugin_tape: 0,
                subscription_duration: 1,
                discount_code: null,
                currency: 'EUR'
            },

        }
    },
    props: {},
    mounted: function() {
        this.licenses = this.$session.get("LICENSES");
        this.licenseDetails = this.$session.getLicense();
        this.roles = this.$api.getRoles();

        this.currentLicenseLogin = this.$root.checkAuth();

    },
    computed: {
        filteredComputers() {
          if (!this.computersinlic?.computers) return [];
      
          return this.computersinlic.computers.filter(comp => {
            const txt = this.searchText.toLowerCase();
            return (
              comp.name.toLowerCase().includes(txt) ||
              comp.custom_name.toLowerCase().includes(txt) ||
              comp.organization_name.toLowerCase().includes(txt)
            );
          });
        }
      },
    created: function() {
     
    },
    components: {
        addLicenseDialog,
        Navigation,
        NoDataAvailable,
        PCLicenseLink,
        //ManDevicesInLicenseModule,
    },
    methods: {

        goBack() {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.replace('/')
        },
        getName() {
            return this.auth = this.$root.checkAuth().firstName + " " + this.$root.checkAuth().lastName;
        },

        formatDate(date) {
            return formatDate(date, this.$moment);
        },    
        
        updateLicense() {
            this.licenses = this.$session.get("LICENSES");
        },

        async getRoles() {
            let self = this;
            var roles = await this.$api.getRoles();
            self.$store.commit('SET_ROLES', roles);
        },

        toggleDeviceInModule(node, index, licObj) {
            this.moduleExtDescription = licObj.description + ' (' + licObj.nome_licenza + ')';
            this.currLicId = licObj.id;
            this.currNode = node;
            this.currModuleIndex = index;
        
            if (node === 'backup') {
                this.computersinlic = this.licenses.backup[index];
                //this.$set(this, 'computersinlic', [...this.licenses.backup[index].computers]); // Notifica Vue
            }    
        
            if (node === 'monitoring') {
                this.computersinlic = this.licenses.monitoring[index];
                //this.$set(this, 'computersinlic', [...this.licenses.monitoring[index].computers]);
            }  
        
            window.Metro.charms.toggle('#mandevicesinlicensemodule');
        },
        

        closeCharms() {
            window.Metro.charms.close('#mandevicesinlicensemodule');
        },

        remotePcFromLicenseModule(pc){
            //PUT "api/licenses/features/enable"
            //PUT "api/licenses/features/disable"

            //per entrambe nel body ci vuole:
            //idc : id computer
            //idf : id_feature


            // 1 fare chiamata API    
            // 2 refresh LICENSES in session
            // 3 aggiornare oggetto per charms

            var dialogID = Math.floor(100000 + Math.random() * 900000).toString();
            let self = this;
           

                this.$root.$on(dialogID, async ok => {
                    try {
                        if (!ok) {
                            return;
                        }
    
                        self.$api.removeComputerFromModule(pc.id,self.currLicId).then(async (value)  => {
                            if (value) {
                                self.$root.toast(this.$t(PC_MESSAGES.REMOVE_FROM_MODULE), 5000, "success")
                                    
                                    this.computersinlic.computers = this.computersinlic.computers.filter(computer => computer.id !== pc.id); 
                                   
                                    let ext_lic_info = await self.$api.getLicenseExtendedInfo();

                                    if (ext_lic_info != null) {
                                       this.$session.setLicenses(ext_lic_info);
                                       this.licenses = this.$session.get("LICENSES");
                                    };

                                }
                                
                        });
    
                        
                    } finally {
                        //self.$router.removeHash(); // prototype implementato in main.js
                        self.$root.$off(dialogID);
                    }
                    return;
    
                });              

            this.$root.openYesNoDialog(dialogID, this.$t("Remove computer from the license module"), this.$t("Are you sure you want to remove " + pc.name + " (" + pc.custom_name + ") from module " + this.moduleExtDescription + " ?"));            



        }




    }
};