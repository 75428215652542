//Veronica : Ho raccolto alcune funzioni che vengono utilizzate in più componenti,
//in modo da non ripetere il codice e renderlo più pulito.

import { DATABASE_MESSAGES, DATACENTER_MESSAGES, DISK_MESSAGES, ERROR, MAILBOXES_MESSAGES, VIRTUAL_MACHINES_MESSAGES } from "./messages";
import { PlatformsTypes, FileFolderEntityType } from "./objects.js";

export function prettyBytes(bytes, prec) { //prec = precision
    if (bytes == "0" || bytes == 0 || isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '0';
    if (typeof prec === 'undefined') prec = 1;
    var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
        number = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, Math.floor(number))).toFixed(prec) + units[number];
}

//Questa è un'altra versione trovata di prettybytes
export function prettyBytes2(num) {
    // jacked from: https://github.com/sindresorhus/pretty-bytes
    if (typeof num !== 'number' || isNaN(num)) {
        throw new TypeError('Expected a number');
    }

    var exponent;
    var unit;
    var neg = num < 0;
    var units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    if (neg) {
        num = -num;
    }

    if (num < 1) {
        return (neg ? '-' : '') + num + ' B';
    }

    exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
    num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
    unit = units[exponent];

    return (neg ? '-' : '') + num + ' ' + unit;
}

export function mConvertToMB(bytes, prec) {
    if (bytes == "0" || isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '0 bytes';
    if (typeof prec === 'undefined') prec = 1;

    return parseFloat((bytes / 1024 / 1024).toFixed(prec).replace(",", "."));
}

export function formatDate(date, momentModule) {
    if (date == null || date == "") return ""
    date = normalizeDateForMomentLibrary(date);
    date = momentModule.utc(date).local().format("LLL");
    return date;
}

export function insertSpacesEveryThreeDigits(value) {
    if (value==null) return "";
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}


export function secondsToFormattedString(string) {
    return Metro.utils.secondsToFormattedString(string);
}

export function fromNow(date, momentModule) {
    if (date == null || date == "") return ""
    date = normalizeDateForMomentLibrary(date);
    date = momentModule.utc(date).local().fromNow();
    return date;
}



export function diffDate(start, end, momentModule) {
    start = momentModule(normalizeDateForMomentLibrary(start));
    end = momentModule(normalizeDateForMomentLibrary(end));
    var diff = end.diff(start);
    return momentModule.utc(diff).format("HH:mm:ss");
}

function normalizeDateForMomentLibrary(date) {
    if (date.indexOf("Z") != -1 && date.indexOf("T") != -1)
        return date.replace("Z", ""); //2023-09-01T11:00:00Z
    if (date.indexOf("Z") == -1 && date.indexOf("T") == -1)
        return date.substr(0, 8) + "T" + date.substr(8); //20230901110000
    return date; //2023(-)09(-)01T11(:)00(:)00
}

//Nota : in Javascript la stringa vuota è uguale a false
export function isValid(value) {
    //return value != undefined && value != null && (typeof(value) != 'string' || (value != "" && value != 'undefined' && value != "null") || (typeof(value).toLowerCase() == 'object' && Object.keys(value).length == 0));
    return value != undefined && value != null && (typeof(value) != 'string' || value != "");
}

export function getValueOrDefault(value, defaultValue) {
    return isValid(value) ? value : defaultValue;
}

export function getFirstValueOrDefault(value1, value2, defaultValue) {
    if (isValid(value1))
        return value1;
    if (isValid(value2))
        return value2;
    return defaultValue;
}

export function cutText(text, x) {

    var l = text.length;

    if (l <= x) return text;

    //if(x<=l && l<=1.5*x) return text.substr(0, x/2) + "..." + text.substr(l-x/2, l);
    return text.substr(0, x / 2) + "..." + text.substr(l - x / 2, l);
}

export async function waitSeconds(seconds) {
    await new Promise(resolve => { setTimeout(resolve, seconds * 1000) });
}

export function createErrorObject(error) {
    var obj = {};
    //STATUS
    obj.status = error.status;

    //MESSAGE
    if (isValid(error.message))
        obj.message = error.message;
    else if (isValid(error.data)) {
        if (typeof(error.data) == 'string')
            obj.message = error.data;
        else if (isValid(error.data.message)) {
            obj.message = error.data.message;
        } else if (isValid(error.data.error)) {
            if (isValid(error.data.error.message)) {
                obj.message = error.data.error.message;
            }
        }
    } else obj.message = ERROR;

    //DATA
    if (isValid(error.data) && typeof(error.data) == 'object') {
        if (isValid(error.data.error) && isValid(error.data.error.errors))
            obj.data = error.data.error.errors[0];
        else obj.data = error.data;
    } else obj.data = null;

    return obj;
}

export function syncronizeArray(type, oldArray, newArray) {
    var utils = getUtils(type);

    return _syncarray(oldArray, newArray, 0, null, utils);
}

function _syncarray(oldArray, newArray, level, parentObj, utils) {
    var comparators = utils.comparators;
    var nextArrayNames = utils.nextArrayNames

    var propForCompare = comparators[level];
    var childArray = nextArrayNames.length == 0 ? null : nextArrayNames[level];
    var result = {
        finalArray: [],
        messages: [] // array di oggetti {message : ..., args : []}
    };

    //Step 0 : Creo un unico array concatenando i due passati e li ordino in base al comparatore in posizione level. 
    //In caso di uguaglianza, dò precedenza agli oggetti old
    var array = newArray.map(d => { d.old = false; return d }).concat(oldArray.map(d => { d.old = true; return d }));
    array.sort((a, b) => {
        if (a[propForCompare] < b[propForCompare])
            return -1;
        if (a[propForCompare] > b[propForCompare])
            return 1;
        if (a.old)
            return -1;
        return 1;
    });

    var a, b;
    //Step 1 : Inizio a scorrere array, analizzando gli oggetti 2 alla volta
    while (array.length != 0) {
        a = array[0];
        //Se l'array ha un solo elemento e è vecchio, significa che nel tempo è stato cancellato e non devo aggiungerlo alla lista in uscita
        if (array.length == 1 && a.old) {
            result.messages.push(createMessage(true, level, utils, a, parentObj));
            array.splice(0, 1);
            continue;
        }

        //Se l'array ha un solo elemento e è nuovo, lo aggiungo all'array in uscita
        if (array.length == 1 && !a.old) {
            result.finalArray.push(a);
            result.messages.push(createMessage(false, level, utils, a, parentObj));
            array.splice(0, 1);
            continue;
        }

        b = array[1];
        console.log(a[propForCompare] + " " + b[propForCompare]);
        var childResult;
        //Caso 1 : Trovo lo stesso oggetto: analizzo lo stato del primo (quello old)
        if (a[propForCompare] == b[propForCompare]) {
            //Caso 1.1 : se a è stato selezionato, devo selezionare  
            if (a.selected) {
                // il corrispettivo b
                b.selected = true;
                // e tutti i figli di b, se esistono
                if (childArray) {
                    childResult = _syncarray(a[childArray], b[childArray], level + 1, a, utils);
                    b[childArray] = childResult.finalArray;
                    result.messages = result.messages.concat(childResult.messages);
                }
                //aggiungo il nuovo b all'array risultato
                result.finalArray.push(b);
                array.splice(0, 2);

                //procedo con l'analisi della prossima coppia
                //i++; // ho trovato una coppia -> analizzo la prossima coppia
                continue;
            }
            //Caso 1.2 : se a è stato parzialmente selezionato
            if (a.indeterminate) {
                //Seleziono parzialmente b
                b.indeterminate = true;
                //Analizzo i figli di a e di b, se esistono
                if (childArray && a[childArray].length != 0) {
                    childResult = _syncarray(a[childArray], b[childArray], level + 1, a, utils);
                    b[childArray] = childResult.finalArray;
                    result.messages = result.messages.concat(childResult.messages);
                }
                //aggiungo il nuovo b all'array risultato
                result.finalArray.push(b);
                //procedo con l'analisi della prossima coppia
                //i++; // ho trovato una coppia -> analizzo la prossima coppia
                array.splice(0, 2);
                continue;
            }

            //Caso 1.3 : se a non è stato selezionato
            //aggiungo il nuovo b all'array risultato, non selezionato per default
            result.finalArray.push(b);
            //procedo con l'analisi della prossima coppia
            //i++; // ho trovato una coppia -> analizzo la prossima coppia
            array.splice(0, 2);
            continue;
        }

        //Caso 2 : Trovo 2 elementi differenti e a è old, a è stato cancellato (perché non si trova in b)
        if (a.old) {
            result.messages.push(createMessage(true, level, utils, a, parentObj));
            array.splice(0, 1);
            continue;
        }

        //Caso 3: Trovo due elementi differenti e a non è old, a è un nuovo elemento, quindi lo aggiungo nell'array risultato
        result.messages.push(createMessage(false, level, utils, a, parentObj));
        result.finalArray.push(a);
        array.splice(0, 1);

    }

    //Applico l'eventuale ordinamento per proprietà
    if (result.finalArray.length != 0) {
        var props = Object.keys(result.finalArray[0]);
        if (props.includes("index")) {
            result.finalArray.sort((a, b) => a.index - b.index);
        } else if (props.includes("partitionNumber")) {
            result.finalArray.sort((a, b) => a.partitionNumber - b.partitionNumber);
        }
    }
    return result;
}

function createMessage(isCancelled, level, utils, currentObj, parentObj) {

    var type = utils.type;

    //DRIVE IMAGE LEVEL 0--------------------------------------------
    if (type == "0" && level == "0" && isCancelled)
        return {
            message: DISK_MESSAGES.NOT_FOUND,
            args: [currentObj.caption]
        };

    if (type == "0" && level == "0" && !isCancelled)
        return {
            message: DISK_MESSAGES.FOUND_NEW,
            args: [currentObj.caption]
        };

    //DRIVE IMAGE LEVEL 1-------------------------------------------
    if (type == "0" && level == "1" && isCancelled)
        return {
            message: DISK_MESSAGES.NOT_FOUND_PARTITION_,
            args: [currentObj.volumeDriveLetter, parentObj.caption]
        };

    if (type == "0" && level == "1" && !isCancelled)
        return {
            message: DISK_MESSAGES.FOUND_NEW_PARTITION,
            args: [currentObj.volumeDriveLetter, parentObj.caption]
        };

    //HYPER-V LEVEL 0--------------------------------------------
    if (type == "3" && level == "0" && isCancelled)
        return {
            message: VIRTUAL_MACHINES_MESSAGES.NOT_FOUND,
            args: [currentObj.name]
        };

    if (type == "3" && level == "0" && !isCancelled)
        return {
            message: VIRTUAL_MACHINES_MESSAGES.FOUND_NEW,
            args: [currentObj.name]
        };

    //HYPER-V LEVEL 1--------------------------------------------
    if (type == "3" && level == "1" && isCancelled)
        return {
            message: VIRTUAL_MACHINES_MESSAGES.NOT_FOUND_VIRTUAL_DISK,
            args: [currentObj.volumeDriveLetter, parentObj.caption]
        };

    if (type == "3" && level == "1" && !isCancelled)
        return {
            message: VIRTUAL_MACHINES_MESSAGES.FOUND_NEW_VIRTUAL_DISK,
            args: [currentObj.name, parentObj.name]
        };

    //ESXI LEVEL 0--------------------------------------------
    if (type == "4" && level == "0" && isCancelled)
        return {
            message: DATACENTER_MESSAGES.NOT_FOUND,
            args: [currentObj.name]
        };

    if (type == "4" && level == "0" && !isCancelled)
        return {
            message: DATACENTER_MESSAGES.FOUND_NEW,
            args: [currentObj.name]
        };

    //ESXI LEVEL 1--------------------------------------------
    if (type == "4" && level == "1" && isCancelled)
        return {
            message: DATACENTER_MESSAGES.NOT_FOUND_COMPUTER,
            args: [currentObj.name, parentObj.name]
        };

    if (type == "4" && level == "1" && !isCancelled)
        return {
            message: DATACENTER_MESSAGES.FOUND_NEW_COMPUTER,
            args: [currentObj.name, parentObj.name]
        };

    //ESXI LEVEL 2--------------------------------------------
    if (type == "4" && level == "2" && isCancelled)
        return {
            message: DATACENTER_MESSAGES.NOT_FOUND_DATASTORE,
            args: [currentObj.name, parentObj.name]
        };

    if (type == "4" && level == "2" && !isCancelled)
        return {
            message: DATACENTER_MESSAGES.FOUND_NEW_DATASTORE,
            args: [currentObj.name, parentObj.name]
        };

    //ESXI LEVEL 3--------------------------------------------
    if (type == "4" && level == "3" && isCancelled)
        return {
            message: DATACENTER_MESSAGES.NOT_FOUND_VIRTUAL_MACHINE,
            args: [currentObj.name, parentObj.name]
        };

    if (type == "4" && level == "3" && !isCancelled)
        return {
            message: DATACENTER_MESSAGES.FOUND_NEW_VIRTUAL_MACHINE,
            args: [currentObj.name, parentObj.name]
        };

    //ESXI LEVEL 4--------------------------------------------
    if (type == "4" && level == "4" && isCancelled)
        return {
            message: VIRTUAL_MACHINES_MESSAGES.NOT_FOUND_VIRTUAL_DISK,
            args: [currentObj.diskFilename, parentObj.name]
        };

    if (type == "4" && level == "4" && !isCancelled)
        return {
            message: VIRTUAL_MACHINES_MESSAGES.FOUND_NEW_VIRTUAL_DISK,
            args: [currentObj.diskFilename, parentObj.name]
        };
    //EXCHANGE LEVEL 0--------------------------------------------
    if (type.startsWith("5") && level == "0" && isCancelled)
        return {
            message: MAILBOXES_MESSAGES.NOT_FOUND,
            args: [currentObj.name]
        };

    if (type.startsWith("5") && level == "0" && !isCancelled)
        return {
            message: MAILBOXES_MESSAGES.FOUND_NEW,
            args: [currentObj.name]
        };

    //DATABASE LEVEL 0--------------------------------------------
    if (type.startsWith("6") && level == "0" && isCancelled)
        return {
            message: DATABASE_MESSAGES.NOT_FOUND,
            args: [currentObj.name]
        };

    if (type.startsWith("6") && level == "0" && !isCancelled)
        return {
            message: DATABASE_MESSAGES.FOUND_NEW,
            args: [currentObj.name]
        };
    return null;
}

function getUtils(type) {
    var comparators;
    var nextArrayNames;
    type = parseInt(type.toString());

    switch (type) {
        case PlatformsTypes.DriveImage: // Drive Image -> source.driveImgSource è un array
            comparators = ["guid", "partitionId"];
            nextArrayNames = ["partitions"]
            break;
        case PlatformsTypes.HyperV: // Hyper-V -> source.hvSource è un array
            comparators = ["name", "name"];
            nextArrayNames = ["virtualHardDisks"];
            break;
        case PlatformsTypes.ESXi: //ESXi -> source.esxiSource.datacenters è un array
            comparators = ["name", "name", "name", "name", "diskFilename"];
            nextArrayNames = ["computerResourceList", "datastores", "virtualMachines", "virtualDisks"];
            break;
        case PlatformsTypes.Microsoft_ExchangeOnPremises:
            comparators = ["name"];
            nextArrayNames = [];
            break;
        case PlatformsTypes.Microsoft_ExchangeOnPremisesEWS:
            comparators = ["name"];
            nextArrayNames = [];
            break;
        case PlatformsTypes.Microsoft_Exchange365:
            comparators = ["name"]; //Exchange Online (365) -> source.exchangeSource.mailboxes è un array
            nextArrayNames = [];
            break;
        case "6":
        case "60":
        case "61": // Database -> source.dbSource.databases è un array
            comparators = ["name"];
            nextArrayNames = [];
            break;
    }

    return {
        "comparators": comparators,
        "nextArrayNames": nextArrayNames,
        "type": type
    };
}



export const cookieManager = {
    setCookie: function(key, value) {
        document.cookie = key + "=" + value + ";path=/";
    },

    getCookie: function(key) {

        var cookie = document.cookie.split(";").find(cookie => cookie.indexOf(key + "=") != -1);

        if (isValid(cookie))
            return cookie.split("=")[1];

        return null;
    },

    deleteCookie: function(key) {
        this.setCookie(key, "");
    },

    // Attendo per un massimo di 60 secondi che il cookie con il nome passato venga popolato.
    // Appena il cookie viene valorizzato ne ritorna il valore.
    // Se dopo 60 secondi il cookie non è stato ancora popolato ritorno null
    waitAndGetCookie: async function(cookieName) {

        var cookieValue;
        for (var i = 1; i <= 30; i++) {
            cookieValue = this.getCookie(cookieName);
            if (isValid(cookieValue)) {
                this.deleteCookie(cookieName);
                return cookieValue;
            }

            await waitSeconds(2);
        }

        return null;
    }

}









export function getNodeType(platformType, isFile, isRoot) {
    switch (platformType) {
        case PlatformsTypes.FileFolder:
        case PlatformsTypes.Network:
            if (isFile)
                return FileFolderEntityType.FN_File;
            return FileFolderEntityType.FN_Folder;
        case PlatformsTypes.FTP:
            if (isFile)
                return FileFolderEntityType.FTP_File;
            return FileFolderEntityType.FTP_Folder;
        case PlatformsTypes.Cloud_AmazonS3Storage:
        case PlatformsTypes.Cloud_AzureBlob:
        case PlatformsTypes.Cloud_AzureFile:
        case PlatformsTypes.Cloud_Dropbox:
        case PlatformsTypes.Cloud_GoogleDrive:
        case PlatformsTypes.Cloud_IperiusS3Storage:
        case PlatformsTypes.Cloud_S3CompatibleStorage:
        case PlatformsTypes.Cloud_OneDrive:
            if (isRoot)
                return FileFolderEntityType.Cloud_Root;
            if (isFile)
                return FileFolderEntityType.Cloud_File;
            return FileFolderEntityType.Cloud_Folder;
        case PlatformsTypes.Microsoft_SharePoint:
            if (isRoot)
                return FileFolderEntityType.SP_Site_All;
            if (isFile)
                return FileFolderEntityType.SP_File;
            return FileFolderEntityType.SP_Folder;
        default:
            if (isFile)
                return FileFolderEntityType.Cloud_File;
            return FileFolderEntityType.Cloud_Folder;
    }
}

export function getRootType(platformType) {
    if ([PlatformsTypes.FileFolder, PlatformsTypes.Network].includes(platformType))
        return FileFolderEntityType.FN_Folder;
    if (platformType == PlatformsTypes.FTP)
        return FileFolderEntityType.FTP_Folder;
    if (platformType == PlatformsTypes.Microsoft_SharePoint)
        return FileFolderEntityType.SP_Site_All;
    if (platformType == PlatformsTypes.Microsoft_Teams)
        return FileFolderEntityType.MT_Team_All;
    return FileFolderEntityType.Cloud_Root;
}




export function generateGuid() {
    // Concatena i numeri casuali per formare il GUID
    return (
        randomHex() + randomHex() + '-' + randomHex() + '-' + randomHex() + '-' +
        randomHex() + '-' + randomHex() + randomHex() + randomHex()
    );
}

// Genera un numero casuale a 32 bit in esadecimale
function randomHex() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

export function assignProperties(from, to) {
    for (let key in from) {

        //Step 1 : se key non ha un valore valido, lo salto
        if (!isValid(from[key]))
            continue;

        //Step 2 : from è un array
        if (Array.isArray(from[key])) {

            //Step 2.1 : se to non ha la proprietà key, la aggiungo
            if (!Array.isArray(to[key])) {
                to[key] = [];
            }
            //Step 2.2 : pulisco l'array esistente in to
            to[key].length = 0; // Clear the existing array
            //Step 2.3 : popolo l'array to[key] con gli elementi di from[key]
            from[key].forEach((item, index) => {
                to[key][index] = item;
            });
            continue;
        }

        //Step 3 : se key è un oggetto
        if (typeof from[key] === 'object' && from[key] !== null) {
            //Step 3.1 : se to non ha l'oggetto from[to], lo inizializzo
            if (typeof to[key] !== 'object' || to[key] === null) {
                to[key] = {};
            }
            //richiamo questo metodo per popolare correttamente l'oggetto to.key
            assignProperties(from[key], to[key]);
            continue;
        }

        //Step 4 : se key è un dato semplice, lo assegno
        to[key] = from[key];
    }
    return to;
}


export function checkInputAsInteger(value, defaultValue = 0) {
    if ([undefined, null, 'undefined', 'null', ''].includes(value)) {
        return defaultValue;
    }
    //Trasforno value in stringa
    value = value + '';
    /*if([undefined, null, ''].includes(value)){
        return defaultValue;
    }*/

    //Rimuovo tutti i caratteri che non sono numeri
    var regexString = "[^0-9]";
    var replaceString = '';
    var regex = new RegExp(regexString);
    var replaceRegex = new RegExp(regexString, 'g');
    if (regex.test(value)) {
        value = value.replace(replaceRegex, replaceString);
    }

    //Se alla fine dei controlli, ho una stringa vuota, ritorno il valore passato di default
    if (value == '') {
        return defaultValue;
    }

    return parseInt(value);
}