var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "locale-changer place-right" }, [
    _c(
      "a",
      {
        staticClass: "dropdown-toggle app-bar-item",
        attrs: { href: "#", title: _vm.$t("Lang") },
      },
      [
        _c(
          "span",
          { staticClass: "icon" },
          [
            _c("country-flag", {
              attrs: {
                country: _vm.locale == "en" ? "gb" : _vm.locale,
                size: "small",
              },
            }),
          ],
          1
        ),
      ]
    ),
    _c(
      "ul",
      {
        staticClass: "d-menu border place-right bg-white mt-2",
        attrs: { id: "langdropdown", "data-role": "dropdown" },
      },
      _vm._l(_vm.languages, function (lang, i) {
        return _c(
          "li",
          {
            key: `Lang${i}`,
            staticClass: "text-small",
            class: { active: lang.language == _vm.$i18n.locale },
            attrs: { value: lang.language },
            on: {
              click: function ($event) {
                _vm.$i18n.locale = lang.language
              },
            },
          },
          [
            _c(
              "a",
              { attrs: { href: "#" } },
              [
                _c("country-flag", {
                  attrs: { country: lang.flag, size: "small" },
                }),
                _vm._v(" " + _vm._s(lang.title)),
              ],
              1
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }