var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Navigation", { attrs: { pageName: "computers" } }),
      _vm.computers == null
        ? _c("div", { staticClass: "row m-5 pt-5" }, [_vm._m(0)])
        : _vm.computers.length == 0
        ? _c("NoDataAvailable", {
            attrs: { message: _vm.$t("No computers available") },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "w-100 p-5 overflow", attrs: { id: "all_computers" } },
        [
          _vm.computers
            ? _c("div", { staticClass: "d-flex flex-justify-start" }, [
                _c("div", { staticClass: "cell-auto pr-2 pl-0" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.id_group,
                          expression: "filters.id_group",
                        },
                      ],
                      staticClass: "pl-2 select text-small",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.filters,
                            "id_group",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("All Groups"))),
                      ]),
                      _vm._l(_vm.groups, function (group) {
                        return _c(
                          "option",
                          {
                            key: group.id_group,
                            domProps: { value: group.id_group },
                          },
                          [_vm._v(_vm._s(group.name_group) + " ")]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "cell-auto flex-justify-end m-0 d-flex flex-align-end pr-2",
                  },
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filters.status,
                            expression: "filters.status",
                          },
                        ],
                        staticClass: "pl-2 select text-small",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.filters,
                              "status",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v(" " + _vm._s(_vm.$t("Online & Offline"))),
                        ]),
                        _c("option", { attrs: { value: "1" } }, [
                          _vm._v(_vm._s(_vm.$t("Online only"))),
                        ]),
                        _c("option", { attrs: { value: "0" } }, [
                          _vm._v(_vm._s(_vm.$t("Offline only"))),
                        ]),
                      ]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.computers != null
            ? _c(
                "table",
                {
                  staticClass: "striped compact table w-100 bg-white border",
                  attrs: { id: "computers-list" },
                },
                [
                  _c("thead", { staticClass: "mb-0" }, [
                    _c("tr", { staticClass: "mb-3" }, [
                      _c(
                        "td",
                        {
                          staticClass: "text-left pl-4",
                          staticStyle: {
                            "vertical-align": "middle",
                            width: "100px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("Device")))]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "text-left",
                          staticStyle: {
                            "vertical-align": "middle",
                            "min-width": "100px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("Last Backup")) + " ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "text-center",
                          staticStyle: {
                            "vertical-align": "middle",
                            width: "100px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("Groups")) + " ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "text-center",
                          staticStyle: { "vertical-align": "middle" },
                        },
                        [_vm._v(_vm._s(_vm.$t("Organization")))]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "text-center",
                          staticStyle: {
                            "vertical-align": "middle",
                            "white-space": "nowrap",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("Private/Public IP")))]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "text-center",
                          staticStyle: { "vertical-align": "middle" },
                        },
                        [_vm._v(_vm._s(_vm.$t("Iperius Remote Client")))]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "text-center",
                          staticStyle: { "vertical-align": "middle" },
                        },
                        [_vm._v(_vm._s(_vm.$t("Monitoring/Backup Agent")))]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "text-center",
                          staticStyle: {
                            "vertical-align": "middle",
                            width: "100px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("Remote Access")))]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "text-center",
                          staticStyle: { "vertical-align": "middle" },
                        },
                        [_vm._v(_vm._s(_vm.$t("License Status")))]
                      ),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    [
                      _vm.computers == null
                        ? _c("tr", { staticClass: "pt-10" }, [_vm._m(1)])
                        : _vm._e(),
                      _vm.computers != null &&
                      Object.keys(_vm.computers).length == 0
                        ? _c("tr", { staticClass: "pt-10" }, [
                            _c(
                              "td",
                              {
                                staticClass: "p-6 text-center",
                                attrs: { colspan: "12" },
                              },
                              [
                                _c("NoDataAvailable", {
                                  attrs: {
                                    message: _vm.$t("No Devices available"),
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._l(_vm.computers, function (computer, id) {
                        return (_vm.filters.status == "" ||
                          (_vm.$root.connectionsStates[computer.id] ==
                          _vm.filters.status
                            ? true
                            : false)) &&
                          (_vm.filters.id_group == "" ||
                            computer.groups.some(
                              (group) => group.id === _vm.filters.id_group
                            ))
                          ? _c("tr", { key: id }, [
                              _c(
                                "td",
                                {},
                                [_c("PCLink", { attrs: { pc: computer } })],
                                1
                              ),
                              _c("td", { staticClass: "text-left" }, [
                                computer.last_job_result != "" &&
                                computer.last_job_name != ""
                                  ? _c("div", { staticClass: "d-flex" }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "c-context-menu mr-1",
                                          class:
                                            computer.last_job_result.getResultValueInfo()
                                              .color,
                                          attrs: {
                                            title: _vm.$t(
                                              computer.last_job_result.getResultValueInfo()
                                                .label
                                            ),
                                          },
                                        },
                                        [_vm._v(" ● ")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "p-0 m-0",
                                          on: {
                                            click: function ($event) {
                                              return _vm.openBackupView(
                                                computer.last_job_id,
                                                computer.id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          computer.last_job_name
                                            ? _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "no-wrap text-bold c-pointer",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        computer.last_job_name
                                                      )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c("br"),
                                          computer.last_job_dt_start
                                            ? _c("label", [
                                                _vm._v(
                                                  "(" +
                                                    _vm._s(
                                                      _vm.formatDate(
                                                        computer.last_job_dt_start
                                                      )
                                                    ) +
                                                    ")"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _c(
                                "td",
                                { staticClass: "text-center" },
                                _vm._l(computer.groups, function (group) {
                                  return _c(
                                    "router-link",
                                    {
                                      key: group.id,
                                      staticClass:
                                        "button fg-dark mr-2 mb-1 small",
                                      attrs: {
                                        key: group.id,
                                        to: "/groups#" + group.id,
                                      },
                                    },
                                    [_vm._v(_vm._s(group.name))]
                                  )
                                }),
                                1
                              ),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(computer.organization_name)),
                              ]),
                              _c("td", { staticClass: "text-center no-wrap" }, [
                                _vm._v(
                                  _vm._s(computer.ipaddress_lan) +
                                    " / " +
                                    _vm._s(computer.ipaddress_public) +
                                    " "
                                ),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(computer.version_remote) + " "),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(computer.version_backup) + " "),
                              ]),
                              _c(
                                "td",
                                { staticClass: "text-center" },
                                [
                                  _c("PCRemoteLink", {
                                    attrs: { pc: computer },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "td",
                                { staticClass: "text-center" },
                                [
                                  _c("PCLicenseLink", {
                                    attrs: { computer: computer },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e()
                      }),
                    ],
                    2
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "h-100 w-100 z-2" }, [
      _c("span", {
        staticClass: "ani-spin fg-primary mif-5x mif-spinner2 pos-center",
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "p-6", attrs: { colspan: "10" } }, [
      _c("div", { staticClass: "d-flex flex-justify-center p-5" }, [
        _c("span", { staticClass: "mif-spinner2 fg-primary ani-spin mif-3x" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }