export const PlatformsTypes = {
    DriveImage: 0,
    Cloud: 1,
    Cloud_GoogleDrive: 10,
    Cloud_S3CompatibleStorage: 110,
    Cloud_IperiusS3Storage: 111,
    Cloud_AmazonS3Storage: 112,
    Cloud_Dropbox: 12,
    Cloud_OneDrive: 13,
    Cloud_AzureBlob: 140,
    Cloud_AzureFile: 141,
    FTP: 2,
    HyperV: 3,
    ESXi: 4,
    Microsoft: 5,
    Microsoft_ExchangeOnPremises: 50,
    Microsoft_ExchangeOnPremisesEWS: 51,
    Microsoft_Exchange365: 52,
    Microsoft_SharePoint: 53,
    Microsoft_Teams: 54,
    Microsoft_OneDriveForBusiness: 55,
    Database: 6,
    Database_SQLServer: 60,
    Database_MySQL: 61,
    FileFolder: 8,
    Network: 81,
    Email: 7,
    Email_GMail: 70,
    Email_Outlook: 71,
    Email_Yahoo: 72,
    Email_ICloud: 73,
    Email_AoL: 74
}

export const platformsTypesInfo = {
    8: {
        mainType: -1,
        name: "Local File/Folder",
        icon: require("../../public/assets/img/icons/file-folder.svg"),
        backupDestinationTypes: [PlatformsTypes.FileFolder, PlatformsTypes.Network, PlatformsTypes.FTP, PlatformsTypes.Cloud /*_AmazonS3Storage, PlatformsTypes.Cloud_AzureBlob, PlatformsTypes.Cloud_AzureFile, PlatformsTypes.Cloud_Dropbox, PlatformsTypes.Cloud_GoogleDrive, PlatformsTypes.Cloud_IperiusS3Storage, PlatformsTypes.Cloud_OneDrive, PlatformsTypes.Cloud_S3CompatibleStorage*/ ],
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: false,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: true,
        forRestoreFileDestination: true,
        enabled: true
    },
    81: {
        mainType: -1,
        name: "Network File/Folder",
        icon: require("../../public/assets/img/icons/network.png"),
        backupDestinationTypes: [PlatformsTypes.FileFolder, PlatformsTypes.Network, PlatformsTypes.FTP, PlatformsTypes.Cloud /*_AmazonS3Storage, PlatformsTypes.Cloud_AzureBlob, PlatformsTypes.Cloud_AzureFile, PlatformsTypes.Cloud_Dropbox, PlatformsTypes.Cloud_GoogleDrive, PlatformsTypes.Cloud_IperiusS3Storage, PlatformsTypes.Cloud_OneDrive, PlatformsTypes.Cloud_S3CompatibleStorage*/ ],
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: true,
        forBackupSource: true,
        forBackupDestination: true,
        forRestoreFileDestination: true,
        enabled: true
    },
    0: {
        mainType: -1,
        name: "Drive Image",
        icon: require("../../public/assets/img/icons/driveimage.png"),
        backupDestinationTypes: [PlatformsTypes.FileFolder, PlatformsTypes.Network],
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: false,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: false,
        enabled: true
    },
    1: {
        mainType: -1,
        name: "Cloud",
        icon: require("../../public/assets/img/icons/cloud-icon.png" /*"../ img/icons/cloud.png"*/ ),
        needSocketConnection: false, //gli elementi vengono recuperati tramite chiamate API
        forServiceAccount: true,
        forRepository: true,
        forBackupSource: false,
        forBackupDestination: true,
        forRestoreFileDestination: false,
        enabled: true
    },
    10: {
        mainType: 1,
        name: "Google Drive",
        icon: require("../../public/assets/img/icons/googledrive.png"),
        needSocketConnection: false, //gli elementi vengono recuperati tramite chiamate API
        forServiceAccount: true,
        forRepository: true,
        forBackupSource: false,
        forBackupDestination: true,
        forRestoreFileDestination: false,
        enabled: true
    },
    110: {
        mainType: 1,
        name: "S3 Compatible",
        icon: require("../../public/assets/img/icons/amazon-s3.svg"),
        needSocketConnection: false, //gli elementi vengono recuperati tramite chiamate API
        forServiceAccount: true,
        forRepository: true,
        forBackupSource: false,
        forBackupDestination: true,
        forRestoreFileDestination: false,
        enabled: true
    },
    111: {
        mainType: 1,
        name: "Iperius Storage S3",
        icon: require("../../public/assets/img/icons/storage_logo_grigio.png"),
        needSocketConnection: false, //gli elementi vengono recuperati tramite chiamate API
        forServiceAccount: true,
        forRepository: true,
        forBackupSource: false,
        forBackupDestination: true,
        forRestoreFileDestination: false,
        enabled: true
    },
    112: {
        mainType: 1,
        name: "Amazon S3",
        icon: require("../../public/assets/img/icons/amazon-s3.svg"),
        needSocketConnection: false, //gli elementi vengono recuperati tramite chiamate API
        forServiceAccount: true,
        forRepository: true,
        forBackupSource: false,
        forBackupDestination: true,
        forRestoreFileDestination: false,
        enabled: true
    },
    12: {
        mainType: 1,
        name: "Dropbox",
        icon: require("../../public/assets/img/icons/dropbox.svg"),
        needSocketConnection: false, //gli elementi vengono recuperati tramite chiamate API
        forServiceAccount: true,
        forRepository: true,
        forBackupSource: false,
        forBackupDestination: true,
        forRestoreFileDestination: false,
        enabled: true
    },
    13: {
        mainType: 1,
        name: "OneDrive",
        icon: require("../../public/assets/img/icons/onedrive.svg"),
        needSocketConnection: false, //gli elementi vengono recuperati tramite chiamate API
        forServiceAccount: true,
        forRepository: true,
        forBackupSource: false,
        forBackupDestination: true,
        forRestoreFileDestination: false,
        enabled: true
    },
    140: {
        mainType: 1,
        name: "Azure Blob",
        icon: require("../../public/assets/img/icons/azure-blob.svg"),
        needSocketConnection: false, //gli elementi vengono recuperati tramite chiamate API
        forServiceAccount: true,
        forRepository: true,
        forBackupSource: false,
        forBackupDestination: true,
        forRestoreFileDestination: false,
        enabled: true
    },
    141: {
        mainType: 1,
        name: "Azure File",
        icon: require("../../public/assets/img/icons/azure-file.svg"),
        needSocketConnection: false, //gli elementi vengono recuperati tramite chiamate API
        forServiceAccount: true,
        forRepository: true,
        forBackupSource: false,
        forBackupDestination: true,
        forRestoreFileDestination: false,
        enabled: true
    },
    2: {
        mainType: -1,
        name: "FTP",
        icon: require("../../public/assets/img/icons/ftp-icon.svg"),
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: true,
        forBackupSource: false,
        forBackupDestination: true,
        forRestoreFileDestination: false,
        enabled: true
    },
    3: {
        mainType: -1,
        name: "Hyper-V",
        icon: require("../../public/assets/img/icons/hyper-v.png"),
        backupDestinationTypes: [PlatformsTypes.FileFolder],
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: false,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: true

    },
    4: {
        mainType: -1,
        name: "ESXi",
        icon: require("../../public/assets/img/icons/esxi-logo.png"),
        backupDestinationTypes: [PlatformsTypes.FileFolder, PlatformsTypes.Network],
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: false //Nel primo rilascio di Enterprise, viene ignorato
    },
    5: {
        mainType: -1,
        name: "Microsoft 365",
        icon: require("../../public/assets/img/icons/microsoft.svg"),
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: true
    },
    50: {
        mainType: 5,
        name: "Exch On Premises",
        icon: require("../../public/assets/img/icons/exchange.png"),
        backupDestinationTypes: [PlatformsTypes.FileFolder, PlatformsTypes.Network],
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: false

    },
    51: {
        mainType: 5,
        name: "Exch.On Prem.(EWS)",
        icon: require("../../public/assets/img/icons/exchange.png"),
        backupDestinationTypes: [PlatformsTypes.FileFolder, PlatformsTypes.Network],
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: false
    },
    52: {
        mainType: 5,
        name: "Exchange 365",
        icon: require("../../public/assets/img/icons/Microsoft Exchange.svg"),
        backupDestinationTypes: [PlatformsTypes.FileFolder, PlatformsTypes.Network],
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: true
    },
    53: {
        mainType: 5,
        name: "SharePoint",
        icon: require("../../public/assets/img/icons/ms-sharepoint.svg"),
        backupDestinationTypes: [PlatformsTypes.FileFolder, PlatformsTypes.Network],
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: true
    },
    54: {
        mainType: 5,
        name: "Microsoft Teams",
        icon: require("../../public/assets/img/icons/teams.svg"),
        backupDestinationTypes: [PlatformsTypes.FileFolder, PlatformsTypes.Network],
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: true
    },
    55: {
        mainType: 5,
        name: "OneDrive for business",
        icon: require("../../public/assets/img/icons/onedrive.svg"),
        backupDestinationTypes: [PlatformsTypes.FileFolder, PlatformsTypes.Network],
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: true
    },
    6: {
        mainType: -1,
        name: "Database",
        icon: require("../../public/assets/img/icons/database.png"),
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        enabled: true
    },
    60: {
        mainType: -1, //6,
        name: "SQL Server",
        icon: require("../../public/assets/img/icons/Microsoft_SqlServer.png"),
        backupDestinationTypes: [PlatformsTypes.FileFolder],
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: true
    },
    61: {
        mainType: 6,
        name: "MySQL",
        icon: require("../../public/assets/img/icons/mysqldatabase.png"),
        backupDestinationTypes: [PlatformsTypes.FileFolder, PlatformsTypes.Network],
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: false
    },
    7: {
        mainType: -1,
        name: "Email",
        icon: require("../../public/assets/img/icons/email-icon.png"),
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: false,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: false
    },
    70: {
        mainType: 7,
        name: "Gmail",
        icon: require("../../public/assets/img/icons/gmail.png"),
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: false,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: false
    },
    71: {
        mainType: 7,
        name: "Outlook",
        icon: require("../../public/assets/img/icons/outlook.png"),
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: false,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: false

    },
    72: {
        mainType: 7,
        name: "Yahoo",
        icon: require("../../public/assets/img/icons/yahoo.png"),
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: false,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: false
    },
    73: {
        mainType: 7,
        name: "iCloud",
        icon: require("../../public/assets/img/icons/icloud.png"),
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: false,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: false
    },
    74: {
        mainType: 7,
        name: "AOL",
        icon: require("../../public/assets/img/icons/aol.png"),
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: false,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: false
    }
};

//...\iperius-enterprise-agent\IperiusEWP\BackupSources\FileFolder\Core\Utils\Enums.cs
export const FileFolderEntityType = {
    FN_File: 0,
    FN_Folder: 1,

    Cloud_Root: 2,
    Cloud_Bucket: 3,
    Cloud_File: 4,
    Cloud_Folder: 5,

    SP_Site_All: 6,
    SP_File: 7,
    SP_Folder: 8,
    SP_Site: 9,
    SP_Library: 10,

    MT_Team: 11,
    MT_Channel: 12,
    MT_PostSection: 13,
    MT_UserChat: 14,

    EX_Note: 15,
    EX_Appointment: 16,
    EX_Contact: 17,
    EX_Task: 18,
    EX_OtherItem: 19,

    FTP_File: 20,
    FTP_Folder: 21,
    CloudAccount: 22,

    MT_Team_All: 23,

    Database: 24,
    Disk: 25,
    DiskPartition: 26,
    VirtualMachine: 27,

    Mailbox_User: 28,
    Mailbox_Impersonation: 29,
    Mailbox_Group: 30,
    Mailbox_Shared: 31,

    CloudAccount_All: 32,
    Mailbox_All: 33,

    MT_Team_AllTeams: 34,

}

export const ResultValue = {
    Error: 0,
    OK: 1,
    SomeWarnings: 2,
    Running: 3,
    Aborted: 4,
    //JobNotNeeded: 5,
    NeverExecuted: 99,
    TerminatedAbnormally: 6
}


export const SystemInfoType = {
    OS_VERSION: 10,
    OS_NAME: 11,
    OS_ARCHITECTURE: 12,
    OS_BUILD_NUMBER: 13,
    ANTIVIRUS: 20,
    WINDOWS_DEFENDER_STATUS: 21,
    FIREWALL: 22,
    WINDOWS_FIREWALL_STATUS: 23,
    ANTI_SPYWARE: 24,
    DOMAIN: 30,
    IS_PART_OF_DOMAIN: 31,
    DOMAIN_ROLE: 32,
    HYPER_V_INSTALLED: 40,
    SQL_INSTANCES_INSTALLED: 41,
    OS_PATCHES_ALERT: 92

}



export const fileExtensions = {
    "Images": [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".tiff"],
    "Documents": [".pdf", ".doc", ".docx", ".txt", ".rtf", ".xls", ".xlsx"],
    "Audio": [".mp3", ".wav", ".flac", ".aac", ".ogg"],
    "Video": [".mp4", ".avi", ".mkv", ".mov", ".flv", ".webm"],
    "Archives": [".zip", ".rar", ".tar", ".gz", ".7z"],
    "Source code": [".js", ".html", ".css", ".php", ".py", ".java", ".cs", ".csproj"]
};