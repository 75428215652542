<template>
    <div v-on:keyup.esc="close()" class="dialog light center text-small overflow w-100-sm w-100-md w-75-lg  w-50-xl draggable-item"
        v-cloak>

        <DialogTitle :title="repository.id == null ? $t('Add Repository') : repository.name"
            :defaultTitle="repository.id == null ? $t('Add Repository') : $t('Edit Repository')"
            :subTitle="repository.type_name" @closeDialog="close()" />

        <div class="dialog-content">


            <!------------------------------SCELTA DEL SERVICE ACCOUNT--------------------------------------------------------------->

            <!--Questa pagina deve implementare il metodo "sendSATestResult"-->
            <SARepoSelect class="form-group p-3" :type="repository.type" :idSA="repository.id_service_account" :idRepo="-2"
                @sendSATestResult="sendSATestResult" ref="saRepoSelect" />

            <!----------------------------------SCELTA DEL PERCORSO------------------------------------------------------------------>

            <div class="form-group p-3" :disabled="repository.id_service_account == -2">
                <span class="mif-folder-open mr-1" />
                <span> {{ $t("Select a path") }}:</span>
                <!--Nota : Se l'utente deve scegliere il sottopercorso, mostro solo il bottone.-->
                <div class="d-flex flex-row  flex-align-baseline">
                    <input v-if="selectedNodes.length == 0" type="text" data-role="input" class="w-75 text-small"/>
                    <input v-else type="text" data-role="input" v-model="selectedNodes[0].path" class="w-75 text-small" v-fsitem/>
                    <a class="button ml-2" @click="toggleFileSystemSidebar" :disabled="testResult.client == null"><span class="mif-folder" /></a>
                </div>
            </div>

            <div class="form-group p-3">
                <label>{{ $t("Name") }}</label>
                <input data-role="input" type="text" v-model.trim="repository.name" maxlength="50"
                    :placeholder="$t('enter name')" data-prepend="<span class='mif-pencil'></span>" v-fsitem/>
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>
        </div>

        <!-------------------------------------SALVATAGGIO-------------------------------------------------------------->
        <div class="dialog-actions">
            
            <button v-if="repository.id !=null" class="button alert ml-2" @click="$root.deleteRepository(repository.id)">
                {{ $t("Delete") }}
            </button>
            <button v-if="repository.id !=null" class="button primary ml-2" @click="$root.duplicateRepository(repository.id)">
                {{ $t("Duplicate") }}
            </button>
            <button class="button primary ml-2" :disabled="repository.id_service_account == -2 || selectedNodes.length == 0 || repository.name == ''" @click="save">
                {{ $t("Save") }}
            </button>
            <button class="button ml-2" @click="close()"><span class="mif-close"></span>{{ $t("Close") }}</button>
        </div>
    </div>
</template>

<script>
import DialogTitle from "../../utils/DialogTitle";
import SARepoSelect from "../../utils/SARepoSelect";
import { isValid, getNodeType } from "../../../utils/utilitiesmodule";
import { draggableMixin } from '../../../mixins/draggable-mixin'


export default {

    mixins: [draggableMixin],
    name: "Repository",
    props: {
        repository: Object,
    },
    components: {
        "DialogTitle": DialogTitle,
        SARepoSelect
    },
    watch: {
        selectedNodes: {
            handler: function(value) {
                if (value.length==0) {
                    return;
                }

                this.setPathAndName(value[0].path);
            }
        }
    },
    data: function() {
        return {
            selectedNodes: [],
            testResult: {
                ok: false,
                okNumber: 0,
                serviceAccount: { id: -2, type: this.repository.type },
                client: null,// il client della piattaforma che effettuerà le richieste dei list
                list: null,
            }
        }
    },
    created() {
        //Se si sta creando un nuovo repository, non devo effettuare alcun controllo
        if (!isValid(this.repository.id)) {
            this.selectedNodes.push({
                path: "",
                type: getNodeType(this.repository.type, false)
            });
            return;
        }


        //Controllo se il service account esiste ancora
        this.testResult.serviceAccount=this.$session.getServiceAccountWithID(this.repository.id_service_account);

        //Se il service account è stato cancellato, pulisco il percorso che era salvato
        if (this.testResult.serviceAccount==null) {
            this.repository.path="";
            this.testResult.serviceAccount={ id: -2, type: this.repository.type };
            //return;
        }

        this.selectedNodes.push({
            path: this.repository.path,
            type: getNodeType(this.repository.type, false)
        });

    },

    beforeDestroy: function() {
        this.close();
    },
    methods: {
        sendSATestResult(testResult) {
            this.testResult=testResult;
            this.repository.id_service_account=testResult.serviceAccount.id;
            /*if (this.testResult.ok && !isValid(this.repository.id)) {
                this.selectedNodes.splice(0, 1);
            }*/
            //if ([this.$PlatformsTypes.Network, this.$PlatformsTypes.FTP].includes(testResult.serviceAccount.type)) {
            this.selectedNodes[0].path=this.$root.getSARootPath(testResult.serviceAccount);
            this.setPathAndName(this.selectedNodes[0].path);
            //}
        },
        async toggleFileSystemSidebar() {

            if (isValid(this.testResult.serviceAccount.options.lastTestPC)&&this.$root.connectionsStates[this.testResult.serviceAccount.options.lastTestPC]) {
                var pc=this.$session.getPCWithID(this.testResult.serviceAccount.options.lastTestPC);
                await this.$root.socket("SETPC", pc);
            }


            //if ([2, $PlatformsTypes.Network].includes(this.repository.type) && this.client.serviceAccount.options.accountSessionId == "") {
            //var method = this.repository.type == 2 ? "connectftp" : "connectnetwork";
            //this.client.serviceAccount.options.accountSessionId = await this.$root.socket(method, null, this.client.serviceAccount);
            //}

            var config={
                type: this.repository.type,
                includeFiles: false,
                multipleChoice: false,
                client: this.testResult.client,
                rootPath: this.$root.getSARootPath(this.testResult.serviceAccount)
            };

            this.$root.$emit("toggleFileSystemSidebar", this.selectedNodes, config);
        },

        async save() {
            let self=this;
            this.$root.$emit("closeFileSystemSidebar");
            self.repository.path=this.selectedNodes[0].path;
            if (self.repository.type==this.$PlatformsTypes.FTP&&!self.repository.path.startsWith(this.testResult.serviceAccount.url))
                self.repository.path=this.testResult.serviceAccount.url+"/"+self.repository.path;
            await this.$root.saveRepository(self.repository);
        },

        close() {
            this.$root.$emit('CLOSEREPOSITORYDIALOG');
            this.$root.$emit("closeFileSystemSidebar");
            //In caso di ftp o network, eseguo la disconnessione
            if (this.testResult.ok&&[this.$PlatformsTypes.FTP, this.$PlatformsTypes.Network].includes(this.repository.type)) {
                var method=this.repository.type==2? "disconnectftp":"disconnectnetwork";
                this.$root.socket(method, this.testResult.client.serviceAccount.options.accountSessionId);
                if (this.$session.getRoomPC()==null){
                    this.$root.socket("SETPC", null);
                }
            }
        },

        setPathAndName(path) {
            var config={
                path: path,
                type: this.repository.type
            };

            if ([this.$PlatformsTypes.Network, this.$PlatformsTypes.FTP].includes(this.repository.type)) {
                config.prefix=this.$root.getSARootPath(this.testResult.serviceAccount);
            }

            if (this.repository.type!=this.$PlatformsTypes.FileFolder) {
                config.saName=this.testResult.serviceAccount.name;
            }

            [this.repository.path, this.repository.name]=this.$root.getPathAndName(config);

        },

        setPathAndName_old(path) {
            this.repository.path=path;

            if (this.repository.type==this.$PlatformsTypes.FTP&&!this.repository.path.startsWith(this.testResult.serviceAccount.url)) {
                this.repository.path=this.testResult.serviceAccount.url+"/"+this.repository.path;
            }

            // se il nome era già stato assegnato (sono in edit), non lo riassegno
            if (isValid(this.repository.id)) {
                return;
            }

            if (path=='') {
                this.repository.name='';
                return;
            }


            switch (this.repository.type) {
                case this.$PlatformsTypes.FileFolder:
                    path=path.trimEnd("\\").trimEnd(":");
                    this.repository.name=this.$session.getRoomPC().name+" - "+path.lastPartOfPath(); //nome pc + ultima parte del percorso
                    break;
                /*case this.$PlatformsTypes.Network:
                case this.$PlatformsTypes.FTP:
                    var partsOfPath = path.split(path.getSeparator()).filter(p =>p!='');
                    this.repository.name = partsOfPath[0] + " - " + partsOfPath.at(-1);
                    break;*/
                default:
                    this.repository.name=this.testResult.serviceAccount.name+" - "+path.lastPartOfPath();
                    break;
            }

        },
    }
}
</script>