var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.pc
    ? _c(
        "div",
        {
          key: _vm.$route.params.idPC,
          staticClass: "w-100 no-overflow",
          staticStyle: { height: "100%" },
        },
        [
          _vm.terminal.toggleTerminal
            ? _c(
                "div",
                {
                  attrs: {
                    id: "terminalwindow",
                    "data-cls-window": "z-10",
                    "data-role": "window",
                    "data-title": _vm.$t("Command Prompt"),
                    "data-resizable": "true",
                    "data-draggable": "true",
                    "data-place": "top-center",
                    "data-btn-max": "false",
                    "data-btn-min": "false",
                    "data-shadow": "true",
                    "data-width": "500",
                    "data-close-action": "Metro.actions.HIDE",
                    "data-cls-caption": "bg-light fg-dark pl-2 text-bold ",
                    "data-height": "400",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.terminal.terminalLogged,
                          expression: "terminal.terminalLogged",
                        },
                      ],
                    },
                    [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.messageToTerminal()
                            },
                          },
                        },
                        [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.terminal.terminalText,
                                expression: "terminal.terminalText",
                                modifiers: { trim: true },
                              },
                            ],
                            staticClass:
                              "bg-black border-visible-none fg-white w-100 p-4",
                            staticStyle: {
                              "font-family": "system-ui",
                              height: "calc(100% - 35px)",
                              "font-size": "14px",
                            },
                            attrs: { id: "textarea", readonly: "" },
                            domProps: { value: _vm.terminal.terminalText },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.terminal,
                                  "terminalText",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.terminal.terminalInput,
                                expression: "terminal.terminalInput",
                                modifiers: { trim: true },
                              },
                            ],
                            attrs: {
                              type: "text",
                              "data-role": "input",
                              "data-clear-button": "false",
                              "data-history": "true",
                              "data-prepend": ">",
                              "data-prevent-submit": "true",
                              "data-on-search-button-click":
                                _vm.messageToTerminal,
                              "data-search-button-icon":
                                "<span class='mif-keyboard-return'></span>",
                            },
                            domProps: { value: _vm.terminal.terminalInput },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.terminal,
                                  "terminalInput",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  !_vm.terminal.terminalLogged
                    ? _c("div", [
                        _c(
                          "form",
                          {
                            staticClass: "form p-5",
                            on: {
                              subtmit: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "prevent()",
                                    undefined,
                                    $event.key,
                                    undefined
                                  )
                                )
                                  return null
                                return _vm.loginTerminal.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._m(0),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "cell-sm-12" }, [
                                _c("label", { staticClass: "mt-1 no-wrap" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("Computer username")) + ":"
                                  ),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.terminal.username,
                                      expression: "terminal.username",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  staticClass: "input",
                                  attrs: { type: "text" },
                                  domProps: { value: _vm.terminal.username },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.terminal,
                                        "username",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "cell-sm-12" }, [
                                _c("label", { staticClass: "mt-1" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("Computer password")) + ":"
                                  ),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.terminal.password,
                                      expression: "terminal.password",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  staticClass: "input",
                                  attrs: {
                                    "data-validate": "required",
                                    "data-role": "input",
                                    type: "password",
                                    placeholder: _vm.$t("Password"),
                                  },
                                  domProps: { value: _vm.terminal.password },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.terminal,
                                        "password",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                                _c(
                                  "span",
                                  { staticClass: "invalid_feedback" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("Please enter a valid password")
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "row text-center" }, [
                              _c("div", { staticClass: "cell-sm-12 pt-5" }, [
                                _c("input", {
                                  staticClass: "button primary w-25",
                                  attrs: {
                                    disabled:
                                      _vm.terminal.password == "" ||
                                      _vm.terminal.username == "",
                                    type: "button",
                                    value: _vm.$t("Login"),
                                  },
                                  on: { click: _vm.loginTerminal },
                                }),
                              ]),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm.pc != null
            ? _c("Navigation", {
                attrs: { pageName: "computer", data: { pc: _vm.pc } },
              })
            : _vm._e(),
          _vm.pc
            ? _c("div", { key: _vm.computerKey, staticClass: "w-100 h-100" }, [
                _c("div", [
                  _c("div", { staticClass: "overflow flex-align-left" }, [
                    _vm.pc
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-sm-12 col-md-12 col-lg-12 col-xl-12 pl-4 p-2",
                          },
                          [
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "bg-transparent text-small pl-2 tabs-material",
                                staticStyle: { "z-index": "1" },
                                attrs: {
                                  "data-role": "materialtabs",
                                  id: "backups_tabs",
                                  "data-expand": "true",
                                  "data-on-tab":
                                    "console.log('tab', arguments)",
                                  "data-on-tab-close":
                                    "console.log('close', arguments)",
                                  "data-on-tab-open":
                                    "console.log('open', arguments)",
                                  "data-update-uri": "true",
                                },
                              },
                              [
                                _c(
                                  "li",
                                  {
                                    staticClass:
                                      "d-flex flex-align-center mt-0 pt-0 active",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#sysinfo" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openTab(1)
                                          },
                                        },
                                      },
                                      [
                                        _vm.pc.av_alert != 0 ||
                                        _vm.pc.disk_alert != 0
                                          ? _c(
                                              "span",
                                              { staticClass: "fg-red" },
                                              [_vm._v(" ● ")]
                                            )
                                          : _vm._e(),
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.$t("Overview"))),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "li",
                                  {
                                    staticClass:
                                      "d-flex flex-align-center mt-0 pt-0",
                                    attrs: {
                                      disabled:
                                        _vm.socket_backups == null ||
                                        _vm.onlyremoteinstalled ||
                                        !_vm.$root.connectionsStates[_vm.pc.id],
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#backups" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openTab(2)
                                          },
                                        },
                                      },
                                      [
                                        _vm.is_global_running.jobs == true
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "fg-cobalt ani-flash",
                                              },
                                              [_vm._v(" ● ")]
                                            )
                                          : _vm._e(),
                                        _c("span", [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("Backup"))
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "li",
                                  {
                                    staticClass:
                                      "d-flex flex-align-center mt-0 pt-0",
                                    attrs: {
                                      disabled:
                                        _vm.restores == null ||
                                        _vm.onlyremoteinstalled,
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#restores" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openTab(3)
                                          },
                                        },
                                      },
                                      [
                                        _vm.is_global_running.restores == true
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "fg-cobalt ani-flash",
                                              },
                                              [_vm._v(" ● ")]
                                            )
                                          : _vm._e(),
                                        _c("span", [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("Restore"))
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "li",
                                  {
                                    staticClass:
                                      "d-flex flex-align-center mt-0 pt-0",
                                    attrs: {
                                      disabled: _vm.processesList.length == 0,
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#taskmanager" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openTab(4)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("Task Manager"))
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "li",
                                  {
                                    staticClass:
                                      "d-flex flex-align-center mt-0 pt-0",
                                    attrs: {
                                      disabled:
                                        _vm.filesystem == null ||
                                        !_vm.$root.connectionsStates[_vm.pc.id],
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#filemanager" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openTab(5)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("File Explorer"))
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "li",
                                  {
                                    staticClass:
                                      "d-flex flex-align-center mt-0 pt-0",
                                    attrs: {
                                      disabled:
                                        _vm.eventlogs == null ||
                                        !_vm.$root.connectionsStates[_vm.pc.id],
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#eventlogs" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openTab(6)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("Event Viewer"))
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "li",
                                  {
                                    staticClass:
                                      "d-flex flex-align-center mt-0 pt-0",
                                    attrs: { disabled: _vm.patches == null },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#patches" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openTab(7)
                                          },
                                        },
                                      },
                                      [
                                        _vm.pc.os_patches_alert.info_value ==
                                        "True"
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "fg-red ani-flash",
                                              },
                                              [_vm._v(" ● ")]
                                            )
                                          : _vm._e(),
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("Patches & Updates")
                                              )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "w-100 active p-3",
                        staticStyle: { height: "75vh", overflow: "auto" },
                        attrs: { id: "backups" },
                      },
                      [
                        _c(
                          "div",
                          [
                            _vm.socket_backups == null ||
                            Object.keys(_vm.socket_backups).length == 0
                              ? _c("NoDataAvailable", {
                                  attrs: {
                                    message: _vm.$t("No backups available"),
                                  },
                                })
                              : [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row pb-1 d-flex flex-justify-start",
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.filters.sourceType,
                                                expression:
                                                  "filters.sourceType",
                                              },
                                            ],
                                            staticClass:
                                              "select text-small w-auto pl-2 pr-2",
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  _vm.filters,
                                                  "sourceType",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              { attrs: { value: "-1" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("All source types")
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._l(
                                              _vm.filters.sourceTypesList,
                                              function (type) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: type,
                                                    domProps: { value: type },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$platformsTypesInfo[
                                                          type
                                                        ].name
                                                      ) + " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]),
                                      _c("div", { staticClass: "ml-4" }, [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.filters.destinationType,
                                                expression:
                                                  "filters.destinationType",
                                              },
                                            ],
                                            staticClass:
                                              "select text-small w-auto pl-2 pr-2",
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  _vm.filters,
                                                  "destinationType",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              { attrs: { value: "-1" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "All destination types"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._l(
                                              _vm.filters.destinationTypesList,
                                              function (type) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: type,
                                                    domProps: { value: type },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$platformsTypesInfo[
                                                          type
                                                        ].name
                                                      ) + " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]),
                                      _c("div", { staticClass: "ml-4" }, [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.filters.lastresult,
                                                expression:
                                                  "filters.lastresult",
                                              },
                                            ],
                                            staticClass:
                                              "select text-small w-auto pl-2 pr-2",
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  _vm.filters,
                                                  "lastresult",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              { attrs: { value: "-1" } },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("All results"))
                                                ),
                                              ]
                                            ),
                                            _vm._l(
                                              _vm.filters.resultList,
                                              function (v, k) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: v,
                                                    domProps: { value: v },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.$t(k)) + " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "overflow bg-white border table table-sm striped compact p-2",
                                      attrs: { id: "backup-list" },
                                    },
                                    [
                                      _c("thead", { staticClass: "mb-0" }, [
                                        _c("tr", { staticClass: "mb-3" }, [
                                          _c(
                                            "td",
                                            {
                                              staticClass: "text-center",
                                              staticStyle: {
                                                "vertical-align": "middle",
                                                width: "10px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("Last Result"))
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass: "text-left pl-4",
                                              staticStyle: {
                                                "vertical-align": "middle",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("Backup name"))
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass: "text-right no-wrap",
                                              staticStyle: {
                                                "vertical-align": "middle",
                                                width: "50px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("Source Type"))
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass: "text-right no-wrap",
                                              staticStyle: {
                                                "vertical-align": "middle",
                                                width: "50px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("Dest. Type"))
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass: "text-right no-wrap",
                                              staticStyle: {
                                                "vertical-align": "middle",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("Start")) +
                                                  " / " +
                                                  _vm._s(_vm.$t("End")) +
                                                  " / " +
                                                  _vm._s(_vm.$t("Duration"))
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass: "text-center",
                                              staticStyle: {
                                                "vertical-align": "middle",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("Data")) + " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass: "text-center",
                                              staticStyle: {
                                                "vertical-align": "middle",
                                                width: "200px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("Current operation")
                                                ) + " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass: "text-center",
                                              staticStyle: {
                                                "vertical-align": "middle",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("Scheduling"))
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "text-center no-wrap",
                                              staticStyle: {
                                                "vertical-align": "middle",
                                                width: "50px",
                                              },
                                            },
                                            [_vm._v(_vm._s(_vm.$t("Next run")))]
                                          ),
                                          _c("td", {
                                            staticClass: "text-center no-wrap",
                                            staticStyle: {
                                              "vertical-align": "middle",
                                            },
                                          }),
                                        ]),
                                      ]),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          _vm.socket_backups,
                                          function (job, id, index) {
                                            return (job.type ==
                                              _vm.filters.sourceType ||
                                              _vm.filters.sourceType == -1 ||
                                              _vm.filters.sourceType ==
                                                _vm.$platformsTypesInfo[
                                                  job.type
                                                ].mainType) &&
                                              (job.lastresult ==
                                                _vm.filters.lastresult ||
                                                _vm.filters.lastresult == -1) &&
                                              (job.destination_type ==
                                                undefined ||
                                                job.destination_type ==
                                                  _vm.filters.destinationType ||
                                                _vm.filters.destinationType ==
                                                  -1 ||
                                                _vm.filters.destinationType ==
                                                  _vm.$platformsTypesInfo[
                                                    job.destination_type
                                                  ].mainType)
                                              ? _c(
                                                  "tr",
                                                  {
                                                    key: id + "-" + index,
                                                    attrs: { "track-by": id },
                                                  },
                                                  [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-center",
                                                      },
                                                      [
                                                        _c("LastResult", {
                                                          attrs: {
                                                            isRunning:
                                                              job.isRunning,
                                                            lastresult:
                                                              job.lastresult,
                                                            progress:
                                                              job.progress,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass: "overflow",
                                                        staticStyle: {
                                                          "min-width": "200px",
                                                          "max-width": "350px",
                                                        },
                                                        attrs: {
                                                          title: job.name,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.jobAction(
                                                              "see",
                                                              job
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "text-bold button flat-button",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(job.name)
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-right",
                                                        staticStyle: {
                                                          width: "50",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "badge inline bg-cobalt fg-white p-1 mr-1",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                job.type_name.toUpperCase()
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-right",
                                                        staticStyle: {
                                                          width: "50",
                                                        },
                                                      },
                                                      [
                                                        job.destination_type
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "badge inline bg-cobalt fg-white p-1 mr-1",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$platformsTypesInfo[
                                                                      job
                                                                        .destination_type
                                                                    ].name.toUpperCase()
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-right no-wrap",
                                                      },
                                                      [
                                                        job.dt_start_utc !=
                                                          "" &&
                                                        job.dt_start_utc != null
                                                          ? _c("label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.formatDate(
                                                                    job.dt_start_utc
                                                                  )
                                                                )
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                        _c("br"),
                                                        !job.isRunning &&
                                                        job.dt_end_utc != "" &&
                                                        job.dt_end_utc != null
                                                          ? _c("label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.formatDate(
                                                                    job.dt_end_utc
                                                                  )
                                                                )
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                        _c("br"),
                                                        !job.isRunning &&
                                                        job.dt_end_utc != "" &&
                                                        job.dt_end_utc != null
                                                          ? _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "text-bold",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.diffDate(
                                                                      job.dt_start_utc,
                                                                      job.dt_end_utc
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-center no-wrap",
                                                      },
                                                      [
                                                        job.lastresult !=
                                                        _vm.$ResultValue
                                                          .NeverExecuted
                                                          ? _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  job.processed_data_string
                                                                )
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticStyle: {
                                                          "max-width": "200px",
                                                        },
                                                      },
                                                      [
                                                        job.isRunning
                                                          ? _c("div", [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "place-left text-truncate pl-3",
                                                                  attrs: {
                                                                    title:
                                                                      job.current_operation,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      job.current_operation
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                        job.last_error_desc &&
                                                        job.last_error_desc !=
                                                          ""
                                                          ? _c("div", [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "place-left text-truncate pl-3",
                                                                  attrs: {
                                                                    title:
                                                                      job.last_error_desc,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      job.last_error_desc
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-center",
                                                      },
                                                      [
                                                        _vm.jobPoliciesList[
                                                          job.id
                                                        ] != null
                                                          ? _c("div", [
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "d-flex flex-justify-center flex-wrap text-center button flat-button",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openDialogPolicy(
                                                                          _vm
                                                                            .jobPoliciesList[
                                                                            job
                                                                              .id
                                                                          ][0]
                                                                            .id
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "label",
                                                                    {},
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm
                                                                            .jobPoliciesList[
                                                                            job
                                                                              .id
                                                                          ][0]
                                                                            .name
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mini",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.cronstrue.toString(
                                                                          _vm
                                                                            .jobPoliciesList[
                                                                            job
                                                                              .id
                                                                          ][0]
                                                                            .policy
                                                                            .scheduling[0],
                                                                          {
                                                                            locale:
                                                                              _vm
                                                                                .$i18n
                                                                                .locale,
                                                                            dayOfWeekStartIndexZero: false,
                                                                          }
                                                                        )
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                            ])
                                                          : job.scheduling !=
                                                              null &&
                                                            job.scheduling != ""
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "mini",
                                                                attrs: {
                                                                  title:
                                                                    job.scheduling !=
                                                                      null &&
                                                                    job.scheduling !=
                                                                      ""
                                                                      ? _vm.$t(
                                                                          "Scheduled"
                                                                        )
                                                                      : _vm.$t(
                                                                          "Not Scheduled"
                                                                        ),
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.cronstrue.toString(
                                                                        job.scheduling,
                                                                        {
                                                                          locale:
                                                                            _vm
                                                                              .$i18n
                                                                              .locale,
                                                                          dayOfWeekStartIndexZero: false,
                                                                        }
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-center",
                                                      },
                                                      [
                                                        _vm.jobPoliciesList[
                                                          job.id
                                                        ] != null
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "mini",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.nextSched(
                                                                        _vm
                                                                          .jobPoliciesList[
                                                                          job.id
                                                                        ][0]
                                                                          .policy
                                                                          .scheduling[0]
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "no-wrap pos-relative",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "button dropdown-toggle",
                                                          },
                                                          [
                                                            _c("span", {
                                                              staticClass:
                                                                "mif-menu",
                                                            }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "ul",
                                                          {
                                                            staticClass:
                                                              "d-menu border drop-left bg-white mt-2",
                                                            attrs: {
                                                              "data-role":
                                                                "dropdown",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "li",
                                                              {
                                                                staticClass:
                                                                  "bg-lightcobalt fg-white",
                                                              },
                                                              [
                                                                job.isRunning
                                                                  ? _c(
                                                                      "a",
                                                                      {
                                                                        attrs: {
                                                                          href: "#",
                                                                          title:
                                                                            _vm.$t(
                                                                              "Stop job"
                                                                            ),
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.jobAction(
                                                                                "stop",
                                                                                job
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "mif-stop icon",
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "Stop"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "a",
                                                                      {
                                                                        attrs: {
                                                                          href: "#",
                                                                          title:
                                                                            _vm.$t(
                                                                              "Run Job"
                                                                            ),
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.jobAction(
                                                                                "run",
                                                                                job
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "mif-play icon",
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "Run"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                              ]
                                                            ),
                                                            _c("li", [
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href: "#",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.jobAction(
                                                                          "restore",
                                                                          job
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "mif-undo icon",
                                                                  }),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "Restore"
                                                                        )
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                            _c("li", {
                                                              staticClass:
                                                                "divider",
                                                            }),
                                                            _c("li", [
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href: "#",
                                                                    title:
                                                                      _vm.$t(
                                                                        "Job details"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.jobAction(
                                                                          "see",
                                                                          job
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "mif-eye icon",
                                                                  }),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "View"
                                                                        )
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                            _c("li", [
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href: "#",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.jobAction(
                                                                          "edit",
                                                                          job
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "mif-pencil icon",
                                                                  }),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "Edit"
                                                                        )
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                            _c("li", [
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href: "#",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.jobAction(
                                                                          "duplicate",
                                                                          job
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "mif-files-empty icon",
                                                                  }),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "Duplicate"
                                                                        )
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                            _c("li", {
                                                              staticClass:
                                                                "divider",
                                                            }),
                                                            _c("li", [
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href: "#",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.jobAction(
                                                                          "delete",
                                                                          job
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "mif-bin icon",
                                                                  }),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "Delete"
                                                                        )
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                            _c("li", {
                                                              staticClass:
                                                                "divider",
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e()
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ],
                          ],
                          2
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "w-100 p-3",
                        staticStyle: { "overflow-y": "auto" },
                        attrs: { id: "restores" },
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { height: "75vh" } },
                          [
                            _vm.restores == null ||
                            Object.keys(_vm.restores).length == 0
                              ? _c("NoDataAvailable", {
                                  attrs: {
                                    message: _vm.$t("No restore in progress"),
                                  },
                                })
                              : _c(
                                  "table",
                                  {
                                    staticClass:
                                      "bg-white border striped compact table w-100",
                                    attrs: { id: "restore-list" },
                                  },
                                  [
                                    _c("thead", { staticClass: "mb-0" }, [
                                      _c("tr", { staticClass: "mb-3" }, [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-center",
                                            staticStyle: {
                                              "vertical-align": "middle",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("Last Result"))
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-center",
                                            staticStyle: {
                                              "vertical-align": "middle",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("Name")))]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-center",
                                            staticStyle: {
                                              "vertical-align": "middle",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("Type")))]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-center",
                                            staticStyle: {
                                              "vertical-align": "middle",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("Started")) +
                                                " / " +
                                                _vm._s(_vm.$t("Ended")) +
                                                " / " +
                                                _vm._s(_vm.$t("Duration"))
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-left",
                                            staticStyle: {
                                              "vertical-align": "middle",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("Last operation"))
                                            ),
                                          ]
                                        ),
                                        _c("td"),
                                      ]),
                                    ]),
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        Object.keys(_vm.restores),
                                        function (idRestore) {
                                          return _c(
                                            "tr",
                                            {
                                              key: idRestore,
                                              attrs: { "track-by": idRestore },
                                            },
                                            [
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "fadeIn text-center w-10",
                                                },
                                                [
                                                  _c("LastResult", {
                                                    attrs: {
                                                      isRunning:
                                                        _vm.restores[idRestore]
                                                          .isRunning,
                                                      lastresult:
                                                        _vm.restores[idRestore]
                                                          .lastresult,
                                                      progress:
                                                        _vm.restores[idRestore]
                                                          .progress,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm.restores[idRestore].type !=
                                              _vm.$PlatformsTypes.ESXi
                                                ? _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "text-bold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.restores[
                                                                idRestore
                                                              ].restorename
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _c("td", [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.restores[
                                                            idRestore
                                                          ].vmname
                                                        )
                                                      ),
                                                    ]),
                                                    _c("br"),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "badge inline bg-cobalt fg-white p-1 mr-1",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.restores[
                                                              idRestore
                                                            ].type_name
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "badge inline bg-cobalt fg-white p-1 mr-1",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.restores[
                                                              idRestore
                                                            ].datacenter
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "badge inline bg-cobalt fg-white p-1 mr-1",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "n. " +
                                                            _vm._s(
                                                              _vm.restores[
                                                                idRestore
                                                              ].backupnumber
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "badge inline bg-cobalt fg-white p-1 mr-1",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.restores[
                                                              idRestore
                                                            ].datastore +
                                                              "-" +
                                                              _vm.restores[
                                                                idRestore
                                                              ].host
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "badge inline bg-cobalt fg-white p-1 mr-1",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.restores[
                                                              idRestore
                                                            ].datastore +
                                                              "-" +
                                                              _vm.restores[
                                                                idRestore
                                                              ].host
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                              _c(
                                                "td",
                                                { staticClass: "text-center" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "badge inline bg-cobalt fg-white p-1",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.restores[
                                                            idRestore
                                                          ].type_name.toUpperCase()
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-center no-wrap",
                                                },
                                                [
                                                  _vm.restores[idRestore]
                                                    .dt_start_utc != "" &&
                                                  _vm.restores[idRestore]
                                                    .dt_start_utc != null
                                                    ? _c("label", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatDate(
                                                              _vm.restores[
                                                                idRestore
                                                              ].dt_start_utc
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  !_vm.restores[idRestore]
                                                    .isRunning &&
                                                  _vm.restores[idRestore]
                                                    .dt_end_utc != "" &&
                                                  _vm.restores[idRestore]
                                                    .dt_end_utc != null
                                                    ? _c("label", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatDate(
                                                              _vm.restores[
                                                                idRestore
                                                              ].dt_end_utc
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _c("br"),
                                                  !_vm.restores[idRestore]
                                                    .isRunning &&
                                                  _vm.restores[idRestore]
                                                    .dt_end_utc != "" &&
                                                  _vm.restores[idRestore]
                                                    .dt_end_utc != null
                                                    ? _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "text-bold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.diffDate(
                                                                _vm.restores[
                                                                  idRestore
                                                                ].dt_start_utc,
                                                                _vm.restores[
                                                                  idRestore
                                                                ].dt_end_utc
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    width: "200px",
                                                  },
                                                },
                                                [
                                                  _vm.restores[idRestore]
                                                    .isRunning
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass: "clear",
                                                        },
                                                        [
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "place-left",
                                                              attrs: {
                                                                "data-role":
                                                                  "hint",
                                                                "data-hint-position":
                                                                  "top",
                                                                "data-hint-text":
                                                                  _vm.restores[
                                                                    idRestore
                                                                  ]
                                                                    .current_operation,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.restores[
                                                                    idRestore
                                                                  ]
                                                                    .current_operation
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "no-wrap pos-relative",
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "button dropdown-toggle border-radius",
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass: "mif-menu",
                                                      }),
                                                    ]
                                                  ),
                                                  _c(
                                                    "ul",
                                                    {
                                                      staticClass:
                                                        "d-menu drop-left",
                                                      attrs: {
                                                        "data-role": "dropdown",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "li",
                                                        {
                                                          staticClass:
                                                            "bg-lightcobalt fg-white",
                                                          attrs: {
                                                            disabled:
                                                              !_vm.restores[
                                                                idRestore
                                                              ].isRunning,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href: "#",
                                                                title:
                                                                  _vm.$t(
                                                                    "Stop restore"
                                                                  ),
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.restoreAction(
                                                                      "stop",
                                                                      idRestore
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("span", {
                                                                staticClass:
                                                                  "mif-stop icon",
                                                              }),
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("Stop")
                                                                ) + " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "li",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              _vm.restores[
                                                                idRestore
                                                              ].isRunning,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href: "#",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.restoreAction(
                                                                      "delete",
                                                                      idRestore
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("span", {
                                                                staticClass:
                                                                  "mif-bin icon",
                                                              }),
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Delete"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "w-100 p-3",
                        staticStyle: { height: "75vh", "overflow-y": "auto" },
                        attrs: { id: "sysinfo" },
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "info-box-content cell-lg-4 cell-md-4 cell-sm-12",
                            },
                            [
                              _c("div", { staticClass: "panel p-4 mb-3" }, [
                                _c(
                                  "div",
                                  { staticClass: "cell-lg-12 cell-sm-12" },
                                  [
                                    _c("div", { staticClass: "content" }, [
                                      _c("h4", { staticClass: "text-bold" }, [
                                        _c("span", {
                                          class: {
                                            "mif-windows":
                                              _vm.pc.device.includes("PC"),
                                            "mif-apple":
                                              _vm.pc.device.includes("MAC") ||
                                              _vm.pc.device.includes("IOS"),
                                          },
                                        }),
                                        _vm._v(
                                          " " + _vm._s(_vm.pc.pc_name) + " "
                                        ),
                                      ]),
                                      _c("hr"),
                                      _c("div", {}, [
                                        _c("div", [
                                          _c("span", {
                                            attrs: {
                                              title: _vm.pc.os_version_full,
                                            },
                                          }),
                                          _vm._v(
                                            " " + _vm._s(_vm.pc.os_version_full)
                                          ),
                                        ]),
                                      ]),
                                      _c("div", {}, [
                                        _c("label", [
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(_vm.$t("Agent Version")) +
                                                ":"
                                            ),
                                          ]),
                                          _vm._v(
                                            _vm._s(_vm.system.agentVersion)
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("br"),
                                        _c("label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.pc.system_info[
                                                    _vm.$SystemInfoType.DOMAIN
                                                  ].info_description
                                                )
                                              ) +
                                              ": " +
                                              _vm._s(
                                                _vm.pc.system_info[
                                                  _vm.$SystemInfoType.DOMAIN
                                                ].info_value
                                              )
                                          ),
                                        ]),
                                        _c("br"),
                                        _c("label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("LAN IP")) +
                                              ": " +
                                              _vm._s(_vm.pc.ipaddress_lan)
                                          ),
                                        ]),
                                        _c("br"),
                                        _c("label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("Public IP")) +
                                              ": " +
                                              _vm._s(_vm.pc.ipaddress_public)
                                          ),
                                        ]),
                                        _c("br"),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "panel p-4 mb-3" }, [
                                _c("form", { staticClass: "form content" }, [
                                  _c("h4", { staticClass: "d-flex" }, [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          width: "24",
                                          height: "24",
                                          fill: "none",
                                          viewBox: "0 0 24 24",
                                          xmlns: "http://www.w3.org/2000/svg",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M6.75 22a.75.75 0 0 1-.102-1.493l.102-.007h1.749v-2.498H4.25a2.25 2.25 0 0 1-2.245-2.096L2 15.752V5.25a2.25 2.25 0 0 1 2.096-2.245L4.25 3h15.499a2.25 2.25 0 0 1 2.245 2.096l.005.154v10.502a2.25 2.25 0 0 1-2.096 2.245l-.154.005h-4.25V20.5h1.751a.75.75 0 0 1 .102 1.494L17.25 22H6.75Zm7.248-3.998h-4l.001 2.498h4l-.001-2.498Z",
                                            fill: "#212121",
                                          },
                                        }),
                                      ]
                                    ),
                                    _c("label", { staticClass: "ml-2" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("Custom Device Info"))
                                      ),
                                    ]),
                                  ]),
                                  _c("hr"),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "cell-sm-12" }, [
                                      _c(
                                        "label",
                                        { staticClass: "mt-1 no-wrap" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("Custom name")) + ":"
                                          ),
                                        ]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value: _vm.pc.name,
                                            expression: "pc.name",
                                            modifiers: { trim: true },
                                          },
                                          {
                                            name: "fsitem",
                                            rawName: "v-fsitem",
                                          },
                                        ],
                                        staticClass:
                                          "bg-light input input-large p-0 text-bold",
                                        attrs: { type: "text" },
                                        domProps: { value: _vm.pc.name },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.pc,
                                              "name",
                                              $event.target.value.trim()
                                            )
                                          },
                                          blur: function ($event) {
                                            return _vm.$forceUpdate()
                                          },
                                        },
                                      }),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "cell-sm-12" }, [
                                      _c("label", { staticClass: "mt-1" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("Organization")) + ":"
                                        ),
                                      ]),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value: _vm.pc.organization_name,
                                            expression: "pc.organization_name",
                                            modifiers: { trim: true },
                                          },
                                        ],
                                        staticClass:
                                          "input input-small input-material bg-light",
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.pc.organization_name,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.pc,
                                              "organization_name",
                                              $event.target.value.trim()
                                            )
                                          },
                                          blur: function ($event) {
                                            return _vm.$forceUpdate()
                                          },
                                        },
                                      }),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "cell-sm-12" }, [
                                      _c("label", { staticClass: "mt-1" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("Description")) + ":"
                                        ),
                                      ]),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value: _vm.pc.description,
                                            expression: "pc.description",
                                            modifiers: { trim: true },
                                          },
                                        ],
                                        staticClass:
                                          "input input-small input-material bg-light",
                                        attrs: { type: "text" },
                                        domProps: { value: _vm.pc.description },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.pc,
                                              "description",
                                              $event.target.value.trim()
                                            )
                                          },
                                          blur: function ($event) {
                                            return _vm.$forceUpdate()
                                          },
                                        },
                                      }),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "cell-sm-12" }, [
                                      _c("label", { staticClass: "ml-3" }, [
                                        _vm._v(_vm._s(_vm.$t("Groups"))),
                                      ]),
                                      _c(
                                        "select",
                                        {
                                          staticClass: "bg-light",
                                          attrs: {
                                            "data-role": "select",
                                            "data-filter": "false",
                                            multiple: "",
                                            "data-cls-selected-item":
                                              "bg-white border pr-2 rounded",
                                            id: "groupselect",
                                            name: "ids_groups",
                                          },
                                        },
                                        _vm._l(
                                          _vm.$session.getGroups(),
                                          function (group) {
                                            return _c(
                                              "option",
                                              {
                                                key: group.id_group,
                                                domProps: {
                                                  selected: _vm.pc.groups.find(
                                                    (elem) =>
                                                      elem.id == group.id_group
                                                  ),
                                                  value: group.id_group,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(group.name_group) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _c("br"),
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-justify-end" },
                                  [
                                    _c("div", [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "button m-1 primary",
                                          on: { click: _vm.editComputer },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("Update")) + " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "info-box-content cell-lg-4 cell-md-4 cell-sm-12",
                            },
                            [
                              _c("div", { staticClass: "panel p-4 mb-3" }, [
                                _vm.onlyremoteinstalled
                                  ? _c(
                                      "div",
                                      { staticClass: "cell-lg-12 cell-sm-12" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "content" },
                                          [
                                            _c("UpgradeToUnlockFeature", {
                                              attrs: {
                                                title: _vm.$t("Performances"),
                                                description: _vm.$t(
                                                  "Unlock this feature to access CPU, RAM, and disk drive monitoring."
                                                ),
                                                buttonText: _vm.$t("Unlock"),
                                                iconType: "perf",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "cell-lg-12 cell-sm-12" },
                                      [
                                        _c("div", { staticClass: "content" }, [
                                          _c("h4", {}, [
                                            _c(
                                              "svg",
                                              {
                                                attrs: {
                                                  width: "30",
                                                  height: "30",
                                                  fill: "none",
                                                  viewBox: "0 0 24 24",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    d: "M8.5 5.247A2.249 2.249 0 0 1 10.75 3h2.5a2.249 2.249 0 0 1 2.25 2.247v1.769h3.25A2.249 2.249 0 0 1 21 9.263v10.988a.75.75 0 0 1-.75.749H3.75a.75.75 0 0 1-.75-.75v-7.99a2.249 2.249 0 0 1 2.25-2.248H8.5V5.247ZM10 19.503h4V5.247a.75.75 0 0 0-.75-.749h-2.5a.75.75 0 0 0-.75.75v14.254ZM8.5 11.51H5.25a.75.75 0 0 0-.75.749v7.242h4V11.51Zm7 7.99h4V9.264a.75.75 0 0 0-.75-.75H15.5v10.989Z",
                                                    fill: "#212121",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("Performance")) +
                                                " "
                                            ),
                                          ]),
                                          _c("hr"),
                                          !_vm.$root.connectionsStates[
                                            _vm.pc.id
                                          ]
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cell-lg-12 cell-sm-12",
                                                },
                                                [
                                                  _c("NoConnection", {
                                                    attrs: { pc: _vm.pc },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "div",
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-small pt-2 d-flex flex-justify-between",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(_vm.$t("RAM"))
                                                        ),
                                                      ]),
                                                      _c("span", [
                                                        _c("b", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.system
                                                                .usedRAM_string
                                                            )
                                                          ),
                                                        ]),
                                                        _vm._v(
                                                          "/" +
                                                            _vm._s(
                                                              _vm.system
                                                                .totalRAM_string
                                                            )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "progress small bg-gray",
                                                      attrs: {
                                                        "data-role": "progress",
                                                        "data-value":
                                                          (parseInt(
                                                            _vm.system.used
                                                          ) /
                                                            parseInt(
                                                              _vm.system
                                                                .totalRAM
                                                            )) *
                                                          100,
                                                        "data-small": "true",
                                                        "data-cls-bar":
                                                          "bg-cobalt",
                                                        "data-cls-back":
                                                          "bg-darkGray",
                                                        "data-role-progress":
                                                          "true",
                                                      },
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticClass: "bar",
                                                        class: {
                                                          "bg-red":
                                                            parseInt(
                                                              _vm.system
                                                                .availableRAM
                                                            ) >
                                                            (parseInt(
                                                              _vm.system
                                                                .totalRAM
                                                            ) /
                                                              100) *
                                                              70,
                                                          "ribbed-cobalt":
                                                            parseInt(
                                                              _vm.system
                                                                .availableRAM
                                                            ) <=
                                                            (parseInt(
                                                              _vm.system
                                                                .totalRAM
                                                            ) /
                                                              100) *
                                                              70,
                                                        },
                                                        style: {
                                                          width:
                                                            (parseInt(
                                                              _vm.system.usedRAM
                                                            ) /
                                                              parseInt(
                                                                _vm.system
                                                                  .totalRAM
                                                              )) *
                                                              100 +
                                                            "%",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-small pt-2 d-flex flex-justify-between",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("CPU")
                                                            )
                                                          ),
                                                        ]),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.system.CPU
                                                            ) + "%"
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "progress small bg-gray",
                                                      attrs: {
                                                        "data-role": "progress",
                                                        "data-value": parseInt(
                                                          _vm.system.CPU
                                                        ),
                                                        "data-small": "true",
                                                        "data-cls-bar":
                                                          "bg-white",
                                                        "data-cls-back":
                                                          "bg-darkGray",
                                                        "data-role-progress":
                                                          "true",
                                                      },
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticClass: "bar",
                                                        class: {
                                                          "bg-red":
                                                            parseInt(
                                                              _vm.system.CPU
                                                            ) > 85,
                                                          "ribbed-cobalt":
                                                            parseInt(
                                                              _vm.system.CPU
                                                            ) <= 85,
                                                        },
                                                        style: {
                                                          width:
                                                            _vm.system.CPU +
                                                            "%",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-small pt-2 pb-2",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm
                                                              .$t("Disk")
                                                              .toUpperCase()
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-small",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("Read")
                                                          ) +
                                                            ": " +
                                                            _vm._s(
                                                              _vm.system
                                                                .readDiskspeed
                                                            ) +
                                                            " KB/s "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-small",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("Write")
                                                          ) +
                                                            ": " +
                                                            _vm._s(
                                                              _vm.system
                                                                .writeDiskspeed
                                                            ) +
                                                            " KB/s "
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c("hr"),
                                                  _vm._l(
                                                    _vm.disks,
                                                    function (disk, i) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: disk.caption,
                                                          staticClass: "pt-4",
                                                        },
                                                        [
                                                          _c("label", [
                                                            _c("span", {
                                                              staticClass:
                                                                "mif-drive2 mr-1",
                                                            }),
                                                            _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    disk.caption
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                          ]),
                                                          _vm._l(
                                                            disk.partitions,
                                                            function (
                                                              partition,
                                                              j
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        partition.volumeDriveLetter !=
                                                                        "None",
                                                                      expression:
                                                                        "partition.volumeDriveLetter != 'None'",
                                                                    },
                                                                  ],
                                                                  key:
                                                                    i + "-" + j,
                                                                  staticClass:
                                                                    "ml-4",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "clear p-1",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "place-left text-small",
                                                                        },
                                                                        [
                                                                          partition.volumeIsWinVolume
                                                                            ? _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "mif-windows mr-2",
                                                                                }
                                                                              )
                                                                            : _vm._e(),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  partition.visibleLabel
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "place-right text-small",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "strong",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  partition.volumeNotfreeSpace_string
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            "/" +
                                                                              _vm._s(
                                                                                partition.volumeCapacity_string
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "progress small",
                                                                      attrs: {
                                                                        "data-role":
                                                                          "progress",
                                                                        "data-value":
                                                                          partition.volumeSpacePercent,
                                                                        "data-cls-bar":
                                                                          "bg-dark",
                                                                        "data-role-progress":
                                                                          "true",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "bar",
                                                                          class:
                                                                            {
                                                                              "bg-red":
                                                                                partition.volumeSpacePercent >
                                                                                85,
                                                                              "bg-cobalt":
                                                                                partition.volumeSpacePercent <=
                                                                                85,
                                                                            },
                                                                          style:
                                                                            {
                                                                              width:
                                                                                partition.volumeSpacePercent +
                                                                                "%",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                        ]),
                                      ]
                                    ),
                              ]),
                              _c("div", { staticClass: "panel p-4 mb-3" }, [
                                _vm.onlyremoteinstalled
                                  ? _c(
                                      "div",
                                      { staticClass: "cell-lg-12 cell-sm-12" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "content" },
                                          [
                                            _c("UpgradeToUnlockFeature", {
                                              attrs: {
                                                title: _vm.$t("Policies"),
                                                description: _vm.$t(
                                                  "Unlock this feature to configure alert and notification policies."
                                                ),
                                                buttonText: _vm.$t("Unlock"),
                                                iconType: "policy",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "cell-lg-12 cell-sm-12" },
                                      [
                                        _c(
                                          "h4",
                                          {
                                            staticClass:
                                              "d-flex flex-justify-between flex-align-center",
                                          },
                                          [
                                            _c("label", [
                                              _c(
                                                "span",
                                                { staticClass: "icon p-0" },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      attrs: {
                                                        width: "24",
                                                        height: "24",
                                                        fill: "none",
                                                        viewBox: "0 0 24 24",
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          d: "M18.5 20.002a.5.5 0 0 1-.5.5H9v1.5h9a2 2 0 0 0 2-2V9.83c0-.53-.211-1.04-.586-1.414l-5.829-5.829a.49.49 0 0 0-.049-.04.63.63 0 0 1-.036-.03 2.072 2.072 0 0 0-.219-.18.65.65 0 0 0-.08-.044c-.017-.008-.033-.015-.048-.024l-.05-.029c-.054-.031-.109-.063-.166-.087a1.977 1.977 0 0 0-.624-.138c-.02-.001-.04-.004-.059-.007A.605.605 0 0 0 12.172 2H6a2 2 0 0 0-2 2v7.257a4.49 4.49 0 0 1 1.5-.256v-7a.5.5 0 0 1 .5-.5h6V8a2 2 0 0 0 2 2h4.5v10.001Zm-5-15.38L17.378 8.5H14a.5.5 0 0 1-.5-.5V4.62ZM9 15.501a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-6 3.742v3.044a.71.71 0 0 0 1.212.503L5.5 21.503l1.288 1.288A.71.71 0 0 0 8 22.288v-3.044a4.479 4.479 0 0 1-2.5.758c-.925 0-1.785-.279-2.5-.758Z",
                                                          fill: "#222F3D",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                " " + _vm._s(_vm.$t("Policies"))
                                              ),
                                            ]),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "button flat-button",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$root.$emit(
                                                      "OPENDEVICEPOLICYVIEWDIALOG",
                                                      _vm.pc,
                                                      _vm.policiesList
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "mif-add mif-2x",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c("hr"),
                                        _vm.policiesList.length == 0
                                          ? _c(
                                              "div",
                                              { staticClass: "content" },
                                              [
                                                _c("NoDataAvailable", {
                                                  attrs: {
                                                    message: _vm.$t(
                                                      "No policies selected"
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.policiesList.length != 0
                                          ? _c(
                                              "div",
                                              { staticClass: "content" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: {
                                                      "data-role": "accordion",
                                                      "data-one-frame": "true",
                                                      "data-show-active":
                                                        "false",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.policiesList,
                                                    function (policy) {
                                                      return policy.related_entity ==
                                                        "PC"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              key: policy.id,
                                                              staticClass:
                                                                "frame",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "heading",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        policy.name
                                                                      )
                                                                  ),
                                                                  _c("br"),
                                                                  _c(
                                                                    "label",
                                                                    {
                                                                      staticClass:
                                                                        "text-muted",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          policy.name_type
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "content p-2",
                                                                },
                                                                [
                                                                  policy.id_type ==
                                                                  3
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "p-2 row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "cell-12",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "h6",
                                                                                {
                                                                                  staticClass:
                                                                                    "pt-1 text-small",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "Thresholds"
                                                                                          )
                                                                                        ) +
                                                                                          ":"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .$t(
                                                                                          "duration"
                                                                                        )
                                                                                        .toUpperCase()
                                                                                    ) +
                                                                                      ": " +
                                                                                      _vm._s(
                                                                                        _vm.secondsToFormattedString(
                                                                                          policy
                                                                                            .policy
                                                                                            .threshold_duration
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "hr"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "cell-4 text-center text-bold",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "CPU"
                                                                                      )
                                                                                    ) +
                                                                                      ": "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-center",
                                                                                  attrs:
                                                                                    {
                                                                                      id: "donut3",
                                                                                      "data-role":
                                                                                        "donut",
                                                                                      "data-size":
                                                                                        "80",
                                                                                      "data-stroke-width":
                                                                                        "2",
                                                                                      "data-value":
                                                                                        policy
                                                                                          .policy
                                                                                          .threshold_cpu,
                                                                                      "data-stroke":
                                                                                        "#f5f5f5",
                                                                                      "data-fill":
                                                                                        "#0e72cf",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "cell-4 text-center text-bold",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "DISK"
                                                                                      )
                                                                                    ) +
                                                                                      ":"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      id: "donut4",
                                                                                      "data-role":
                                                                                        "donut",
                                                                                      "data-size":
                                                                                        "80",
                                                                                      "data-stroke-width":
                                                                                        "2",
                                                                                      "data-value":
                                                                                        policy
                                                                                          .policy
                                                                                          .threshold_disk,
                                                                                      "data-stroke":
                                                                                        "#f5f5f5",
                                                                                      "data-fill":
                                                                                        "#0e72cf",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "cell-4 text-center text-bold",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "RAM"
                                                                                      )
                                                                                    ) +
                                                                                      ":"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      id: "donut5",
                                                                                      "data-role":
                                                                                        "donut",
                                                                                      "data-size":
                                                                                        "80",
                                                                                      "data-stroke-width":
                                                                                        "2",
                                                                                      "data-value":
                                                                                        policy
                                                                                          .policy
                                                                                          .threshold_ram,
                                                                                      "data-stroke":
                                                                                        "#f5f5f5",
                                                                                      "data-fill":
                                                                                        "#0e72cf",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  policy.id_type ==
                                                                  2
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "p-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "p",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "Reboot Auto"
                                                                                  )
                                                                                ) +
                                                                                  ": "
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "fg-red",
                                                                                  class:
                                                                                    {
                                                                                      "fg-green":
                                                                                        policy
                                                                                          .policy
                                                                                          .reboot_auto ==
                                                                                        true,
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " ● "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "p",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "Reboot Time"
                                                                                  )
                                                                                ) +
                                                                                  ": " +
                                                                                  _vm._s(
                                                                                    _vm.nextSched(
                                                                                      policy
                                                                                        .policy
                                                                                        .reboot_time
                                                                                    )
                                                                                  )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "p",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "Update Time"
                                                                                  )
                                                                                ) +
                                                                                  ": " +
                                                                                  _vm._s(
                                                                                    _vm.nextSched(
                                                                                      policy
                                                                                        .policy
                                                                                        .update_time
                                                                                    )
                                                                                  )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _c("br"),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c("br"),
                                      ]
                                    ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "info-box-content cell-lg-4 cell-md-4 cell-sm-12",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "panel p-4 mb-3",
                                  attrs: { disabled: _vm.pc.id_remote == "" },
                                },
                                [
                                  _c("div", { staticClass: "content" }, [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "d-flex flex-justify-between flex-align-center",
                                      },
                                      [
                                        _c("label", {}, [
                                          _c("span", {
                                            staticClass: "mif-cast",
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("Remote Access"))
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c("hr"),
                                    _c("div", {}, [
                                      _c("label", [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("ID")) + ": "
                                        ),
                                        _c("span", { staticClass: "ml-1" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.IDRemoteWithSpace(
                                                _vm.pc.id_remote
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("br"),
                                      _c("label", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("Unattended mode")) +
                                            ": "
                                        ),
                                        _c("span", { staticClass: "ml-1" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.pc.service_remote
                                                ? _vm.$t("Yes")
                                                : _vm.$t("No")
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("br"),
                                      _c("label", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("2FA Enabled")) +
                                            ": "
                                        ),
                                        _c("span", { staticClass: "ml-1" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.pc.service_remote
                                                ? _vm.$t("Yes")
                                                : _vm.$t("No")
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("br"),
                                      _c("label", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("Client version")) +
                                            ": "
                                        ),
                                        _c("span", { staticClass: "ml-1" }, [
                                          _vm._v(_vm._s(_vm.pc.version_remote)),
                                        ]),
                                      ]),
                                      _c("br"),
                                      _c("label", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("Client Rebranded")) +
                                            ": "
                                        ),
                                        _c("span", { staticClass: "ml-1" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.pc.is_rebranded_remote
                                                ? _vm.$t("Yes")
                                                : _vm.$t("No")
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("br"),
                                    ]),
                                  ]),
                                  _c("br"),
                                  _c("h6", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "Protect remote access with an additional password"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("br"),
                                  _c(
                                    "form",
                                    {
                                      staticClass: "form",
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          return _vm.saveCustomPw.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("div", [
                                        _vm.pc.is_protected
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "mb-2",
                                                staticStyle: {
                                                  "vertical-align": "middle",
                                                },
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.pc.is_protected,
                                                      expression:
                                                        "pc.is_protected",
                                                    },
                                                  ],
                                                  attrs: {
                                                    "data-caption": _vm.$t(
                                                      "Remove custom password"
                                                    ),
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "switch",
                                                    "data-on": "on",
                                                  },
                                                  domProps: {
                                                    checked:
                                                      _vm.pc.is_protected,
                                                    checked: Array.isArray(
                                                      _vm.pc.is_protected
                                                    )
                                                      ? _vm._i(
                                                          _vm.pc.is_protected,
                                                          null
                                                        ) > -1
                                                      : _vm.pc.is_protected,
                                                  },
                                                  on: {
                                                    change: [
                                                      function ($event) {
                                                        var $$a =
                                                            _vm.pc.is_protected,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              _vm.$set(
                                                                _vm.pc,
                                                                "is_protected",
                                                                $$a.concat([
                                                                  $$v,
                                                                ])
                                                              )
                                                          } else {
                                                            $$i > -1 &&
                                                              _vm.$set(
                                                                _vm.pc,
                                                                "is_protected",
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  )
                                                              )
                                                          }
                                                        } else {
                                                          _vm.$set(
                                                            _vm.pc,
                                                            "is_protected",
                                                            $$c
                                                          )
                                                        }
                                                      },
                                                      _vm.removeCustomPw,
                                                    ],
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c("div", [
                                          _c(
                                            "div",
                                            { staticClass: "cell-sm-12 p-0" },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "mt-1 no-wrap" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("Custom password")
                                                    ) + ":"
                                                  ),
                                                ]
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.trim",
                                                    value:
                                                      _vm.pc.custom_password,
                                                    expression:
                                                      "pc.custom_password",
                                                    modifiers: { trim: true },
                                                  },
                                                ],
                                                staticClass: "input",
                                                attrs: {
                                                  autocomplete: "off",
                                                  "data-validate": "required",
                                                  "data-role": "input",
                                                  disabled:
                                                    _vm.pc.is_protected == 1,
                                                  type: "password",
                                                },
                                                domProps: {
                                                  value: _vm.pc.custom_password,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.pc,
                                                      "custom_password",
                                                      $event.target.value.trim()
                                                    )
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$forceUpdate()
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "cell-sm-12 p-0" },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "mt-1 no-wrap" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "Retype Custom password"
                                                      )
                                                    ) + ":"
                                                  ),
                                                ]
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.trim",
                                                    value:
                                                      _vm.pc.custom_password2,
                                                    expression:
                                                      "pc.custom_password2",
                                                    modifiers: { trim: true },
                                                  },
                                                ],
                                                staticClass: "input",
                                                attrs: {
                                                  autocomplete: "off",
                                                  "data-validate": "required",
                                                  "data-role": "input",
                                                  type: "password",
                                                  disabled:
                                                    _vm.pc.is_protected == 1,
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.pc.custom_password2,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.pc,
                                                      "custom_password2",
                                                      $event.target.value.trim()
                                                    )
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$forceUpdate()
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                      _c("br"),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-justify-end",
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "button m-1 primary",
                                              attrs: { type: "submit" },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("Set password")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "panel p-4 mb-3" }, [
                                _vm.onlyremoteinstalled
                                  ? _c(
                                      "div",
                                      { staticClass: "cell-lg-12 cell-sm-12" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "content" },
                                          [
                                            _c("UpgradeToUnlockFeature", {
                                              attrs: {
                                                title: _vm.$t("Security"),
                                                description: _vm.$t(
                                                  "Unlock this feature to access antivirus, firewall, and security monitoring."
                                                ),
                                                buttonText: _vm.$t("Unlock"),
                                                iconType: "shield",
                                                hinted: _vm.$t(
                                                  "Unlock this feature to access antivirus, firewall, and security monitoring."
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "cell-lg-12 cell-sm-12" },
                                      [
                                        _c(
                                          "h4",
                                          {
                                            staticClass:
                                              "d-flex flex-justify-between flex-align-center",
                                          },
                                          [
                                            _c("label", {}, [
                                              _c("span", {
                                                staticClass: "mif-security",
                                              }),
                                              _vm._v(
                                                " " + _vm._s(_vm.$t("Security"))
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c("hr"),
                                        _c("div", { staticClass: "content" }, [
                                          _c("label", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    _vm.pc.system_info[
                                                      _vm.$SystemInfoType
                                                        .ANTIVIRUS
                                                    ].info_description
                                                  )
                                                ) +
                                                ":"
                                            ),
                                          ]),
                                          _vm.pc.system_info[
                                            _vm.$SystemInfoType.ANTIVIRUS
                                          ].info_value.length == 0
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ml-1 fg-gray mif-2x",
                                                },
                                                [_vm._v(" ● ")]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "ul",
                                            { staticClass: "listview" },
                                            _vm._l(
                                              _vm.pc.system_info[
                                                _vm.$SystemInfoType.ANTIVIRUS
                                              ].info_value,
                                              function (ivalue) {
                                                return _c("li", [
                                                  _c("label", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(ivalue.Name) +
                                                        ": "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "ml-1 mif-2x",
                                                        class: {
                                                          "fg-green":
                                                            ivalue.Enabled,
                                                          "fg-red":
                                                            !ivalue.Enabled,
                                                        },
                                                      },
                                                      [_vm._v(" ● ")]
                                                    ),
                                                  ]),
                                                ])
                                              }
                                            ),
                                            0
                                          ),
                                          _c("label", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    _vm.pc.system_info[
                                                      _vm.$SystemInfoType
                                                        .FIREWALL
                                                    ].info_description
                                                  )
                                                ) +
                                                ":"
                                            ),
                                          ]),
                                          _vm.pc.system_info[
                                            _vm.$SystemInfoType.FIREWALL
                                          ].info_value.length == 0
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ml-1 fg-gray mif-2x",
                                                },
                                                [_vm._v(" ● ")]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "ul",
                                            { staticClass: "listview" },
                                            _vm._l(
                                              _vm.pc.system_info[
                                                _vm.$SystemInfoType.FIREWALL
                                              ].info_value,
                                              function (ivalue) {
                                                return _c("li", [
                                                  _c("label", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(ivalue.Name) +
                                                        ": "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "ml-1 mif-2x",
                                                        class: {
                                                          "fg-green":
                                                            ivalue.Enabled,
                                                          "fg-red":
                                                            !ivalue.Enabled,
                                                        },
                                                      },
                                                      [_vm._v(" ● ")]
                                                    ),
                                                  ]),
                                                ])
                                              }
                                            ),
                                            0
                                          ),
                                          _c("br"),
                                          _c("label", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    _vm.pc.system_info[
                                                      _vm.$SystemInfoType
                                                        .WINDOWS_DEFENDER_STATUS
                                                    ].info_description
                                                  )
                                                ) +
                                                ": "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "ml-1 mif-2x",
                                                class: {
                                                  "fg-green":
                                                    _vm.pc.system_info[
                                                      _vm.$SystemInfoType
                                                        .WINDOWS_DEFENDER_STATUS
                                                    ].info_value == "True",
                                                  "fg-red":
                                                    _vm.pc.system_info[
                                                      _vm.$SystemInfoType
                                                        .WINDOWS_DEFENDER_STATUS
                                                    ].info_value != "True",
                                                },
                                              },
                                              [_vm._v(" ● ")]
                                            ),
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.pc.system_info[
                                                    _vm.$SystemInfoType
                                                      .WINDOWS_DEFENDER_STATUS
                                                  ].info_value == "True"
                                                    ? "Enabled"
                                                    : "Disabled"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("br"),
                                          _c("label", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    _vm.pc.system_info[
                                                      _vm.$SystemInfoType
                                                        .WINDOWS_FIREWALL_STATUS
                                                    ].info_description
                                                  )
                                                ) +
                                                ": "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "ml-1 mif-2x",
                                                class: {
                                                  "fg-green":
                                                    _vm.pc.system_info[
                                                      _vm.$SystemInfoType
                                                        .WINDOWS_FIREWALL_STATUS
                                                    ].info_value == "True",
                                                  "fg-red":
                                                    _vm.pc.system_info[
                                                      _vm.$SystemInfoType
                                                        .WINDOWS_FIREWALL_STATUS
                                                    ].info_value != "True",
                                                },
                                              },
                                              [_vm._v(" ● ")]
                                            ),
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.pc.system_info[
                                                    _vm.$SystemInfoType
                                                      .WINDOWS_FIREWALL_STATUS
                                                  ].info_value == "True"
                                                    ? "Enabled"
                                                    : "Disabled"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("br"),
                                        ]),
                                      ]
                                    ),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "w-100 p-3",
                        staticStyle: { height: "75vh", "overflow-y": "auto" },
                        attrs: { id: "eventlogs" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex flex-justify-start" },
                          [
                            _c("div", { staticClass: "col-auto m-0" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.eventlogs.type,
                                      expression: "eventlogs.type",
                                    },
                                  ],
                                  staticClass: "pl-4 select text-small w-auto",
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.eventlogs,
                                          "type",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      function ($event) {
                                        return _vm.refreshEventLogs()
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "1" } }, [
                                    _vm._v(_vm._s(_vm.$t("Errors"))),
                                  ]),
                                  _c("option", { attrs: { value: "2" } }, [
                                    _vm._v(_vm._s(_vm.$t("Warnings"))),
                                  ]),
                                  _c("option", { attrs: { value: "4" } }, [
                                    _vm._v(_vm._s(_vm.$t("Info"))),
                                  ]),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "col-auto m-0" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.eventlogs.level,
                                      expression: "eventlogs.level",
                                    },
                                  ],
                                  staticClass: "pl-4 select text-small w-auto",
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.eventlogs,
                                          "level",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      function ($event) {
                                        return _vm.refreshEventLogs()
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "System" } }, [
                                    _vm._v(_vm._s(_vm.$t("System"))),
                                  ]),
                                  _c(
                                    "option",
                                    { attrs: { value: "Application" } },
                                    [_vm._v(_vm._s(_vm.$t("Application")))]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _vm.eventlogs.list
                          ? _c(
                              "table",
                              {
                                staticClass:
                                  "bg-white border table compact striped pt-4",
                              },
                              [
                                _c("thead", [
                                  _c("tr", [
                                    _c(
                                      "th",
                                      {
                                        staticClass: "text-center",
                                        staticStyle: { width: "100px" },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Source")))]
                                    ),
                                    _c("th", [
                                      _vm._v(_vm._s(_vm.$t("Message"))),
                                    ]),
                                    _c(
                                      "th",
                                      { staticStyle: { width: "150px" } },
                                      [_vm._v(_vm._s(_vm.$t("Date Created")))]
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.eventlogs.list,
                                    function (event, index) {
                                      return _c(
                                        "tr",
                                        {
                                          key: index,
                                          attrs: { "track-by": index },
                                        },
                                        [
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "badge inline bg-cobalt fg-white p-1 mr-1",
                                                },
                                                [_vm._v(_vm._s(event.source))]
                                              ),
                                            ]
                                          ),
                                          _c("td", [
                                            _vm._v(_vm._s(event.message)),
                                          ]),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatDate(
                                                    event.timeGenerated
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "w-100 p-3",
                        staticStyle: { height: "75vh", "overflow-y": "auto" },
                        attrs: { id: "taskmanager" },
                      },
                      [
                        _c("div", { staticClass: "cell-12" }, [
                          _vm.processesList.length != 0
                            ? _c(
                                "table",
                                {
                                  staticClass:
                                    "bg-white border table striped compact overflow",
                                },
                                [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v("PID"),
                                      ]),
                                      _c("th", [
                                        _vm._v(_vm._s(_vm.$t("Name"))),
                                      ]),
                                      _c("th", [
                                        _vm._v(_vm._s(_vm.$t("Username"))),
                                      ]),
                                      _c("th", [
                                        _vm._v(_vm._s(_vm.$t("Memory")) + " "),
                                      ]),
                                      _c("th", {
                                        staticStyle: { width: "10px" },
                                      }),
                                    ]),
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.processesList,
                                      function (process, index) {
                                        return _c(
                                          "tr",
                                          {
                                            key: process.pid,
                                            attrs: { "track-by": process.pid },
                                          },
                                          [
                                            _c("td", {}, [
                                              _vm._v(_vm._s(process.pid)),
                                            ]),
                                            _c("td", {}, [
                                              _vm._v(_vm._s(process.name)),
                                            ]),
                                            _c("td", {}, [
                                              _vm._v(_vm._s(process.username)),
                                            ]),
                                            _c("td", {}, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("prettyBytes")(
                                                    process.ram
                                                  )
                                                )
                                              ),
                                            ]),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href:
                                                        "#killprocess_" +
                                                        process.pid,
                                                    },
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass:
                                                        "mif mif-cross-light fg-red",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "p-3 w-100 pt-4",
                        staticStyle: { height: "75vh", "overflow-y": "auto" },
                        attrs: { id: "patches" },
                      },
                      [
                        _c("div", { staticClass: "overflow" }, [
                          _vm.patches != null
                            ? _c(
                                "table",
                                {
                                  staticClass:
                                    "bg-white border table striped compact overflow",
                                  attrs: {
                                    "data-check": false,
                                    "data-check-name": "chkBox",
                                    "data-locale": _vm.$i18n.locale,
                                    "data-table-search-title":
                                      _vm.$t("Search Patch"),
                                    "data-cls-search":
                                      "w-100-sm w-25-xl w-25-lg w-50-md",
                                    "data-show-activity": "true",
                                    "data-cell-wrapper": "false",
                                    "data-role": "table",
                                    "data-rows": "20",
                                    "data-show-rows-steps": "false",
                                    "data-show-pagination":
                                      Object.keys(_vm.patches).length > 20
                                        ? "true"
                                        : "false",
                                    "data-horizontal-scroll": "true",
                                  },
                                },
                                [
                                  _c("thead", [
                                    _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-center",
                                          attrs: {
                                            "data-cls-column": "text-center",
                                            "data-name": "status",
                                            "data-sortable": "true",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Status")))]
                                      ),
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-center",
                                          attrs: {
                                            "data-cls-column": "text-center",
                                            "data-name": "severity",
                                            "data-sortable": "true",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Severity")))]
                                      ),
                                      _c(
                                        "th",
                                        {
                                          staticClass: "w-25",
                                          attrs: {
                                            "data-name": "title",
                                            "data-sortable": "true",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Title")))]
                                      ),
                                      _c(
                                        "th",
                                        {
                                          attrs: {
                                            "data-name": "description",
                                            "data-sortable": "true",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Description")))]
                                      ),
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-center w-10",
                                          attrs: {
                                            "data-name": "lastupdate",
                                            "data-sortable": "true",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("Last Update")) + " "
                                          ),
                                        ]
                                      ),
                                      _c("th"),
                                    ]),
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      Object.keys(_vm.patches),
                                      function (idPatch) {
                                        return _c(
                                          "tr",
                                          {
                                            key: idPatch,
                                            attrs: { "track-by": idPatch },
                                          },
                                          [
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _vm.patches[idPatch].isInstalled
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "badge inline bg-green fg-white p-1 mr-1",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm
                                                              .$t("Installed")
                                                              .toUpperCase()
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : !_vm.patches[idPatch]
                                                      .isDownloading &&
                                                    !_vm.patches[idPatch]
                                                      .isInstalling
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "badge inline bg-dark fg-white p-1 mr-1",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm
                                                                .$t(
                                                                  "Not Installed"
                                                                )
                                                                .toUpperCase()
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "badge inline bg-cobalt fg-white p-1 mr-1",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.patches[
                                                                idPatch
                                                              ].isDownloading
                                                                ? _vm
                                                                    .$t(
                                                                      "Downloading"
                                                                    )
                                                                    .toUpperCase()
                                                                : _vm
                                                                    .$t(
                                                                      "Installing"
                                                                    )
                                                                    .toUpperCase()
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                _vm.patches[idPatch]
                                                  .isDownloading ||
                                                _vm.patches[idPatch]
                                                  .isInstalling
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-align-center flex-column mt-2",
                                                      },
                                                      [
                                                        _c("div", {
                                                          attrs: {
                                                            id: idPatch + "d",
                                                            "data-role":
                                                              "donut",
                                                            "data-value": _vm
                                                              .patches[idPatch]
                                                              .isDownloading
                                                              ? _vm.patches[
                                                                  idPatch
                                                                ]
                                                                  .downloadProgress
                                                              : _vm.patches[
                                                                  idPatch
                                                                ]
                                                                  .installProgress,
                                                            "data-size": "20",
                                                            "data-hole": "0",
                                                            "data-stroke":
                                                              "transparent",
                                                            "data-background":
                                                              "transparent",
                                                            "data-fill":
                                                              "#0052ff",
                                                            "data-animate":
                                                              "10",
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _vm.patches[idPatch].severity !=
                                                null
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "badge inline fg-white p-1 mr-1",
                                                        class: {
                                                          "bg-red":
                                                            _vm.patches[
                                                              idPatch
                                                            ].severity.toUpperCase() ==
                                                            "CRITICAL",
                                                          "bg-cobalt":
                                                            _vm.patches[
                                                              idPatch
                                                            ].severity.toUpperCase() ==
                                                            "IMPORTANT",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm
                                                              .$t(
                                                                _vm.patches[
                                                                  idPatch
                                                                ].severity
                                                              )
                                                              .toUpperCase()
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.patches[idPatch].title
                                                )
                                              ),
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.patches[idPatch]
                                                    .description
                                                )
                                              ),
                                            ]),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatDate(
                                                      _vm.patches[idPatch]
                                                        .lastDeploymentChangeTime
                                                    )
                                                  )
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  "(" +
                                                    _vm._s(
                                                      _vm.fromNow(
                                                        _vm.patches[idPatch]
                                                          .lastDeploymentChangeTime
                                                      )
                                                    ) +
                                                    ")"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass: "text-center",
                                                attrs: {
                                                  title: _vm.patches[idPatch]
                                                    .isInstalled
                                                    ? _vm.$t("Installed")
                                                    : _vm.$t("Install"),
                                                },
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "button button-sm",
                                                    attrs: {
                                                      href:
                                                        "#installpatch:" +
                                                        idPatch,
                                                      disabled:
                                                        _vm.patches[idPatch]
                                                          .isInstalled ||
                                                        _vm.patches[idPatch]
                                                          .isInstalling ||
                                                        _vm.patches[idPatch]
                                                          .isDownloading,
                                                      title: _vm.patches[
                                                        idPatch
                                                      ].isInstalled
                                                        ? _vm.$t("Installed")
                                                        : _vm.$t("Install"),
                                                    },
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass:
                                                        "mif mif-download",
                                                      attrs: {
                                                        title: _vm.patches[
                                                          idPatch
                                                        ].isInstalled
                                                          ? _vm.$t("Installed")
                                                          : _vm.$t("Install"),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "m-3 w-100 pr-4",
                        staticStyle: { height: "75vh", "overflow-y": "auto" },
                        attrs: { id: "filemanager" },
                      },
                      [
                        _c("div", { staticClass: "w-100" }, [
                          _vm.filesystem
                            ? _c("div", { staticClass: "pos-relative grid" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-justify-between flex-align-center",
                                  },
                                  [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.filesystem.driveSelected,
                                            expression:
                                              "filesystem.driveSelected",
                                          },
                                        ],
                                        staticClass: "select w-10 m-1 pl-2",
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.filesystem,
                                                "driveSelected",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                            function ($event) {
                                              return _vm.changeDrive(
                                                _vm.filesystem.driveSelected
                                              )
                                            },
                                          ],
                                        },
                                      },
                                      _vm._l(
                                        _vm.filesystem.drives,
                                        function (drive) {
                                          return _c(
                                            "option",
                                            {
                                              key: drive,
                                              domProps: { value: drive },
                                            },
                                            [_vm._v(_vm._s(drive) + "\\")]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "button p-2 secondary",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openCreateFolderDialog()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("Create Folder")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "table",
                                  {
                                    staticClass:
                                      "bg-white border table striped compact overflow",
                                  },
                                  [
                                    _c("thead", [
                                      _c("tr", [
                                        _c("td", [
                                          _vm._v(_vm._s(_vm.$t("File Name"))),
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "text-center" },
                                          [_vm._v(_vm._s(_vm.$t("File Size")))]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-right" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("Date Created"))
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-right" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("Date Modified"))
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "tbody",
                                      [
                                        _c(
                                          "tr",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.filesystem
                                                    .directorySelected !=
                                                  _vm.filesystem.driveSelected,
                                                expression:
                                                  "filesystem.directorySelected != filesystem.driveSelected",
                                              },
                                            ],
                                          },
                                          [
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "bg-lightgray d-flex flex-align-center p-2 c-pointer",
                                                attrs: {
                                                  title:
                                                    _vm.filesystem
                                                      .directorySelected,
                                                },
                                                on: {
                                                  dblclick: function ($event) {
                                                    return _vm.navigate("up")
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "mif-backspace mif-2x",
                                                  attrs: {
                                                    disabled:
                                                      _vm.waitFileSystem,
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-bold no-user-select ml-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.filesystem.directorySelected
                                                            .split("\\")
                                                            .at(-1)
                                                        ) +
                                                        "\\"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c("td", {
                                              staticClass: "bg-lightgray",
                                            }),
                                            _c("td", {
                                              staticClass: "bg-lightgray",
                                            }),
                                            _c("td", {
                                              staticClass: "bg-lightgray",
                                            }),
                                            _c("td", {
                                              staticClass: "bg-lightgray",
                                            }),
                                          ]
                                        ),
                                        _vm.filesystem.items == null ||
                                        _vm.waitFileSystem
                                          ? _c("tr", [
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "text-center",
                                                  attrs: { colspan: "4" },
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass:
                                                      "mif-spinner2 mif-2x ani-spin mr-2",
                                                  }),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "Retrieving items. Please wait..."
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ])
                                          : _vm._l(
                                              _vm.filesystem.items,
                                              function (item) {
                                                return _c(
                                                  "tr",
                                                  { key: item.path },
                                                  [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "c-pointer no-user-select",
                                                        on: {
                                                          dblclick: function (
                                                            $event
                                                          ) {
                                                            item.type ==
                                                            _vm
                                                              .$FileFolderEntityType
                                                              .FN_Folder
                                                              ? _vm.navigate(
                                                                  item.path
                                                                )
                                                              : null
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("span", {
                                                          staticClass: "m-1",
                                                          class: {
                                                            "mif-folder fg-dark":
                                                              item.type ==
                                                              _vm
                                                                .$FileFolderEntityType
                                                                .FN_Folder,
                                                            "mif-file-empty":
                                                              item.type ==
                                                              _vm
                                                                .$FileFolderEntityType
                                                                .FN_File,
                                                          },
                                                        }),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "c-pointer no-user-select",
                                                            attrs: {
                                                              title: item.path,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.path
                                                                    .split("\\")
                                                                    .at(-1)
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-center",
                                                      },
                                                      [
                                                        item.type !=
                                                        _vm
                                                          .$FileFolderEntityType
                                                          .FN_Folder
                                                          ? _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "prettyBytes"
                                                                  )(item.size)
                                                                )
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-right",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatDate(
                                                              item.creationDate
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-right",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatDate(
                                                              item.modificationDate
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row text-center" }, [
      _c("div", { staticClass: "cell-12" }, [
        _c("img", {
          attrs: { src: "/assets/img/icons/prompt.svg", width: "50" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }