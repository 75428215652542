var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "m-6",
      attrs: {
        id: "panel",
        "data-role": "panel",
        "data-title-caption": _vm.$t(_vm.title),
        "data-title-icon": "<span class='mif-chart-bars'></span>",
        "data-collapsible": "true",
        "data-collapsed": "true",
      },
    },
    [
      _vm.loading
        ? _c(
            "div",
            {
              staticClass:
                "h-100 m-0 op-dark-hi p-0 pos-absolute pos-center w-100 z-2",
            },
            [
              _c("span", {
                staticClass:
                  "ani-spin fg-white mif-5x mif-spinner2 mt-13-minus pos-center",
              }),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "overflow-sm p-6" }, [
        _c("div", { staticClass: "d-flex flex-justify-end pb-6 pr-6" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filters.id_group,
                  expression: "filters.id_group",
                },
              ],
              staticClass: "bg-graywhite pl-2 select text-bold text-small",
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.filters,
                    "id_group",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { attrs: { value: "" } }, [
                _vm._v(_vm._s(_vm.$t("All Groups"))),
              ]),
              _vm._l(_vm.groups, function (group) {
                return _c(
                  "option",
                  { key: group.id_group, domProps: { value: group.id_group } },
                  [_vm._v(_vm._s(group.name_group) + " ")]
                )
              }),
            ],
            2
          ),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filters.id_user,
                  expression: "filters.id_user",
                },
              ],
              staticClass: "bg-graywhite pl-2 select text-bold text-small",
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.filters,
                    "id_user",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { attrs: { value: "" } }, [
                _vm._v(_vm._s(_vm.$t("All Users"))),
              ]),
              _vm._l(_vm.users, function (user) {
                return _c(
                  "option",
                  { key: user.id, domProps: { value: user.id } },
                  [_vm._v(_vm._s(user.username) + " ")]
                )
              }),
            ],
            2
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.filters.dt_inf,
                expression: "filters.dt_inf",
              },
            ],
            staticClass:
              "cell-2 date select bg-graywhite p-4 text-bold text-small text-center",
            attrs: {
              type: "date",
              max: _vm._f("moment")(Date.now(), "YYYY-MM-DD"),
            },
            domProps: { value: _vm.filters.dt_inf },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.filters, "dt_inf", $event.target.value)
              },
            },
          }),
          _c("div", { staticClass: "cell-auto" }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.filters.dt_sup,
                expression: "filters.dt_sup",
              },
            ],
            staticClass:
              "cell-2 date select bg-graywhite p-4 text-bold text-small text-center",
            attrs: {
              type: "date",
              max: _vm._f("moment")(Date.now(), "YYYY-MM-DD"),
            },
            domProps: { value: _vm.filters.dt_sup },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.filters, "dt_sup", $event.target.value)
              },
            },
          }),
          _c("div", { staticClass: "cell-auto" }),
          _c("input", {
            staticClass: "button",
            attrs: { type: "button", value: _vm.$t("Search") },
            on: { click: _vm.getStats },
          }),
        ]),
        _c(
          "div",
          { staticClass: "d-flex flex-wrap pt-4" },
          _vm._l(_vm.graph, function (item, index) {
            return _c("div", { class: item, attrs: { id: index } })
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }