<template>
    
    <div class="row p-1 pl-6 pr-6">
        <div class="cell d-flex flex-align-center p-0 ">
            <h3 class="dashboard-section-title text-left-md m-0">
                <ul class="breadcrumbs p-3">
                    <button class="ml-3 mr-3  button square" id="menu-toggle">
                        <span class="mif-menu"></span>
                    </button>
                    <button @click="$root.goBack()" v-if="pageName != 'dashboard'" class="page-link button">
                        <i class=" mif-arrow-left"></i>
                    </button>
                    
                    <li  v-if="pageName == 'dashboard'" class="page-item">
                        <router-link to="/dashboard" class="page-link pl-0">
                            <table>
                                <tr>
                                    <td class="px-1 py-0"
                                        :class="{ 'h2': pageName == 'dashboard', 'p-4': pageName != 'dashboard' }"> 
                                        <label>{{ $t("Dashboard") }}</label>
                                    </td>
                                </tr>
                                <tr >
                                    <td class="px-1 py-0">{{ $t("License") }} : {{ $root.checkAuth().company }} ({{ $root.checkAuth().ma_username }})</td>
                                </tr>
                           
                            </table>
                        </router-link>
                    </li>
                    
                    <li v-if="accounts" class="page-item">
                        <router-link to="/accounts" class="page-link">
                            <span v-if="pageName == 'accounts'" class="mr-1"></span>
                            <span>{{ $t("Credentials Locker") }}</span>
                        </router-link>
                    </li>
                    
                    <li v-if="repositories" class="page-item">
                        <router-link to="/repo" class="page-link">
                            <span v-if="pageName == 'repositories'" class="mr-1"></span>
                            <span>{{ $t("Backup Repositories") }}</span>
                        </router-link>
                    </li>


                    <li  v-if="computer && pc && $root.forceRefreshID >= 0" class="page-item ">
                        <router-link to="/computers" class="page-link">
                            <span>{{ $t("Devices") }} </span>
                        </router-link>
                    </li>

                    <li v-if="computer && pc && $root.forceRefreshID>=0" class="page-item ">
                        <select class="select input w-auto ml-3 mr-3" @change="navigateToComputer" >
                            <option :key="pc2.id" :disabled="(pageName=='backup' && data.editing)" :selected="pc2.id == pc.id" :value="pc2.id" class="no-wrap" v-for="pc2 in $session.getPCList()">
                               <label  :class="{'text-bold':$root.connectionsStates[pc2.id]}"> {{ pc2.name }}</label>
                            </option>
                        </select>
                    </li>

                    <li v-if="backup" class="page-item">
                        <router-link to="/backup/" class="page-link"><span v-if="pageName == 'backup'" class="mr-1" />{{ data.editing ? data.backupName : $t('Create Backup') }}</router-link>
                    </li>

                    <li v-if="restore" class="page-item">
                        <router-link to="/restore" class="page-link"><span v-if="pageName == 'restore'" class="mr-1 mif-arrow-up" />{{ $t('Restore Backup') }}</router-link>
                    </li>

                    <li v-if="backupView" class="page-item">
                        <router-link :to="'/backupview/' + data.job.id" class="page-link  no-wrap "><span
                                v-if="pageName == 'backupview'" class="mr-1 mif-news" />{{ data.job.name }}</router-link>
                    </li>

                    <li v-if="groups" class="page-item">
                        <router-link to="/groups" class="page-link"><span v-if="pageName == 'groups'" class="mr-1" />{{ $t("Groups") }}</router-link>

                    </li>

                    <li v-if="policies" class="page-item">
                        <router-link to="/policies" class="page-link"><span v-if="pageName == 'policies'"
                                class="mr-1" />{{ $t("Policies") }}</router-link>

                    </li>

                    <li v-if="group" class="page-item">
                        <router-link :to="'/group/' + data.group.id" class="page-link  no-wrap "><span
                                v-if="pageName == 'group'" class="mr-1 mif-folder-open" />{{ data.group.name}}</router-link>
                    </li>

                    <li v-if="users" class="page-item">
                        <router-link to="/users" class="page-link"><span v-if="pageName == 'users'" />{{ $t("Users") }}</router-link>
                    </li>
                    <li v-if="pageName == 'settings'" class="page-item">
                        <router-link to="/settings" class="page-link">
                            <span class="mr-1 " />{{ $t("Privacy Settings") }}
                        </router-link>
                    </li>
                    <li v-if="pageName == 'computers'" class="page-item">
                        <router-link to="/computers" class="page-link">
                            <span class="mr-1 " />{{ $t("Devices") }} 
                        </router-link>
                    </li>
                    <li v-if="pageName == 'licenses'" class="page-item">
                        <router-link to="/licenses" class="page-link">
                            <span class="mr-1 " />{{ $t("Licenses") }}</router-link>
                    </li>
                    <li v-if="pageName == 'schedulation'" class="page-item">
                        <router-link to="/scheduling" class="page-link">
                            <span class="mr-1 " />{{ $t("Backup Scheduling") }}</router-link>
                    </li>
                    <li v-if="pageName == 'remotestats'" class="page-item">
                        <router-link to="/remotestats" class="page-link">
                            <span class="mr-1 " />{{ $t("Remote statistics") }}</router-link>
                    </li>
                    <li v-if="pageName == 'backupstats'" class="page-item">
                        <router-link to="/backupstats" class="page-link">
                            <span class="mr-1 " />{{ $t("Backup statistics") }}</router-link>
                    </li>
                    <li v-if="pageName == 'sessions'" class="page-item">
                        <router-link to="/sessions" class="page-link">
                            <span class="mr-1 " />{{ $t("Sessions") }}</router-link>
                    </li>
                    <li v-if="pageName == 'activesessions'" class="page-item">
                        <router-link to="/sessions" class="page-link">
                            <span class="mr-1 " />{{ $t("Active Sessions") }}</router-link>
                    </li>
                    <li v-if="pageName == 'customclient'" class="page-item">
                        <router-link to="/customclient" class="page-link">
                            <span class="mr-1 " />{{ $t("Client customization") }}</router-link>
                    </li>
                </ul>
            </h3>
        </div>
        


        <div v-if="pageName == 'computers'" class="cell d-flex flex-align-center flex-justify-end">
            <ul class="listview">
                   <a class="button primary" onclick="window.Metro.charms.toggle('#agentbuilding');">
                        <span class="icon mif-devices "></span>
                        <span class="">{{ $t("Add Device") }}</span>
                    </a>
                <li class="ml-2">
                    <input type="text" @change="emitFilter" @input="emitFilter" data-role="input"  :placeholder="$t('Search')+'...'" />
                </li>

            </ul>
        </div>
        <div v-if="pageName == 'groups'" class="cell d-flex flex-align-center flex-justify-end pr-1">
            <ul class="listview">
                <li>
                    <a class="button primary mr-1" @click="$root.$emit('OPENGROUPVIEWDIALOG')">
                        <span class="icon mif-2x mif-add"></span>
                        <span>{{ $t("Add Group") }}</span>
                    </a>
                </li>
                <!-- <li>
                    <a :title="$t('Refresh all Iperius Backup')" class="button secondary">
                        <span class="icon mif-2x mif-refresh "></span>
                        <span>{{ $t("Refresh all Iperius Backup") }}</span>
                    </a>
                </li> -->
            </ul>
        </div>

        <div v-if="pageName == 'policies'" class="cell d-flex flex-align-center flex-justify-end pr-1">
            <ul class="listview">
                <li>
                    <a class="button primary mr-1" @click="$root.$emit('OPENPOLICYVIEWDIALOG')">
                        <span class="icon mif-2x mif-add"></span>
                        <span class="">{{ $t("Add Policy") }}</span>
                    </a>
                </li>

            </ul>
        </div>

        <div v-if="pageName == 'dashboard'" class="cell d-flex flex-align-center flex-justify-end ">
            <ul class="listview">
                
                <li class="ml-2 mr-2">
                    <a class="button primary" onclick="window.Metro.charms.toggle('#agentbuilding');">
                        <span class="icon mif-download "></span>
                        <span class="">{{ $t("Agent download") }}</span>
                    </a>
                </li>
                <li>
                    <button class="button dropdown-toggle" id="fullstats" ><span class="mif-chart-pie pr-2"></span>{{ $t("Toggle statistics") }}</button>
                </li>



            </ul>
        </div>
        <div v-if="pageName == 'licenses'" class="cell d-flex flex-align-center flex-justify-end ">
            <ul class="listview">
                
                <li>
                    <a class="button primary ml-4" onclick="window.Metro.charms.toggle('#plugin');">
                        <span class=""></span>{{ $t("Upgrade your License") }}
                    </a>
                </li>



            </ul>
        </div>
        <div v-if="pageName == 'settings'" class="cell d-flex flex-align-center flex-justify-end ">
            <ul class="listview">
                
                <li>
                    <a class="button" @click="$root.requestNotificationPermission()"><span class="mif-notifications mif-2x mr-2"></span> {{ $t("Enable Web Notifications")}}</a>
                </li>

            </ul>
        </div>
        <div v-if="pageName == 'users'" class="cell d-flex flex-align-center flex-justify-end ">
            <ul class="listview">
                <li>
                    <a class="button primary mr-2" @click="$root.$emit('OPENUSERVIEWDIALOG')">
                        <span class="icon mif-2x mif-add"></span>
                        <span>{{ $t("Add User") }}</span>
                    </a>
                </li>
            </ul>
        </div>
        
        <div v-if="pageName == 'computer'" class="cell d-flex flex-align-center flex-justify-end">
            <div class="">

                <ul class="h-menu bg-light  d-flex">
                    
                    <li>
                        <div >

                            <a v-if="pc.backup_license_status == 0" href="#" onclick="window.Metro.charms.toggle('#plugin');" class="dropdown-toggle button primary mr-2 p-4 pr-4">{{ $t('Backup') }}</a>
                            <a v-else href="#" class="dropdown-toggle button primary mr-2 p-4 pr-4">{{ $t('Backup') }}</a>
                            <ul class="d-menu" data-role="dropdown">
                            
                                <li>
                                    <router-link :to="pc.backup_license_status == 0?'#':this.$route.path + '/backup'" class="text-bold" :disabled="!$root.connectionsStates[pc.id] ">
                                        {{ $t("Add backup job") }}
                                    </router-link>
                                </li>
                                <li>
                                    <a :disabled="!$root.connectionsStates[pc.id]" href="#" class="text-bold dropdown-toggle">{{ $t('Restore backup job') }} </a>
                                    <ul v-if="pc.backup_license_status != 0" class="d-menu" data-role="dropdown">
                                        <template v-if="$platformsTypesInfo != null" v-for="t in restoreTypes" >
                                            <li v-if="t == 5" v-for="microsoft_t in $root.getMicrosoftTypes()" :key="microsoft_t" class="">
                                                <a @click="$root.$emit('OPENRESTOREDIALOG', microsoft_t, null)" :disabled="!$root.connectionsStates[pc.id] || pc.backup_license_status==0">Microsoft <b>{{ $platformsTypesInfo[microsoft_t].name }}</b></a>
                                            </li>
                                            <li v-if="t != 5">
                                                <a @click="$root.$emit('OPENRESTOREDIALOG', t, null)" :disabled="!$root.connectionsStates[pc.id] || pc.backup_license_status==0"><b>{{ $platformsTypesInfo[t].name }}</b></a>
                                            </li>
                                        </template>
                                    </ul>
                                </li>
                            
                            </ul>
                    </div>
                    </li>
                    <li>
                        <PCRemoteLink :pc="pc" :disabled="!pc.status" complete="true" />
                    </li>
                    <li>
                        <div :disabled="!$root.connectionsStates[pc.id] || pc.backup_license_status==0">
                            <a class="ml-2 button" href="#" @click="$root.$emit('openTerminal')"><span class="mif-2x mif-external mr-1"></span> {{ $t("Open Command Prompt") }}</a>
                        </div>
                    </li>
                </ul>


                <!-- <ul class="listview">

                    <router-link :to="this.$route.path + '/backup'" class="mr-2 text-bold" :disabled="!$root.connectionsStates[pc.id]">
                        <button class="button primary">{{ $t("Add Backup") }}</button>
                    </router-link>


                    <div class="dropdown-button place-right mr-2" :disabled="!$root.connectionsStates[pc.id]">
                        <a class="button dropdown-toggle primary pl-5">
                            <div class="text-left "> {{ $t('Restore Backup') }} </div>
                        </a>
                        <ul class="d-menu border  place-right mt-2" data-role="dropdown">
                            <template v-if="$platformsTypesInfo != null" v-for="t in restoreTypes" :disabled="!$root.connectionsStates[pc.id]">
                                <li v-if="t == 5" v-for="microsoft_t in $root.getMicrosoftTypes()" :key="microsoft_t" class="">
                                    <a @click="$root.$emit('OPENRESTOREDIALOG', microsoft_t, null)">Microsoft <b>{{ $platformsTypesInfo[microsoft_t].name }}</b></a>
                                </li>
                                <li v-if="t != 5">
                                    <a @click="$root.$emit('OPENRESTOREDIALOG', t, null)"><b>{{ $platformsTypesInfo[t].name }}</b></a>
                                </li>
                            </template>

                        </ul>
                    </div>

                    <PCRemoteLink :pc="pc" :disabled="!$root.connectionsStates[pc.id]" complete="true" />

                  


                </ul> -->
            </div>
        </div>



    </div>
</template>
<script>

import moment from 'moment';
import PCRemoteLink from '../../components/utils/PCRemoteLink.vue';
export default {
    name: "Navigation",
    components: {
        "PCRemoteLink": PCRemoteLink,
    },
    props: {
        pageName: {
            type: String,
            required: true
        },
        data: {
            type: Object,
            required: false
        },
        childIframeSrc: {
            type: String,
            required: false
        },
        childisNotificationsVisible: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            input: "",
            back: false,
            computers: false,
            computer: false,
            backup: false,
            licenses: false,
            restore: false,
            backupView: false,
            accounts: false,
            repositories: false,
            groups: false,
            policies: false,
            group: false,
            users: false,
            pc: null,
            // questo è l'array che contiene le voci di sottomenu del bottone restore backup
            restoreTypes: [
                this.$PlatformsTypes.FileFolder,
                this.$PlatformsTypes.Network,
                this.$PlatformsTypes.Microsoft_OneDriveForBusiness,
                this.$PlatformsTypes.Microsoft_SharePoint,
                this.$PlatformsTypes.Microsoft_Teams,
                this.$PlatformsTypes.Microsoft_Exchange365,
                this.$PlatformsTypes.HyperV,
                this.$PlatformsTypes.Database_SQLServer
            ],

        }
    },
    methods: {

        navigateToComputer(event) {
            var currentRoomPC = this.$session.getRoomPC();
            var newRoomPCID=event.target.value; // Ottieni l'ID del computer selezionato
            var newURL;
            if(currentRoomPC==null){
                newURL = "/computers/computer/" + newRoomPCID;
            }else{
                newURL = this.$route.path.replace(currentRoomPC.id, newRoomPCID);
            }
            
            this.$router.replace({path:newURL});
            this.$router.push(newURL);
            window.location.href = newURL;//this.replaceIdInUrl(this.$route.path, selectedId);
        },

        replaceIdInUrl(url, newId) {
            // Regex per individuare un UUID (puoi personalizzare se l'ID ha un formato diverso)
            const idRegex=/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

            // Sostituisci il primo match dell'ID con la nuova stringa
            return url.replace(idRegex, newId);
        },

        toggleNotifications() {
            window.Metro.charms.toggle("#notifications");
        },



        async getOTP() {
            var Key_OTP=await this.$api.getOTP(this.pc.id);
            console.log(Key_OTP);

            var win=window.open('iperiusremote://'+Key_OTP+'/', '_blank');
            win.getValue=function() {
                return Key_OTP;
            };

        },

        emitFilter: function(event) {
            this.$root.$emit("filteredString", event.target.value);
        },

       /*  formatDate(dateString) {
            // Usa date-fns per formattare la data
            //return format(new Date(dateString), 'yyyyMMddHHmmss');
            return moment(dateString).utc().format('yyyyMMDDHHmmss');
        }, */

    },
    created() {
        switch (this.pageName.toLowerCase()) {
            case "computers":
                this.computers=true;
                //this.computersnum = this.data.computersnum;
                this.back=true;
                break;

            case "accounts":
                this.back=true;
                this.computers=true;
                this.accounts=true;
                break;

            case "repositories":
                this.back=true;
                this.computers=true;
                this.repositories=true;
                break;

            case "computer":
                this.back=true;
                this.computers=true;
                this.computer=true;
                this.pc=this.data.pc;
                break;

            case "backup": // data deve contenere il booleano editing
                this.back=true;
                this.computers=true;
                this.computer=true;
                this.backup=true;
                this.pc=this.data.pc;
                break;

            case "restore":
                this.back=true;
                this.computers=true;
                this.computer=true;
                this.restore=true
                break;

            case "backupview":// data deve contenere job.id, job.name
                this.back=true;
                this.computers=true;
                this.computer=true;
                this.backupView=true
                break;

            case "groups":
                this.back=true;
                this.groups=true;
                break;

            case "policies":
                this.back=true;
                this.policies=true;
                break;

            case "group": // data deve contenere group.id, group.name
                this.back=true;
                this.groups=true;
                this.group=true;
                break;

            case "users":
                this.back=true;
                this.users=true;
                break;

            case "settings":
                this.back=true;
                break;

            case "remotestats":
                this.back=true;
                break;

        }
    }
}
</script>