<template>
    <div class="dialog light w-100-sm w-100-md w-75-lg  w-50-xl draggable-item" v-cloak>

        <DialogTitle :title="serviceAccount.id == null ? $t('Add Service Account') : serviceAccount.name"
            :defaultTitle="serviceAccount.id == null ? $t('Add Service Account') : $t('Edit/Test Service Account')"
            :subTitle="serviceAccount.type_name" @closeDialog="$root.$emit('CLOSESERVICEACCOUNTDIALOG')" />


        <div class="dialog-content overflow bg-light">

            <div class="form-group">
                <label>{{ $t("Name") }}</label>
                <input data-role="input" type="text" v-model.trim="serviceAccount.name" maxlength="50"
                    :placeholder="$t('enter name')" data-prepend="<span class='mif-pencil'></span>" />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>

            <div class="form-group">
                <label>{{ $t("Sender") }}</label>
                <input data-role="input" type="text" v-model.trim="serviceAccount.options.senderAddress"
                    :placeholder="$t('Enter a sender email')" data-prepend="<span class='mif-mail'></span>" />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>


            <div class="form-group">
                <div class="row">
                    <div class="cell-6">
                        <label>{{ $t("Username") }}</label>
                        <input data-role="input" class="metro-input" type="text" v-model.trim="serviceAccount.username"
                            :placeholder="$t('Enter Username')" data-prepend="<span class='mif-user'></span>" />
                        <small class="text-muted">{{ $t("Required") }}</small>
                    </div>
                    <div class="cell-6">
                        <label>{{ $t("Password") }}</label>
                        <input data-role="input" class="metro-input" type="password" v-model.trim="serviceAccount.password"
                            :placeholder="$t('Enter Password')" data-prepend="<span class='mif-lock'></span>" />
                        <small class="text-muted">{{ $t("Required") }}</small>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label>{{ $t("Recipient") }}</label>
                <input data-role="input" type="text" v-model.trim="serviceAccount.options.recipient"
                    :placeholder="$t('Enter recipient email')" data-prepend="<span class='mif-mail'></span>" />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>

            <!--<div class="form-group">
                <div class="row">
                    <div class="cell-8">
                        <label>Server SMTP</label>
                        <input data-role="input" class="metro-input" type="text" v-model="serviceAccount.url"
                            :placeholder="$t('Enter Domain')" data-prepend="<span class='mif-cloud'></span>" disabled/>
                        <small class="text-muted">{{ $t("Required") }}</small>
                    </div>
                    <div class="cell-4 ">
                        <label class="text-upper">{{ $t("Port") }}</label>
                        <input data-role="input" class="metro-input" type="text" v-model="serviceAccount.port"
                            :placeholder="$t('Enter Domain')" data-prepend="<span class='mif-cloud'></span>" disabled/>
                        <small class="text-muted">{{ $t("Required") }}</small>
                    </div>
                </div>
            </div>-->

            <div class="form-group">
                <div class="row">
                    <div class="cell-12 ">
                        <input data-role="checkbox" type="checkbox"
                            :data-caption="$t('Use secure connection (SSL/TLS)')" v-model="serviceAccount.use_ssl"
                            required data-prepend="<span class='mif-lock'></span>" disabled />
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="cell-12 ">
                        <input data-role="checkbox" type="checkbox" :data-caption="$t('Use POP3')"
                            v-model="serviceAccount.options.usePop3" required
                            data-prepend="<span class='mif-lock'></span>" />
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="cell-6">
                        <label>{{ $t("POP Username") }}</label>
                        <input data-role="input" class="metro-input" type="text"
                            v-model.trim="serviceAccount.options.popUsername" :placeholder="$t('Enter Username')"
                            data-prepend="<span class='mif-user'></span>" />
                        <small class="text-muted">{{ $t("Required") }}</small>
                    </div>
                    <div class="cell-6">
                        <label>{{ $t("Password") }}</label>
                        <input data-role="input" class="metro-input" type="password"
                        v-model.trim="serviceAccount.options.popPassword" :placeholder="$t('Enter Password')"
                            data-prepend="<span class='mif-lock'></span>" />
                        <small class="text-muted">{{ $t("Required") }}</small>
                    </div>
                </div>
            </div>

            <div class="form-group" v-if="serviceAccount.type == '7'">
                <div class="row">
                    <div class="cell-8">
                        <label>POP Server</label>
                        <input data-role="input" class="metro-input" type="text"
                            v-model.trim="serviceAccount.options.popServer" :placeholder="$t('Enter POP Server')"
                            data-prepend="<span class='mif-cloud'></span>" />
                        <small class="text-muted">{{ $t("Required") }}</small>
                    </div>
                    <div class="cell-4 ">
                        <label class="text-upper">{{ $t("POP Port") }}</label>
                        <input data-role="input" class="metro-input" type="text"
                            v-model.trim="serviceAccount.options.popPort" :placeholder="$t('Enter POP Port')"
                            data-prepend="<span class='mif-cloud'></span>" />
                        <small class="text-muted">{{ $t("Required") }}</small>
                    </div>
                </div>
            </div>
            <!-------------------------------SCELTA PC----------------------------------------->
            <div v-if="$session.getRoomPC() == null" class="form-group">
                <label>
                    <span>{{ $t("Select an endpoint from which test the {0} connection", {0: $platformsTypesInfo[serviceAccount.type].name}) }}</span>
                </label>
                <select class="select cell-9" v-model="testPC" :disabled="$session.getPCList().length == 0">
                    <option :data-append="pc.note" v-for="pc in $session.getPCList()" :value="pc"
                        :disabled="!$root.connectionsStates[pc.id]" v-bind:key="pc.id"> {{ pc.name }}
                    </option>
                </select>
            </div>
        </div>
        <!-------------------------------TEST ACCOUNT----------------------------------------->
        <div class="dialog-actions d-flex">
            <button v-if="serviceAccount.id !=null" class="button primary" :disabled="tested == TEST_WAIT" @click="$root.duplicateServiceAccount(serviceAccount)">{{ $t("Duplicate") }}</button><!---->
            <div v-if="tested == TEST_WAIT"><span class="mif-spinner2 ani-spin" /></div>
            <button class="button primary" @click="test" :disabled="!enableTest">{{ $t("Test Account") }}</button>
            <button class="button primary" :disabled="!enableSave" @click="$root.saveServiceAccount(serviceAccount)">{{ $t("Save") }}</button>
            <button class="button primary" @click="$root.$emit('CLOSESERVICEACCOUNTDIALOG')">{{ $t("Close") }}</button>
            <button v-if="serviceAccount.id !=null" class="button alert" :disabled="tested == TEST_WAIT" @click="$root.deleteServiceAccount(serviceAccount)">{{ $t("Delete") }}</button>
        </div>
    </div>
</template>

<script>
import DialogTitle from "../../utils/DialogTitle.vue";
import { draggableMixin } from '../../../mixins/draggable-mixin'


export default {
    mixins: [draggableMixin],
    name: "EmailServiceAccount",
    components: {
        "DialogTitle": DialogTitle,
    },
    props: {
        serviceAccount: Object,
    },
    data() {
        return {
            testPC: null,

            // -1 : avviato
            // 0 : non avviato o fallito
            // 1 : ok
            tested: 0,
            TEST_NOTOK: 0,
            TEST_WAIT: -1,
            TEST_OK: 1,
        }
    },
    computed: {
        allInputCompleted() {
            return this.serviceAccount.name != "" && (this.$session.getRoomPC() != null || this.testPC != null) &&
                this.serviceAccount.url != "" &&
                this.serviceAccount.username != "" &&
                this.serviceAccount.password != "" &&
                this.serviceAccount.options.senderAddress != "";
        },
        enableTest() {
            return this.allInputCompleted && this.tested == this.TEST_NOTOK;
        },
        enableSave() {
            return this.allInputCompleted && (this.tested == this.TEST_OK || this.tested == this.TEST_NOTOK && this.serviceAccount.id != null);
        }

    },
    created() {
        if (this.serviceAccount.options.lastTestPC != null)
            this.testPC = this.$session.getPCWithID(this.serviceAccount.options.lastTestPC);
    },
    methods: {
        async test() {
            this.tested = (await this.$root.testServiceAccount(this.serviceAccount, this.testPC)).okNumber;
        }
    }

}

</script>