<template>

<!--
        0 -> # computer ancora senza licenza one, ma in rubrica di Iperius Remote
        1 -> # computer connesso tuttora alla licenza corretta;
        2 -> # computer in stato di waiting solo con questa licenza: si può ricollegare;
        3 -> # computer rimosso da questa licenza: NON si potrà ricollegare fino a riabilitazione;
        4 -> # computer BANNATO: non si potrà mai più ricollegare fino a nostro contrordine;

-->

    <div class="dropdown-button">
        <a class="button" :title="computer.backup_license_status == 2 ? $t('Waiting to restart agent') : ''"
            :class="computer.backup_license_status == 2 ? 'fg-white bg-amazon' : ' dropdown-toggle'">
            <div class="text-left">
                <span class="mif-admin_panel_settings mif-3x"></span>
            </div>
        </a>
        <ul class="d-menu border place-right text-center" data-role="dropdown">
            <li v-if="computer.backup_license_status == 2">
                <a class="text-bold" :title="$t('Waiting to restart agent')">
                    {{ $t('Waiting to restart agent') }}
                </a>
            </li>
            <li>
                <a :title="$t('Remove from license')" @click.prevent="removeFromLicense(computer.id)"
                    :class="computer.backup_license_status == 1 ? '' : 'fg-gray disabled'">
                    <span class="icon mif-2x mif-link_off"></span> {{ $t('Remove from license') }}
                </a>
            </li>
            <li>
                <a :title="$t('Add to license')" @click.prevent="reconnectToLicense(computer.id)"
                    :class="computer.backup_license_status == 3 ? '' : 'fg-gray disabled'">
                    <span class="icon mif-2x mif-link_on"></span> {{ $t('Add to license') }}
                </a>
            </li>


        </ul>
    </div>
</template>
<script>
import { PC_MESSAGES } from '../../utils/messages.js';
export default {
    name: "PCLicenseLink",
    props: {
        computer: {
            type: Object,
            required: true
        },
        parentName: String,
    },
    methods: {
        async removeFromLicense(idPC) {
            var dialogID = Math.floor(100000 + Math.random() * 900000).toString();
            let self = this;
            this.$root.$on(dialogID, ok => {

                if (!ok) {
                    self.$root.$off(dialogID);
                    return;
                }

                this.$api.removeFromLicense(idPC).then(async (value) => {
                    if (value) {
                        self.$root.toast(this.$t(PC_MESSAGES.LICENSE_REMOVED), 5000, "success");
                        self.computer.backup_license_status = 3;
                        let ext_lic_info = await self.$api.getLicenseExtendedInfo();

                        if (ext_lic_info != null) {
                            this.$session.setLicenses(ext_lic_info);
                            //this.licenses = this.$session.get("LICENSES");
                            this.updateLicenseInLicenseVue();
                        };
                    }
                    self.$root.$off(dialogID);

                });
            });
            this.$root.openYesNoDialog(dialogID, this.$t("Remove device from license"), this.$t("Are you sure to remove this device from license?"));
        },

        async reconnectToLicense(idPC) {
            var dialogID = Math.floor(100000 + Math.random() * 900000).toString();
            let self = this;
            this.$root.$on(dialogID, ok => {

                if (!ok) {
                    self.$root.$off(dialogID);
                    return;
                }

                self.$api.addToLicense(idPC).then(async (value) => {
                    if (value) {
                        self.$root.toast(this.$t(PC_MESSAGES.LICENSE_ADDED), 5000, "success")
                        self.computer.backup_license_status = 2;

                        let ext_lic_info = await self.$api.getLicenseExtendedInfo();

                        if (ext_lic_info != null) {
                            this.$session.setLicenses(ext_lic_info);
                            //this.licenses = this.$session.get("LICENSES");
                            this.updateLicenseInLicenseVue();
                        };                        
                        
                    }
                    self.$root.$off(dialogID);
                });
            });

            this.$root.openYesNoDialog(dialogID, this.$t("Register device to license"), this.$t("Are you sure to register this device to license?"));
        },

        updateLicenseInLicenseVue() {
            console.log('Istanza del componente padre: ' + this.parentName);
            if (this.parentName === 'Licenses')  
                this.$emit('updateLicense');
        }

    }
    

}
</script>