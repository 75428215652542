<template>
    <div>
        <Navigation pageName="computers"  />
       
        
        <div class="row m-5 pt-5" v-if="computers == null" >
            <div class="h-100 w-100 z-2">
                    <span class="ani-spin fg-primary mif-5x mif-spinner2 pos-center"></span>
            </div>
        </div>

        <NoDataAvailable v-else-if="computers.length == 0" :message="$t('No computers available')" />

       
        
        <div id="all_computers" class="w-100 p-5 overflow" >
               
               
                <div v-if="computers" class=" d-flex flex-justify-start">
                    
                  <div class="cell-auto pr-2 pl-0">
                      <select class=" pl-2 select  text-small" v-model="filters.id_group">
                          <option value="">{{ $t("All Groups") }}</option>
                          <option v-for="group in groups" :value="group.id_group" v-bind:key="group.id_group">{{ group.name_group }}
                          </option>
                         
                      </select>
                  </div>
                  <div class="cell-auto flex-justify-end m-0 d-flex flex-align-end pr-2">
                      <select class="pl-2 select  text-small" v-model="filters.status">
                          <option value=""> {{ $t("Online & Offline") }}</option>
                          <option value="1">{{ $t("Online only") }}</option>
                          <option value="0">{{ $t("Offline only") }}</option> 
                      </select>
                  </div>

                  
                  
                 
                </div>
                <table v-if="computers != null" id="computers-list" class="striped compact table w-100 bg-white border ">
                    <thead class="mb-0">
                        <tr class="mb-3">
                            
                            <td class="text-left pl-4" style="vertical-align: middle;width:100px;">{{ $t('Device') }}</td>
                            <td class="text-left" style="vertical-align: middle;min-width:100px;">{{ $t("Last Backup") }} </td>
                            <td class="text-center" style="vertical-align: middle;width:100px;">{{ $t("Groups") }} </td>
                            <td class="text-center" style="vertical-align: middle;">{{ $t("Organization") }}</td>
                            <td class="text-center" style="vertical-align: middle;white-space:nowrap;">{{ $t("Private/Public IP") }}</td>
                            <td class="text-center" style="vertical-align: middle;">{{ $t("Iperius Remote Client") }}</td>
                            <td class="text-center" style="vertical-align: middle;">{{ $t("Monitoring/Backup Agent") }}</td>
                            <td class="text-center" style="vertical-align: middle;width:100px;">{{ $t("Remote Access") }}</td>
                            <td class="text-center" style="vertical-align: middle;">{{ $t("License Status") }}</td>
                            
                        </tr>
                    </thead>

                    <tbody>
                        <tr class="pt-10" v-if="computers == null">
                            <td class="p-6" colspan="10" >
                                    <div  class="d-flex flex-justify-center  p-5">
                                    <span class="mif-spinner2 fg-primary ani-spin mif-3x " />
                                </div>
                            </td>
                        </tr>
                        <tr class="pt-10" v-if="computers != null && Object.keys(computers).length == 0">
                            <td class="p-6 text-center" colspan="12" >
                                <NoDataAvailable :message="$t('No Devices available')" />
                            </td>
                        </tr>
                        <!--computers è una mappa {idPC : pc}-->
                        <tr v-for="(computer, id) in computers" :key="id" v-if="(filters.status == '' || ($root.connectionsStates[computer.id] == filters.status ? true : false)) && (filters.id_group == '' || computer.groups.some(group => group.id === filters.id_group))" >
                            
                            <td class="">
                                <PCLink :pc="computer" />
                            </td>
                           
                            <td class="text-left">
                                <div v-if="computer.last_job_result != '' && computer.last_job_name != ''" class="d-flex"> 
                                    <span class="c-context-menu mr-1"  :class="computer.last_job_result.getResultValueInfo().color" :title="$t(computer.last_job_result.getResultValueInfo().label)"> ● </span>  
                                    <div @click="openBackupView(computer.last_job_id, computer.id)" class="p-0 m-0 ">
                                        <label class="no-wrap text-bold c-pointer" v-if="computer.last_job_name"> {{ computer.last_job_name }}</label><br>
                                        <label v-if="computer.last_job_dt_start">({{ formatDate(computer.last_job_dt_start) }})</label> 
                                    </div>
                                </div>
                            </td>
                            
                            <td class="text-center">
                                    <router-link class="button fg-dark mr-2 mb-1 small" v-for="group in computer.groups" v-bind:key="group.id" :to="'/groups#' + group.id" :key="group.id">{{ group.name }}</router-link>
                            </td>
                            <td class="text-center">{{ computer.organization_name }}</td>
                            <td class="text-center no-wrap">{{ computer.ipaddress_lan }} / {{ computer.ipaddress_public }} </td>
                            <td class="text-center">{{ computer.version_remote }} </td>
                            <td class="text-center">{{ computer.version_backup }} </td>
                            <td class="text-center">
                                <PCRemoteLink :pc="computer"/>
                            </td>
                            <td class="text-center"> 
                                <PCLicenseLink :computer="computer" />
                            </td>
                            
                            <!--  <td class="text-center">
                                <a class="button  tertiary" href="#" @click="openComputerView(computer)"> 
                                    <span class="mif-menu_open mif-2x"></span> 
                                </a>
                            </td> -->

                        </tr>
                    </tbody>
                </table>
                
            </div>
        
    </div>
    
</template>
<script>

import Navigation from './utils/Navigation';
import { formatDate, fromNow, diffDate } from '../utils/utilitiesmodule';

import PCRemoteLink from '../components/utils/PCRemoteLink.vue';
import PCLicenseLink from '../components/utils/PCLicenseLink.vue';
import PCBackupLink from '../components/utils/PCBackupLink.vue';
import PCLink from "../components/utils/PCLink"
export default {
    name: "Computers",
    components:{
        Navigation,
        PCLink,
        PCRemoteLink,
        PCLicenseLink,
        PCBackupLink,
            //Tile
    },
    data() { return { 
        computers: null,
        filteredcomputers:null,
        connectionPctimeInterval:null,
        groups: null,
        filters: {
            backupName: '',
            numrows: 25,
            offset: 0,
            lastresult: '',
            status: '',
            dt_inf: '',
            dt_sup: '',
            id_group: '',
            id_pc: '',
            id_host: '',
            id_user: '',
            limit: '-1',
            activitystatus: '',
        },
        } 
    },
  
     mounted: function () {
        var self = this;
         //Solo dopo aver recuperato i computers, avvio la connessione socket e recupero i jobs
         this.$api.getPCList().then(pcs => {
             self.$session.setPCList(pcs);

             if (pcs == null) {
                 //Se la chiamata fosse andata a buon fine, computers sarebbe stato una mappa idPC : pc 
                 self.computers = {};
                 return;
             }

             self.computers = pcs.reduce((obj, pc) => ({ ...obj, [pc.id]: pc }), {});
             self.checkRemoteConnectionTimerLight();
           

             //serve per fixare la prima apertura della scheda laterale.
             //self.selectedcomputer = pcs[0];
             //###DA INSERIRE IN OGNI COMPONENTE CHE NECESSITA DI CHIAMATE SOCKET###
             // "checkSocket" avvia la connessione socket se ferma
             // "socketEventConnected" viene emesso quando la connessione socket è attiva, quindi nella sua callback si possono avviare tutte le istruzioni che riguardano le chiamate socket
             // "socketEventDisconnected" viene emesso quando la connessione socket non è attiva
             self.$root.$on("socketEventConnected", () => {
                 self.$root.socketConnected = true;
                 self.$root.checkConnectionTimerLight();
             });
             self.$root.checkSocket();



             //passo la lista dei computer e vedo quali sono connessi attraverso IPERIUS REMOTE, cioè collegati come host
             // self.$api.getPCRemoteConnected(pcs).then(map => {
             //     self.$root.connectionsStatesRemote = map;
             //     self.$session.setRemoteConnectionStates(map); // viene letta da PCRemoteLink.vue solo la prima volta poi viene letto con timer da socketapi di Remote con il metodo checkRemoteConnectionTimerLight()
             //     pcs.forEach(computer => {
             //         computer.connected_remote = map[computer.id_remote];
             //     });
             //     //Trasformo la lista in una mappa {idPC : pc}
             //     self.getJobs();
             // });
             //this.$root.checkSocket();



         });


    },
    beforeDestroy: function() {
        this.$root.$off("socketEventConnected");
        this.$root.$off("socketEventDisconnected");
        clearInterval(this.connectionPctimeInterval);
        this.computers = null; // Pulire i dati in memoria
    },
    methods:{

        checkRemoteConnectionTimerLight() {
            let self = this;
            var PCList = self.$session.getPCList();
            // timer per controllo stato ogni 3 secondi
            self.checkRemoteConnectionTimer = setInterval(() => {
                if (self.computers == null)
                    return;
                // api che verifica i remote connessi
                this.$api.getPCRemoteConnected(PCList)
                    .then(response => {
                        var remoteConnected, pcsConnected;
                        //Se c'è un problema del server, questa chiamata restituisce undefined.
                        //metto tutto a false
                        if (response == undefined) {
                            remoteConnected = {};
                            pcsConnected = {};
                            PCList.forEach(pc => {
                                remoteConnected[pc.id_remote] = false;
                                pcsConnected[pc.id] = false;
                            });
                        } else {
                            remoteConnected = response[0];
                            pcsConnected = response[1];
                        }

                        // alla fine aggiorno l'oggetto in sessione per far cambiare i valori sulla lista
                        self.$session.setRemoteConnectionStates(remoteConnected);
                        // emetto un command che viene recepito da PCRemoteLink.vue
                        self.$emit("updateConnectionsStates", remoteConnected);

                        Object.keys(pcsConnected).forEach(idPC => {
                            self.computers[idPC].connected_remote = pcsConnected[idPC];
                        })
                    });

            }, 5000);
        },

        formatDate(date) {
            return formatDate(date, this.$moment);
        },
        fromNow(date) {
            if (date == null || date == "") return ""
            return fromNow(date, this.$moment);
        },
        diffDate(datestart, dateend) {
            return diffDate(datestart, dateend, this.$moment);
        },

        openBackupView(idBackup) {
            this.idBackupInView = idBackup;
            this.$root.$emit("OPENBACKUPVIEW", idBackup);
        },

        filteredList(data) {
            this.filteredcomputers = this.computers;
            this.filteredcomputers = this.computers.filter((computer) =>{
                return computer.name.toLowerCase().includes(data.toLowerCase()) || computer.description.toLowerCase().includes(data.toLowerCase())
            }); 
        },
        
    }
}
</script>
