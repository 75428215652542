var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "dialog light w-100-sm w-100-md w-75-lg w-50-xl draggable-item",
      attrs: { id: "foldersource" },
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.$root.$emit("CLOSESOURCEDIALOG")
        },
      },
    },
    [
      _c("DialogTitle", {
        attrs: {
          title: _vm.$t("Configure Backup Source"),
          defaultTitle: _vm.$t("Configure Backup Source"),
          subTitle: _vm.$t(_vm.source.type_name),
        },
        on: {
          closeDialog: function ($event) {
            return _vm.$root.$emit("CLOSESOURCEDIALOG")
          },
        },
      }),
      _vm.step == 0
        ? _c(
            "div",
            { staticClass: "dialog-content overflow bg-light" },
            [
              _c("SelectedResourcesLayout", {
                attrs: {
                  buttonText: _vm.$t("Select files and folders"),
                  singleItem: false,
                  type: _vm.source.type,
                  isDisabled: false,
                  list: _vm.source.folderSource.paths,
                  heightPercentage: 55,
                  canCancel: true,
                },
                on: { clickFunction: _vm.toggleFileSystemSidebar },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == 1,
              expression: "step == 1",
            },
          ],
          staticClass: "dialog-content overflow bg-light",
        },
        [
          _c("div", { staticClass: "form-group" }, [
            _c("h6", { staticClass: "bg-light px-2 py-2 text-bold" }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("Filter by extensions")) +
                    " (" +
                    _vm._s(_vm.$t("Optional")) +
                    ")"
                ),
              ]),
            ]),
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.extensionsFilterAction,
                    expression: "source.options.extensionsFilterAction",
                  },
                ],
                attrs: {
                  name: "includeExcludeExtensions",
                  type: "radio",
                  "data-role": "radio",
                  "data-caption": _vm.$t("Exclude specified extensions"),
                },
                domProps: {
                  value: 0,
                  checked: _vm._q(_vm.source.options.extensionsFilterAction, 0),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(
                      _vm.source.options,
                      "extensionsFilterAction",
                      0
                    )
                  },
                },
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.extensionsFilterAction,
                    expression: "source.options.extensionsFilterAction",
                  },
                ],
                attrs: {
                  name: "includeExcludeExtensions",
                  type: "radio",
                  "data-role": "radio",
                  "data-caption": _vm.$t("Include only specified extensions"),
                },
                domProps: {
                  value: 1,
                  checked: _vm._q(_vm.source.options.extensionsFilterAction, 1),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(
                      _vm.source.options,
                      "extensionsFilterAction",
                      1
                    )
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "cell-12" }, [
              _c("label", { staticClass: "text-bold" }, [
                _vm._v(_vm._s(_vm.$t("Extensions :"))),
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.specifiedExtensionsArray,
                      expression: "specifiedExtensionsArray",
                    },
                  ],
                  staticClass: "input",
                  attrs: { multiple: "", "data-role": "select" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.specifiedExtensionsArray = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(
                  _vm.fileExtensions,
                  function (extensionsList, extensionsCategory) {
                    return _c(
                      "optgroup",
                      {
                        key: extensionsCategory,
                        attrs: { label: _vm.$t(extensionsCategory) },
                      },
                      _vm._l(extensionsList, function (extension) {
                        return _c(
                          "option",
                          { domProps: { value: extension } },
                          [_vm._v(_vm._s(extension))]
                        )
                      }),
                      0
                    )
                  }
                ),
                0
              ),
            ]),
          ]),
          _c("br"),
          _c("div", { staticClass: "form-group" }, [
            _c("h6", { staticClass: "bg-light px-2 py-2 text-bold" }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("Filter by file size")) +
                    " (" +
                    _vm._s(_vm.$t("Optional")) +
                    ")"
                ),
              ]),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.source.options.useFileSizeFilter,
                  expression: "source.options.useFileSizeFilter",
                },
              ],
              attrs: {
                type: "checkbox",
                "data-caption": _vm.$t("Use file size filter"),
                "data-role": "checkbox",
              },
              domProps: {
                checked: Array.isArray(_vm.source.options.useFileSizeFilter)
                  ? _vm._i(_vm.source.options.useFileSizeFilter, null) > -1
                  : _vm.source.options.useFileSizeFilter,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.source.options.useFileSizeFilter,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.source.options,
                          "useFileSizeFilter",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.source.options,
                          "useFileSizeFilter",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.source.options, "useFileSizeFilter", $$c)
                  }
                },
              },
            }),
            _c("div", { staticClass: "w-100 d-flex flex-align-center" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.source.options.fileSizeFilterAction,
                      expression: "source.options.fileSizeFilterAction",
                    },
                  ],
                  staticClass: "input input-small mr-2 h-100 w-40",
                  attrs: { disabled: !_vm.source.options.useFileSizeFilter },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.source.options,
                        "fileSizeFilterAction",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(
                  _vm.$root.selectValues.fileSizeFilterAction,
                  function (value, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: index } },
                      [_vm._v(" " + _vm._s(_vm.$t(value)) + " ")]
                    )
                  }
                ),
                0
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.source.options.fileSizeFilterCompare,
                      expression: "source.options.fileSizeFilterCompare",
                    },
                  ],
                  staticClass: "input input-small mr-2 h-100 w-40",
                  attrs: { disabled: !_vm.source.options.useFileSizeFilter },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.source.options,
                        "fileSizeFilterCompare",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(
                  _vm.$root.selectValues.fileSizeFilterCompare,
                  function (value, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: index } },
                      [_vm._v(" " + _vm._s(_vm.$t(value)) + " ")]
                    )
                  }
                ),
                0
              ),
              _c("input", {
                directives: [
                  { name: "number", rawName: "v-number" },
                  {
                    name: "range",
                    rawName: "v-range",
                    value: { min: 1, max: 999999 },
                    expression: "{ min: 1, max: 999999 }",
                  },
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.source.options.fileSize,
                    expression: "source.options.fileSize",
                    modifiers: { number: true },
                  },
                ],
                staticClass: "input text-right mr-2 w-25",
                attrs: {
                  type: "number",
                  disabled: !_vm.source.options.useFileSizeFilter,
                },
                domProps: { value: _vm.source.options.fileSize },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.source.options,
                      "fileSize",
                      _vm._n($event.target.value)
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _c("span", [_vm._v(" MB")]),
            ]),
          ]),
        ]
      ),
      _vm.step == 2
        ? _c("div", { staticClass: "dialog-content overflow bg-light" }, [
            _vm.source.folderSource.paths.length != 0
              ? _c("div", { staticClass: "form-group overflow" }, [
                  _c("h6", { staticClass: "bg-light px-2 py-2 text-bold" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("Exclusions")) +
                          " (" +
                          _vm._s(_vm.$t("Optional")) +
                          ")"
                      ),
                    ]),
                  ]),
                  _c("hr"),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "Click on an item to select subfolders or files to exclude"
                        )
                      ) +
                      " "
                  ),
                  _c(
                    "table",
                    {
                      staticClass:
                        "striped table text-small w-100 table-compact",
                    },
                    [
                      _vm._m(0),
                      _c(
                        "tbody",
                        _vm._l(_vm.source.folderSource.paths, function (res) {
                          return _c(
                            "tr",
                            {
                              key: res.id,
                              on: {
                                click: function ($event) {
                                  return _vm.openFileSystemSidebarFrom(res)
                                },
                              },
                            },
                            [
                              _c("td", { staticClass: "c-pointer" }, [
                                _c("span", {
                                  staticClass: "px-2",
                                  class: res.type.getMetroIcon(res.path),
                                }),
                                _vm.source.options.recreateCompletePath
                                  ? _c(
                                      "span",
                                      { staticClass: "flex-self-center" },
                                      [_vm._v(_vm._s(res.path))]
                                    )
                                  : _c(
                                      "span",
                                      { staticClass: "flex-self-center" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              (res.path.indexOf("/") != -1
                                                ? res.path.split("/")
                                                : res.path.split("\\")
                                              ).at(-1)
                                            )
                                        ),
                                      ]
                                    ),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "form-group overflow" },
              [
                _vm.source.options.excludedPaths.length == 0
                  ? _c("NoDataAvailable", {
                      attrs: { message: _vm.$t("No excluded path selected") },
                    })
                  : _c("div", [
                      _c("label", [_vm._v(_vm._s(_vm.$t("Excluded paths:")))]),
                      _c("div", [
                        _c(
                          "table",
                          {
                            staticClass:
                              "striped table text-small w-100 table-compact",
                          },
                          [
                            _c("thead", [
                              _c("th"),
                              _c("th", { staticClass: "w-10" }),
                            ]),
                            _c(
                              "tbody",
                              _vm._l(
                                _vm.source.options.excludedPaths,
                                function (res, i) {
                                  return _c(
                                    "tr",
                                    { key: res.id, attrs: { title: res.path } },
                                    [
                                      _c("td", { staticClass: "d-flex" }, [
                                        _c("span", {
                                          staticClass: "px-2",
                                          class: res.type.getMetroIcon(
                                            res.path
                                          ),
                                        }),
                                        _c(
                                          "span",
                                          {
                                            attrs: { lass: "flex-self-center" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.cutText(res.path, 50))
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", [
                                        _c("span", {
                                          staticClass: "mif-bin",
                                          on: {
                                            click: function ($event) {
                                              return _vm.$root.$emit(
                                                "uncheckNode",
                                                res
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                      ]),
                    ]),
              ],
              1
            ),
            _vm.step == 2
              ? _c("div", { staticClass: "form-group text-small" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.source.options.excludeSubFolders,
                        expression: "source.options.excludeSubFolders",
                      },
                    ],
                    attrs: {
                      "data-role": "checkbox",
                      type: "checkbox",
                      "data-caption": _vm.$t(
                        "Do not include any subfolders (only files in the root folder will be copied)"
                      ),
                      "data-validate": "required",
                      "data-prepend": "<span class='mif-folder'></span>",
                    },
                    domProps: {
                      checked: Array.isArray(
                        _vm.source.options.excludeSubFolders
                      )
                        ? _vm._i(_vm.source.options.excludeSubFolders, null) >
                          -1
                        : _vm.source.options.excludeSubFolders,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.source.options.excludeSubFolders,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.source.options,
                                "excludeSubFolders",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.source.options,
                                "excludeSubFolders",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.source.options, "excludeSubFolders", $$c)
                        }
                      },
                    },
                  }),
                  _c("br"),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == 3,
              expression: "step == 3",
            },
          ],
          staticClass: "dialog-content overflow bg-light",
        },
        [
          _c("div", { staticClass: "form-group" }, [
            _c("h6", { staticClass: "text-bold" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("Backup")))]),
            ]),
            _c("hr"),
            _c("label", [_vm._v(_vm._s(_vm.$t("Type")) + ":")]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.backupType,
                    expression: "source.options.backupType",
                  },
                ],
                staticClass: "select",
                attrs: { "data-filter": "false" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.source.options,
                      "backupType",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              _vm._l(
                Object.keys(_vm.$root.selectValues.backupTypes),
                function (key) {
                  return _c("option", { key: key, domProps: { value: key } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(_vm.$root.selectValues.backupTypes[key]))
                    ),
                  ])
                }
              ),
              0
            ),
            _c("label", [_vm._v(_vm._s(_vm.$t("Number of copies")) + ":")]),
            _c("input", {
              directives: [
                { name: "number", rawName: "v-number" },
                {
                  name: "range",
                  rawName: "v-range",
                  value: { min: _vm.minCopies, max: 365 },
                  expression: "{ 'min': minCopies, 'max': 365 }",
                },
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.source.options.nCopies,
                  expression: "source.options.nCopies",
                  modifiers: { number: true },
                },
              ],
              staticClass: "w-10 input",
              attrs: { type: "number" },
              domProps: { value: _vm.source.options.nCopies },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.source.options,
                    "nCopies",
                    _vm._n($event.target.value)
                  )
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
            _c("label", { staticClass: "text-muted" }, [
              _vm._v(
                _vm._s(
                  _vm.$t("Range: {0}-{1}", {
                    0: _vm.minCopies.toString(),
                    1: "365",
                  })
                )
              ),
            ]),
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.source.options.recreateCompletePath,
                  expression: "source.options.recreateCompletePath",
                },
              ],
              attrs: {
                type: "checkbox",
                "data-role": "checkbox",
                "data-caption": _vm.$t("Recreate the full path of the items"),
              },
              domProps: {
                checked: Array.isArray(_vm.source.options.recreateCompletePath)
                  ? _vm._i(_vm.source.options.recreateCompletePath, null) > -1
                  : _vm.source.options.recreateCompletePath,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.source.options.recreateCompletePath,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.source.options,
                          "recreateCompletePath",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.source.options,
                          "recreateCompletePath",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.source.options, "recreateCompletePath", $$c)
                  }
                },
              },
            }),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == 4,
              expression: "step == 4",
            },
          ],
          staticClass: "dialog-content overflow bg-light p-4",
          attrs: { disabled: _vm.source.options.backupType == "U" },
        },
        [
          _c("div", { staticClass: "form-group" }, [
            _c("h6", { staticClass: "text-bold" }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("Backup compression")) +
                    " (" +
                    _vm._s(_vm.$t("Optional")) +
                    ")"
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.source.options.useArchiveCompression,
                  expression: "source.options.useArchiveCompression",
                },
              ],
              attrs: {
                type: "checkbox",
                "data-role": "checkbox",
                "data-caption": _vm.$t("Enable compression"),
              },
              domProps: {
                checked: Array.isArray(_vm.source.options.useArchiveCompression)
                  ? _vm._i(_vm.source.options.useArchiveCompression, null) > -1
                  : _vm.source.options.useArchiveCompression,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.source.options.useArchiveCompression,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.source.options,
                          "useArchiveCompression",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.source.options,
                          "useArchiveCompression",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.source.options, "useArchiveCompression", $$c)
                  }
                },
              },
            }),
            _c("br"),
          ]),
          _c(
            "div",
            {
              staticClass: "form-group d-flex flex-justify-between",
              attrs: { disabled: !_vm.source.options.useArchiveCompression },
            },
            [
              _c("div", [
                _c("label", [_vm._v(_vm._s(_vm.$t("Archive type")))]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.source.options.archiveType,
                        expression: "source.options.archiveType",
                      },
                    ],
                    staticClass: "input",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.source.options,
                          "archiveType",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(
                    _vm.$root.selectValues.archiveTypes,
                    function (value, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: index } },
                        [_vm._v(" " + _vm._s(_vm.$t(value)) + " ")]
                      )
                    }
                  ),
                  0
                ),
              ]),
              _c("div", { staticClass: "d-none" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("Paths in archive")))]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.source.options.pathsInArchive,
                        expression: "source.options.pathsInArchive",
                      },
                    ],
                    staticClass: "input",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.source.options,
                          "pathsInArchive",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(
                    _vm.$root.selectValues.pathsInArchive,
                    function (value, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: index } },
                        [_vm._v(_vm._s(_vm.$t(value)) + " ")]
                      )
                    }
                  ),
                  0
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "form-group",
              attrs: { disabled: !_vm.source.options.useArchiveCompression },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.useArchiveFileCustomName,
                    expression: "source.options.useArchiveFileCustomName",
                  },
                ],
                attrs: { type: "checkbox", "data-role": "checkbox" },
                domProps: {
                  checked: Array.isArray(
                    _vm.source.options.useArchiveFileCustomName
                  )
                    ? _vm._i(
                        _vm.source.options.useArchiveFileCustomName,
                        null
                      ) > -1
                    : _vm.source.options.useArchiveFileCustomName,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.useArchiveFileCustomName,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "useArchiveFileCustomName",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "useArchiveFileCustomName",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(
                        _vm.source.options,
                        "useArchiveFileCustomName",
                        $$c
                      )
                    }
                  },
                },
              }),
              _c("label", [
                _vm._v(_vm._s(_vm.$t("Give the zip file a custom name"))),
              ]),
              _c("br"),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.archiveFileCustomName,
                    expression: "source.options.archiveFileCustomName",
                  },
                  { name: "fsitem", rawName: "v-fsitem" },
                ],
                staticClass: "input",
                attrs: {
                  type: "text",
                  disabled: !_vm.source.options.useArchiveFileCustomName,
                  required: "",
                },
                domProps: { value: _vm.source.options.archiveFileCustomName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.source.options,
                      "archiveFileCustomName",
                      $event.target.value
                    )
                  },
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "form-group",
              attrs: { disabled: !_vm.source.options.useArchiveCompression },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.useArchivePassword,
                    expression: "source.options.useArchivePassword",
                  },
                ],
                attrs: {
                  type: "checkbox",
                  "data-role": "checkbox",
                  "data-caption": _vm.$t("Protect zip files using password"),
                },
                domProps: {
                  checked: Array.isArray(_vm.source.options.useArchivePassword)
                    ? _vm._i(_vm.source.options.useArchivePassword, null) > -1
                    : _vm.source.options.useArchivePassword,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.useArchivePassword,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "useArchivePassword",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "useArchivePassword",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.source.options, "useArchivePassword", $$c)
                    }
                  },
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "form-group",
              attrs: {
                disabled:
                  !_vm.source.options.useArchiveCompression ||
                  !_vm.source.options.useArchivePassword,
              },
            },
            [
              _c("label", [_vm._v(_vm._s(_vm.$t("Password")) + ":")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.source.options.archivePassword,
                    expression: "source.options.archivePassword",
                    modifiers: { trim: true },
                  },
                ],
                attrs: {
                  "data-role": "input",
                  type: "password",
                  placeholder: _vm.$t("Enter Password"),
                  "data-prepend": "<span class='mif-lock'></span>",
                  disabled:
                    !_vm.source.options.useArchiveCompression ||
                    !_vm.source.options.useArchivePassword,
                },
                domProps: { value: _vm.source.options.archivePassword },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.source.options,
                      "archivePassword",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(_vm._s(_vm.$t("Required"))),
              ]),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "dialog-actions" }, [
        _c(
          "button",
          {
            staticClass: "button",
            class: { disabled: _vm.step == 0 },
            on: {
              click: function ($event) {
                return _vm.setStep(_vm.step - 1)
              },
            },
          },
          [
            _c("span", { staticClass: "mif-arrow-left mr-2" }),
            _vm._v(_vm._s(_vm.$t("Prev"))),
          ]
        ),
        !_vm.showSaveButton
          ? _c(
              "button",
              {
                staticClass: "button",
                class: {
                  disabled:
                    _vm.step == 4 || _vm.source.folderSource.paths.length == 0,
                },
                on: {
                  click: function ($event) {
                    return _vm.setStep(_vm.step + 1)
                  },
                },
              },
              [
                _vm._v(_vm._s(_vm.$t("Next"))),
                _c("span", { staticClass: "mif-arrow-right ml-2" }),
              ]
            )
          : _vm._e(),
        _vm.showSaveButton
          ? _c(
              "button",
              {
                staticClass: "button primary ml-2",
                on: { click: _vm.saveSource },
              },
              [_vm._v(_vm._s(_vm.$t("Save")))]
            )
          : _vm._e(),
        _c(
          "button",
          {
            staticClass: "button",
            on: {
              click: function ($event) {
                return _vm.$root.$emit("CLOSESOURCEDIALOG")
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("Close")))]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [_c("th")])
  },
]
render._withStripped = true

export { render, staticRenderFns }