var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog light w-100-sm w-100-md w-75-lg w-50-xl h-75",
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.$root.$emit("CLOSESOURCEDIALOG")
        },
      },
    },
    [
      _c("DialogTitle", {
        attrs: {
          title: _vm.$t("Configure Backup Source"),
          defaultTitle: _vm.$t("Configure Backup Source"),
          subTitle: _vm.source.type_name,
        },
        on: {
          closeDialog: function ($event) {
            return _vm.$root.$emit("CLOSESOURCEDIALOG")
          },
        },
      }),
      _vm.step == 0
        ? _c(
            "div",
            { staticClass: "dialog-content" },
            [
              _c("SARepoSelect", {
                attrs: {
                  type: _vm.source.type,
                  idSA: _vm.source.id_service_account,
                  idRepo: -2,
                },
                on: { sendSATestResult: _vm.sendSATestResult },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.step == 1
        ? _c(
            "div",
            { staticClass: "dialog-content overflow" },
            [
              _vm.source.id_service_account != -2 && _vm.source.dbSource == null
                ? _c("NoDataAvailable", {
                    attrs: { message: _vm.$t("No Database available") },
                  })
                : _vm.source.dbSource != null
                ? _c("div", { staticClass: "form-group mt-5" }, [
                    _c("h6", [
                      _vm._v(
                        _vm._s(_vm.$t("Backup the following databases")) + ":"
                      ),
                    ]),
                    _vm.source.dbSource.databases.length != 0
                      ? _c("div", { attrs: { "data-role": "treeview" } }, [
                          _c("ul", { staticClass: "mt-4" }, [
                            _c("li", [
                              _c("span", {
                                staticClass: "mif-server fs-5",
                                staticStyle: {
                                  "margin-right": "8px",
                                  "font-size": "16px",
                                },
                              }),
                              _c("span", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.instanceSelected,
                                      expression: "instanceSelected",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    "data-role": "checkbox",
                                    "data-caption":
                                      _vm.source.dbSource.serverName +
                                      " (SQL Server " +
                                      _vm.source.dbSource.serverVersion +
                                      " - " +
                                      _vm.$session.getServiceAccountWithID(
                                        _vm.source.id_service_account
                                      ).username +
                                      ")",
                                  },
                                  domProps: {
                                    value: true,
                                    checked: Array.isArray(_vm.instanceSelected)
                                      ? _vm._i(_vm.instanceSelected, true) > -1
                                      : _vm.instanceSelected,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.instanceSelected,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = true,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.instanceSelected = $$a.concat([
                                              $$v,
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.instanceSelected = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.instanceSelected = $$c
                                      }
                                    },
                                  },
                                }),
                              ]),
                              _c(
                                "ul",
                                [
                                  _c(
                                    "li",
                                    {
                                      attrs: {
                                        "data-collapsed": "true",
                                        "data-caption":
                                          _vm.$t("System databases"),
                                      },
                                    },
                                    [
                                      _c(
                                        "ul",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.showSystemDatabases,
                                              expression: "showSystemDatabases",
                                            },
                                          ],
                                        },
                                        _vm._l(
                                          _vm.sortedSystemDatabases,
                                          function (db) {
                                            return _c(
                                              "li",
                                              {
                                                key: db.name,
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center",
                                                },
                                                attrs: {
                                                  "data-collapsed": "true",
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "mif-database",
                                                  staticStyle: {
                                                    "margin-right": "8px",
                                                  },
                                                }),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: db.selected,
                                                      expression: "db.selected",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-role": "checkbox",
                                                    "data-caption": db.name,
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      db.selected
                                                    )
                                                      ? _vm._i(
                                                          db.selected,
                                                          null
                                                        ) > -1
                                                      : db.selected,
                                                  },
                                                  on: {
                                                    change: [
                                                      function ($event) {
                                                        var $$a = db.selected,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              _vm.$set(
                                                                db,
                                                                "selected",
                                                                $$a.concat([
                                                                  $$v,
                                                                ])
                                                              )
                                                          } else {
                                                            $$i > -1 &&
                                                              _vm.$set(
                                                                db,
                                                                "selected",
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  )
                                                              )
                                                          }
                                                        } else {
                                                          _vm.$set(
                                                            db,
                                                            "selected",
                                                            $$c
                                                          )
                                                        }
                                                      },
                                                      _vm.updateSystemDatabasesSelection,
                                                    ],
                                                  },
                                                }),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                  _vm._l(
                                    _vm.sortedUserDatabases,
                                    function (db) {
                                      return _c(
                                        "li",
                                        {
                                          key: db.name,
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                          attrs: { "data-collapsed": "true" },
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "mif-database",
                                            staticStyle: {
                                              "margin-right": "8px",
                                            },
                                          }),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: db.selected,
                                                expression: "db.selected",
                                              },
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              "data-role": "checkbox",
                                              "data-caption": db.name,
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                db.selected
                                              )
                                                ? _vm._i(db.selected, null) > -1
                                                : db.selected,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = db.selected,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        db,
                                                        "selected",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        db,
                                                        "selected",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(db, "selected", $$c)
                                                }
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.step == 2
        ? _c("div", { staticClass: "dialog-content" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("h6", [_vm._v(_vm._s(_vm.$t("Options")) + ":")]),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.verifyBackup,
                    expression: "source.options.verifyBackup",
                  },
                ],
                attrs: {
                  type: "checkbox",
                  "data-role": "checkbox",
                  "data-caption": _vm.$t("Verify Backup"),
                },
                domProps: {
                  checked: Array.isArray(_vm.source.options.verifyBackup)
                    ? _vm._i(_vm.source.options.verifyBackup, null) > -1
                    : _vm.source.options.verifyBackup,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.verifyBackup,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "verifyBackup",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "verifyBackup",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.source.options, "verifyBackup", $$c)
                    }
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.useNativeComp,
                    expression: "source.options.useNativeComp",
                  },
                ],
                attrs: {
                  type: "checkbox",
                  "data-role": "checkbox",
                  "data-caption": _vm.$t(
                    "Enable Native Compression if supported"
                  ),
                },
                domProps: {
                  checked: Array.isArray(_vm.source.options.useNativeComp)
                    ? _vm._i(_vm.source.options.useNativeComp, null) > -1
                    : _vm.source.options.useNativeComp,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.useNativeComp,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "useNativeComp",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "useNativeComp",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.source.options, "useNativeComp", $$c)
                    }
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.useCopyOnly,
                    expression: "source.options.useCopyOnly",
                  },
                ],
                attrs: {
                  type: "checkbox",
                  "data-role": "checkbox",
                  "data-caption": _vm.$t("Use copy-only mode"),
                },
                domProps: {
                  checked: Array.isArray(_vm.source.options.useCopyOnly)
                    ? _vm._i(_vm.source.options.useCopyOnly, null) > -1
                    : _vm.source.options.useCopyOnly,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.useCopyOnly,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "useCopyOnly",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "useCopyOnly",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.source.options, "useCopyOnly", $$c)
                    }
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.enableAppend,
                    expression: "source.options.enableAppend",
                  },
                ],
                attrs: {
                  type: "checkbox",
                  "data-role": "checkbox",
                  "data-caption": _vm.$t("Append text to the backup file name"),
                },
                domProps: {
                  checked: Array.isArray(_vm.source.options.enableAppend)
                    ? _vm._i(_vm.source.options.enableAppend, null) > -1
                    : _vm.source.options.enableAppend,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.enableAppend,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "enableAppend",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "enableAppend",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.source.options, "enableAppend", $$c)
                    }
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.lazy.trim",
                    value: _vm.source.options.appendTextBkp,
                    expression: "source.options.appendTextBkp",
                    modifiers: { lazy: true, trim: true },
                  },
                  { name: "fsitem", rawName: "v-fsitem" },
                ],
                attrs: {
                  disabled: !_vm.source.options.enableAppend,
                  type: "text",
                  "data-role": "input",
                },
                domProps: { value: _vm.source.options.appendTextBkp },
                on: {
                  change: function ($event) {
                    _vm.$set(
                      _vm.source.options,
                      "appendTextBkp",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _c("label", { staticClass: "text-small text-muted" }, [
                _vm._v(_vm._s(_vm.$t("If blank, default name will be used"))),
              ]),
            ]),
            _c("hr"),
            _c("div", { staticClass: "form-group" }, [
              _c("h6", [_vm._v(_vm._s(_vm.$t("Additional Options")) + ":")]),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.backupLog,
                    expression: "source.options.backupLog",
                  },
                ],
                attrs: {
                  type: "checkbox",
                  "data-role": "checkbox",
                  "data-caption": _vm.$t("Backup the transaction log"),
                },
                domProps: {
                  checked: Array.isArray(_vm.source.options.backupLog)
                    ? _vm._i(_vm.source.options.backupLog, null) > -1
                    : _vm.source.options.backupLog,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.backupLog,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "backupLog",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "backupLog",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.source.options, "backupLog", $$c)
                    }
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.shrinkLog,
                    expression: "source.options.shrinkLog",
                  },
                ],
                attrs: {
                  disabled: !_vm.source.options.backupLog,
                  type: "checkbox",
                  "data-role": "checkbox",
                  "data-caption": _vm.$t(
                    "Shrink the transaction log after the backup"
                  ),
                },
                domProps: {
                  checked: Array.isArray(_vm.source.options.shrinkLog)
                    ? _vm._i(_vm.source.options.shrinkLog, null) > -1
                    : _vm.source.options.shrinkLog,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.shrinkLog,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "shrinkLog",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "shrinkLog",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.source.options, "shrinkLog", $$c)
                    }
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.alterDbRecModel,
                    expression: "source.options.alterDbRecModel",
                  },
                ],
                attrs: {
                  disabled: !_vm.source.options.backupLog,
                  type: "checkbox",
                  "data-role": "checkbox",
                  "data-caption": _vm.$t(
                    "Alter the database recovery mode to SIMPLE if necessary"
                  ),
                },
                domProps: {
                  checked: Array.isArray(_vm.source.options.alterDbRecModel)
                    ? _vm._i(_vm.source.options.alterDbRecModel, null) > -1
                    : _vm.source.options.alterDbRecModel,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.alterDbRecModel,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "alterDbRecModel",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "alterDbRecModel",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.source.options, "alterDbRecModel", $$c)
                    }
                  },
                },
              }),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.source.enableCompression &&
                      _vm.source.options.useArchivePassword,
                    expression:
                      "source.enableCompression && source.options.useArchivePassword",
                  },
                ],
              },
              [
                _c("div", { staticClass: "form-group" }, [
                  _c("p", [_vm._v(_vm._s(_vm.$t("Archive Password")))]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.source.options.archivePassword,
                        expression: "source.options.archivePassword",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "metro-input",
                    attrs: {
                      "data-role": "input",
                      type: "password",
                      placeholder: _vm.$t("Enter Password"),
                      "data-prepend": "<span class='mif-lock'></span>",
                    },
                    domProps: { value: _vm.source.options.archivePassword },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.source.options,
                          "archivePassword",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.$t("Required"))),
                  ]),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "dialog-actions" }, [
        _c("div", { staticClass: "ml-auto" }, [
          _c(
            "button",
            {
              staticClass: "button",
              class: { disabled: _vm.step == 0 },
              on: { click: _vm.back },
            },
            [
              _c("span", { staticClass: "mif-arrow-left" }),
              _vm._v(" " + _vm._s(_vm.$t("Prev"))),
            ]
          ),
          _vm.step < 2
            ? _c(
                "button",
                {
                  staticClass: "button",
                  class: {
                    disabled:
                      _vm.step == 2 ||
                      _vm.source.dbSource == null ||
                      !_vm.isAnySelected,
                  },
                  on: { click: _vm.next },
                },
                [
                  _vm._v(_vm._s(_vm.$t("Next"))),
                  _c("span", { staticClass: "mif-arrow-right" }),
                ]
              )
            : _vm._e(),
          _vm.step == 2
            ? _c(
                "button",
                {
                  staticClass: "button primary",
                  on: { click: _vm.saveSource },
                },
                [_vm._v(_vm._s(_vm.$t("Save")))]
              )
            : _vm._e(),
          _c(
            "button",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  return _vm.$root.$emit("CLOSESOURCEDIALOG")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("Close")))]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }