import api from "../../../router/api";
import { isValid, createErrorObject, cookieManager } from "../../utilitiesmodule";
import { FileFolderEntityType, PlatformsTypes } from "../../objects";
import { ClientTreeNode } from "../../../models/TreeNode";
import { ERROR } from "../../messages";
//DOCUMENTAZIONE PARAMETRI QUERY : https://developers.google.com/drive/api/guides/search-files?hl=it
//L'applicazione deve essere pubblicata e deve avere i permessi
//https://console.cloud.google.com/welcome?hl=it&project=cogent-scion-373609
export class GoogleDriveAuthentication {
    constructor(clientID, clientSecret) {
        this.clientID=clientID;
        this.clientSecret=clientSecret;
    }

    async getAuthCode() {

        var params=[
            "scope=https%3A//www.googleapis.com/auth/drive", //drive.metadata.readonly",
            "access_type=offline",
            "include_granted_scopes=true",
            "response_type=code",
            "state=state_parameter_passthrough_value",
            "redirect_uri="+encodeURIComponent(window.location.origin),
            "client_id="+this.clientID
        ];

        cookieManager.deleteCookie("authCode");
        var url="https://accounts.google.com/o/oauth2/v2/auth?"+params.join("&");
        window.open(url);

        var authCode=await cookieManager.waitAndGetCookie("authCode");

        return authCode;

    }

    async getTokensWithAuthorizationCode(authCode) {


        if (!isValid(authCode)) {
            authCode=await this.getAuthCode();
        }

        var body=JSON.stringify({
            code: authCode,
            redirect_uri: window.location.origin, ///*"http://localhost:8080/"*/
            scope: "openid", //"https://www.googleapis.com/auth/drive",
            grant_type: "authorization_code",
            client_id: this.clientID,
            client_secret: this.clientSecret
        });

        var [ok, response]=await api.sendRequestToServer("POST", "https://oauth2.googleapis.com/token", {}, body);

        console.log(response);

        if (!ok) {
            return null;
        }

        return {
            accessToken: response.access_token,
            refreshToken: response.refresh_token
        };

    }

    async getAccessTokenWithRefreshToken(refreshToken) {
        var body=JSON.stringify({
            refresh_token: refreshToken,
            //redirect_uri: "http://localhost:8080",
            //scope: "https://www.googleapis.com/auth/drive",
            grant_type: "refresh_token",
            client_id: this.clientID,
            client_secret: this.clientSecret
        });
        var [ok, response]=await api.sendRequestToServer("POST", "https://www.googleapis.com/oauth2/v4/token", {}, body);

        console.log(response);

        if (!ok) {
            return null;
        }

        return response.access_token;
    }
}

export class GoogleDriveClient {
    constructor(serviceAccount) {
        this.serviceAccount=serviceAccount
        this.accessToken=serviceAccount.token;
        this.nodes=[];
    }

    async getRootNodes() {
        var children=this.nodes.length==0? []:
            this.nodes.filter(n => n.isChildOf(null));

        if (children.length!=0)
            return children;
        var nextContinuationToken=null;
        var response;
        var nodes=[];
        //https://developers.google.com/drive/api/v3/reference/files/list
        var params=_getDefaultParams();
        params.push("q=%28%27root%27%20in%20parents%29"); //Aggiungo una query che mi permette di cercare i children della root
        do {
            response=await createRequest(params, this.accessToken, "", nextContinuationToken);
            if (response==null)
                return null;
            nextContinuationToken=response.nextContinuationToken;
            if (response.status==200)
                nodes=nodes.concat(response.nodes);
        } while (nextContinuationToken!=null);
        this.nodes=nodes;
        return nodes;
    }

    async getNodesFrom(resource) {
        var children=this.nodes.length==0? []:
            this.nodes.filter(n => n.isChildOf(resource, "/"));

        if (children.length!=0)
            return children;

        if (!isValid(resource.resID))
            resource.resID=await _getIDFromPath(resource.path, this.accessToken);
        var idPath=resource.resID;
        var nextContinuationToken=null;
        var response;
        var nodes=[];

        var params=_getDefaultParams();
        do {
            //Aggiungo una query che mi permette di cercare i children della risorsa con in idPath
            params.push(encodeURI("q=('"+idPath+"' in parents)") /*.replace("(", "%28").replace(")", "%29").replace("'", "%27").replace(" ", "%20").replace("/", "%2F").replace(".", "%2C")*/);

            response=await createRequest(params, this.accessToken, resource.path, nextContinuationToken);
            if (response==null)
                return null;

            nextContinuationToken=response.nextContinuationToken;
            if (response.status==200)
                nodes=nodes.concat(response.nodes);
        } while (nextContinuationToken!=null);

        this.nodes=[...nodes];
        return nodes;
    }

    async createFolder(path, parentID) {
        if(!isValid(parentID)){
            parentID = "root";
        }
        var headers = {
            "Authorization": `Bearer ${this.accessToken}`,
            "Content-Type": "application/json",
        };

        var body = {
            name: path.lastPartOfPath(),
            parents: [parentID], //{ "id": parentID, type: "application/vnd.google-apps.folder" },
            mimeType: "application/vnd.google-apps.folder",
        };

        var [ok, response] = await api.sendRequestToServer("POST", 'https://www.googleapis.com/drive/v3/files', headers, body);
        
        if(!ok){
            return null
        }

        var id = response.id;
        var newNode = new ClientTreeNode(null, id, path, FileFolderEntityType.Cloud_Folder);
        return newNode;
    }
}

async function createRequest(params, accessToken, parentPath, nextPageToken) {

    if (nextPageToken!=null)
        params.push("pageToken="+nextPageToken);

    var url="https://www.googleapis.com/drive/v3/files?"+params.join("&");
    var headers={
        "Authorization": "Bearer "+accessToken
    }
    var [ok, response]=await api.sendRequestToServer("GET", url, headers, null);

    if (!ok) {
        //TODO DA CONTROLLARE BENE QUESTA PARTE+++++++++++++++++++++++++++++++++++++++++++++    
        //L'utente deve abilitare Google Drive API
        if (
            isValid(response.message) &&
            response.message.startsWith("Access Not Configured. Drive API has not been used in project")
        ) {
            return {
                status: 400,
                url: response.extendedHelp
            };
        }

        return null;
        //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    }

    //https://console.developers.google.com/apis/api/drive.googleapis.com/overview?project=24613014036
    var resources=response.files.map(r => new ClientTreeNode(
        null,
        r.id,
        parentPath==""? r.name:parentPath+"/"+r.name,
        r.mimeType!="application/vnd.google-apps.folder"?
            FileFolderEntityType.Cloud_File:
            FileFolderEntityType.Cloud_Folder
    ));

    return { status: 200, nodes: resources, nextContinuationToken: isValid(response.nextPageToken)? response.nextPageToken:null };
}

async function _getIDFromPath(path, accessToken) {
    var folders=path.getAllPaths(PlatformsTypes.Cloud_GoogleDrive);
    var foldersNames=path.GetFoldersNames(PlatformsTypes.Cloud_GoogleDrive);
    var parentID="root";
    var children=[];
    var params;

    for (var i=0; i<foldersNames.length; i++) {
        params=_getDefaultParams();
        //Aggiungo una query che mi permette di cercare all'interno di parentID la risorsa con il nome foldersNames[i]
        params.push(encodeURI("q=('"+parentID+"' in parents and name = '"+foldersNames[i]+"')") /*.replace("(", "%28").replace(")", "%29").replace("'", "%27").replace(" ", "%20").replace("/", "%2F").replace(".", "%2C")*/);

        children=await createRequest(params, accessToken, parentID=="root"? "":folders[i-1], null);
        if (children==null)
            return null;

        parentID=children.nodes.find(r => r.path==folders[i]).resID;
    }

    return parentID;

}

//https://howto.caspio.com/tech-tips-and-articles/using-special-characters-in-query-string/
function _getDefaultParams() {
    return [
        "corpora=user",
        "fields=nextPageToken%2cfiles%28id%2cname%2cmimeType%29",
        "includeItemsFromAllDrives=false",
        "supportsAllDrives=true",
        "includeDeleted=false",
    ];
}