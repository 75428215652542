var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100 overflow" },
    [
      _c("Navigation", { attrs: { pageName: "users" } }),
      _vm.users == null
        ? _c("div", { staticClass: "row m-5 pt-5" }, [_vm._m(0)])
        : _vm.users.length == 0
        ? _c("NoDataAvailable", {
            attrs: { message: _vm.$t("No users available") },
          })
        : _c("div", { staticClass: "p-5" }, [
            _c(
              "table",
              {
                staticClass: "table border striped bg-white m-0 compact",
                attrs: {
                  "data-check": false,
                  "data-check-name": "chkBox",
                  "data-table-search-title": _vm.$t("Search Users"),
                  "data-cls-search": "w-100-sm w-25-xl w-25-lg w-50-md",
                  "data-show-activity": "true",
                  "data-cell-wrapper": "false",
                  "data-role": "table",
                  "data-rows": "20",
                  "data-show-rows-steps": "false",
                  "data-show-pagination":
                    _vm.users.length > 20 ? "true" : "false",
                  "data-horizontal-scroll": "true",
                },
              },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "sortable-column" }, [
                      _vm._v(_vm._s(_vm.$t("Username"))),
                    ]),
                    _c("th", { staticClass: "sortable-column" }, [
                      _vm._v(_vm._s(_vm.$t("Role"))),
                    ]),
                    _c("th", { staticClass: "sortable-column" }, [
                      _vm._v(_vm._s(_vm.$t("Firstname"))),
                    ]),
                    _c("th", { staticClass: "sortable-column" }, [
                      _vm._v(_vm._s(_vm.$t("Lastname"))),
                    ]),
                    _c(
                      "th",
                      {
                        staticClass: "text-center",
                        attrs: {
                          "data-cls": "text-center",
                          "data-cls-column": "text-center",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Groups")) + " ")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-center",
                        attrs: { "data-cls-column": "text-center w-10" },
                      },
                      [_vm._v(_vm._s(_vm.$t("2FA")))]
                    ),
                    _c("th"),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.users, function (user) {
                    return _c(
                      "tr",
                      { key: user.id, staticClass: "mx-8 p-4 rounded m-6" },
                      [
                        _c("td", [
                          _c(
                            "a",
                            {
                              staticClass: "button text-bold text-left m-0",
                              class: {
                                "flat-button":
                                  _vm.$root.checkAuth().id_user != user.id,
                              },
                              attrs: { href: "#" + user.id },
                            },
                            [
                              _c("span", [_vm._v(_vm._s(user.username))]),
                              _vm.$root.checkAuth().id_user == user.id
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "badge bg-primary fg-white text-normal",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Logged")))]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _c("td", {}, [
                          _c("label", { staticClass: "text-left m-0" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "badge inline bg-cobalt fg-white p-1 mr-1",
                              },
                              [_vm._v(_vm._s(user.role.toUpperCase()))]
                            ),
                          ]),
                        ]),
                        _c("td", [_vm._v(" " + _vm._s(user.firstname) + " ")]),
                        _c("td", [_vm._v(" " + _vm._s(user.lastname) + " ")]),
                        _c(
                          "td",
                          { staticClass: "text-center text-bold" },
                          _vm._l(user.groups, function (group) {
                            return _c(
                              "router-link",
                              {
                                key: group.id,
                                staticClass: "button fg-dark mr-2 small",
                                attrs: { to: "groups#" + group.id },
                              },
                              [_vm._v(_vm._s(group.name))]
                            )
                          }),
                          1
                        ),
                        _c("td", { staticClass: "text-center" }, [
                          _c("span", {
                            staticClass: "text-center mif-2x",
                            class: user.mfa_enabled ? "mif-security" : "",
                          }),
                        ]),
                        _c("td", [
                          _c(
                            "ul",
                            { staticClass: "listview flex-justify-end d-flex" },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: "#" + user.id } },
                                [
                                  _c(
                                    "li",
                                    { staticClass: "button flat-button" },
                                    [_c("span", { staticClass: "mif-pencil" })]
                                  ),
                                ]
                              ),
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.$root.checkAuth().id_user ==
                                        user.id || user.level == "2",
                                    to:
                                      _vm.$root.checkAuth().id_user ==
                                        user.id || user.level == "2"
                                        ? "#"
                                        : "#" + user.id + "/delete",
                                  },
                                },
                                [
                                  _c(
                                    "li",
                                    { staticClass: "button flat-button" },
                                    [_c("span", { staticClass: "mif-bin" })]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "h-100 w-100 z-2" }, [
      _c("span", {
        staticClass: "ani-spin fg-primary mif-5x mif-spinner2 pos-center",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }