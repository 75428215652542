<template>
    <div v-on:keyup.esc="$root.$emit('CLOSESOURCEDIALOG')"
        class="dialog light w-100-sm w-100-md w-75-lg  w-50-xl h-75 draggable-item">

        <DialogTitle :title="$t('Configure Backup Source')" :defaultTitle="$t('Configure Backup Source')" :subTitle="source.type_name"
            @closeDialog="$root.$emit('CLOSESOURCEDIALOG')" />

        <!--SCELTA PERCORSI-->
        <div v-if="step == STEP_SA_AND_SOURCE" class="dialog-content bg-light">
            <!------------------------------SCELTA DEL SERVICE ACCOUNT--------------------------------------------------------------->

            <!--Questa pagina deve implementare il metodo "sendSATestResult"-->
            <SARepoSelect :type=source.type :idSA=source.id_service_account :idRepo="-2"
                @sendSATestResult="sendSATestResult" />

            <SelectedResourcesLayout :buttonText="$t('Select files and folders')" :singleItem="false" :type="source.type"
                :isDisabled="!testResult.ok" :list="source.networkSource.paths" :heightPercentage="30" :canCancel="true"
                @clickFunction="toggleFileSystemSidebar" />
            <div class="d-flex flex-column form-group h-20 mt-auto">
                <!--Opzione abilitata prossimamente-->
                <!--input type="checkbox" v-model="source.options.copySecurityPermissions" data-role="checkbox"
                    :data-caption="$t('Copy security permissions')" :disabled="!testResult.ok" /-->
                <input type="checkbox" v-model="source.options.recreateCompletePath" data-role="checkbox"
                    :data-caption="$t('Recreate the full path of the items')" :disabled="!testResult.ok" />
            </div>
        </div>


        <!--FILTRI-->
        <div class="dialog-content bg-light" v-show="step == STEP_FILTERS">
            <div class="form-group">
                <label class="text-bold">
                    <span>{{ $t('Filters') }} ({{ $t('Optional') }})</span>
                </label>
            </div>

            <!--FILTRI - ESTENSIONI-->
            <div class="form-group">
                <h6 class="bg-light px-2 py-2 text-bold">
                    <span>{{ $t('Filter by extensions') }} ({{ $t('Optional') }})</span>
                </h6>

                <div class="d-flex flex-column">
				    <input name='includeExcludeExtensions' type='radio' data-role='radio' :data-caption="$t('Exclude specified extensions')" v-model="source.options.extensionsFilterAction" :value="0">
				    <input name='includeExcludeExtensions' type='radio' data-role='radio' :data-caption="$t('Include only specified extensions')" v-model="source.options.extensionsFilterAction" :value="1">
			    </div>

                <div class="cell-12">
                    <label class="text-bold">{{ $t("Extensions :") }}</label>
                       <select v-model="specifiedExtensionsArray" multiple data-role="select" class="input">
                        <optgroup v-for="(extensionsList, extensionsCategory) in fileExtensions" :label="$t(extensionsCategory)" :key="extensionsCategory">
                                <option v-for="extension in extensionsList" :value="extension">{{ extension }}</option>
                            </optgroup>
                       </select>
                </div>
            </div>

            <!--FILTRI - DIMENSIONE FILE-->
            <div class="form-group">
                <h6 class="bg-light px-2 py-2 text-bold">
                    <span>{{ $t('Filter by file size') }} ({{ $t('Optional') }})</span>
                </h6>

                <input type="checkbox" v-model="source.options.useFileSizeFilter"
                    :data-caption="$t('Use file size filter')" data-role="checkbox" />

                <div class="w-100 d-flex flex-align-center">
                    <select v-model="source.options.fileSizeFilterAction" :disabled="!source.options.useFileSizeFilter"
                        class="input input-small mr-2 h-100">
                        <option v-for="(value, index) in $root.selectValues.fileSizeFilterAction" :value="index"
                            v-bind:key="index">{{
                                $t(value)
                            }}</option>

                    </select>

                    <select v-model="source.options.fileSizeFilterCompare" :disabled="!source.options.useFileSizeFilter"
                        class="input input-small mr-2 h-100 w-75">
                        <option v-for="(value, index) in $root.selectValues.fileSizeFilterCompare" :value="index"
                            v-bind:key="index">{{
                                $t(value)
                            }}</option>
                    </select>

                    <input type="number" v-number v-range="{ min: 1, max: 999999 }" v-model.number="source.options.fileSize" :disabled="!source.options.useFileSizeFilter"
                        class="input input-small mr-2" min="1" style="width: 100px;" />
                    <span> MB</span>
                </div>
            </div>
        </div>

        <!--ESCLUSIONI-->
        <div class="dialog-content bg-light" v-if="step == STEP_EXCLUSIONS">
            <div class="form-group">
                <h6 class="text-bold">
                    <span class="mif-blocked icon mr-1" />
                    <span>{{ $t('Exclusions') }} ({{ $t('Optional') }})</span>
                </h6>
            </div>

            <div class="form-group">{{ $t("Click on an item to select subfolders or files to exclude") }}</div>
            <!--VISUALIZZAZIONE DEGLI ELEMENTI SELEZIONATI-->
            <div class="form-group overflow h-50">
                <table class="border striped table text-small">
                    <thead class="bg-light">
                        <tr>
                            <th>{{ $t('Path') }}</th>
                            <th ></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="res in source.networkSource.paths" @click="openFileSystemSidebarFrom(res)"
                            v-bind:key="res.id">
                            <td class="d-flex">
                                <span :class="res.type.getMetroIcon(res.path)" class="px-2" />
                                <span v-if="source.options.recreateCompletePath" class="c-pointer">
                                    {{ res.path }}
                                </span>
                                <span v-else class="flex-self-center">{{ (res.path.indexOf('/') != -1 ?
                                    res.path.split('/') : res.path.split('\\')).at(-1) }}</span>
                            </td>
                            <td class="text-right"><span class='mif-bin' @click="$root.$emit('uncheckNode', res)" /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            <!--VISUALIZZAZIONE DEGLI ELEMENTI-->
            <div v-if="source.options.excludedPaths.length != 0" >
                <div class="form-group mt-7">
                    <h6>{{ $t("Excluded paths:") }}</h6>
                    <div class="overflow">
                        <table class="bd-black border border-radius-10 border-size-1 d-table-lg striped table text-small">
                            <tbody>
                                <tr v-for="res in source.options.excludedPaths" :title="res.path" v-bind:key="res.id">
                                    <td class="d-flex">
                                        <span :class="res.type.getMetroIcon(res.path)" class="px-2" />
                                        <span class="c-pointer">{{ cutText(res.path, 50) }}</span>
                                    </td>
                                    <td><span class='mif-bin' @click="$root.$emit('uncheckNode', res)" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="form-group mt-4" v-if="step == 2">
                <input type="checkbox" v-model="source.options.excludeSubFolders" />
                <label> {{ $t("Do not include any subfolders (only files in the root folder will be copied)") }}</label>
            </div>
        </div>

        <!--BACKUP-->
        <div class="dialog-content bg-light" v-show="step == STEP_BACKUPTYPE">
            <div class="form-group">
                <label class="text-bold">
                    <span class="mif-file-upload icon mr-1" />
                    <span>{{ $t('Backup') }}</span>
                </label>
            </div>
            <div class="form-group">
                <label>{{ $t("Type") }}:</label>
                <select v-model="source.options.backupType" data-role="select" data-filter="false">
                    <option v-for="key in Object.keys($root.selectValues.backupTypes)" :value="key" v-bind:key="key">{{
                        $t($root.selectValues.backupTypes[key]) }}</option>
                </select>
            </div>

            <div class="form-group">
                <label>{{ $t("Number of copies") }}:</label>
                <input type="number" v-number v-range="{ min: 1, max: 365 }" class="input" v-model.number="source.options.nCopies"  />
            </div>
        </div>

        <!--COMPRESSIONE ZIP-->
        <div class="dialog-content bg-light" v-if="step == STEP_COMPRESSION" :disabled="source.options.backupType == 'U'">
            <div class="form-group">
                <h6 class="text-bold">
                    <span>{{ $t('Backup compression') }} ({{ $t('Optional') }})</span>
                </h6>
            </div>
            <div class="form-group">
                <input type="checkbox" v-model="source.options.useArchiveCompression" data-role="checkbox"
                    :data-caption="$t('Enable compression')" />
                <br />
            </div>

            <div class="form-group d-flex flex-justify-between" :disabled="!source.options.useArchiveCompression">
                <div>
                    <label>{{ $t("Archive type") }}</label>
                    <select class="input" v-model="source.options.archiveType">
                        <option v-for="(value, index) in $root.selectValues.archiveTypes" :value="index"
                            v-bind:key="index">
                            {{ $t(value) }}
                        </option>
                    </select>
                </div>
                <!--
                09/09/2024 : Al momento si considera solo il percorso completo (source.options.pathsInArchive = 1) all'interno del file zip.
                Se si vorranno implementare altre modalità, basta attivare questo div
                -->
                <div class="d-none">
                    <label>{{ $t("Paths in archive") }}</label>
                    <select class="input" v-model="source.options.pathsInArchive">
                        <option v-for="(value, index) in $root.selectValues.pathsInArchive" :value="index"
                            v-bind:key="index">{{ $t(value) }}
                        </option>
                    </select>
                </div>
            </div>


            <div class="form-group" :disabled="!source.options.useArchiveCompression">
                <input type="checkbox" v-model="source.options.useArchiveFileCustomName" data-role="checkbox" />
                <label>{{ $t("Give the zip file a custom name") }}</label>
                <br />
                <input type="text" class="input" v-model="source.options.archiveFileCustomName"
                   v-fsitem :disabled="!source.options.useArchiveFileCustomName" required />
            </div>

            <div class="form-group" :disabled="!source.options.useArchiveCompression">
                <input type="checkbox" v-model="source.options.useArchivePassword" data-role="checkbox"
                    :data-caption="$t('Protect zip files using password')" />
            </div>
            <div :disabled="!source.options.useArchiveCompression || !source.options.useArchivePassword"
                class="form-group">
                <label>{{ $t("Password") }}:</label>
                <input data-role="input" type="password" v-model.trim="source.options.archivePassword"
                    :placeholder="$t('Enter Password')" data-prepend="<span class='mif-lock'></span>"
                    :disabled="!source.options.useArchiveCompression || !source.options.useArchivePassword" />
                <small class="text-muted">{{ $t("Required") }}</small>
                <!--Al momento c'è solo un tipo di protezione-->
                <!--
                <br />
                <label>{{ $t("Protection type") }}:</label>
                <br />
                <select class="input" v-model="source.options.protectionType"
                    :disabled="!source.options.useArchiveCompression || !source.options.useArchivePassword">
                    <option v-for="(value, index) in $root.selectValues.protectionTypes" :value="index"
                        v-bind:key="index">{{ $t(value) }}
                    </option>
                </select>-->

            </div>
        </div>
        <div class="dialog-actions">
            <button v-on:click="setStep(step - 1)" class="button primary" :disabled="step == 0"><span class="mif-arrow-left mr-2"> </span> {{ $t('Prev') }}</button>
            <button v-if="!showSaveButton" v-on:click="setStep(step + 1)" class="button primary" :disabled="step == 4 || source.networkSource.paths.length == 0" >{{ $t('Next') }} <span class="mif-arrow-right ml-2"> </span></button>
            <button v-if="showSaveButton" v-on:click="saveSource" class="button primary">{{ $t('Save') }} </button>
            <button class="button" @click="$root.$emit('CLOSESOURCEDIALOG')">{{ $t("Close") }}</button>
        </div>
    </div>
</template>
<script>
import DialogTitle from "../../utils/DialogTitle";
import SARepoSelect from "../../utils/SARepoSelect";
import NoDataAvailable from "../../utils/NoDataAvailable.vue";
import SelectedResourcesLayout from "../../utils/SelectedResourcesLayout.vue"
import { cutText, isValid} from "../../../utils/utilitiesmodule";
import { fileExtensions } from "../../../utils/objects";
import { draggableMixin } from '../../../mixins/draggable-mixin'

export default {
    mixins: [draggableMixin],
    name: "NetworkSource",
    props: {
        source: Object,
    },
    components: {
        "DialogTitle": DialogTitle,
        "NoDataAvailable": NoDataAvailable,
        SARepoSelect,
        SelectedResourcesLayout
    },
    data() {
        return {
            step: 0,
            testResult: {
                ok: false,
                okNumber: 0,
                serviceAccount: { id: -2, type: this.$PlatformsTypes.Network },
                client: null,// il client della piattaforma che effettuerà le richieste dei list
                list: null,
            },
            STEP_SA_AND_SOURCE: 0,
            STEP_FILTERS: 1,
            STEP_EXCLUSIONS: 2,
            STEP_BACKUPTYPE: 3,
            STEP_COMPRESSION: 4,
            specifiedExtensionsArray : [],

            fileExtensions:fileExtensions
        }
    },
    computed: {
        showSaveButton() {
            return (this.step == 3 && this.source.options.backupType == 'U') || this.step == 4
        }
    },

    created: function () {
        this.$root.$emit('closeFileSystemSidebar');
        if(isValid(this.source.id)){
            this.specifiedExtensionsArray = this.source.options.specifiedExtensions.split(";").filter(e => e!='');
        }

    },

    async beforeDestroy() {
        this.$root.$off("socketEventConnected");
        this.$root.$off("socketEventDisconnected");
        if (this.testResult.client != null) {
            var disconnect = await this.$root.socket("disconnectnetwork", this.testResult.client.serviceAccount.accountSessionId);
            console.log(disconnect);
        }
    },
    methods: {
        sendSATestResult(testResult) {
            this.testResult = testResult;
            this.source.id_service_account = testResult.serviceAccount.id;
        },

        setStep(nStep) {
            this.step = nStep;

            this.$root.$emit("closeFileSystemSidebar");
        },

        async toggleFileSystemSidebar() {
            if (this.testResult.client.serviceAccount.options.accountSessionId == "") {
                this.testResult.client.serviceAccount.options.accountSessionId = await this.$root.socket("connectnetwork", this.testResult.client.serviceAccount);
            }
            var config = {
                type: this.$PlatformsTypes.Network,
                includeFiles: true,
                multipleChoice: true,
                client: this.testResult.client,
                rootPath: this.testResult.client.serviceAccount.options.path
            };

            this.$root.$emit(
                "toggleFileSystemSidebar",
                this.source.networkSource.paths,
                config
            );
        },

        async openFileSystemSidebarFrom(res) {
            if (res.isFile()) return;
            if (this.testResult.client.serviceAccount.options.accountSessionId == "") {
                var method = this.restore.type == 2 ? "connectftp" : "connectnetwork";
                this.sourceData.client.serviceAccount.options.accountSessionId = await this.$root.socket(method, this.sourceData.client.serviceAccount);
            }
            var config = {
                type: this.$PlatformsTypes.Network,
                includeFiles: true,
                multipleChoice: true,
                client: this.testResult.client,
                rootPath: res.path
            };

            this.$root.$emit(
                "toggleFileSystemSidebar",
                this.source.options.excludedPaths,
                config
            );
        },

        saveSource() {
            let self = this;
            //I paths sono nodi ma devo salvare solamente la struttura {path:'', type:''}
            self.source.networkSource.paths = self.source.networkSource.paths.map(n => {
                return {
                    path: n.path,
                    type: n.type
                }
            });

            if(this.specifiedExtensionsArray.length!=0){
                self.source.options.specifiedExtensions = this.specifiedExtensionsArray.join(";");
            }

            if (self.source.options.excludedPaths.length == 0)
                self.source.options.excludedPaths = "";
            else //paths?type(FileFolderEntityType)|paths?type(FileFolderEntityType)| ....
                self.source.options.excludedPaths = self.source.options.excludedPaths.map(r => r.toString()).join("|");

            this.$root.$emit("saveBackupSource", this.source);
        },

        cutText(text, range) {
            return cutText(text, range);
        },

        disableNextButton() {
            switch (this.step) {
                case this.STEP_SA_AND_SOURCE:
                    return !this.testResult.ok || this.source.networkSource.paths.length == 0;
                case this.STEP_COMPRESSION:
                    return true;
                default:
                    false;
            }
        }
    }
}
</script>