var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex flex-column", staticStyle: { height: "90vh" } },
    [
      _vm.accountselected != null
        ? _c(
            "div",
            {
              attrs: {
                "data-role": "charms",
                id: "accountselected",
                "data-position": "left",
                "data-cls-charms": "bg-white fg-dark border-right bd-gray ",
              },
              on: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape",
                    ])
                  )
                    return null
                  return _vm.closeCharm()
                },
              },
            },
            [
              _vm.accountselected
                ? _c("div", { staticClass: "info-box" }, [
                    _c("span", {
                      staticClass: "button square closer",
                      on: {
                        click: function ($event) {
                          return _vm.closeCharm()
                        },
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "info-box-content" },
                      [
                        _vm.accountselected != null
                          ? _c("SARepoView", {
                              attrs: {
                                repository: null,
                                serviceAccount: _vm.accountselected,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _c("Navigation", { attrs: { pageName: "accounts" } }),
      _vm.serviceAccounts == null
        ? _c("div", { staticClass: "d-flex flex-justify-center p-10" }, [
            _c("span", {
              staticClass: "mif-spinner2 fg-primary ani-spin mif-3x",
            }),
          ])
        : _c(
            "div",
            { staticClass: "bg-white border m-4 p-2 rounded overflow" },
            [
              _c(
                "ul",
                {
                  staticClass: "p-4",
                  attrs: { "data-role": "listview", "data-view": "icons" },
                },
                _vm._l(_vm.$root.getServiceAccountsTypes(), function (t) {
                  return t == _vm.$PlatformsTypes.Cloud
                    ? _c("li", {
                        attrs: {
                          "data-icon": "<span class='mif-cloud fg-black'>",
                          "data-caption": "Cloud",
                          id: "cloud_toggle",
                        },
                      })
                    : t == _vm.$PlatformsTypes.Microsoft
                    ? _c("li", {
                        attrs: {
                          "data-icon": _vm.$platformsTypesInfo[t].icon,
                          "data-caption": _vm.$platformsTypesInfo[t].name,
                          id: "microsoft_toggle",
                        },
                      })
                    : t == _vm.$PlatformsTypes.Email
                    ? _c("li", {
                        attrs: {
                          "data-icon": _vm.$platformsTypesInfo[t].icon,
                          "data-caption": _vm.$platformsTypesInfo[t].name,
                          id: "email_toggle",
                        },
                      })
                    : _c("li", {
                        attrs: {
                          disabled:
                            _vm.$platformsTypesInfo[t].needSocketConnection &&
                            !_vm.$root.atLeastOnePCConnectedToSocket,
                          "data-icon": _vm.$platformsTypesInfo[t].icon,
                          "data-caption": _vm.$platformsTypesInfo[t].name,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$root.$emit(
                              "OPENSERVICEACCOUNTDIALOG",
                              t
                            )
                          },
                        },
                      })
                }),
                0
              ),
              _c(
                "div",
                {
                  staticClass: "pl-4 pb-8",
                  attrs: {
                    "data-role": "dropdown",
                    "data-toggle-element": "#cloud_toggle",
                  },
                },
                [
                  _c(
                    "ul",
                    {
                      attrs: { "data-role": "listview", "data-view": "tiles" },
                    },
                    _vm._l(_vm.$root.getCloudTypes(), function (t) {
                      return _c("li", {
                        key: t,
                        attrs: {
                          "data-icon": _vm.$platformsTypesInfo[t].icon,
                          "data-caption": _vm.$platformsTypesInfo[t].name,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$root.$emit(
                              "OPENSERVICEACCOUNTDIALOG",
                              t
                            )
                          },
                        },
                      })
                    }),
                    0
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "pl-4 pb-8",
                  attrs: {
                    "data-role": "dropdown",
                    "data-toggle-element": "#microsoft_toggle",
                  },
                },
                [
                  _c(
                    "ul",
                    {
                      attrs: { "data-role": "listview", "data-view": "tiles" },
                    },
                    _vm._l(_vm.$root.getMicrosoftTypes(), function (t) {
                      return _c("li", {
                        key: t,
                        attrs: {
                          "data-icon": _vm.$platformsTypesInfo[t].icon,
                          "data-caption": _vm.$platformsTypesInfo[t].name,
                          disabled:
                            _vm.$platformsTypesInfo[t].needSocketConnection &&
                            !_vm.$root.atLeastOnePCConnectedToSocket,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$root.$emit(
                              "OPENSERVICEACCOUNTDIALOG",
                              t
                            )
                          },
                        },
                      })
                    }),
                    0
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "pl-4 pb-8",
                  attrs: {
                    "data-role": "dropdown",
                    "data-toggle-element": "#email_toggle",
                  },
                },
                [
                  _c(
                    "ul",
                    {
                      attrs: { "data-role": "listview", "data-view": "tiles" },
                    },
                    _vm._l(_vm.$root.getEmailTypes(), function (t) {
                      return _c("li", {
                        key: t,
                        attrs: {
                          "data-icon": _vm.$platformsTypesInfo[t].icon,
                          "data-caption": _vm.$platformsTypesInfo[t].name,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$root.$emit(
                              "OPENSERVICEACCOUNTDIALOG",
                              t
                            )
                          },
                        },
                      })
                    }),
                    0
                  ),
                ]
              ),
              _vm.serviceAccounts == null
                ? _c("div", { staticClass: "d-flex flex-justify-center" }, [
                    _c("span", {
                      staticClass: "mif-spinner2 fg-primary ani-spin mif-3x",
                    }),
                  ])
                : _vm._e(),
              _vm.serviceAccounts.length == 0
                ? _c("NoDataAvailable", {
                    attrs: { message: _vm.$t("No accounts available") },
                  })
                : _c("div", { staticClass: "pl-4 pr-4" }, [
                    _c(
                      "table",
                      {
                        staticClass:
                          "table border striped small m-0 compact bg-white",
                        attrs: {
                          "data-check": false,
                          "data-check-name": "chkBox",
                          "data-table-search-title": _vm.$t(
                            "Search saved credentials"
                          ),
                          "data-cls-search": "w-100-sm w-25-xl w-25-lg w-50-md",
                          "data-cell-wrapper": "false",
                          "data-role": "table",
                          "data-rows": "20",
                          "data-show-rows-steps": "false",
                          "data-show-pagination": "true",
                          "data-horizontal-scroll": "true",
                        },
                      },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { staticClass: "sortable-column" }, [
                              _vm._v(_vm._s(_vm.$t("Name"))),
                            ]),
                            _c("th", { staticClass: "sortable-column" }, [
                              _vm._v(_vm._s(_vm.$t("Type"))),
                            ]),
                            _c("th", { staticClass: "sortable-column" }, [
                              _vm._v(_vm._s(_vm.$t("Computer"))),
                            ]),
                            _c("th"),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(
                            _vm.serviceAccounts,
                            function (serviceAccount, index) {
                              return _c("tr", { key: serviceAccount.id }, [
                                _c("td", { staticClass: "text-left" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "button flat-button",
                                      attrs: { href: "#" + serviceAccount.id },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(serviceAccount.name) + " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("td", [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "badge inline bg-cobalt fg-white p-1 mr-1",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$platformsTypesInfo[
                                              serviceAccount.type
                                            ].name.toUpperCase()
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "td",
                                  [
                                    serviceAccount.type.isDatabase() &&
                                    _vm.$session.getPCWithID(
                                      serviceAccount.options.lastTestPC
                                    ) != null
                                      ? _c("PCLink", {
                                          attrs: {
                                            pc: _vm.$session.getPCWithID(
                                              serviceAccount.options.lastTestPC
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c("td", [
                                  _c("div", {}, [
                                    _c(
                                      "button",
                                      { staticClass: "button dropdown-toggle" },
                                      [_c("span", { staticClass: "mif-menu" })]
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "d-menu drop-left",
                                        attrs: { "data-role": "dropdown" },
                                      },
                                      [
                                        _c("li", [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "#" + serviceAccount.id,
                                                title: _vm.$t("Edit"),
                                              },
                                            },
                                            [
                                              _c("span", {
                                                staticClass:
                                                  "mif-pencil icon mr-2",
                                              }),
                                              _vm._v(
                                                _vm._s(_vm.$t("Edit")) + " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("li", [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "#duplicate:" +
                                                  serviceAccount.id,
                                                title: _vm.$t("Duplicate"),
                                              },
                                            },
                                            [
                                              _c("span", {
                                                staticClass:
                                                  "mif-copy icon mr-2",
                                              }),
                                              _vm._v(
                                                _vm._s(_vm.$t("Duplicate")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("li", [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "#delete:" +
                                                  serviceAccount.id,
                                                title: _vm.$t("Delete"),
                                              },
                                            },
                                            [
                                              _c("span", {
                                                staticClass:
                                                  "mif-bin icon mr-2",
                                              }),
                                              _vm._v(
                                                _vm._s(_vm.$t("Delete")) + " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ])
                            }
                          ),
                          0
                        ),
                      ]
                    ),
                  ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }