var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "dialog light center text-small overflow w-100-sm w-100-md w-75-lg w-50-xl draggable-item",
    },
    [
      _c("DialogTitle", {
        attrs: {
          title:
            _vm.serviceAccount.id == null
              ? _vm.$t("Add Service Account")
              : _vm.serviceAccount.name,
          defaultTitle:
            _vm.serviceAccount.id == null
              ? _vm.$t("Add Service Account")
              : _vm.$t("Edit/Test Service Account"),
          subTitle: _vm.serviceAccount.type_name,
        },
        on: {
          closeDialog: function ($event) {
            return _vm.$root.$emit("CLOSESERVICEACCOUNTDIALOG")
          },
        },
      }),
      _c("div", { staticClass: "dialog-content overflow bg-light" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("Name")))]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.serviceAccount.name,
                expression: "serviceAccount.name",
                modifiers: { trim: true },
              },
            ],
            attrs: {
              "data-role": "input",
              type: "text",
              maxlength: "50",
              placeholder: _vm.$t("enter name"),
              "data-prepend": "<span class='mif-pencil'></span>",
            },
            domProps: { value: _vm.serviceAccount.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.serviceAccount, "name", $event.target.value.trim())
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(_vm._s(_vm.$t("Required"))),
          ]),
        ]),
        _vm.needClientIDandClientSecret
          ? _c("div", { staticClass: "form-group" }, [
              _vm.serviceAccount.type.isCloudS3()
                ? _c("label", [_vm._v("Access Key")])
                : [
                    _vm.$PlatformsTypes.Cloud_AzureBlob,
                    _vm.$PlatformsTypes.Cloud_AzureFile,
                  ].includes(_vm.serviceAccount.type)
                ? _c("label", [_vm._v("Account Name")])
                : [_vm.$PlatformsTypes.Cloud_Dropbox].includes(
                    _vm.serviceAccount.type
                  )
                ? _c("label", [_vm._v("App Key")])
                : _c("label", [_vm._v("Client ID")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.serviceAccount.client_id,
                    expression: "serviceAccount.client_id",
                    modifiers: { trim: true },
                  },
                ],
                attrs: {
                  "data-role": "input",
                  type: "text",
                  autocomplete: "off",
                  "data-default-value": "",
                  "data-autocomplete": "",
                  required: "",
                  "data-prepend": "<span class='mif-lock'></span>",
                },
                domProps: { value: _vm.serviceAccount.client_id },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.serviceAccount,
                      "client_id",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(_vm._s(_vm.$t("Required"))),
              ]),
            ])
          : _vm._e(),
        _vm.needClientIDandClientSecret
          ? _c("div", { staticClass: "form-group" }, [
              _vm.serviceAccount.type.isCloudS3()
                ? _c("label", [_vm._v("Secret Key")])
                : [
                    _vm.$PlatformsTypes.Cloud_AzureBlob,
                    _vm.$PlatformsTypes.Cloud_AzureFile,
                  ].includes(_vm.serviceAccount.type)
                ? _c("label", [_vm._v("Access Key")])
                : [_vm.$PlatformsTypes.Cloud_Dropbox].includes(
                    _vm.serviceAccount.type
                  )
                ? _c("label", [_vm._v("App Secret")])
                : _c("label", [_vm._v("Client Secret")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.serviceAccount.client_secret,
                    expression: "serviceAccount.client_secret",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "metro-input",
                attrs: {
                  "data-role": "input",
                  type: "password",
                  "data-prepend": "<span class='mif-lock'></span>",
                },
                domProps: { value: _vm.serviceAccount.client_secret },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.serviceAccount,
                      "client_secret",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(_vm._s(_vm.$t("Required"))),
              ]),
            ])
          : _vm._e(),
        [_vm.$PlatformsTypes.Cloud_AmazonS3Storage].includes(
          _vm.serviceAccount.type
        )
          ? _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v(_vm._s(_vm.$t("Region")))]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.serviceAccount.region,
                      expression: "serviceAccount.region",
                    },
                  ],
                  staticClass: "select w-100",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.serviceAccount,
                        "region",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(_vm.regions, function (region) {
                  return _c(
                    "option",
                    { key: region.Url, domProps: { value: region.Url } },
                    [_vm._v(_vm._s(region.Name))]
                  )
                }),
                0
              ),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(_vm._s(_vm.$t("Required"))),
              ]),
            ])
          : _vm._e(),
        [_vm.$PlatformsTypes.Cloud_S3CompatibleStorage].includes(
          _vm.serviceAccount.type
        )
          ? _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v(_vm._s(_vm.$t("URL")))]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.serviceAccount.url,
                    expression: "serviceAccount.url",
                  },
                ],
                staticClass: "metro-input",
                attrs: { "data-role": "input", type: "text" },
                domProps: { value: _vm.serviceAccount.url },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.serviceAccount, "url", $event.target.value)
                  },
                },
              }),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(_vm._s(_vm.$t("Required"))),
              ]),
            ])
          : _vm._e(),
        [
          _vm.$PlatformsTypes.Cloud_IperiusS3Storage,
          _vm.$PlatformsTypes.Cloud_S3CompatibleStorage,
        ].includes(_vm.serviceAccount.type)
          ? _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.serviceAccount.use_ssl,
                    expression: "serviceAccount.use_ssl",
                  },
                ],
                attrs: {
                  "data-role": "checkbox",
                  type: "checkbox",
                  "data-caption": _vm.$t("Use secure connection (SSL/TLS)"),
                  required: "",
                  "data-prepend": "<span class='mif-lock'></span>",
                  disabled:
                    _vm.serviceAccount.type ==
                    _vm.$PlatformsTypes.Cloud_IperiusS3Storage,
                },
                domProps: {
                  checked: Array.isArray(_vm.serviceAccount.use_ssl)
                    ? _vm._i(_vm.serviceAccount.use_ssl, null) > -1
                    : _vm.serviceAccount.use_ssl,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.serviceAccount.use_ssl,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.serviceAccount,
                            "use_ssl",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.serviceAccount,
                            "use_ssl",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.serviceAccount, "use_ssl", $$c)
                    }
                  },
                },
              }),
            ])
          : _vm._e(),
        _vm.serviceAccount.type == _vm.$PlatformsTypes.Cloud_OneDrive
          ? _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v(_vm._s(_vm.$t("Application Name")))]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.serviceAccount.options.appName,
                    expression: "serviceAccount.options.appName",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "metro-input",
                attrs: {
                  "data-role": "input",
                  type: "text",
                  "data-prepend": "<span class='mif-lock'></span>",
                  disabled: _vm.serviceAccount.client_id != "",
                },
                domProps: { value: _vm.serviceAccount.options.appName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.serviceAccount.options,
                      "appName",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(_vm._s(_vm.$t("Required"))),
              ]),
            ])
          : _vm._e(),
        [_vm.$PlatformsTypes.Cloud_S3CompatibleStorage].includes(
          _vm.serviceAccount.type
        )
          ? _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v(_vm._s(_vm.$t("Signature version")))]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.serviceAccount.options.signatureversion,
                      expression: "serviceAccount.options.signatureversion",
                    },
                  ],
                  staticClass: "select w-25",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.serviceAccount.options,
                        "signatureversion",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { key: 2, domProps: { value: 2 } }, [
                    _vm._v("2"),
                  ]),
                  _c("option", { key: 4, domProps: { value: 4 } }, [
                    _vm._v("4"),
                  ]),
                ]
              ),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(_vm._s(_vm.$t("Required"))),
              ]),
            ])
          : _vm._e(),
        _vm.serviceAccount.options.authCode != undefined &&
        _vm.serviceAccount.options.authCode != null
          ? _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v(_vm._s(_vm.$t("AuthCode")))]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.serviceAccount.options.authCode,
                    expression: "serviceAccount.options.authCode",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "metro-input",
                attrs: {
                  "data-role": "input",
                  type: "password",
                  placeholder: _vm.$t("Enter auth code"),
                  "data-prepend": "<span class='mif-lock'></span>",
                },
                domProps: { value: _vm.serviceAccount.options.authCode },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.serviceAccount.options,
                      "authCode",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(_vm._s(_vm.$t("Required"))),
              ]),
            ])
          : _vm._e(),
        _vm.googledrive_url == ""
          ? _c("div", { staticClass: "form-group" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "Click on the following URL and enable the Google Drive API, then try the test again"
                    )
                  ) +
                  ":"
              ),
              _c("br"),
              _c(
                "a",
                { attrs: { href: _vm.googledrive_url, target: "_blank" } },
                [_vm._v(_vm._s(_vm.googledrive_url))]
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-actions d-flex",
          class:
            _vm.onedrive_step == null
              ? "flex-justify-end"
              : "flex-justify-between",
        },
        [
          _vm.onedrive_step != null
            ? _c("div", { staticClass: "text-light fg-cobalt" }, [
                _vm._v(_vm._s(_vm.$t(_vm.onedrive_step))),
              ])
            : _vm._e(),
          _vm.serviceAccount.id != null
            ? _c(
                "button",
                {
                  staticClass: "button primary",
                  attrs: { disabled: _vm.tested == _vm.TEST_WAIT },
                  on: {
                    click: function ($event) {
                      return _vm.$root.duplicateServiceAccount(
                        _vm.serviceAccount
                      )
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Duplicate")))]
              )
            : _vm._e(),
          _vm.tested == _vm.TEST_WAIT
            ? _c("div", [_c("span", { staticClass: "mif-spinner2 ani-spin" })])
            : _vm._e(),
          _c(
            "button",
            {
              staticClass: "button primary",
              attrs: { disabled: !_vm.enableTest },
              on: { click: _vm.test },
            },
            [_vm._v(" " + _vm._s(_vm.$t("Test account")) + " ")]
          ),
          _c(
            "button",
            {
              staticClass: "button primary",
              attrs: { disabled: !_vm.enableSave },
              on: {
                click: function ($event) {
                  return _vm.$root.saveServiceAccount(_vm.serviceAccount)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("Save")))]
          ),
          _c(
            "button",
            {
              staticClass: "button primary",
              on: {
                click: function ($event) {
                  return _vm.$root.$emit("CLOSESERVICEACCOUNTDIALOG")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("Close")))]
          ),
          _vm.serviceAccount.id != null
            ? _c(
                "button",
                {
                  staticClass: "button alert",
                  attrs: { disabled: _vm.tested == _vm.TEST_WAIT },
                  on: {
                    click: function ($event) {
                      return _vm.$root.deleteServiceAccount(_vm.serviceAccount)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Delete")))]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }