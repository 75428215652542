var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.selecteduser
    ? _c(
        "div",
        {
          staticClass:
            "dialog light w-100-sm w-100-md w-75-lg w-50-xl h-75 draggable-item",
          attrs: { id: "user_dialog" },
        },
        [
          _c("div", { staticClass: "dialog-title pl-10" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.selecteduser.id == null
                    ? _vm.$t("Create New User")
                    : _vm.selecteduser.username
                ) +
                " "
            ),
            _c("span", {
              staticClass: "button square closer",
              on: {
                click: function ($event) {
                  return _vm.$root.$emit("CLOSEUSERVIEWDIALOG")
                },
              },
            }),
          ]),
          _c("div", { staticClass: "dialog-content overflow bg-light" }, [
            _c(
              "ul",
              {
                staticClass: "bg-light",
                staticStyle: { "z-index": "1" },
                attrs: { "data-role": "tabs", "data-expand": "true" },
              },
              [
                _c("li", [
                  _c("a", { attrs: { href: "#profile" } }, [
                    _c("span", { staticClass: "mif mif-profile mif-2x" }),
                    _vm._v(_vm._s(_vm.$t("Profile"))),
                  ]),
                ]),
                _vm.$root.checkAuth().id_user == _vm.selecteduser.id
                  ? _c("li", { staticClass: "no-border" }, [
                      _c("a", { attrs: { href: "#permissions" } }, [
                        _c("span", {
                          staticClass: "mif mif-notifications mif-2x",
                        }),
                        _vm._v(" " + _vm._s(_vm.$t("Policy Notifications"))),
                      ]),
                    ])
                  : _vm._e(),
              ]
            ),
            _c("form", [
              _c(
                "div",
                { staticClass: "border border-top", attrs: { id: "profile" } },
                [
                  this.$root.checkAuth().id_user == _vm.selecteduser.id
                    ? _c(
                        "div",
                        { staticClass: "form-group row bg-light mt-4 mb-4" },
                        [
                          _c("div", { staticClass: "p-2" }, [
                            _c("label", [
                              _vm._v(
                                _vm._s(_vm.$t("Two Factor Authentication"))
                              ),
                            ]),
                            _c("br"),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.startEnabling,
                                  expression: "startEnabling",
                                },
                              ],
                              attrs: {
                                "data-class": "ml-3",
                                disabled:
                                  this.$root.checkAuth().id_user !=
                                  _vm.selecteduser.id,
                                "data-on": "on",
                                "data-off": "off",
                                type: "checkbox",
                                "data-role": "switch",
                                "data-caption-position": "right",
                                "data-caption": _vm.$t("Enable 2FA"),
                              },
                              domProps: {
                                checked: Array.isArray(_vm.startEnabling)
                                  ? _vm._i(_vm.startEnabling, null) > -1
                                  : _vm.startEnabling,
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = _vm.startEnabling,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.startEnabling = $$a.concat([
                                            $$v,
                                          ]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.startEnabling = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.startEnabling = $$c
                                    }
                                  },
                                  function ($event) {
                                    return _vm.toggleTFA()
                                  },
                                ],
                              },
                            }),
                          ]),
                          _vm.checkTFA.step1 != null &&
                          _vm.checkTFA.step2 == null
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "bg-primary border fg-white rounded",
                                },
                                [
                                  _c("div", { staticClass: "row p-2" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "cell-md-6 cel-lg-4 cell-sm-12 text-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "m-4" },
                                          [
                                            _c("Qrcode", {
                                              attrs: {
                                                value: _vm.checkTFA.step1,
                                              },
                                            }),
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(_vm.checkTFA.secret)
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "cell-md-6 cel-lg-8 cell-sm-12",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mb-3 p-1 text-left pt-6",
                                          },
                                          [
                                            _c("label", { staticClass: "h6" }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "Scan the QR code on the page using an app such as Google Authenticator, 2 FAS, Microsoft Authenticator, Twilio Authy."
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "inline-form" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model.trim",
                                                  value:
                                                    _vm.selecteduser.TFAcode,
                                                  expression:
                                                    "selecteduser.TFAcode",
                                                  modifiers: { trim: true },
                                                },
                                              ],
                                              staticClass: "input w-100",
                                              attrs: {
                                                maxlength: "6",
                                                minlength: "6",
                                                type: "text",
                                                placeholder: _vm.$t(
                                                  "Insert Code from Authenticator"
                                                ),
                                                required: "",
                                              },
                                              domProps: {
                                                value: _vm.selecteduser.TFAcode,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.selecteduser,
                                                    "TFAcode",
                                                    $event.target.value.trim()
                                                  )
                                                },
                                                blur: function ($event) {
                                                  return _vm.$forceUpdate()
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "inline-form p-2" },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "button primary w-100",
                                                attrs: {
                                                  disabled:
                                                    _vm.selecteduser.TFAcode ==
                                                      null ||
                                                    _vm.selecteduser.TFAcode
                                                      .length < 6,
                                                },
                                                on: { click: _vm.enableTFA },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("Enable TFA"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", [_vm._v(_vm._s(_vm.$t("Username")))]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.selecteduser.username,
                          expression: "selecteduser.username",
                          modifiers: { trim: true },
                        },
                      ],
                      attrs: {
                        "data-role": "input",
                        disabled: _vm.selecteduser.id != null,
                        type: "text",
                        required: "",
                        "data-prepend": "<span class='mif-user'></span>",
                      },
                      domProps: { value: _vm.selecteduser.username },
                      on: {
                        keydown: _vm.$root.blockSpaces,
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.selecteduser,
                            "username",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v(_vm._s(_vm.$t("Required"))),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("div", { staticClass: "col-6" }, [
                      _c("label", [_vm._v(_vm._s(_vm.$t("Firstname")))]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.selecteduser.firstname,
                            expression: "selecteduser.firstname",
                            modifiers: { trim: true },
                          },
                        ],
                        attrs: {
                          "data-role": "input",
                          type: "text",
                          required: "",
                          "data-prepend": "<span class='mif-user'></span>",
                        },
                        domProps: { value: _vm.selecteduser.firstname },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.selecteduser,
                              "firstname",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.$t("Required"))),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-6" }, [
                      _c("label", [_vm._v(_vm._s(_vm.$t("Lastname")))]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.selecteduser.lastname,
                            expression: "selecteduser.lastname",
                            modifiers: { trim: true },
                          },
                        ],
                        attrs: {
                          "data-role": "input",
                          type: "text",
                          required: "",
                          "data-prepend": "<span class='mif-user'></span>",
                        },
                        domProps: { value: _vm.selecteduser.lastname },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.selecteduser,
                              "lastname",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.$t("Required"))),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group row pb-1" }, [
                    _c("div", { staticClass: "col-6" }, [
                      _c("label", [_vm._v(_vm._s(_vm.$t("Email")))]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selecteduser.email,
                            expression: "selecteduser.email",
                          },
                        ],
                        attrs: {
                          disabled: _vm.selecteduser.id != null,
                          "data-role": "input",
                          type: "email",
                          required: "",
                          "data-prepend": "<span class='mif-mail'></span>",
                        },
                        domProps: { value: _vm.selecteduser.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.selecteduser,
                              "email",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.$t("Required"))),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-6" }, [
                      _c("label", [_vm._v(_vm._s(_vm.$t("User Role")))]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selecteduser.role,
                              expression: "selecteduser.role",
                            },
                          ],
                          staticClass: "select",
                          attrs: {
                            disabled:
                              _vm.selecteduser.id ==
                              _vm.$session.getAuth()?.id_user,
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.selecteduser,
                                "role",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(this.$session.getRoles(), function (role) {
                          return _c(
                            "option",
                            { key: role.id, domProps: { value: role.name } },
                            [_vm._v(" " + _vm._s(role.name) + " ")]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _vm.selecteduser.id == null
                    ? _c("div", { staticClass: "form-group row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectAllGroups,
                                expression: "selectAllGroups",
                              },
                            ],
                            attrs: {
                              "data-class": "ml-3",
                              "data-on": "on",
                              "data-off": "off",
                              type: "checkbox",
                              "data-role": "switch",
                              "data-caption-position": "right",
                              "data-caption": _vm.$t("Assign all groups"),
                            },
                            domProps: {
                              checked: Array.isArray(_vm.selectAllGroups)
                                ? _vm._i(_vm.selectAllGroups, null) > -1
                                : _vm.selectAllGroups,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.selectAllGroups,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.selectAllGroups = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.selectAllGroups = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.selectAllGroups = $$c
                                }
                              },
                            },
                          }),
                        ]),
                        _vm.selectAllGroups == false
                          ? _c("div", { staticClass: "col-12" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("Allowed Groups"))),
                              ]),
                              _c(
                                "select",
                                {
                                  attrs: {
                                    "data-role": "select",
                                    "data-filter": "true",
                                    multiple: "",
                                    "data-filter-placeholder":
                                      _vm.$t("Search for groups"),
                                    "data-cls-selected-item":
                                      "bg-light border pr-2 rounded",
                                    id: "groupselect",
                                    name: "ids_groups",
                                  },
                                },
                                _vm._l(
                                  _vm.$session.getGroups(),
                                  function (group) {
                                    return _c(
                                      "option",
                                      {
                                        key: group.id_group,
                                        domProps: {
                                          selected:
                                            _vm.selecteduser.groups.find(
                                              (elem) => elem == group.id_group
                                            ),
                                          value: group.id_group,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(group.name_group) + " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm.selecteduser.id != null
                    ? _c("div", { staticClass: "form-group row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("label", [
                            _vm._v(_vm._s(_vm.$t("Allowed Groups"))),
                          ]),
                          _c(
                            "select",
                            {
                              attrs: {
                                "data-role": "select",
                                "data-filter": "true",
                                multiple: "",
                                "data-cls-selected-item":
                                  "bg-light border pr-2 rounded",
                                id: "groupselect",
                                name: "ids_groups",
                              },
                            },
                            _vm._l(_vm.$session.getGroups(), function (group) {
                              return _c(
                                "option",
                                {
                                  key: group.id_group,
                                  domProps: {
                                    selected: _vm.selecteduser.groups.find(
                                      (elem) => elem == group.id_group
                                    ),
                                    value: group.id_group,
                                  },
                                },
                                [_vm._v(" " + _vm._s(group.name_group) + " ")]
                              )
                            }),
                            0
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.selecteduser.id != null
                    ? _c("div", { staticClass: "form-group row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("label", [_vm._v(_vm._s(_vm.$t("Password")))]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.selecteduser.oldPassword,
                                expression: "selecteduser.oldPassword",
                                modifiers: { trim: true },
                              },
                            ],
                            attrs: {
                              "data-role": "input",
                              autocomplete: "false",
                              type: "password",
                              required: "",
                              "data-prepend": "<span class='mif-lock'></span>",
                            },
                            domProps: { value: _vm.selecteduser.oldPassword },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.selecteduser,
                                  "oldPassword",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                        ]),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("div", { staticClass: "col-6" }, [
                      _c("label", [_vm._v(_vm._s(_vm.$t("New Password")))]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.selecteduser.password,
                            expression: "selecteduser.password",
                            modifiers: { trim: true },
                          },
                        ],
                        attrs: {
                          "data-role": "input",
                          autocomplete: "false",
                          type: "password",
                          required: "",
                          "data-prepend": "<span class='mif-lock'></span>",
                        },
                        domProps: { value: _vm.selecteduser.password },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.selecteduser,
                              "password",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.$t("Required"))),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-6" }, [
                      _c("label", [_vm._v(_vm._s(_vm.$t("Retype Password")))]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.selecteduser.confirmPassword,
                            expression: "selecteduser.confirmPassword",
                            modifiers: { trim: true },
                          },
                        ],
                        attrs: {
                          "data-role": "input",
                          autocomplete: "false",
                          type: "password",
                          required: "",
                          "data-prepend": "<span class='mif-lock'></span>",
                        },
                        domProps: { value: _vm.selecteduser.confirmPassword },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.selecteduser,
                              "confirmPassword",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.$t("Required"))),
                      ]),
                    ]),
                  ]),
                  _vm.$root.checkAuth().id_user == _vm.selecteduser.id
                    ? _c("div", { staticClass: "form-group row" }, [
                        _c("div", { staticClass: "p-2" }, [
                          _c("h6", { staticClass: "text-bold" }, [
                            _vm._v(_vm._s(_vm.$t("Notification Preferences"))),
                          ]),
                        ]),
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(_vm.$t("In App"))),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(_vm.$t("Desktop")) + " "),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(_vm.$t("Mobile")) + " "),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(_vm.$t("E-mail")) + " "),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(_vm.$t("Whatsapp")) + " "),
                              ]),
                            ]),
                          ]),
                          _c("tbody", [
                            _c("tr", [
                              _c("td", [
                                _c("div", { staticClass: "text-center mb-2" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.selecteduser.socket_notification,
                                        expression:
                                          "selecteduser.socket_notification",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      "data-role": "switch",
                                      "data-on": true,
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selecteduser.socket_notification
                                      )
                                        ? _vm._i(
                                            _vm.selecteduser
                                              .socket_notification,
                                            null
                                          ) > -1
                                        : _vm.selecteduser.socket_notification,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a =
                                            _vm.selecteduser
                                              .socket_notification,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.selecteduser,
                                                "socket_notification",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.selecteduser,
                                                "socket_notification",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.selecteduser,
                                            "socket_notification",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c("td", [
                                _c("div", { staticClass: "text-center mb-2" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.selecteduser.webpush_notification,
                                        expression:
                                          "selecteduser.webpush_notification",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      "data-caption-position": "left",
                                      "data-role": "switch",
                                      "data-on": "true",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selecteduser.webpush_notification
                                      )
                                        ? _vm._i(
                                            _vm.selecteduser
                                              .webpush_notification,
                                            null
                                          ) > -1
                                        : _vm.selecteduser.webpush_notification,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a =
                                            _vm.selecteduser
                                              .webpush_notification,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.selecteduser,
                                                "webpush_notification",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.selecteduser,
                                                "webpush_notification",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.selecteduser,
                                            "webpush_notification",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c("td", [
                                _c("div", { staticClass: "text-center mb-2" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.selecteduser.push_notification,
                                        expression:
                                          "selecteduser.push_notification",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      "data-caption-position": "left",
                                      "data-role": "switch",
                                      "data-on": "true",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selecteduser.push_notification
                                      )
                                        ? _vm._i(
                                            _vm.selecteduser.push_notification,
                                            null
                                          ) > -1
                                        : _vm.selecteduser.push_notification,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a =
                                            _vm.selecteduser.push_notification,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.selecteduser,
                                                "push_notification",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.selecteduser,
                                                "push_notification",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.selecteduser,
                                            "push_notification",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c("td", [
                                _c("div", { staticClass: "text-center mb-2" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.selecteduser.email_notification,
                                        expression:
                                          "selecteduser.email_notification",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      "data-caption-position": "left",
                                      "data-role": "switch",
                                      "data-on": "true",
                                      disabled:
                                        _vm.selecteduser.email == ""
                                          ? "disabled"
                                          : false,
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selecteduser.email_notification
                                      )
                                        ? _vm._i(
                                            _vm.selecteduser.email_notification,
                                            null
                                          ) > -1
                                        : _vm.selecteduser.email_notification,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a =
                                            _vm.selecteduser.email_notification,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.selecteduser,
                                                "email_notification",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.selecteduser,
                                                "email_notification",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.selecteduser,
                                            "email_notification",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c("td", [
                                _c("div", { staticClass: "text-center mb-2" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.selecteduser
                                            .whatsapp_notification,
                                        expression:
                                          "selecteduser.whatsapp_notification",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      disabled: "",
                                      "data-caption-position": "left",
                                      "data-role": "switch",
                                      "data-on": "true",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.selecteduser.whatsapp_notification
                                      )
                                        ? _vm._i(
                                            _vm.selecteduser
                                              .whatsapp_notification,
                                            null
                                          ) > -1
                                        : _vm.selecteduser
                                            .whatsapp_notification,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a =
                                            _vm.selecteduser
                                              .whatsapp_notification,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.selecteduser,
                                                "whatsapp_notification",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.selecteduser,
                                                "whatsapp_notification",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.selecteduser,
                                            "whatsapp_notification",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]
              ),
            ]),
            _vm.$root.checkAuth().id_user == _vm.selecteduser.id
              ? _c(
                  "div",
                  {
                    staticClass: "border border-top h-100",
                    attrs: { id: "permissions" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c(
                          "ul",
                          { staticClass: "sidebar-menu col-4 p-0 pt-5" },
                          _vm._l(
                            Object.keys(_vm.selecteduser.policies),
                            function (key, index) {
                              return _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "pl-3",
                                    class: {
                                      "ml-5 bg-light pl-3 rounded text-bold":
                                        _vm.policyTypeselected == index,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.policyTypeselected = index
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(key))]
                                ),
                              ])
                            }
                          ),
                          0
                        ),
                        _vm._l(
                          Object.values(_vm.selecteduser.policies),
                          function (value, index) {
                            return index == _vm.policyTypeselected
                              ? _c("div", { staticClass: "col-8 p-4" }, [
                                  _c(
                                    "ul",
                                    {},
                                    _vm._l(value, function (policy) {
                                      return _c(
                                        "li",
                                        {
                                          staticClass:
                                            "p-2 m-1 d-flex flex-align-center flex-justify-between",
                                        },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "text-bold" },
                                            [_vm._v(_vm._s(policy.name))]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  policy.enabled_notifications,
                                                expression:
                                                  "policy.enabled_notifications",
                                              },
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              "data-caption-position": "right",
                                              "data-role": "checkbox",
                                              "data-caption":
                                                "<span class='mif-notifications mif-2x'></span>",
                                              "data-on": true,
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                policy.enabled_notifications
                                              )
                                                ? _vm._i(
                                                    policy.enabled_notifications,
                                                    null
                                                  ) > -1
                                                : policy.enabled_notifications,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    policy.enabled_notifications,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        policy,
                                                        "enabled_notifications",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        policy,
                                                        "enabled_notifications",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    policy,
                                                    "enabled_notifications",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ])
                              : _vm._e()
                          }
                        ),
                      ],
                      2
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "dialog-actions" }, [
            _c(
              "button",
              { staticClass: "button primary", on: { click: _vm.saveUser } },
              [_vm._v(_vm._s(_vm.$t("Save")))]
            ),
            _vm.selecteduser.id != null
              ? _c(
                  "button",
                  {
                    staticClass: "button error ml-2",
                    attrs: {
                      disabled:
                        _vm.$root.checkAuth().id_user == _vm.selecteduser.id ||
                        _vm.selecteduser.level == "2",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.deleteUser(_vm.selecteduser)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("Delete")))]
                )
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "button ml-2",
                on: {
                  click: function ($event) {
                    return _vm.$root.$emit("CLOSEUSERVIEWDIALOG")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("Close")))]
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }