var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog light w-100-sm w-100-md w-75-lg w-50-xl",
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.$root.$emit("CLOSERESTOREDIALOG")
        },
      },
    },
    [
      _c("DialogTitle", {
        attrs: {
          title: _vm.$t("MS Exchange Restore"),
          defaultTitle: _vm.$t("MS Exchange restore"),
          subTitle: _vm.$t("MS Exchange Backups"),
        },
        on: {
          closeDialog: function ($event) {
            return _vm.$root.$emit("CLOSERESTOREDIALOG")
          },
        },
      }),
      _vm.step == _vm.STEP_SELECTBACKUPCOPY
        ? _c(
            "div",
            { staticClass: "dialog-content" },
            [
              _vm.isManual
                ? _c("div", { staticClass: "row h6 text-bold m-4" }, [
                    _c("div", { staticClass: "cell-12" }, [
                      _vm._v(_vm._s(_vm.$t("Choose source type"))),
                    ]),
                  ])
                : _vm._e(),
              _vm.isManual
                ? _c("div", { staticClass: "form-group" }, [
                    _c(
                      "ul",
                      {
                        attrs: {
                          "data-role": "listview",
                          "data-view": "icons",
                          "data-select-node": "true",
                          "data-selectable": "false",
                        },
                      },
                      _vm._l(
                        _vm.$platformsTypesInfo[_vm.restore.type]
                          .backupDestinationTypes,
                        function (sourceType) {
                          return _c("li", {
                            key: sourceType,
                            attrs: {
                              "data-icon":
                                _vm.$platformsTypesInfo[sourceType].icon,
                              "data-caption":
                                _vm.$platformsTypesInfo[sourceType].name,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.setSourceType(sourceType)
                              },
                            },
                          })
                        }
                      ),
                      0
                    ),
                  ])
                : _vm._e(),
              ![-1, _vm.$PlatformsTypes.FileFolder].includes(
                _vm.restore.source_type
              )
                ? _c("SARepoSelect", {
                    attrs: {
                      type: _vm.restore.source_type,
                      idSA: _vm.restore.source.id_service_account,
                      idRepo: -2,
                    },
                    on: { sendSATestResult: _vm.sendSATestResult },
                  })
                : _vm._e(),
              _vm.isManual
                ? _c("SelectedResourcesLayout", {
                    attrs: {
                      list: _vm.sourceData.selectedNodes,
                      singleItem: true,
                      canCancel: false,
                      buttonText: _vm.$t("Select the folder of a backup copy"),
                      type: _vm.restore.sourceType,
                      isDisabled:
                        _vm.restore.source_type !=
                          _vm.$PlatformsTypes.FileFolder &&
                        _vm.sourceData.client == null,
                      heightPercentage: 30,
                    },
                    on: {
                      clickFunction: function ($event) {
                        return _vm.toggleFileSystemSidebar("CHOOSEBACKUPCOPY")
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.step == _vm.STEP_SELECTRESTOREOPTIONS
        ? _c("div", { staticClass: "dialog-content" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("h6", { staticClass: "text-bold" }, [
                _vm._v(_vm._s(_vm.$t("What is to be recovered?")) + ":"),
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.restore.restoreType,
                      expression: "restore.restoreType",
                    },
                  ],
                  staticClass: "select w-100",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.restore,
                        "restoreType",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("Recreate full copy from backup"))),
                  ]),
                  _c("option", { domProps: { value: 2 } }, [
                    _vm._v(
                      _vm._s(_vm.$t("Recreate a specific copy from backup"))
                    ),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("h6", { staticClass: "text-bold mb-3" }, [
                _vm._v(_vm._s(_vm.$t("Select a copy"))),
              ]),
              _vm.fileConfig != null && _vm.fileConfig.iterations != null
                ? _c(
                    "div",
                    { staticClass: "overflow" },
                    _vm._l(_vm.fileConfig.iterations, function (iteration) {
                      return _c(
                        "div",
                        { key: iteration.index, staticClass: "d-flex mb-4" },
                        [
                          _c("input", {
                            staticClass: "mr-5",
                            attrs: {
                              name: "iter",
                              type: "radio",
                              "data-role": "radio",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onIterationChanged(iteration)
                              },
                            },
                          }),
                          _c("div", { staticClass: "pr-4" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-justify-between w-100 pr-3",
                              },
                              [
                                _c("div", [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "badge inline bg-cobalt fg-white p-1",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$platformsTypesInfo[
                                              iteration.sourceType
                                            ].name
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("span", {
                                    staticClass: "mif-arrow-right mx-1",
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "badge inline bg-cobalt fg-white p-1",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$platformsTypesInfo[
                                              iteration.destinationType
                                            ].name
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "text-bold" }, [
                                  _c("label", { staticClass: "mr-2" }, [
                                    _vm._v(_vm._s(_vm.$t("Copy number")) + ":"),
                                  ]),
                                  _vm._v(
                                    _vm._s(
                                      iteration.index +
                                        1 +
                                        " / " +
                                        (iteration.nCopies + 1)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c("div", [
                              _c("small", [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(_vm.$t("Backup folder")) + " : "
                                  ),
                                ]),
                                _vm._v(" " + _vm._s(iteration.iterationFolder)),
                              ]),
                            ]),
                            _c("small", { staticClass: "text-bold" }, [
                              _c("label", { staticClass: "mr-2" }, [
                                _vm._v(_vm._s(_vm.$t("Start date")) + ":"),
                              ]),
                              _vm._v(
                                _vm._s(
                                  _vm._f("moment")(
                                    _vm._f("utcAsLocal")(iteration.lastStart),
                                    "LLLL"
                                  )
                                )
                              ),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == _vm.STEP_OPTIONS,
              expression: "step == STEP_OPTIONS",
            },
          ],
          staticClass: "dialog-content",
        },
        [
          _c("h6", { staticClass: "form-group text-bold" }, [
            _vm._v(_vm._s(_vm.$t("Restore Options"))),
          ]),
          _c("div", { staticClass: "form-group d-flex row" }, [
            _c("p", { staticClass: "w-50" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.restore.source_options.includeInbox,
                    expression: "restore.source_options.includeInbox",
                  },
                ],
                attrs: {
                  "data-role": "checkbox",
                  type: "checkbox",
                  checked: "",
                },
                domProps: {
                  checked: Array.isArray(
                    _vm.restore.source_options.includeInbox
                  )
                    ? _vm._i(_vm.restore.source_options.includeInbox, null) > -1
                    : _vm.restore.source_options.includeInbox,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.restore.source_options.includeInbox,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.restore.source_options,
                            "includeInbox",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.restore.source_options,
                            "includeInbox",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.restore.source_options, "includeInbox", $$c)
                    }
                  },
                },
              }),
              _c("label", [
                _c("span", { staticClass: "mif-email mr-2" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("Include Inbox")))]),
              ]),
            ]),
            _c("p", { staticClass: "w-50" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.restore.source_options.includeSentItems,
                    expression: "restore.source_options.includeSentItems",
                  },
                ],
                attrs: {
                  "data-role": "checkbox",
                  type: "checkbox",
                  checked: "",
                },
                domProps: {
                  checked: Array.isArray(
                    _vm.restore.source_options.includeSentItems
                  )
                    ? _vm._i(
                        _vm.restore.source_options.includeSentItems,
                        null
                      ) > -1
                    : _vm.restore.source_options.includeSentItems,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.restore.source_options.includeSentItems,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.restore.source_options,
                            "includeSentItems",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.restore.source_options,
                            "includeSentItems",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(
                        _vm.restore.source_options,
                        "includeSentItems",
                        $$c
                      )
                    }
                  },
                },
              }),
              _c("label", [
                _c("span", { staticClass: "mif-email mr-2" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("Include Sent Items")))]),
              ]),
            ]),
            _c("p", { staticClass: "w-50" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.restore.source_options.includeDrafts,
                    expression: "restore.source_options.includeDrafts",
                  },
                ],
                attrs: { "data-role": "checkbox", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(
                    _vm.restore.source_options.includeDrafts
                  )
                    ? _vm._i(_vm.restore.source_options.includeDrafts, null) >
                      -1
                    : _vm.restore.source_options.includeDrafts,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.restore.source_options.includeDrafts,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.restore.source_options,
                            "includeDrafts",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.restore.source_options,
                            "includeDrafts",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.restore.source_options, "includeDrafts", $$c)
                    }
                  },
                },
              }),
              _c("label", [
                _c("span", { staticClass: "mif-email mr-2" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("Include Drafts")))]),
              ]),
            ]),
            _c("p", { staticClass: "w-50" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.restore.source_options.includeOutbox,
                    expression: "restore.source_options.includeOutbox",
                  },
                ],
                attrs: { "data-role": "checkbox", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(
                    _vm.restore.source_options.includeOutbox
                  )
                    ? _vm._i(_vm.restore.source_options.includeOutbox, null) >
                      -1
                    : _vm.restore.source_options.includeOutbox,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.restore.source_options.includeOutbox,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.restore.source_options,
                            "includeOutbox",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.restore.source_options,
                            "includeOutbox",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.restore.source_options, "includeOutbox", $$c)
                    }
                  },
                },
              }),
              _c("label", [
                _c("span", { staticClass: "mif-email mr-2" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("Include Outbox")))]),
              ]),
            ]),
            _c("p", { staticClass: "w-50" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.restore.source_options.includeDeletedItems,
                    expression: "restore.source_options.includeDeletedItems",
                  },
                ],
                attrs: { "data-role": "checkbox", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(
                    _vm.restore.source_options.includeDeletedItems
                  )
                    ? _vm._i(
                        _vm.restore.source_options.includeDeletedItems,
                        null
                      ) > -1
                    : _vm.restore.source_options.includeDeletedItems,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.restore.source_options.includeDeletedItems,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.restore.source_options,
                            "includeDeletedItems",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.restore.source_options,
                            "includeDeletedItems",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(
                        _vm.restore.source_options,
                        "includeDeletedItems",
                        $$c
                      )
                    }
                  },
                },
              }),
              _c("label", [
                _c("span", { staticClass: "mif-email mr-2" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("Include Deleted Items")))]),
              ]),
            ]),
            _c("p", { staticClass: "w-50" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.restore.source_options.includeJunkeMails,
                    expression: "restore.source_options.includeJunkeMails",
                  },
                ],
                attrs: { "data-role": "checkbox", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(
                    _vm.restore.source_options.includeJunkeMails
                  )
                    ? _vm._i(
                        _vm.restore.source_options.includeJunkeMails,
                        null
                      ) > -1
                    : _vm.restore.source_options.includeJunkeMails,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.restore.source_options.includeJunkeMails,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.restore.source_options,
                            "includeJunkeMails",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.restore.source_options,
                            "includeJunkeMails",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(
                        _vm.restore.source_options,
                        "includeJunkeMails",
                        $$c
                      )
                    }
                  },
                },
              }),
              _c("label", [
                _c("span", { staticClass: "mif-email mr-2" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("Include Junke Mails")))]),
              ]),
            ]),
            _c("p", { staticClass: "w-50" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.restore.source_options.includeArchive,
                    expression: "restore.source_options.includeArchive",
                  },
                ],
                attrs: { "data-role": "checkbox", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(
                    _vm.restore.source_options.includeArchive
                  )
                    ? _vm._i(_vm.restore.source_options.includeArchive, null) >
                      -1
                    : _vm.restore.source_options.includeArchive,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.restore.source_options.includeArchive,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.restore.source_options,
                            "includeArchive",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.restore.source_options,
                            "includeArchive",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(
                        _vm.restore.source_options,
                        "includeArchive",
                        $$c
                      )
                    }
                  },
                },
              }),
              _c("label", [
                _c("span", { staticClass: "mif-email mr-2" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("Include Archive")))]),
              ]),
            ]),
            _c("p", { staticClass: "w-50" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.restore.source_options.includePublicFolders,
                    expression: "restore.source_options.includePublicFolders",
                  },
                ],
                attrs: { "data-role": "checkbox", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(
                    _vm.restore.source_options.includePublicFolders
                  )
                    ? _vm._i(
                        _vm.restore.source_options.includePublicFolders,
                        null
                      ) > -1
                    : _vm.restore.source_options.includePublicFolders,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.restore.source_options.includePublicFolders,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.restore.source_options,
                            "includePublicFolders",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.restore.source_options,
                            "includePublicFolders",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(
                        _vm.restore.source_options,
                        "includePublicFolders",
                        $$c
                      )
                    }
                  },
                },
              }),
              _c("label", [
                _c("span", { staticClass: "mif-email mr-2" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("Include Public Folders")))]),
              ]),
            ]),
            _c("p", { staticClass: "w-50" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.restore.source_options.includeCalendar,
                    expression: "restore.source_options.includeCalendar",
                  },
                ],
                attrs: { "data-role": "checkbox", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(
                    _vm.restore.source_options.includeCalendar
                  )
                    ? _vm._i(_vm.restore.source_options.includeCalendar, null) >
                      -1
                    : _vm.restore.source_options.includeCalendar,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.restore.source_options.includeCalendar,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.restore.source_options,
                            "includeCalendar",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.restore.source_options,
                            "includeCalendar",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(
                        _vm.restore.source_options,
                        "includeCalendar",
                        $$c
                      )
                    }
                  },
                },
              }),
              _c("label", [
                _c("span", { staticClass: "mif-calendar mr-2" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("Include Calendar")))]),
              ]),
            ]),
            _c("p", { staticClass: "w-50" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.restore.source_options.includeContacts,
                    expression: "restore.source_options.includeContacts",
                  },
                ],
                attrs: { "data-role": "checkbox", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(
                    _vm.restore.source_options.includeContacts
                  )
                    ? _vm._i(_vm.restore.source_options.includeContacts, null) >
                      -1
                    : _vm.restore.source_options.includeContacts,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.restore.source_options.includeContacts,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.restore.source_options,
                            "includeContacts",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.restore.source_options,
                            "includeContacts",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(
                        _vm.restore.source_options,
                        "includeContacts",
                        $$c
                      )
                    }
                  },
                },
              }),
              _c("label", [
                _c("span", { staticClass: "mif-contacts-dialer mr-2" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("Include Contacts")))]),
              ]),
            ]),
            _c("p", { staticClass: "w-50" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.restore.source_options.includeTasks,
                    expression: "restore.source_options.includeTasks",
                  },
                ],
                attrs: { "data-role": "checkbox", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(
                    _vm.restore.source_options.includeTasks
                  )
                    ? _vm._i(_vm.restore.source_options.includeTasks, null) > -1
                    : _vm.restore.source_options.includeTasks,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.restore.source_options.includeTasks,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.restore.source_options,
                            "includeTasks",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.restore.source_options,
                            "includeTasks",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.restore.source_options, "includeTasks", $$c)
                    }
                  },
                },
              }),
              _c("label", [
                _c("span", { staticClass: "mif-add_task mr-2" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("Include Tasks")))]),
              ]),
            ]),
          ]),
          _vm.restore.destination_type !=
          _vm.$PlatformsTypes.Microsoft_Exchange365
            ? _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.restore.source_options.truncateExchangeLogs,
                      expression: "restore.source_options.truncateExchangeLogs",
                    },
                  ],
                  attrs: {
                    "data-role": "checkbox",
                    type: "checkbox",
                    "data-caption": _vm.$t("Truncate Exchange log"),
                    "data-validate": "required",
                    "data-prepend": "<span class='mif-server'></span>",
                  },
                  domProps: {
                    checked: Array.isArray(
                      _vm.restore.source_options.truncateExchangeLogs
                    )
                      ? _vm._i(
                          _vm.restore.source_options.truncateExchangeLogs,
                          null
                        ) > -1
                      : _vm.restore.source_options.truncateExchangeLogs,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.restore.source_options.truncateExchangeLogs,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.restore.source_options,
                              "truncateExchangeLogs",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.restore.source_options,
                              "truncateExchangeLogs",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.restore.source_options,
                          "truncateExchangeLogs",
                          $$c
                        )
                      }
                    },
                  },
                }),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "form-group row d-flex flex-justify-between" },
            [
              _c("label", [
                _vm._v(_vm._s(_vm.$t("Number of main threads")) + ":"),
              ]),
              _c("input", {
                directives: [
                  { name: "number", rawName: "v-number" },
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.restore.source_options.nThreads,
                    expression: "restore.source_options.nThreads",
                    modifiers: { number: true },
                  },
                  {
                    name: "range",
                    rawName: "v-range",
                    value: { min: 1, max: 20 },
                    expression: "{min:1, max: 20}",
                  },
                ],
                staticClass: "input w-25",
                attrs: { type: "number" },
                domProps: { value: _vm.restore.source_options.nThreads },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.restore.source_options,
                      "nThreads",
                      _vm._n($event.target.value)
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]
          ),
          _c("div", { staticClass: "form-group pt-4" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("Buffer size")) + ":")]),
            _c("input", {
              directives: [
                { name: "number", rawName: "v-number" },
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.restore.source_options.buffer,
                  expression: "restore.source_options.buffer",
                  modifiers: { number: true },
                },
                {
                  name: "range",
                  rawName: "v-range",
                  value: { min: 150, max: 1500 },
                  expression: "{min:150, max:1500}",
                },
              ],
              staticClass: "input w-25",
              attrs: { type: "number" },
              domProps: { value: _vm.restore.source_options.buffer },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.restore.source_options,
                    "buffer",
                    _vm._n($event.target.value)
                  )
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == _vm.STEP_DESTINATION,
              expression: "step == STEP_DESTINATION",
            },
          ],
          staticClass: "dialog-content",
        },
        [
          _c("div", { staticClass: "row h6 text-bold m-4" }, [
            _c("div", { staticClass: "cell-12" }, [
              _vm._v(_vm._s(_vm.$t("Configure destination"))),
            ]),
          ]),
          _c("SARepoSelect", {
            attrs: {
              type: _vm.restore.type,
              idSA: _vm.restore.destination_idSA,
              idRepo: -2,
            },
            on: { sendSATestResult: _vm.sendSATestResult },
          }),
          _vm.destinationData.client != null
            ? _c("SelectedResourcesLayout", {
                attrs: {
                  list: _vm.destinationData.selectedNodes,
                  singleItem: false,
                  canCancel: true,
                  buttonText: _vm.$t("Select the mailboxes to restore"),
                  type: _vm.restore.type,
                  heightPercentage: 100,
                },
                on: {
                  clickFunction: function ($event) {
                    return _vm.toggleFileSystemSidebar("SELECTMAILBOXES")
                  },
                },
              })
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.destinationData.client != null,
                  expression: "destinationData.client != null",
                },
              ],
              staticClass: "form-group row pb-5 w-100",
            },
            [
              _c("label", [_vm._v(_vm._s(_vm.$t("Custom folder")) + ":")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.restore.destination_customFolder,
                    expression: "restore.destination_customFolder",
                  },
                  {
                    name: "path",
                    rawName: "v-path",
                    value: _vm.$PlatformsTypes.Microsoft_Exchange365,
                    expression: "$PlatformsTypes.Microsoft_Exchange365",
                  },
                ],
                staticClass: "metro-input",
                attrs: {
                  "data-role": "input",
                  type: "text",
                  placeholder: _vm.$t(
                    "Enter the name of the customised folder"
                  ),
                },
                domProps: { value: _vm.restore.destination_customFolder },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.restore,
                      "destination_customFolder",
                      $event.target.value
                    )
                  },
                },
              }),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(_vm._s(_vm.$t("Required"))),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "dialog-actions" }, [
        _vm.waiting == -1
          ? _c("div", [
              _c("span", {
                staticClass: "mif-spinner2 fg-primary ani-spin ml-5",
              }),
            ])
          : _vm._e(),
        _vm.step != 1 ||
        _vm.restore.source_type != _vm.$PlatformsTypes.FileFolder
          ? _c("input", {
              staticClass: "button primary ml-2",
              attrs: {
                disabled: _vm.step == _vm.STEP_SELECTBACKUPCOPY,
                type: "button",
                value: _vm.$t("Prev"),
              },
              on: {
                click: function ($event) {
                  return _vm.setStep(_vm.step - 1)
                },
              },
            })
          : _vm._e(),
        _c("input", {
          staticClass: "button primary ml-2",
          attrs: {
            type: "button",
            value: _vm.$t("Next"),
            disabled: !_vm.enableNextButton,
          },
          on: {
            click: function ($event) {
              return _vm.setStep(_vm.step + 1)
            },
          },
        }),
        _c("input", {
          staticClass: "button primary ml-2",
          attrs: {
            type: "button",
            value: _vm.$t("Start"),
            disabled:
              _vm.step != _vm.STEP_DESTINATION ||
              _vm.destinationData.selectedNodes.length == 0 ||
              _vm.restore.destination_customFolder == "",
          },
          on: { click: _vm.startRestore },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }