import Navigation from "../components/utils/Navigation";
export default {
    components: {

        Navigation,

    },
    name: "Settings",
    data() {
        return {
            userSettingsNotifications: {
                backupNotification: [false,false,false],
                hardwareNotification: [false,false,false],
                systemNotification:  [false,false,false],
                emailbackupNotification:[],
                emailhardwareNotification:[],
                emailsystemNotification:[]
            },  
        };
    },
    props: {
        msg: String
    },
    created: function() {
        this.$session.setRoomPC(null);
        this.$root.socket("SETPC", null);
    },
    methods: {
        saveSettings: function() {

            this.userSettingsNotifications.emailbackupNotification = $("#emailbackupNotification").data('taginput').val();
            this.userSettingsNotifications.emailsystemNotification = $("#emailsystemNotification").data('taginput').val();
            this.userSettingsNotifications.emailhardwareNotification = $("#emailhardwareNotification").data('taginput').val();
            //qui faccio un test per capire se le mail inserite sono corrette sennò non salvo...

            //fine check
            this.$session.saveSettings(this.userSettingsNotifications);
        },
        getSettings: function() {
            this.userSettingsNotifications = this.$session.getSettings();
        }
    }
}