<template>
   <div class="" v-if="!$root.connectionsStates[pc.id]" >
        <div class="pt-2 text-center">
            <span class="mif-warning mif-2x primary text-muted"></span><br>
            <h6 class="m-0 mt-3 text-muted">{{$t("Device is not connected")}}</h6>
        </div>
    </div> 
</template>
<script>
export default {
    name : "NoConnection",
    props : {
        pc : {
            type : Object,
            required : true
        }
    }
}
</script>