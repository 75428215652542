<template>
    <div class="dialog light center text-small w-100-sm w-100-md w-75-lg w-50-xl h-vh-75" v-cloak>

        <DialogTitle :title="$t('Configure a restore')" :defaultTitle="$t('Configure a restore')"
            :subTitle="restore.destination.type_name" @closeDialog="$root.$emit('CLOSERESTOREDIALOG')" />

        <!---------------------SERVICE ACCOUNT DELLA SORGENTE------------------------------------------------->
        <div v-if="step == STEP_SELECTBACKUPCOPY" class="dialog-content">
            <div v-if="isManual" class="row h6 text-bold m-4">
                <div class="cell-12">{{ $t("Choose source type") }}</div>
            </div>

            <div v-if="isManual" class="form-group">
                <ul data-role="listview" data-view="icons" data-select-node="true" data-selectable="false">
                    <!--Le destinazioni del backup diventano le sorgenti del restore-->
                    <li v-for="sourceType in $platformsTypesInfo[restore.type].backupDestinationTypes"
                        @click="setSourceType(sourceType)" :data-icon="$platformsTypesInfo[sourceType].icon"
                        :data-caption="$platformsTypesInfo[sourceType].name" :key="sourceType" />
                </ul>
            </div>

            <!------------------------------SCELTA DEL SERVICE ACCOUNT--------------------------------------------------------------->
            <!--Questa pagina deve implementare il metodo "sendSATestResult"-->
            <SARepoSelect v-if="![-1, $PlatformsTypes.FileFolder].includes(restore.source.type)"
                :type=restore.source.type :idSA='restore.source.id_service_account' :idRepo="-2"
                @sendSATestResult="sendSATestResult" />

            <!-------------------SCELTA PERCORSO CARTELLA COPIA BACKUP----------------------------------------------->
            <SelectedResourcesLayout v-if="isManual" :list="sourceData.selectedNodes" :singleItem="true"
                :canCancel="false" :buttonText="$t('Select the folder of a backup copy')"  :type="restore.source.type"
                :isDisabled="restore.source.type != $PlatformsTypes.FileFolder && sourceData.client == null"
                :heightPercentage="30" @clickFunction="toggleFileSystemSidebar('CHOOSEBACKUPCOPY')" />

        </div>

        <!--INFORMAZIONI INIZIALI SUL RESTORE-->
        <div v-if="step == STEP_SELECTRESTOREOPTIONS" class="dialog-content">

            <!-----------------------------RESTORETYPE: COSA SI VUOLE RECUPERARE?--------------------------------->
            <div class="form-group">
                <h6 class="text-bold">{{ $t("What is to be recovered?") }}:</h6>
                <select class="select w-100" v-model="restore.restoreType">
                    <!-- 14/10/2024 : Per il momento, se il backup ha la compressione zip, non è possibile ripristinare singoli elementi-->
                    <option :value="RESTORETYPE_SELECTSINGLEITEMS" :disabled="restore.type.isMicrosoft() || isBackupWithZip">{{ $t("Single elements") }}</option>
                    <option :value="RESTORETYPE_BUILDFULLCOPY">{{ $t("Recreate full copy from backup") }}</option>
                    <option :value="RESTORETYPE_BUILDSPECIFICCOPY">{{ $t("Recreate a specific copy from backup") }}
                    </option>
                </select>
            </div>

            <!-------------------RESTORE DA UN BACKUP - SCELTA DELLE RISORSE----------------------------->
            <SelectedResourcesLayout v-if="restore.restoreType == RESTORETYPE_SELECTSINGLEITEMS"
                :list="restore.source.paths" :singleItem="false" :canCancel="true" :type="restore.source.type"
                :buttonText="$t('Select files and folders')" :isDisabled="iterationFolder == null"
                :heightPercentage="30" @clickFunction="toggleFileSystemSidebar('SELECTSINGLEITEMS')" />

            <!-------------------RESTORE DA UN BACKUP - SCELTA DELLA DATA DALLA QUALE ESEGUIRE IL RESTORE----------------------------->
            <div v-else-if="restore.restoreType > RESTORETYPE_SELECTSINGLEITEMS" class="form-group">

                <h6 class="text-bold">{{ $t("Select a copy") }}</h6>
                <div class="overflow">
                    <div v-for="iteration in fileConfig.iterations" :key="iteration.index" class="d-flex mb-4">
                        <input name="iter" type='radio' data-role='radio' class="mr-5"
                            @click="onIterationChanged(iteration)" :checked="restore.index == iteration.index">
                        <div class="pr-4">
                            <div class="d-flex flex-justify-between w-100 pr-3">
                                <div>
                                    <span class='badge inline bg-cobalt fg-white p-1'> {{ $platformsTypesInfo[iteration.sourceType].name }} </span>
                                    <span class="mif-arrow-right mx-1" />
                                    <span class='badge inline bg-cobalt fg-white p-1'> {{ $platformsTypesInfo[iteration.destinationType].name }} </span>
                                </div>

                                <div class="text-bold"><label class="mr-2">{{ $t("Copy number") }}:</label>{{ (iteration.index) + " / " + (iteration.nCopies) }}</div>
                            </div>
                            <div>
                                <small><b>{{ $t("Backup folder") }} : </b> {{ iteration.iterationFolder }}</small>
                            </div>
                            <small class="text-bold"><label class="mr-2">{{ $t("Start date") }}:</label>{{ iteration.lastStart | utcAsLocal | moment("LLLL") }}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--------------------------------------------FILTRI----------------------------------------------------->
        <div v-show="step == STEP_FILTERS" class="dialog-content">
            <!--FILTRI - ESTENSIONI-->
            <div class="form-group">
                <h6 class="bg-light px-2 py-2 text-bold">
                    <span>{{ $t('Filter by extensions') }} ({{ $t('Optional') }})</span>
                </h6>

                <div class="d-flex flex-column">
				    <input name='includeExcludeExtensions' type='radio' data-role='radio' :data-caption="$t('Exclude specified extensions')" v-model="restore.source.options.extensionsFilterAction" :value="0">
				    <input name='includeExcludeExtensions' type='radio' data-role='radio' :data-caption="$t('Include only specified extensions')" v-model="restore.source.options.extensionsFilterAction" :value="1">
			    </div>

                <div class="cell-12">
                    <label class="text-bold">{{ $t("Extensions :") }}</label>
                    <select v-model="specifiedExtensionsArray" multiple data-role="select" class="input">
                        <optgroup v-for="(extensionsList, extensionsCategory) in fileExtensions" :label="$t(extensionsCategory)" :key="extensionsCategory">
                                <option v-for="extension in extensionsList" :value="extension">{{ extension }}</option>
                            </optgroup>
                    </select>
                </div>
            </div>

            <!--FILTRI - DIMENSIONE FILE-->
            <div class="form-group row">
                <div>
                    <input type="checkbox" v-model="restore.source.options.useFileSizeFilter"
                        :data-caption="$t('File size filter') +'(' + $t('Optional') + ')'" data-role="checkbox" />
                </div>
                <div class="w-100 d-flex flex-align-center">
                    <select v-model="restore.source.options.fileSizeFilterAction"
                        :disabled="!restore.source.options.useFileSizeFilter" class="mr-2 h-100">
                        <option v-for="(value, index) in $root.selectValues.fileSizeFilterAction" :value="index"
                            v-bind:key="index">{{ $t(value) }}</option>
                    </select>

                    <select v-model="restore.source.options.fileSizeFilterCompare"
                        :disabled="!restore.source.options.useFileSizeFilter" class="mr-2 h-100">
                        <option v-for="(value, index) in $root.selectValues.fileSizeFilterCompare" :value="index"
                            v-bind:key="index">{{
                                $t(value)
                            }}</option>
                    </select>

                    <input type="number" v-number v-range="{ min: 1, max: 999999 }" v-model.number="restore.source.options.fileSize"
                        :disabled="!restore.source.options.useFileSizeFilter" class="input input-small mr-2" min="1" style="width: 100px;" />
                    <span> MB</span>
                </div>
            </div>
        </div>

        <!---------------------------------------------ESCLUSIONI--------------------------------------------------------------->
        <div v-if="step == STEP_EXCLUSIONS" class="dialog-content">
            <div class="row h6 text-bold m-4">
                <div class="cell-12">{{ $t('Exclusions') }}</div>
            </div>

            <div class="form-group text-bold">{{ $t("Click on an item to select subfolders or files to exclude") }}
            </div>
            <!--VISUALIZZAZIONE DEGLI ELEMENTI SELEZIONATI-->
            <div class="form-group overflow h-vh-20 bd-black border border-radius-10 border-size-1 mx-7">
                <table class=" d-table-lg striped table text-small">
                    <tbody>
                        <tr v-for="res in restore.source.paths" @click="openFileSystemSidebarFrom(res)"
                            v-bind:key="res.id">
                            <td class="d-flex">
                                <span :class="res.type.getMetroIcon(res.path)" class="px-2" />
                                <span class="flex-self-center">{{ res.path }}</span>
                            </td>
                            <td><span class='mif-bin' @click="$root.$emit('uncheckNode', res)" /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="form-group text-bold mt-6">{{ $t("Excluded paths:") }}</div>

            <NoDataAvailable v-if="restore.source.options.excludedPaths.length == 0"
                :message="$t('No excluded path selected')" />
            <!--VISUALIZZAZIONE DEGLI ELEMENTI-->
            <div v-else class="form-group overflow mt-4 h-vh-20 bd-black border border-radius-10 border-size-1">
                <table class="d-table-lg striped table text-small">
                    <tbody>
                        <tr v-for="res in restore.source.options.excludedPaths" :title="res.path" v-bind:key="res.id">
                            <td class="d-flex">
                                <span class="px-2" :class="res.type.getMetroIcon(res.path)" />
                                <span lass="flex-self-center">{{ cutText(res.path, 50) }}</span>
                            </td>
                            <td><span class='mif-bin' @click="$root.$emit('uncheckNode', res)" /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="form-group text-small flex-left">
                <input type="checkbox" v-model="restore.source.options.excludeSubFolders" />
                <label> {{ $t("Do not include any subfolders (only files in the root folder will be copied)") }}</label>
            </div>
        </div>

        <!--DESTINAZIONE-->
        <!--
        FileFolder = DOVE RIPRISTINARE + PERCORSO DELLA CARTELLA DOVE RIPRISTINARE
        Network = SERVICEACCOUNT + DOVE RIPRISTINARE + PERCORSO DELLA CARTELLA DOVE RIPRISTINARE
        Onedrive = SERVICEACCOUNT + LISTA + DOVE RIPRISTINARE + NOME DELLA CARTELLA DOVE RIPRISTINARE
        Sharepoint = SERVICEACCOUNT + LISTA + DOVE RIPRISTINARE + NOME DELLA CARTELLA DOVE RIPRISTINARE
        Teams = SERVICEACCOUNT + LISTA
        
        -->
        <div v-if="step == STEP_DESTINATION" class="dialog-content">
            <div class="row h6 text-bold m-4">
                <div class="cell-12">{{ $t('Configure destination') }}</div>
            </div>

            <!------------------------------DESTINAZIONE : SCELTA DEL SERVICE ACCOUNT--------------------------------------------------------------->
            <!--Questa pagina deve implementare sendSATestResult-->
            <SARepoSelect v-if="restore.type != $PlatformsTypes.FileFolder" :type="restore.type.isMicrosoft365() ? $root.getMicrosoftTypes() : restore.type"
                :idSA=restore.destination.id_service_account :idRepo="-2" @sendSATestResult="sendSATestResult" />

            <!------------------------------DESTINAZIONE : LISTA DI ACCOUNT/SITI/TEAMS/UTENTI DA RESTORARE--------------------------------------------------------------->
            <SelectedResourcesLayout v-if="restore.type.isMicrosoft365()" :list="destinationData.selectedNodes" :singleItem="false" :canCancel="true"
                :buttonText="destinationData.buttonText" :isDisabled="destinationData.client == null" :type="restore.type"
                :heightPercentage="30" @clickFunction="toggleFileSystemSidebar('SELECTITEMSTORESTORE')" />

            <!------------------------------DESTINAZIONE : DOVE RIPRISTINARE--------------------------------------------------------------->
            <div v-if="restore.type != $PlatformsTypes.Microsoft_Teams" class="form-group d-flex flex-column">
                <input type="radio" :data-caption="$t('Restores items to their original position')"
                    @click="restore.source.options.restoreInOriginalPosition = true" data-role="radio" name="r1"
                    :disabled="restore.type != $PlatformsTypes.FileFolder && destinationData.client == null" checked>
                <input type="radio" :data-caption="$t('Restores items to the following folder')"
                    @click="restore.source.options.restoreInOriginalPosition = false" data-role="radio" name="r1"
                    :disabled="restore.type != $PlatformsTypes.FileFolder && destinationData.client == null">
            </div>

            <!------------------------------DESTINAZIONE : NOME DELLA CARTELLA DOVE RIPRISTINARE--------------------------------------------------------------->
            <div v-show="[$PlatformsTypes.Microsoft_OneDriveForBusiness, $PlatformsTypes.Microsoft_SharePoint].includes(restore.type)" 
            class="form-group row pb-5 w-100" 
            :disabled="restore.source.options.restoreInOriginalPosition">
                <label>{{ $t("Custom folder") }}:</label>
                <input data-role="input" class="metro-input" type="text"
                    v-model="restore.source.options.restoreCustomFolder"
                    :placeholder="$t('Enter the name of the customised folder')" v-fsitem/>
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>

            <!------------------------------DESTINAZIONE : PERCORSO DELLA CARTELLA DOVE RIPRISTINARE--------------------------------------------------------------->
            <SelectedResourcesLayout v-if="[$PlatformsTypes.FileFolder, $PlatformsTypes.Network].includes(restore.type)" :list="destinationData.selectedNodes" :singleItem="true" :canCancel="true"
                :buttonText="destinationData.buttonText" :type="restore.type"
                :isDisabled="restore.source.options.restoreInOriginalPosition || (restore.type == $PlatformsTypes.Network && destinationData.client == null)"
                :heightPercentage="30" @clickFunction="toggleFileSystemSidebar('SELECTDESTINATIONPATH')" />

        </div>

    
        <!-------------------------------TEST ACCOUNT----------------------------------------->
        <div class="dialog-actions">
            <div v-if="waiting">
                <span class="mif-spinner2 fg-primary ani-spin ml-5" />
            </div>
            <input
                v-if="step != STEP_SELECTBACKUPCOPY || restore.source.type != $PlatformsTypes.FileFolder"
                :disabled="step == STEP_SELECTBACKUPCOPY" type="button" v-on:click="setStep(step - 1)"
                :value="$t('Prev')" class="button primary ml-2" />
            <input type="button" @click="setStep(step + 1)" :value="$t('Next')" class="button primary ml-2"
                :disabled="!enableNextButton" />
            <input type="button" @click="startRestore" :value="$t('Start')" class="button primary ml-2"
                :disabled="!enableStartButton" />
        </div>
    </div>
</template>
<script>
import DialogTitle from "../../utils/DialogTitle.vue";
import { isValid, cutText, getNodeType } from "../../../utils/utilitiesmodule";
import NoDataAvailable from "../../utils/NoDataAvailable.vue";
import SARepoSelect from "../../utils/SARepoSelect.vue";
import SelectedResourcesLayout from "../../utils/SelectedResourcesLayout.vue";
import { ClientTreeNode, FileSystemSidebarConfig } from "../../../models/TreeNode";
import { AzureClient } from "../../../utils/serviceAccountsUtils/clients/azureClient";
import { fileExtensions } from "../../../utils/objects";

export default {
    name: "RestoreFile",
    props: {
        restore: Object,
    },
    components: {
        "DialogTitle": DialogTitle,
        "NoDataAvailable": NoDataAvailable,
        SelectedResourcesLayout,
        SARepoSelect
    },
    data() {
        return {
            step: 0,
            STEP_SELECTBACKUPCOPY: 0,
            STEP_SELECTRESTOREOPTIONS: 1,
            STEP_FILTERS: 2,
            STEP_EXCLUSIONS: 3,
            STEP_DESTINATION: 4,



            RESTORETYPE_SELECTSINGLEITEMS: 0,
            RESTORETYPE_BUILDFULLCOPY: 1,
            RESTORETYPE_BUILDSPECIFICCOPY: 2,
            sourceData: {
                serviceAccount: null,
                client: null,
                filteredServiceAccounts: [],
                selectedNodes: [], //appoggio per la copia di backup scelta
            },

            destinationData: {
                serviceAccount: null,
                client: null,
                filteredServiceAccounts: [],
                filteredRepositories: [],
                selectedNodes: [],
                buttonText: null
            },

            waiting: false,

            fileConfig: null,

            isManual: true,

            str1: "Choose account from credential locker or use Custom credentials",
            microsoftTree: null,

            isBackupWithZip: false,
            fileExtensions:fileExtensions,
            specifiedExtensionsArray:[],

        }
    },
    computed: {
        iterationFolder: function() {
            if (this.sourceData.selectedNodes.length==1)
                return this.sourceData.selectedNodes[0];
            return null;
        },
        enableNextButton: function() {
            if (this.waiting)
                return false;
            switch (this.step) {
                case this.STEP_SELECTBACKUPCOPY:
                    //Nel primo step se il restore è manuale sono obbligato a:
                    // 1 - Selezionare il tipo della sorgente
                    // 2 - Se il tipo della sorgente necessita di un service account, selezionare un serviceaccount e testarlo
                    // 3 - selezionare una cartella di una copia
                    if (
                        this.isManual&&
                        this.restore.source.type!=-1&&
                        (this.restore.source.type==this.$PlatformsTypes.FileFolder||this.sourceData.client!=null)&&
                        this.iterationFolder!=null
                    ) {
                        return true;
                    }

                    //Nel primo step, se il restore è automatico sono obbligato a selezionare un service account e a testarlo se necessario
                    if (
                        !this.isManual&&
                        (this.restore.source.type==this.$PlatformsTypes.FileFolder||this.sourceData.client!=null)
                    ) {
                        return true;
                    }
                    return false;

                case this.STEP_SELECTRESTOREOPTIONS:

                    return this.restore.restoreType!=-1&&
                        (
                            (this.restore.restoreType==this.RESTORETYPE_SELECTSINGLEITEMS&&this.restore.source.paths.length>0)||
                            (this.restore.restoreType>this.RESTORETYPE_SELECTSINGLEITEMS&&this.restore.index!=-1)

                        );

                case this.STEP_DESTINATION:
                    return false;
            }

            return true;
        },

        enableStartButton: function() {

            if (this.step!=this.STEP_DESTINATION)
                return false;

            if(this.waiting)
                return false;

            var okDestinationSA=this.restore.type==this.$PlatformsTypes.FileFolder||this.destinationData.client!=null;
            var okItems=!this.restore.type.isMicrosoft365()||this.destinationData.selectedNodes.length!=0;
            var okModeToRestore=this.restore.type==this.$PlatformsTypes.Microsoft_Teams||
                this.restore.source.options.restoreInOriginalPosition||
                (!this.restore.source.options.restoreInOriginalPosition&&this.restore.type.isMicrosoft365()&&isValid(this.restore.source.options.restoreCustomFolder))||
                (!this.restore.source.options.restoreInOriginalPosition&&!this.restore.type.isMicrosoft365()&&this.destinationData.selectedNodes.length!=0);

            return okDestinationSA&&okItems&&okModeToRestore;
        }
    },
    created: async function() {

        //Se sto creando un restore manuale, idBackup è vuoto
        this.isManual=!isValid(this.restore.idBackup);

        //Se è un restore automatico da una sorgente filefolder, non mi serve nulla del primo step perchè non ho bisogno del serviceaccount
        this.step=!this.isManual&&this.restore.source.type==this.$PlatformsTypes.FileFolder
            ? this.STEP_SELECTRESTOREOPTIONS
            :this.STEP_SELECTBACKUPCOPY;

        if (!this.isManual) {
            await this.buildFileConfigFromIDBackup();
        }


        if (this.restore.type==this.$PlatformsTypes.Microsoft_OneDriveForBusiness)
            this.destinationData.buttonText=this.$t('Select the accounts to be restore');
        else if (this.restore.type==this.$PlatformsTypes.Microsoft_Teams) {
            this.destinationData.buttonText=this.$t('Select the teams and channels to be restore');
            this.restore.source.options.restoreInOriginalPosition=true
        } else if (this.restore.type==this.$PlatformsTypes.Microsoft_SharePoint)
            this.destinationData.buttonText=this.$t('Select the sites to be restore');
        else
            this.destinationData.buttonText=this.$t('Select a path');

    },

    beforeDestroy() {
        this.$root.$off("socketEventConnected");
        this.$root.$off("socketEventDisconnected");
        var method;
        //In caso di ftp o network, eseguo la disconnessione
        if (this.sourceData.client!=null&&[this.$PlatformsTypes.FTP, this.$PlatformsTypes.Network].includes(this.restore.source.type)) {
            method=this.restore.source.type==this.$PlatformsTypes.FTP? "disconnectftp":"disconnectnetwork";
            this.$root.socket(method, this.sourceData.client.serviceAccount.accountSessionId);
        }
    },
    methods: {
        /**********STEP 1 : SORGENTE - SCELTA DEL SERVICE ACCOUNT + TEST SERVICE ACCOUNT************************************************************** */
        setSourceType(type) {
            this.restore.source.type=type;
            this.restore.source.type_name=this.$platformsTypesInfo[type].name;
            this.restore.source.id_service_account=-2;
            this.sourceData.client=null;
            this.sourceData.serviceAccount=null;
        },
        async sendSATestResult(testResult) {
            let self=this;

            if (self.step==this.STEP_SELECTBACKUPCOPY) {
                self.restore.source.id_service_account=testResult.serviceAccount.id;
                self.sourceData.serviceAccount=testResult.serviceAccount;
                self.sourceData.client=testResult.client;
                //Finito il test, resetto accountSessionId se il restore è di tipo ftp o network
                //if ([2, $PlatformsTypes.Network].includes(self.restore.source.type))
                //    self.sourceData.client.serviceAccount.options.accountSessionId = "";
                return;
            }

            if (self.step==this.STEP_DESTINATION) {
                self.restore.destination.id_service_account=testResult.serviceAccount.id;
                self.destinationData.serviceAccount=testResult.serviceAccount;
                self.destinationData.client=testResult.client;
                //Finito il test, resetto accountSessionId se la destinazone del restore è di tipo ftp o network
                //if ([2, $PlatformsTypes.Network].includes(self.restore.type))
                //    self.destinationData.client.serviceAccount.options.accountSessionId = "";
                //self.toggleFileSystemSidebar();
                return;
            }
        },

        async buildFileConfigFromIDBackup() {
            //Step 1 : Prendo i logs del job
            /*
            Array di :
             {
                "account": "Veronica",
                "backup_number": 5,
                "backup_size": 32,
                "copied_files": 6,
                "dt_end_utc": "2024-10-07T07:45:23Z",
                "dt_ins": "2024-10-07T07:45:21Z",
                "dt_start_utc": "2024-10-07T07:44:44Z",
                "elevated": 0,
                "execution_type": 1,
                "failed_files": 0,
                "id": "0a9a0d40-3727-4c4f-b084-195a4e1d1b56",
                "id_job": "692aaf1f-7f0f-4ca1-865a-2491c3576726",
                "logfile_hash": null,
                "logfile_name": "C:\\ProgramData\\IperiusONE\\Logs\\692aaf1f-7f0f-4ca1-865a-2491c3576726\\Log_20241007094525.html",
                "partial_log": null,
                "pid": 0,
                "result": 1,
                "status": 1,
                "version_agent": "0.1.0",
                "vss": 0
            }
            
            */
            var logs=(await this.$api.getBackupLogs(this.restore.idBackup)).filter(log => [1, 2].includes(log.result));
            //Step 2 : Ordino i log dal più recente al meno recente
            logs.sort((a, b) => {
                var dateA=this.$moment(a.dt_start_utc.substr(0, 8)+"T"+a.dt_start_utc.substr(8), "YYYYMMDD HH:mm:ss");
                var dateB=this.$moment(b.dt_start_utc.substr(0, 8)+"T"+b.dt_start_utc.substr(8), "YYYYMMDD HH:mm:ss");
                if (dateA>dateB)
                    return -1;
                return 1;
            });
            //Step 3 : Costruisco il file di config prendendo i [nCopies] logs più recenti

            var nCopies=1; //All'inizio non conosco il numero di copie, quidni lo imposto ad 1 per far partire il for 
            var currentConfiguration;
            var currentIterationFolder;
            var slash;
            for (var c=0; c<nCopies; c++) {
                if (c>=logs.length)
                    break;
                currentConfiguration=await this.$api.getBackupLogConfiguration(logs[c].id);
                //Al primo log,
                if (c==0) {
                    //inizio a costruire il fileConfig
                    this.fileConfig={
                        id: this.restore.idBackup,
                        currentIndex: logs[0].backup_number,
                        nCopies: parseInt(currentConfiguration.sources[0].options.nCopies.toString()),
                        iterations: []
                    };
                    this.isBackupWithZip=currentConfiguration.sources[0].options.useArchiveCompression.toString().toUpperCase()=="TRUE";
                    slash=currentConfiguration.destinations[0].path.getSeparator();
                    //imposto il numero di copie
                    nCopies=["F", "U"].includes(currentConfiguration.sources[0].options.backupType)
                        ? this.fileConfig.nCopies
                        :this.fileConfig.nCopies+1;
                    //popolo dei dati del restore che prima non conoscevo
                    this.restore.source.paths.push(new ClientTreeNode(null, null, currentConfiguration.destinations[0].path+slash+this.restore.backupName, getNodeType(currentConfiguration.destinations[0].type, false, false)));

                    this.sourceData.selectedNodes.push(this.restore.source.paths[0]);
                }

                if (this.fileConfig.iterations.length!=0&&this.fileConfig.iterations.some(iter => iter.index==logs[c].backup_number))
                    continue;

                if (logs[c].backup_number==0)
                    currentIterationFolder=currentConfiguration.destinations[0].path+slash+this.restore.backupName+slash+"FULL_"+this.restore.idBackup;
                else
                    currentIterationFolder=currentConfiguration.destinations[0].path+slash+this.restore.backupName+slash+logs[c].backup_number.toString().padStart(3, "0")+"_"+this.restore.idBackup;

                this.fileConfig.iterations.push({
                    index: logs[c].backup_number,
                    name: this.restore.backupName,
                    result: logs[c].result==1,
                    lastStart: this.$moment(logs[c].dt_start_utc.substr(0, 8)+"T"+logs[c].dt_start_utc.substr(8), "YYYYMMDD HH:mm:ss"),
                    iterationFolder: currentIterationFolder,
                    nCopies: parseInt(currentConfiguration.sources[0].options.nCopies.toString()),
                    sourceType: currentConfiguration.sources[0].type,
                    destinationType: currentConfiguration.destinations[0].type
                });
            }
            this.waiting=false;
        },

        /**********STEP 2 : SCELTA DI COSA SI VUOLE RECUPERARE + SCELTA PERCORSI************************************************************** */
        async toggleFileSystemSidebar(n) {

            switch (n) {
                case 'CHOOSEBACKUPCOPY':
                    this.$root.$emit("closeFileSystemSidebar");
                    this.$root.$emit(
                        "toggleFileSystemSidebar",
                        this.sourceData.selectedNodes,
                        await this._getConfig('CHOOSEBACKUPCOPY')
                    );
                    break;
                case 'SELECTSINGLEITEMS':
                    this.$root.$emit("closeFileSystemSidebar");
                    this.$root.$emit(
                        "toggleFileSystemSidebar",
                        this.restore.source.paths,
                        await this._getConfig(n)
                    );
                    break;
                case 'SELECTITEMSTORESTORE':
                case 'SELECTDESTINATIONPATH':
                    this.$root.$emit("closeFileSystemSidebar");
                    this.$root.$emit(
                        "toggleFileSystemSidebar",
                        this.destinationData.selectedNodes,
                        await this._getConfig(n)
                    );
            }

        },

        cutText(text, range) {
            return cutText(text, range);
        },

        /**
         * {
         *  id:,
         * currentIndex
         * iterations : // array di : 
         *     {
         *         index:
         *         name:
         *         result:
         *         lastStart
         *         iterationFolder
         *         nCopies
         *         sourceType
         *         destinationType
         *     }
         * }
         * 
         */
        async buildFileConfigFromPath() {
            this.$root.$emit("closeFileSystemSidebar");
            this.waiting=true;
            var id_service_account="";
            if (isValid(this.sourceData.serviceAccount)) id_service_account=this.sourceData.serviceAccount.id;
            try {
                this.fileConfig=await this.$root.socket("getconfigfileforrestorefile", id_service_account, this.iterationFolder.path);
                if (isValid(this.fileConfig)) {
                    if ([this.$PlatformsTypes.Microsoft_OneDriveForBusiness, this.$PlatformsTypes.Microsoft_SharePoint, this.$PlatformsTypes.Microsoft_Teams].includes(this.restore.type)) {
                        this.microsoftTree=await this.$root.socket("getTreeFromConfigFile", this.iterationFolder.path, this.restore.type);

                    }
                    this.restore.idBackup=this.fileConfig.id;
                    this.restore.backupName=this.fileConfig.iterations[this.fileConfig.currentIndex].name; //assegno il nome del job al momento dell'ultima iterazione eseguita
                    this.waiting=false;
                    this.step=this.STEP_SELECTRESTOREOPTIONS;
                    return;
                }
            } catch (ex) {
                console.log(ex);
            }
            this.sourceData.selectedNodes.pop();
            this.waiting=false;
        },



        onIterationChanged(iteration) {
            this.restore.index=iteration.index;
            //Se si vuole restorare solamente una copia, aggiungo come unico path della sorgente, il path dell'iterazione selezionata
            if (this.restore.restoreType==this.RESTORETYPE_BUILDSPECIFICCOPY) {
                var iterationFolderType=[this.$PlatformsTypes.FileFolder, this.$PlatformsTypes.Network].includes(this.restore.source.type)
                    ? this.$FileFolderEntityType.FN_Folder
                    : this.restore.source.type.isCloud()
                        ? this.$FileFolderEntityType.Cloud_Folder
                        :this.$FileFolderEntityType.FTP_Folder;
                this.restore.source.paths=[{ path: iteration.iterationFolder.slice(0, -1), type: iterationFolderType }];
            }

        },

        /************************STEP 5 : SCELTA DELLE ESCLUSIONI********************************************************/
        async openFileSystemSidebarFrom(res) {
            //res = new ClientTreeNode(res.nodeID, res.resID, res.path, res.type);
            if (res.isFile()) return;

            var config=await this._getConfig(3);
            config.rootPath=res.path;

            this.$root.$emit(
                "toggleFileSystemSidebar",
                this.restore.source.options.excludedPaths,
                config
            );

        },


        /*****************************STEP 7 : START*********************************************************/
        async startRestore() {
            this.$root.$emit("closeFileSystemSidebar");
            let self=this;
            //I paths sono nodi ma devo salvare solamente la struttura {path:'', type:''}
            /*self.restore.source.paths = self.restore.source.paths.map(n => {
                return {
                    path: n.path,
                    type: n.type
                }
            });*/

            if(this.specifiedExtensionsArray.length!=0){
                self.restore.source.options.specifiedExtensions = this.specifiedExtensionsArray.join(";");
            }
            if (self.restore.source.options.excludedPaths.length==0)
                self.restore.source.options.excludedPaths="";
            else //paths?type|paths?type| ....
                self.restore.source.options.excludedPaths=self.restore.source.options.excludedPaths.map(r => r.toString()).join("|");

            //userID1|userID2|userID3
            //siteID1|siteID2|siteID3
            //teamID1?channelID1|teamID1?channelID2|teamID3|userID1 (il punto interrogativo è creato al momento del list dei channel)
            if (this.restore.type.isMicrosoft365()) {
                var childrenToSelect=this.destinationData.selectedNodes;
                var size=childrenToSelect.length
                var child;
                //I nodi selezionati potrebbero avere figli, quindi seleziono solo tutti i figli (siti, account, channel e utenti di teams)
                for (var i=0; i<size; i++) {
                    child=childrenToSelect[i];
                    if (child.children==null||child.children.length==0)
                        continue;
                    childrenToSelect.push(...child.children);
                    childrenToSelect.splice(i, 1);
                    size=childrenToSelect.length;
                    i--;
                }
                self.restore.destination.id=childrenToSelect.map(r => r.resID).join("|");
                self.restore.destination.path=childrenToSelect.map(r => r.path+"?"+r.type).join("|");

            } else if (this.restore.source.options.restoreInOriginalPosition)
                self.restore.source.options.restoreCustomFolder="";
            else self.restore.source.options.restoreCustomFolder=this.destinationData.selectedNodes[0].path;

            //Se sto effettuando il restore di Microsoft Teams, ho bisogno del token preso tramite interazione dell'utente, per acquisire dei permessi speciali 
            //(creazione teams/channel eliminati)
            if (this.restore.type==this.$PlatformsTypes.Microsoft_Teams) {
                var realLastRoute=sessionStorage.getItem('lastRoute');
                //Alcune richieste token necessitano di un reinderezzamento su /
                sessionStorage.setItem('lastRoute', "/");
                var azureClient=new AzureClient(this.destinationData.serviceAccount);
                var loginWithDeviceCodeResult=await azureClient._getTokensFromAzure();
                sessionStorage.setItem('lastRoute', realLastRoute);

                if (!loginWithDeviceCodeResult)
                    return;
                this.restore.source.options.deviceCodeToken=azureClient.serviceAccount.token;
            }
            this.waiting=true;
            var restoreStarted=await self.$root.socket("restorefile", this.restore);
            this.waiting=false;
            if (!restoreStarted)
                return;

            this.$root.$emit('CLOSERESTOREDIALOG');

        },

        /**********************************GENERALE*********************************************** */
        /**
         * 0 : informazioni iniziali : service account + restoretype
         * 1 : selezione del percorso / percorsi + scelta data
         * 2 : filtri
         * 3 : esclusioni
         * 4 : destinazione
         */
        setStep(nStep) {
            this.$root.$emit("closeFileSystemSidebar");

            switch (nStep) {
                case this.STEP_SELECTRESTOREOPTIONS:

                    // RESTORE MANUALE + NO FILE CONFIG
                    if (this.isManual&&!isValid(this.fileConfig)) {
                        this.buildFileConfigFromPath();
                        return; // lo step verrà impostato solo al momento in cui fileConfig sarà valido
                    }/**/

                    // RESTORE MANUALE + FILE CONFIG + RESTORE SINGLE FILES
                    if (this.isManual&&isValid(this.fileConfig)&&this.restore.restoreType==this.RESTORETYPE_SELECTSINGLEITEMS) {
                        this.step=this.STEP_SELECTRESTOREOPTIONS; // lo imposto prima, perchè nel seguente metodo verrà considetato lo step corrente
                        this.toggleFileSystemSidebar('SELECTSINGLEITEMS')
                        return;
                    }

                    // RESTORE MANUALE + FILE CONFIG + BUILD ITERATION
                    if (this.isManual&&isValid(this.fileConfig)&&this.restore.restoreType!=this.RESTORETYPE_SELECTSINGLEITEMS) {
                        this.step=this.STEP_SELECTRESTOREOPTIONS; // lo imposto prima, perchè nel seguente metodo verrà considetato lo step corrente
                        return;
                    }

                    break;
                case this.STEP_FILTERS:
                    var self=this;

                    if (this.restore.restoreType!=this.RESTORETYPE_SELECTSINGLEITEMS)
                        this.restore.source.paths=[this.iterationFolder];

                    break;
            }

            this.step=nStep;

        },



        async _getConfig(n) {
            var method;

            switch (n) {
                case 'CHOOSEBACKUPCOPY':
                    //Si deve scegliere la cartella di backup
                    //if (this.restore.source.paths.length == 0 || [1, 2].includes(this.restore.restoreType)) {
                    if ([this.$PlatformsTypes.FTP, this.$PlatformsTypes.Network].includes(this.restore.source.type)&&this.sourceData.client.serviceAccount.options.accountSessionId=="") {
                        method=this.restore.source.type==2? "connectftp":"connectnetwork";
                        this.sourceData.client.serviceAccount.options.accountSessionId=await this.$root.socket(method, this.sourceData.client.serviceAccount);
                    }

                    return {
                        type: this.restore.source.type,
                        includeFiles: false,
                        multipleChoice: false,
                        client: this.sourceData.client,
                        rootPath: this.$root.getSARootPath(this.sourceData.client.serviceAccount)
                    };

                //}
                case 'SELECTSINGLEITEMS':

                    //Si vogliono restorare file e cartelle
                    if ([this.$PlatformsTypes.FTP, this.$PlatformsTypes.Network].includes(this.restore.source.type)&&this.sourceData.client.serviceAccount.options.accountSessionId=="") {
                        method=this.restore.source.type==2? "connectftp":"connectnetwork";
                        this.sourceData.client.serviceAccount.options.accountSessionId=await this.$root.socket(method, this.sourceData.client.serviceAccount);
                    }
                    return {
                        type: this.restore.source.type,
                        includeFiles: true, // mostra i file se si vuole restorare degli elementi singoli
                        multipleChoice: true, // si possono selezionare più elementi solo se si vuole restorare degli elementi singoli
                        client: this.sourceData.client,
                        rootPath: this.iterationFolder!=null? this.iterationFolder.path:this.restore.source.paths[0].path,
                    };


                case 3:
                    if ([this.$PlatformsTypes.FTP, this.$PlatformsTypes.Network].includes(this.restore.source.type)&&this.sourceData.client.serviceAccount.options.accountSessionId=="") {
                        method=this.restore.source.type==2? "connectftp":"connectnetwork";
                        this.sourceData.client.serviceAccount.options.accountSessionId=await this.$root.socket(method, this.sourceData.client.serviceAccount);
                    }
                    return {
                        type: this.restore.source.type,
                        includeFiles: true,
                        multipleChoice: true,
                        client: this.sourceData.client,
                    };

                case 'SELECTDESTINATIONPATH':
                case 'SELECTITEMSTORESTORE':
                    if ([this.$PlatformsTypes.FTP, this.$PlatformsTypes.Network].includes(this.restore.type)&&this.destinationData.client.serviceAccount.options.accountSessionId=="") {
                        method=this.restore.type==2? "connectftp":"connectnetwork";
                        this.destinationData.client.serviceAccount.options.accountSessionId=await this.$root.socket(method, this.destinationData.client.serviceAccount);
                    }

                    var oneLevel = [this.$PlatformsTypes.Microsoft_SharePoint, this.$PlatformsTypes.Microsoft_OneDriveForBusiness].includes(this.restore.type);
                    return new FileSystemSidebarConfig(
                        this.restore.type,
                        false,
                        this.restore.type.isMicrosoft365(),//elementi multipli
                        this.destinationData.client,
                        this.$root.getSARootPath(this.destinationData.client.serviceAccount),
                        oneLevel,
                        this.microsoftTree
                    );
            }

            return null;
        },

        onSelectRestoreType(a, b, c) {
            console.log(a);
            console.log(b);
            console.log(c);
        },

    }
}
</script>