<template>
    <div class="form-group">
        <div>

            <!--Se devo selezionare un elemento singolo, mostro un input text e un bottone per aprire la filesystemsidebar-->
            <div v-if="singleItem" class="mb-4">
                <label>{{ $t(buttonText) }}</label>
                <div class="d-flex flex-row  flex-align-baseline">
                    <!--<input v-if="list.length == 0" type="text" data-role="input" class="w-75 text-small"/>
                    <input v-else type="text" data-role="input" v-model="singleValue" class="w-75 text-small" />-->
                    <input type="text" data-role="input" v-model="singleValue" class="w-75 text-small" v-path="type.getSeparator()"/>
                    <!--<input v-else type="text" data-role="input" v-model="list[0].path" @change="onChangeInput()" class="w-75 text-small" />-->
                    <a class="button ml-2" @click="$emit('clickFunction')" :disabled="isDisabled"><span class="mif-folder" /></a>
                </div>

                <!--<div :title="list.length == 1 ? list[0].path : ''">
                    <input v-if="list.length == 1" type="text" readonly data-role="input" data-validate="required"
                        data-role-input="true" :value="list[0].path">
                    <input v-else type="text" data-role="input" data-validate="required" data-role-input="true">
                    <div class="button-group">
                        <button class="button" type="button" :disabled="isDisabled">
                            <span class="mif-folder" />
                        </button>
                    </div>
                </div>-->
            </div>
            <!--Se devo selezionare più elementi, mostro un bottone per aprire la filesystemsidebar-->
            <input v-else type="button" @click="$emit('clickFunction')" :value="$t(buttonText)"
                class="button primary mb-2" :disabled="isDisabled" />
        </div>

        <div v-if="!singleItem && list.length > 0" class="overflow mt-2" :style="'max-height: 60vh;'">

            <table class="table striped compact table-compact table-border">
                <thead class="bg-light">
                    <tr>
                        <th>{{$t("Selected resources")}}</th>
                        <th class="text-right"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr  v-for="res in list" v-bind:key="res.id" :title="res.path">
                        <td class=""><span :class="getClass(res)" />  {{ res.path.split("?")[0] }}</td>
                        <td class="text-right">
                            <a href="#" class="button flat-button" v-if="canCancel" @click="$root.$emit('uncheckNode', res)">
                                <span class='mif-bin'></span>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>

</template>

<script>

export default {
    name: "SelectedResourcesLayout",
    props: {
        buttonText: String,
        singleItem: Boolean,
        isDisabled: Boolean,
        type:Number,
        list: {
            type: Array,
            required: true
        },
        heightPercentage: {
            type: Number,
            required: true
        },
        canCancel: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            height: 0,
            iconClass: '',
            singleValue : ''
            
        }
    },
    watch:{
        list :{
            handler : function(value){
                if(value.length==0 || !this.singleItem){
                    return;
                }

                this.singleValue = this.list[0].path;
            }
        },
        singleValue : {
            handler : function(value){
                this.$emit('setNewPath', value);
            }
        }
    },
    computed: {
        /*         valueToShow : function(){
                    if(this.list.length == 1)
                    return this.list[0].path;
                return "";
        
                } */
    },
    mounted: function() {
        //this.height=this.$parent.$el.clientHeight*this.heightPercentage/100;
        if(this.singleItem){
            this.singleValue = this.list[0].path;
        }
    },
    methods: {

        getClass(node) {
            var classString="px-2 "+node.type.getMetroIcon(node.path);
            if (!this.compact)
                classString+=" mif-2x py-3";
            return classString;
        },



        getShortPath(node) {
            return node.getShortPath();
        },

        onChangeInput(){
            alert(this.list[0].path);
            this.$emit('setNewPath', this.list[0].path);
        }
    }
}
</script>