var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "text-bold mif-2x",
      class: _vm.lastresult.getResultValueInfo().color,
      attrs: { title: _vm.$t(_vm.lastresult.getResultValueInfo().label) },
    },
    [
      _vm.isRunning
        ? _c("span", { attrs: { title: _vm.$t("Running") } }, [
            _vm.progress != null || _vm.isRunning
              ? _c("span", { staticClass: "fg-dark" }, [
                  _c("div", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.progress) + "%"),
                  ]),
                  _c("div", {
                    attrs: {
                      "data-role": "progress",
                      "data-cls-buffer": "bg-dark",
                      "data-cls-back": "bg-dark",
                      "data-type": "load",
                      "data-value": _vm.progress,
                      "data-buffer": 100,
                      "data-small": "true",
                    },
                  }),
                ])
              : _vm._e(),
          ])
        : _vm.lastresult == _vm.$ResultValue.OK
        ? _c("span", { staticClass: "c-default" }, [_vm._v(" ● ")])
        : _vm.lastresult == _vm.$ResultValue.SomeWarnings
        ? _c("span", { staticClass: "c-default" }, [_vm._v(" ● ")])
        : _vm.lastresult == _vm.$ResultValue.Error
        ? _c("span", { staticClass: "c-default" }, [_vm._v(" ● ")])
        : _vm.lastresult == _vm.$ResultValue.Aborted
        ? _c("span", { staticClass: "c-default" }, [_vm._v(" ● ")])
        : _vm.lastresult == _vm.$ResultValue.NeverExecuted
        ? _c("span", { staticClass: "c-default" }, [_vm._v(" ● ")])
        : _vm.lastresult == _vm.$ResultValue.TerminatedAbnormally
        ? _c("span", { staticClass: "h5" }, [_vm._v("!")])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }