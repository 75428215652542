import { checkInputAsInteger } from "./utilitiesmodule";

export function installVueDirectives(Vue) {

    /******************************************NUMBER****************************************************************************************************/
    Vue.directive('number', {
        inserted(el, _, vnode) {

            //Evento scatenato dopo l'input: rimuove tutti i caratteri che non sono numeri e permette la cancellazione
            //L'evento beforeinput non va bene perchè non tiene conto del cambiamento dei dati attraverso le frecce (esempio 1) o l'inserimento di un numero non alla fine (esempio 2).
            //Esempio 1: Se il valore attuale è di 200 e si premeva la freccia in avanti, si concatenava 200 a 201, formando il numero 200201. 
            //Invece si voleva semplicemente sostituire 200 con 201.
            //Esempio 2: Se il valore attuale è di 3, ma si vuole il numero 13, l'utente deve aggiungere 1 davanti, ma 1 veniva concatenato dopo il 3, ottenendo 31 e non 13.
            //Per questi motivi è meglio tener conto del valore finale
            el.addEventListener('input', (event) => {
                var oldValue = event.target.value + '';
                if (oldValue == "") {
                    return;
                }
                event.preventDefault();
                event.target.value = checkInputAsInteger(oldValue, 0);
            });
            //Evento scatenato quando si toglie il focus dall'input. Se l'utente ha lasciato l'input vuoto, inserisce automaticamente il valore 0
            el.addEventListener('change', (event) => {
                var oldValue = event.target.value + '';
                if (oldValue == "") {
                    event.target.value = 0;
                }

            });
        },
    });

    Vue.directive('range', {
        inserted(el, binding) {
            const { min = 1, max = 365 } = binding.value || {};

            //Evento scatenato dopo l'input: rimuove tutti i caratteri che non sono numeri, corregge il range e permette la cancellazione
            //L'evento beforeinput non va bene perchè non tiene conto del cambiamento dei dati attraverso le frecce (esempio 1) o l'inserimento di un numero non alla fine (esempio 2).
            //Esempio 1: Se il valore attuale è di 200 e si premeva la freccia in avanti, si concatenava 200 a 201, formando il numero 200201. 
            //Questo valore viene corretto con il massimo. Invece si voleva semplicemente sostituire 200 con 201.
            //Esempio 2: Se il valore attuale è di 3, ma si vuole il numero 13, l'utente deve aggiungere 1 davanti, ma 1 veniva concatenato dopo il 3, ottenendo 31 e non 13.
            //Per questi motivi è meglio tener conto del valore finale            
            el.addEventListener('input', (event) => {
                var oldValue = event.target.value + '';
                if (oldValue == "") {
                    return;
                }
                //var newChar = event.data;
                var newValue = checkInputAsInteger(oldValue, min);

                event.preventDefault();
                if (newValue < min) {
                    event.target.value = min;
                } else if (newValue > max) {
                    event.target.value = max;
                } else {
                    event.target.value = newValue;
                }

                // Sincronizza il valore con v-model
                //vnode.elm.dispatchEvent(new Event('input'));
                //event.preventDefault();

            });

            //Evento scatenato quando si toglie il focus dall'input. Se l'utente ha lasciato l'input vuoto, inserisce automaticamente il valore 0
            el.addEventListener('change', (event) => {
                var oldValue = event.target.value + '';
                if (oldValue == "") {
                    event.target.value = min;
                }

            });
            /*el.addEventListener('beforeinput', (event) => {
                var oldValue = event.target.value + '';
                var newChar = event.data;
                var newValue = checkInputAsInteger(oldValue, newChar, min);

                event.preventDefault();
                if (newValue < min) {
                    event.target.value = min;
                } else if (newValue > max) {
                    event.target.value = max;
                } else {
                    event.target.value = newValue;
                }

                // Sincronizza il valore con v-model
                vnode.elm.dispatchEvent(new Event('input'));
            });*/
        }
    });



    /***************************************************STRING******************************************************************/
    Vue.directive("fsitem", {
        inserted(el, _, vnode) {
            el.addEventListener('beforeinput', (event) => {

                let value = event.target.value;
                //Gli elementi che dovranno essere nomi di file o cartelle 
                value = value.trim().replace(/[<>:|?*\\/]/g, '');
                event.target.value = value;

                // Sincronizza il valore con v-model
                vnode.elm.dispatchEvent(new Event('input'));
            });
        }
    });

    Vue.directive("path", {

        inserted(el, binding, vnode) {
            var type = binding.value;
            var slash = type.getSeparator()
            

            el.addEventListener("input"/*'beforeinput'*/, (event) => {
                let value = event.target.value;
                /*
                //Tolgo gli spazi
                value = value.trim();
                //tolgo gli slash all'inizio e alla fine
                value = value.trimStart('/').trimStart('\\').trimEnd('/').trimEnd('\\');
                //tolgo i caratteri non ammessi
                value = value.replace(/[<>:|?*"']/g, '');
                //sostituisco gli slash sbagliati
                if (slash == "/" && value.indexOf('\\') != -1) {
                    value = value.replace("\\", "/")
                } else if (slash == "\\" && value.indexOf('/') != -1) {
                    value = value.replace("/", "\\");
                }

                if(slash== "\\" && !value.startsWith("\\\\")){
                    value = value.substr(0,1)+ ":"+value.substr(1);
                }*/

                event.target.value = value.validatePath(type, slash);

                // Sincronizza il valore con v-model
                vnode.elm.dispatchEvent(new Event('input'));
            });

        }
    });

}