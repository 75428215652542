<template>
    <span :title="$t(lastresult.getResultValueInfo().label)" :class="lastresult.getResultValueInfo().color" class="text-bold mif-2x">
        <span v-if="isRunning" :title="$t('Running')"> 
            <span v-if="progress!=null || isRunning" class="fg-dark">
                <div class="text-center">{{ progress }}%</div>
                <div data-role="progress" data-cls-buffer="bg-dark" data-cls-back="bg-dark" data-type="load" :data-value="progress" :data-buffer="100" data-small="true"></div>
            </span>  
        </span>
        <span v-else-if="lastresult == $ResultValue.OK" class="c-default"> ● </span>
        <span v-else-if="lastresult == $ResultValue.SomeWarnings" class="c-default"> ● </span>
        <span v-else-if="lastresult == $ResultValue.Error" class="c-default"> ● </span>
        <span v-else-if="lastresult == $ResultValue.Aborted" class="c-default"> ● </span>
        <span v-else-if="lastresult == $ResultValue.NeverExecuted" class="c-default"> ● </span>
        <span v-else-if="lastresult == $ResultValue.TerminatedAbnormally" class="h5">!</span>
    </span>
</template>
<script>
export default {
    name: "LastResult",
    props: {
        lastresult: {
            type: Number,
            required: true
        },
        progress: {
            type: Number,
            required: false
        },
        isRunning: {
            type: Boolean,  
            required: false
        }
    }

}
</script>