var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog light w-100-sm w-100-md w-75-lg w-50-xl h-75",
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.$root.$emit("CLOSESOURCEDIALOG")
        },
      },
    },
    [
      _c("DialogTitle", {
        attrs: {
          title: _vm.$t("Configure Backup Source"),
          defaultTitle: _vm.$t("Configure Backup Source"),
          subTitle: _vm.source.type_name,
        },
        on: {
          closeDialog: function ($event) {
            return _vm.$root.$emit("CLOSESOURCEDIALOG")
          },
        },
      }),
      _vm.step == 0
        ? _c(
            "div",
            { staticClass: "dialog-content overflow" },
            [
              _c("SARepoSelect", {
                attrs: {
                  type: _vm.source.type,
                  idSA: _vm.source.id_service_account,
                  idRepo: -2,
                },
                on: { sendSATestResult: _vm.sendSATestResult },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.step == 1
        ? _c("div", { staticClass: "dialog-content overflow" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("h6", { staticClass: "text-bold" }, [
                _vm._v(_vm._s(_vm.$t("Select the Virtual Machines to backup"))),
              ]),
            ]),
            _vm.wait
              ? _c("div", { staticClass: "form-group text-center" }, [
                  _c("span", {
                    staticClass: "mif-spinner2 fg-primary ani-spin",
                  }),
                ])
              : _c(
                  "div",
                  {
                    staticClass: "h-100 w-100",
                    attrs: {
                      "data-role": "splitter",
                      "data-split-sizes": "70, 30",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "p-2",
                        staticStyle: {
                          "max-height": "600px",
                          "overflow-y": "auto",
                          "overflow-x": "hidden",
                          "min-width": "500px",
                        },
                      },
                      [
                        _vm.source.esxiSource.dataCenters.length != 0
                          ? _c("ul", { attrs: { "data-role": "treeview" } }, [
                              _c(
                                "li",
                                {
                                  staticClass: "p-1",
                                  attrs: { title: _vm.source.esxiSource.url },
                                },
                                [
                                  _c("input", {
                                    staticClass: "mt-3-minus",
                                    attrs: {
                                      type: "checkbox",
                                      "data-role": "checkbox",
                                      "data-caption":
                                        _vm.source.esxiSource.url +
                                        " (" +
                                        _vm.source.esxiSource.hostVersion +
                                        ")",
                                    },
                                    domProps: { value: true },
                                  }),
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.source.esxiSource.dataCenters,
                                      function (datacenter) {
                                        return _c(
                                          "li",
                                          {
                                            key: datacenter.name,
                                            attrs: {
                                              "data-collapsed": "true",
                                              "data-icon":
                                                "<span class='mif-location-city pt-1'></span>",
                                            },
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: datacenter.selected,
                                                  expression:
                                                    "datacenter.selected",
                                                },
                                              ],
                                              staticClass: "ml-5",
                                              attrs: {
                                                type: "checkbox",
                                                "data-role": "checkbox",
                                                "data-caption": datacenter.name,
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  datacenter.selected
                                                )
                                                  ? _vm._i(
                                                      datacenter.selected,
                                                      null
                                                    ) > -1
                                                  : datacenter.selected,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a = datacenter.selected,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          datacenter,
                                                          "selected",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          datacenter,
                                                          "selected",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      datacenter,
                                                      "selected",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                            datacenter.computerResourceList
                                              ? _c(
                                                  "ul",
                                                  _vm._l(
                                                    datacenter.computerResourceList,
                                                    function (
                                                      computerResource
                                                    ) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: computerResource.name,
                                                          staticClass: "p-1",
                                                          attrs: {
                                                            title:
                                                              computerResource.name,
                                                            "data-collapsed":
                                                              "true",
                                                            "data-icon":
                                                              "<span class='mif-cabinet pt-1'></span>",
                                                          },
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  computerResource.selected,
                                                                expression:
                                                                  "computerResource.selected",
                                                              },
                                                            ],
                                                            staticClass: "ml-5",
                                                            attrs: {
                                                              disabled:
                                                                computerResource
                                                                  .datastores
                                                                  .length == 0,
                                                              type: "checkbox",
                                                              "data-role":
                                                                "checkbox",
                                                              "data-caption":
                                                                computerResource.name,
                                                            },
                                                            domProps: {
                                                              checked:
                                                                Array.isArray(
                                                                  computerResource.selected
                                                                )
                                                                  ? _vm._i(
                                                                      computerResource.selected,
                                                                      null
                                                                    ) > -1
                                                                  : computerResource.selected,
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                var $$a =
                                                                    computerResource.selected,
                                                                  $$el =
                                                                    $event.target,
                                                                  $$c =
                                                                    $$el.checked
                                                                      ? true
                                                                      : false
                                                                if (
                                                                  Array.isArray(
                                                                    $$a
                                                                  )
                                                                ) {
                                                                  var $$v =
                                                                      null,
                                                                    $$i =
                                                                      _vm._i(
                                                                        $$a,
                                                                        $$v
                                                                      )
                                                                  if (
                                                                    $$el.checked
                                                                  ) {
                                                                    $$i < 0 &&
                                                                      _vm.$set(
                                                                        computerResource,
                                                                        "selected",
                                                                        $$a.concat(
                                                                          [$$v]
                                                                        )
                                                                      )
                                                                  } else {
                                                                    $$i > -1 &&
                                                                      _vm.$set(
                                                                        computerResource,
                                                                        "selected",
                                                                        $$a
                                                                          .slice(
                                                                            0,
                                                                            $$i
                                                                          )
                                                                          .concat(
                                                                            $$a.slice(
                                                                              $$i +
                                                                                1
                                                                            )
                                                                          )
                                                                      )
                                                                  }
                                                                } else {
                                                                  _vm.$set(
                                                                    computerResource,
                                                                    "selected",
                                                                    $$c
                                                                  )
                                                                }
                                                              },
                                                            },
                                                          }),
                                                          datacenter.computerResourceList
                                                            ? _c(
                                                                "ul",
                                                                _vm._l(
                                                                  computerResource.datastores,
                                                                  function (
                                                                    datastore
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key: datastore.name,
                                                                        staticClass:
                                                                          "p-1",
                                                                        attrs: {
                                                                          title:
                                                                            datastore.name,
                                                                          "data-collapsed":
                                                                            "true",
                                                                          "data-icon":
                                                                            "<span class='mif-database pt-1'></span>",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    datastore.selected,
                                                                                  expression:
                                                                                    "datastore.selected",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "ml-5",
                                                                            attrs:
                                                                              {
                                                                                disabled:
                                                                                  datastore
                                                                                    .virtualMachines
                                                                                    .length ==
                                                                                  0,
                                                                                type: "checkbox",
                                                                                "data-role":
                                                                                  "checkbox",
                                                                                "data-caption":
                                                                                  datastore.name +
                                                                                  " [" +
                                                                                  datastore.freeSpace_string +
                                                                                  "/" +
                                                                                  datastore.capacity_string +
                                                                                  "]",
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                checked:
                                                                                  Array.isArray(
                                                                                    datastore.selected
                                                                                  )
                                                                                    ? _vm._i(
                                                                                        datastore.selected,
                                                                                        null
                                                                                      ) >
                                                                                      -1
                                                                                    : datastore.selected,
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  var $$a =
                                                                                      datastore.selected,
                                                                                    $$el =
                                                                                      $event.target,
                                                                                    $$c =
                                                                                      $$el.checked
                                                                                        ? true
                                                                                        : false
                                                                                  if (
                                                                                    Array.isArray(
                                                                                      $$a
                                                                                    )
                                                                                  ) {
                                                                                    var $$v =
                                                                                        null,
                                                                                      $$i =
                                                                                        _vm._i(
                                                                                          $$a,
                                                                                          $$v
                                                                                        )
                                                                                    if (
                                                                                      $$el.checked
                                                                                    ) {
                                                                                      $$i <
                                                                                        0 &&
                                                                                        _vm.$set(
                                                                                          datastore,
                                                                                          "selected",
                                                                                          $$a.concat(
                                                                                            [
                                                                                              $$v,
                                                                                            ]
                                                                                          )
                                                                                        )
                                                                                    } else {
                                                                                      $$i >
                                                                                        -1 &&
                                                                                        _vm.$set(
                                                                                          datastore,
                                                                                          "selected",
                                                                                          $$a
                                                                                            .slice(
                                                                                              0,
                                                                                              $$i
                                                                                            )
                                                                                            .concat(
                                                                                              $$a.slice(
                                                                                                $$i +
                                                                                                  1
                                                                                              )
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                  } else {
                                                                                    _vm.$set(
                                                                                      datastore,
                                                                                      "selected",
                                                                                      $$c
                                                                                    )
                                                                                  }
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                        datastore.virtualMachines
                                                                          ? _c(
                                                                              "ul",
                                                                              _vm._l(
                                                                                datastore.virtualMachines,
                                                                                function (
                                                                                  virtualmachine
                                                                                ) {
                                                                                  return _c(
                                                                                    "li",
                                                                                    {
                                                                                      key: virtualmachine.name,
                                                                                      staticClass:
                                                                                        "p-1",
                                                                                      attrs:
                                                                                        {
                                                                                          "data-collapsed":
                                                                                            "true",
                                                                                          "data-icon":
                                                                                            "<span class='mif-stack pt-1'></span>",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "input",
                                                                                        {
                                                                                          directives:
                                                                                            [
                                                                                              {
                                                                                                name: "model",
                                                                                                rawName:
                                                                                                  "v-model",
                                                                                                value:
                                                                                                  virtualmachine.selected,
                                                                                                expression:
                                                                                                  "virtualmachine.selected",
                                                                                              },
                                                                                            ],
                                                                                          staticClass:
                                                                                            "ml-5",
                                                                                          attrs:
                                                                                            {
                                                                                              type: "checkbox",
                                                                                              "data-role":
                                                                                                "checkbox",
                                                                                            },
                                                                                          domProps:
                                                                                            {
                                                                                              checked:
                                                                                                Array.isArray(
                                                                                                  virtualmachine.selected
                                                                                                )
                                                                                                  ? _vm._i(
                                                                                                      virtualmachine.selected,
                                                                                                      null
                                                                                                    ) >
                                                                                                    -1
                                                                                                  : virtualmachine.selected,
                                                                                            },
                                                                                          on: {
                                                                                            change:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                var $$a =
                                                                                                    virtualmachine.selected,
                                                                                                  $$el =
                                                                                                    $event.target,
                                                                                                  $$c =
                                                                                                    $$el.checked
                                                                                                      ? true
                                                                                                      : false
                                                                                                if (
                                                                                                  Array.isArray(
                                                                                                    $$a
                                                                                                  )
                                                                                                ) {
                                                                                                  var $$v =
                                                                                                      null,
                                                                                                    $$i =
                                                                                                      _vm._i(
                                                                                                        $$a,
                                                                                                        $$v
                                                                                                      )
                                                                                                  if (
                                                                                                    $$el.checked
                                                                                                  ) {
                                                                                                    $$i <
                                                                                                      0 &&
                                                                                                      _vm.$set(
                                                                                                        virtualmachine,
                                                                                                        "selected",
                                                                                                        $$a.concat(
                                                                                                          [
                                                                                                            $$v,
                                                                                                          ]
                                                                                                        )
                                                                                                      )
                                                                                                  } else {
                                                                                                    $$i >
                                                                                                      -1 &&
                                                                                                      _vm.$set(
                                                                                                        virtualmachine,
                                                                                                        "selected",
                                                                                                        $$a
                                                                                                          .slice(
                                                                                                            0,
                                                                                                            $$i
                                                                                                          )
                                                                                                          .concat(
                                                                                                            $$a.slice(
                                                                                                              $$i +
                                                                                                                1
                                                                                                            )
                                                                                                          )
                                                                                                      )
                                                                                                  }
                                                                                                } else {
                                                                                                  _vm.$set(
                                                                                                    virtualmachine,
                                                                                                    "selected",
                                                                                                    $$c
                                                                                                  )
                                                                                                }
                                                                                              },
                                                                                          },
                                                                                        }
                                                                                      ),
                                                                                      _c(
                                                                                        "label",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-bold",
                                                                                          class:
                                                                                            {
                                                                                              active:
                                                                                                _vm.esxiselected ==
                                                                                                virtualmachine,
                                                                                            },
                                                                                          staticStyle:
                                                                                            {
                                                                                              cursor:
                                                                                                "pointer",
                                                                                              top: "-4px",
                                                                                              position:
                                                                                                "relative",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                _vm.esxiselected =
                                                                                                  virtualmachine
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              virtualmachine.name
                                                                                            ) +
                                                                                              " "
                                                                                          ),
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticClass:
                                                                                                "mif-info",
                                                                                            }
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      virtualmachine.virtualDisks
                                                                                        ? _c(
                                                                                            "ul",
                                                                                            _vm._l(
                                                                                              virtualmachine.virtualDisks,
                                                                                              function (
                                                                                                virtualDisk
                                                                                              ) {
                                                                                                return _c(
                                                                                                  "li",
                                                                                                  {
                                                                                                    key: virtualDisk.deviceKey,
                                                                                                    staticClass:
                                                                                                      "p-1",
                                                                                                    attrs:
                                                                                                      {
                                                                                                        title:
                                                                                                          virtualDisk.diskFilename.split(
                                                                                                            "/"
                                                                                                          )[1],
                                                                                                        "data-collapsed":
                                                                                                          "true",
                                                                                                        "data-icon":
                                                                                                          "<span class='mif-drive2 pt-1'></span>",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "input",
                                                                                                      {
                                                                                                        directives:
                                                                                                          [
                                                                                                            {
                                                                                                              name: "model",
                                                                                                              rawName:
                                                                                                                "v-model",
                                                                                                              value:
                                                                                                                virtualDisk.selected,
                                                                                                              expression:
                                                                                                                "virtualDisk.selected",
                                                                                                            },
                                                                                                          ],
                                                                                                        staticClass:
                                                                                                          "ml-5",
                                                                                                        attrs:
                                                                                                          {
                                                                                                            type: "checkbox",
                                                                                                            "data-role":
                                                                                                              "checkbox",
                                                                                                            "data-caption":
                                                                                                              virtualDisk.diskFilename.split(
                                                                                                                "/"
                                                                                                              )[1] +
                                                                                                              " [" +
                                                                                                              virtualDisk.size_string +
                                                                                                              "]",
                                                                                                          },
                                                                                                        domProps:
                                                                                                          {
                                                                                                            checked:
                                                                                                              Array.isArray(
                                                                                                                virtualDisk.selected
                                                                                                              )
                                                                                                                ? _vm._i(
                                                                                                                    virtualDisk.selected,
                                                                                                                    null
                                                                                                                  ) >
                                                                                                                  -1
                                                                                                                : virtualDisk.selected,
                                                                                                          },
                                                                                                        on: {
                                                                                                          change:
                                                                                                            function (
                                                                                                              $event
                                                                                                            ) {
                                                                                                              var $$a =
                                                                                                                  virtualDisk.selected,
                                                                                                                $$el =
                                                                                                                  $event.target,
                                                                                                                $$c =
                                                                                                                  $$el.checked
                                                                                                                    ? true
                                                                                                                    : false
                                                                                                              if (
                                                                                                                Array.isArray(
                                                                                                                  $$a
                                                                                                                )
                                                                                                              ) {
                                                                                                                var $$v =
                                                                                                                    null,
                                                                                                                  $$i =
                                                                                                                    _vm._i(
                                                                                                                      $$a,
                                                                                                                      $$v
                                                                                                                    )
                                                                                                                if (
                                                                                                                  $$el.checked
                                                                                                                ) {
                                                                                                                  $$i <
                                                                                                                    0 &&
                                                                                                                    _vm.$set(
                                                                                                                      virtualDisk,
                                                                                                                      "selected",
                                                                                                                      $$a.concat(
                                                                                                                        [
                                                                                                                          $$v,
                                                                                                                        ]
                                                                                                                      )
                                                                                                                    )
                                                                                                                } else {
                                                                                                                  $$i >
                                                                                                                    -1 &&
                                                                                                                    _vm.$set(
                                                                                                                      virtualDisk,
                                                                                                                      "selected",
                                                                                                                      $$a
                                                                                                                        .slice(
                                                                                                                          0,
                                                                                                                          $$i
                                                                                                                        )
                                                                                                                        .concat(
                                                                                                                          $$a.slice(
                                                                                                                            $$i +
                                                                                                                              1
                                                                                                                          )
                                                                                                                        )
                                                                                                                    )
                                                                                                                }
                                                                                                              } else {
                                                                                                                _vm.$set(
                                                                                                                  virtualDisk,
                                                                                                                  "selected",
                                                                                                                  $$c
                                                                                                                )
                                                                                                              }
                                                                                                            },
                                                                                                        },
                                                                                                      }
                                                                                                    ),
                                                                                                  ]
                                                                                                )
                                                                                              }
                                                                                            ),
                                                                                            0
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              ),
                                                                              0
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c("div", { staticClass: "pl-2 p-0 m-0" }, [
                      _vm.esxiselected
                        ? _c("div", { staticClass: "h-100" }, [
                            _c("h6", { staticClass: "w-100 mb-0 text-bold" }, [
                              _vm._v(" " + _vm._s(_vm.esxiselected.name)),
                            ]),
                            _c("p", { staticClass: "mute mt-0" }, [
                              _vm._v(_vm._s(_vm.esxiselected.os)),
                            ]),
                            _c(
                              "div",
                              { attrs: { "data-role": "buttongroup" } },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "button bg-primary fg-white rounded m-1 small",
                                    class: {
                                      disabled:
                                        _vm.esxiselected.powerState == 1,
                                    },
                                    attrs: { title: _vm.ESXiVmPowerState[1] },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeVMStatusEsxi(
                                          _vm.esxiselected,
                                          1
                                        )
                                      },
                                    },
                                  },
                                  [_c("span", { staticClass: "mif-play" })]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "button bg-primary rounded m-1 small",
                                    class: {
                                      disabled:
                                        _vm.esxiselected.powerState == 2,
                                    },
                                    attrs: { title: _vm.ESXiVmPowerState[2] },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeVMStatusEsxi(
                                          _vm.esxiselected,
                                          2
                                        )
                                      },
                                    },
                                  },
                                  [_c("span", { staticClass: "mif-pause" })]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "button bg-primary fg-white rounded m-1 small",
                                    class: {
                                      disabled:
                                        _vm.esxiselected.powerState == 0,
                                    },
                                    attrs: { title: _vm.ESXiVmPowerState[0] },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeVMStatusEsxi(
                                          _vm.esxiselected,
                                          0
                                        )
                                      },
                                    },
                                  },
                                  [_c("span", { staticClass: "mif-stop" })]
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "form-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.esxiselected.backupOptions
                                        .turnOffBefore,
                                    expression:
                                      "esxiselected.backupOptions.turnOffBefore",
                                  },
                                ],
                                attrs: {
                                  "data-role": "checkbox",
                                  type: "checkbox",
                                  "data-caption": _vm.$t(
                                    "Power off virtual machines before the backup"
                                  ),
                                  "data-validate": "required",
                                  required: "",
                                  "data-prepend":
                                    "<span class='mif-server'></span>",
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.esxiselected.backupOptions.turnOffBefore
                                  )
                                    ? _vm._i(
                                        _vm.esxiselected.backupOptions
                                          .turnOffBefore,
                                        null
                                      ) > -1
                                    : _vm.esxiselected.backupOptions
                                        .turnOffBefore,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a =
                                        _vm.esxiselected.backupOptions
                                          .turnOffBefore,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.esxiselected.backupOptions,
                                            "turnOffBefore",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.esxiselected.backupOptions,
                                            "turnOffBefore",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.esxiselected.backupOptions,
                                        "turnOffBefore",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "form-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.esxiselected.backupOptions
                                        .turnOnAfter,
                                    expression:
                                      "esxiselected.backupOptions.turnOnAfter",
                                  },
                                ],
                                attrs: {
                                  "data-role": "checkbox",
                                  type: "checkbox",
                                  "data-caption": _vm.$t(
                                    "Power on virtual machines after the backup"
                                  ),
                                  "data-validate": "required",
                                  required: "",
                                  "data-prepend":
                                    "<span class='mif-server'></span>",
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.esxiselected.backupOptions.turnOnAfter
                                  )
                                    ? _vm._i(
                                        _vm.esxiselected.backupOptions
                                          .turnOnAfter,
                                        null
                                      ) > -1
                                    : _vm.esxiselected.backupOptions
                                        .turnOnAfter,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a =
                                        _vm.esxiselected.backupOptions
                                          .turnOnAfter,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.esxiselected.backupOptions,
                                            "turnOnAfter",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.esxiselected.backupOptions,
                                            "turnOnAfter",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.esxiselected.backupOptions,
                                        "turnOnAfter",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "form-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.esxiselected.backupOptions
                                        .quiesceBefore,
                                    expression:
                                      "esxiselected.backupOptions.quiesceBefore",
                                  },
                                ],
                                attrs: {
                                  "data-role": "checkbox",
                                  type: "checkbox",
                                  "data-caption": _vm.$t(
                                    "Quiesce the file system before the backup (recommended)"
                                  ),
                                  "data-validate": "required",
                                  required: "",
                                  "data-prepend":
                                    "<span class='mif-server'></span>",
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.esxiselected.backupOptions.quiesceBefore
                                  )
                                    ? _vm._i(
                                        _vm.esxiselected.backupOptions
                                          .quiesceBefore,
                                        null
                                      ) > -1
                                    : _vm.esxiselected.backupOptions
                                        .quiesceBefore,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a =
                                        _vm.esxiselected.backupOptions
                                          .quiesceBefore,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.esxiselected.backupOptions,
                                            "quiesceBefore",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.esxiselected.backupOptions,
                                            "quiesceBefore",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.esxiselected.backupOptions,
                                        "quiesceBefore",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "form-group pt-4 pb-2" }, [
                              _c("p", { staticClass: "text-bold" }, [
                                _vm._v("Windows Os: "),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.esxiselected.backupOptions.vssMode,
                                    expression:
                                      "esxiselected.backupOptions.vssMode",
                                  },
                                ],
                                staticClass: "form-check-input mb-1",
                                attrs: {
                                  "data-role": "radio",
                                  type: "radio",
                                  value: "COPY",
                                  "data-caption": _vm.$t(
                                    "VSS copy mode (default)"
                                  ),
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.esxiselected.backupOptions.vssMode,
                                    "COPY"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.esxiselected.backupOptions,
                                      "vssMode",
                                      "COPY"
                                    )
                                  },
                                },
                              }),
                              _c("br"),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.esxiselected.backupOptions.vssMode,
                                    expression:
                                      "esxiselected.backupOptions.vssMode",
                                  },
                                ],
                                staticClass: "form-check-input mb-1",
                                attrs: {
                                  "data-role": "radio",
                                  type: "radio",
                                  value: "FULL",
                                  "data-caption": _vm.$t(
                                    "VSS full mode (logs of supported applications will be truncated)"
                                  ),
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.esxiselected.backupOptions.vssMode,
                                    "FULL"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.esxiselected.backupOptions,
                                      "vssMode",
                                      "FULL"
                                    )
                                  },
                                },
                              }),
                              _c("br"),
                            ]),
                            _c("div", { staticClass: "form-group pt-4 pb-2" }, [
                              _c("p", { staticClass: "text-bold" }, [
                                _vm._v("Linux Os: "),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.esxiselected.backupOptions.run_script,
                                    expression:
                                      "esxiselected.backupOptions.run_script",
                                  },
                                ],
                                attrs: {
                                  "data-role": "checkbox",
                                  type: "checkbox",
                                  "data-caption": _vm.$t(
                                    "Run pre-freeze and post-thaw script with the following credentials (Linex machines only)"
                                  ),
                                  required: "",
                                  "data-prepend":
                                    "<span class='mif-server'></span>",
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.esxiselected.backupOptions.run_script
                                  )
                                    ? _vm._i(
                                        _vm.esxiselected.backupOptions
                                          .run_script,
                                        null
                                      ) > -1
                                    : _vm.esxiselected.backupOptions.run_script,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a =
                                        _vm.esxiselected.backupOptions
                                          .run_script,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.esxiselected.backupOptions,
                                            "run_script",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.esxiselected.backupOptions,
                                            "run_script",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.esxiselected.backupOptions,
                                        "run_script",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "form-group" }, [
                              _c("div", { staticClass: "cell-12" }, [
                                _c("label", [
                                  _vm._v(_vm._s(_vm.$t("Username"))),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value:
                                        _vm.esxiselected.backupOptions
                                          .userScript,
                                      expression:
                                        "esxiselected.backupOptions.userScript",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  attrs: {
                                    "data-role": "input",
                                    type: "text",
                                    required: "",
                                    "data-prepend":
                                      "<span class='mif-user'></span>",
                                  },
                                  domProps: {
                                    value:
                                      _vm.esxiselected.backupOptions.userScript,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.esxiselected.backupOptions,
                                        "userScript",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                                _c("small", { staticClass: "text-muted" }, [
                                  _vm._v(_vm._s(_vm.$t("Required"))),
                                ]),
                              ]),
                              _c("div", { staticClass: "cell-12" }, [
                                _c("label", [
                                  _vm._v(_vm._s(_vm.$t("Password"))),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value:
                                        _vm.esxiselected.backupOptions.pwScript,
                                      expression:
                                        "esxiselected.backupOptions.pwScript",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  staticClass: "metro-input",
                                  attrs: {
                                    "data-role": "input",
                                    type: "password",
                                    placeholder: _vm.$t("Enter Password"),
                                    "data-prepend":
                                      "<span class='mif-lock'></span>",
                                  },
                                  domProps: {
                                    value:
                                      _vm.esxiselected.backupOptions.pwScript,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.esxiselected.backupOptions,
                                        "pwScript",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                                _c("small", { staticClass: "text-muted" }, [
                                  _vm._v(_vm._s(_vm.$t("Required"))),
                                ]),
                              ]),
                              _c("small", { staticClass: "text-muted ml-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "Freezing is recommended on Linux to get an 'application consistent' backup"
                                    )
                                  ) + " "
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                ),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == 2,
              expression: "step == 2",
            },
          ],
          staticClass: "dialog-content overflow",
        },
        [
          _c("div", { staticClass: "form-group pb-4" }, [
            _c("p", { staticClass: "text-bold" }, [
              _vm._v(_vm._s(_vm.$t("Backup mode"))),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.source.options.backupMode,
                  expression: "source.options.backupMode",
                },
              ],
              staticClass: "form-check-input mb-1",
              attrs: {
                "data-role": "radio",
                type: "radio",
                value: "STD",
                "data-caption": _vm.$t(
                  "Standard (copy of individual virtual machine files)"
                ),
              },
              domProps: {
                checked: _vm._q(_vm.source.options.backupMode, "STD"),
              },
              on: {
                change: function ($event) {
                  return _vm.$set(_vm.source.options, "backupMode", "STD")
                },
              },
            }),
            _c("br"),
            _c(
              "label",
              {
                attrs: {
                  "data-role": "hint",
                  "data-hint-text": _vm.$t(
                    "*Virtual machines must have the CBT option enabled"
                  ),
                  "data-hint-position": "right",
                },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.source.options.backupMode,
                      expression: "source.options.backupMode",
                    },
                  ],
                  staticClass: "form-check-input mb-1",
                  attrs: {
                    "data-role": "radio",
                    type: "radio",
                    value: "CBT",
                    "data-caption": _vm.$t(
                      "CBT / VDDK (full/incremental/differential)"
                    ),
                  },
                  domProps: {
                    checked: _vm._q(_vm.source.options.backupMode, "CBT"),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.source.options, "backupMode", "CBT")
                    },
                  },
                }),
              ]
            ),
            _c("br"),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.source.options.backupMode,
                  expression: "source.options.backupMode",
                },
              ],
              staticClass: "form-check-input mb-1",
              attrs: {
                "data-role": "radio",
                type: "radio",
                value: "2DS",
                "data-caption": _vm.$t(
                  "Full Backup to Datastore/Host (requires a vCenter connection)"
                ),
              },
              domProps: {
                checked: _vm._q(_vm.source.options.backupMode, "2DS"),
              },
              on: {
                change: function ($event) {
                  return _vm.$set(_vm.source.options, "backupMode", "2DS")
                },
              },
            }),
            _c("br"),
            _c(
              "label",
              {
                attrs: {
                  "data-role": "hint",
                  "data-hint-text": _vm.$t(
                    "*Virtual machines must have the CBT option enabled"
                  ),
                  "data-hint-position": "right",
                },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.source.options.backupMode,
                      expression: "source.options.backupMode",
                    },
                  ],
                  staticClass: "form-check-input mb-1",
                  attrs: {
                    "data-role": "radio",
                    type: "radio",
                    value: "REP",
                    "data-caption": _vm.$t(
                      "Incremental replication from host to host"
                    ),
                  },
                  domProps: {
                    checked: _vm._q(_vm.source.options.backupMode, "REP"),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.source.options, "backupMode", "REP")
                    },
                  },
                }),
              ]
            ),
            _c("br"),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.source.options.backupMode != "REP",
                  expression: "source.options.backupMode != 'REP'",
                },
              ],
              staticClass: "form-group pb-4",
            },
            [
              _c("p", { staticClass: "text-bold" }, [
                _vm._v(_vm._s(_vm.$t("Backup type"))),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.backupType,
                    expression: "source.options.backupType",
                  },
                ],
                staticClass: "form-check-input mb-1",
                attrs: {
                  "data-role": "radio",
                  type: "radio",
                  value: "F",
                  "data-caption": _vm.$t("Full Backups only"),
                },
                domProps: {
                  checked: _vm._q(_vm.source.options.backupType, "F"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.source.options, "backupType", "F")
                  },
                },
              }),
              _c("br"),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.backupType,
                    expression: "source.options.backupType",
                  },
                ],
                staticClass: "form-check-input mb-1",
                attrs: {
                  "data-role": "radio",
                  disabled: _vm.source.options.backupMode != "CBT",
                  type: "radio",
                  value: "I",
                  "data-caption": _vm.$t(
                    "One Full backup + Incremental backups"
                  ),
                },
                domProps: {
                  checked: _vm._q(_vm.source.options.backupType, "I"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.source.options, "backupType", "I")
                  },
                },
              }),
              _c("br"),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.backupType,
                    expression: "source.options.backupType",
                  },
                ],
                staticClass: "form-check-input mb-1",
                attrs: {
                  "data-role": "radio",
                  disabled: _vm.source.options.backupMode != "CBT",
                  type: "radio",
                  value: "D",
                  "data-caption": _vm.$t(
                    "One Full backup + Differential backups"
                  ),
                },
                domProps: {
                  checked: _vm._q(_vm.source.options.backupType, "D"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.source.options, "backupType", "D")
                  },
                },
              }),
              _c("br"),
            ]
          ),
          _c("div", { staticClass: "form-group" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("CBT Port")) + ":")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.lazy.number",
                  value: _vm.source.options.cbtPort,
                  expression: "source.options.cbtPort",
                  modifiers: { lazy: true, number: true },
                },
                { name: "number", rawName: "v-number" },
                {
                  name: "range",
                  rawName: "v-range",
                  value: { min: 10, max: 65535 },
                  expression: "{min:10, max:65535}",
                },
              ],
              staticClass: "input w-25 p-2",
              attrs: { type: "number", min: "0" },
              domProps: { value: _vm.source.options.cbtPort },
              on: {
                change: function ($event) {
                  _vm.$set(
                    _vm.source.options,
                    "cbtPort",
                    _vm._n($event.target.value)
                  )
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
            _c("label", [
              _vm._v(_vm._s(_vm.$t("Leave blank if you don't use it"))),
            ]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.source.options.backupMode == "CBT",
                  expression: "source.options.backupMode == 'CBT'",
                },
              ],
              staticClass: "form-group pt-4",
            },
            [
              _c("p", [_vm._v(_vm._s(_vm.$t("Number of copies")) + ":")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.lazy.number",
                    value: _vm.source.options.nOfCopies,
                    expression: "source.options.nOfCopies",
                    modifiers: { lazy: true, number: true },
                  },
                  { name: "number", rawName: "v-number" },
                  {
                    name: "range",
                    rawName: "v-range",
                    value: { min: 0, max: 365 },
                    expression: "{min:0, max:365}",
                  },
                ],
                staticClass: "input w-25",
                attrs: { type: "number" },
                domProps: { value: _vm.source.options.nOfCopies },
                on: {
                  change: function ($event) {
                    _vm.$set(
                      _vm.source.options,
                      "nOfCopies",
                      _vm._n($event.target.value)
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == 3,
              expression: "step == 3",
            },
          ],
          staticClass: "dialog-content overflow",
        },
        [
          _c("h6", { staticClass: "text-bold" }, [
            _vm._v(_vm._s(_vm.$t("Configure backup options")) + ":"),
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.source.options.autoAddNewItems,
                  expression: "source.options.autoAddNewItems",
                },
              ],
              attrs: {
                "data-role": "checkbox",
                type: "checkbox",
                "data-caption": _vm.$t(
                  "Add automatically every new virtual machine to the backup"
                ),
                "data-validate": "required",
                required: "",
              },
              domProps: {
                checked: Array.isArray(_vm.source.options.autoAddNewItems)
                  ? _vm._i(_vm.source.options.autoAddNewItems, null) > -1
                  : _vm.source.options.autoAddNewItems,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.source.options.autoAddNewItems,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.source.options,
                          "autoAddNewItems",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.source.options,
                          "autoAddNewItems",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.source.options, "autoAddNewItems", $$c)
                  }
                },
              },
            }),
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.source.enableCompression,
                  expression: "source.enableCompression",
                },
              ],
              attrs: {
                disabled:
                  _vm.source.options.backupMode == "REP" ||
                  _vm.source.options.backupMode == "2DS",
                type: "checkbox",
                "data-role": "checkbox",
                "data-caption": _vm.$t("Enable Compression"),
              },
              domProps: {
                checked: Array.isArray(_vm.source.enableCompression)
                  ? _vm._i(_vm.source.enableCompression, null) > -1
                  : _vm.source.enableCompression,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.source.enableCompression,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.source,
                          "enableCompression",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.source,
                          "enableCompression",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.source, "enableCompression", $$c)
                  }
                },
              },
            }),
          ]),
          _c("div", { staticClass: "form-group w-50" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("Archive File Custom Name")) + " ")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.lazy",
                  value: _vm.source.options.archiveFileCustomName,
                  expression: "source.options.archiveFileCustomName",
                  modifiers: { lazy: true },
                },
                { name: "fsitem", rawName: "v-fsitem" },
              ],
              attrs: {
                disabled: !_vm.source.enableCompression,
                type: "text",
                "data-role": "input",
              },
              domProps: { value: _vm.source.options.archiveFileCustomName },
              on: {
                change: function ($event) {
                  return _vm.$set(
                    _vm.source.options,
                    "archiveFileCustomName",
                    $event.target.value
                  )
                },
              },
            }),
            _c("label", { staticClass: "text-small text-muted" }, [
              _vm._v(_vm._s(_vm.$t("If blank, default name will be used"))),
            ]),
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.source.options.useArchivePassword,
                  expression: "source.options.useArchivePassword",
                },
              ],
              attrs: {
                disabled: !_vm.source.enableCompression,
                type: "checkbox",
                "data-role": "checkbox",
                "data-caption": _vm.$t("Protect zip with Password"),
              },
              domProps: {
                checked: Array.isArray(_vm.source.options.useArchivePassword)
                  ? _vm._i(_vm.source.options.useArchivePassword, null) > -1
                  : _vm.source.options.useArchivePassword,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.source.options.useArchivePassword,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.source.options,
                          "useArchivePassword",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.source.options,
                          "useArchivePassword",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.source.options, "useArchivePassword", $$c)
                  }
                },
              },
            }),
          ]),
          _vm.source.enableCompression && _vm.source.options.useArchivePassword
            ? _c("div", [
                _c("div", { staticClass: "form-group w-50" }, [
                  _c("p", [_vm._v(_vm._s(_vm.$t("Archive Password")))]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.source.options.archivePassword,
                        expression: "source.options.archivePassword",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "metro-input",
                    attrs: {
                      "data-role": "input",
                      type: "password",
                      placeholder: _vm.$t("Enter Password"),
                      "data-prepend": "<span class='mif-lock'></span>",
                    },
                    domProps: { value: _vm.source.options.archivePassword },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.source.options,
                          "archivePassword",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.$t("Required"))),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm.step == 4 && ["2DS", "REP"].includes(_vm.source.options.backupMode)
        ? _c("div", { staticClass: "dialog-content overflow" }, [
            _c(
              "div",
              { staticClass: "form-group pb-6" },
              [
                _c("label", [
                  _c("span", { staticClass: "mif-cabinet mr-1" }),
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("Vmware ESXi / VCenter Account")) +
                        " "
                    ),
                  ]),
                ]),
                _c("SARepoSelect", {
                  attrs: {
                    type: _vm.destination.type,
                    idSA: _vm.destination.id_service_account,
                    idRepo: -2,
                  },
                  on: { sendSATestResult: _vm.sendSATestResult },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "form-group" }, [
              _c("label", [
                _vm._v(" " + _vm._s(_vm.$t("Target Datastore")) + ": "),
              ]),
              _vm.destination.id_service_account != -2 &&
              _vm.destination.datastoreList == null
                ? _c("div", {
                    staticClass: "m-4",
                    attrs: {
                      "data-role": "progress",
                      "data-cls-back": "bg-light",
                      "data-cls-bar": "bg-light",
                      "data-cls-buffer": "bg-cobalt",
                      "data-type": "line",
                      "data-small": "true",
                    },
                  })
                : _vm.destination.datastoreList
                ? _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.destinationData.datastore,
                          expression: "destinationData.datastore",
                        },
                      ],
                      staticClass: "select w-100",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.destinationData,
                              "datastore",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.getFoldersFromDatastore()
                          },
                        ],
                      },
                    },
                    _vm._l(
                      _vm.destination.datastoreList,
                      function (datastore, index) {
                        return _c(
                          "option",
                          {
                            key: datastore.datastore.name + "_" + index,
                            domProps: { value: datastore },
                          },
                          [
                            _vm._v(
                              " [" +
                                _vm._s(datastore.datacentername) +
                                "]/[" +
                                _vm._s(datastore.resourcename) +
                                "] " +
                                _vm._s(datastore.datastore.name) +
                                " [" +
                                _vm._s(datastore.datastore.freeSpace_string) +
                                "/" +
                                _vm._s(datastore.datastore.capacity_string) +
                                "] "
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
            ]),
            _vm.destinationData.datastore != null &&
            _vm.source.options.backupMode == "2DS"
              ? _c("div", { staticClass: "form-group" }, [
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("Destination folder on the Datastore")) +
                        ": "
                    ),
                  ]),
                  _vm.destinationData.datastore.datastore.folders == null
                    ? _c("div", {
                        staticClass: "m-4",
                        attrs: {
                          "data-role": "progress",
                          "data-cls-back": "bg-light",
                          "data-cls-bar": "bg-light",
                          "data-cls-buffer": "bg-cobalt",
                          "data-type": "line",
                          "data-small": "true",
                        },
                      })
                    : _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.destinationData.datastoreFolder,
                              expression: "destinationData.datastoreFolder",
                            },
                          ],
                          staticClass: "select w-100",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.destinationData,
                                "datastoreFolder",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(
                          _vm.destinationData.datastore.datastore.folders,
                          function (folder) {
                            return _c(
                              "option",
                              { key: folder.name, domProps: { value: folder } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(folder.name) +
                                    " (" +
                                    _vm._s(folder.path) +
                                    ")"
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                ])
              : _vm._e(),
            _vm.source.options.backupMode == "REP"
              ? _c("div", [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "Suffix to add to the name of the target virtual machine"
                        )
                      )
                    ),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.lazy",
                        value: _vm.destination.options.replicaName,
                        expression: "destination.options.replicaName",
                        modifiers: { lazy: true },
                      },
                      { name: "fsitem", rawName: "v-fsitem" },
                    ],
                    attrs: { type: "text", "data-role": "input" },
                    domProps: { value: _vm.destination.options.replicaName },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.destination.options,
                          "replicaName",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c("div", { staticClass: "dialog-actions" }, [
        _c("div", { staticClass: "ml-auto" }, [
          _c(
            "button",
            {
              staticClass: "button error float-right ml-2",
              on: {
                click: function ($event) {
                  return _vm.$root.$emit("CLOSESOURCEDIALOG")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("Close")))]
          ),
          _c(
            "button",
            {
              staticClass: "button",
              class: { disabled: _vm.step == 0 },
              on: {
                click: function ($event) {
                  _vm.step -= 1
                },
              },
            },
            [
              _c("span", { staticClass: "mif-arrow-left" }),
              _vm._v(" " + _vm._s(_vm.$t("Prev"))),
            ]
          ),
          _c(
            "button",
            {
              staticClass: "button",
              class: {
                disabled:
                  _vm.source.esxiSource == null ||
                  _vm.step == 4 ||
                  (_vm.step == 3 &&
                    _vm.source.options.backupMode != "2DS" &&
                    _vm.source.options.backupMode != "REP"),
              },
              on: {
                click: function ($event) {
                  _vm.step += 1
                },
              },
            },
            [
              _vm._v(_vm._s(_vm.$t("Next")) + " "),
              _c("span", { staticClass: "mif-arrow-right" }),
            ]
          ),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.step == 4 ||
                    (_vm.step == 3 &&
                      _vm.source.options.backupMode != "2DS" &&
                      _vm.source.options.backupMode != "REP"),
                  expression:
                    "step == 4 || (step == 3 && (source.options.backupMode != '2DS' && source.options.backupMode != 'REP'))",
                },
              ],
              staticClass: "button primary float-right",
              on: { click: _vm.saveSource },
            },
            [_vm._v(_vm._s(_vm.$t("Save")))]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }