/**
 * Note: 
 *  - per aprire una dialog dedicata ad uno dei seguenti componenti [source, destination, repository, serviceAccount], vedere i this.$root.$on del componente Dialogs:
 *  - all'inizio questi oggetti sono null,
 * - quando si vuole aprire la dialog dedicata, vengono popolati
 * - Se si vuole creare/modificare un oggetto, questo viene popolato/modificato dall'esterno del suo componente dedicato, per poi essere ritornato.
 */

import SelectedResourcesLayout from "../components/utils/SelectedResourcesLayout.vue";
import Navigation from "../components/utils/Navigation.vue";
import ObjectView from "../components/dialogs/view/ObjectView.vue";
import SourceView from "../components/dialogs/view/SourceView";
import { isValid, waitSeconds, prettyBytes } from '../utils/utilitiesmodule';
import NoDataAvailable from "../components/utils/NoDataAvailable.vue";
import { PC_MESSAGES } from '../utils/messages';
export default {
    components: {
        "SelectedResourcesLayout": SelectedResourcesLayout,
        Navigation,
        SourceView,
        NoDataAvailable,
        ObjectView
    },

    name: "Backup",
    data() {
        return {
            step: 1,
            //Questi dati servono all'interno delle funzioni
            //Questi dati sono generici e servono all'interno della pagina
            pc: null,
            selectedIndex: -1,
            realtime: {},
            backupSourcesTypes: [
                this.$PlatformsTypes.FileFolder,
                this.$PlatformsTypes.Network,
                this.$PlatformsTypes.DriveImage,
                this.$PlatformsTypes.HyperV,
                this.$PlatformsTypes.Microsoft,
                this.$PlatformsTypes.Database_SQLServer
            ],

            //Scheduling
            cronstrue: null,
            cronParser: null,
            cronOptions: {
                minutes: {
                    istab: "minutes/",
                    active: true,
                    value: 3, //il valore sono i minuti di intervallo
                },
                hourly: {
                    istab: "hourly/",
                    active: false,
                    value: 1,
                    radio: "every/", // every/ o at? 
                    at: {
                        hour: 12,
                        minute: 0,
                    }
                },
                daily: {
                    istab: "daily/",
                    active: false,
                    radio: "everyDay?", // everyDay? o weekdays?
                    at: {
                        hour: 12,
                        minute: 0,
                    }
                },
                weekly: {
                    istab: "weekly?",
                    active: false,
                    days: [],
                    at: {
                        hour: 12,
                        minute: 0,
                    }
                },
                monthly: {
                    istab: "monthly/",
                    active: false,
                    radio: "fixed", // fixed o recurrent
                    day: 1,
                    ofEvery: 1,
                    ddRank: "first",
                    ddDay: "mon",
                    ddMonth: 1,
                    at: {
                        hour: 12,
                        minute: 0,
                    }
                },
                yearly: {
                    istab: "yearly/",
                    active: false,
                    radio: "fixed",
                    day: 1,
                    ofEvery: 1,
                    ddRank: "first",
                    ddDay: "mon",
                    ddMonth: 1,
                    // es: /first/tue/ofEvery/3
                    at: {
                        hour: 12,
                        minute: 0,
                    }
                },
            },

            //BACKUP
            backupJob: null,

            editing: false,

            /*
            -2 : salvataggio non avviato
            -1 : salvataggio in corso
            0  : salvataggio fallito
            1  : salvataggio eseguito
            */
            saveBackupResult: -2,

            SAVEBACKUP_NOTSTARTED: -2,
            SAVEBACKUP_STARTED: -1,
            SAVEBACKUP_NOTSAVED: 0,
            SAVEBACKUP_SAVED: 1,

            cloudTypes: [],
            microsoftTypes: [],

            schedulationType: 0

        }
    },

    props: {
        idBackup: String,
        idPC: String
    },

    beforeRouteLeave(to, from, next) {


      

        if (this.backupJob == null || this.backupJob.sources.length == 0 || this.backupJob.destinations.length == 0  ) {
            next();
        }else{

            var dialogID = Math.floor(100000 + Math.random() * 900000).toString();
            var dialogTitle = this.$t("Exit from backup?");
            var dialogContent = this.$t('The backup is not saved and you will lose all the configuration you made, are you sure?');
            let self = this;
            this.$root.$on(dialogID, async ok => {
            if (ok) {
                next()
            } else {
                next(false)
            }
                self.$root.$off(dialogID);
            });

            //Avvio la richiesta che mi risponderà con un $emit
            this.$root.openYesNoDialog(dialogID, dialogTitle, dialogContent);
        }
    },
    beforeDestroy: function() {
        this.backupJob = null;
        this.pc = null;
        this.api_backups = [];
        this.$root.$off("socketEventConnected");
        this.$root.$off("socketEventDisconnected");
        //alert("destroy backup.JS");
        //Avvio un timer che controlla la connessione dei pc presenti in questa pagina
        //this.$root.checkConnectionTimerAction("stop");
        //this.$root.newPage("backup", "destroy");
        //CALLBACKS - JOB
        this.$root.$off("saveBackupSource");
        this.$root.$off("saveBackupDestination");
        //CALLBACKS - OTHER
        this.$root.$off("cancel");
        this.$root.$off("realtimeEvents");
        this.$root.$off("addBackupsToPage");

    },
    created: function() {
        //alert("CREATED COMPUTERS.JS");
        //this.$root.newPage("backup", "created");
        this.cloudTypes = this.$root.getCloudTypes();
        this.microsoftTypes = this.$root.getMicrosoftTypes();

    },
    filters: {
        prettyBytes: function(bytes, precision) {
            return prettyBytes(bytes, precision);
        },
    },
    mounted: function() {
        let self = this;
        self.cronstrue = require('cronstrue/i18n');
        self.cronParser = require('cron-parser');
        self.$root.socket("SETPC", this.idPC);
        self.$session.setRoomPC(self.$session.getPCWithID(this.idPC));
        self.pc = self.$session.getRoomPC();

        //###DA INSERIRE IN OGNI COMPONENTE CHE NECESSITA DI CHIAMATE SOCKET###
        // "checkSocket" avvia la connessione socket se ferma
        // "socketEventConnected" viene emesso quando la connessione socket è attiva, quindi nella sua callback si possono avviare tutte le istruzioni che riguardano le chiamate socket
        // "socketEventDisconnected" viene emesso quando la connessione socket non è attiva
        this.$root.$on("socketEventConnected", () => {
            self.$root.socketConnected = true;
            self.$root.checkConnectionTimerLight();
            self.$root.socket("subscribe_pc", this.idPC)
                .then(() => self.$root.socket("STARTSENDINFO"));
        });
        //"socketEventDisconnected" viene emesso se la connessione socket non è attiva
        this.$root.$on("socketEventDisconnected", () => {
            self.$root.socketConnected = false;
        });
        this.$root.checkSocket();

        //CALLBACKS - JOB
        this.$root.$on("saveBackupSource", (source) => self.saveBackupSource(source));
        this.$root.$on("saveBackupDestination", (destination) => self.saveBackupDestination(destination));
        //CALLBACKS - OTHER
        this.$root.$on("cancel", () => this.reset());
        this.$root.$on("realtimeEvents", data => console.log(data));

        //JOB
        if (this.idBackup == null) {
            self.backupJob = {
                id: null,
                id_computer: this.idPC,
                name: "",
                sources: [],
                destinations: [],
                scheduling: [],
                id_scheduling_policy: "",
                options: {},
            };
            return;
        }

        self.editing = true;
        this.$api.getBackupWithID(self.idBackup).then((value) => {
            self.backupJob = value;
            //se è stato recuperato un job e appartiene al pc corrente
            if (self.backupJob.id_computer == self.idPC) {
                return;
            }
            //se il job recuperato non corrisponde al pc corrente significa che:
            // è stato aperto un job in edit sul pc A (job.id_pc==A.id)
            // è stato cambiato il pc, da a a B
            self.$router.push("/computers/computer/" + self.idPC + "/backup/")
                //self.$router.push(this.$route.path.replace(job.id, ""));

            self.backupJob = {
                id: null,
                id_computer: self.idPC,
                name: "",
                sources: [],
                destinations: [],
                scheduling: [],
                id_scheduling_policy: "",
                options: {},
            }; /**/
        });
    },
    computed: {
        activeCronOption() {
            return this.getActiveCronOption();
        }
    },

    methods: {
        
    convertCronTo6Fields(cronExpression) {
            let parts = cronExpression.trim().split(" ");
            if (parts.length === 7) {
                parts.pop(); // Rimuove l'ultimo elemento (anno)
            }
            return parts.join(" ");
        },
       getActiveCronOption() {
            for (const key in this.cronOptions) {
                if (this.cronOptions[key].active === true) {
                    return key; // Restituisce il nome dell'opzione attiva
                }
            }
            return null; // Nessuna opzione attiva trovata
        },


        /*********************AZIONI SORGENTI/DESTINAZIONI ***********************************/
        isEnabled_source(type) {
            if (this.backupJob == undefined || !this.$root.connectionsStates[this.idPC] || this.backupJob.sources.length != 0)
                return false;

            if (type == this.$PlatformsTypes.Database_SQLServer && this.pc.system_info[this.$SystemInfoType.SQL_INSTANCES_INSTALLED].info_value == "")
                return false;

            if (type == this.$PlatformsTypes.HyperV && this.pc.system_info[this.$SystemInfoType.HYPER_V_INSTALLED].info_value == "False")
                return false;

            return true;
        },

        /** [TG - 27/03/2025] 
         *  modificata per controllo se modulo backup relativo alla sorgente è attivo
         *  se non è attivo, controllo se ci sono slot liberi, in caso affermativo assegno lo slot al computer in caso negativo rimando alla pagina per upgrade licenza o libera slot
         * 
        */
        openSource(type_or_source, index = -1) {

                //Sorgente con i sottotipi
                if (typeof type_or_source == 'number' && type_or_source == this.$PlatformsTypes.Microsoft)
                    return;

                let self = this;

                /** "type_or_source" corrisponde a "this.$PlatformsTypes" e sono gli stessi valori che troviamo nel nodo/array "enabled_backup_sources" dell'oggetto in sessione json "LICENSES" 
                 * enabled_backup_sources [
                        3,  --> Hyper-V
                        4   --> ESXI
                    ]
                * se nel json LICENSE-->backup-->computers[] c'è il computer corrente, vado a leggere tutti gli id delle Sorgenti di backup abilitate
                * se type_or_source, argomento di questa funzione, è compreso in "enabledBackupSources" vuol dire che posso fare configurare quel backup
                */

                const licenseData = this.$session.get("LICENSES"); 
                const computerId = this.$session.get("ROOMPC").id; 
            
                // Trova la licenza che contiene la sorgente richiesta
                const backupLicense = licenseData.backup.find(backupItem =>
                    backupItem.enabled_backup_sources.includes(type_or_source)
                );
            
                // Se la licenza di login non ha questo tipo di backup
                // Se non esiste una licenza per questo tipo di backup
                if (!backupLicense) {
                    var dialogID = Math.floor(100000 + Math.random() * 900000).toString();                                       
                        this.$root.$on(dialogID, async ok => {
                            try {                                
                                if (!ok) {
                                    return; // se utente clicca no la function si ferma qui.
                                }  
                                
                                self.$router.replace({ path: '/licenses' });
                                                
                            } finally {
                                self.$root.$off(dialogID);
                            }
                            return;                
                        }); 

                        //$platformsTypesInfo[sourceType].name
    
                        this.$root.openYesNoDialog(dialogID, this.$t("License Upgrade Required"), 
                             this.$t("license_not_supported",{0:self.$platformsTypesInfo[type_or_source].name}) );             
                    //return;
                }
                
            
                // Ottieni total_slots e used_slots
                const { total_slots, used_slots, computers } = backupLicense;
            
                // Verifica che il computer corrente sia abilitato nella licenza
                const isComputerLicensed = computers.some(computer => computer.id === computerId);
            
                // il computer corrente non è associato a nessun modulo licenza
                // devo chiedere all'utente se vuole utilizzare uno slot, se ci sono slot liberi.
                if (!isComputerLicensed) { 

                    // Controlla che ci siano slot disponibili
                    if (used_slots >= total_slots) { // qui messaggio che dice che deve liberare uno slot oppure fare upgrade della licenza
                        //this.$root.$emit('SHOW_MESSAGE', 'Hai esaurito gli slot disponibili per questo tipo di backup.');

                        var dialogID = Math.floor(100000 + Math.random() * 900000).toString();                 
                            this.$root.$on(dialogID, async ok => {
                                try {                                    
                                    if (!ok) {
                                        return;
                                    }             
                    
                                    self.$router.replace({ path: '/licenses' });
                                    
                                } finally {
                                    self.$root.$off(dialogID);
                                }
                                return;                
                            }); 
        
                            this.$root.openYesNoDialog(dialogID, this.$t("Computer Not Associated to Backup Module"), 
                                            this.$t("upgrade_license",{0:self.$platformsTypesInfo[type_or_source].name}));     
                        //return;
                    }else{
                        // qui messaggio per chiedere se vuole utilizzare lo slot
                        // se l'utente risponde si, faccio preseguire e aggiungo il pc alla licenza con 
                       
                        var dialogID = Math.floor(100000 + Math.random() * 900000).toString();                 
                            this.$root.$on(dialogID, async ok => {
                                try {                                    
                                    if (!ok) {
                                        return;
                                    }
                                    // se utente ha scelto si
                                    // chiamata RestAPI PUT "api/licenses/features/enable"
                                    //per entrambe nel body ci vuole:
                                    //idc : id computer
                                    //idf : id_feature
                                    self.$api.associateComputerToModule(computerId,backupLicense.id).then(async (value)  => {
                                        if (value) {
                                            self.$root.toast(this.$t(PC_MESSAGES.ADD_TO_MODULE), 5000, "success")
                                                                                           
                                                let ext_lic_info = await self.$api.getLicenseExtendedInfo();

                                                if (ext_lic_info != null) {
                                                    this.$session.setLicenses(ext_lic_info);
                                                    this.licenses = this.$session.get("LICENSES");
                                                };

                                        }                                            
                                    });  

                                } finally {
                                    self.$root.$off(dialogID);
                                }
                                return;                
                            }); 
        
                            this.$root.openYesNoDialog(dialogID, this.$t("Computer Not Associated to Backup Module"), 
                            this.$t("associate_to_module",{0:self.$platformsTypesInfo[type_or_source].name}));                                        
                    }

                }else{

                    // se abilitato apre la modale per configurare la sorgente 
                    
                    this.selectedIndex = index;
                    this.$root.$emit("OPENSOURCEDIALOG", type_or_source);
                }
        },
        deleteSource(index) {
            var dialogID = Math.floor(100000 + Math.random() * 900000).toString();
            let self = this;
            this.$root.$on(dialogID, ok => {

                if (!ok) {
                    self.$root.$off(dialogID);
                    return;
                }

                self.backupJob.sources.splice(index, 1);
                self.reset();
                self.$root.$off(dialogID);
            });

            this.$root.openYesNoDialog(dialogID, this.$t("Delete source"), this.$t("Are you sure to delete this source?"));
        },
        openDestination(type_or_destination, index = -1) {
            //destination con i sottotipi
            if (typeof type_or_destination == 'number' && type_or_destination == this.$PlatformsTypes.Cloud)
                return;
            this.selectedIndex = index;
            this.$root.$emit("OPENDESTINATIONDIALOG", type_or_destination);
        },
        deleteDestination(index) {
            var dialogID = Math.floor(100000 + Math.random() * 900000).toString();
            let self = this;
            this.$root.$on(dialogID, ok => {

                if (!ok) {
                    self.$root.$off(dialogID);
                    return;
                }

                self.backupJob.destinations.splice(index, 1);
                self.reset();
                self.$root.$off(dialogID);
            });

            this.$root.openYesNoDialog(dialogID, this.$t("Delete destination"), this.$t("Are you sure to delete this destination?"));
        },


        /********************************SALVATAGGIO***********************************************************************/
        //Codice predisposto solo per una sola sorgente!!
        async saveBackupSource(source) {
            if (this.backupJob.sources.length == 1) {
                this.backupJob.sources.splice(0, 1);
                await waitSeconds(1);
            }
            this.backupJob.sources.push(source);
            this.$root.$emit("CLOSESOURCEDIALOG");
            this.reset();
        },

        //Codice predisposto solo per una sola destinazione!!
        saveBackupDestination(destination) {
            if (this.backupJob.destinations.length == 1) {
                this.backupJob.destinations.splice(0, 1);
            }

            //Per evitare loop controllo se la destinazione interferisce con la sorgente
            // Se ho selezionato come sorgente C:\Documents
            //Non posso selezionare come destinazione C:\Documents o C:\Documents\Importanti
            if (this.backupJob.sources[0].type == destination.type) {
                var destinationPath = destination.path;
                var sourcePaths;
                if (this.backupJob.sources[0].type == this.$PlatformsTypes.FileFolder) {
                    sourcePaths = this.backupJob.sources[0].folderSource.paths.map(n => n.path);
                }

                if (this.backupJob.sources[0].type == this.$PlatformsTypes.Network) {
                    sourcePaths = this.backupJob.sources[0].networkSource.paths.map(n => n.path);
                }

                if (sourcePaths.some(sourcePath => destinationPath == sourcePath)) {
                    this.$root.toast(this.$t("You cannot select a path that is already included as a source item"), 6000, "alert");
                    return;
                }

                if (sourcePaths.some(sourcePath => destinationPath.startsWith(sourcePath + destination.type.getSeparator()))) {
                    this.$root.toast(this.$t("You cannot select a path that is a sub-path of a source item"), 6000, "alert");
                    return;
                }
            }

            this.backupJob.destinations.push(destination);
            this.$root.$emit("CLOSEDESTINATIONDIALOG");
            this.reset();
        },

        openTab(index) {
            this.step = index;
        },

        async sendBackupJob() {

            let self = this;

            var dialogID = Math.floor(100000 + Math.random() * 900000).toString();
            var dialogTitle = this.$t("Save Backup Job");
            var dialogContent = "<label>"+this.$t('Insert a name to save the Backup Job')+"</label><input type='text' placeholder='"+this.$t('backup name')+"' v-model='backupJob.name' id='backupJobName' class='input rounded border mt-2' maxlength='100' / >";
            this.$root.$on(dialogID, async ok => {
                if (ok) {
                    self.backupJob.name = document.getElementById("backupJobName").value;
                    if (self.backupJob.name == "") {
                        return;
                    }
                    self.saveBackupResult = this.SAVEBACKUP_STARTED; // salvataggio in corso
                    if (!isValid(this.backupJob.type)) this.backupJob.type = this.backupJob.sources.map(s => s.type).join("|");
                    if (!isValid(this.backupJob.id_computer)) this.backupJob.id_computer = this.idPC;

                    //Se il pc è offline, imposto il salvataggio come fallito
                    if (!this.$root.connectionsStates[this.idPC]) {
                        this.saveBackupResult = this.SAVEBACKUP_NOTSAVED;
                        return;
                    }

                    var isNew = !isValid(this.backupJob.id);
                    var method = isNew ? "CREATEBACKUP" : "EDITBACKUP";
                    

                    //Step 1 : Invio la richiesta all'API di salvare il job sul database
                    var api_response = !isValid(this.backupJob.id) ?
                        await this.$api.createBackup(this.backupJob) :
                        await this.$api.editBackup(this.backupJob)
                    var socket_response;

                    // Step 2 : Se il salvataggio sul database non è andato a buon fine, imposto il salvataggio come fallito
                    if (!api_response) {
                        this.saveBackupResult = this.SAVEBACKUP_NOTSAVED;
                        return;
                    }

                    //Aggiunto da veronica per fare i test con tipi nuovi che devono essere includi nelle API di andrea per essere riconosciuti
                    for (var s = 0; s < api_response.sources.length; s++) {
                        if (api_response.sources[s].type_name == "Unknown") {
                            api_response.sources[s].type_name = this.$platformsTypesInfo[api_response.sources[s].type].name;
                        }
                    }
                    //Step 3 : Se il salvataggio sul database è andato a buon fine, eseguo la richiesta socket
                    method = isNew ? "CreateJob" : "SaveJob";
                    socket_response = await this.$root.socket(method, api_response);

                    //Step 4 : Se il salvataggio non è andato a buon fine imposto il salvataggio come fallito 
                    if (!socket_response) {
                        this.saveBackupResult = this.SAVEBACKUP_NOTSAVED;
                        return;
                    }

                    //Step 5 : Se il salvataggio è andato a buon fine, imposto il salvataggio come riuscito e resetto il job
                    this.saveBackupResult = this.SAVEBACKUP_SAVED;
                    Metro.dialog.close($(".js-dialog-ok").parent().parent());

           
                } else {
                    return;
                }
                self.$root.$off(dialogID);
            });

            //Avvio la richiesta che mi risponderà con un $emit
            this.$root.openYesNoDialog(dialogID, dialogTitle, dialogContent, "Save", "Close", "js-dialog-ok primary","js-dialog-close");


        },

        /***************************SCHEDULING************************************************/
        toggleTab(objectToActivate) {
            //li resetto tutti...
            this.cronOptions.minutes.active = false;
            this.cronOptions.hourly.active = false;
            this.cronOptions.daily.active = false;
            this.cronOptions.weekly.active = false;
            this.cronOptions.monthly.active = false;
            this.cronOptions.yearly.active = false;
            //attivo quello selezionato
            objectToActivate.active = !objectToActivate.active;

        },

        setWizardStep(step) {
            if (step > 0 && step <= 4) {
                if (step == 2 && this.backupJob.sources.length == 0) {
                    if (this.step == 1) step = 3;
                    if (this.step == 3) step = 1;
                }
                if (step == 4 && (this.backupJob.sources.length == 0 || this.backupJob.destinations.length == 0)) {
                    return;
                }

                this.step = step;
                $("#wizardTabs").data("tabs").open(step);
            }
        },

        convertToCronParserFormat(cronExpression) {
            // Dividi la stringa cron in campi
            const cronParts = cronExpression.split(' ');

            // Se la stringa ha 7 campi, rimuoviamo il primo campo (secondi)
            if (cronParts.length === 7) {
                cronParts.shift(); // Rimuove il campo dei secondi
            }

            // Gestione dei campi non necessari (es: '?', '1/1') e conversione a 5 campi
            return cronParts
                .map((part, index) => {
                    // Rimuove '?' che non è supportato da cron-parser
                    if (part === '?') return '*';

                    // Rimuove '1/1' dai campi mese/giorno del mese
                    if (index >= 2 && (part === '1/1' || part === '1')) return '*';
                    return part;
                })
                .slice(0, 5) // Assicuriamo che ci siano solo 5 campi
                .join(' ');
        },

        generateCronFromRestString(restString) {
            // Rimuoviamo eventuali slash iniziali
            restString = restString.startsWith('/') ? restString.slice(1) : restString;

            // Suddividiamo l'URL in parti prima di "?"
            const urlParts = restString.split('?');
            const path = urlParts[0]; // La parte prima del "?"
            const query = urlParts[1] ? new URLSearchParams(urlParts[1]) : null;

            // Suddividiamo il path in parti
            const parts = path.split('/');
            const type = parts[0]; // Il tipo (yearly, monthly, daily, hourly, etc.)

            switch (type) {
                case 'minutes':
                    const minuteInterval = parts[1]; // es: every/2
                    return `0 0/${minuteInterval} * 1/1 * ? *`;

                case 'hourly':
                    if (parts[1] === 'at') {
                        // Estraiamo i parametri della query string (hour e minute)
                        const hour = query.get('hour');
                        const minute = query.get('minute');
                        return `0 ${minute} ${hour} 1/1 * ? *`;
                    } else if (parts[1] === 'every') {
                        const hourInterval = parts[2]; // es: every/1
                        return `0 0 0/${hourInterval} 1/1 * ? *`;
                    }
                    break;

                case 'daily':
                    if (parts[1] === 'everyDay') {
                        const hour = query.get('hour');
                        const minute = query.get('minute');
                        return `0 ${minute} ${hour} 1/1 * ? *`;
                    } else if (parts[1] === 'weekdays') {
                        const hour = query.get('hour');
                        const minute = query.get('minute');
                        return `0 ${minute} ${hour} ? * MON-FRI *`; // Settimana lavorativa
                    }
                    break;

                case 'weekly':
                    const days = query.get('days');
                    return `0 0 12 ? * ${days.toUpperCase()} *`;

                case 'monthly':
                    if (parts[1] === 'day') {
                        const day = parts[2];
                        const frequency = parts[4];
                        return `0 0 12 ${day} 1/${frequency} ? *`;
                    } else if (['first', 'second', 'third', 'fourth'].includes(parts[1])) {
                        const weekPosition = { 'first': 1, 'second': 2, 'third': 3, 'fourth': 4 }[parts[1]];
                        const dayOfWeek = parts[2];
                        const frequency = parts[4];
                        const hour = query.get('hour');
                        const minute = query.get('minute');
                        return `0 ${minute} ${hour} ? 1/${frequency} ${dayOfWeek.toUpperCase()}#${weekPosition} *`;
                    }
                    break;

                case 'yearly':
                    if (parts[1] === 'atDay') {
                        // Gestiamo il caso specifico /yearly/atDay/X/ofEvery/Y
                        const dayOfMonth = parts[2];
                        const frequency = parts[4];
                        const hour = query.get('hour');
                        const minute = query.get('minute');
                        return `0 ${minute} ${hour} ${dayOfMonth} ${frequency} ? *`;
                    } else if (['first', 'second', 'third', 'fourth'].includes(parts[1])) {
                        const weekPosition = { 'first': 1, 'second': 2, 'third': 3, 'fourth': 4 }[parts[1]];
                        const dayOfWeek = parts[2];
                        const month = parts[4];
                        const hour = query.get('hour');
                        const minute = query.get('minute');
                        return `0 ${minute} ${hour} ? ${month} ${dayOfWeek.toUpperCase()}#${weekPosition} *`;
                    }
                    break;

                default:
                    throw new Error('Tipo di cron non supportato.');
            }
        },

        removeSched(index) {
            this.backupJob.scheduling.splice(index, 1);
        },

        async refreshSched() {

            var self = this;
            var stringpath = this.createStringRest();
            console.log(stringpath);
            var newSched = this.generateCronFromRestString("/" + stringpath)
            console.log(newSched);
            if (newSched != null) {
                self.backupJob.scheduling.push(newSched);
                self.backupJob.id_scheduling_policy = ""; //Ripulisco l'eventuale policy impostata
                self.nextSched(newSched);
            }

        },

        nextSched(schedString) {
            var parser = require('cron-parser');
            try {
                var compatibleString = this.convertToCronParserFormat(schedString)

                var options = {
                    tz: this.$i18n.locale
                };

                var interval = parser.parseExpression(compatibleString, options);

                console.log('Date: ', interval.next().toString()); // Sat Dec 29 2012 00:42:00 GMT+0200 (EET)
                console.log('Date: ', interval.next().toString()); // Sat Dec 29 2012 00:44:00 GMT+0200 (EET)

                console.log('Date: ', interval.prev().toString()); // Sat Dec 29 2012 00:42:00 GMT+0200 (EET)
                console.log('Date: ', interval.prev().toString()); // Sat Dec 29 2012 00:40:00 GMT+0200 (EET)
            } catch (err) {
                console.log('Error: ' + err.message);
            }
        },


        /***************************AZIONI ATOMICHE*************************************************/
        reset() {
            this.selectedIndex = -1;
            this.$root.$emit("closeFileSystemSidebar");
        },
        createStringRest() {
            //return "monthly/day/21/ofEvery/3";
            for (const property in this.cronOptions) {
                console.log(property + " : " + this.cronOptions[property]);
                var string = "";
                if (this.cronOptions[property]["active"]) {

                    if (this.cronOptions[property]["istab"] == "minutes/") {
                        string = this.cronOptions[property]["istab"] + this.cronOptions[property]["value"];
                        return string;
                    }
                    if (this.cronOptions[property]["istab"] == "hourly/") {
                        string = this.cronOptions[property]["istab"] +
                            (this.cronOptions[property]["radio"] == "every/" ? "every/" + this.cronOptions[property]["value"] : "at?hour=" + this.cronOptions[property]["at"]["hour"] + "&minute=" + this.cronOptions[property]["at"]["minute"]);
                        return string;
                    }
                    if (this.cronOptions[property]["istab"] == "daily/") {
                        string = this.cronOptions[property]["istab"] + this.cronOptions[property]["radio"] + "hour=" + this.cronOptions[property]["at"]["hour"] + "&minute=" + this.cronOptions[property]["at"]["minute"];
                        return string;
                    }
                    if (this.cronOptions[property]["istab"] == "weekly?") {
                        string = this.cronOptions[property]["istab"] + "days=" + this.cronOptions[property]["days"] + "&hour=" + this.cronOptions[property]["at"]["hour"] + "&minute=" + this.cronOptions[property]["at"]["minute"];
                        return string;
                    }
                    if (this.cronOptions[property]["istab"] == "monthly/") {
                        string = this.cronOptions[property]["istab"] +
                            (this.cronOptions[property]["radio"] == "fixed" ? "day/" + this.cronOptions[property]["day"] + "/ofEvery/" + this.cronOptions[property]["ofEvery"] + "?hour=" + this.cronOptions[property]["at"]["hour"] + "&minute=" + this.cronOptions[property]["at"]["minute"] :
                                this.cronOptions[property]["ddRank"] + "/" + this.cronOptions[property]["ddDay"] + "/ofEvery/" + this.cronOptions[property]["ddMonth"] + "?hour=" + this.cronOptions[property]["at"]["hour"] + "&minute=" + this.cronOptions[property]["at"]["minute"]);
                        console.log(string);
                        return string;
                    }
                    if (this.cronOptions[property]["istab"] == "yearly/") {
                        string = this.cronOptions[property]["istab"] +
                            (this.cronOptions[property]["radio"] == "fixed" ? "atDay/" + this.cronOptions[property]["day"] + "/ofEvery/" + this.cronOptions[property]["ofEvery"] + "?hour=" + this.cronOptions[property]["at"]["hour"] + "&minute=" + this.cronOptions[property]["at"]["minute"] :
                                this.cronOptions[property]["ddRank"] + "/" + this.cronOptions[property]["ddDay"] + "/ofEvery/" + this.cronOptions[property]["ddMonth"] + "?hour=" + this.cronOptions[property]["at"]["hour"] + "&minute=" + this.cronOptions[property]["at"]["minute"]);
                        console.log(string);
                        return string;
                    }
                }
            }
        }
    }
}