import api from '../../router/api';
import { FileFolderEntityType, platformsTypesInfo, PlatformsTypes, ResultValue, SystemInfoType } from '../objects.js';
export function installVuePrototypes(Vue) {
    Vue.prototype.$api = api;
    Vue.prototype.$FileFolderEntityType = FileFolderEntityType;
    Vue.prototype.$PlatformsTypes = PlatformsTypes;
    Vue.prototype.$platformsTypesInfo = platformsTypesInfo;
    Vue.prototype.$ResultValue = ResultValue;
    Vue.prototype.$SystemInfoType = SystemInfoType;
    Vue.prototype.$session.getServiceAccounts = function() {
        if (!this.has("SERVICEACCOUNTS"))
            this.set("SERVICEACCOUNTS", []);
        return this.get("SERVICEACCOUNTS");
    }

    Vue.prototype.$session.getServiceAccountsWithTypeIn = function(types) {
        if (!this.has("SERVICEACCOUNTS"))
            this.set("SERVICEACCOUNTS", []);

        var serviceAccounts = this.get("SERVICEACCOUNTS");
        var filteredServiceAccounts = serviceAccounts.filter(sa => types.includes(sa.type));

        //Si ricorda che il tipo database è strettamente collegato al computer.
        //Quindi sto cercando i service account dei database
        if (types[0].isDatabase()) {
            //controllo se sono in un pc
            var roomPC = this.getRoomPC();
            //Se sono in un pc, ritorno i service account dei database di quel pc
            if (roomPC != null) {
                return filteredServiceAccounts.filter(sa => sa.options.lastTestPC == roomPC.id);
            }
        }

        return filteredServiceAccounts
    }

    Vue.prototype.$session.setServiceAccounts = function(list) {
        this.set("SERVICEACCOUNTS", list);
    }

    Vue.prototype.$session.getServiceAccountWithID = function(id) {
        var serviceAccounts = this.getServiceAccounts();
        var sa = serviceAccounts.find(sa => sa.id == id)
        if (sa == undefined)
            return null;
        return sa;
    }

    Vue.prototype.$session.addServiceAccount = function(sa) {
        var serviceAccounts = this.getServiceAccounts();
        serviceAccounts.push(sa);
        this.set("SERVICEACCOUNTS", serviceAccounts);
    }

    Vue.prototype.$session.editServiceAccount = function(sa) {
        var serviceAccounts = this.getServiceAccounts();

        var saIndex = serviceAccounts.findIndex(s => s.id == sa.id);
        if (saIndex == -1)
            serviceAccounts.push(sa);
        else
            serviceAccounts[saIndex] = sa;
        this.set("SERVICEACCOUNTS", serviceAccounts);

    }

    Vue.prototype.$session.deleteServiceAccount = function(idSA) {
        var serviceAccounts = this.getServiceAccounts();
        var saIndex = serviceAccounts.findIndex(s => s.id == idSA);
        if (saIndex == -1)
            return;
        serviceAccounts.splice(saIndex, 1);
        this.set("SERVICEACCOUNTS", serviceAccounts);

    }

    /*****************************SESSION - REPOSITORIES*****************************************************************************************************/
    Vue.prototype.$session.getRepositories = function() {
        return this.has("REPOSITORIES") ?
            this.get("REPOSITORIES") : [];
    }

    Vue.prototype.$session.getRepositoriesWithTypeIn = function(types) {
        if (!this.has("REPOSITORIES"))
            this.set("REPOSITORIES", []);
        var repositories = this.get("REPOSITORIES");
        return repositories.filter(repo => types.includes(repo.type))
    }

    Vue.prototype.$session.setRepositories = function(list) {
        this.set("REPOSITORIES", list);
    }

    Vue.prototype.$session.getRepositoryWithID = function(id) {
        var repositories = this.getRepositories();

        var repo = repositories.find(r => r.id == id)
        if (repo == undefined)
            return null;
        return repo;
    }

    Vue.prototype.$session.addRepository = function(repo) {
        var repositories = this.getRepositories();

        repositories.push(repo);
        this.set("REPOSITORIES", repositories);
    }

    Vue.prototype.$session.editRepository = function(repo) {
        var repositories = this.getRepositories();

        var repoIndex = repositories.findIndex(r => r.id == repo.id);
        if (repoIndex == -1)
            repositories.push(repo);
        else
            repositories[repoIndex] = repo;
        this.set("REPOSITORIES", repositories);

    }

    Vue.prototype.$session.deleteRepository = function(idRepo) {
        var repositories = this.getRepositories();

        var repoIndex = repositories.findIndex(r => r.id == idRepo);
        if (repoIndex == -1)
            return;
        repositories.splice(repoIndex, 1);
        this.set("REPOSITORIES", repositories);

    }

    /*****************************SESSION - COMPUTERS*****************************************************************************************************/
    Vue.prototype.$session.getPCList = function() {
        return this.has("COMPUTERS") ?
            this.get("COMPUTERS") : [];
    }

    Vue.prototype.$session.setPCList = function(list) {
        this.set("COMPUTERS", list);
    }

    Vue.prototype.$session.getPCWithID = function(idPC) {
        if (!this.has("COMPUTERS")) {
            return null;
        }

        //mappa {id :pc, ...}
        var pcs = this.get("COMPUTERS");
        var pc = pcs.find(x => x.id == idPC);
        if (pc == undefined) {
            return null;
        }
        return pc;
    }

    /*     Vue.prototype.$session.getPCsConnections = function() {
            return this.has("PCSCONNECTIONS") ?
                this.get("PCSCONNECTIONS") : [];
        }

        //{
        //    idPC : true/false
        //    ...
        //}
        Vue.prototype.$session.setPCsConnections = function(idPC_isConnected) {
            this.set("PCSCONNECTIONS", idPC_isConnected);
            var roomPC = this.getRoomPC();
            if (roomPC == null) {
                this.set("ONEISCONNECTED", Object.values(idPC_isConnected).some(isConnectedValue => isConnectedValue));
            } else {
                this.set("ONEISCONNECTED", idPC_isConnected[roomPC.id])
            }
        } */


    /*****************************SESSION - GROUPS*****************************************************************************************************/
    Vue.prototype.$session.getGroups = function() {
        return this.has("GROUPS") ?
            this.get("GROUPS") : [];
    }
    Vue.prototype.$session.setGroups = function(groups) {
            this.set("GROUPS", groups);
        }
        /*****************************SESSION - POLICIES*****************************************************************************************************/
    Vue.prototype.$session.getPolicies = function() {
        return this.has("POLICIES") ?
            this.get("POLICIES") : [];
    }

    Vue.prototype.$session.setPolicies = function(policies) {
        this.set("POLICIES", policies);
    }

    Vue.prototype.$session.deletePolicy = function(idPolicy) {
        var policies = this.getPolicies();

        var policyIndex = policies.findIndex(p => p.id == idPolicy);
        if (policyIndex == -1)
            return;
        policies.splice(policyIndex, 1);
        this.set("POLICIES", policies);
    }

    Vue.prototype.$session.getPolicyByID = function(idPolicy) {
        if (!this.has("POLICIES")) {
            return null;
        }

        //mappa {id :pc, ...}
        var policies = this.get("POLICIES");
        var policy = policies.find(x => x.id == idPolicy);
        if (policy == undefined) {
            return null;
        }
        return policy;
    }

    Vue.prototype.$session.getSchedulingPolicies = function() {
        var policies = this.getPolicies();
        if (policies.length != 0) {
            policies = policies.filter(p => p.id_type == 1);
        }
        return policies;
    }

    /*****************************SESSION - USERS*****************************************************************************************************/
    Vue.prototype.$session.getUsers = function() {
        return this.has("USERS") ?
            this.get("USERS") : [];
    }

    Vue.prototype.$session.setUsers = function(users) {
        this.set("USERS", users);
    }

    Vue.prototype.$session.saveSettings = function(userSettings) {
        this.set("userSettings", userSettings);
    }



    /*****************************SESSION - ROLES*****************************************************************************************************/
    Vue.prototype.$session.getRoles = function() {
        return this.has("ROLES") ?
            this.get("ROLES") : [];
    }

    Vue.prototype.$session.setRoles = function(roles) {
        this.set("ROLES", roles);
    }

    /*****************************SESSION - POLICY_TYPES*****************************************************************************************************/
    Vue.prototype.$session.getPolicyTypes = function() {
        return this.get("POLICY_TYPES") ?
            this.get("POLICY_TYPES") : [];
    }

    Vue.prototype.$session.setPolicyTypes = function(policyTypes) {
        this.set("POLICY_TYPES", policyTypes);
    }

    /*****************************SESSION - TYPES*****************************************************************************************************/
    Vue.prototype.$session.getTypes = function() {
        if (!this.has("TYPES"))
            return [];
        return this.get("TYPES");
    }

    Vue.prototype.$session.setTypes = function(types) {
        this.set("TYPES", types);
    }

    /*****************************SESSION - LICENSES*****************************************************************************************************/
    Vue.prototype.$session.getLicenses = function() {
        return this.has("LICENSES") ?
            this.get("LICENSES") : [];
    }

    Vue.prototype.$session.setLicenses = function(licenses) {
        this.set("LICENSES", licenses);
    }

    Vue.prototype.$session.getLicense = function() {
        return this.get("LICENSE");
    }

    Vue.prototype.$session.setLicense = function(license) {
        this.set("LICENSE", license);
    }

    /*****************************SESSION - CONNECTION STATES REMOTE*****************************************************************************************************/
    Vue.prototype.$session.getRemoteConnectionStates = function() {
        return this.get("REMOTECONNECTIONSTATES");
    }

    Vue.prototype.$session.setRemoteConnectionStates = function(connectionStates) {
        this.set("REMOTECONNECTIONSTATES", connectionStates);
    }

    /*****************************SESSION - SINGOLI DATI*****************************************************************************************************/
    Vue.prototype.$session.getRoomPC = function() {
        return this.get("ROOMPC");
    }

    Vue.prototype.$session.setRoomPC = function(pc) {
        this.set("ROOMPC", pc);
    }

    Vue.prototype.$session.getAuth = function() {
        if (this.has("AUTH"))
            return this.get("AUTH");
        return JSON.parse(localStorage.getItem("AUTH"));
    }

    Vue.prototype.$session.setAuth = function(auth) {
        this.set("AUTH", auth);
    }

    Vue.prototype.$session.getLastRemoteVersion = function() {
        return this.get("LASTREMOTEVERSION");
    }

    Vue.prototype.$session.setLastRemoteVersion = function(version) {
        this.set("LASTREMOTEVERSION", version);
    }




}