var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100 overflow" },
    [
      _c("Navigation", { attrs: { pageName: "customclient" } }),
      _vm.iframeLoaded
        ? _c("div", { staticClass: "d-flex flex-justify-center p-5" }, [
            _c("span", {
              staticClass: "mif-spinner2 fg-primary ani-spin mif-3x",
            }),
          ])
        : _vm._e(),
      _c("div", { staticClass: "row p-4" }, [
        _c("div", { staticClass: "col col-12" }, [
          _c("iframe", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.iframeLoaded,
                expression: "!iframeLoaded",
              },
            ],
            staticStyle: {
              height: "calc(100vh - 190px)",
              overflow: "auto",
              border: "none",
              display: "block",
            },
            attrs: {
              allowfullscreen: "",
              sandbox:
                "allow-popups allow-top-navigation allow-same-origin allow-scripts allow-modals allow-forms allow-downloads",
              src: _vm.parentIframeSrc,
              width: "100%",
              loading: "lazy",
            },
            on: { load: _vm.onIframeLoad },
          }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }