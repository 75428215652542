<template>
    <div>
        <div class="split-button m-1" v-if="pc">
            <button class="button m-0 p-0 bg-transparent"  >
                <!--router-link class="flat-button p-0 m-0" 
                    :class=" (!$root.connectionsStates[pc.id] && pc.backup_license_status != 99) ? 'disabled':''" 
                    :to="!$root.connectionsStates[pc.id] ? '#' :'/dashboard/computer/' + pc.id"  
                    :disabled="!$root.connectionsStates[pc.id]"-->
                <router-link class="flat-button p-0 m-0" :to="'/computers/computer/' + pc.id" >

                
                <div v-if="pc.description==''">
                    <span  :title="$t('Device Health') + ' : ' + $t(pc.av_alert +  pc.disk_alert == 0 ? $t('Good'):pc.av_alert +  pc.disk_alert == 1?$t('Some Warning'):$t('Errors'))" class="mif-2x"  :class="{ 'fg-green': pc.av_alert + pc.disk_alert == 0, 'fg-orange': pc.av_alert  + pc.disk_alert == 1, 'fg-red': pc.av_alert  + pc.disk_alert > 1 }"> ● </span>
                    <span class=" text-bold">{{ pc.name }}</span>
                </div>
                <div v-else class="text-left">
                    <div class="row m-0 d-flex flex-nowrap m-0 mt-2 row">
                        <div class="col ">
                             <span :title="$t('Device Health') + ' : ' + $t(pc.av_alert +  pc.disk_alert == 0 ? $t('Good') : pc.av_alert + pc.disk_alert > 2 ? $t('Some Warning') : $t('Errors'))" class="mif-2x"  :class="{ 'fg-green': pc.av_alert  + pc.disk_alert == 0, 'fg-orange': pc.av_alert  + pc.disk_alert == 1, 'fg-red': pc.av_alert  + pc.disk_alert >1 }"> ● </span>
                        </div>
                        <div class="col ">
                            <span class="ml-1  text-bold">{{ pc.name }}</span>
                            <span class="badge inline bg-transparent ml-0" style="display:block">{{ pc.description }}</span>
                        </div>

                    </div>
                </div>
         
             </router-link>
            </button>
        </div>
      
            
      
    </div>
</template>
<script>
export default {
    name: "PCLink",
    props: {
        pc: {
            type: Object,
            required: true
        }
    }
}
</script>