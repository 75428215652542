var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    {
      staticClass:
        "bd-primary border border-3 border-left-none border-right-none border-top-none w-100",
      attrs: { "data-role": "appbar", "data-expand-point": "xl" },
    },
    [
      _c(
        "div",
        {
          staticClass: "app-bar-container w-100 flex-justify-between",
          staticStyle: { "user-select": "none" },
        },
        [
          _c(
            "div",
            [
              _c("router-link", { attrs: { to: "/dashboard" } }, [
                _c("img", {
                  staticClass: "ml-7 mt-3",
                  attrs: {
                    src: "/assets/img/brand/iperius_one_v6.svg",
                    height: "100",
                    alt: "Iperius One",
                  },
                }),
              ]),
            ],
            1
          ),
          _c("ul", { staticClass: "bg-transparent h-menu" }, [
            _vm.user
              ? _c("li", [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-toggle app-bar-item",
                      attrs: { href: "#" },
                    },
                    [
                      _c("label", { staticClass: "pl-4" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$root.checkAuth()?.firstname +
                              " " +
                              _vm.$root.checkAuth()?.lastname +
                              " (" +
                              _vm.$root.checkAuth()?.username +
                              ")"
                          )
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "ul",
                    {
                      staticClass: "d-menu border w-100 bg-white",
                      attrs: { id: "userdropdown", "data-role": "dropdown" },
                    },
                    [
                      _c(
                        "li",
                        { staticClass: "bg-light" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "pl-5",
                              attrs: {
                                to: "/users#" + _vm.$root.checkAuth()?.id_user,
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "va-middle",
                                  attrs: {
                                    width: "24",
                                    height: "24",
                                    fill: "none",
                                    viewBox: "0 0 24 24",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M6.25 3A3.25 3.25 0 0 0 3 6.25v8.5A3.25 3.25 0 0 0 6.25 18h8.5A3.25 3.25 0 0 0 18 14.75v-8.5A3.25 3.25 0 0 0 14.75 3h-8.5Zm6.5 4.5a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm.75 3.25a1.5 1.5 0 0 1 1.5 1.5v.5c0 .496-.086.86-.254 1.16-.168.303-.43.562-.818.83-.733.503-1.858.76-3.428.76-1.32 0-2.445-.257-3.24-.76-.419-.266-.73-.524-.938-.83-.205-.3-.322-.664-.322-1.16v-.5a1.5 1.5 0 0 1 1.5-1.5h6Z",
                                      fill: "#212121",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M6.01 19a3.248 3.248 0 0 0 2.74 1.5h6.5c2.9 0 5.25-2.35 5.25-5.25v-6.5c0-1.15-.598-2.162-1.5-2.74v9.24A3.75 3.75 0 0 1 15.25 19H6.01Z",
                                      fill: "#212121",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(
                                " " + _vm._s(_vm.$t("User Profile")) + " "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "badge bg-primary fg-white inline ml-3",
                                },
                                [_vm._v(_vm._s(_vm.$root.checkAuth()?.roles))]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "li",
                        {},
                        [
                          _c(
                            "router-link",
                            { staticClass: "pl-5", attrs: { to: "/licenses" } },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "va-middle",
                                  attrs: {
                                    width: "24",
                                    height: "24",
                                    fill: "none",
                                    viewBox: "0 0 24 24",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M3 5.75V11c0 5.001 2.958 8.676 8.725 10.948a.75.75 0 0 0 .55 0C18.042 19.676 21 16 21 11V5.75a.75.75 0 0 0-.75-.75c-2.663 0-5.258-.943-7.8-2.85a.75.75 0 0 0-.9 0C9.008 4.057 6.413 5 3.75 5a.75.75 0 0 0-.75.75ZM13.995 11a2 2 0 0 1-1.245 1.852v2.398a.75.75 0 0 1-1.5 0v-2.394A2 2 0 1 1 13.995 11Z",
                                      fill: "#212121",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" " + _vm._s(_vm.$t("License")) + " "),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("li", {}, [
                        _c(
                          "a",
                          {
                            staticClass: "pl-5",
                            on: {
                              click: function ($event) {
                                return _vm.$parent.$parent.logout()
                              },
                            },
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "va-middle",
                                attrs: {
                                  width: "24",
                                  height: "24",
                                  fill: "none",
                                  viewBox: "0 0 24 24",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M2.75 4.504a.75.75 0 0 1 .743.648l.007.102v13.499a.75.75 0 0 1-1.493.101L2 18.753v-13.5a.75.75 0 0 1 .75-.75Zm12.46 1.883.083-.094a1 1 0 0 1 1.32-.083l.094.083 4.997 4.998a1 1 0 0 1 .083 1.32l-.083.093-4.996 5.004a1 1 0 0 1-1.499-1.32l.083-.094L18.581 13H6a1 1 0 0 1-.993-.883L5 12a1 1 0 0 1 .883-.993L6 11h12.584l-3.291-3.293a1 1 0 0 1-.083-1.32l.083-.094-.083.094Z",
                                    fill: "#212121",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" " + _vm._s(_vm.$t("Logout"))),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _c("li", [_c("locale-changer")], 1),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }