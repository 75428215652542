<template>
    <div class="dialog light center text-small w-100-sm w-100-md w-75-lg  w-50-xl draggable-item" v-cloak>

        <DialogTitle :title="serviceAccount.id == null ? $t('Add Service Account') : serviceAccount.name"
            :defaultTitle="serviceAccount.id == null ? $t('Add Service Account') : $t('Edit/Test Service Account')"
            :subTitle="serviceAccount.type_name" @closeDialog="$root.$emit('CLOSESERVICEACCOUNTDIALOG')" />


        <div class="dialog-content overflow bg-light">
            <div class="form-group d-flex">
                <div class="d-flex flex-column w-40">
                    <input type="radio" :data-caption="$t('Register an Azure application')" @click="registerApp = true" data-role="radio" name="r1" :checked="registerApp">
                    <input type="radio" :data-caption="$t('Use an existing Azure application')" @click="registerApp = false" data-role="radio" name="r1" :checked="!registerApp">
                </div>
                <!--ISTRUZIONI PER RECUPERARE UN'APPLICAZIONE AZURE-->
                <div v-show="!registerApp" class="text-secondary w-60 border bg-white border-1 bd-black p-4">
                    <h5 class="fg-cobalt">{{ $t("Choose an Azure application") }}</h5>
                    <p>
                        <span>{{ $t("1 - Access ") }}</span>
                        <a :href="chooseAppURL" target="_blank" :title="chooseAppURL">{{ $t("this link") }}</a>
                        <span>{{ $t(" with your organisation's administrator account") }}</span>
                    </p>
                    <p>{{ $t("2 - Click on the name of the Azure application you want to use") }}</p>
                    <p>{{ $t("3 - Fill in the following fields with the information of chosen application") }}</p>               
                </div>
                <!--ISTRUZIONI PER REGISTRARE UN'APPLICAZIONE AZURE-->
                <div v-show="registerApp" class="text-secondary w-60 border bg-white border-1 bd-black p-4">
                    <h5 class="fg-cobalt">{{ $t("Register an Azure application") }}</h5>
                    <p>
                        <span>{{ $t("1 - Access ") }}</span>
                        <a :href="registerAppURL" target="_blank" :title="registerAppURL">{{ $t("this link") }}</a>
                        <span>{{ $t(" with your organisation's administrator account") }}</span>
                    </p>
                    <p>{{ $t("2 - Enter a name for your application (e.g. IperiusONE Azure App)") }}</p>
                    <p>{{ $t("3 - Choose the type of account supported") }}</p>
                    <p>
                        <span>{{ $t("4 - Enter a Public client/Native redirect URI with the value: ") }}</span>
                        <span>
                            <span>{{ redirectURI }}</span><span class="badge mif-copy" @click="$root.copy(redirectURI)"></span>
                        </span>
                    </p>
                    <p>{{ $t("5 - Click on the ‘Register’ button to confirm your application") }}</p>
                    <p>{{ $t("6 - Fill in the following fields with the information of your newly registered application") }}</p>
                </div>
            </div>
            
            <div v-if="serviceAccount.type == $PlatformsTypes.Cloud_OneDrive" class="form-group">
                <label>{{ $t("User email") }}</label>
                <input data-role="input" v-model.trim="serviceAccount.username" type="text" required />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>
            <!--<div class="form-group">
                <label>{{ $t("Application name") }}</label>
                <input data-role="input" v-model.trim="serviceAccount.options.appName" type="text" required />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>-->
            <div class="form-group">
                <label>{{ $t("Application (client) ID") }}</label>
                <input data-role="input" v-model.trim="serviceAccount.client_id" type="text" required />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>
            <!--<div class="form-group">
                <label>{{ $t("Object ID") }}</label>
                <input data-role="input" v-model.trim="serviceAccount.options.objID" type="text" required />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>-->
            <div class="form-group">
                <label>{{ $t("Directory (tenant) ID") }}</label>
                <input data-role="input" v-model.trim="serviceAccount.options.tenant_id" type="text" required />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>
                
            <div class="form-group" :disabled="serviceAccount.id == null && serviceAccount.name == ''">
                <label>{{ $t("Name") }}</label>
                <input data-role="input" type="text" v-model.trim="serviceAccount.name" maxlength="50"
                    :placeholder="$t('enter name')" data-prepend="<span class='mif-pencil'></span>" />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>

            <!--------------MICROSOFT - MODERN AUTH ---------------------------->
            <!--15/01/24 : Tolta la basic per exchange 365-->
            <!--<div v-show="!serviceAccount.type.isExchangeOnPremises()">-->
                
                
                <!--<div class="form-group">
                    <label>{{ $t("Region") }}</label>
                    <select class="select w-auto" v-model="serviceAccount.region">
                        <option v-for="region in regions" :value="region.Url" :key="region.Url">{{
                            region.Name
                        }}</option>
                    </select>
                    <small class="text-muted">{{ $t("Required") }}</small>
                </div>-->
                <!--Visualizzato solo in edit, perchè si imposano automaticamente i permessi alla creazione-->
            <!--</div>-->

            <!------EXCHANGE ON PREMISES = username, password, server, domain, include child domains------------->
            <!--NON INCLUSO NELLA VERSIONE BETA DI IPERIUSONE-->
            <!--<div v-show="serviceAccount.type.isExchangeOnPremises()" class="form-group">
                <label>{{ $t("Server") }}</label>
                <input data-role="input" type="text" class="metro-input" v-model.trim="serviceAccount.options.server"
                    :placeholder="$t('Enter Server')" data-prepend="<span class='mif-cloud'></span>" />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>

            <div v-show="serviceAccount.type.isExchangeOnPremises()" class="form-group">
                <div class="row">
                    <div class="cell-8">
                        <label>{{ $t("Domain") }}</label>
                        <input data-role="input" class="metro-input" type="text" v-model.trim="serviceAccount.options.domain"
                            :placeholder="$t('Enter Domain')" data-prepend="<span class='mif-cloud'></span>" />
                        <small class="text-muted">{{ $t("Required") }}</small>
                    </div>
                    <div class="cell-4 pt-6">
                        <input data-role="checkbox" type="checkbox" :data-caption="$t('Include child domains')"
                            data-validate="required" v-model="serviceAccount.options.IncludeChildDomains" required
                            data-prepend="<span class='mif-server'></span>" />
                    </div>
                </div>
            </div>

            <div v-show="serviceAccount.type.isExchangeOnPremises()" class="form-group">
                <label>{{ $t("Username") }}</label>
                <input data-role="input" class="metro-input" v-model.trim="serviceAccount.username" type="text" required
                    data-prepend="<span class='mif-user'></span>" />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>

            <div v-show="serviceAccount.type.isExchangeOnPremises()" class="form-group">
                <label>{{ $t("Password") }}</label>
                <input data-role="input" class="metro-input" type="password" v-model.trim="serviceAccount.password"
                    :placeholder="$t('Enter Password')" data-prepend="<span class='mif-lock'></span>" />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>-->


            <!-------------------------------SCELTA PC----------------------------------------->
            <div v-if="serviceAccount.type.isExchangeOnPremises() && $session.getRoomPC() == null"
                class="form-group">
                <label>
                    <span>{{ $t("Select PC to Test Service Account") }}</span>
                </label>
                <select class="select cell-9" v-model="testPC" :disabled="$session.getPCList().length == 0">
                    <option :data-append="pc.note" v-for="pc in $session.getPCList()" :value="pc"
                        :disabled="!$root.connectionsStates[pc.id]" v-bind:key="pc.id"> {{ pc.name }}
                    </option>
                </select>
            </div>


        </div>

        <!-------------------------------TEST ACCOUNT----------------------------------------->
        <div class="dialog-actions d-flex " :class="step == null ? 'flex-justify-end' : 'flex-justify-between'">
            <div v-if="step != null" class="text-light fg-cobalt">{{ $t(step) }}</div>
            
            <!--Durante l'autenticazione a Exchange 365, verrà mostrato un messaggio che indica il corrente step -->
            <div class="d-flex flex-justify-end flex-align-center">
                <button v-if="serviceAccount.id != null" class="button primary" :disabled="testState == WAIT" @click="$root.duplicateServiceAccount(serviceAccount)">{{ $t("Duplicate") }}</button><!---->
                <button v-show="testState == OK" class="button primary" disabled> 
                    {{ $t("Test account") }}
                </button>                
                <div v-show="testState == WAIT"><span class="mif-spinner2 ani-spin mr-2" /></div>
                <button v-show="testState == NOTOK" class="button primary" @click="test" :disabled="!enableTest">
                    {{ $t("Test account") }}
                </button>

                <button class="button primary" :disabled="!enableSave" @click="$root.saveServiceAccount(serviceAccount)">
                   {{ $t("Save") }}
                </button>
                <button class="button alert" @click="$root.$emit('CLOSESERVICEACCOUNTDIALOG')">{{ $t("Close") }}</button>
                <button v-if="serviceAccount.id != null" class="button alert" :disabled="testState == WAIT" @click="$root.deleteServiceAccount(serviceAccount)">{{ $t("Delete") }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import DialogTitle from "../../utils/DialogTitle.vue";
import { cookieManager, isValid } from "../../../utils/utilitiesmodule";
import { draggableMixin } from '../../../mixins/draggable-mixin'


export default {

    mixins: [draggableMixin],
    name: "MicrosoftServiceAccount",
    components: {
        "DialogTitle": DialogTitle,
    },
    props: {
        serviceAccount: Object,
    },
    data() {
        return {
            registerApp: true,
            chooseAppURL: "https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationsListBlade",
            registerAppURL: "https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/CreateApplicationBlade/quickStartType~/null/isMSAApp~/false",
            redirectURI: window.location.origin,//"http://localhost:8080",
            testPC: null,
            testResult: null,
            // -1 : avviato
            // 0 : non avviato o fallito
            // 1 : ok
            testState: 0,
            NOTOK: 0,
            WAIT: -1,
            OK: 1,
            regions: [],

            step: null,
            readStepCookie: null,

            forbiddenChars: "!@#$%^&*()=+{}[]\\|;:\"'<>,.?/",
            forbiddenNames: [
                "Microsoft", "Azure", "Office", "Windows", "Dynamics", "Outlook", "Teams",
                "OneDrive", "SharePoint", "PowerBI", "VisualStudio", "MSFT", "MS",
                "WindowsServer", "Intune", "Exchange", "Google", "Gmail", "Android",
                "YouTube", "GoogleCloud", "Amazon", "AWS", "Alexa", "Prime",
                "AmazonWebServices", "Apple", "iCloud", "iPhone", "iPad", "iOS",
                "Facebook", "Instagram", "WhatsApp", "Oculus", "IBM", "Watson",
                "Oracle", "Java", "Salesforce", "Heroku", "LinkedIn", "Twitter",
                "Netflix", "Spotify", "Slack", "Zoom", "Adobe", "Dropbox", "GitHub",
                "Pinterest", "Snapchat", "TikTok", "Hulu", "PayPal", "Stripe",
                "Admin", "Support", "Service", "Helpdesk", "Root", "System",
                "Default", "User", "Guest"
            ]
        }
    },
    computed: {
        name() {
            if (!isValid(this.serviceAccount.username)||!isValid(this.serviceAccount.options.appName)) {
                return "";
            }
            this.serviceAccount.name=this.serviceAccount.username.split("@")[0]+" - "+this.serviceAccount.options.appName;
            return this.serviceAccount.name;
        },
        allInputCompleted() {
            var specificInputsCompleted;
            switch (this.serviceAccount.type) {
                case this.$PlatformsTypes.Microsoft_Exchange365:
                case this.$PlatformsTypes.Microsoft_SharePoint:
                case this.$PlatformsTypes.Microsoft_Teams:
                case this.$PlatformsTypes.Microsoft_OneDriveForBusiness:
                case this.$PlatformsTypes.Cloud_OneDrive:
                    specificInputsCompleted=
                        (this.serviceAccount.type!=this.$PlatformsTypes.Cloud_OneDrive||this.serviceAccount.username!="")&&
                        //this.serviceAccount.options.appName!=""&&
                        this.serviceAccount.client_id!=""&&
                        //this.serviceAccount.options.objID!=""&&
                        this.serviceAccount.options.tenant_id!="";
                    //this.serviceAccount.region!=""&&
                    break;
                case this.$PlatformsTypes.Microsoft_ExchangeOnPremises:
                case this.$PlatformsTypes.Microsoft_ExchangeOnPremisesEWS:
                    specificInputsCompleted=this.serviceAccount.server!=""&&
                        this.serviceAccount.domain!=""&&
                        this.serviceAccount.username!=""&&
                        this.serviceAccount.password!="";
                    break;
            }
            return (!this.serviceAccount.type.isExchangeOnPremises()||this.$session.getRoomPC()!=null||this.testPC!=null)
                &&specificInputsCompleted;//this.serviceAccount.name!=""&&
            //il pc è richiesto solo nel caso di exchange on premises

        },
        enableTest() {
            if (this.testState==this.OK)
                return false;

            return this.allInputCompleted&&this.testState==this.NOTOK;
        },
        enableSave() {

            if (!this.allInputCompleted) {
                return false;
            }

            if (this.serviceAccount.name=="") {
                return false;
            }
            //Durante il primo test, si assegnano i permessi
            if (this.serviceAccount.id==null&&this.testState==this.OK) {
                return true;
            }

            if (this.serviceAccount.id!=null) {
                return true;
            }

            return false;
        }

    },
    created() {
        if (this.serviceAccount.id!=null) {
            this.registerApp=false;
            return;
        }
        this.registerApp=true;
        this.testPC=this.$session.getPCWithID(this.serviceAccount.options.lastTestPC);
        this.serviceAccount.url=window.location.origin;
    },


    methods: {

        async test() {
            let self=this;
            self.testState=this.WAIT; // test in corso

            //Exchange On-Premises
            if (self.serviceAccount.type.isExchangeOnPremises()) {
                self.testState=(await this.$root.testServiceAccount(self.serviceAccount, self.testPC)).okNumber;
                return;
            }

            //Exchange 365 + ModernAuth

            var checkedName=this.validateAndReplace(self.serviceAccount.name);

            cookieManager.setCookie("step", "");
            //Se il service account è di tipo Exchange 265, avvio un timer che legge il cookie che conterrà un messaggio che descrive il corrente step
            self.readStepCookie=setInterval(
                () => { self.step=cookieManager.getCookie("step"); },
                1000
            );

            if (this.testResult==null||!this.testResult.ok) {
                this.testResult=await this.$root.testServiceAccount(self.serviceAccount);
            }

            self.testState=this.testResult.okNumber;
            if (this.testResult.ok) {
                self.serviceAccount.client_id=this.testResult.serviceAccount.client_id;
                self.serviceAccount.client_secret=this.testResult.serviceAccount.client_secret;
                self.serviceAccount.options["objID"]=this.testResult.serviceAccount.options.objID;
                self.serviceAccount.options["appName"]=this.testResult.serviceAccount.options.appName;
                self.serviceAccount.token=this.testResult.serviceAccount.token;
                self.serviceAccount.refresh_token=this.testResult.serviceAccount.refresh_token;
                self.serviceAccount.name=this.testResult.serviceAccount.options.appName;
            }

            clearInterval(self.readStepCookie);
            self.readStepCookie=null;
            self.step=cookieManager.getCookie("step");
        },

        validateAndReplace(inputString) {
            // Controllo se la stringa contiene un nome proibito
            const lowerCaseInput=inputString.toLowerCase();
            for (let name of this.forbiddenNames) {
                if (lowerCaseInput.includes(name.toLowerCase())) {
                    return false;
                }
            }

            // Sostituzione dei caratteri proibiti con lo spazio
            /*let processedString = inputString;
            for (let char of forbiddenChars) {
                processedString = processedString.split(char).join(' ');
            }*/
            for (let char of this.forbiddenChars) {
                inputString=inputString.split(char).join(' ');
            }
            inputString=inputString.trimStart().trimEnd();
            while (inputString.indexOf("  ")!=-1) {
                inputString=inputString.replace("  ", " ");
            }

            return inputString;
        }
    }
}

</script>