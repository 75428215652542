import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { VOffline } from 'v-offline';
import VueContext from 'vue-context'
import i18n from '@/plugins/i18n';
import FlagIcon from 'vue-country-flag'
import moment from 'moment';
import { EventBus } from './event-bus';
import VueSessionStorage from 'vue-session';
import { WebSocketClient } from './models/WebSocketClient';
import { SignalRClient } from './models/SignalRClient';
import { WebSocketInfo } from './models/WebSocketInfo';
import VueMoment from 'vue-moment';
import CountryFlag from 'vue-country-flag'
import VueApexCharts from 'vue-apexcharts';
import { isValid } from './utils/utilitiesmodule';
import { testServiceAccount } from './utils/serviceAccountsUtils/testAndClient.js';
import { selectValues } from './utils/selectvalues';
import "./utils/prototypes/simplePrototypes.js";
import "./utils//prototypes/clientTreeNodePrototypes.js";
import { installVuePrototypes } from './utils/prototypes/sessionPrototypes.js';
import { PC_MESSAGES, USER_MESSAGES, SERVICEACCOUNT_MESSAGES } from './utils/messages.js';
import { installVueDirectives } from './utils/vuedirectives.js';
import JSZip from 'jszip';


Vue.use(VueSessionStorage)

Vue.use(require('vue-cookies'))
Vue.use(VueMoment, { moment })
Vue.component('apexchart', VueApexCharts);
Vue.component('country-flag', CountryFlag)
Vue.config.productionTip=false
let appKey=0;
//Vue.$cookies.config('7d', '', '', true)

Vue.config.productionTip=false;

// Recupera l'ultima rotta salvata dalla session storage
const lastRoute=sessionStorage.getItem('lastRoute');
if (lastRoute&&lastRoute!=='/') {
    router.push(lastRoute); // Reindirizza alla rotta salvata
}

window.app=new Vue({
    router,
    store,
    i18n,
    VOffline,
    FlagIcon,
    EventBus,
    Metro,
    data() {
        return {
            signalr: null,
            websocket: null,
            checkConnectionTimer: null,
            connectionsStates: [],
            connectionsStatesRemote: [],
            logs:[],
            originalLog:null,
            originalError:null,
            originalWarn:null,
            originalInfo:null,
            atLeastOnePCConnectedToSocket: false, //indica se è connesso almeno un pc
            socketConnected: false,

            //Mappe utili
            selectValues: null,

            serviceAccounts: [],
            repositories: [],
            forceRefreshID: 0,
            //pc: null
        }
    },
    mounted() {
       
        this.originalLog = console.log;
        this.originalError = console.error;
        this.originalWarn = console.warn;
        this.originalInfo = console.info;

        const self = this;

        console.log = function (...args) {
            const time = new Date().toISOString();
            self.logs.push('\n[' + time +'] [LOG]\n ' + args.map(a => String(a)).join(' '));
            self.originalLog.apply(console, args);
        };

        console.error = function (...args) {
            const time = new Date().toISOString();
            self.logs.push('\n[' + time +'] [ERROR]\n ' + args.map(a => String(a)).join(' '));
            self.originalError.apply(console, args);
        };

        console.warn = function (...args) {
            const time = new Date().toISOString();
            self.logs.push('\n['+time+'] [WARN]\n ' + args.map(a => String(a)).join(' '));
            self.originalWarn.apply(console, args);
        };

        console.info = function (...args) {
            const time = new Date().toISOString();
            self.logs.push('\n[' + time +'] [INFO]\n ' + args.map(a => String(a)).join(' '));
            self.originalInfo.apply(console, args);
        };
    },


    methods: {

        handleShortcut(e) {
            if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() === 'o') {
                e.preventDefault(); // evita che il browser faccia altro
                this.salvaLogInZip();
            }
        },

        salvaLogInZip() {
            const time = new Date().toISOString();
            const zip = new JSZip();
            const logText = this.logs.join('\r\n');
            zip.file("log.txt", logText);

            zip.generateAsync({ type: "blob" })
                .then(function (blob) {
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = time+"-logs.zip";
                    a.click();
                    URL.revokeObjectURL(url);
                });
        },

        
        createhintGroups(groupsList, isString=false) {
            var hint="";
            groupsList.forEach(group => {
                hint+="• "+(isString? group.toUpperCase():group.name.toUpperCase())+"<br> ";
            });
            return hint.substring(0, hint.length-2);
        },

        format(time) {

            // Hours, minutes and seconds
            var hrs=~~(time/3600);
            var mins=~~((time%3600)/60);
            var secs=~~time%60;

            // Output like "1:01" or "4:03:59" or "123:03:59"
            var ret="";
            if (hrs>0) {
                ret+=""+hrs+":"+(mins<10? "0":"");
            }
            ret+=""+mins+":"+(secs<10? "0":"");
            ret+=""+secs;

            return ret;
        },

        //Veronica - 08/08/2024
        openYesNoDialog(id, title, content, okBtn_text=this.$t("Yes"), cancelBtn_text=this.$t("No"), okBtn_class="js-dialog-close primary", cancelBtn_class="js-dialog-close alert") {
            this.openDialogActions(id, title, content, okBtn_text, okBtn_class, true, cancelBtn_text, cancelBtn_class, false);
        },

        openDialogActions(id, title, content, okBtn_text, okBtn_class, okBtn_result, cancelBtn_text, cancelBtn_class, cancelBtn_result) {

            //var result = confirm(this.$t(title) +"\n"+ this.$t(content));
            //this.$root.$emit(id, result);

            let self=this;
            var okFunction=() => self.$root.$emit(id, okBtn_result);
            var cancelFunction=() => self.$root.$emit(id, cancelBtn_result);

            return Metro.dialog.create({
                title: title,
                content: content,
                clsDialog: "dialog light pos-center dialog-topmost",
                clsContent: "bg-light",
                closeAction: true,
                actions: [{
                    caption: this.$t(okBtn_text),
                    cls: okBtn_class,
                    onclick: okFunction
                },
                {
                    caption: this.$t(cancelBtn_text),
                    cls: cancelBtn_class,
                    onclick: cancelFunction
                }
                ],
                /* onClose: function(el) {
                    console.log(el);
                    this.$root.$emit(id, cancelBtn_result);

                } */
            });

        },

      


        openOnlyOkDialog(id, title, content, okBtn_text=this.$t("Ok"), okBtn_class="js-dialog-close primary") {
            this.openDialogOk(id, title, content, okBtn_text, okBtn_class, true);
        },

        openDialogOk(id, title, content, okBtn_text, okBtn_class, okBtn_result) {
            let self=this;

            const okFunction=() => self.$root.$emit(id, okBtn_result);

            return Metro.dialog.create({
                title: title,
                content: content,
                clsDialog: "dialog light pos-center dialog-topmost",
                clsContent: "p-10",
                closeAction: true,
                actions: [
                    {
                        caption: this.$t(okBtn_text),
                        cls: okBtn_class,
                        onclick: okFunction
                    }
                ]
            });
        },


        goBack() {
            window.history.length>1? this.$router.go(-1):this.$router.replace('/')
        },

        //se non ci sta nel local storage lo prendo dalla session
        checkAuth() {
            this.auth=JSON.parse(localStorage.getItem("AUTH"));
            if (this.auth==null) {
                this.auth=this.$session.getAuth();
            }
            return this.auth;
        },

        // Funzione per generare colori varianti dello stesso colore base
        generateBlueShades(baseColor, numberOfShades) {
            const colors=[];
            for (let i=1; i<=numberOfShades; i++) {
                const lightness=Math.round(i*(100/numberOfShades)); // Calcola la luminosità
                const color='hsl('+baseColor+',68%,'+lightness+'%)'; // Imposta la tonalità del blu a 210 (blu) e varia solo la luminosità
                colors.push(color);
            }
            console.log(colors);
            return colors;
        },

        /*****************************MESSAGGI*****************************************************************************************************/
        //Il messaggio deve essere già tradotto
        //Secondo la documentazione ufficiale di Metro UI (https://docs.metroui.org.ua/toast.html), clsToast deve essere uno dei seguenti valori : 
        // - primary (blu), 
        // - secondary (grigio)
        // - success (verde)
        // - alert (rosso)
        // - warning (arancione)
        // - yellow (giallo)
        // - info (celeste)
        // - light (grigio chiarissimo, quasi trasparente)
        toast(message, timeoutMs, level) {

            var options={
                showTop: true,
                position: "top",
                distance: 20,
                timeout: timeoutMs!=null? timeoutMs:3000,
                cls: level!=null? level.toLowerCase():"info"
            };
            Metro.toast.create(message, null, null, null, options);
        },

        /****************************INIZIO CHIAMATE SOCKET*********************************************************************************************************/
        /**
         * Implementazione
         * 1- In src/models/WebSocketInfo:
         *     1.2 - Aggiungere nel metodo buildInfo il case adeguato, in cui si implementa
         *         1.2.1 - Il caso in cui si chiama il metodo prima di effettuare la richiesta (forRequest=true + eventuali argomenti in arg1, arg2...)
         *         1.2.2 - Il caso in cui la risposta è andata a buon fine (forRequest=false + okResponse=true + arg1 eventuale dato ritornato dalla risposta).
         *                 Se la risposta è stata ricevuta con un oggetto in cui msg_type==data (vedere i msg_type in src/models/WebSocketClient.js -> onMessgeCallback), arg1 è il dato ricevuto.
         *                 Altrimenti okResponse è l'esito dela chiamata, quindi aggiungere in public/js/messages.js l'eventuale messaggio relativo alla buona riuscita della chiamata
         *         1.2.3 - Il caso in cui la risposta non è andata a buon fine (forRequest=false + okResponse=false). 
         *                 In questo caso verrà generato un toast per comunicare l'errore, quindi aggiungere in public/js/messages.js il relativo messaggio
         * Utilizzo: chiamare this.$root.socket("medoto_socket", array_di_argomenti_da_passare_al_messaggio_da_visualizzare, argomento_1, argomento_2 ecc).
         */
        async socket(message, arg1, arg2, arg3) {
            let self=this;
            if (this.websocket==null) {
                console.log("è stato tentato di fare la chiamata socket \""+message+"\" ma websocket è null");
                return;
            }

            //Se non è impostato l'id pc, provo ad impostarlo con il room pc se esiste
            if (this.websocket.idPC==null) {
                this.websocket.idPC=this.$session.getRoomPC()==null?
                    null:
                    this.$session.getRoomPC().id;
            }

            if (message.toUpperCase()=="SETPC") {
                this.websocket.idPC=arg1==null?
                    null:
                    typeof (arg1)=="string"?
                        arg1:
                        arg1.id; // messageargs è l'id o il pc da impostare
                return;
            }

            //Step 1 : Controllo la connessione. Se fallisce
            if (!this.socketConnected) {
                //Step 1.1 : si visualizza un toast per comunicare all'utente che non è connesso
                //this.connectionMessage(false);

                var currentPC=isValid(this.$session.getRoomPC())?
                    this.$session.getRoomPC()
                    //step 4.2 : se non sono in un pc ma è stato impostato un computer, comunico che tale computer non è connesso
                    :
                    this.$session.getPCWithID(this.websocket.idPC)

                // Step 1.2 : si ritorna il risultato di default
                var msgObj={
                    text: isValid(currentPC)? PC_MESSAGES.NOT_CONNECTED:USER_MESSAGES.NOT_CONNECTED,
                    msgType: "ERROR",
                    msgArgs: isValid(currentPC)? { 0: currentPC.pc_name }:null
                };

                var errorResult=WebSocketInfo.buildInfo(message.toUpperCase(), false, false, msgObj);
                return errorResult.result;
            }


            //Step 2 : Si tenta la chiamata.
            var info=await self.websocket.call(message, arg1, arg2, arg3);
            // Step 3 : Se l'oggetto ritornato non ha un messaggio che deve essere visualizzato, ritorno subito il risultato
            if (!isValid(info.message)) {
                return info.result;
            }

            //Step 4 : Se l'oggetto ritornato ha un messaggio che deve essere ritornato che comunica che il computer non è connesso
            //devo impostare come argomento del messaggio iò nome del pc
            if (info.message.text==PC_MESSAGES.NOT_CONNECTED) {
                //Step 4.1 : se sono in un pc, comunico che roompc non è connesso
                var currentPC=isValid(this.$session.getRoomPC())?
                    this.$session.getRoomPC():
                    //step 4.2 : se non sono in un pc ma è stato impostato un computer, comunico che tale computer non è connesso
                    isValid(this.websocket.idPC)?
                        this.$session.getPCWithID(this.websocket.idPC):
                        null;
                //Step 4.3 : Se non ho alcuna imformazione sul computer, ritorno il risultato
                if (currentPC==null)
                    return info.result;

                info.message.msgArgs={ 0: currentPC.pc_name };
            }

            // Caso 3.3 : Se il messaggio da visualizzare non ha argomenti, creo un toast normale
            if (info.message.msgArgs==null) {
                this.toast(this.$t(info.message.text), 3000, info.message.msgType);
            } else {
                // Caso 3.4 : Se il messaggio da visualizzare ha argomenti, creo un toast formattato
                this.toast(this.$t(info.message.text, info.message.msgArgs), 3000, info.message.msgType);
            }
            //Step 4 : Restituisco il risultato
            return info.result;
        },


        checkSocket() {
            let self=this;
            //Caso 1 : Non è stata mai instanziata la connessione: creo la connessione
            if (self.websocket==null) {
                console.log("websocket è null!!!!");
                self.websocket=new WebSocketClient(this.checkAuth(), this.emitFunction, this.translateFunction);
            }
            self.websocket.checkConnection();
        },


        async closeSocket() {
            if (this.websocket!=null&&this.websocket.websocket!=null) {
                await this.websocket.websocket.close();
            }
            this.websocket=null;
        },

        async checkSignalR(idPC) {
            let self=this;

            if (self.signalr==null) self.signalr=await (new SignalRClient(process.env.VUE_APP_SIGNALR_BASE_URL+"iperiusHub?client=C_"+idPC, idPC, self.$root.$emit, this.$t)).connect();

            return self.signalr!=null;
        },

        /********************************************************************************************************************************************/
        emitFunction(message, data) {
            if (message!="realtimeMessages") {
                this.$root.$emit(message, data);
                return;
            }
            if (data.msgArgs==null) {
                this.toast(this.$t(data.text), 3000, data.msgType);
                return;
            }
            //TODO : Deve correggerlo andrea sul server
            //Adesso manda l'array ma $t vuole un oggetto
            var argsToObj={};
            for (var i=0; i<data.msgArgs.length; i++) {
                argsToObj[i]=data.msgArgs[i];
            }
            this.toast(this.$t(data.text, argsToObj), 3000, data.msgType);
        },

        translateFunction(message) {
            return this.$t(message);
        },

        generateUniqueId() {
            return Math.floor(100000+Math.random()*900000).toString();
        },

        sendNotification(title, options) {
            if (Notification.permission==='granted') {
                new Notification(title, options);

                // Esempio di utilizzo
                /*  sendNotification('Benvenuto!', {
                     body: 'Grazie per aver visitato la nostra web app!',
                     icon: '/path/to/icon.png' // Icona per la notifica
                 }); */
            }
        },



        requestNotificationPermission() {
            console.log('Richiesta di permesso per le notifiche');
            console.log(event);

            if ('Notification' in window) {
                if (Notification.permission==='granted') {
                    console.log('Notifiche abilitate!');
                } else {

                    Notification.requestPermission().then(permission => {
                        if (permission==='granted') {
                            console.log('Notifiche abilitate!');

                            //aggiorno onesignal con il token externalID
                            OneSignal.login(this.checkAuth().id_user).then((value) => {
                                console.log(value);
                                OneSignal.User.setLanguage(this.$i18n.locale);
                            });


                        } else {
                            console.log('Notifiche negate!');
                        }
                    });
                }
            } else {
                console.error('Le notifiche non sono supportate dal browser.');
            }
        },



        //MA: aggiornamento checkConnectionTimerAction con questo semplificato
        async checkConnectionTimerLight() {
            let self=this;
            //Il timer attivo è di tipo number in javascript
            if (typeof (self.checkConnectionTimer)=='number'||!isValid(self.$session.getPCList())) {
                return;
            }

            self.checkConnectionTimer=null;
            var roomPC;
            self.checkConnectionTimer=setInterval(async () => {
                //Ritorna un oggetto in cui le chiavi sono gli id dei computer e i valori sono booleani che indicano se quel pc è connesso o no
                self.connectionsStates=await self.socket("is_online", self.$session.getPCList().map(pc => pc.id));
                //Alcune operazioni sono disattivate se tutti i computer sono disconnessi.
                //Quindi è utile memorizzare una variabile che indica se c'è almeno un pc connesso
                //Se sono in un pc, mi interessa sapere se questo è connesso
                roomPC=self.$session.getRoomPC();
                if (isValid(roomPC)) {
                    this.atLeastOnePCConnectedToSocket=self.connectionsStates[roomPC.id];
                    return;
                }
                //Se non sono in un pc, controllo la risposta di "is_online", che sarà "true" se c'è almeno un valore idPC: true
                this.atLeastOnePCConnectedToSocket=Object.values(self.connectionsStates).some(isConnectedValue => isConnectedValue);
            }, 5000);

        },

        blockSpaces(event) {
            // Prevenire l'inserimento dello spazio
            if (event.key===" ") {
                event.preventDefault();
            }
        },


        closeConnectionTimerLight() {
            this.$root.$off("is_online");
            clearInterval(this.checkConnectionTimer);
        },

        async getOTP(idPC) {
            var Key_OTP=await this.$api.getOTP(idPC);
            console.log(Key_OTP);

            var win=window.open('iperiusremote://'+Key_OTP+'/', '_blank');
            win.getValue=function() {
                return Key_OTP;
            };
        },


        /**********************************SERVICEACCOUNT*****************************************************************************************************/
        async testServiceAccount(serviceAccount, pc) {
            var response={
                ok: false,
                okNumber: 0,
                serviceAccount: serviceAccount,
                list: null,
                client: null
            };

            //Cloud
            if (serviceAccount.type.isCloud()||serviceAccount.type.isMicrosoft365()) {
                var realLastRoute=sessionStorage.getItem('lastRoute');
                //Alcune richieste token necessitano di un reinderezzamento su /
                sessionStorage.setItem('lastRoute', "/");
                response=await testServiceAccount(serviceAccount);
                sessionStorage.setItem('lastRoute', realLastRoute);

                if (!isValid(response.serviceAccount))
                    response.serviceAccount=serviceAccount;
                if (!response.ok) {
                    return response;
                }
                if (isValid(response.serviceAccount.id)) {
                    this.$api.editServiceAccount(response.serviceAccount);
                    this.$session.editServiceAccount(response.serviceAccount);
                }
                return response;
            }


            //Raggruppo tutti i service account i cui test necessitano di una chiamata socket, quindi di un pc
            //Questo pc sarà:
            // - this.$session.getRoomPC() se sono dentro un computer
            // - pc passato se + stato passato un computer con cui eseguire il test
            // - serviceAccount.options.lastTestPC (è un id di un computer) se è stato salvato nel service account ed è attualmente connesso
            if (isValid(this.$session.getRoomPC()))
                pc=this.$session.getRoomPC();
            else if (isValid(pc))
                await this.socket("SETPC", pc);
            else if (isValid(serviceAccount.options.lastTestPC)&&this.connectionsStates[serviceAccount.options.lastTestPC]) {
                pc=this.$session.getPCWithID(serviceAccount.options.lastTestPC);
                await this.socket("SETPC", pc);
            } else {
                this.toast(this.$t(SERVICEACCOUNT_MESSAGES.TEST_FAILED), 5000, "alert");
                return response;
            }

            //EMAIL
            if (serviceAccount.type.isEmail()) {
                response.ok=await this.socket("SendEmailTest", serviceAccount, serviceAccount.options.recipient);
                response.okNumber=response.ok? 1:0;
            } else

                //EXCHANGE ON PREMISES
                if (serviceAccount.type.isExchangeOnPremises()) {
                    response.ok=await this.socket("testserviceaccount", serviceAccount);
                    response.okNumber=response.ok? 1:0;

                    if (response.ok&&isValid(serviceAccount.id)) {
                        response.list=await this.socket("GetExchangeMailboxes", serviceAccount.id);
                        response.ok=response.list!=null;
                        response.okNumber=response.ok? 1:0;
                    }

                } else

                    //DATABASE
                    if (serviceAccount.type.isDatabase()) {
                        response.ok=await this.socket("testserviceaccount", serviceAccount);
                        response.okNumber=response.ok? 1:0;

                        if (response.ok&&isValid(serviceAccount.id)) {
                            response.list=await this.socket("GetDatabaseList", serviceAccount.id);
                            response.ok=response.list!=null;
                            response.okNumber=response.ok? 1:0;
                        }


                    } else {
                        var requestData;
                        var fsOptions={ // recupero il meno possibile perchè è un test
                            includeFiles: false,
                            includeHiddenFiles: false,
                            includeInaccessibleFiles: false,
                            includeSystemFiles: false,
                            includeDetails: false
                        };
                        switch (serviceAccount.type) {
                            case this.$PlatformsTypes.FTP:
                                var accountSessionId_FTP=await this.socket("connectftp", serviceAccount);

                                if (accountSessionId_FTP=="") {
                                    response.ok=false;
                                    response.okNumber=0;
                                    break;
                                }
                                response.ok=true;
                                response.okNumber=1;
                                serviceAccount.options.accountSessionId=accountSessionId_FTP;
                                response.serviceAccount=serviceAccount;
                                requestData={
                                    accountSessionId: accountSessionId_FTP,
                                    path: serviceAccount.url,
                                    options: fsOptions,
                                    nodeID: "node"
                                };
                                response.list=await this.socket("exploreftp", requestData);
                                if (response.list==null) {
                                    response.ok=false;
                                    response.okNumber=0;
                                    break;
                                }
                                response.ok=true;
                                response.okNumber=1;
                                response.client={ "serviceAccount": serviceAccount };
                                break;
                            case this.$PlatformsTypes.ESXi:
                                response.ok=await this.socket("testserviceaccount", serviceAccount);
                                if (response.ok)
                                    response.okNumber=1;
                                //non richiedo la lista poiché la chiamata è molto lenta
                                break;

                            case this.$PlatformsTypes.Network:
                                var accountSessionId_NETWORK=await this.socket("connectnetwork", serviceAccount);

                                if (accountSessionId_NETWORK=="") {
                                    response.ok=false;
                                    response.okNumber=0;
                                    break;
                                }
                                response.ok=true;
                                response.okNumber=1;
                                serviceAccount.options.accountSessionId=accountSessionId_NETWORK;
                                response.serviceAccount=serviceAccount;

                                requestData={
                                    accountSessionId: accountSessionId_NETWORK,
                                    path: serviceAccount.options.path,
                                    options: fsOptions,
                                    nodeID: "node"
                                };
                                //ritorna la lista di ClientTreeNode
                                response.list=await this.socket("getexplorenetworkfs", requestData);
                                if (response.list==null) {
                                    response.ok=false;
                                    response.okNumber=0;
                                    break;
                                }
                                response.ok=true;
                                response.okNumber=1;
                                response.client={ "serviceAccount": serviceAccount };
                                break;
                        }
                    }

            if (this.$session.getRoomPC()==null)
                await this.socket("SETPC", null);

            if (response.ok) {
                response.serviceAccount.options.lastTestPC=pc.id;
                if (isValid(response.serviceAccount.id))
                    this.$api.editServiceAccount(response.serviceAccount);
                return response;
            }
            return response;
        },

        async saveServiceAccount(serviceAccount) {
            var newServiceAccount=isValid(serviceAccount.id)?
                await this.$api.editServiceAccount(serviceAccount):
                await this.$api.createServiceAccount(serviceAccount);

            if (!isValid(newServiceAccount)) {
                return false;
            }

            if (!isValid(serviceAccount.id)) {
                this.$session.addServiceAccount(newServiceAccount);
            } else {
                this.$session.editServiceAccount(newServiceAccount);
            }

            this.$root.$emit("REFRESHSERVICEACCOUNTS", newServiceAccount);
            this.$root.$emit('CLOSESERVICEACCOUNTDIALOG');
            return true;
        },

        duplicateServiceAccount(idSA_or_SA) {
            var serviceAccount;
            if (typeof idSA_or_SA=="string") {
                serviceAccount=this.$session.getServiceAccountWithID(idSA_or_SA);
            } else {
                serviceAccount=idSA_or_SA
            }

            var newServiceAccount=serviceAccount
            newServiceAccount.id=null;
            var date=new Date();
            newServiceAccount.name=newServiceAccount.name+" "+date.getDate()+""+(date.getMonth()+1)+""+date.getFullYear()+""+date.getHours()+""+date.getMinutes()+""+date.getSeconds();

            var self=this;

            var dialogID=Math.floor(100000+Math.random()*900000).toString();
            var dialogTitle=this.$t("Duplicate Service Account \"{0}\"", { 0: serviceAccount.name });
            var dialogContent=this.$t('Are you sure to duplicate service account \"{0}\"? ', { 0: serviceAccount.name });

            this.$root.$on(dialogID, async ok => {
                var closeDialog=true;
                try {
                    if (!ok) {
                        return;
                    }

                    ok=await self.saveServiceAccount(newServiceAccount);

                    if (ok) {
                        return;
                    }

                    //Tengo aperta la dialog per consentire all'utente di riprovare
                    closeDialog=false;

                } finally {
                    if (closeDialog) {
                        self.$root.$off(dialogID);
                        self.$router.removeHash(); // prototype implementato in main.js
                        //location.reload();
                    }
                }
            });

            //Avvio la richiesta che mi risponderà con un $emit
            this.openYesNoDialog(dialogID, dialogTitle, dialogContent)
        },

        deleteServiceAccount(idSA_or_SA) {
            var self=this;
            var serviceAccount;
            if (typeof idSA_or_SA=="string") {
                serviceAccount=this.$session.getServiceAccountWithID(idSA_or_SA);
            } else {
                serviceAccount=idSA_or_SA;
            }
            var dialogID=Math.floor(100000+Math.random()*900000).toString();
            var dialogTitle=this.$t("Delete Service Account \"{0}\"", { 0: serviceAccount.name });
            var dialogContent=this.$t('Are you sure to delete service account \"{0}\"?', { 0: serviceAccount.name });

            this.$root.$on(dialogID, async ok => {
                var closeDialog=true;
                try {
                    if (!ok) {
                        return;
                    }

                    ok=await self.$api.deleteServiceAccount(serviceAccount.id, serviceAccount.name);

                    if (!ok) {
                        closeDialog=false;
                        return;
                    }

                    self.$session.deleteServiceAccount(serviceAccount.id);
                    this.$root.$emit("REFRESHSERVICEACCOUNTS");

                } finally {
                    if (closeDialog) {
                        self.$root.$off(dialogID);
                        self.$router.removeHash(); // prototype implementato in main.js
                        //location.reload();
                    }
                }
            });

            //Avvio la richiesta che mi risponderà con un $emit
            this.openYesNoDialog(dialogID, dialogTitle, dialogContent)

        },

        /**********************************REPOSITORY*****************************************************************************************************/
        async saveRepository(repository) {
            var newRepository=isValid(repository.id)?
                await this.$api.editRepository(repository):
                await this.$api.createRepository(repository);


            if (!isValid(newRepository)) {
                return;
            }

            if (!isValid(repository.id)) {
                this.$session.addRepository(newRepository);
            } else {
                this.$session.editRepository(newRepository);
            }

            this.$root.$emit('CLOSEREPOSITORYDIALOG');
            this.$root.$emit("REFRESHREPOSITORIES", newRepository);

        },
        duplicateRepository(idRepo) {
            var repository=this.$session.getRepositoryWithID(idRepo);
            var newRepository=repository
            newRepository.id=null;
            var date=new Date();
            newRepository.name=newRepository.name+" "+date.getDate()+""+(date.getMonth()+1)+""+date.getFullYear()+""+date.getHours()+""+date.getMinutes()+""+date.getSeconds();

            var self=this;

            var dialogID=Math.floor(100000+Math.random()*900000).toString();
            var dialogTitle=this.$t("Duplicate Repository \"{0}\"", { 0: repository.name });
            var dialogContent=this.$t('Are you sure to duplicate repository \"{0}\"? ', { 0: repository.name });

            this.$root.$on(dialogID, async ok => {
                var closeDialog=true;
                try {
                    if (!ok) {
                        return;
                    }

                    ok=await self.saveRepository(newRepository);

                    if (ok) {
                        return;
                    }

                    //Tengo aperta la dialog per consentire all'utente di riprovare
                    closeDialog=false;

                } finally {
                    if (closeDialog) {
                        self.$root.$off(dialogID);
                        self.$router.removeHash(); // prototype implementato in main.js
                        //location.reload();
                    }
                }
            });

            //Avvio la richiesta che mi risponderà con un $emit
            this.openYesNoDialog(dialogID, dialogTitle, dialogContent)
        },

        deleteRepository(idRepo) {
            var self=this;
            var repository=this.$session.getRepositoryWithID(idRepo);

            var dialogID=Math.floor(100000+Math.random()*900000).toString();
            var dialogTitle=this.$t("Delete Repository \"{0}\"", { 0: repository.name });
            var dialogContent=this.$t('Are you sure to delete repository \"{0}\"?', { 0: repository.name });

            this.$root.$on(dialogID, async ok => {
                var closeDialog=true;
                try {
                    if (!ok) {
                        return;
                    }

                    ok=await self.$api.deleteRepository(repository.id, repository.name);

                    if (!ok) {
                        closeDialog=false;
                        return;
                    }

                    self.$session.deleteRepository(idRepo);
                    this.$root.$emit("REFRESHREPOSITORIES");

                } finally {
                    if (closeDialog) {
                        self.$root.$off(dialogID);
                        self.$router.removeHash(); // prototype implementato in main.js
                        //location.reload();
                    }
                }
            });

            //Avvio la richiesta che mi risponderà con un $emit
            this.openYesNoDialog(dialogID, dialogTitle, dialogContent)

        },
        /***********************************************************TIPI*************************************************************/
        getAllBackupSourceTypes() {
            return Object.keys(this.$platformsTypesInfo)
                .filter(t => this.$platformsTypesInfo[t].forBackupSource&&this.$platformsTypesInfo[t].enabled)
                .map(t => parseInt(t));
        },
        getAllBackupDestinationTypes() {
            return Object.keys(this.$platformsTypesInfo)
                .filter(t => this.$platformsTypesInfo[t].forBackupDestination&&this.$platformsTypesInfo[t].enabled)
                .map(t => parseInt(t));
        },

        getMicrosoftTypes() {
            return Object.keys(this.$platformsTypesInfo).filter(t => this.$platformsTypesInfo[t].mainType==this.$PlatformsTypes.Microsoft&&this.$platformsTypesInfo[t].enabled).map(t => parseInt(t));
        },
        getCloudTypes() {
            return Object.keys(this.$platformsTypesInfo).filter(t => this.$platformsTypesInfo[t].mainType==this.$PlatformsTypes.Cloud&&this.$platformsTypesInfo[t].enabled).map(t => parseInt(t));
        },
        getEmailTypes() {
            return Object.keys(this.$platformsTypesInfo).filter(t => this.$platformsTypesInfo[t].mainType==this.$PlatformsTypes.Email&&this.$platformsTypesInfo[t].enabled).map(t => parseInt(t));
        },

        getServiceAccountsTypes() {
            return Object.keys(this.$platformsTypesInfo).filter(t => this.$platformsTypesInfo[t].forServiceAccount&&this.$platformsTypesInfo[t].enabled&&this.$platformsTypesInfo[t].mainType==-1).map(t => parseInt(t));
        },
        getRepositoriesTypes() {
            return Object.keys(this.$platformsTypesInfo).filter(t => this.$platformsTypesInfo[t].forRepository&&this.$platformsTypesInfo[t].enabled&&this.$platformsTypesInfo[t].mainType==-1).map(t => parseInt(t));
        },
        getBackupSourcesTypes() {
            return Object.keys(this.$platformsTypesInfo).filter(t => this.$platformsTypesInfo[t].forBackupSource&&this.$platformsTypesInfo[t].enabled&&this.$platformsTypesInfo[t].mainType==-1).map(t => parseInt(t));
        },
        getRestoreFileDestinationTypes() {
            return [
                this.$PlatformsTypes.FileFolder,
                this.$PlatformsTypes.Network,
                this.$PlatformsTypes.FTP,
                this.$PlatformsTypes.Cloud_AmazonS3Storage,
                this.$PlatformsTypes.Cloud_AzureBlob,
                this.$PlatformsTypes.Cloud_AzureFile,
                this.$PlatformsTypes.Cloud_Dropbox,
                this.$PlatformsTypes.Cloud_GoogleDrive,
                this.$PlatformsTypes.Cloud_IperiusS3Storage,
                this.$PlatformsTypes.Cloud_OneDrive,
                this.$PlatformsTypes.Cloud_S3CompatibleStorage,
                this.$PlatformsTypes.Microsoft_OneDriveForBusiness,
                this.$PlatformsTypes.Microsoft_SharePoint,
                this.$PlatformsTypes.Microsoft_Teams
            ]
        },



        isValidEmail(email) {
            // Utilizzo di una regex per validare l'email
            const emailRegex=/^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },

        isConnected(idPC) {
            return this.connectionsStates[idPC];
        },

        /**
         * Veronica : Ho creato un oggetto per facilitare la lettura del codice e eventuali cambiamenti sulla logica
         * config = {
         *     path: // obbligatorio
         *     type: // obbligatorio
         *     prefix:
         *     repoName:
         *     saName
         * }
         */
        getPathAndName(config) {
            var path, name="";
            if (config.prefix!=undefined&&config.path!=config.prefix&&!config.path.startsWith(config.prefix+config.type.getSeparator())) {
                config.path=config.prefix+config.type.getSeparator()+config.path
            }

            path=config.path;

            //Caso 1 : Se è stato passato il nome del repository, questo diventa il nome ritornato
            if (config.repoName!=undefined) {
                return [path, config.repoName];
            }

            //Caso 2 : Se non è stato passato il nome del repository ma solo quello del service account,
            // lo concateno all'ultima parte del percorso (scrivo 'root' se il percorso passato è vuoto)
            if (config.saName!=undefined&&path=='') {
                name=config.saName+" - root";
                return [path, name];
            }

            if (config.saName!=undefined&&path!='') {
                name=config.saName+" - "+path.lastPartOfPath();
                return [path, name];
            }

            //Caso 3 : Se non è stato passato alcun nome e il tipo è filefolder
            // il nome ritornato sarà la concatenazione del nome del pc con l'ultima parte del percorso
            if (config.type==this.$PlatformsTypes.FileFolder) {
                if (path=='') {
                    return [path, ""];
                }

                var roomPC=this.$session.getRoomPC();
                name=roomPC.name+" - "+path.trimEnd("\\").lastPartOfPath().trimEnd(":");
                return [path, name];
            }

            //Caso 4 (non dovrebbe mai andare qua) : ritorno un nome vuoto se non è filefolder 
            // e non è stato passato alcun repo o serviceaccount
            return [path, name];
        },

        getSARootPath(sa) {
            if (sa.type==this.$PlatformsTypes.Network) {
                return sa.options.path;
            }

            if (sa.type==this.$PlatformsTypes.FTP) {
                return sa.url;
            }

            return "";
        },

        copy(value) {
            navigator.clipboard.writeText(value).then(() => {
                this.toast(this.$t("Copied to clipboard"), 2000, "info");
            });
        },


        // syncronize(idPC, socket_backups) {
        //     var list, method;
        //     //Se è stao passato un pc non valido, controllo se in sessione sono stati salvati service account o repositories
        //     if (!isValid(idPC)) {
        //         list = this.$session.getServiceAccounts();

        //         if (list != null) {
        //             list.forEach(sa => {
        //                 method = isValid(sa.id) ? "EDITSERVICEACCOUNT" : "CREATESERVICEACCOUNT";
        //                 this.api(method, sa);
        //                 this.session("SERVICEACCOUNTS", "DELETE");
        //             });
        //         }

        //         list = this.session("REPOSITORIES", "GET");
        //         if (list != null) {
        //             list.forEach(repo => {
        //                 method = isValid(repo.id) ? "EDITREPOSITORY" : "CREATEREPOSITORY";
        //                 this.api(method, repo);
        //                 this.session("REPOSITORIES", "DELETE");
        //             });
        //         }

        //         list = this.session("JOBS", "GET");
        //         if (list != null) {
        //             list.forEach(job => {
        //                 method = isValid(job.id) ? "EDITBACKUP" : "CREATEBACKUP";
        //                 this.api(method, job);
        //                 this.session("JOBS", "DELETE");
        //             });
        //         }
        //         return;
        //     }

        //     let self = this;
        //     var complete_api_job;
        //     //Se è stato passato un idPC valido, extradata corrisponde alla lista dei backup recuperati tramite socket (in realtime)
        //     this.api("GETBACKUPS", idPC, 99, 25, 0).then(api_backups => {
        //         if (api_backups == null)
        //             return;

        //         api_backups = api_backups.filter(job => job.id_computer == idPC);
        //         if (api_backups.length == 0)
        //             return;

        //         //Per sincronizzare i dati si da priorità al database.
        //         //Ciò che è presente lì è stato creato
        //         //Ciò che non è presente lì, è stato eliminato
        //         api_backups.forEach(async api_job => {
        //             //Se la lista socket non contiene un job presente nella lista api
        //             //eseguo la chiamata socket per creare il job
        //             if (!socket_backups.some(socket_job => socket_job.id == api_job.id)) {
        //                 complete_api_job = await self.api("GETBACKUPWITHID", api_job.id);
        //                 if (complete_api_job != null)
        //                     await self.socket("CreateJob", [api_job.name], complete_api_job);
        //             }
        //         });
        //         var deleted;
        //         socket_backups.forEach(async socket_job => {
        //             //Se nella lista socket trovo un backup non presente nella lista api,
        //             //eseguo la chiamata per eliminare il job
        //             if (!api_backups.some(api_job => api_job.id == socket_job.id)) {
        //                 deleted = await self.socket("DeleteJob", [socket_job.name], socket_job.id);
        //                 console.log("Il job " + socket_job.name + " è presente sul pc ma non nel db, quindi ho provato a eliminarlo. Risultato = " + (deleted))
        //                     //alert("Il job " + socket_job.name + " è presente sul pc ma non nel db, quindi ho provato a eliminarlo. Risultato = " + (deleted));
        //             }

        //         });
        //     })


        // },
      
    },
    components: {
        VueContext,
    },
    created: function() {
        var self=this;
        this.selectValues=selectValues;
        installVuePrototypes(Vue);
        installVueDirectives(Vue);
        this.$api.vueEnvironment=this;
        this.$api.createToastFunction=this.$root.toast;
        this.$api.translateFunction=this.translateFunction;

        //require("dotenv").config();
        window.addEventListener('beforeunload', async function(event) {
            await self.closeSocket();
        });

        // Salva i dati in una scheda
        //localStorage.setItem("sessionData", JSON.stringify({ user: "Matteo", loggedIn: true }));

        // Ascolta le modifiche in un'altra scheda
        window.addEventListener("storage", (event) => {
            if (event.key==="sessionData") {
                console.log("Nuovi dati di sessione ricevuti:", JSON.parse(event.newValue));
            }
        });
        window.addEventListener('keydown', this.handleShortcut);

        /*  // Creazione del canale
        const channel = new BroadcastChannel("session_channel");

        // Invia un messaggio a tutte le tab aperte
        channel.postMessage({ type: "session_update", user: "Matteo", loggedIn: true });

        // Ricezione dei messaggi in un'altra scheda
        channel.onmessage = (event) => {
            console.log("Dati di sessione aggiornati:", event.data);
        };
 */



        if (this.checkAuth()) {
            this.checkSocket();
        }

        document.addEventListener('contextmenu', (event) => {
            event.preventDefault();
        });



        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistration().then(registration => {

                if (!registration||registration.active.scriptURL.indexOf('service-worker.js')===-1) {
                    navigator.serviceWorker.register('/assets/js/service-worker.js')
                        .then(registration => {
                            console.log('Service Worker registrato con successo:', registration);
                        })
                        .catch(error => {
                            console.error('Errore nella registrazione del Service Worker:', error);
                        }); // Registra solo se non esiste
                } else {
                    console.log('Service Worker già presente:', registration);
                }
            });
        }

    

    },
    beforeDestroy: async function() {
        this.$off("socketEventConnected");
        this.$off("socketEventDisconnected");
        clearInterval(this.checkConnectionTimer);
        this.checkConnectionTimer=null;
        window.removeEventListener('keydown', this.handleShortcut);
        await this.closeSocket();
    },

    /* mounted: function() {
        Metro.init;
    }, */
    render: h => h(App),
}, { key: appKey });


app.$mount('#app');



Vue.filter('utcAsLocal', function(value) {
    return moment.utc(value).local();
});