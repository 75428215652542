var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "dialog light w-100-sm w-100-md w-75-lg w-50-xl draggable-item",
    },
    [
      _c("DialogTitle", {
        attrs: {
          title:
            _vm.serviceAccount.id == null
              ? _vm.$t("Add Service Account")
              : _vm.serviceAccount.name,
          defaultTitle:
            _vm.serviceAccount.id == null
              ? _vm.$t("Add Service Account")
              : _vm.$t("Edit/Test Service Account"),
          subTitle: _vm.serviceAccount.type_name,
        },
        on: {
          closeDialog: function ($event) {
            return _vm.$root.$emit("CLOSESERVICEACCOUNTDIALOG")
          },
        },
      }),
      _c("div", { staticClass: "dialog-content overflow bg-light" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("Name")))]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.serviceAccount.name,
                expression: "serviceAccount.name",
                modifiers: { trim: true },
              },
            ],
            attrs: {
              "data-role": "input",
              type: "text",
              maxlength: "50",
              placeholder: _vm.$t("Enter name"),
              "data-prepend": "<span class='mif-pencil'></span>",
            },
            domProps: { value: _vm.serviceAccount.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.serviceAccount, "name", $event.target.value.trim())
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(_vm._s(_vm.$t("Required"))),
          ]),
        ]),
        _vm.$session.getRoomPC() == null
          ? _c("div", { staticClass: "form-group" }, [
              _c("label", [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "Select an endpoint from which test the {0} connection",
                        {
                          0: _vm.$platformsTypesInfo[_vm.serviceAccount.type]
                            .name,
                        }
                      )
                    )
                  ),
                ]),
              ]),
              _c("div", { staticClass: "row m-0" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.serviceAccount.options.lastTestPC,
                        expression: "serviceAccount.options.lastTestPC",
                      },
                    ],
                    staticClass: "select cell-6 mr-5",
                    attrs: {
                      disabled:
                        _vm.$session.getPCList().length == 0 ||
                        _vm.instances.length != 0,
                    },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.serviceAccount.options,
                          "lastTestPC",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.$session.getPCList(), function (pc) {
                    return _c(
                      "option",
                      {
                        key: pc.id,
                        attrs: {
                          "data-append": pc.note,
                          disabled:
                            !_vm.$root.connectionsStates[pc.id] ||
                            pc.system_info[
                              _vm.$SystemInfoType.SQL_INSTANCES_INSTALLED
                            ] == undefined ||
                            pc.system_info[
                              _vm.$SystemInfoType.SQL_INSTANCES_INSTALLED
                            ].info_value == "",
                          title: !_vm.$root.connectionsStates[pc.id]
                            ? "Not connected"
                            : pc.system_info[
                                _vm.$SystemInfoType.SQL_INSTANCES_INSTALLED
                              ] == undefined ||
                              pc.system_info[
                                _vm.$SystemInfoType.SQL_INSTANCES_INSTALLED
                              ].info_value == ""
                            ? "No SQL instance"
                            : "",
                        },
                        domProps: {
                          value: pc.id,
                          selected:
                            _vm.serviceAccount.options.lastTestPC != null &&
                            _vm.serviceAccount.options.lastTestPC == pc.id,
                        },
                      },
                      [_vm._v(" " + _vm._s(pc.name) + " ")]
                    )
                  }),
                  0
                ),
                _vm.searchingInstances
                  ? _c("div", [
                      _c("span", {
                        staticClass: "mif-spinner2 fg-primary ani-spin",
                      }),
                    ])
                  : _c(
                      "button",
                      {
                        staticClass: "button primary",
                        attrs: {
                          disabled:
                            !_vm.serviceAccount.options.lastTestPC ||
                            _vm.instances.length != 0,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getInstances()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("List SQL Server instances")))]
                    ),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("Local Instance URL")))]),
          _c("div", { staticClass: "cell-10 p-0" }, [
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.serviceAccount.url,
                      expression: "serviceAccount.url",
                    },
                  ],
                  staticClass: "w-50 select",
                  attrs: {
                    disabled:
                      !_vm.serviceAccount.options.lastTestPC ||
                      _vm.instances.length == 0,
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.serviceAccount,
                          "url",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.onChange,
                    ],
                  },
                },
                [
                  _vm.instances.length == 0
                    ? _c("option", { attrs: { value: "-1", disabled: "" } }, [
                        _vm._v(
                          _vm._s(_vm.$t("Local instances not found")) + " "
                        ),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.instances, function (sa, index) {
                    return _c(
                      "option",
                      {
                        key: index,
                        staticClass: "p-0",
                        domProps: { value: sa.name },
                      },
                      [_vm._v(" " + _vm._s(sa.name) + " ")]
                    )
                  }),
                ],
                2
              ),
              _vm.searchingInstances == true
                ? _c("div", { staticClass: "align-items-center" }, [
                    _c("span", {
                      staticClass: "mif-spinner2 mif-2x ani-spin ml-3 mr-3",
                    }),
                    _c("label", [
                      _vm._v(_vm._s(_vm.$t("Searching for Sql instances..."))),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.$t("Required"))),
            ]),
          ]),
          _vm.serviceAccount.type == _vm.$PlatformsTypes.Database_MySQL
            ? _c(
                "div",
                {
                  staticClass: "cell-2 p-0",
                  attrs: { disabled: _vm.instances.length == 0 },
                },
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("PORT")))]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.serviceAccount.port,
                        expression: "serviceAccount.port",
                        modifiers: { number: true },
                      },
                      { name: "number", rawName: "v-number" },
                      {
                        name: "range",
                        rawName: "v-range",
                        value: { min: 10, max: 65535 },
                        expression: "{min:10, max:65535}",
                      },
                    ],
                    staticClass: "metro-input",
                    attrs: {
                      "data-role": "input",
                      type: "number",
                      placeholder: _vm.$t("Enter Domain"),
                      "data-prepend": "<span class='mif-cloud'></span>",
                      disabled: !_vm.serviceAccount.options.lastTestPC,
                    },
                    domProps: { value: _vm.serviceAccount.port },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.serviceAccount,
                          "port",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.$t("Required"))),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
        _vm.serviceAccount.type == _vm.$PlatformsTypes.Database_SQLServer
          ? _c(
              "div",
              {
                staticClass: "form-group",
                attrs: {
                  disabled:
                    _vm.instances.length == 0 ||
                    !_vm.serviceAccount.options.lastTestPC,
                },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.serviceAccount.options.useWinAuth,
                      expression: "serviceAccount.options.useWinAuth",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    "data-role": "radio",
                    "data-caption": _vm.$t(
                      "Use Integrated Windows Authentication"
                    ),
                    name: "useWinAuth",
                  },
                  domProps: {
                    value: true,
                    checked: _vm._q(
                      _vm.serviceAccount.options.useWinAuth,
                      true
                    ),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$set(
                        _vm.serviceAccount.options,
                        "useWinAuth",
                        true
                      )
                    },
                  },
                }),
                _c("br"),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.serviceAccount.options.useWinAuth,
                      expression: "serviceAccount.options.useWinAuth",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    "data-role": "radio",
                    "data-caption": _vm.$t("Use SQL Server Authentication"),
                    name: "useWinAuth",
                  },
                  domProps: {
                    value: false,
                    checked: _vm._q(
                      _vm.serviceAccount.options.useWinAuth,
                      false
                    ),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$set(
                        _vm.serviceAccount.options,
                        "useWinAuth",
                        false
                      )
                    },
                  },
                }),
                _c("br"),
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "form-group row",
            attrs: {
              disabled:
                _vm.instances.length == 0 ||
                !_vm.serviceAccount.options.lastTestPC,
            },
          },
          [
            _c("div", { staticClass: "cell-6" }, [
              _c("label", [_vm._v(_vm._s(_vm.$t("Username")))]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.serviceAccount.username,
                    expression: "serviceAccount.username",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "metro-input",
                attrs: {
                  disabled:
                    !_vm.serviceAccount.options.lastTestPC ||
                    (_vm.serviceAccount.options.useWinAuth &&
                      _vm.serviceAccount.type ==
                        _vm.$PlatformsTypes.Database_SQLServer),
                  "data-role": "input",
                  type: "text",
                  placeholder: _vm.$t("Enter Username"),
                  "data-prepend": "<span class='mif-user'></span>",
                },
                domProps: { value: _vm.serviceAccount.username },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.serviceAccount,
                      "username",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(_vm._s(_vm.$t("Required"))),
              ]),
            ]),
            _c("div", { staticClass: "cell-6" }, [
              _c("label", [_vm._v(_vm._s(_vm.$t("Password")))]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.serviceAccount.password,
                    expression: "serviceAccount.password",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "metro-input",
                attrs: {
                  disabled:
                    !_vm.serviceAccount.options.lastTestPC ||
                    (_vm.serviceAccount.options.useWinAuth &&
                      _vm.serviceAccount.type ==
                        _vm.$PlatformsTypes.Database_SQLServer),
                  "data-role": "input",
                  type: "password",
                  placeholder: _vm.$t("Enter Password"),
                  "data-prepend": "<span class='mif-lock'></span>",
                },
                domProps: { value: _vm.serviceAccount.password },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.serviceAccount,
                      "password",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(_vm._s(_vm.$t("Required"))),
              ]),
            ]),
          ]
        ),
        _vm.serviceAccount.type == _vm.$PlatformsTypes.Database_SQLServer
          ? _c(
              "div",
              {
                staticClass: "form-group",
                attrs: {
                  disabled:
                    _vm.instances.length == 0 ||
                    !_vm.serviceAccount.options.lastTestPC,
                },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.serviceAccount.options.useTcpIpNetLib,
                      expression: "serviceAccount.options.useTcpIpNetLib",
                    },
                  ],
                  attrs: {
                    "data-role": "checkbox",
                    type: "checkbox",
                    "data-caption": _vm.$t("Use TCP/IP Network Library"),
                    "data-validate": "required",
                    required: "",
                    "data-prepend": "<span class='mif-server'></span>",
                  },
                  domProps: {
                    checked: Array.isArray(
                      _vm.serviceAccount.options.useTcpIpNetLib
                    )
                      ? _vm._i(
                          _vm.serviceAccount.options.useTcpIpNetLib,
                          null
                        ) > -1
                      : _vm.serviceAccount.options.useTcpIpNetLib,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.serviceAccount.options.useTcpIpNetLib,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.serviceAccount.options,
                              "useTcpIpNetLib",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.serviceAccount.options,
                              "useTcpIpNetLib",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.serviceAccount.options,
                          "useTcpIpNetLib",
                          $$c
                        )
                      }
                    },
                  },
                }),
                _c("br"),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.serviceAccount.options.usePersistSecInfo,
                      expression: "serviceAccount.options.usePersistSecInfo",
                    },
                  ],
                  attrs: {
                    "data-role": "checkbox",
                    type: "checkbox",
                    "data-caption": _vm.$t("Use Persist Security Info"),
                    "data-validate": "required",
                    required: "",
                    "data-prepend": "<span class='mif-server'></span>",
                  },
                  domProps: {
                    checked: Array.isArray(
                      _vm.serviceAccount.options.usePersistSecInfo
                    )
                      ? _vm._i(
                          _vm.serviceAccount.options.usePersistSecInfo,
                          null
                        ) > -1
                      : _vm.serviceAccount.options.usePersistSecInfo,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.serviceAccount.options.usePersistSecInfo,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.serviceAccount.options,
                              "usePersistSecInfo",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.serviceAccount.options,
                              "usePersistSecInfo",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.serviceAccount.options,
                          "usePersistSecInfo",
                          $$c
                        )
                      }
                    },
                  },
                }),
                _c("br"),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.serviceAccount.options.useIntSecSSPI,
                      expression: "serviceAccount.options.useIntSecSSPI",
                    },
                  ],
                  attrs: {
                    "data-role": "checkbox",
                    type: "checkbox",
                    "data-caption": _vm.$t("Use Integrated Security SSPI"),
                    "data-validate": "required",
                    required: "",
                    "data-prepend": "<span class='mif-server'></span>",
                  },
                  domProps: {
                    checked: Array.isArray(
                      _vm.serviceAccount.options.useIntSecSSPI
                    )
                      ? _vm._i(_vm.serviceAccount.options.useIntSecSSPI, null) >
                        -1
                      : _vm.serviceAccount.options.useIntSecSSPI,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.serviceAccount.options.useIntSecSSPI,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.serviceAccount.options,
                              "useIntSecSSPI",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.serviceAccount.options,
                              "useIntSecSSPI",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.serviceAccount.options,
                          "useIntSecSSPI",
                          $$c
                        )
                      }
                    },
                  },
                }),
                _c("br"),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.serviceAccount.options.useTrustConn,
                      expression: "serviceAccount.options.useTrustConn",
                    },
                  ],
                  attrs: {
                    "data-role": "checkbox",
                    type: "checkbox",
                    "data-caption": _vm.$t(
                      "Use Trusted Connection (required for Windows authentication)"
                    ),
                    "data-validate": "required",
                    required: "",
                    "data-prepend": "<span class='mif-server'></span>",
                  },
                  domProps: {
                    checked: Array.isArray(
                      _vm.serviceAccount.options.useTrustConn
                    )
                      ? _vm._i(_vm.serviceAccount.options.useTrustConn, null) >
                        -1
                      : _vm.serviceAccount.options.useTrustConn,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.serviceAccount.options.useTrustConn,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.serviceAccount.options,
                              "useTrustConn",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.serviceAccount.options,
                              "useTrustConn",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.serviceAccount.options,
                          "useTrustConn",
                          $$c
                        )
                      }
                    },
                  },
                }),
                _c("br"),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.serviceAccount.options.useEncConn,
                      expression: "serviceAccount.options.useEncConn",
                    },
                  ],
                  attrs: {
                    "data-role": "checkbox",
                    type: "checkbox",
                    "data-caption": _vm.$t("Use Encrypted Connection"),
                    "data-validate": "required",
                    required: "",
                    "data-prepend": "<span class='mif-server'></span>",
                  },
                  domProps: {
                    checked: Array.isArray(
                      _vm.serviceAccount.options.useEncConn
                    )
                      ? _vm._i(_vm.serviceAccount.options.useEncConn, null) > -1
                      : _vm.serviceAccount.options.useEncConn,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.serviceAccount.options.useEncConn,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.serviceAccount.options,
                              "useEncConn",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.serviceAccount.options,
                              "useEncConn",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.serviceAccount.options, "useEncConn", $$c)
                      }
                    },
                  },
                }),
                _c("br"),
              ]
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "dialog-actions d-flex" }, [
        _vm.serviceAccount.id != null
          ? _c(
              "button",
              {
                staticClass: "button primary",
                attrs: { disabled: _vm.tested == _vm.TEST_WAIT },
                on: {
                  click: function ($event) {
                    return _vm.$root.duplicateServiceAccount(_vm.serviceAccount)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("Duplicate")))]
            )
          : _vm._e(),
        _vm.tested == _vm.TEST_WAIT
          ? _c("div", [_c("span", { staticClass: "mif-spinner2 ani-spin" })])
          : _vm._e(),
        _c(
          "button",
          {
            staticClass: "button primary",
            attrs: { disabled: !_vm.enableTest },
            on: { click: _vm.test },
          },
          [_vm._v(_vm._s(_vm.$t("Test account")))]
        ),
        _c(
          "button",
          {
            staticClass: "button primary",
            attrs: { disabled: !_vm.enableSave },
            on: {
              click: function ($event) {
                return _vm.$root.saveServiceAccount(_vm.serviceAccount)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("Save")))]
        ),
        _c(
          "button",
          {
            staticClass: "button primary",
            on: {
              click: function ($event) {
                return _vm.$root.$emit("CLOSESERVICEACCOUNTDIALOG")
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("Close")))]
        ),
        _vm.serviceAccount.id != null
          ? _c(
              "button",
              {
                staticClass: "button alert",
                attrs: { disabled: _vm.tested == _vm.TEST_WAIT },
                on: {
                  click: function ($event) {
                    return _vm.$root.deleteServiceAccount(_vm.serviceAccount)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("Delete")))]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }