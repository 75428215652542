import { FileFolderEntityType, PlatformsTypes } from "../../objects";
import { ClientTreeNode } from "../../../models/TreeNode";
import api from "../../../router/api";

export class DropboxAuthentication {

    constructor(appKey, appSecret) {
        this.appKey=appKey;
        this.appSecret=appSecret;
    }

    getAuthenticationCode() {
        var url="https://www.dropbox.com/oauth2/authorize?client_id="+this.appKey+"&client_secret="+this.appSecret+"&response_type=code&token_access_type=offline&grant_type=authorization_code";
        window.open(url, "_blank");
    }


    async getTokensWithAuthCode(authCode) {

        var url="https://api.dropbox.com/oauth2/token?code="+authCode+"&grant_type=authorization_code&client_id="+this.appKey+"&client_secret="+this.appSecret;
        var headers={
            "Content-Type": "application/json"
        }
        var [ok, response]=await api.sendRequestToServer("POST", url, headers, null)

        if (!ok) {
            return null;
        }

        setTimeout(async () => {

            var newTokens=await this.getAccessTokenWithRefreshToken(response.data.refresh_token);
            console.log(newTokens);
        }, 20000);


        return {
            accessToken: response.access_token,
            refreshToken: response.refresh_token
        };
    }

    async getAccessTokenWithRefreshToken(refreshToken) {
        var url="https://api.dropbox.com/oauth2/token?refresh_token="+refreshToken+"&grant_type=refresh_token&client_id="+this.appKey+"&client_secret="+this.appSecret;

        var [ok, response]=await api.sendRequestToServer("POST", url, {}, null)
        console.log(response);

        if (!ok) {
            return null;
        }

        return response.access_token;
    }
}

export class DropboxClient {
    constructor(serviceAccount) {
        this.serviceAccount=serviceAccount;
        this.accessToken=serviceAccount.token;
        this.nodes=[];
    }

    async getRootNodes() {
        var children=this.nodes.length==0? []:
            this.nodes.filter(n => n.isChildOf(null));

        if (children.length!=0)
            return children;
        var rootNodes=await this.getNodesFrom(new ClientTreeNode(null, null, "", FileFolderEntityType.Cloud_Root));
        if (rootNodes==null)
            return null;
        this.nodes=rootNodes;
        return this.nodes;
    }

    async getNodesFrom(resource) {
        var children=this.nodes.length==0? []:
            this.nodes.filter(n => n.isChildOf(resource, "/"));

        if (children.length!=0)
            return children;
        var path=resource.path;

        if (path!="") path="/"+path;

        var url="https://api.dropboxapi.com/2/files/list_folder";
        var body={ "path": path, "recursive": false, "include_deleted": false, "include_mounted_folders": true };
        var headers={
            /*  "Access-Control-Allow-Origin": window.location.origin, */
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
            "Authorization": "Bearer "+this.accessToken
        };
        var [ok, response]=await api.sendRequestToServer("POST", url, headers, body);

        if (!ok) {
            return null;
        }

        var newPath;

        children=response.entries.map(r => {

            if (path=="") newPath=r.name;
            else newPath=resource.path+"/"+r.name;

            /**
             * Array di oggetti di tipo
             * .tag : "folder"
             * id : id:tPcDEmrP3EAAAAAAAADkmg"
             * name : "Full28"
             *  path_display : "/Full28"
             *   path_lower : "/full28"
             */
            return new ClientTreeNode(
                null,
                r.id,
                newPath,
                r[".tag"]!="folder"?
                    FileFolderEntityType.Cloud_File:
                    FileFolderEntityType.Cloud_Folder
            );
        });


        //this.nodes.push(...children);
        return children;

    }

    async createFolder(path) {
        var url='https://api.dropboxapi.com/2/files/create_folder_v2';
        var headers={
            "Authorization": `Bearer ${this.accessToken}`,
            "Content-Type": "application/json",
        };
        var body={
            path: "/"+path,
            autorename: false,
        };

        var [ok, response]=await api.sendRequestToServer("POST", url, headers, body);
        if (!ok) {
            return null;
        }

        var id=response.metadata.id;

        return new ClientTreeNode(null, id, path, FileFolderEntityType.Cloud_Folder);
    }
}