<template>
    <div v-on:keyup.esc="$root.$emit('CLOSESOURCEDIALOG')"
        class="dialog light w-100-sm w-100-md w-75-lg w-50-xl h-75">

        <DialogTitle :title="$t('Configure Backup Source')" :defaultTitle="$t('Configure Backup Source')" :subTitle="source.type_name"
            @closeDialog="$root.$emit('CLOSESOURCEDIALOG')" />

        <div v-if="source.hvSource==null" class="dialog-content d-flex flex-justify-center flex-align-center flex-column">
            <span>{{ $t("Listing virtual machines from host {0}. Please wait...", {0:pc.name}) }}</span>
            <span class="mif-spinner2 ani-spin"></span>
        </div>

        <div v-else-if="source.hvSource == ''" class="dialog-content p-4">
            <div class=" d-flex flex-align-center flex-column flex-justify-center py-4">
                <span class="mif-info mif-6x fg-gray" />
                <h5 class="text-center">{{ $t('No virtual machine available') }}</h5>
            </div>
        </div>
        <div v-else class="dialog-content p-4 overflow" style="overflow: hidden;">

            <div class="info-box-content ">
                <div class="bd-default no-border-top">

                    <div class="p-1 h-100" v-show="steps[0]">

                        <div class="w-100">
                            <h6 class="p-3">{{ $t("Select Virtual Machines you want to back up") }}</h6>

                            <div data-role="splitter" class="h-100" data-split-sizes="50, 50"
                                data-split-mode="vertical">

                                <div class="d-flex flex-justify-center overflow m-1">
                                    <div class="w-100">
                                        <ul data-role="treeview" class="p-0" v-if="source.hvSource.length">
                                            <TreeItem v-for="hvsource in source.hvSource" :key="hvsource.guid"
                                                :item="hvsource" :hvselected.sync="hvselected"
                                                :selectedCount.sync="selectedCount" />
                                        </ul>
                                    </div>
                                </div>
                                <div class="d-flex flex-justify-center">
                                    <div class="p-3 w-100" v-if="hvselected">
                                        <h5 class="w-100"> <span class='mif-computer'></span> {{ hvselected.name }}</h5>
                                        <div data-role="buttongroup">
                                            <button v-for="status_index in machine_status_enabled" 
                                                :title="machine_status[status_index]" class="button rounded m-1 small"
                                                @click="changeVmStatusHyperV(status_index)"
                                                :class="{ disabled: hvselected.status == status_index }" 
                                            >
                                                <span :class='machine_status_icons[status_index]'></span>
                                            </button>
                                            
                                        </div>
                                        <hr>
                                        <table class="w-100 text-secondary">
                                            <tr>
                                                <td>
                                                    {{ $t("Status") }}
                                                </td>
                                                <td class="text-right">
                                                    <strong>{{ machine_status[hvselected.status] }}</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {{ $t("Version") }}
                                                </td>
                                                <td class="text-right">
                                                    <strong>{{ hvselected.version }}</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    RAM
                                                </td>
                                                <td class="text-right">
                                                    <strong>{{ hvselected.status == 2 ? hvselected.ram : "-" }}</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {{ $t("Integrated Services") }}
                                                </td>
                                                <td class="text-right">
                                                    <strong>{{ hvselected.vss ? "Enabled" : "Disabled" }}</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {{ $t("Number of Snapshots") }}
                                                </td>
                                                <td class="text-right">
                                                    <strong>{{ hvselected.nofSnapshots }}</strong>
                                                </td>
                                            </tr>
                                        </table>
                                        <hr>
                                        <div>
                                            <h6 class="mb-3">{{ $t("Backup Options") }}</h6>
                                            <label class="text-secondary"> <input
                                                    v-model="hvselected.backupOptions.changeVMstatus" type="checkbox"
                                                    data-role="checkbox"
                                                    :data-caption="$t('Change VM status before the backup')">
                                            </label>
                                            <select
                                                :disabled="hvselected.backupOptions.changeVMstatus == false || hvselected.backupOptions.changeVMstatus == null"
                                                class="select w-100" v-model="hvselected.backupOptions.changeVMstatus">
                                                <option value="3">{{ $t("Turn off the VM") }}</option>
                                                <option value="6">{{ $t("Put in Saved State") }}</option>
                                                <option value="9">{{ $t("Quiesce the VM") }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="p-2 h-100" v-show="false">

                        <h6 class="pl-5">{{ $t("Select the type of Hyper-V backup you want to configure") }}:</h6>

                        <div class="form-group p-4">
                            <input type="radio" data-role="radio"
                                :data-caption="$t('Backup RCT (Resilient Change Tracking)')"
                                v-model="source.options.rct" :value="1"><br>
                            <small>{{ $t(messages[0]) }}.</small><br>
                            <small>{{ $t(messages[1]) }}</small>
                        </div>
                        <div class="form-group p-4">
                            <input type="radio" data-role="radio"
                                :data-caption="$t('Direct Copy of single disks and config. files')"
                                v-model="source.options.rct" :value="2"><br>
                            <small>{{ $t(messages[2]) }}</small><br>
                            <small>{{ $t(messages[3]) }}</small>
                        </div>


                    </div>
                    <div class="p-4 h-100" v-show="steps[1]">
                        <h6>{{ $t("Configure backup options") }}:</h6>
                        <div class="form-group  row">
                            <div class="cell-8">
                                <label>{{ $t("Backup Type") }}</label>
                                <select class="select w-100" :disabled="source.options.rct == 2"
                                    v-model="source.options.backupType">
                                    <option value="F">{{ $t("Full backup Only") }}</option>
                                    <option value="I">{{ $t("One full Backup + Incremental Backups") }}</option>
                                    <option value="D">{{ $t("One full Backup + Differential Backups") }}</option>
                                </select>
                            </div>
                            <div class="cell-4">
                                <label>{{ $t("Copies") }}</label>
                                <input type="number" v-number v-range="{ min: 1, max: 99 }" id="nCopiesSpinner" v-model.number="source.options.nCopies"  />
                            </div>
                        </div>
                        <div class="form-group">
                            <input data-role="checkbox" type="checkbox"
                                :data-caption="$t('Add automatically every new virtual machine to the backup')"
                                data-validate="required" v-model="source.options.autoAddNewItems" required />
                        </div>
                        <div class="form-group" v-show="false">
                            <input v-model="source.enableCompression" type="checkbox" data-role="checkbox"
                                :data-caption="$t('Enable Compression')">
                        </div>
                        <div class="form-group w-50" v-show="false">
                            <p>{{ $t("Archive File Custom Name") }} </p>
                            <input :disabled="!source.enableCompression"
                                v-model.lazy="source.options.archiveFileCustomName" type="text" data-role="input" v-fsitem
                               >
                            <label class="text-small text-muted">{{ $t("If blank, default name will be used") }}</label>
                        </div>
                        <div class="form-group" v-show="false">
                            <input :disabled="!source.enableCompression" v-model="source.options.useArchivePassword"
                                type="checkbox" data-role="checkbox" :data-caption="$t('Protect zip with Password')">
                        </div>
                        <div v-if="source.enableCompression && source.options.useArchivePassword" v-show="false">
                            <div class="form-group w-50">
                                <p>{{ $t("Archive Password") }}</p>
                                <input data-role="input" class="metro-input" type="password"
                                v-model.trim="source.options.archivePassword" :placeholder="$t('Enter Password')"
                                    data-prepend="<span class='mif-lock'></span>" />
                                <small class="text-muted">{{ $t("Required") }}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="dialog-actions">
            <div class="ml-auto">
                <button class="button" :class="{ 'disabled': source.hvSource == '' || steps[0] }" @click="back"><span class="mif-arrow-left mr-2"></span>{{ $t("Prev") }}</button>
                <button class="button" :class="{ 'disabled': source.hvSource == '' || steps[1] || selectedCount <= 0 }" @click="next">{{ $t("Next") }}<span class="mif-arrow-right ml-2"> </span> 
                </button>
                <button class="button primary" v-if="steps[1]" @click="saveSource">{{ $t("Save") }}</button>
                <button class="button" @click="$root.$emit('CLOSESOURCEDIALOG')">{{ $t("Close") }}</button>
            </div>
        </div>
    </div>
</template>
<script>
import DialogTitle from "../../utils/DialogTitle";
import { isValid, syncronizeArray } from "../../../utils/utilitiesmodule";
import TreeItem from '../../utils/TreeItem.vue';



export default {
    name: "HyperVSource",
    props: {
        source: {
            type: Object,
            required: true,
        }
    },
    components: {
        "DialogTitle": DialogTitle,
        TreeItem,
    },
    data() {
        return {
            steps: [1, 0, 0],
            visible: false,

            hvselected: null,
            machine_status_enabled: [2,3,9,6,4],
            machine_status: ["", "", "Start", "Turn off", "Shut Down", "", "Save", "Test", "Defer", "Quiesce", "Reboot", "Reset"],
            machine_status_icons : ["","", "mif-play", "mif-stop", "mif-switch","","mif-bookmarks","","", "mif-pause","",""],
            STATE_START: 2,
            STATE_OFF: 3,
            STATE_SHUTDOWN: 4,
            STATE_SAVE: 6,
            STATE_QUIESCE: 9,
            pc: null,
            messages: [
                "The software will create an incremental or differential backup based on the modified blocks",
                "(Available only if the software is installed on a Hyper-V 2016, 2019, Windows 10 or higher)",
                "The software will copy directly in the backup job destinations the disk files and the configuration files.",
                "(Allows you to back up virtual machines even from Hyper-V servers on the network, without agents)"
            ],
            selectedCount: 0,

        }
    },
    created: async function() {
        let self=this;
        self.pc=self.$session.getRoomPC();

        var newVM=await this.$root.socket("GetVirtualMachinesHyperV");
        //Caso 1: Si è verificato un errore
        if (newVM.length==0) {
            this.source.hvSource="";
            return;
        }

        //Caso 2 : Si sta creando la sorgente
        if (!isValid(self.source.id))
            self.source.hvSource=newVM;
        else {//Caso 3 : Si sta modificando la sorgente
            var oldVM=self.source.hvSource;

            //test
            //newVM.splice(0, 1);
            //oldVM.splice(1, 1);
            //newVM[0].virtualHardDisks.splice(0, 1);
            //oldVM[1].virtualHardDisks.splice(1, 1);
            var syncronizationResult=syncronizeArray(this.$PlatformsTypes.HyperV, oldVM, newVM);
            self.source.hvSource=syncronizationResult.finalArray;

            // syncronizationResult.messages.forEach(async messageObj => {
            //     self.$root.toast(this.$t(messageObj.message, messageObj.args), 3000, "warning");
            //     await waitSeconds(3);
            // });
        }
        //self.hvselected = self.source.hvSource[0]

    },
    methods: {

        /*handleSelectionChanged(count) {
            this.selectedCount = count;
        },*/

        async changeVmStatusHyperV(newState) {

            //Personalizzo la dialog da mostrare
            var dialogID=Math.floor(100000+Math.random()*900000).toString();
            var dialogTitle=this.$t("Change VM status");
            var dialogContent;

            switch (newState) {
                case this.STATE_START:
                    dialogContent=this.$t("Do you want to turn on the virtual machine '{0}'?", { 0: this.hvselected.name });
                    break;
                case this.STATE_OFF:
                    dialogContent=this.$t("Do you want to turn off the virtual machine '{0}'?", { 0: this.hvselected.name });
                    break;
                case this.STATE_SHUTDOWN:
                    dialogContent=this.$t("Do you want to shut down the virtual machine '{0}'?", { 0: this.hvselected.name });
                    break;
                case this.STATE_SAVE:
                    dialogContent=this.$t("Do you want to save the state of virtual machine '{0}'?", { 0: this.hvselected.name });
                    break;
                case this.STATE_QUIESCE:
                    dialogContent=this.$t("Do you want to quiesce the virtual machine '{0}'?", { 0: this.hvselected.name });
                    break;
            }

            let self=this;
            this.$root.$on(dialogID, async ok => {
                try {
                    var changedState=await self.$root.socket("ChangeVmStatusHyperV", self.hvselected.guid, newState);

                    if (changedState)
                        self.hvselected.status=newState;

                } finally {
                    self.$root.$off(dialogID);

                }


            });

            //Avvio la richiesta che mi risponderà con un $emit
            this.$root.openYesNoDialog(dialogID, dialogTitle, dialogContent)


        },
        next() {
            var next=true;

            this.steps.forEach((step, index) => {
                if (step==1) {
                    if (next) {
                        this.$set(this.steps, index+1, true)
                        this.$set(this.steps, index, false)
                        next=false;

                    }
                }
            });

        },
        back() {
            var next=true;
            this.steps.forEach((step, index) => {
                if (step==1) {
                    if (next) {
                        this.$set(this.steps, index-1, true)
                        this.$set(this.steps, index, false)
                        next=false;

                    }
                }
            });

        },
        saveSource() {
            this.$root.$emit("saveBackupSource", this.source);
        },
    }
}
</script>
<style scoped>
.splitter.vertical>.gutter {
    cursor: col-resize;
    display: none;
}
</style>