var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100 d-flex flex-column" },
    [
      _c("Navigation", { attrs: { pageName: "remotestats" } }),
      _vm.loading
        ? _c(
            "div",
            {
              staticClass:
                "h-100 m-0 op-dark-hi p-0 pos-absolute pos-center w-100 z-2",
            },
            [
              _c("span", {
                staticClass:
                  "ani-spin fg-white mif-5x mif-spinner2 mt-13-minus pos-center",
              }),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "w-100 p-3" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-12 col-sm-12 bg-transparent" }, [
            _c(
              "form",
              {
                staticClass: "inline-form",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.getStats()
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex flex-justify-left pb-1 pr-5" },
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filters.id_group,
                            expression: "filters.id_group",
                          },
                        ],
                        staticClass: "pl-2 select text-small mr-2 pl-3 pr-3",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.filters,
                              "id_group",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v(_vm._s(_vm.$t("All Groups"))),
                        ]),
                        _vm._l(_vm.groups, function (group) {
                          return _c(
                            "option",
                            {
                              key: group.id_group,
                              domProps: { value: group.id_group },
                            },
                            [_vm._v(_vm._s(group.name_group) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filters.id_user,
                            expression: "filters.id_user",
                          },
                        ],
                        staticClass: "pl-2 select text-small mr-2 pl-3 pr-3",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.filters,
                              "id_user",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v(_vm._s(_vm.$t("All Users"))),
                        ]),
                        _vm._l(_vm.users, function (user) {
                          return _c(
                            "option",
                            { key: user.id, domProps: { value: user.id } },
                            [_vm._v(_vm._s(user.username) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.dt_inf,
                          expression: "filters.dt_inf",
                        },
                      ],
                      staticClass: "pl-2 select text-small mr-2 pr-3",
                      attrs: {
                        type: "date",
                        max: _vm._f("moment")(Date.now(), "YYYY-MM-DD"),
                      },
                      domProps: { value: _vm.filters.dt_inf },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.filters, "dt_inf", $event.target.value)
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.dt_sup,
                          expression: "filters.dt_sup",
                        },
                      ],
                      staticClass: "pl-2 select text-small mr-2 pr-3",
                      attrs: {
                        type: "date",
                        max: _vm._f("moment")(Date.now(), "YYYY-MM-DD"),
                      },
                      domProps: { value: _vm.filters.dt_sup },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.filters, "dt_sup", $event.target.value)
                        },
                      },
                    }),
                    _c("input", {
                      staticClass: "button primary",
                      attrs: { type: "button", value: _vm.$t("Search") },
                      on: { click: _vm.getStats },
                    }),
                  ]
                ),
              ]
            ),
            _c("div", { staticClass: "info-box-content" }, [
              _c(
                "div",
                {
                  staticClass: "panel scroll p-4 mb-3",
                  staticStyle: { height: "78vh" },
                },
                [
                  _c("div", { staticClass: "overflow" }, [
                    _c("div", [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "cell-12" }, [
                          _c("label", { staticClass: "mr-1" }, [
                            _vm._v(_vm._s(_vm.$t("Number of Sessions per"))),
                          ]),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selected,
                                  expression: "selected",
                                },
                              ],
                              staticClass:
                                "d-inline-flex select text-small mr-2 pl-3 pr-3 w-auto",
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.selected = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  function ($event) {
                                    return _vm.onSelBarUnoChange()
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "option",
                                { attrs: { disabled: "", value: "" } },
                                [_vm._v(_vm._s(_vm.$t("Please select one")))]
                              ),
                              _c("option", { attrs: { value: "1" } }, [
                                _vm._v(_vm._s(_vm.$t("Month"))),
                              ]),
                              _c(
                                "option",
                                { attrs: { value: "2", selected: "" } },
                                [_vm._v(_vm._s(_vm.$t("Day")))]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { attrs: { id: "chart1" } },
                            [
                              _c("apexchart", {
                                attrs: {
                                  type: "bar",
                                  height: "380",
                                  options: _vm.chartOptions,
                                  series: _vm.seriesBar,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "cell-12" }, [
                          _c("label", { staticClass: "mr-1" }, [
                            _vm._v(_vm._s(_vm.$t("Duration of Sessions per"))),
                          ]),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedLine,
                                  expression: "selectedLine",
                                },
                              ],
                              staticClass:
                                "d-inline-flex select text-small mr-2 pl-3 pr-3 w-auto",
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.selectedLine = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  function ($event) {
                                    return _vm.onSelLineUnoChange()
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "option",
                                { attrs: { disabled: "", value: "" } },
                                [_vm._v(_vm._s(_vm.$t("Please select one")))]
                              ),
                              _c("option", { attrs: { value: "1" } }, [
                                _vm._v(_vm._s(_vm.$t("Month"))),
                              ]),
                              _c(
                                "option",
                                { attrs: { value: "2", selected: "" } },
                                [_vm._v(_vm._s(_vm.$t("Day")))]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { attrs: { id: "chart3" } },
                            [
                              _c("apexchart", {
                                attrs: {
                                  type: "area",
                                  height: "380",
                                  options: _vm.chartOptionsLine,
                                  series: _vm.seriesLine,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "cell-6 cell-lg-5 cell-xl-4" },
                          [
                            _c("label", [
                              _vm._v(
                                _vm._s(_vm.$t("% of Sessions per Operator"))
                              ),
                            ]),
                            _c(
                              "div",
                              { attrs: { id: "chart2" } },
                              [
                                _c("apexchart", {
                                  attrs: {
                                    type: "donut",
                                    height: "380",
                                    options: _vm.chartOptionsPie,
                                    series: _vm.seriesPie,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "cell-6 cell-lg-5 cell-xl-4" },
                          [
                            _c("label", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("% of Session Duration per Operator")
                                )
                              ),
                            ]),
                            _c(
                              "div",
                              { attrs: { id: "chart4" } },
                              [
                                _c("apexchart", {
                                  attrs: {
                                    type: "donut",
                                    height: "380",
                                    options: _vm.chartOptionsPieDown,
                                    series: _vm.seriesPieDown,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "cell-12" }, [
                          _c("label", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "Number and Duration of incoming sessions per device"
                                )
                              )
                            ),
                          ]),
                          _c(
                            "div",
                            { attrs: { id: "chart5" } },
                            [
                              _c("apexchart", {
                                attrs: {
                                  type: "bar",
                                  height: "400",
                                  options: _vm.chartOptionsBarBottom,
                                  series: _vm.chartSeriesBarBottom,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "cell-12" }, [
                          _c("label", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "Number and Duration of incoming sessions per group"
                                )
                              )
                            ),
                          ]),
                          _c(
                            "div",
                            { attrs: { id: "chart6" } },
                            [
                              _c("apexchart", {
                                attrs: {
                                  type: "bar",
                                  height: "400",
                                  options: _vm.chartOptionsBarBottomGRP,
                                  series: _vm.chartSeriesBarBottomGRP,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "cell-12" }, [
                          _c("label", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "Number and Duration of incoming sessions per operator"
                                )
                              )
                            ),
                          ]),
                          _c(
                            "div",
                            { attrs: { id: "chart7" } },
                            [
                              _c("apexchart", {
                                attrs: {
                                  type: "bar",
                                  height: "400",
                                  options: _vm.chartOptionsBarBottomUSR,
                                  series: _vm.chartSeriesBarBottomUSR,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }