var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "w-100 h-100",
      staticStyle: { position: "relative", height: "100%", width: "100%" },
    },
    [_c("canvas", { ref: "chartCanvas" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }