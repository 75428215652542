<template>
    <div class="w-100 h-100" style="position: relative; height: 100%; width: 100%;">
      <canvas ref="chartCanvas"></canvas>
    </div>
  </template>
  
  <script>
  import { Chart } from "chart.js";
  
  
  export default {
    name: "NestedDoughnutChart",
    props: {
      typeChart: {
        type: String,
        required: true
      },
      fullStats: {
        type: Object,
        required: true
      },

    },
    data() {
      return {
        statColors : {
          nOfServers: "#6610f2",
          nOfTotalPcs: "#20c997",
          nOfWorkstations: "#17a2b8",
          nPcAvAlert: "#dc3545",        // Rosso (alert)
          nPcAvOk: "#28a745",           // Verde (ok)
          nPcDiskAlert: "#e83e8c",      // Rosa (alert disk)
          nPcDiskOk: "#007bff",         // Blu (ok disk)
          nPcMonitored: "#28a745",      // Verde (monitorati)
          nPcOsPatchesAlert: "#ffc107", // Giallo (patches alert)
          nPcOsPatchesOk: "#6f42c1",    // Viola (patches ok)
          nPcUnmonitored: "#d3d3d3" ,    // Grigio (non monitorati)
          nPcMonitored: "#007bff"     // Grigio (non monitorati)
        }
      }
    },
    mounted() {

      const ctx = this.$refs.chartCanvas.getContext("2d");
     
  
      new Chart(ctx, {
        responsive:true,
        type: "doughnut",
        data: {
          labels: 
            this.typeChart == 'Disk' ? ["OK", "Alert", "Monitored", "Unmonitored"]:
            this.typeChart == 'Security' ?["OK", "Alert", "Monitored", "Unmonitored"]:
            this.typeChart == 'Backups' ? ["OK", "Alert", "Warning"]:
            this.typeChart == 'Devices' ? ["Server", "Workstation"]:
            ["OK", "Alert", "Monitored", "Unmonitored"],
          datasets: [
            {
              label: 
                this.typeChart == 'Disk' ? "Devices Disk Status": 
                this.typeChart == 'Security'?"Antivirus Status":
                this.typeChart == 'Devices'?"Devices Type":
                "OS Patch Installation",
              data:
                this.typeChart == 'Disk' ? 
                [
                this.fullStats.computer_stats.nPcDiskOk, 
                this.fullStats.computer_stats.nPcDiskAlert, 
                0,
                this.fullStats.computer_stats.nPcUnmonitored,
                ] : 
                this.typeChart == 'Security' ? [
                  this.fullStats.computer_stats.nPcAvOk,
                  this.fullStats.computer_stats.nPcAvAlert,
                  0,
                  this.fullStats.computer_stats.nPcUnmonitored,
                ] :
                this.typeChart == 'Backups' ? [
                  this.fullStats.backup_stats.result_ok,
                  this.fullStats.backup_stats.result_error,
                  this.fullStats.backup_stats.result_warning,
                  0
                ] : 
                this.typeChart == 'Devices' ? [
                  this.fullStats.computer_stats.nOfServers,
                  this.fullStats.computer_stats.nOfWorkstations,
                  0,
                  0
                ] : 
                [
                  this.fullStats.computer_stats.nPcOsPatchesOk,
                  this.fullStats.computer_stats.nPcOsPatchesAlert,
                  0,
                  this.fullStats.computer_stats.nPcUnmonitored,
                ],
                backgroundColor: this.typeChart == 'Devices' ? ["#5ebdec", "#007bff"] : this.typeChart == 'Backups' ? ["#69b31f", "#dc3545", "#ffc107"] : ["#69b31f", "#dc3545", "#007bff", "#d3d3d3"],  
            },
            this.typeChart != 'Backups' && this.typeChart != 'Devices' ? {
              label: "Pc Monitored",
              data: [
                0,
                0,
                this.fullStats.computer_stats.nPcMonitored, 
                this.fullStats.computer_stats.nPcUnmonitored,
                
            ], 
              backgroundColor: ["#69b31f", "#dc3545", "#007bff", "#d3d3d3"],  
            }:{}
          ],
        },
        options: {
          responsive: true,
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0
            }
          },
          legend: {
            display:false,
            position: "bottom",
            align: "center",
            labels: {
              defaultFontFamily: 'Poppins, Arial, sans-serif',
            }
          },


          cutoutPercentage: "20",
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                var dataset = data.datasets[tooltipItem.datasetIndex];
                var datasetLabel = dataset.label;
                var dataLabel = data.labels[tooltipItem.index];
                var value = dataset.data[tooltipItem.index];

                // Mostra il tooltip solo per i segmenti con valore > 0
                if (value > 0) {
                  return datasetLabel + ": " + dataLabel+" (" + value + ")";
                }
              }
            }
          }
        },
      });
    },
  };
  </script>

  