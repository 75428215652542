<template>
    <!--------------------FINESTRA AGGIUNTA / MODIFICA UTENTE------------------------------------------------>
    <div class="dialog light w-100-sm w-100-md w-75-lg  w-50-xl h-75 draggable-item" id="user_dialog" v-if="selecteduser">
        <div class="dialog-title pl-10">
            {{ selecteduser.id == null ? $t('Create New User') : selecteduser.username }}
            <span class="button square closer" @click="$root.$emit('CLOSEUSERVIEWDIALOG')"></span>
        </div>
        <div class="dialog-content overflow bg-light">
            <ul data-role="tabs" class="bg-light"  data-expand="true" style="z-index: 1;">
                <li><a href="#profile"><span class="mif mif-profile mif-2x"></span>{{ $t("Profile") }}</a></li>
                <li class=" no-border" v-if="$root.checkAuth().id_user == selecteduser.id"><a href="#permissions"><span class="mif mif-notifications mif-2x"></span> {{ $t("Policy Notifications") }}</a></li> 
            </ul>

            <!--------------------FINESTRA AGGIUNTA / MODIFICA UTENTE / PROFILE------------------------------------------------>
            <form>
                
                <div id="profile" class="border border-top">

                     <div class="form-group row bg-light mt-4 mb-4" v-if="this.$root.checkAuth().id_user == selecteduser.id">
                        <div class="p-2">
                            <label>{{ $t("Two Factor Authentication") }}</label><br>
                            <input data-class="ml-3" :disabled="this.$root.checkAuth().id_user != selecteduser.id"
                                data-on="on" data-off="off" @change="toggleTFA()" v-model="startEnabling"
                                type="checkbox" data-role="switch" data-caption-position="right"
                                :data-caption="$t('Enable 2FA')">
                        </div>
                        <div v-if="checkTFA.step1 != null && checkTFA.step2 == null" class="bg-primary border fg-white rounded">
                            <div class="row p-2">

                                <div class="cell-md-6  cel-lg-4 cell-sm-12 text-center">
                                    <div class="m-4">
                                        <Qrcode :value="checkTFA.step1" />
                                        <label>{{ checkTFA.secret }}</label>
                                    </div>

                                </div>
                                <div class="cell-md-6 cel-lg-8 cell-sm-12">
                                    <div class="mb-3 p-1 text-left pt-6">
                                        <label class="h6">
                                            {{ $t("Scan the QR code on the page using an app such as Google Authenticator, 2 FAS, Microsoft Authenticator, Twilio Authy.") }}
                                        </label>
                                    </div>
                                    <div class="inline-form">
                                        <input maxlength="6" minlength="6" class="input w-100" v-model.trim="selecteduser.TFAcode" type="text" :placeholder="$t('Insert Code from Authenticator')" required />
                                    </div>
                                    <div class="inline-form p-2">
                                        <a class="button primary w-100" :disabled="selecteduser.TFAcode == null || selecteduser.TFAcode.length < 6"  @click="enableTFA">{{ $t("Enable TFA") }}</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="form-group row">
                        <label>{{ $t("Username") }}</label>
                        <input data-role="input"  @keydown="$root.blockSpaces" :disabled="selecteduser.id!=null" v-model.trim="selecteduser.username" type="text" required
                            data-prepend="<span class='mif-user'></span>" />
                        <small class="text-muted">{{ $t("Required") }}</small>
                    </div>
                    <div class="form-group row">
                        <div class="col-6">
                            <label>{{ $t("Firstname") }}</label>
                            <input data-role="input" v-model.trim="selecteduser.firstname" type="text" required
                                data-prepend="<span class='mif-user'></span>" />
                            <small class="text-muted">{{ $t("Required") }}</small>

                        </div>
                        <div class="col-6">
                            <label>{{ $t("Lastname") }}</label>
                            <input data-role="input" v-model.trim="selecteduser.lastname" type="text" required
                                data-prepend="<span class='mif-user'></span>" />
                            <small class="text-muted">{{ $t("Required") }}</small>

                        </div>
                    </div>
                    <div class="form-group row pb-1">
                        <div class="col-6">
                            <label>{{ $t("Email") }}</label>
                            <input :disabled="selecteduser.id != null" data-role="input" v-model="selecteduser.email" type="email" required
                                data-prepend="<span class='mif-mail'></span>" />
                            <small class="text-muted">{{ $t("Required") }}</small>
                        </div>
                        <div class="col-6">
                            <label>{{ $t("User Role") }}</label>
                            <select class="select" v-model="selecteduser.role"
                                :disabled="selecteduser.id==$session.getAuth()?.id_user">
                                <option v-for="role in this.$session.getRoles()" :value="role.name" :key="role.id"> {{ role.name }} </option>
                            </select>
                        </div>

                    </div>

                    <div class="form-group row" v-if="selecteduser.id==null">
                        <div class="col-12">
                            <input data-class="ml-3" 
                                data-on="on" data-off="off"  v-model="selectAllGroups"
                                type="checkbox" data-role="switch" data-caption-position="right"
                                :data-caption="$t('Assign all groups')">
                        </div>
                        <div class="col-12" v-if="selectAllGroups==false">
                            <label>{{ $t("Allowed Groups") }}</label>
                                <select data-role="select" data-filter="true" multiple  :data-filter-placeholder="$t('Search for groups')"
                                data-cls-selected-item="bg-light border pr-2 rounded" id="groupselect" name="ids_groups">
                                <option :selected="selecteduser.groups.find(elem => elem == group.id_group)"
                                    v-for="group in $session.getGroups()" :value="group.id_group" :key="group.id_group">
                                    {{ group.name_group }} </option>
                                </select>
                        </div>
                    </div>

                    <div class="form-group row" v-if="selecteduser.id != null">
                       
                        <div class="col-12">
                            <label>{{ $t("Allowed Groups") }}</label>
                                <select data-role="select" data-filter="true" multiple data-cls-selected-item="bg-light border pr-2 rounded" id="groupselect" name="ids_groups">
                                <option :selected="selecteduser.groups.find(elem => elem == group.id_group)"
                                    v-for="group in $session.getGroups()" :value="group.id_group" :key="group.id_group">
                                    {{ group.name_group }} </option>
                                </select>
                        </div>
                    </div>


                    <div class="form-group row" v-if="selecteduser.id != null">
                        <div class="col-12">

                            <label>{{ $t("Password") }}</label>
                            <input data-role="input" autocomplete="false" v-model.trim="selecteduser.oldPassword"
                                type="password" required data-prepend="<span class='mif-lock'></span>" />
                        </div>

                    </div>
                    <div class="form-group row">
                        <div class="col-6">
                            <label>{{ $t("New Password") }}</label>
                            <input data-role="input" autocomplete="false" v-model.trim="selecteduser.password"
                                type="password" required data-prepend="<span class='mif-lock'></span>" />
                            <small class="text-muted">{{ $t("Required") }}</small>

                        </div>
                        <div class="col-6">
                            <label>{{ $t("Retype Password") }}</label>
                            <input data-role="input" autocomplete="false" v-model.trim="selecteduser.confirmPassword"
                                type="password" required data-prepend="<span class='mif-lock'></span>" />
                            <small class="text-muted">{{ $t("Required") }}</small>

                        </div>
                    </div>
                    <!-- <div class="form-group row">
                            <input v-if="selecteduser.id == null" v-model="selecteduser.sendResetPasswordEmail"
                            type="checkbox" data-role="checkbox"
                            :data-caption="$t('Send Reset Password email to user')">
                    </div> -->

                   
                    <div class="form-group row" v-if="$root.checkAuth().id_user == selecteduser.id">
                        <div class="p-2">

                        <h6 class="text-bold">{{ $t("Notification Preferences") }}</h6>
                        </div>
                        <table class="table">
                            <thead>
                                <tr>
                                    <td class="text-center">{{ $t("In App") }}</td>
                                    <td class="text-center">{{ $t("Desktop") }} </td>
                                    <td class="text-center">{{ $t("Mobile") }} </td>
                                    <td class="text-center">{{ $t("E-mail") }} </td>
                                    <td class="text-center">{{ $t("Whatsapp") }} </td>
                                </tr>
                            </thead>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <div class="text-center  mb-2">
                                                <input type="checkbox"  data-role="switch" :data-on="true" v-model="selecteduser.socket_notification">
                                            </div>
                                        </td>
                                        <td>
                                            <div class="text-center mb-2">
                                                <input type="checkbox" data-caption-position="left" data-role="switch" data-on="true" v-model="selecteduser.webpush_notification" >
                                            </div>
                                        </td>
                                        <td>
                                            <div class="text-center mb-2">
                                                <input type="checkbox" data-caption-position="left" data-role="switch" data-on="true" v-model="selecteduser.push_notification">
                                            </div>
                                        </td>
                                        <td>
                                            <div class="text-center mb-2">
                                                <input type="checkbox" data-caption-position="left" data-role="switch" data-on="true" :disabled="selecteduser.email==''?'disabled':false" v-model="selecteduser.email_notification">
                                            </div>
                                        </td>
                                        <td>
                                            <div class="text-center mb-2">
                                                <input type="checkbox" disabled data-caption-position="left" data-role="switch" data-on="true" v-model="selecteduser.whatsapp_notification">
                                            </div>
                                        </td>
                                    </tr>
                                    
                                    
                                </tbody>
                            </table>

                        </div>




                </div>
            </form>
             <div id="permissions" class="border border-top h-100" v-if="$root.checkAuth().id_user == selecteduser.id">
                    <div class="row">
                       
                            <ul class="sidebar-menu col-4 p-0 pt-5">
                          
                                <li v-for="(key, index) in Object.keys(selecteduser.policies) "><a class="pl-3" @click="policyTypeselected=index" :class="{ 'ml-5 bg-light pl-3 rounded text-bold': policyTypeselected == index }">{{ key }}</a></li>

                                
                            </ul>
                           <div v-if="index==policyTypeselected" v-for="(value,index) in Object.values(selecteduser.policies)" class="col-8 p-4">
                                <ul class="" >

                                    <li class="p-2 m-1 d-flex flex-align-center flex-justify-between" v-for="(policy) in value">
                                          <label class="text-bold">{{ policy.name }}</label>
                                          <input type="checkbox" v-model="policy.enabled_notifications" data-caption-position="right" data-role="checkbox"  data-caption="<span class='mif-notifications mif-2x'></span>" :data-on="true"   >
                                        
                                    
                                    </li>
                                   
                                </ul>
                        
                                
                        
                            </div>
                         
                        

                    </div>
                </div>
        </div>
        <div class="dialog-actions">
            <button class="button primary" @click="saveUser">{{ $t("Save") }}</button>
            <button v-if="selecteduser.id != null" :disabled="$root.checkAuth().id_user == selecteduser.id || selecteduser.level == '2'" class="button error ml-2" @click="deleteUser(selecteduser)">{{ $t("Delete")}}</button>
            <button class="button ml-2" @click="$root.$emit('CLOSEUSERVIEWDIALOG')">{{ $t("Close") }}</button>
        </div>
    </div>

</template>
<script>
import { TFA_MESSAGES } from '../../../utils/messages';
import Qrcode from '../../Qrcode.vue';
import { draggableMixin } from '../../../mixins/draggable-mixin'

export default {

    mixins: [draggableMixin],
    components: {
        Qrcode
    },
    name: "UserDialog",
    data() { return { 
        policyTypeselected:0,
        selectAllGroups:false,
       startEnabling:false
     } },
    props: {
        selecteduser: {
            type: Object,
            required: true
        },
        checkTFA:{
            type:Object,
            required:true
        }
    },
      mounted: function() {
        this.startEnabling = this.selecteduser.mfa_enabled;
        this.checkTFA.step1=null;
    },
    methods:{

            confirm(option){
                console.log(option);
            },

            async deleteUser(user) {

                var dialogID = Math.floor(100000 + Math.random() * 900000).toString();
                var dialogTitle = "Delete User";
                var dialogContent = this.$t('deleteConfirmation', { 0: user.username });
                
                var okText = "Yes";
                var okClass = "mr-2 primary";
                var okResult = true;

                var cancelText = "No";
                var cancelClass = "alert";
                var cancelResult = false;

                let self = this;
                this.$root.$on(dialogID, async ok => {
                    self.$root.$off(dialogID);
                    if (ok) {


                        var ok = await self.$api.deleteUser(user.id, user.username);

                        if (ok) {
                            this.$root.$emit("refreshUsers");
                            this.$root.$emit('CLOSEUSERVIEWDIALOG');

                        }
                    }

                    self.$root.$off(dialogID);
                  
                });

                //Avvio la richiesta che mi risponderà con un $emit
                this.$root.openDialogActions(dialogID,dialogTitle, dialogContent, okText, okClass, okResult, cancelText, cancelClass, cancelResult)
            },

        async saveUser() {
            let self = this;

            var isNew = self.selecteduser.id == null;
           
            if (isNew && !this.selecteduser.password.split("").some(c => 65 <= c.charCodeAt(0) && c.charCodeAt(0) <= 90)) {
                self.$root.toast(this.$t("No uppercase characters in password."), 5000, "alert");
                return;
            }

            if (!self.selectAllGroups){
                self.selecteduser.ids_groups = window.Metro.getPlugin($("#groupselect"), 'select').val();
            }else{
                self.selecteduser.ids_groups = "ALL";
            }


            var user = isNew
            ?  await this.$api.createUser(self.selecteduser)
            : await this.$api.editUser(self.selecteduser);

            if (user == null) {
                return;
            }


            this.$root.$emit("refreshUsers");
            this.$root.$emit('CLOSEUSERVIEWDIALOG');
        },

         /*****************************USERS - TFA *************************************************/
        async toggleTFA() {
            var self = this;
            
            //lo abilito
            if (self.startEnabling){

                var uri = await this.$api.toggleTFA(self.$root.checkAuth().access_token);
                        

                const secretRegex = /secret=([^&]+)/;
                const match = uri.match(secretRegex);

                if (match) {
                    const secretValue = match[1];
                    self.checkTFA.secret= secretValue;
                    self.checkTFA.step1 = uri;
                } else {
                     this.$root.toast(this.$t(TFA_MESSAGES.NOT_ENABLED));
                }

            
            }else {
                if(self.selecteduser.mfa_enabled){
                    if(confirm("Are you sure?")==true){
                        var tfaDisabled = await this.$api.disableTFA(self.$root.checkAuth().access_token);
                        if (tfaDisabled) {
                            self.checkTFA.step1 = null;
                            self.selecteduser.mfa_enabled = false;
                            this.$root.toast(this.$t(TFA_MESSAGES.DISABLED));
                        } else{
                            this.$root.toast(this.$t(TFA_MESSAGES.DISABLED));
                        }
                    }else{
                        self.startEnabling = true;
                    }

                }else{
                    self.checkTFA.step1 = null;
                }

                

            }
        },
        async enableTFA() {
            var self = this;
            var esito = await this.$api.enableTFA(self.selecteduser.TFAcode);
            if(esito==false){
                 //this.$root.toast(this.$t("TFA_NOT_ENABLED"));
            }else{
                self.checkTFA.step2 = true;
                self.selecteduser.mfa_enabled = true;
                self.startEnabling = true;

            }
        },
    }
}
</script>