<template>
    <div class="dialog light w-100-sm w-100-md w-75-lg  w-50-xl draggable-item" v-cloak>

        <DialogTitle :title="serviceAccount.id == null ? $t('Add Service Account') : serviceAccount.name"
            :defaultTitle="serviceAccount.id == null ? $t('Add Service Account') : $t('Edit/Test Service Account')"
            :subTitle="serviceAccount.type_name" @closeDialog="$root.$emit('CLOSESERVICEACCOUNTDIALOG')" />


        <div class="dialog-content overflow bg-light">

            <!--------------------------FORM--------------------------------------------->

            <div class="form-group">
                <label>{{ $t("Name") }}</label>
                <input data-role="input" type="text" v-model.trim="serviceAccount.name" maxlength="50"
                    :placeholder="$t('Enter name')" data-prepend="<span class='mif-pencil'></span>" />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>

            <div v-if="$session.getRoomPC() == null" class="form-group">

                <!-------------------------------SCELTA PC + RECUPERO INSTANZE----------------------------------------->
                <label>
                    <span>{{ $t("Select an endpoint from which test the {0} connection", {0: $platformsTypesInfo[serviceAccount.type].name}) }}</span>
                </label>
                <div class="row m-0">
                    <select class="select cell-6 mr-5" v-model="serviceAccount.options.lastTestPC" :disabled="$session.getPCList().length == 0 || instances.length != 0">
                        <option v-for="pc in $session.getPCList()" :data-append="pc.note" :value="pc.id" 
                        :disabled="!$root.connectionsStates[pc.id] || pc.system_info[$SystemInfoType.SQL_INSTANCES_INSTALLED] == undefined || pc.system_info[$SystemInfoType.SQL_INSTANCES_INSTALLED].info_value==''" 
                        :title="!$root.connectionsStates[pc.id] ? 'Not connected' : pc.system_info[$SystemInfoType.SQL_INSTANCES_INSTALLED] == undefined || pc.system_info[$SystemInfoType.SQL_INSTANCES_INSTALLED].info_value=='' ? 'No SQL instance' : ''"
                        :key="pc.id" :selected="serviceAccount.options.lastTestPC != null && serviceAccount.options.lastTestPC == pc.id"> 
                                {{ pc.name }}
                        </option>
                    </select>
                    <div v-if="searchingInstances">
                        <span class="mif-spinner2 fg-primary ani-spin" />
                    </div>
                    <button v-else class="button primary" @click="getInstances()" :disabled="!serviceAccount.options.lastTestPC || instances.length != 0">{{ $t("List SQL Server instances") }}</button>
                </div>
            </div>

            <div class="form-group">
                <label>{{ $t("Local Instance URL") }}</label>
                <div class="cell-10 p-0">
                    <div class="d-flex align-items-center">
                        <select class="w-50 select" @change="onChange" v-model="serviceAccount.url" :disabled="!serviceAccount.options.lastTestPC || instances.length == 0">
                            <option value="-1" v-if="instances.length == 0" disabled>{{ $t("Local instances not found") }} </option>
                            <option v-for="(sa, index) in instances" class="p-0" :key="index" :value="sa.name">
                                {{ sa.name }}
                            </option>
                        </select>
                        <div v-if="searchingInstances == true" class="align-items-center">
                            <span class="mif-spinner2 mif-2x ani-spin ml-3 mr-3" /><label>{{ $t("Searching for Sql instances...") }}</label></div>
                    </div>
                    <small class="text-muted">{{ $t("Required") }}</small>
                </div>
                <div class="cell-2 p-0" v-if="serviceAccount.type == $PlatformsTypes.Database_MySQL" :disabled="instances.length == 0">
                    <label>{{ $t("PORT") }}</label>
                    <input data-role="input" class="metro-input" type="number" v-model.number="serviceAccount.port" v-number v-range="{min:10, max:65535}"
                        :placeholder="$t('Enter Domain')" data-prepend="<span class='mif-cloud'></span>" :disabled="!serviceAccount.options.lastTestPC" />
                    <small class="text-muted">{{ $t("Required") }}</small>
                </div>
            </div>

            <div class="form-group" v-if="serviceAccount.type == $PlatformsTypes.Database_SQLServer" :disabled="instances.length == 0 || !serviceAccount.options.lastTestPC">
                <input type="radio" data-role="radio" :data-caption="$t('Use Integrated Windows Authentication')"
                    name="useWinAuth" :value=true v-model="serviceAccount.options.useWinAuth"><br>
                <input type="radio" data-role="radio" :data-caption="$t('Use SQL Server Authentication')"
                    name="useWinAuth" :value=false v-model="serviceAccount.options.useWinAuth"><br>
            </div>

            <div class="form-group row" :disabled="instances.length == 0 || !serviceAccount.options.lastTestPC">
                <div class="cell-6">
                    <label>{{ $t("Username") }}</label>
                    <input :disabled="!serviceAccount.options.lastTestPC || (serviceAccount.options.useWinAuth && serviceAccount.type == $PlatformsTypes.Database_SQLServer)" data-role="input"
                        class="metro-input" type="text" v-model.trim="serviceAccount.username"
                        :placeholder="$t('Enter Username')" data-prepend="<span class='mif-user'></span>" />
                    <small class="text-muted">{{ $t("Required") }}</small>
                </div>
                <div class="cell-6">
                    <label>{{ $t("Password") }}</label>
                    <input :disabled="!serviceAccount.options.lastTestPC || (serviceAccount.options.useWinAuth && serviceAccount.type == $PlatformsTypes.Database_SQLServer)" data-role="input"
                        class="metro-input" type="password" v-model.trim="serviceAccount.password"
                        :placeholder="$t('Enter Password')" data-prepend="<span class='mif-lock'></span>" />
                    <small class="text-muted">{{ $t("Required") }}</small>
                </div>
            </div>

            <div v-if="serviceAccount.type == $PlatformsTypes.Database_SQLServer" class="form-group" :disabled="instances.length == 0 || !serviceAccount.options.lastTestPC">

                <input data-role="checkbox" type="checkbox" :data-caption="$t('Use TCP/IP Network Library')"
                    data-validate="required" v-model="serviceAccount.options.useTcpIpNetLib" required
                    data-prepend="<span class='mif-server'></span>" /><br>
                <input data-role="checkbox" type="checkbox" :data-caption="$t('Use Persist Security Info')"
                    data-validate="required" v-model="serviceAccount.options.usePersistSecInfo" required
                    data-prepend="<span class='mif-server'></span>" /><br>
                <input data-role="checkbox" type="checkbox" :data-caption="$t('Use Integrated Security SSPI')"
                    data-validate="required" v-model="serviceAccount.options.useIntSecSSPI" required
                    data-prepend="<span class='mif-server'></span>" /><br>
                <input data-role="checkbox" type="checkbox"
                    :data-caption="$t('Use Trusted Connection (required for Windows authentication)')"
                    data-validate="required" v-model="serviceAccount.options.useTrustConn" required
                    data-prepend="<span class='mif-server'></span>" /><br>
                <input data-role="checkbox" type="checkbox" :data-caption="$t('Use Encrypted Connection')"
                    data-validate="required" v-model="serviceAccount.options.useEncConn" required
                    data-prepend="<span class='mif-server'></span>" /><br>
            </div>

            
        </div>

        <!-------------------------------TEST ACCOUNT----------------------------------------->
        <div class="dialog-actions d-flex">
            <button v-if="serviceAccount.id !=null" class="button primary" :disabled="tested == TEST_WAIT" @click="$root.duplicateServiceAccount(serviceAccount)">{{ $t("Duplicate") }}</button><!---->
            <div v-if="tested == TEST_WAIT"><span class="mif-spinner2 ani-spin" /></div>
            <button class="button primary" @click="test" :disabled="!enableTest">{{ $t("Test account") }}</button>
            <button class="button primary" :disabled="!enableSave" @click="$root.saveServiceAccount(serviceAccount)">{{ $t("Save") }}</button>
            <button class="button primary" @click="$root.$emit('CLOSESERVICEACCOUNTDIALOG')">{{ $t("Close") }}</button>
            <button v-if="serviceAccount.id !=null" class="button alert" :disabled="tested == TEST_WAIT" @click="$root.deleteServiceAccount(serviceAccount)">{{ $t("Delete") }}</button>
        </div>
    </div>
</template>

<script>
import DialogTitle from "../../utils/DialogTitle.vue";
import { draggableMixin } from '../../../mixins/draggable-mixin'
import { isValid } from "../../../utils/utilitiesmodule";


export default {

    mixins: [draggableMixin],
    name: "DatabaseServiceAccount",
    components: {
        "DialogTitle": DialogTitle,
    },
    props: {
        serviceAccount: Object
    },
    data() {
        return {
            // -1 : avviato
            // 0 : non avviato o fallito
            // 1 : ok
            tested: 0,
            index_SA: -1,
            TEST_NOTOK: 0,
            TEST_WAIT: -1,
            TEST_OK: 1,
            instances: [],
            searchingInstances: false,
        }
    },
    computed: {
        allInputCompleted() {
            var specificInputsCompleted;
            switch (this.serviceAccount.type) {
                case this.$PlatformsTypes.Database_SQLServer:
                    specificInputsCompleted=this.serviceAccount.options.useWinAuth? this.serviceAccount.url!="":this.serviceAccount.url!=""&&this.serviceAccount.username!=""&&this.serviceAccount.password!="";
                    break;
                case this.$PlatformsTypes.Database_MySQL:
                    specificInputsCompleted=this.serviceAccount.url!="";
                    break;
            }
            return this.serviceAccount.name!=""&&(this.$session.getRoomPC()!=null||this.serviceAccount.options.lastTestPC!=null)&&specificInputsCompleted;
        },
        enableTest() {
            return this.allInputCompleted&&this.tested==this.TEST_NOTOK;
        },
        enableSave() {
            return this.allInputCompleted&&(this.tested==this.TEST_OK||this.tested==this.TEST_NOTOK&&this.serviceAccount.id!=null);
        }

    },
    created() {

        if (this.serviceAccount.options.lastTestPC==null && isValid(this.$session.getRoomPC())) {
            this.serviceAccount.options.lastTestPC=this.$session.getRoomPC().id;
        } else if (this.serviceAccount.options.lastTestPC!=null && this.$session.getPCWithID(this.serviceAccount.options.lastTestPC)==null){
            this.$root.toast(this.$t("No computer found"), 3000, "alert");
            this.serviceAccount.options.lastTestPC=null;
        }

        if (this.serviceAccount.options.lastTestPC!=null) {
            this.getInstances();
        }

    },
    methods: {
        async test() {
            this.tested=(await this.$root.testServiceAccount(this.serviceAccount, this.$session.getPCWithID(this.serviceAccount.options.lastTestPC))).okNumber;
        },

        getInstances() {

            let self=this;

            self.searchingInstances=true;
            this.$root.socket("SETPC", this.serviceAccount.options.lastTestPC);
            this.$root.socket("GETINSTANCESLIST")
                .then(response => {
                    self.searchingInstances=false;
                    if (response?.instances) {
                        self.instances=response.instances;

                        if (response?.instances.length==1) {
                            self.serviceAccount.url=self.instances[0].name;
                        }

                        if (!isValid(self.$session.getRoomPC()))
                            self.$root.socket("SETPC", null);
                    }
                })
                .catch(error => {
                    // Gestisci l'errore qui
                    self.searchingInstances=false;
                    console.error(error);
                    if (!isValid(self.$session.getRoomPC()))
                        self.$root.socket("SETPC", null);
                });

        },

        onChange(event) {

            // per aiutare l'utente si imposta il friendly name uguale al nome dell'istanza
            let self=this;
            const selectedIndex=event.target.selectedIndex;
            self.serviceAccount.name=event.target.options[selectedIndex].text;
        },

    }
}

</script>