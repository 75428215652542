var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "dialog light center text-small w-100-sm w-100-md w-75-lg w-50-xl draggable-item",
    },
    [
      _c("DialogTitle", {
        attrs: {
          title:
            _vm.serviceAccount.id == null
              ? _vm.$t("Add Service Account")
              : _vm.serviceAccount.name,
          defaultTitle:
            _vm.serviceAccount.id == null
              ? _vm.$t("Add Service Account")
              : _vm.$t("Edit/Test Service Account"),
          subTitle: _vm.serviceAccount.type_name,
        },
        on: {
          closeDialog: function ($event) {
            return _vm.$root.$emit("CLOSESERVICEACCOUNTDIALOG")
          },
        },
      }),
      _c("div", { staticClass: "dialog-content overflow bg-light" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("Name")))]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.serviceAccount.name,
                expression: "serviceAccount.name",
                modifiers: { trim: true },
              },
            ],
            attrs: {
              "data-role": "input",
              type: "text",
              maxlength: "50",
              placeholder: _vm.$t("Enter name"),
              "data-prepend": "<span class='mif-pencil'></span>",
            },
            domProps: { value: _vm.serviceAccount.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.serviceAccount, "name", $event.target.value.trim())
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(_vm._s(_vm.$t("Required"))),
          ]),
        ]),
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("Connection Type")))]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.serviceAccount.options.connectionType,
                  expression: "serviceAccount.options.connectionType",
                },
              ],
              staticClass: "select cell-2 p-0",
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.serviceAccount.options,
                    "connectionType",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { attrs: { value: "ftp" } }, [_vm._v("FTP")]),
              _c("option", { attrs: { value: "ftps" } }, [_vm._v("FTPS")]),
            ]
          ),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(_vm._s(_vm.$t("Required"))),
          ]),
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c("div", { staticClass: "cell-6 p-0 mr-3" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("Host name")))]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.serviceAccount.options.host,
                  expression: "serviceAccount.options.host",
                  modifiers: { trim: true },
                },
              ],
              attrs: {
                "data-role": "input",
                type: "text",
                placeholder: _vm.$t("Enter Address"),
                "data-prepend": "<span class='mif-cloud'></span>",
              },
              domProps: { value: _vm.serviceAccount.options.host },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.serviceAccount.options,
                    "host",
                    $event.target.value.trim()
                  )
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.$t("Required"))),
            ]),
          ]),
          _c("div", { staticClass: "cell-2 p-0" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("Port")))]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.serviceAccount.port,
                  expression: "serviceAccount.port",
                  modifiers: { number: true },
                },
                { name: "number", rawName: "v-number" },
                {
                  name: "range",
                  rawName: "v-range",
                  value: { min: 10, max: 65535 },
                  expression: "{ min: 10, max: 65535 }",
                },
              ],
              staticClass: "metro-input",
              attrs: { type: "number", placeholder: _vm.$t("Enter Port") },
              domProps: { value: _vm.serviceAccount.port },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.serviceAccount,
                    "port",
                    _vm._n($event.target.value)
                  )
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.$t("Required"))),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c("div", { staticClass: "cell-6" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("Username")))]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.serviceAccount.username,
                  expression: "serviceAccount.username",
                },
              ],
              attrs: {
                "data-role": "input",
                type: "text",
                required: "",
                "data-prepend": "<span class='mif-user'></span>",
              },
              domProps: { value: _vm.serviceAccount.username },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.serviceAccount, "username", $event.target.value)
                },
              },
            }),
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.$t("Required"))),
            ]),
          ]),
          _c("div", { staticClass: "cell-6" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("Password")))]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.serviceAccount.password,
                  expression: "serviceAccount.password",
                  modifiers: { trim: true },
                },
              ],
              staticClass: "metro-input",
              attrs: {
                "data-role": "input",
                type: "password",
                placeholder: _vm.$t("Enter Password"),
                "data-prepend": "<span class='mif-lock'></span>",
              },
              domProps: { value: _vm.serviceAccount.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.serviceAccount,
                    "password",
                    $event.target.value.trim()
                  )
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.$t("Required"))),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "form-group row" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.serviceAccount.options.passiveMode,
                expression: "serviceAccount.options.passiveMode",
              },
            ],
            attrs: {
              "data-role": "checkbox",
              type: "checkbox",
              "data-caption": _vm.$t("Passive Mode"),
              "data-validate": "required",
              required: "",
              "data-prepend": "<span class='mif-server'></span>",
            },
            domProps: {
              checked: Array.isArray(_vm.serviceAccount.options.passiveMode)
                ? _vm._i(_vm.serviceAccount.options.passiveMode, null) > -1
                : _vm.serviceAccount.options.passiveMode,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.serviceAccount.options.passiveMode,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(
                        _vm.serviceAccount.options,
                        "passiveMode",
                        $$a.concat([$$v])
                      )
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.serviceAccount.options,
                        "passiveMode",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.serviceAccount.options, "passiveMode", $$c)
                }
              },
            },
          }),
        ]),
        _vm.$session.getRoomPC() == null
          ? _c("div", { staticClass: "form-group" }, [
              _c("label", [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "Select an endpoint from which test the {0} connection",
                        {
                          0: _vm.$platformsTypesInfo[_vm.serviceAccount.type]
                            .name,
                        }
                      )
                    )
                  ),
                ]),
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.testPC,
                      expression: "testPC",
                    },
                  ],
                  staticClass: "select cell-9",
                  attrs: { disabled: _vm.$session.getPCList().length == 0 },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.testPC = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.$session.getPCList(), function (pc) {
                  return _c(
                    "option",
                    {
                      key: pc.id,
                      attrs: {
                        "data-append": pc.note,
                        disabled: !_vm.$root.connectionsStates[pc.id],
                      },
                      domProps: { value: pc },
                    },
                    [_vm._v(" " + _vm._s(pc.name) + " ")]
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "dialog-actions" }, [
        _vm.serviceAccount.id != null
          ? _c(
              "button",
              {
                staticClass: "button primary",
                attrs: { disabled: _vm.tested == _vm.TEST_WAIT },
                on: {
                  click: function ($event) {
                    return _vm.$root.duplicateServiceAccount(_vm.serviceAccount)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("Duplicate")))]
            )
          : _vm._e(),
        _vm.tested == _vm.TEST_WAIT
          ? _c("div", [_c("span", { staticClass: "mif-spinner2 ani-spin" })])
          : _vm._e(),
        _c(
          "button",
          {
            staticClass: "button primary",
            attrs: { disabled: !_vm.enableTest },
            on: { click: _vm.test },
          },
          [_vm._v(" " + _vm._s(_vm.$t("Test account")) + " ")]
        ),
        _c(
          "button",
          {
            staticClass: "button primary",
            attrs: { disabled: !_vm.enableSave },
            on: {
              click: function ($event) {
                return _vm.$root.saveServiceAccount(_vm.serviceAccount)
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
        ),
        _c(
          "button",
          {
            staticClass: "button primary",
            on: {
              click: function ($event) {
                return _vm.$root.$emit("CLOSESERVICEACCOUNTDIALOG")
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("Close")))]
        ),
        _vm.serviceAccount.id != null
          ? _c(
              "button",
              {
                staticClass: "button alert",
                attrs: { disabled: _vm.tested == _vm.TEST_WAIT },
                on: {
                  click: function ($event) {
                    return _vm.$root.deleteServiceAccount(_vm.serviceAccount)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("Delete")))]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }