//args = // oggetto di dati extra passati
//ritorna un oggetto del tipo:
//{
//  ok:
//  okNumber:
//  serviceAccount
//  client:
//  list:

import { AzureBlobClient, AzureFileClient } from "./clients/azureblobfileClient";
import { AzureClient } from "./clients/azureClient";
import { DropboxAuthentication, DropboxClient } from "./clients/dropboxClient";
import { GoogleDriveAuthentication, GoogleDriveClient } from "./clients/googledriveClient";
import { GraphClient } from "./clients/graphClient";
import { S3Client } from "./clients/s3Client";
import { PlatformsTypes } from "../objects";
import { isValid } from "../utilitiesmodule";

//}
export async function testServiceAccount(serviceAccount) {

    var feedback = {
        ok: false,
        okNumber: 0,
        serviceAccount: serviceAccount,
        client: null,
        list: null
    };

    switch (serviceAccount.type) {
        case 10:
            feedback = await _testGoogleDrive(serviceAccount, true);
            break;

        case 110:
            feedback = await _testS3Storage(serviceAccount);
            break;

        case 111:
            feedback = await _testIperiusStorage(serviceAccount);
            break;

        case 112:
            feedback = await _testAmazonS3(serviceAccount);
            break;

        case 12:
            feedback = await _testDropbox(serviceAccount, true);
            break;

        case 13:
            feedback = await _testOnedrive(serviceAccount, true);
            break;

        case 140:
            feedback = await _testAzureBlob(serviceAccount);
            break;

        case 141:
            feedback = await _testAzureFile(serviceAccount);
            break;

        case PlatformsTypes.Microsoft_Exchange365:
        case PlatformsTypes.Microsoft_SharePoint:
        case PlatformsTypes.Microsoft_Teams:
        case PlatformsTypes.Microsoft_OneDriveForBusiness:
            feedback = await _testMicrosoft365(serviceAccount);
            break;
    }

    return feedback;

}

async function _testGoogleDrive(serviceAccount, tryAgain) {

    //Caso 1 : Se è presente l'accessToken, provo ad utilizzarlo
    if (isValid(serviceAccount.token)) {
        var googledrive = new GoogleDriveClient(serviceAccount);
        //Le richieste possono restituire un oggetto di tip {status:400, url:...} se non è stato abilitato Google Drive API sulla console di Google
        //Le richieste possono restituire null in caso di errore, ad esempio di token scaduto
        var response = await googledrive.getRootNodes();

        //Se il token non è valido, lo svuoto è riprovo
        if (response == null && tryAgain) {
            serviceAccount.token = "";
            return await _testGoogleDrive(serviceAccount, false);
        }

        if (response == null && !tryAgain) {
            serviceAccount.token = null;
            serviceAccount.refresh_token = null;
            return await _testGoogleDrive(serviceAccount, false);

        }

        //Non è stato abilitato Google Drive API sulla console di Google
        if (isValid(response.url)) {
            return {
                ok: false,
                okNumber: 0,
                serviceAccount: null,
                client: null,
                url: response.url
            };
        }

        var folders = response;

        if (folders != null) {
            return {
                ok: true,
                okNumber: 1,
                serviceAccount: serviceAccount,
                client: googledrive
            }
        }

        if (!tryAgain)
            return {
                ok: false,
                okNumber: 0,
                serviceAccount: null,
                client: null
            };

    }

    var auth = new GoogleDriveAuthentication(serviceAccount.client_id, serviceAccount.client_secret);

    //Caso 2 : Se è presente il refreshToken, provo ad utilizzarlo per richiedere il nuovo accessToken
    if (isValid(serviceAccount.refresh_token)) {
        serviceAccount.token = await auth.getAccessTokenWithRefreshToken(serviceAccount.refresh_token);

        if (serviceAccount.token != null)
            return await _testGoogleDrive(serviceAccount, false);

    }

    //Caso 3 : I token non sono validi, richiedo l'authorization code
    var authCode = await auth.getAuthCode();

    // Se la richiesta fallisce, fallisce il test
    if (authCode == null) {
        return {
            ok: false,
            okNumber: 0,
            serviceAccount: null,
            client: null
        };
    }

    // Richiedo i nuovi token con l'authorization code
    var tokens = await auth.getTokensWithAuthorizationCode(authCode);

    console.log(tokens);

    // Se la richiesta fallisce, fallisce il test
    if (tokens == null) {
        return {
            ok: false,
            okNumber: 0,
            serviceAccount: null,
            client: null
        }
    }

    serviceAccount.token = tokens.accessToken;
    serviceAccount.refresh_token = tokens.refreshToken;

    return await _testGoogleDrive(serviceAccount, false);

}

async function _testAmazonS3(serviceAccount) {
    var s3 = new S3Client(serviceAccount);

    var rootResources = await s3.getRootNodes();
    if (rootResources == null)
        return {
            ok: false,
            okNumber: 0,
            serviceAccount: serviceAccount,
            client: null
        };

    return {
        ok: true,
        okNumber: 1,
        serviceAccount: serviceAccount,
        client: s3
    };
}

async function _testDropbox(serviceAccount, tryAgain) {

    //Caso 1 : Se è presente l'accessToken, provo ad utilizzarlo
    if (isValid(serviceAccount.token)) {
        var db = new DropboxClient(serviceAccount);
        var rootResources = await db.getRootNodes();

        if (rootResources != null)
            return {
                ok: true,
                okNumber: 1,
                serviceAccount: serviceAccount,
                client: db
            };

        if (!tryAgain)
            return {
                ok: false,
                okNumber: 0,
                serviceAccount: null,
                client: null
            };
    }

    var auth = new DropboxAuthentication(serviceAccount.client_id, serviceAccount.client_secret);

    //Caso 2 : Se è presente il refreshToken, lo utilizza per richiedere il nuovo token
    if (isValid(serviceAccount.refresh_token)) {
        serviceAccount.token = await auth.getAccessTokenWithRefreshToken(serviceAccount.refresh_token);
        if (isValid(serviceAccount.token))
            return await _testDropbox(serviceAccount, null, false);
    }

    //Caso 3 : Se è stato passato l'authorizationCode, lo utilizzo per richiedere i nuovi token
    if (isValid(serviceAccount.options.authCode)) {

        var tokens = await auth.getTokensWithAuthCode(serviceAccount.options.authCode);
        //Se la richiesta fallisce, il test fallisce
        if (tokens == null)
            return {
                ok: false,
                okNumber: 0,
                serviceAccount: null,
                client: null
            };

        serviceAccount.token = tokens.accessToken;
        serviceAccount.refresh_token = tokens.refreshToken;
        serviceAccount.options.authCode = null;

        return await _testDropbox(serviceAccount, null, false);
    }

    //Caso 4 : Se i token non sono validi e non è stato passato l'authorization code, devo richiedere quest'ultimo,
    // che verrà inserito dall'utente nello step successivo
    auth.getAuthenticationCode();

    return {
        ok: false,
        okNumber: 0,
        serviceAccount: null,
        client: null
    };
}

async function _testOnedrive(serviceAccount, tryAgain) {
    var user;
    var drive;
    var response = {
        ok: false,
        okNumber: 0,
        serviceAccount: serviceAccount,
        client: null,
        list: null
    };

    //Caso 1 : Se è presente l'accessToken, provo ad effettuare una richiesta 
    if (isValid(serviceAccount.token)) {
        response.client = new GraphClient(serviceAccount);
        user = await response.client.getUserByEmail(serviceAccount.username);
        response.ok = isValid(user);
        response.okNumber = response.ok ? 1 : 0;
    }

    //Caso 2 : provo a recuperare il drive dell'utente
    if (isValid(serviceAccount.token) && response.ok) {
        drive = await response.client.getDriveByUserID(user.resID);
        response.ok = drive != null;
    }

    //Caso 3 : provo ad effettuare una richiesta al drive dell'utente
    if (isValid(serviceAccount.token) && response.ok) {
        response.serviceAccount.options.userID = user.resID;
        response.serviceAccount.options.driveID = drive.id;
        response.client = new GraphClient(serviceAccount);
        response.ok = (await response.client.getOneDriveRootNodes()) != null;
    }

    //Caso 1.1 : Se la richiesta va a buon fine, il test si è concluso con successo
    if (isValid(response.serviceAccount.token) && response.ok)
        return response;

    //Caso 1.2 : Se la richiesta non va a buon fine e non si deve effettuare un altro tentativo,
    // il test è fallito
    if (isValid(response.serviceAccount.token) && !response.ok && !tryAgain)
        return response;

    //Caso 1.2 : Se la richiesta non va a buon fine e si può effettuare un altro tentativo,
    // resetto l'accessToken e provo altri metodi di autenticazione
    if (isValid(response.serviceAccount.token) && !response.ok && tryAgain) {
        response.serviceAccount.token = null;
        return await _testOnedrive(response.serviceAccount, false);
    }



    //Caso 2 : Se non è presente l'access token, richiedo i token e riprovo
    //All'interno di azureClient.getTokens(), verranno gestiti tutti i casi di autenticazione
    //e verranno modificati i campi del service account passato
    var azureClient = new AzureClient(serviceAccount);
    var okAuth = await azureClient.getTokens();

    //Caso 2.1 : Se la richiesta va a buon fine, memorizzo le informazioni nel service account e riprovo
    if (okAuth) {
        return await _testOnedrive(azureClient.serviceAccount, false);
    }

    //Caso 2.2 : Se la richiesta non va a buon fine, il test è fallito
    return response;
}

async function _testAzureBlob(serviceAccount) {
    var azureblob = new AzureBlobClient(serviceAccount);
    var rootResources = await azureblob.getRootNodes();
    if (rootResources == null)
        return {
            ok: false,
            okNumber: 0,
            serviceAccount: false,
            client: null
        };

    return {
        ok: true,
        okNumber: 1,
        serviceAccount: serviceAccount,
        client: azureblob
    }
}

async function _testAzureFile(serviceAccount) {
    var azurefile = new AzureFileClient(serviceAccount);
    var rootResources = await azurefile.getRootNodes();
    if (rootResources == null)
        return {
            ok: false,
            okNumber: 0,
            serviceAccount: false,
            client: null
        };

    return {
        ok: true,
        okNumber: 1,
        serviceAccount: serviceAccount,
        client: azurefile
    }
}

async function _testIperiusStorage(serviceAccount) {
    var s3 = new S3Client(serviceAccount);
    var rootResources = await s3.getRootNodes();
    if (rootResources == null)
        return {
            ok: false,
            okNumber: 0,
            serviceAccount: serviceAccount,
            client: null
        };

    return {
        ok: true,
        okNumber: 1,
        serviceAccount: serviceAccount,
        client: s3
    };
}

async function _testS3Storage(serviceAccount) {
    var s3 = new S3Client(serviceAccount);
    var rootResources = await s3.getRootNodes();
    if (rootResources == null){
        return {
            ok: false,
            okNumber: 0,
            serviceAccount: serviceAccount,
            client: null
        };
    }

    return {
        ok: true,
        okNumber: 1,
        serviceAccount: serviceAccount,
        client: s3
    };
}
//Nota : Exchange 365 non utilizza il refresh token come Onedrive
//Verrà utilizzata l'autenticazione con client ID e client secret
async function _testMicrosoft365(serviceAccount) {
    var client = new AzureClient(serviceAccount);
    var ok = await client.getTokens();
    //Caso 1.1 : Se la richiesta va a buon fine, il test si è concluso con successo
    if (ok){
        return {
            ok: true,
            okNumber: 1,
            serviceAccount: client.serviceAccount,
            client: new GraphClient(client.serviceAccount),
            list: null
        };
    }
        
    //Caso 1.2 : Se la richiesta non va a buon fine e non si deve effettuare un altro tentativo,
    // il test è fallito
    return {
        ok: false,
        okNumber: 0,
        serviceAccount: null,
        client: null,
        list: null
    }
}


//ATTENZIONE : Sono mappati solamente i cloud, ftp, network e exchange 365
//args : oggetto di dati extra passati
export function getClientByTestedServiceAccount(serviceAccount) {
    switch (serviceAccount.type) {
        case PlatformsTypes.Cloud_GoogleDrive:
            return new GoogleDriveClient(serviceAccount);
        case PlatformsTypes.Cloud_S3CompatibleStorage:
        case PlatformsTypes.Cloud_IperiusS3Storage:
        case PlatformsTypes.Cloud_AmazonS3Storage:
            return new S3Client(serviceAccount);
        case PlatformsTypes.Cloud_Dropbox:
            return new DropboxClient(serviceAccount);
        case PlatformsTypes.Cloud_OneDrive:
        case PlatformsTypes.Microsoft_OneDriveForBusiness:
            return new GraphClient(serviceAccount);
        case PlatformsTypes.Cloud_AzureBlob:
            return new AzureBlobClient(serviceAccount);
        case PlatformsTypes.Cloud_AzureFile:
            return new AzureFileClient(serviceAccount);
        case PlatformsTypes.FTP:
        case PlatformsTypes.Network:
            serviceAccount.options.accountSessionId = "";
            return {
                serviceAccount: serviceAccount
            }

        default:
            return null;
    }
}