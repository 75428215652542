import { FileFolderEntityType, PlatformsTypes } from '../objects.js';
import { ClientTreeNode } from '../../models/TreeNode';
import { isValid } from '../utilitiesmodule.js';
/**********************RICERCA*****************************************************/
ClientTreeNode.prototype.findNodeWithPath=function(path) {
    var startNode=this;
    if (startNode.path==path)
        return startNode;
    var child=startNode.children.find(res =>
        path==res.path||
        path.startsWith(res.path+"/")||
        path.startsWith(res.path+"\\")||
        (path.startsWith(res.path)&&res.path.endsWith("/"))||
        (path.startsWith(res.path)&&res.path.endsWith("\\"))
    );

    if (child==undefined) {
        alert("Ho provato a cercare "+path+" in "+startNode.path+"ma non l'ho trovato");
        return null;
    }
    return child.findNodeWithPath(path);
}

ClientTreeNode.prototype.findNodeWithId=function(id) {
    var startNode=this;
    if (startNode.nodeID==id) return startNode;

    var indexes=id.split("-");
    if (indexes.length==1)
        return startNode;

    indexes.shift(); //rimuove il primo elemento
    indexes=indexes.map(i => parseInt(i));
    //ogni indice indica la posizione del figlio da prendere
    var currentNode=startNode;
    indexes.forEach(i => currentNode=currentNode.children[i]);
    return currentNode;
}

ClientTreeNode.prototype.toSimpleResource=function() {
    return {
        path: this.path,
        type: this.type
    }
}

ClientTreeNode.prototype.toString=function() {
    return this.path+"?"+this.type;
}

ClientTreeNode.prototype.getParent=function() {
    var id=this.nodeID.lastIndexOf("-")==4 //node-{numero con una cifra}
        ?
        "":
        this.nodeID.substr(0, this.nodeID.lastIndexOf("-"));

    var parentPath=this.path.getParent();

    return new ClientTreeNode(id, null, parentPath, false);
}

ClientTreeNode.prototype.hasParent=function() {
    return this.nodeID!="node"&&this.nodeID.lastIndexOf("-")!=4;
}

ClientTreeNode.prototype.getTemplate=function(checked) {
    var shortPath=this.getShortPath();
    var icon=this.type.getMetroIcon(this.path);
    var checkedProperty=checked?
        " checked ":
        " ";
    var template=
        "<div class='d-flex flex-align-center'>"+
        "<input type='checkbox' data-role='checkbox' id='checkbox-"+this.nodeID+"'"+checkedProperty+">"+
        "<label><span class='"+icon+" mx-1'/><span class='mx-1'>"+shortPath+"</span></label>"+
        "</div>";

    return template;
}

ClientTreeNode.prototype.getTemplateForSingleChoice=function(checked) {
    var shortPath=this.getShortPath();
    var icon=this.type.getMetroIcon(this.path);
    var checkedProperty=checked?
        " checked ":
        " ";
    var template=
        "<div class='d-flex flex-align-center'>"+
        "<input name='node' type='radio' data-role='radio' id='checkbox-"+this.nodeID+"'"+checkedProperty+">"+
        "<label><span class='"+icon+" mx-1'/><span class='mx-1'>"+shortPath+"</span></label>"+
        "</div>";

    return template;
}

ClientTreeNode.prototype.getShortPath=function() {
    var shortPath="";
    var slash=[FileFolderEntityType.FN_Folder, FileFolderEntityType.FN_File].includes(this.type)?
        "\\":"/";

    if (this.path.indexOf(slash)!=-1) {
        shortPath=this.path.split(slash).pop();
    } else if (this.path.indexOf("?")!=-1) {
        shortPath=this.path.split("?")[0];
    } else {
        shortPath=this.path;
    }

    if (slash=="\\"&&shortPath.endsWith(":")) {
        shortPath+="\\";
    }

    return shortPath;
}


ClientTreeNode.prototype.insertParentsInto=function(inputNodes, platformType) {
    if (!isValid(inputNodes))
        inputNodes=[];

    var parentsPaths=this.path.getAllPaths(platformType);
    parentsPaths.splice(-1, 1);
    parentsPaths.forEach(p => {
        if (inputNodes.findIndex(n => n.path==p)==-1)
            inputNodes.push(new ClientTreeNode(null, null, p, this.getParentType(platformType)));
    });

    return inputNodes;
}

ClientTreeNode.prototype.getParentType=function(platformType) {
    if ([PlatformsTypes.FileFolder, PlatformsTypes.Network].includes(platformType))
        return FileFolderEntityType.FN_Folder;
    if (platformType==2)
        return FileFolderEntityType.FTP_Folder;
    //childpath è la prima cartella
    if (this.path.indexOf("/")==-1) {
        return platformType==PlatformsTypes.Microsoft_SharePoint?
            FileFolderEntityType.SP_Site_All:
            FileFolderEntityType.Cloud_Root;
    }
    var nFolders=this.path.split("/").length;
    if (nFolders==2)
        switch (platformType) {
            case PlatformsTypes.Microsoft_SharePoint:
                return FileFolderEntityType.SP_Site;
            case PlatformsTypes.Cloud_AmazonS3Storage:
            case PlatformsTypes.Cloud_S3CompatibleStorage:
            case PlatformsTypes.Cloud_IperiusS3Storage:
            case PlatformsTypes.Cloud_AzureBlob:
            case PlatformsTypes.Cloud_AzureFile:
                return FileFolderEntityType.Cloud_Bucket;
            default:
                return FileFolderEntityType.Cloud_Folder;
        }


    if (nFolders==3&&platformType==PlatformsTypes.Microsoft_SharePoint)
        return FileFolderEntityType.SP_Library;


    return platformType==PlatformsTypes.Microsoft_SharePoint?
        FileFolderEntityType.SP_Folder:
        platformType==PlatformsTypes.FTP?
            FileFolderEntityType.FTP_Folder:
            FileFolderEntityType.Cloud_Folder;
}

ClientTreeNode.prototype.isFile=function() {
    return this.type.isFile();
}

ClientTreeNode.prototype.isChildOf=function(parentNodeMaybe, slash=null) {
    if (parentNodeMaybe==null) {
        return this.nParts==1;
    }
    if (slash==null) {
        slash=this.path.getSeparator();
    }

    return this.path.startsWith(parentNodeMaybe.path+slash)&&this.nParts==(parentNodeMaybe.nParts+1)
}