<template>
    <div>

        <!------------------SORGENTI - DRIVEIMAGE-------------------------------->
        <ul v-if="source.driveImgSource" data-role="treeview" data-on-expand-node.stop>
            <li v-for="(d, i) in source.driveImgSource.filter(dis => dis.partitions.some(p => p.selected || p.indeterminate))"  :data-caption="createCaptionForDisk(d, i)" v-bind:key="d.id"  data-collapsed="true">

                <ul>
                    <li v-for="p in (d.partitions.filter(d => d.selected || d.indeterminate))"
                        :data-caption="createCaptionForPartition(p)" v-bind:key="p.id" />
                </ul>
            </li>
        </ul>

        <!------------------SORGENTI - HYPERV----------------------------------------------->
        <ul v-if="source.hvSource" id="hypervTreeview" data-role="treeview" data-on-expand-node.stop>
            <li data-icon="<span class='mif-cabinet pt-1'/>" data-caption="localhost"  data-collapsed="true">
                <ul>
                    <li v-for="vm in source.hvSource.filter(x => x.selected || x.indeterminate)" data-icon="<span class='mif-stack pt-1'/>" :data-caption="vm.name"
                        :id="vm.name.replace(' ', '')">
                        <ul>
                            <ul>
                                <li v-for="vd in vm.virtualHardDisks" data-icon="<span class='mif-drive2 pt-1'/>"
                                    :data-caption="buildVirtualDiskCaptionHYPERV(vd)" />
                            </ul>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>

        <!------------------SORGENTI - ESXI----------------------------------------------->
        <ul v-if="source.esxiSource" data-role="treeview" data-on-expand-node.stop>
            <li class="p-1" :title="source.esxiSource.url"  data-collapsed="true">
                <label>
                    <span class="mif-vpn-publ pr-2" />
                    <span>{{ source.esxiSource.url + ' (' + source.esxiSource.hostVersion + ')' }}</span>
                </label>
                <ul>
                    <li v-for="datacenter in source.esxiSource.dataCenters?.filter(x => x.selected || x.indeterminate)"
                        :key="datacenter.name" data-collapsed="true">
                        <label><span class='mif-location-city pt-1 pr-2'></span>{{ datacenter.name }}</label>
                        <ul v-if="datacenter.computerResourceList.some(x => x.selected || x.indeterminate)">
                            <li class="p-1" :title="computerResource.name" data-collapsed="true"
                                v-for="computerResource in datacenter.computerResourceList.filter(x => x.selected || x.indeterminate)"
                                :key="computerResource.name" data-icon="<span class='mif-cabinet pt-1'></span>">
                                <label class="ml-5">{{ computerResource.name }}</label>
                                <ul v-if="datacenter.computerResourceList.some(x => x.selected || x.indeterminate)">
                                    <li class="p-1" :title="datastore.name" data-collapsed="true"
                                        v-for="(datastore) in computerResource.datastores.filter(x => x.selected || x.indeterminate)"
                                        :key="datastore.name" data-icon="<span class='mif-database pt-1'></span>">
                                        <label class="ml-5">{{ buildDatastoreCaption(datastore) }}</label>
                                        <ul v-if="datastore.virtualMachines.some(x => x.selected || x.indeterminate)">
                                            <li class="p-1" data-collapsed="true"
                                                v-for="(virtualmachine) in datastore.virtualMachines.filter(x => x.selected || x.indeterminate)"
                                                :key="virtualmachine.name" data-icon="<span class='mif-stack pt-1'></span>">
                                                <label class="ml-5 mif-info">{{ virtualmachine.name
                                                }}</label>
                                                <ul
                                                    v-if="virtualmachine.virtualDisks.some(x => x.selected || x.indeterminate)">
                                                    <li class="p-1" :title="virtualDisk.diskFilename.split('/')[1]"
                                                        data-collapsed="true"
                                                        v-for="(virtualDisk) in virtualmachine.virtualDisks.filter(x => x.selected || x.indeterminate)"
                                                        :key="virtualDisk.deviceKey"
                                                        data-icon="<span class='mif-drive2 pt-1'></span>">
                                                        <label class="ml-5">{{
                                                            buildVirtualDiskCaptionESXI(virtualDisk)
                                                        }}</label>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>

        <!-----------------SORGENTI EXCHANGE---------------------------------------------->
        <ul v-if="source.exchangeSource" data-role="treeview" data-on-expand-node.stop>
            <li data-icon="<span class='mif-server pt-1'/>" :data-caption="'Server ( ' + source.exchangeSource.name + ' )' " data-collapsed="true">
                <ul>
                    <li v-for="m in source.exchangeSource.mailboxes.filter(x => x.selected || x.indeterminate)"
                        :data-icon="'<span class=\''+ m.exchangeMailboxType.getMetroIcon(m.name) +' pr-2\'/>'" :data-caption="m.name" v-bind:key="m.id" />
                </ul>
            </li>
        </ul>

        <!------------------SORGENTI - DATABASE--------------------------------->
       
        <ul v-if="source.dbSource" data-role="treeview" data-on-expand-node.stop>
            <li data-icon="<span class='mif-server'></span>" :data-caption="source.dbSource.serverName + '( ' + source.dbSource.serverVersion + ' ) ' " data-collapsed="true">
                <ul>
                    <li data-icon="<span class='mif-database'></span>" :data-caption="db.name" v-for="db in source.dbSource.databases.filter(x => x.selected || x.indeterminate)" :key="db.name" ></li>
                </ul>
            </li>
        </ul>

        <!--------------SORGENTI - FILEFOLDER - NETWORK - MICROSOFT--------------------------->
        <ul v-if="source.folderSource || source.networkSource" data-role="treeview" data-on-expand-node.stop>
             <li data-icon="<span class='mif-folder-open'></span>" :data-caption="$t('Items to Back-up')" data-collapsed="true">
                <ul>
                    <li data-icon="<span class='mif-folder'></span>" :data-caption="res.path" v-for="res in (source.type == $PlatformsTypes.Network ? source.networkSource.paths : source.folderSource.paths)" :key="res.path"></li>
                </ul>
             </li>
        </ul>
    </div>
</template>
<script>
import { isValid, prettyBytes } from "../../../utils/utilitiesmodule";
export default {
    name: "SourceView",
    props: {
        source: {
            type: Object,
            required: true
        }
    },
    methods: {
        /*************************DRIVE IMAGE*******************************************************/
        getPartitionsNames(disk) {
            return disk.partitions
                .filter(p => p.selected)
                .map((p, i) => p.volumeDriveLetter == 'None' ? i : p.volumeDriveLetter)
                .join(" , ");
        },
        createCaptionForDisk(disk, index) {
            var caption;
           caption = "Disk " + (index + 1) + " " + disk.caption;
            return caption;
        },
        createCaptionForPartition(partition) {
            var space;
            if (partition.volumeDriveLetter == 'None')
                space = prettyBytes(partition.volumeCapacity);
            else
                space = prettyBytes(partition.volumeCapacity - partition.volumeFreeSpace) + " / " + partition.volumeCapacity_string
            return partition.visibleLabel + " ( " + space + " )";
        },
        /****************************HYPER-V*******************************************************/
        buildVirtualDiskCaptionHYPERV(vd) {
            var name = vd.name ? vd.name : vd.diskFilename;
            return name + " ( " + vd.size_string + " )";
        },

        /**************************ESXI***********************************************************/
        buildDatastoreCaption(ds) {
            if (this.source.type == $PlatformsTypes.HyperV) {//hyperv
                var name = ds.name;
                return name + " ( " + prettyBytes(ds.capacity - ds.freeSpace) + " / " + ds.capacity_string + " )";
            }

            return ds.name + ' [' + ds.freeSpace_string + '/' + ds.capacity_string + ']';
        },

        buildVirtualMachineCaption(vm) {
            return vm.name + " ( " + vm.os + " )";
        },

        buildVirtualDiskCaptionESXI(virtualDisk) {
            return virtualDisk.diskFilename.split('/')[1] + '[' + virtualDisk.size_string + ']';
        },

        /**********************UTILS***********************************************************/
        isValid(value) {
            return isValid(value);
        }
    }
}
</script>