    import LastResult from "../components/utils/LastResult";
    import Navigation from "../components/utils/Navigation";
    import { formatDate, fromNow, diffDate, prettyBytes, isValid } from '../utils/utilitiesmodule';
    import PCLink from "../components/utils/PCLink"
    import { NO_UPPERCASE_CHARACTERS } from '../utils/messages';
    import BackupView from '../components/dialogs/view/BackupView';
    import PCRemoteLink from '../components/utils/PCRemoteLink.vue';
    import PCLicenseLink from '../components/utils/PCLicenseLink.vue';
    import PCBackupLink from '../components/utils/PCBackupLink.vue';
    import StatsCollector from '../components/stats/StatsCollector.vue'
    import NoDataAvailable from "../components/utils/NoDataAvailable.vue";
    import VueApexCharts from 'vue-apexcharts'
    import $ from 'jquery';
    import { PC_MESSAGES } from '../utils/messages';
    import NestedDoughnutChart from "../components/stats/NestedDoughnutChart.vue";
    //import api from "../router/api";

    export default {
        name: "Dashboard",
        components: {
            "LastResult": LastResult,
            "PCLink": PCLink,
            "PCLicenseLink": PCLicenseLink,
            "PCRemoteLink": PCRemoteLink,
            "PCBackupLink": PCBackupLink,
            StatsCollector,
            NoDataAvailable,
            Navigation,
            BackupView,
            apexchart: VueApexCharts,
            "NestedDoughnutChart":NestedDoughnutChart
        },
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Dashboard',
            // all titles will be injected into this template
            titleTemplate: '%s | Iperius One ',
        },
        data() {
            return {


                options : {
                    legend: {
                        show: true,
                        fontSize: '10px',
                    },
                    series: [
                        {
                            name: this.$t("Slot Acquistati"),
                            data: [3, 5, 2, 20, 20] // total_slots per ogni licenza
                        },
                        {
                            name: this.$t("Slot Usati"),
                            data: [3, 1, 1, 0, 9] // used_slots per ogni licenza
                        }
                    ],
                    chart: { type: 'bar', height: 450 },
                    plotOptions: {
                        bar: {
                            horizontal: true, 
                            columnWidth: '100%', 
                            borderRadius: 4,
                            borderRadiusApplication: 'end', }
                    },
                    theme: {
                        monochrome: {
                            enabled: true,
                            color: '#0e72cf',
                            shadeTo: 'light',
                            shadeIntensity: 1
                        },
                    },
                    xaxis: {
                        categories: []
                    }
                },

                remoteOptions: {
                  
                    legend: {
                        show: false,
                        fontSize: '10px',
                    },
                    dataLabels: {
                        style: {
                            fontSize: '10px',
                            fontFamily: 'Poppins, Arial, sans-serif',
                            fontWeight: 'normal',

                        },
                        enabled: true,
                        textAnchor: 'start',
                        formatter: function (val, opt) {
                            return opt.w.globals.labels[opt.seriesIndex]
                        },
                        offsetX: 0,
                    },
                    theme: {
                        monochrome: {
                            enabled: true,
                            color: '#0e72cf',
                            shadeTo: 'light',
                            shadeIntensity: 1
                        },
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 400
                            },
                            legend: {
                                position: 'top'
                            }
                        }
                    }],
                    series: [{
                        name: 'Count',
                        type: 'area',
                        data: [],
                    }, {
                        name: 'Total Time',
                        type: 'area',
                        data: [],
                    }],
                    chart: {
                        height: 550,
                        toolbar: {
                            show: false,
                        },
                        type: 'area',
                    },
                    grid: {
                        borderColor: '#e7e7e7',
                        row: {
                            colors: ['#d3d3d3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    markers: {
                        size: 1,
                        show:false
                    },
                   
                   
                    colors: ['#d3d3d3', '#d3d3d3'],
                  
                    fill: {
                        type: 'solid',
                        opacity: [0.35, 1],
                    },
                    stroke: {
                        width: 2
                    },
                    labels: [],
                    dataLabels: {
                        enabled: false,
                    },
                    yaxis: [
                        {
                            title: {
                                text: this.$t('Num. Connections'),
                            },
                        },
                        {
                            opposite: true,
                            title: {
                                text: this.$t('Total Time (min)'),
                            },
                        },
                    ],
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            formatter: function (y,opt) {
                                console.log(opt);
                                if (typeof y !== "undefined" && opt.seriesIndex !=0) {
                                    return y.toFixed(0)+" min";
                                }
                                return y;
                            }
                        }
                    }
                },
                remoteOptions2: {

                    legend: {
                        show: false,
                        fontSize: '10px',
                    },
                    dataLabels: {
                        style: {
                            fontSize: '10px',
                            fontFamily: 'Poppins, Arial, sans-serif',
                            fontWeight: 'normal',

                        },
                        enabled: true,
                        textAnchor: 'start',
                        formatter: function (val, opt) {
                            return opt.w.globals.labels[opt.seriesIndex]
                        },
                        offsetX: 0,
                    },
                    theme: {
                        monochrome: {
                            enabled: true,
                            color: '#0e72cf',
                            shadeTo: 'light',
                            shadeIntensity: 1
                        },
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 400
                            },
                            legend: {
                                position: 'right'
                            }
                        }
                    }],
                    series: [{
                        name: 'Count',
                        type: 'area',
                        data: [],
                    }, {
                        name: 'Total Time',
                        type: 'area',
                        data: [],
                    }],
                    chart: {
                        height: 550,
                        toolbar: {
                            show: false,
                        },
                        type: 'area',
                    },
                    grid: {
                        borderColor: '#e7e7e7',
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                  
                    colors: ['#77B6EA', '#545454'],
                    stroke: {
                        width: 2
                    },
                    fill: {
                        type: 'solid',
                        opacity: [0.35, 1],
                    },
                    labels: [],
                    dataLabels: {
                        enabled: false,
                    },
                    markers: {
                        size: 1,
                    },
                    yaxis: [
                        {
                            title: {
                                text: this.$t('Num. Connections'),
                            },
                        },
                        {
                            opposite: true,
                            title: {
                                text: this.$t('Total Time (min)'),
                            },
                        },
                    ],
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            formatter: function (y,opt) {
                                if (typeof y !== "undefined" && opt.seriesIndex != 0) {
                                    return y.toFixed(0) + " min";
                                }
                                return y;
                            }
                        }
                    }
                },
               
                licenseName: "",
                disabledDates: {
                    from: new Date(), // Disable all dates after specific date
                },
                
                //caricati tramite api
                jobs: null,
                loading: false,
                firstline: true,
                lastline: false,
                sessionrow: 0,
                first_dt_start: 0,
                total_session_inarow: "",
                headergroupname: "",
                filters: {
                    backupName: '',
                    numrows: 25,
                    offset: 0,
                    lastresult: '',
                    status: '',
                    dt_inf: '',
                    dt_sup: '',
                    id_group: '',
                    id_pc: '',
                    id_host: '',
                    id_user: '',
                    limit: '-1',
                    activitystatus: '',
                },
                sessions: null,
                computers: null,
                events: null,
                transformedArray: {},
                users: null,
                roles: null,
                types: null,
                filterDate: null,
                groups: null,
                controls: null,
                checkTFA: {
                    step1: null,
                    step2: null
                },
                treeViewHtml: null,
                gridView: false,
                subDirectories: [],
                parentDir: null,
                datacollection: null,
                fullStats: null,
                selectedcomputer: null,
                selecteduser: null,
                selectedgroup: null,
                selectedbackup: null,
                connected: false,
                licenses:[],
                publicIP: 'Caricamento...',
                privateIP: 'Caricamento...',

                newVer: '0.0.0.0',

                checkRemoteConnectionTimer: null,


            }
        },
        beforeDestroy: function() {
            this.$root.$off("socketEventConnected");
            this.$root.$off("socketEventDisconnected");
            this.$root.$off("addBackupsToPage");
            this.$root.$off("is_online");
            this.computers = null; // Pulire i dati in memoria
            this.checkRemoteConnectionTimer = null;
            this.sessions = null;
            this.events = null;
            //this.$root.newPage("dashboard", "destroy");
        },
        filters: {
            prettyBytes: function(bytes, precision) {
                return prettyBytes(bytes, precision);
            },

        },
        mounted: function() {
            this.$session.setRoomPC(null);
            this.$root.socket("SETPC", null);

            //this.$root.newPage("dashboard", "mounted");
            this.licenseName = this.$root.checkAuth().nome_licenza;
            this.licenses = this.$session.getLicenses();
            //popolo i valori dei filtri
            this.headergroupname = "";
            this.sessionrow = 0;
            this.filters.typeList = this.$root.getAllBackupSourceTypes(); //this.$platformsTypesInfo;
            this.filters.resultList = this.$ResultValue;
            this.filters.dt_inf = this.$moment().subtract(5, 'days').format('YYYY-MM-DD')
            this.filters.dt_sup = this.$moment().format('YYYY-MM-DD')

            this.$session.setRoomPC(null);

            this.startRetrieveDataFromAPI();

            /* fetch('https://api64.ipify.org?format=json')
                .then((response) => {

                    console.log(response);
                    return response.json();
                })
                .then((data) => {
                    console.log(data);

                    this.publicIP = data.ip;
                })
                .catch(() => {
                    this.publicIP = 'Errore nel recupero';
                }); */

            this.getPrivateIP()
                .then((ip) => console.log("IP privato:", ip))
                .catch((error) => console.error("Errore:", error));

            //this.$api.getLocalPublicIP().then(data => console.log(data));
            this.getLatestIR4Version();

            //this.checkRemoteConnectionTimerLight(); // chiamata ogni 3 secondi per la lista dei remote collegati

        },

        computed: {
            userEmail() {
                return this.$root.checkAuth().email;
            },
        },
        methods: {

            async getPrivateIP() {
                return new Promise((resolve, reject) => {
                    // Crea una nuova connessione RTC
                    const peerConnection = new RTCPeerConnection();

                    // Crea un canale dati per inizializzare ICE
                    peerConnection.createDataChannel("");

                    // Ascolta i candidati ICE generati
                    peerConnection.onicecandidate = (event) => {
                        if (event && event.candidate) {
                            const candidateString = event.candidate.candidate;

                            // Usa una regex per trovare un indirizzo IP
                            const ipRegex = /(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})/; // IPv4 regex
                            const match = candidateString.match(ipRegex);

                            if (match) {
                                resolve(match[1]); // Restituisce l'IP privato
                                peerConnection.close(); // Chiude la connessione dopo aver trovato l'IP
                            }
                        }
                    };

                    // Genera una descrizione locale per avviare il processo ICE
                    peerConnection.createOffer().then((offer) => {
                        peerConnection.setLocalDescription(offer);
                    }).catch(reject);
                });
            },




            //MA: aggiornamento checkRemoteConnectionTimerAction con questo semplificato
            checkRemoteConnectionTimerLight() {
                let self = this;
                var PCList = self.$session.getPCList();
                // timer per controllo stato ogni 3 secondi
                self.checkRemoteConnectionTimer = setInterval(() => {
                    if (self.computers == null)
                        return;
                    // api che verifica i remote connessi
                    this.$api.getPCRemoteConnected(PCList)
                        .then(response => {
                            var remoteConnected, pcsConnected;
                            //Se c'è un problema del server, questa chiamata restituisce undefined.
                            //metto tutto a false
                            if (response == undefined) {
                                remoteConnected = {};
                                pcsConnected = {};
                                PCList.forEach(pc => {
                                    remoteConnected[pc.id_remote] = false;
                                    pcsConnected[pc.id] = false;
                                });
                            } else {
                                remoteConnected = response[0];
                                pcsConnected = response[1];
                            }

                            // alla fine aggiorno l'oggetto in sessione per far cambiare i valori sulla lista
                            self.$session.setRemoteConnectionStates(remoteConnected);
                            // emetto un command che viene recepito da PCRemoteLink.vue
                            self.$emit("updateConnectionsStates", remoteConnected);

                            Object.keys(pcsConnected).forEach(idPC => {
                                if (self.computers[idPC] != undefined) {
                                    self.computers[idPC].connected_remote = pcsConnected[idPC];
                                }

                            })
                        });

                }, 5000);
            },
            async checkRemoteConnectionTimerLight_old() {
                let self = this;

                /*if (self.checkRemoteConnectionTimer != null || !isValid(self.$session.getPCList())) {
                    return;
                }

                var roomPC;
                self.checkRemoteConnectionTimer = true;*/

                /*self.$api.getPCRemoteConnectedList(pcs).then(res => {
                    self.$root.connectionsStatesRemote = res;
                    self.$session.setRemoteConnectionStates(res);
                    pcs.forEach(computer => {
                        computer.connected_remote = res == [] ? false : res[computer.id_remote];
                    });
                    //Trasformo la lista in una mappa {idPC : pc}
                    self.computers = pcs.reduce((obj, pc) => ({...obj, [pc.id]: pc }), {});
                    self.getJobs();
                });
                this.$root.checkSocket();*/

                if (!isValid(self.$session.getPCList())) {
                    return;
                }

                const devices = self.$session.getPCList();

                /**  trasformo l'array di computer in un oggetto json del tipo
                {
                    "ids_remote": ["123456789", "222333444", "333444555"]
                }*/
                const idsRemote = {
                    ids_remote: devices.map(device => device.id_remote)
                        .filter(id => typeof id === 'string' && /^[0-9]{9}$/.test(id)) //filter(id => id)
                };
                // lo converto in una stringa JSON come richiesta dalla api di Fores
                const jsonString = JSON.stringify(idsRemote);

                // // timer per controllo stato ogni 3 secondi
                // self.checkRemoteConnectionTimer = setInterval(async() => {
                //     // api che verifica i remote connessi
                //     await this.$api.getPCRemoteConnected(jsonString).then(async(remoteConnected, pcsConnected) => {
                //         //Se c'è un problema del server, questa chiamata restituisce undefined.
                //         //metto tutto a false
                //         if (response == undefined) {
                //             var notConnectedMap = {};

                //             idsRemote.ids_remote.forEach(id => {
                //                 notConnectedMap[id] = false;
                //             });

                //             self.$session.setRemoteConnectionStates(notConnectedMap);
                //             // emetto un command che viene recepito da PCRemoteLink.vue
                //             this.$emit("updateConnectionsStates", notConnectedMap);
                //             return;
                //         }

                //         // alla fine aggiorno l'oggetto in sessione per far cambiare i valori sulla lista
                //         self.$session.setRemoteConnectionStates(remoteConnected);
                //         // emetto un command che viene recepito da PCRemoteLink.vue
                //         this.$emit("updateConnectionsStates", remoteConnected);

                //         Object.keys(pcsConnected).forEach(idPC => {
                //             self.devices
                //         })


                //     });

                // }, 5000);

            },


            async getLatestIR4Version() {
                // /api/getversion

                let self = this;


                // [20-11-2024] l'update di un client iperius remote avviene con chiamata alle api come per l'area admin di remote
                // Elixir invierà poi il command di update al client tramite socket
                await this.$api.latestIperiusRemote4Version().then(async response => {
                    if (response == null || response.status != 200) {
                        return;
                    }

                    var major = response.data.major;
                    var minor = response.data.minor;
                    var release = response.data.release;
                    var build = response.data.build;

                    this.newVer = major + '.' + minor + '.' + release + '.' + build;

                    self.$session.setLastRemoteVersion(this.newVer);

                });


            },

            saveNote(session) {

                let self = this;
                const note = {
                    id_session: session.id,
                    note: session['notes'].length == 0 ? session.notedesc : session['notes'][0].note,
                    id: session['notes'].length == 0 ? null : session['notes'][0].id,
                    hide_customer: 0
                }

                self.$api.saveNote(note).then((value) => {
                    if (value) {
                        self.$root.toast(this.$t(PC_MESSAGES.NOTE_SAVED), 5000, "success")
                    }
                });
                /*   if ($note["id"] == "") {
                      $response = $http -> post($this -> endpoint.'/api/notes', [
                          'id_session'=> $note["session_id"],
                          'note'=> $note["note"],
                          'hide_customer'=> 0
                      ]);
                  } else {
                      $response = $http -> put($this -> endpoint.'/api/notes', [
                          'note'=> $note["note"],
                          'id'=> $note["id"]
                      ]);
                  } */
            },

         


            /********************************STATS*****************************************************/

            //Si effettuano le chiamate API per richiedere i dati più utilizzati e si inseriscono in sessione. 
            //In questo modo verranno presi in maniera più veloce
            //Per praticità, tutte le chiavi di session sono in uppercase
            startRetrieveDataFromAPI() {

                let self = this;

                this.$root.$on("DeletedJob", idBackup => {
                    var index = self.jobs.findIndex(j => j.id == idBackup);
                    self.jobs.splice(index, 1);
                });


                //Solo dopo aver recuperato i computers, avvio la connessione socket e recupero i jobs
                this.$api.getPCList().then(pcs => {
                    self.$session.setPCList(pcs);

                    if (pcs == null) {
                        //Se la chiamata fosse andata a buon fine, computers sarebbe stato una mappa idPC : pc 
                        self.computers = {};
                        return;
                    }

                    self.computers = pcs.reduce((obj, pc) => ({...obj, [pc.id]: pc }), {});
                    self.checkRemoteConnectionTimerLight();
                    self.getJobs();

                    //serve per fixare la prima apertura della scheda laterale.
                    //self.selectedcomputer = pcs[0];
                    //###DA INSERIRE IN OGNI COMPONENTE CHE NECESSITA DI CHIAMATE SOCKET###
                    // "checkSocket" avvia la connessione socket se ferma
                    // "socketEventConnected" viene emesso quando la connessione socket è attiva, quindi nella sua callback si possono avviare tutte le istruzioni che riguardano le chiamate socket
                    // "socketEventDisconnected" viene emesso quando la connessione socket non è attiva
                    self.$root.$on("socketEventConnected", () => {
                        self.$root.socketConnected = true;
                        self.$root.checkConnectionTimerLight();
                    });
                    self.$root.checkSocket();



                    //passo la lista dei computer e vedo quali sono connessi attraverso IPERIUS REMOTE, cioè collegati come host
                    // self.$api.getPCRemoteConnected(pcs).then(map => {
                    //     self.$root.connectionsStatesRemote = map;
                    //     self.$session.setRemoteConnectionStates(map); // viene letta da PCRemoteLink.vue solo la prima volta poi viene letto con timer da socketapi di Remote con il metodo checkRemoteConnectionTimerLight()
                    //     pcs.forEach(computer => {
                    //         computer.connected_remote = map[computer.id_remote];
                    //     });
                    //     //Trasformo la lista in una mappa {idPC : pc}
                    //     self.getJobs();
                    // });
                    //this.$root.checkSocket();



                });

                this.$api.getUsers().then(res => {
                    self.users = res;
                    self.$session.setUsers(res);
                });

                this.$api.getGroups().then(res => {
                    self.groups = res;
                    self.$session.setGroups(res);
                });
                this.$api.getPolicies().then(res => {
                    self.$session.setPolicies(res);
                });
                //ho fatto la pagina apposta
                /* this.$api.getControls().then(res => {
                    self.controls = res.controls;
                    self.$session.setControls(res.controls);
                }); */

                this.$api.getSessionsLight().then(res => {
                    self.sessions = res;
                });

                this.$api.getRoles().then(res => {
                    self.roles = res;
                    self.$store.commit('SET_ROLES', res);
                    self.$session.setRoles(res);
                });

                this.$api.getPolicyTypes().then(res => {
                    self.policyTypes = res;
                    self.$store.commit('SET_POLICY_TYPES', res);
                    self.$session.setPolicyTypes(res);
                });

                this.$api.getTypes().then(res => {
                    self.types = res;
                    self.$store.commit('SET_TYPES', res);
                    self.$session.setTypes(res);
                });

                this.$api.getServiceAccounts().then(serviceAccounts => {
                    self.$session.setServiceAccounts(serviceAccounts);
                });

                this.$api.getRepositories().then(repositories => {
                    self.$session.setRepositories(repositories);
                });


                this.$api.getStatsFromLicenseLight(null).then(stats => {
                    self.fullStats = stats;

                    self.remoteOptions.series[0].data = Object.values(self.fullStats?.remote_stats.stats_per_date).map(
                        (value) => { return value.count }
                    )
                    self.remoteOptions.series[1].data = Object.values(self.fullStats?.remote_stats.stats_per_date).map(
                        (value) => { return Math.round((value.total_time) / 60) }
                    )
                    self.remoteOptions.labels = Object.keys(self.fullStats?.remote_stats.stats_per_date).map(
                        (value) => { return this.$moment(value).format('DD/M') }
                    )


                    self.remoteOptions2.series[0].data = Object.values(self.fullStats?.remote_stats.stats_per_user).map(
                        (value) => { return value.count }
                    )
                    self.remoteOptions2.series[1].data = Object.values(self.fullStats?.remote_stats.stats_per_user).map(
                        (value) => { return Math.round((value.total_time)/60) }
                    )
                    self.remoteOptions2.labels = Object.keys(self.fullStats?.remote_stats.stats_per_user).map(
                        (value) => { return ((value.split('|')[1]).split(' ')[1]) }
                    )
                    self.options.series[0].data = Object.values(self.licenses.backup).map(
                        (value) => { return value.total_slots }
                    )
                    self.options.series[1].data = Object.values(self.licenses.backup).map(
                        (value) => { return value.used_slots }
                    )
                    self.options.xaxis.categories = Object.values(self.licenses.backup).map(
                        (value) => { return value.nome_licenza }
                    )
                   
                   
                   
                });

                this.$api.getEvents().then(events => {
                    self.events = events;
                });


                //Questa chiamata si effettua automaticamente al created di navLayout
                /*this.$api.getLicenseExtendedInfo(this.$session.getLicense().id).then(authObj => {
                    this.$session.setLicenses(authObj);
                });*/


            },


            /*****************************BACKUPS*************************************************/



            async getJobs() {
                let self = this;
                if (self.jobs != null && self.jobs.length > parseInt(this.filters.numrows)) {
                    return;
                }
                self.jobs = await this.$api.getBackups(null, self.filters.lastresult == '' ? 99 : self.filters.lastresult, self.filters.numrows, self.filters.offset, 1, self.filters.backupName);
            },



            /*  applyFiltersToJobs() {
                let self = this;
                var list = self.jobs;
                if (this.filters.numrows.toString() == "25" && list.length > 25)
                    list = list.slice(0, 25);

                if (this.filters.lastresult == 'idle') {
                    list = list.filter(j => {
                        if (isValid(j.scheduling) && j.scheduling[0] != '') {
                            var interval = self.cronParser.parseExpression(j.scheduling[0], { currentDate: new Date(), iterator: true });
                            console.log(interval.next().value.toString());
                            console.log();
                            var prevIteration = new Date(interval.prev().value.toString());
                            return new Date(j.dt_start_utc) < prevIteration;
                        }
                        return false;
                    });
                }

                if (this.filters.lastresult != 'idle' && this.filters.lastresult != '')
                    list = list.filter(job => job.last_result.toString() == this.filters.lastresult);

                return list;
            },
 */
            //metodo per controllare se devo aggiungere o meno la riga iniziale...
            shouldBeAddedBefore(session) {
                if (this.headergroupname == "" || session.name_computer_control != this.headergroupname) {
                    this.headergroupname = session.name_computer_control;
                    this.sessionrow = 1;
                    this.total_session_inarow = session.total_time;
                    return true;
                }
                if (session.name_computer_control == this.headergroupname) {
                    this.total_session_inarow += session.total_time;
                    this.sessionrow += 1;
                    return false;
                }

            },
            shouldBeAddedAfter(session) {

                if (this.headergroupname != "" && session.name_computer_control != this.headergroupname) {

                    return true;
                }
                return false;

            },

            openComputerView(computer) {
                this.$api.getPC(computer.id).then(computer => {
                    this.selectedcomputer = computer;
                    window.Metro.charms.toggle("#computerselected");
                });
            },

            closeCharm() {
                window.Metro.charms.close('#computerselected');
            },

            async openBackupView(idBackup, idPC) {

                //Si vuole aprire la view del job con l'id passato.
                //Il job mostrato sarà un insieme di informazioni prese sia dalla chiamata API, sia dal job arrivato in realtime tramite socket.
                //Per questo secondo tipo di job è necessario che il computer collegato al job sia connesso.
                //Se il computer è connesso, si chiede all'agent l'invio dei backups
                let self = this;
                if (this.$root.connectionsStates[idPC]) {
                    //le chiamate stop e unsubscribe verranno effettuate all'interno di backupview
                    self.$root.socket("subscribe_pc", idPC)
                        .then(() => self.$root.socket("STARTSENDINFO"));


                    //TODO: VERONICA: non sarebbe meglio metterlo direttamente sulla maschera che si apre in modo da semplificare?
                    //backups_received è una mappa in cui le chiavi sono gli id dei job e i valori sono i job
                    this.$root.$on("addBackupsToPage", backups_received => {
                        self.$root.$emit("sendRealtimeJob", backups_received[idBackup]);
                    });

                }
                //this.selectedbackup = this.jobs[index];
                this.$root.$emit("OPENBACKUPVIEW", idBackup);
                //window.Metro.charms.toggle("#backupselected");
            },
            /*****************************USERS*************************************************/
            async deleteUser(user) {
                var dialogID = Math.floor(100000 + Math.random() * 900000).toString();
                let self = this;
                this.$root.$on(dialogID, async ok => {

                    if (ok) {
                        self.$root.$off(dialogID);
                        return;
                    }

                    ok = await self.$api.deleteUser(user.id, user.username);

                    if (ok) {
                        self.users.splice(self.selectedIndex, 1);
                        self.closeDialog();
                    }
                    self.$root.$off(dialogID);
                });

                this.$root.openYesNoDialog(dialogID, this.$t("Delete user"), this.$t("Are you sure to delete this user?"));
            },


            async saveUser() {
                let self = this;
                this.selecteduser.directories = [];
                $('#treeview input[type=checkbox]:checked').each(function() {
                    this.selecteduser.directories.push($(this).val().toString());
                });


                if (!this.selecteduser.password.split("").some(c => 65 <= c.charCodeAt(0) && c.charCodeAt(0) <= 90)) {
                    this.$root.toast(this.$t(NO_UPPERCASE_CHARACTERS), 5000, "alert");
                    return;
                }

                var user = this.selecteduser.id == null ?
                    await this.$api.createUser(this.selecteduser) :
                    await this.$api.editUser(this.selecteduser);
                var ok = user != null;

                if (ok) {
                    self.closeDialog();
                    if (isNew) {
                        self.users.push(user);
                    } else {
                        self.users[self.selectedIndex] = user;
                    }
                }
            },



            openDialogUser(user, index) {

                this.selectedIndex = index;
                if (user == null) {
                    this.selecteduser = {};
                    this.selecteduser.id = null;
                    //this.selecteduser.directories = [];
                    this.selecteduser.email = null;
                    this.selecteduser.username = null;
                    this.selecteduser.firstname = null;
                    this.selecteduser.lastname = null;
                    this.selecteduser.password = "";
                    this.selecteduser.role = "User";
                } else {
                    this.selecteduser = JSON.parse(JSON.stringify(user));
                    this.$root.$emit("OPENUSERVIEWDIALOG", this.selecteduser, self.checkTFA);
                }
                //this.createTreeView(this.selecteduser);
            },




            /*****************************UTILS*************************************************/

            closeDialog() {
                this.selecteduser = null;
            },
            /*
            createTree(array_nodes, directoryChecked) {
                var nodi = "";
                //v-model="selecteduser.directories" 
                if (array_nodes == "") return nodi;
                array_nodes.forEach(node => {
                    nodi += '<li > <input type="checkbox" data-role="checkbox" value="' + node.id + '" ' + (directoryChecked.includes(node.id) ? 'checked' : '') + ' data-caption="' + node.name + '" ><ul>' + this.createTree(node.subDirectories, directoryChecked) + '</ul></li>'
                });
                return nodi;
            },

            createTreeView(userSelected) {
                let self = this;

                self.treeViewHtml = "";
                self.treeViewHtml += '<ul id="treeview" data-role="treeview"  >' + self.createTree([self.treeView], userSelected.groups) + '</ul>';
            },



            
            ?????????????????????????????????????????????????????????
            getDirectory(dir, direction = "down") {
                let self = this;

                api
                    .get('/directories/' + self.idLicense + "?d=" + (direction == "down" ? dir.id : dir.parent), {
                        headers: {
                            Authorization: 'Bearer ' + self.$root.checkAuth().access_token
                        }
                    })
                    .then(response => {
                        self.computers = response.data.computers;
                        self.subDirectories = response.data.subDirectories;
                        self.parentDir = response.data.parent;
                        self.dir = response.data
                    })
            },*/

            buildHintText(computer) {
                var text = "";
                if (computer.disk_alert == 1)
                    text += "Some disk alert\n";
               
                if (computer.av_alert == 1)
                    text += "Some antivirus alert\n";
                return text;
            },

            formatDate(date) {
                return formatDate(date, this.$moment);
            },
            fromNow(date) {
                if (date == null || date == "") return ""
                return fromNow(date, this.$moment);
            },
            diffDate(datestart, dateend) {
                return diffDate(datestart, dateend, this.$moment);
            },


        }
    };