<template>
    <div class="w-100 overflow ">
        <Navigation pageName="schedulation"  />
        

       

        <div class="p-10">

            <h3 class="p-2">{{ $t("Create Scheduling") }}</h3>
            <section class="overflow w-100">
                          
                            <div class="page-content p-4">

                                <div class="row">

                                    <div class=" w-100">
                                        <ul data-role="tabs" data-tabs-type="" data-expand="true" data-cls-tabs="bg-transparent" style="z-index: 1;">
                                            <li>
                                                <a href="#minutes" @click="toggleTab(cronOptions.minutes)">{{$t("Minutes") }}</a>
                                            </li>
                                            <li>
                                                <a href="#hourly" @click="toggleTab(cronOptions.hourly)">{{ $t("Hourly")}}</a>
                                            </li>
                                            <li>
                                                <a href="#daily" @click="toggleTab(cronOptions.daily)">{{ $t("Daily") }}</a>
                                            </li>
                                            <li>
                                                <a href="#weekly" @click="toggleTab(cronOptions.weekly)">{{ $t("Weekly")}}</a>
                                            </li>
                                            <li>
                                                <a href="#montly" @click="toggleTab(cronOptions.monthly)">{{$t("Monthly") }}</a>
                                            </li>
                                            <li>
                                                <a href="#yearly" @click="toggleTab(cronOptions.yearly)">{{ $t("Yearly")}} </a>
                                            </li>
                                        </ul>
                                        <div class="border bd-default no-border-top p-4  panel">
                                            <div id="minutes" class="p-4">
                                                <div class="form-row align-items-center">
                                                    <div class="col-lg-1">
                                                        <label class="col-form-label inline">{{ $t("Every") }}</label>
                                                        <select v-model="cronOptions.minutes.value"
                                                            class="form-control select w-auto" style="display: inline;">
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="10">10</option>
                                                            <option value="15">15</option>
                                                            <option value="20">20</option>
                                                            <option value="30">30</option>
                                                        </select>
                                                        <label class="col-form-label">{{ $t("minute(s)") }}</label>
                                                    </div>


                                                </div>
                                            </div>
                                            <div id="hourly" class="p-4">
                                                <div class="card-body" id="idb">

                                                    <div class="form-inline ">
                                                        <label class="form-check-label">
                                                            <input data-role="radio" class="form-check-input mb-1"
                                                                type="radio" v-model="cronOptions.hourly.radio"
                                                                name="pnlDetails:pnlHourly:radioGroup" value="every/">
                                                            {{ $t("Every") }}
                                                            <select style="display: inline;"
                                                                v-model="cronOptions.hourly.value"
                                                                class="form-control ml-1 mr-1 select w-auto"
                                                                name="pnlDetails:pnlHourly:radioGroup:ddHours">
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="6">6</option>
                                                                <option value="12">12</option>
                                                            </select> {{ $t("hour(s)") }}</label>
                                                    </div>

                                                    <div class="form-inline mt-3">
                                                        <label class="form-check-label">
                                                            <input data-role="radio" class="form-check-input mb-1"
                                                                v-model="cronOptions.hourly.radio" type="radio"
                                                                name="pnlDetails:pnlHourly:radioGroup" value="at?">
                                                            {{ $t("Starts at") }}


                                                            <div class="form-inline ">
                                                                <select style="display: inline;"
                                                                    v-model="cronOptions.hourly.at.hour"
                                                                    class="form-control mr-1 ml-1 select w-auto"
                                                                    name="pnlDetails:pnlHourly:radioGroup:pnlTime:hourPart">
                                                                    <option v-for="n in 24" :value="n - 1"
                                                                        v-bind:key="n - 1">
                                                                        {{ (n - 1).toString().padStart(2, "0") }}
                                                                    </option>
                                                                </select>
                                                                :
                                                                <select style="display: inline;"
                                                                    v-model="cronOptions.hourly.at.minute"
                                                                    class="form-control ml-1 select w-auto"
                                                                    name="pnlDetails:pnlHourly:radioGroup:pnlTime:minutePart">
                                                                    <option v-for="n in 60" :value="n - 1"
                                                                        v-bind:key="n - 1">
                                                                        {{ (n - 1).toString().padStart(2, "0") }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="daily">
                                                <div class="card-body" id="idb">
                                                    <div class="form-inline ">
                                                        <label class="form-check-label">
                                                            <input data-role="radio" v-model="cronOptions.daily.radio"
                                                                class="form-check-input mb-1 " type="radio"
                                                                name="pnlDetails:pnlDaily:radioGroup" value="everyDay?"
                                                                checked="checked">
                                                            {{ $t("Everyday") }}</label>
                                                    </div>

                                                    <div class="form-inline mt-3">
                                                        <label class="form-check-input">
                                                            <input data-role="radio" v-model="cronOptions.daily.radio"
                                                                class="form-check-input mb-1" type="radio"
                                                                name="pnlDetails:pnlDaily:radioGroup" value="weekdays?">
                                                            {{ $t("Every weekday") }}</label>
                                                    </div>


                                                    <div class="form-inline mt-4">
                                                        {{ $t("Starts at") }} :

                                                        <div class="form-inline ">
                                                            <select style="display: inline;"
                                                                v-model="cronOptions.daily.at.hour"
                                                                class="form-control mr-1 ml-1 select w-auto"
                                                                name="pnlDetails:pnlDaily:pnlTime:hourPart">
                                                                <option v-for="n in 24" :value="n - 1"
                                                                    v-bind:key="n - 1">
                                                                    {{ (n - 1).toString().padStart(2, "0") }}
                                                                </option>
                                                            </select>
                                                            :
                                                            <select style="display: inline;"
                                                                v-model="cronOptions.daily.at.minute"
                                                                class="form-control ml-1 select w-auto"
                                                                name="pnlDetails:pnlDaily:pnlTime:minutePart">
                                                                <option v-for="n in 60" :value="n - 1"
                                                                    v-bind:key="n - 1">
                                                                    {{ (n - 1).toString().padStart(2, "0") }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="weekly">
                                                <div class="card-body" id="idb">

                                                    <div class="row">
                                                        <div class="col-3">
                                                            <div class="form-inline ">
                                                                <label class="form-check-label">
                                                                    <input data-role="checkbox"
                                                                        :data-caption="$t('Monday')"
                                                                        v-model="cronOptions.weekly.days"
                                                                        type="checkbox" class="form-check-input"
                                                                        name="pnlDetails:pnlWeekly:pnlDaysSelected"
                                                                        value="Mon">

                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div class="col-3">
                                                            <div class="form-inline ">
                                                                <label class="form-check-label">
                                                                    <input data-role="checkbox"
                                                                        :data-caption="$t('Tuesday')"
                                                                        v-model="cronOptions.weekly.days"
                                                                        type="checkbox" class="form-check-input ml-4"
                                                                        name="pnlDetails:pnlWeekly:pnlDaysSelected"
                                                                        value="Tue">

                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-inline ">
                                                                <label class="form-check-label">
                                                                    <input data-role="checkbox"
                                                                        :data-caption="$t('Wednesday')"
                                                                        v-model="cronOptions.weekly.days"
                                                                        type="checkbox" class="form-check-input ml-4"
                                                                        name="pnlDetails:pnlWeekly:pnlDaysSelected"
                                                                        value="Wed">

                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col-auto">
                                                            <div class="form-inline ">
                                                                <label class="form-check-label">
                                                                    <input data-role="checkbox"
                                                                        :data-caption="$t('Thursday')"
                                                                        v-model="cronOptions.weekly.days"
                                                                        type="checkbox" class="form-check-input ml-4"
                                                                        name="pnlDetails:pnlWeekly:pnlDaysSelected"
                                                                        value="Thu">

                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-2">
                                                        <div class="col-3">
                                                            <div class="form-inline ">
                                                                <label class="form-check-label">
                                                                    <input data-role="checkbox"
                                                                        :data-caption="$t('Friday')"
                                                                        v-model="cronOptions.weekly.days"
                                                                        type="checkbox" class="form-check-input"
                                                                        name="pnlDetails:pnlWeekly:pnlDaysSelected"
                                                                        value="Fri">

                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div class="col-3">
                                                            <div class="form-inline ">
                                                                <label class="form-check-label">
                                                                    <input data-role="checkbox"
                                                                        :data-caption="$t('Saturday')"
                                                                        v-model="cronOptions.weekly.days"
                                                                        type="checkbox" class="form-check-input ml-4"
                                                                        name="pnlDetails:pnlWeekly:pnlDaysSelected"
                                                                        value="Sat">

                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <div class="form-inline ">
                                                                <label class="form-check-label">
                                                                    <input data-role="checkbox"
                                                                        :data-caption="$t('Sunday')"
                                                                        v-model="cronOptions.weekly.days"
                                                                        type="checkbox" class="form-check-input ml-4"
                                                                        name="pnlDetails:pnlWeekly:pnlDaysSelected"
                                                                        value="Sun">

                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="form-inline mt-2">
                                                        {{ $t("Starts at") }} :

                                                        <div class="form-inline ">
                                                            <select style="display: inline;"
                                                                v-model="cronOptions.weekly.at.hour"
                                                                class="form-control mr-1 ml-1 select w-auto inline"
                                                                name="pnlDetails:pnlWeekly:pnlTime:hourPart">
                                                                <option v-for="n in 24" :value="n - 1"
                                                                    v-bind:key="n - 1">
                                                                    {{ (n - 1).toString().padStart(2, "0") }}
                                                                </option>
                                                            </select>
                                                            :
                                                            <select style="display: inline;"
                                                                v-model="cronOptions.weekly.at.minute"
                                                                class="form-control ml-1 select w-auto"
                                                                name="pnlDetails:pnlWeekly:pnlTime:minutePart">
                                                                <option v-for="n in 60" :value="n - 1"
                                                                    v-bind:key="n - 1">
                                                                    {{ (n - 1).toString().padStart(2, "0") }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="montly">
                                                <div class="card-body" id="idb">
                                                    <div class="form-inline">
                                                        <label class="form-check-label">
                                                            <input data-role="radio" v-model="cronOptions.monthly.radio"
                                                                class="form-check-input mb-1" type="radio"
                                                                name="pnlDetails:pnlMonthly:radioGroup" value="fixed"
                                                                checked="checked">
                                                            {{ $t("Day") }}
                                                            <input style="display: inline;"
                                                                v-model="cronOptions.monthly.day" type="text"
                                                                class="form-control ml-1 mr-1 input w-auto text-center"
                                                                size="2" name="pnlDetails:pnlMonthly:radioGroup:txtDay">
                                                            {{ $t("of every") }}
                                                            <select style="display: inline;"
                                                                v-model="cronOptions.monthly.ofEvery"
                                                                class="form-control ml-1 mr-1 select w-auto"
                                                                name="pnlDetails:pnlMonthly:radioGroup:ddMonth">
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="6">6</option>
                                                            </select>
                                                            {{ $t("month(s)") }}</label>
                                                    </div>
                                                    <div class="form-inline mt-3">
                                                        <label class="form-check-label">
                                                            <input data-role="radio" v-model="cronOptions.monthly.radio"
                                                                class="form-check-input mb-1" type="radio"
                                                                name="pnlDetails:pnlMonthly:radioGroup"
                                                                value="recurrent">
                                                            {{ $t("The") }}
                                                            <select style="display: inline;"
                                                                v-model="cronOptions.monthly.ddRank"
                                                                class="form-control ml-1 mr-1 select w-auto"
                                                                name="pnlDetails:pnlMonthly:radioGroup:ddRank">
                                                                <option value="first">{{ $t("First") }}</option>
                                                                <option value="second">{{ $t("Second") }}</option>
                                                                <option value="third">{{ $t("Third") }}</option>
                                                                <option value="fourth">{{ $t("Fourth") }}</option>
                                                            </select>
                                                            <select style="display: inline;"
                                                                v-model="cronOptions.monthly.ddDay"
                                                                class="form-control  mr-1 select w-auto"
                                                                name="pnlDetails:pnlMonthly:radioGroup:ddDay">
                                                                <option value="mon">{{ $t("Monday") }}</option>
                                                                <option value="tue">{{ $t("Tuesday") }}</option>
                                                                <option value="wed">{{ $t("Wednesday") }}</option>
                                                                <option value="thu">{{ $t("Thursday") }}</option>
                                                                <option value="fri">{{ $t("Friday") }}</option>
                                                                <option value="sat">{{ $t("Saturday") }}</option>
                                                                <option value="sun">{{ $t("Sunday") }}</option>
                                                            </select>
                                                            {{ $t("of every") }}
                                                            <select style="display: inline;"
                                                                v-model="cronOptions.monthly.ddMonth"
                                                                class="form-control ml-1 mr-1 select w-auto"
                                                                name="pnlDetails:pnlMonthly:radioGroup:ddMonth">
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="6">6</option>
                                                            </select>
                                                            {{ $t("month(s)") }}</label>

                                                    </div>

                                                    <div class="form-inline mt-2">
                                                        {{ $t("Starts at") }}:

                                                        <div class="form-inline ">
                                                            <select style="display: inline;"
                                                                v-model="cronOptions.monthly.at.hour"
                                                                class="form-control mr-1 ml-1 select w-auto"
                                                                name="pnlDetails:pnlMonthly:pnlTime:hourPart">
                                                                <option v-for="n in 24" :value="n - 1"
                                                                    v-bind:key="n - 1">
                                                                    {{ (n - 1).toString().padStart(2, "0") }}
                                                                </option>
                                                            </select>
                                                            :
                                                            <select style="display: inline;"
                                                                v-model="cronOptions.monthly.at.minute"
                                                                class="form-control ml-1 select w-auto"
                                                                name="pnlDetails:pnlMonthly:pnlTime:minutePart">
                                                                <option v-for="n in 60" :value="n - 1"
                                                                    v-bind:key="n - 1">
                                                                    {{ (n - 1).toString().padStart(2, "0") }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="yearly">
                                                <div class="card-body" id="idb">


                                                    <div class="form-inline ">
                                                        <label class="form-check-label mr-1">
                                                            <input data-role="radio" :data-caption="$t('Every')"
                                                                v-model="cronOptions.yearly.radio"
                                                                class="form-check-input mb-1" type="radio"
                                                                name="pnlDetails:pnlYearly:radioGroup" value="fixed"
                                                                checked="checked">
                                                        </label>
                                                        <select style="display: inline;"
                                                            v-model="cronOptions.yearly.ofEvery"
                                                            class="form-control select w-auto"
                                                            name="pnlDetails:pnlYearly:radioGroup:ddMonth">
                                                            <option value="1">{{ $t("January") }}</option>
                                                            <option value="2">{{ $t("February") }}</option>
                                                            <option value="3">{{ $t("March") }}</option>
                                                            <option value="4">{{ $t("April") }}</option>
                                                            <option value="5">{{ $t("May") }}</option>
                                                            <option value="6">{{ $t("June") }}</option>
                                                            <option value="7">{{ $t("July") }}</option>
                                                            <option value="8">{{ $t("August") }}</option>
                                                            <option value="9">{{ $t("September") }}</option>
                                                            <option value="10">{{ $t("October") }}</option>
                                                            <option value="11">{{ $t("November") }}</option>
                                                            <option value="12">{{ $t("December") }}</option>
                                                        </select>
                                                        <input style="display: inline-grid;"
                                                            v-model="cronOptions.yearly.day" type="text"
                                                            class="form-control ml-1 mr-1 input w-auto text-center"
                                                            size="2" name="pnlDetails:pnlYearly:radioGroup:txtDay">

                                                    </div>

                                                    <div class="form-inline mt-3">
                                                        <label class="form-check-label">
                                                            <input data-role="radio" :data-caption="$t('The')"
                                                                v-model="cronOptions.yearly.radio"
                                                                class="form-check-input mb-1" type="radio"
                                                                name="pnlDetails:pnlYearly:radioGroup"
                                                                value="recurrent">

                                                            <select style="display: inline;"
                                                                v-model="cronOptions.yearly.ddRank"
                                                                class="form-control ml-1 select w-auto"
                                                                name="pnlDetails:pnlYearly:radioGroup:ddRank">
                                                                <option value="first">{{ $t("First") }}</option>
                                                                <option value="second">{{ $t("Second") }}</option>
                                                                <option value="third">{{ $t("Third") }}</option>
                                                                <option value="fourth">{{ $t("Fourth") }}</option>
                                                            </select>
                                                            <select style="display: inline;"
                                                                v-model="cronOptions.yearly.ddDay"
                                                                class="form-control ml-1 mr-1 select w-auto"
                                                                name="pnlDetails:pnlYearly:radioGroup:ddDay">
                                                                <option value="mon">{{ $t("Monday") }}</option>
                                                                <option value="tue">{{ $t("Tuesday") }}</option>
                                                                <option value="wed">{{ $t("Wednesday") }}</option>
                                                                <option value="thu">{{ $t("Thursday") }}</option>
                                                                <option value="fri">{{ $t("Friday") }}</option>
                                                                <option value="sat">{{ $t("Saturday") }}</option>
                                                                <option value="sun">{{ $t("Sunday") }}</option>
                                                            </select>
                                                            {{ $t("of") }}
                                                        </label>
                                                        <select style="display: inline;"
                                                            v-model="cronOptions.yearly.ddMonth"
                                                            class="form-control ml-1 select w-auto"
                                                            name="pnlDetails:pnlYearly:radioGroup:ddMonth">
                                                            <option value="1">{{ $t("January") }}</option>
                                                            <option value="2">{{ $t("February") }}</option>
                                                            <option value="3">{{ $t("March") }}</option>
                                                            <option value="4">{{ $t("April") }}</option>
                                                            <option value="5">{{ $t("May") }}</option>
                                                            <option value="6">{{ $t("June") }}</option>
                                                            <option value="7">{{ $t("July") }}</option>
                                                            <option value="8">{{ $t("August") }}</option>
                                                            <option value="9">{{ $t("September") }}</option>
                                                            <option value="10">{{ $t("October") }}</option>
                                                            <option value="11">{{ $t("November") }}</option>
                                                            <option value="12">{{ $t("December") }}</option>
                                                        </select>

                                                    </div>


                                                    <div class="form-inline mt-2">
                                                        {{ $t("Starts at") }} :

                                                        <div class="form-inline ">
                                                            <select style="display: inline;"
                                                                v-model="cronOptions.yearly.at.hour"
                                                                class="form-control mr-1 ml-1 select w-auto"
                                                                name="pnlDetails:pnlYearly:pnlTime:hourPart">
                                                                <option v-for="n in 24" :value="n - 1"
                                                                    v-bind:key="n - 1">
                                                                    {{ (n - 1).toString().padStart(2, "0") }}
                                                                </option>
                                                            </select>
                                                            :
                                                            <select style="display: inline;"
                                                                v-model="cronOptions.yearly.at.minute"
                                                                class="form-control ml-1 select w-auto"
                                                                name="pnlDetails:pnlYearly:pnlTime:minutePart">
                                                                <option v-for="n in 60" :value="n - 1"
                                                                    v-bind:key="n - 1">
                                                                    {{ (n - 1).toString().padStart(2, "0") }}
                                                                </option>
                                                            </select>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row p-2 mb-9">

                                    <button class="button mr-3" @click="refreshSched()">{{ $t("Save Scheduling")
                                        }}</button>

                                </div>
                            </div>
                            <div style="">

                                <ul class="listview">
                                    <li v-for="(sched, index) in scheduling.filter(s => s != '')" v-bind:key="index" class="bg-dark fg-white m-4 p-4 rounded">
                                        <span class=" mif-alarm "></span>
                                        {{ cronstrue.toString(sched, { locale:$i18n.locale, dayOfWeekStartIndexZero: false}) }}
                                        <!--  <span class="second-label">Next :{{cronParser.parseExpression('*/2 * * * *').next().toString()}} </span> -->
                                        <a @click="removeSched(index)" class="second-action mif-bin "></a>
                                    </li>

                                </ul>

                            </div>
                        </section>
        </div>


    </div>
</template>
<script>

import Navigation from './utils/Navigation';

export default {
    name: "Schedulation",
    props: {
        idBackup: String
    },
    components: {
        Navigation
    },
    data() {
        return {
            scheduling:[],
             //Scheduling
            cronstrue: null,
            cronParser: null,
            cronOptions: {
                minutes: {
                    istab: "minutes/",
                    active: true,
                    value: 3, //il valore sono i minuti di intervallo
                },
                hourly: {
                    istab: "hourly/",
                    active: false,
                    value: 1,
                    radio: "every/", // every/ o at? 
                    at: {
                        hour: 12,
                        minute: 0,
                    }
                },
                daily: {
                    istab: "daily/",
                    active: false,
                    radio: "everyDay?", // everyDay? o weekdays?
                    at: {
                        hour: 12,
                        minute: 0,
                    }
                },
                weekly: {
                    istab: "weekly?",
                    active: false,
                    days: [],
                    at: {
                        hour: 12,
                        minute: 0,
                    }
                },
                monthly: {
                    istab: "monthly/",
                    active: false,
                    radio: "fixed", // fixed o recurrent
                    day: 1,
                    ofEvery: 1,
                    ddRank: "first",
                    ddDay: "mon",
                    ddMonth: 1,
                    at: {
                        hour: 12,
                        minute: 0,
                    }
                },
                yearly: {
                    istab: "yearly/",
                    active: false,
                    radio: "fixed",
                    day: 1,
                    ofEvery: 1,
                    ddRank: "first",
                    ddDay: "mon",
                    ddMonth: 1,
                    // es: /first/tue/ofEvery/3
                    at: {
                        hour: 12,
                        minute: 0,
                    }
                },
            },
        }
    },

    mounted(){
         self.cronstrue = require('cronstrue/i18n');
        self.cronParser = require('cron-parser');
    }
}
</script>