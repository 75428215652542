var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.policy.refreshID != 0
    ? _c(
        "div",
        {
          staticClass:
            "dialog light w-100-sm w-100-md w-75-lg w-50-xl h-75 draggable-item",
          attrs: { id: "policy_dialog" },
        },
        [
          _c("div", { staticClass: "dialog-title pl-10 pl-10" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.policy.id == null
                    ? _vm.$t("Create New Policy")
                    : _vm.policy.name
                ) +
                " "
            ),
            _vm.policy.id != null
              ? _c("label", { staticClass: "text-bold" }, [
                  _c("br"),
                  _vm._v(_vm._s(_vm.$t(_vm.policy.name_type))),
                ])
              : _vm._e(),
            _c("br"),
            _c("label", { staticClass: "text-muted text-small" }, [
              _vm._v(
                _vm._s(
                  _vm.$session
                    .getPolicyTypes("POLICY_TYPES")
                    .find((type) => type.id == _vm.policy.id_type).description
                )
              ),
            ]),
            _c("span", {
              staticClass: "button square closer",
              on: {
                click: function ($event) {
                  return _vm.$root.$emit("CLOSEPOLICYVIEWDIALOG")
                },
              },
            }),
          ]),
          _c("div", { staticClass: "dialog-content overflow bg-light" }, [
            _c("div", { attrs: { id: "profile" } }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("Name")) + "*")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.policy.name,
                      expression: "policy.name",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "input",
                  attrs: {
                    "data-role": "input",
                    required: "",
                    type: "text",
                    "data-prepend": "<span class='mif-folder'></span>",
                  },
                  domProps: { value: _vm.policy.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.policy, "name", $event.target.value.trim())
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(_vm.$t("Required"))),
                ]),
              ]),
              _c("div", { staticClass: "d-flex flex-align-center row" }, [
                _vm.policy.id == null
                  ? _c("div", { staticClass: "form-group cell-5" }, [
                      _c("label", [_vm._v(_vm._s(_vm.$t("Type")) + "*")]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.policy.id_type,
                              expression: "policy.id_type",
                            },
                          ],
                          staticClass: "input w-auto",
                          attrs: { "data-filter": "false", id: "typeselect" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.policy,
                                  "id_type",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              _vm.formatTree,
                            ],
                          },
                        },
                        _vm._l(
                          _vm.$session.getPolicyTypes(),
                          function (policytypes) {
                            return _c(
                              "option",
                              {
                                key: policytypes.id,
                                domProps: { value: policytypes.id },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t(policytypes.name)) + " "
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.$t("Required"))),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.policy.id_type != 1,
                      expression: "policy.id_type != 1",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("Devices")))]),
                  _vm.computers
                    ? _c(
                        "select",
                        {
                          staticClass: "input",
                          attrs: {
                            "data-role": "select",
                            "data-cls-selected-item":
                              "bg-light border pr-2 rounded",
                            "data-filter": "false",
                            multiple: "",
                            id: "computerselect",
                            name: "ids_computers",
                          },
                        },
                        _vm._l(_vm.computers, function (pc) {
                          return _c(
                            "option",
                            {
                              key: pc.id,
                              attrs: {
                                "data-template":
                                  "<span class='mif-laptop icon'></span> $1",
                              },
                              domProps: {
                                selected: _vm.policy.computers.find(
                                  (elem) => elem.id == pc.id
                                ),
                                value: pc.id,
                              },
                            },
                            [_vm._v(" " + _vm._s(pc.name) + " ")]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.policy.id_type == 3,
                      expression: "policy.id_type == 3",
                    },
                  ],
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [
                      _vm._v(_vm._s(_vm.$t("Threshold DURATION")) + "*"),
                    ]),
                    _c("input", {
                      directives: [
                        { name: "number", rawName: "v-number" },
                        {
                          name: "range",
                          rawName: "v-range",
                          value: { min: 1, max: 999 },
                          expression: "{ min: 1, max: 999 }",
                        },
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.policy.policy.threshold_duration,
                          expression: "policy.policy.threshold_duration",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "input w-auto",
                      attrs: { type: "number", min: "0" },
                      domProps: { value: _vm.policy.policy.threshold_duration },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.policy.policy,
                            "threshold_duration",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                    _c("small", { staticClass: "muted" }, [
                      _vm._v("*" + _vm._s(_vm.$t("in minutes"))),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("Threshold CPU")) + "%"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.policy.policy.threshold_cpu,
                            expression: "policy.policy.threshold_cpu",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "ultra-thin cycle-marker",
                        attrs: {
                          type: "number",
                          id: "sliderCPU",
                          "data-hint-always": "true",
                          "data-cls-complete": "bg-cobalt",
                          "data-cls-hint": "bg-cobalt custom-marker ",
                          "data-cls-marker":
                            "bg-cobalt border-50 custom-marker",
                          "data-role": "slider",
                          "data-hint": "true",
                          "data-hint-position": "top",
                          "data-value": _vm.policy.policy.threshold_cpu,
                          "data-max": "100",
                          "data-min": "0",
                        },
                        domProps: { value: _vm.policy.policy.threshold_cpu },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.policy.policy,
                              "threshold_cpu",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("Threshold RAM")) + "%"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.policy.policy.threshold_ram,
                            expression: "policy.policy.threshold_ram",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "ultra-thin cycle-marker",
                        attrs: {
                          type: "number",
                          id: "sliderRAM",
                          "data-hint-always": "true",
                          "data-cls-complete": "bg-cobalt",
                          "data-cls-hint": "bg-cobalt custom-marker",
                          "data-cls-marker":
                            "bg-cobalt border-50 custom-marker",
                          "data-role": "slider",
                          "data-hint": "true",
                          "data-hint-position": "top",
                          "data-value": _vm.policy.policy.threshold_ram,
                          "data-max": "100",
                          "data-min": "0",
                        },
                        domProps: { value: _vm.policy.policy.threshold_ram },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.policy.policy,
                              "threshold_ram",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("Threshold DISK")) + "%"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.policy.policy.threshold_disk,
                            expression: "policy.policy.threshold_disk",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "ultra-thin cycle-marker",
                        attrs: {
                          type: "number",
                          id: "sliderDISK",
                          "data-hint-always": "true",
                          "data-cls-complete": "bg-cobalt",
                          "data-cls-hint": "bg-cobalt custom-marker",
                          "data-cls-marker":
                            "bg-cobalt border-50 custom-marker",
                          "data-role": "slider",
                          "data-hint": "true",
                          "data-hint-position": "top",
                          "data-value": _vm.policy.policy.threshold_disk,
                          "data-max": "100",
                          "data-min": "0",
                        },
                        domProps: { value: _vm.policy.policy.threshold_disk },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.policy.policy,
                              "threshold_disk",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.policy.id_type == 2,
                      expression: "policy.id_type == 2",
                    },
                  ],
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v(_vm._s(_vm.$t("Update time")))]),
                    _c("input", {
                      staticClass: "input w-auto",
                      attrs: {
                        "data-role": "input",
                        type: "text",
                        "data-prepend": "<span class='mif-calendar'></span>",
                      },
                      domProps: {
                        value:
                          _vm.policy.policy.update_time != null &&
                          _vm.policy.policy.update_time != ""
                            ? _vm.cronstrue.toString(
                                _vm.policy.policy.update_time,
                                {
                                  locale: _vm.$i18n.locale,
                                  dayOfWeekStartIndexZero: false,
                                }
                              )
                            : "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$root.$emit(
                            "OPENSCHEDULATIONVIEWDIALOG",
                            ""
                          )
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "form-group mt-5" }, [
                    _c("label", { staticClass: "text-bold" }, [
                      _vm._v(_vm._s(_vm.$t("OS Patch Management"))),
                    ]),
                    _c(
                      "ul",
                      {
                        staticClass: "treeview p-0",
                        attrs: { "data-role": "treeview", id: "patchview" },
                      },
                      [
                        _c("li", [
                          _c("input", {
                            staticClass: "ml-3 text-bold",
                            attrs: {
                              "data-style": "1",
                              type: "checkbox",
                              "data-role": "checkbox",
                              "data-caption": _vm.$t("Security Updates"),
                              title: _vm.$t("Security Updates"),
                              value:
                                '["0fa1201d-4330-4fa8-8ae9-b877473b6441","e0789628-ce08-4437-be74-2495b842f43b","28bc880e-0592-4cbf-8f95-c79b17911d5f"]',
                            },
                          }),
                          _c("ul", [
                            _c("li", [
                              _c("input", {
                                staticClass: "ml-3",
                                attrs: {
                                  "data-style": "1",
                                  type: "checkbox",
                                  "data-role": "checkbox",
                                  value: "0fa1201d-4330-4fa8-8ae9-b877473b6441",
                                  "data-caption":
                                    _vm.patchCategories[
                                      "0fa1201d-4330-4fa8-8ae9-b877473b6441"
                                    ],
                                  title:
                                    _vm.patchCategories[
                                      "0fa1201d-4330-4fa8-8ae9-b877473b6441"
                                    ],
                                },
                                domProps: {
                                  checked:
                                    _vm.policy.policy.included_categories?.includes(
                                      "0fa1201d-4330-4fa8-8ae9-b877473b6441"
                                    ),
                                },
                              }),
                            ]),
                            _c("li", [
                              _c("input", {
                                staticClass: "ml-3",
                                attrs: {
                                  "data-style": "1",
                                  type: "checkbox",
                                  "data-role": "checkbox",
                                  value: "e0789628-ce08-4437-be74-2495b842f43b",
                                  "data-caption":
                                    _vm.patchCategories[
                                      "e0789628-ce08-4437-be74-2495b842f43b"
                                    ],
                                  title:
                                    _vm.patchCategories[
                                      "e0789628-ce08-4437-be74-2495b842f43b"
                                    ],
                                },
                                domProps: {
                                  checked:
                                    _vm.policy.policy.included_categories?.includes(
                                      "e0789628-ce08-4437-be74-2495b842f43b"
                                    ),
                                },
                              }),
                            ]),
                            _c("li", [
                              _c("input", {
                                staticClass: "ml-3",
                                attrs: {
                                  "data-style": "1",
                                  type: "checkbox",
                                  "data-role": "checkbox",
                                  value: "28bc880e-0592-4cbf-8f95-c79b17911d5f",
                                  "data-caption":
                                    _vm.patchCategories[
                                      "28bc880e-0592-4cbf-8f95-c79b17911d5f"
                                    ],
                                  title:
                                    _vm.patchCategories[
                                      "28bc880e-0592-4cbf-8f95-c79b17911d5f"
                                    ],
                                },
                                domProps: {
                                  checked:
                                    _vm.policy.policy.included_categories?.includes(
                                      "28bc880e-0592-4cbf-8f95-c79b17911d5f"
                                    ),
                                },
                              }),
                            ]),
                          ]),
                        ]),
                        _c("li", [
                          _c("input", {
                            staticClass: "ml-3 text-bold",
                            attrs: {
                              "data-style": "1",
                              type: "checkbox",
                              "data-role": "checkbox",
                              "data-caption": _vm.$t("Service packs"),
                              title: _vm.$t("Service packs"),
                              value:
                                '["68c5b0a3-d1a6-4553-ae49-01d3a7827828","b54e7d24-7add-428f-8b75-90a396fa584f","cd5ffd1e-e932-4e3a-bf74-18bf0b1bbd83"]',
                            },
                          }),
                          _c("ul", [
                            _c("li", [
                              _c("input", {
                                staticClass: "ml-3",
                                attrs: {
                                  "data-style": "1",
                                  type: "checkbox",
                                  "data-role": "checkbox",
                                  value: "68c5b0a3-d1a6-4553-ae49-01d3a7827828",
                                  "data-caption":
                                    _vm.patchCategories[
                                      "68c5b0a3-d1a6-4553-ae49-01d3a7827828"
                                    ],
                                  title:
                                    _vm.patchCategories[
                                      "68c5b0a3-d1a6-4553-ae49-01d3a7827828"
                                    ],
                                },
                                domProps: {
                                  checked:
                                    _vm.policy.policy.included_categories?.includes(
                                      "68c5b0a3-d1a6-4553-ae49-01d3a7827828"
                                    ),
                                },
                              }),
                            ]),
                            _c("li", [
                              _c("input", {
                                staticClass: "ml-3",
                                attrs: {
                                  "data-style": "1",
                                  type: "checkbox",
                                  "data-role": "checkbox",
                                  value: "b54e7d24-7add-428f-8b75-90a396fa584f",
                                  "data-caption":
                                    _vm.patchCategories[
                                      "b54e7d24-7add-428f-8b75-90a396fa584f"
                                    ],
                                  title:
                                    _vm.patchCategories[
                                      "b54e7d24-7add-428f-8b75-90a396fa584f"
                                    ],
                                },
                                domProps: {
                                  checked:
                                    _vm.policy.policy.included_categories?.includes(
                                      "b54e7d24-7add-428f-8b75-90a396fa584f"
                                    ),
                                },
                              }),
                            ]),
                            _c("li", [
                              _c("input", {
                                staticClass: "ml-3",
                                attrs: {
                                  "data-style": "1",
                                  type: "checkbox",
                                  "data-role": "checkbox",
                                  value: "cd5ffd1e-e932-4e3a-bf74-18bf0b1bbd83",
                                  "data-caption":
                                    _vm.patchCategories[
                                      "cd5ffd1e-e932-4e3a-bf74-18bf0b1bbd83"
                                    ],
                                  title:
                                    _vm.patchCategories[
                                      "cd5ffd1e-e932-4e3a-bf74-18bf0b1bbd83"
                                    ],
                                },
                                domProps: {
                                  checked:
                                    _vm.policy.policy.included_categories?.includes(
                                      "cd5ffd1e-e932-4e3a-bf74-18bf0b1bbd83"
                                    ),
                                },
                              }),
                            ]),
                          ]),
                        ]),
                        _c("li", [
                          _c("input", {
                            staticClass: "ml-3 text-bold",
                            attrs: {
                              "data-style": "1",
                              type: "checkbox",
                              "data-role": "checkbox",
                              "data-caption": _vm.$t("Drivers and Tools"),
                              title: _vm.$t("Drivers and Tools"),
                              value:
                                '["ebfc1fc5-71a4-4f7b-9aca-3b9a503104a0","b4832bd8-e735-4761-8daf-37f882276dab"]',
                            },
                          }),
                          _c("ul", [
                            _c("li", [
                              _c("input", {
                                staticClass: "ml-3",
                                attrs: {
                                  "data-style": "1",
                                  type: "checkbox",
                                  "data-role": "checkbox",
                                  value: "ebfc1fc5-71a4-4f7b-9aca-3b9a503104a0",
                                  "data-caption":
                                    _vm.patchCategories[
                                      "ebfc1fc5-71a4-4f7b-9aca-3b9a503104a0"
                                    ],
                                  title:
                                    _vm.patchCategories[
                                      "ebfc1fc5-71a4-4f7b-9aca-3b9a503104a0"
                                    ],
                                },
                                domProps: {
                                  checked:
                                    _vm.policy.policy.included_categories?.includes(
                                      "ebfc1fc5-71a4-4f7b-9aca-3b9a503104a0"
                                    ),
                                },
                              }),
                            ]),
                            _c("li", [
                              _c("input", {
                                staticClass: "ml-3",
                                attrs: {
                                  "data-style": "1",
                                  type: "checkbox",
                                  "data-role": "checkbox",
                                  value: "b4832bd8-e735-4761-8daf-37f882276dab",
                                  "data-caption":
                                    _vm.patchCategories[
                                      "b4832bd8-e735-4761-8daf-37f882276dab"
                                    ],
                                  title:
                                    _vm.patchCategories[
                                      "b4832bd8-e735-4761-8daf-37f882276dab"
                                    ],
                                },
                                domProps: {
                                  checked:
                                    _vm.policy.policy.included_categories?.includes(
                                      "b4832bd8-e735-4761-8daf-37f882276dab"
                                    ),
                                },
                              }),
                            ]),
                          ]),
                        ]),
                        _c("li", [
                          _c("input", {
                            staticClass: "ml-3 text-bold",
                            attrs: {
                              "data-style": "1",
                              type: "checkbox",
                              "data-role": "checkbox",
                              value: "e6cf1350-c01b-414d-a61f-263d14d133b4",
                              "data-caption":
                                _vm.patchCategories[
                                  "e6cf1350-c01b-414d-a61f-263d14d133b4"
                                ],
                              title:
                                _vm.patchCategories[
                                  "e6cf1350-c01b-414d-a61f-263d14d133b4"
                                ],
                            },
                            domProps: {
                              checked:
                                _vm.policy.policy.included_categories?.includes(
                                  "e6cf1350-c01b-414d-a61f-263d14d133b4"
                                ),
                            },
                          }),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "form-group mt-3" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.policy.policy.reboot_auto,
                          expression: "policy.policy.reboot_auto",
                        },
                      ],
                      attrs: {
                        "data-role": "checkbox",
                        type: "checkbox",
                        "data-caption": _vm.$t("Reboot auto"),
                      },
                      domProps: {
                        checked: Array.isArray(_vm.policy.policy.reboot_auto)
                          ? _vm._i(_vm.policy.policy.reboot_auto, null) > -1
                          : _vm.policy.policy.reboot_auto,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.policy.policy.reboot_auto,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.policy.policy,
                                  "reboot_auto",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.policy.policy,
                                  "reboot_auto",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.policy.policy, "reboot_auto", $$c)
                          }
                        },
                      },
                    }),
                    _c("br"),
                  ]),
                  _c("div", { staticClass: "row ml-3 d-flex" }, [
                    _c("div", { staticClass: "form-group cell-3" }, [
                      _c("label", { staticClass: "no-wrap" }, [
                        _vm._v(_vm._s(_vm.$t("Reboot time from"))),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.policy.policy.reboot_time_inf,
                            expression: "policy.policy.reboot_time_inf",
                          },
                        ],
                        staticClass: "input w-auto",
                        attrs: {
                          disabled: !_vm.policy.policy.reboot_auto,
                          type: "time",
                        },
                        domProps: { value: _vm.policy.policy.reboot_time_inf },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.policy.policy,
                              "reboot_time_inf",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "form-group cell-3" }, [
                      _c("label", { staticClass: "no-wrap" }, [
                        _vm._v(_vm._s(_vm.$t("Reboot time to"))),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.policy.policy.reboot_time_sup,
                            expression: "policy.policy.reboot_time_sup",
                          },
                        ],
                        staticClass: "input w-auto",
                        attrs: {
                          disabled: !_vm.policy.policy.reboot_auto,
                          type: "time",
                        },
                        domProps: { value: _vm.policy.policy.reboot_time_sup },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.policy.policy,
                              "reboot_time_sup",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.policy.id_type == 1,
                      expression: "policy.id_type == 1",
                    },
                  ],
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v(_vm._s(_vm.$t("Scheduling")))]),
                    _vm.policy.refreshID != 0
                      ? _c("input", {
                          key: _vm.policy.refreshID,
                          staticClass: "input",
                          attrs: { type: "text" },
                          domProps: {
                            value:
                              _vm.policy.policy.scheduling != null &&
                              _vm.policy.policy.scheduling.length > 0 &&
                              _vm.policy.policy.scheduling[0] != ""
                                ? _vm.cronstrue.toString(
                                    _vm.policy.policy.scheduling[0],
                                    {
                                      locale: _vm.$i18n.locale,
                                      dayOfWeekStartIndexZero: false,
                                    }
                                  )
                                : "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$root.$emit(
                                "OPENSCHEDULATIONVIEWDIALOG",
                                ""
                              )
                            },
                          },
                        })
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "form-group mt-3" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.policy.policy.misfire_alert,
                          expression: "policy.policy.misfire_alert",
                        },
                      ],
                      attrs: {
                        "data-role": "checkbox",
                        type: "checkbox",
                        "data-caption": _vm.$t("Enable Misfire Alert"),
                      },
                      domProps: {
                        checked: Array.isArray(_vm.policy.policy.misfire_alert)
                          ? _vm._i(_vm.policy.policy.misfire_alert, null) > -1
                          : _vm.policy.policy.misfire_alert,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.policy.policy.misfire_alert,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.policy.policy,
                                  "misfire_alert",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.policy.policy,
                                  "misfire_alert",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.policy.policy, "misfire_alert", $$c)
                          }
                        },
                      },
                    }),
                    _c("br"),
                  ]),
                  _vm.backupTree
                    ? _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v(_vm._s(_vm.$t("Select Backups")))]),
                        _c(
                          "ul",
                          {
                            attrs: { "data-role": "treeview", id: "treeview" },
                          },
                          _vm._l(_vm.backupTree.computers, function (computer) {
                            return _c(
                              "li",
                              {
                                attrs: { "data-icon": "", "data-caption": "" },
                              },
                              [
                                _c("input", {
                                  key: computer.id,
                                  attrs: {
                                    name: "computer",
                                    type: "checkbox",
                                    "data-role": "checkbox",
                                    "data-caption": computer.name,
                                    title: computer.name,
                                  },
                                  domProps: { value: computer.id },
                                }),
                                computer.jobs.size != 0
                                  ? _c(
                                      "ul",
                                      _vm._l(computer.jobs, function (backup) {
                                        return _c(
                                          "li",
                                          {
                                            attrs: {
                                              "data-icon": "",
                                              "data-caption": "",
                                            },
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.policy.jobs,
                                                  expression: "policy.jobs",
                                                },
                                              ],
                                              key: backup.id,
                                              attrs: {
                                                name: "backup",
                                                type: "checkbox",
                                                "data-role": "checkbox",
                                                "data-caption": backup.name,
                                                title: backup.name,
                                              },
                                              domProps: {
                                                value: backup.id,
                                                checked: Array.isArray(
                                                  _vm.policy.jobs
                                                )
                                                  ? _vm._i(
                                                      _vm.policy.jobs,
                                                      backup.id
                                                    ) > -1
                                                  : _vm.policy.jobs,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a = _vm.policy.jobs,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = backup.id,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.policy,
                                                          "jobs",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.policy,
                                                          "jobs",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.policy,
                                                      "jobs",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "dialog-actions" }, [
            _c(
              "button",
              { staticClass: "button primary", on: { click: _vm.save } },
              [
                _vm._v(
                  _vm._s(
                    _vm.policy.id == "" ? _vm.$t("Create") : _vm.$t("Save")
                  )
                ),
              ]
            ),
            _vm.policy.id != null
              ? _c(
                  "button",
                  {
                    staticClass: "button error ml-2",
                    on: {
                      click: function ($event) {
                        return _vm.$root.$emit("deletePolicy", _vm.policy)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("Delete")))]
                )
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "button ml-2",
                on: {
                  click: function ($event) {
                    return _vm.$root.$emit("CLOSEPOLICYVIEWDIALOG")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("Close")))]
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }