var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row p-1 pl-6 pr-6" }, [
    _c("div", { staticClass: "cell d-flex flex-align-center p-0" }, [
      _c("h3", { staticClass: "dashboard-section-title text-left-md m-0" }, [
        _c("ul", { staticClass: "breadcrumbs p-3" }, [
          _vm._m(0),
          _vm.pageName != "dashboard"
            ? _c(
                "button",
                {
                  staticClass: "page-link button",
                  on: {
                    click: function ($event) {
                      return _vm.$root.goBack()
                    },
                  },
                },
                [_c("i", { staticClass: "mif-arrow-left" })]
              )
            : _vm._e(),
          _vm.pageName == "dashboard"
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "page-link pl-0",
                      attrs: { to: "/dashboard" },
                    },
                    [
                      _c("table", [
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "px-1 py-0",
                              class: {
                                h2: _vm.pageName == "dashboard",
                                "p-4": _vm.pageName != "dashboard",
                              },
                            },
                            [_c("label", [_vm._v(_vm._s(_vm.$t("Dashboard")))])]
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "px-1 py-0" }, [
                            _vm._v(
                              _vm._s(_vm.$t("License")) +
                                " : " +
                                _vm._s(_vm.$root.checkAuth().company) +
                                " (" +
                                _vm._s(_vm.$root.checkAuth().ma_username) +
                                ")"
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.accounts
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/accounts" } },
                    [
                      _vm.pageName == "accounts"
                        ? _c("span", { staticClass: "mr-1" })
                        : _vm._e(),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("Credentials Locker"))),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.repositories
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/repo" } },
                    [
                      _vm.pageName == "repositories"
                        ? _c("span", { staticClass: "mr-1" })
                        : _vm._e(),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("Backup Repositories"))),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.computer && _vm.pc && _vm.$root.forceRefreshID >= 0
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/computers" } },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("Devices")) + " ")])]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.computer && _vm.pc && _vm.$root.forceRefreshID >= 0
            ? _c("li", { staticClass: "page-item" }, [
                _c(
                  "select",
                  {
                    staticClass: "select input w-auto ml-3 mr-3",
                    on: { change: _vm.navigateToComputer },
                  },
                  _vm._l(_vm.$session.getPCList(), function (pc2) {
                    return _c(
                      "option",
                      {
                        key: pc2.id,
                        staticClass: "no-wrap",
                        attrs: {
                          disabled:
                            _vm.pageName == "backup" && _vm.data.editing,
                        },
                        domProps: {
                          selected: pc2.id == _vm.pc.id,
                          value: pc2.id,
                        },
                      },
                      [
                        _c(
                          "label",
                          {
                            class: {
                              "text-bold": _vm.$root.connectionsStates[pc2.id],
                            },
                          },
                          [_vm._v(" " + _vm._s(pc2.name))]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm.backup
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/backup/" } },
                    [
                      _vm.pageName == "backup"
                        ? _c("span", { staticClass: "mr-1" })
                        : _vm._e(),
                      _vm._v(
                        _vm._s(
                          _vm.data.editing
                            ? _vm.data.backupName
                            : _vm.$t("Create Backup")
                        )
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.restore
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/restore" } },
                    [
                      _vm.pageName == "restore"
                        ? _c("span", { staticClass: "mr-1 mif-arrow-up" })
                        : _vm._e(),
                      _vm._v(_vm._s(_vm.$t("Restore Backup"))),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.backupView
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "page-link no-wrap",
                      attrs: { to: "/backupview/" + _vm.data.job.id },
                    },
                    [
                      _vm.pageName == "backupview"
                        ? _c("span", { staticClass: "mr-1 mif-news" })
                        : _vm._e(),
                      _vm._v(_vm._s(_vm.data.job.name)),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.groups
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/groups" } },
                    [
                      _vm.pageName == "groups"
                        ? _c("span", { staticClass: "mr-1" })
                        : _vm._e(),
                      _vm._v(_vm._s(_vm.$t("Groups"))),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.policies
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/policies" } },
                    [
                      _vm.pageName == "policies"
                        ? _c("span", { staticClass: "mr-1" })
                        : _vm._e(),
                      _vm._v(_vm._s(_vm.$t("Policies"))),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.group
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "page-link no-wrap",
                      attrs: { to: "/group/" + _vm.data.group.id },
                    },
                    [
                      _vm.pageName == "group"
                        ? _c("span", { staticClass: "mr-1 mif-folder-open" })
                        : _vm._e(),
                      _vm._v(_vm._s(_vm.data.group.name)),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.users
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/users" } },
                    [
                      _vm.pageName == "users" ? _c("span") : _vm._e(),
                      _vm._v(_vm._s(_vm.$t("Users"))),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.pageName == "settings"
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/settings" } },
                    [
                      _c("span", { staticClass: "mr-1" }),
                      _vm._v(_vm._s(_vm.$t("Privacy Settings")) + " "),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.pageName == "computers"
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/computers" } },
                    [
                      _c("span", { staticClass: "mr-1" }),
                      _vm._v(_vm._s(_vm.$t("Devices")) + " "),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.pageName == "licenses"
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/licenses" } },
                    [
                      _c("span", { staticClass: "mr-1" }),
                      _vm._v(_vm._s(_vm.$t("Licenses"))),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.pageName == "schedulation"
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/scheduling" } },
                    [
                      _c("span", { staticClass: "mr-1" }),
                      _vm._v(_vm._s(_vm.$t("Backup Scheduling"))),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.pageName == "remotestats"
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/remotestats" } },
                    [
                      _c("span", { staticClass: "mr-1" }),
                      _vm._v(_vm._s(_vm.$t("Remote statistics"))),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.pageName == "backupstats"
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/backupstats" } },
                    [
                      _c("span", { staticClass: "mr-1" }),
                      _vm._v(_vm._s(_vm.$t("Backup statistics"))),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.pageName == "sessions"
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/sessions" } },
                    [
                      _c("span", { staticClass: "mr-1" }),
                      _vm._v(_vm._s(_vm.$t("Sessions"))),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.pageName == "activesessions"
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "page-link", attrs: { to: "/sessions" } },
                    [
                      _c("span", { staticClass: "mr-1" }),
                      _vm._v(_vm._s(_vm.$t("Active Sessions"))),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.pageName == "customclient"
            ? _c(
                "li",
                { staticClass: "page-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "page-link",
                      attrs: { to: "/customclient" },
                    },
                    [
                      _c("span", { staticClass: "mr-1" }),
                      _vm._v(_vm._s(_vm.$t("Client customization"))),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ]),
    _vm.pageName == "computers"
      ? _c(
          "div",
          { staticClass: "cell d-flex flex-align-center flex-justify-end" },
          [
            _c("ul", { staticClass: "listview" }, [
              _c(
                "a",
                {
                  staticClass: "button primary",
                  attrs: {
                    onclick: "window.Metro.charms.toggle('#agentbuilding');",
                  },
                },
                [
                  _c("span", { staticClass: "icon mif-devices" }),
                  _c("span", {}, [_vm._v(_vm._s(_vm.$t("Add Device")))]),
                ]
              ),
              _c("li", { staticClass: "ml-2" }, [
                _c("input", {
                  attrs: {
                    type: "text",
                    "data-role": "input",
                    placeholder: _vm.$t("Search") + "...",
                  },
                  on: { change: _vm.emitFilter, input: _vm.emitFilter },
                }),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.pageName == "groups"
      ? _c(
          "div",
          {
            staticClass: "cell d-flex flex-align-center flex-justify-end pr-1",
          },
          [
            _c("ul", { staticClass: "listview" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "button primary mr-1",
                    on: {
                      click: function ($event) {
                        return _vm.$root.$emit("OPENGROUPVIEWDIALOG")
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "icon mif-2x mif-add" }),
                    _c("span", [_vm._v(_vm._s(_vm.$t("Add Group")))]),
                  ]
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.pageName == "policies"
      ? _c(
          "div",
          {
            staticClass: "cell d-flex flex-align-center flex-justify-end pr-1",
          },
          [
            _c("ul", { staticClass: "listview" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "button primary mr-1",
                    on: {
                      click: function ($event) {
                        return _vm.$root.$emit("OPENPOLICYVIEWDIALOG")
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "icon mif-2x mif-add" }),
                    _c("span", {}, [_vm._v(_vm._s(_vm.$t("Add Policy")))]),
                  ]
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.pageName == "dashboard"
      ? _c(
          "div",
          { staticClass: "cell d-flex flex-align-center flex-justify-end" },
          [
            _c("ul", { staticClass: "listview" }, [
              _c("li", { staticClass: "ml-2 mr-2" }, [
                _c(
                  "a",
                  {
                    staticClass: "button primary",
                    attrs: {
                      onclick: "window.Metro.charms.toggle('#agentbuilding');",
                    },
                  },
                  [
                    _c("span", { staticClass: "icon mif-download" }),
                    _c("span", {}, [_vm._v(_vm._s(_vm.$t("Agent download")))]),
                  ]
                ),
              ]),
              _c("li", [
                _c(
                  "button",
                  {
                    staticClass: "button dropdown-toggle",
                    attrs: { id: "fullstats" },
                  },
                  [
                    _c("span", { staticClass: "mif-chart-pie pr-2" }),
                    _vm._v(_vm._s(_vm.$t("Toggle statistics"))),
                  ]
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.pageName == "licenses"
      ? _c(
          "div",
          { staticClass: "cell d-flex flex-align-center flex-justify-end" },
          [
            _c("ul", { staticClass: "listview" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "button primary ml-4",
                    attrs: {
                      onclick: "window.Metro.charms.toggle('#plugin');",
                    },
                  },
                  [
                    _c("span", {}),
                    _vm._v(_vm._s(_vm.$t("Upgrade your License")) + " "),
                  ]
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.pageName == "settings"
      ? _c(
          "div",
          { staticClass: "cell d-flex flex-align-center flex-justify-end" },
          [
            _c("ul", { staticClass: "listview" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "button",
                    on: {
                      click: function ($event) {
                        return _vm.$root.requestNotificationPermission()
                      },
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "mif-notifications mif-2x mr-2",
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("Enable Web Notifications"))),
                  ]
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.pageName == "users"
      ? _c(
          "div",
          { staticClass: "cell d-flex flex-align-center flex-justify-end" },
          [
            _c("ul", { staticClass: "listview" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "button primary mr-2",
                    on: {
                      click: function ($event) {
                        return _vm.$root.$emit("OPENUSERVIEWDIALOG")
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "icon mif-2x mif-add" }),
                    _c("span", [_vm._v(_vm._s(_vm.$t("Add User")))]),
                  ]
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.pageName == "computer"
      ? _c(
          "div",
          { staticClass: "cell d-flex flex-align-center flex-justify-end" },
          [
            _c("div", {}, [
              _c("ul", { staticClass: "h-menu bg-light d-flex" }, [
                _c("li", [
                  _c("div", [
                    _vm.pc.backup_license_status == 0
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "dropdown-toggle button primary mr-2 p-4 pr-4",
                            attrs: {
                              href: "#",
                              onclick: "window.Metro.charms.toggle('#plugin');",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Backup")))]
                        )
                      : _c(
                          "a",
                          {
                            staticClass:
                              "dropdown-toggle button primary mr-2 p-4 pr-4",
                            attrs: { href: "#" },
                          },
                          [_vm._v(_vm._s(_vm.$t("Backup")))]
                        ),
                    _c(
                      "ul",
                      {
                        staticClass: "d-menu",
                        attrs: { "data-role": "dropdown" },
                      },
                      [
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "text-bold",
                                attrs: {
                                  to:
                                    _vm.pc.backup_license_status == 0
                                      ? "#"
                                      : this.$route.path + "/backup",
                                  disabled:
                                    !_vm.$root.connectionsStates[_vm.pc.id],
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("Add backup job")) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "text-bold dropdown-toggle",
                              attrs: {
                                disabled:
                                  !_vm.$root.connectionsStates[_vm.pc.id],
                                href: "#",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("Restore backup job")) + " ")]
                          ),
                          _vm.pc.backup_license_status != 0
                            ? _c(
                                "ul",
                                {
                                  staticClass: "d-menu",
                                  attrs: { "data-role": "dropdown" },
                                },
                                [
                                  _vm._l(_vm.restoreTypes, function (t) {
                                    return _vm.$platformsTypesInfo != null
                                      ? [
                                          _vm._l(
                                            _vm.$root.getMicrosoftTypes(),
                                            function (microsoft_t) {
                                              return t == 5
                                                ? _c(
                                                    "li",
                                                    { key: microsoft_t },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm.$root
                                                                .connectionsStates[
                                                                _vm.pc.id
                                                              ] ||
                                                              _vm.pc
                                                                .backup_license_status ==
                                                                0,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.$root.$emit(
                                                                "OPENRESTOREDIALOG",
                                                                microsoft_t,
                                                                null
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v("Microsoft "),
                                                          _c("b", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm
                                                                  .$platformsTypesInfo[
                                                                  microsoft_t
                                                                ].name
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e()
                                            }
                                          ),
                                          t != 5
                                            ? _c("li", [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        !_vm.$root
                                                          .connectionsStates[
                                                          _vm.pc.id
                                                        ] ||
                                                        _vm.pc
                                                          .backup_license_status ==
                                                          0,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.$root.$emit(
                                                          "OPENRESTOREDIALOG",
                                                          t,
                                                          null
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm
                                                            .$platformsTypesInfo[
                                                            t
                                                          ].name
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      : _vm._e()
                                  }),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c(
                  "li",
                  [
                    _c("PCRemoteLink", {
                      attrs: {
                        pc: _vm.pc,
                        disabled: !_vm.pc.status,
                        complete: "true",
                      },
                    }),
                  ],
                  1
                ),
                _c("li", [
                  _c(
                    "div",
                    {
                      attrs: {
                        disabled:
                          !_vm.$root.connectionsStates[_vm.pc.id] ||
                          _vm.pc.backup_license_status == 0,
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "ml-2 button",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.$root.$emit("openTerminal")
                            },
                          },
                        },
                        [
                          _c("span", {
                            staticClass: "mif-2x mif-external mr-1",
                          }),
                          _vm._v(" " + _vm._s(_vm.$t("Open Command Prompt"))),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      { staticClass: "ml-3 mr-3 button square", attrs: { id: "menu-toggle" } },
      [_c("span", { staticClass: "mif-menu" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }