var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.createFolderObj != null
    ? _c("div", { staticClass: "dialog light h-auto" }, [
        _vm.showChoices
          ? _c("div", { staticClass: "dialog-title pl-10" }, [
              _vm._v(_vm._s(_vm.$t("Create new folder"))),
            ])
          : _vm.type == _vm.$PlatformsTypes.Cloud_AzureBlob
          ? _c("div", { staticClass: "dialog-title pl-10" }, [
              _vm._v(_vm._s(_vm.$t("Create new container"))),
            ])
          : _vm.type == _vm.$PlatformsTypes.Cloud_AzureFile
          ? _c("div", { staticClass: "dialog-title pl-10" }, [
              _vm._v(_vm._s(_vm.$t("Create new share"))),
            ])
          : _vm.type.isCloudS3()
          ? _c("div", { staticClass: "dialog-title pl-10" }, [
              _vm._v(_vm._s(_vm.$t("Create new bucket"))),
            ])
          : _vm._e(),
        _c("div", { staticClass: "dialog-content p-0" }, [
          _vm.showChoices
            ? _c("div", { staticClass: "d-flex flex-column form-group" }, [
                _vm.whatCreate_showValues[_vm.IN_SELECTED_PATH]
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.whatCreate,
                          expression: "whatCreate",
                        },
                      ],
                      attrs: {
                        name: "createFolderRadio",
                        type: "radio",
                        "data-role": "radio",
                        "data-caption": _vm.$t(
                          "Create a folder in selected path"
                        ),
                      },
                      domProps: {
                        value: _vm.IN_SELECTED_PATH,
                        checked: _vm._q(_vm.whatCreate, _vm.IN_SELECTED_PATH),
                      },
                      on: {
                        change: function ($event) {
                          _vm.whatCreate = _vm.IN_SELECTED_PATH
                        },
                      },
                    })
                  : _vm._e(),
                _vm.whatCreate_showValues[_vm.IN_ANOTHER_PATH]
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.whatCreate,
                          expression: "whatCreate",
                        },
                      ],
                      attrs: {
                        name: "createFolderRadio",
                        type: "radio",
                        "data-role": "radio",
                        "data-caption": _vm.$t(
                          "Create a folder in another path"
                        ),
                      },
                      domProps: {
                        value: _vm.IN_ANOTHER_PATH,
                        checked: _vm._q(_vm.whatCreate, _vm.IN_ANOTHER_PATH),
                      },
                      on: {
                        change: function ($event) {
                          _vm.whatCreate = _vm.IN_ANOTHER_PATH
                        },
                      },
                    })
                  : _vm._e(),
                _vm.whatCreate_showValues[_vm.ROOT_FOLDER]
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.whatCreate,
                          expression: "whatCreate",
                        },
                      ],
                      attrs: {
                        name: "createFolderRadio",
                        type: "radio",
                        "data-role": "radio",
                        "data-caption": _vm.$t("Create a main folder"),
                      },
                      domProps: {
                        value: _vm.ROOT_FOLDER,
                        checked: _vm._q(_vm.whatCreate, _vm.ROOT_FOLDER),
                      },
                      on: {
                        change: function ($event) {
                          _vm.whatCreate = _vm.ROOT_FOLDER
                        },
                      },
                    })
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.whatCreate == _vm.IN_ANOTHER_PATH &&
          _vm.createFolderObj.mainFolders.length != 0
            ? _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("Main folder")))]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.mainFolderSelected,
                        expression: "mainFolderSelected",
                      },
                    ],
                    staticClass: "select w-auto",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.mainFolderSelected = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.createFolderObj.mainFolders, function (rootName) {
                    return _c(
                      "option",
                      { key: rootName, domProps: { value: rootName } },
                      [_vm._v(" " + _vm._s(rootName) + " ")]
                    )
                  }),
                  0
                ),
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(_vm.$t("Required"))),
                ]),
              ])
            : _vm._e(),
          _vm.parentPath != ""
            ? _c("div", { staticClass: "form-group d-flex mb-2 va-baseline" }, [
                _c("span", { staticClass: "mr-2" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "24",
                        height: "24",
                        fill: "none",
                        viewBox: "0 0 24 24",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M13.821 6.5h5.929a2.25 2.25 0 0 1 2.229 1.938l.016.158.005.154v9a2.25 2.25 0 0 1-2.096 2.245L19.75 20H4.25a2.25 2.25 0 0 1-2.245-2.096L2 17.75v-7.251l6.207.001.196-.009a2.25 2.25 0 0 0 1.088-.393l.156-.12L13.821 6.5ZM8.207 4c.46 0 .908.141 1.284.402l.156.12 2.103 1.751-3.063 2.553-.085.061a.75.75 0 0 1-.29.106L8.206 9 2 8.999V6.25a2.25 2.25 0 0 1 2.096-2.245L4.25 4h3.957Z",
                          fill: "#212121",
                        },
                      }),
                    ]
                  ),
                ]),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.parentPath.trimEnd(_vm.slash)) +
                      _vm._s(_vm.slash)
                  ),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.createFolderObj.name,
                  expression: "createFolderObj.name",
                },
                { name: "fsitem", rawName: "v-fsitem" },
              ],
              staticClass: "input",
              attrs: { type: "text", "data-role": "input" },
              domProps: { value: _vm.createFolderObj.name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.createFolderObj, "name", $event.target.value)
                },
              },
            }),
            _vm.nameIsInvalid
              ? _c("div", { staticClass: "mt-1 fg-red text-small" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "A filename cannot contain any of the following characters : {0}.",
                          { 0: _vm.notValidChars.join(" ") }
                        )
                      ) + " "
                    ),
                  ]),
                  _c("br"),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("Use {0} as a separator.", { 0: this.slash })
                      ) + " "
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm.whatCreate == _vm.ROOT_FOLDER &&
          _vm.regions.length != 0 &&
          _vm.type == _vm.$PlatformsTypes.Cloud_AmazonS3Storage
            ? _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("Region")))]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.region,
                        expression: "region",
                      },
                    ],
                    staticClass: "select w-auto",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.region = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.regions, function (region) {
                    return _c(
                      "option",
                      { key: region.Url, domProps: { value: region.Url } },
                      [_vm._v(_vm._s(region.Name))]
                    )
                  }),
                  0
                ),
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(_vm.$t("Required"))),
                ]),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "dialog-actions" }, [
          _vm.wait
            ? _c("div", { staticClass: "form-group text-center" }, [
                _c("span", { staticClass: "mif-spinner2 fg-primary ani-spin" }),
              ])
            : _c(
                "button",
                {
                  staticClass: "button js-dialog-close primary my-3",
                  attrs: {
                    disabled:
                      _vm.createFolderObj.name == "" || _vm.nameIsInvalid,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.createFolder()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Create")))]
              ),
          _c(
            "button",
            {
              staticClass: "button js-dialog-close",
              on: {
                click: function ($event) {
                  return _vm.$root.$emit("CLOSECREATEFOLDERDIALOG")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("Close")))]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }