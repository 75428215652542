var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100 overflow" },
    [
      _c("Navigation", { attrs: { pageName: "policies" } }),
      _vm.policies == null
        ? _c("div", { staticClass: "d-flex flex-justify-center p-5" }, [
            _c("span", {
              staticClass: "mif-spinner2 fg-primary ani-spin mif-3x",
            }),
          ])
        : _vm.policies.length == 0
        ? _c("NoDataAvailable", {
            attrs: { message: _vm.$t("No policies selected") },
          })
        : _c("div", { staticClass: "p-5" }, [
            _c(
              "table",
              {
                staticClass: "table table-border compact striped bg-white m-0",
                attrs: {
                  "data-check": false,
                  "data-check-name": "chkBox",
                  "data-locale": _vm.$i18n.locale,
                  "data-table-search-title": _vm.$t("Search Policy"),
                  "data-cls-search": "w-100-sm w-25-xl w-25-lg w-50-md",
                  "data-show-activity": "true",
                  "data-cell-wrapper": "false",
                  "data-role": "table",
                  "data-rows": "20",
                  "data-show-rows-steps": "false",
                  "data-show-pagination":
                    _vm.policies.length > 20 ? "true" : "false",
                  "data-horizontal-scroll": "true",
                },
              },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "sortable-column" }, [
                      _vm._v(_vm._s(_vm.$t("Name"))),
                    ]),
                    _c("th", { staticClass: "sortable-column" }, [
                      _vm._v(_vm._s(_vm.$t("Type"))),
                    ]),
                    _c(
                      "th",
                      {
                        staticClass: "text-center no-wrap",
                        attrs: { "data-cls-column": "text-center  no-wrap" },
                      },
                      [_vm._v(_vm._s(_vm.$t("Created at")) + " ")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-center no-wrap",
                        attrs: { "data-cls-column": "text-center  no-wrap" },
                      },
                      [_vm._v(_vm._s(_vm.$t("Last Update")) + " ")]
                    ),
                    _c("th"),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.policies, function (policy) {
                    return _c(
                      "tr",
                      { key: policy.id, staticClass: "mx-8 p-4 rounded m-6" },
                      [
                        _c("td", [
                          _c(
                            "a",
                            {
                              staticClass:
                                "text-bold text-left m-0 button flat-button",
                              attrs: { href: "#" + policy.id },
                            },
                            [_vm._v(" " + _vm._s(policy.name) + " ")]
                          ),
                        ]),
                        _c("td", [
                          _c(
                            "label",
                            {
                              staticClass:
                                "badge inline bg-cobalt fg-white p-1 mr-1",
                            },
                            [_vm._v(_vm._s(policy.name_type))]
                          ),
                        ]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.formatDate(policy.dt_creation)) +
                              " "
                          ),
                        ]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.formatDate(policy.dt_last_edit)) +
                              " "
                          ),
                        ]),
                        _c("td", [
                          _c(
                            "ul",
                            { staticClass: "listview flex-justify-end d-flex" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "fg-dark",
                                  attrs: { to: "#" + policy.id },
                                },
                                [
                                  _c(
                                    "li",
                                    { staticClass: "button flat-button" },
                                    [_c("span", { staticClass: "mif-pencil" })]
                                  ),
                                ]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "fg-dark",
                                  attrs: { to: "#" + policy.id + "/delete" },
                                },
                                [
                                  _c(
                                    "li",
                                    { staticClass: "button flat-button" },
                                    [_c("span", { staticClass: "mif-bin" })]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }