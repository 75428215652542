var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "" },
    [
      _c("Navigation", { attrs: { pageName: "settings" } }),
      _c("div", { staticClass: "row p-4" }, [
        _c(
          "div",
          {
            staticClass:
              "bg-white col-lg-12 col-md-12 border border-radius pt-9 p-4",
          },
          [
            _c("form", [
              false
                ? _c("table", { staticClass: "table p-4" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("td", [_vm._v(_vm._s(_vm.$t("Device")))]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("Backup Notifications"))),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            _vm._s(_vm.$t("Hardware Notifications")) + " "
                          ),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("System Notifications")) + " "),
                        ]),
                      ]),
                    ]),
                    _c("tbody", [
                      _c("tr", [
                        _vm._m(0),
                        _c("td", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-justify-end text-center",
                            },
                            [
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.userSettingsNotifications
                                          .backupNotification[0],
                                      expression:
                                        "userSettingsNotifications.backupNotification[0]",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "right",
                                    "data-role": "checkbox",
                                    "data-caption": "Email",
                                    "data-on": true,
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.userSettingsNotifications
                                        .backupNotification[0]
                                    )
                                      ? _vm._i(
                                          _vm.userSettingsNotifications
                                            .backupNotification[0],
                                          null
                                        ) > -1
                                      : _vm.userSettingsNotifications
                                          .backupNotification[0],
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.userSettingsNotifications
                                            .backupNotification[0],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              0,
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              0,
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.userSettingsNotifications
                                            .backupNotification,
                                          0,
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.userSettingsNotifications
                                          .backupNotification[1],
                                      expression:
                                        "userSettingsNotifications.backupNotification[1]",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "right",
                                    "data-role": "checkbox",
                                    "data-caption": "Mobile",
                                    "data-on": "true",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.userSettingsNotifications
                                        .backupNotification[1]
                                    )
                                      ? _vm._i(
                                          _vm.userSettingsNotifications
                                            .backupNotification[1],
                                          null
                                        ) > -1
                                      : _vm.userSettingsNotifications
                                          .backupNotification[1],
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.userSettingsNotifications
                                            .backupNotification[1],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              1,
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              1,
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.userSettingsNotifications
                                            .backupNotification,
                                          1,
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.userSettingsNotifications
                                          .backupNotification[2],
                                      expression:
                                        "userSettingsNotifications.backupNotification[2]",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "right",
                                    "data-role": "checkbox",
                                    "data-caption": "Browser",
                                    "data-on": "true",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.userSettingsNotifications
                                        .backupNotification[2]
                                    )
                                      ? _vm._i(
                                          _vm.userSettingsNotifications
                                            .backupNotification[2],
                                          null
                                        ) > -1
                                      : _vm.userSettingsNotifications
                                          .backupNotification[2],
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.userSettingsNotifications
                                            .backupNotification[2],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              2,
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              2,
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.userSettingsNotifications
                                            .backupNotification,
                                          2,
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ]),
                        _c("td", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-justify-end text-center",
                            },
                            [
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.userSettingsNotifications
                                          .backupNotification[0],
                                      expression:
                                        "userSettingsNotifications.backupNotification[0]",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "right",
                                    "data-role": "checkbox",
                                    "data-caption": "Email",
                                    "data-on": true,
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.userSettingsNotifications
                                        .backupNotification[0]
                                    )
                                      ? _vm._i(
                                          _vm.userSettingsNotifications
                                            .backupNotification[0],
                                          null
                                        ) > -1
                                      : _vm.userSettingsNotifications
                                          .backupNotification[0],
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.userSettingsNotifications
                                            .backupNotification[0],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              0,
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              0,
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.userSettingsNotifications
                                            .backupNotification,
                                          0,
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.userSettingsNotifications
                                          .backupNotification[1],
                                      expression:
                                        "userSettingsNotifications.backupNotification[1]",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "right",
                                    "data-role": "checkbox",
                                    "data-caption": "Mobile",
                                    "data-on": "true",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.userSettingsNotifications
                                        .backupNotification[1]
                                    )
                                      ? _vm._i(
                                          _vm.userSettingsNotifications
                                            .backupNotification[1],
                                          null
                                        ) > -1
                                      : _vm.userSettingsNotifications
                                          .backupNotification[1],
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.userSettingsNotifications
                                            .backupNotification[1],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              1,
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              1,
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.userSettingsNotifications
                                            .backupNotification,
                                          1,
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.userSettingsNotifications
                                          .backupNotification[2],
                                      expression:
                                        "userSettingsNotifications.backupNotification[2]",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "right",
                                    "data-role": "checkbox",
                                    "data-caption": "Browser",
                                    "data-on": "true",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.userSettingsNotifications
                                        .backupNotification[2]
                                    )
                                      ? _vm._i(
                                          _vm.userSettingsNotifications
                                            .backupNotification[2],
                                          null
                                        ) > -1
                                      : _vm.userSettingsNotifications
                                          .backupNotification[2],
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.userSettingsNotifications
                                            .backupNotification[2],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              2,
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              2,
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.userSettingsNotifications
                                            .backupNotification,
                                          2,
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ]),
                        _c("td", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-justify-end text-center",
                            },
                            [
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.userSettingsNotifications
                                          .backupNotification[0],
                                      expression:
                                        "userSettingsNotifications.backupNotification[0]",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "right",
                                    "data-role": "checkbox",
                                    "data-caption": "Email",
                                    "data-on": true,
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.userSettingsNotifications
                                        .backupNotification[0]
                                    )
                                      ? _vm._i(
                                          _vm.userSettingsNotifications
                                            .backupNotification[0],
                                          null
                                        ) > -1
                                      : _vm.userSettingsNotifications
                                          .backupNotification[0],
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.userSettingsNotifications
                                            .backupNotification[0],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              0,
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              0,
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.userSettingsNotifications
                                            .backupNotification,
                                          0,
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.userSettingsNotifications
                                          .backupNotification[1],
                                      expression:
                                        "userSettingsNotifications.backupNotification[1]",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "right",
                                    "data-role": "checkbox",
                                    "data-caption": "Mobile",
                                    "data-on": "true",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.userSettingsNotifications
                                        .backupNotification[1]
                                    )
                                      ? _vm._i(
                                          _vm.userSettingsNotifications
                                            .backupNotification[1],
                                          null
                                        ) > -1
                                      : _vm.userSettingsNotifications
                                          .backupNotification[1],
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.userSettingsNotifications
                                            .backupNotification[1],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              1,
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              1,
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.userSettingsNotifications
                                            .backupNotification,
                                          1,
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.userSettingsNotifications
                                          .backupNotification[2],
                                      expression:
                                        "userSettingsNotifications.backupNotification[2]",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "right",
                                    "data-role": "checkbox",
                                    "data-caption": "Browser",
                                    "data-on": "true",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.userSettingsNotifications
                                        .backupNotification[2]
                                    )
                                      ? _vm._i(
                                          _vm.userSettingsNotifications
                                            .backupNotification[2],
                                          null
                                        ) > -1
                                      : _vm.userSettingsNotifications
                                          .backupNotification[2],
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.userSettingsNotifications
                                            .backupNotification[2],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              2,
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              2,
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.userSettingsNotifications
                                            .backupNotification,
                                          2,
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                      _c(
                        "tr",
                        {
                          staticClass: "content pos-relative",
                          attrs: {
                            "data-role": "collapse",
                            "data-toggle-element": "#id_group_1",
                          },
                        },
                        [
                          _c(
                            "td",
                            { staticClass: "p-0", attrs: { colspan: "4" } },
                            [
                              _c(
                                "table",
                                { staticClass: "table bg-light rounded" },
                                [
                                  _c("tr", [
                                    _c("td", { staticClass: "w-10" }, [
                                      _vm._v("COMPUTER"),
                                    ]),
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-justify-end text-center",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[0]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Email",
                                                  "data-on": true,
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[0]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            0,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            0,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        0,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[1]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Mobile",
                                                  "data-on": "true",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[1]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            1,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            1,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        1,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[2]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Browser",
                                                  "data-on": "true",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[2]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            2,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            2,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        2,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-justify-end text-center",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[0]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Email",
                                                  "data-on": true,
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[0]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            0,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            0,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        0,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[1]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Mobile",
                                                  "data-on": "true",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[1]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            1,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            1,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        1,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[2]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Browser",
                                                  "data-on": "true",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[2]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            2,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            2,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        2,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-justify-end text-center",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[0]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Email",
                                                  "data-on": true,
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[0]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            0,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            0,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        0,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[1]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Mobile",
                                                  "data-on": "true",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[1]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            1,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            1,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        1,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[2]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Browser",
                                                  "data-on": "true",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[2]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            2,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            2,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        2,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _c("tr", [
                                    _c("td", { staticClass: "w-10" }, [
                                      _vm._v("COMPUTER"),
                                    ]),
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-justify-end text-center",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[0]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Email",
                                                  "data-on": true,
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[0]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            0,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            0,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        0,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[1]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Mobile",
                                                  "data-on": "true",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[1]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            1,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            1,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        1,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[2]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Browser",
                                                  "data-on": "true",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[2]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            2,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            2,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        2,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-justify-end text-center",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[0]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Email",
                                                  "data-on": true,
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[0]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            0,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            0,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        0,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[1]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Mobile",
                                                  "data-on": "true",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[1]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            1,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            1,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        1,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[2]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Browser",
                                                  "data-on": "true",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[2]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            2,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            2,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        2,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-justify-end text-center",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[0]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Email",
                                                  "data-on": true,
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[0]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            0,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            0,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        0,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[1]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Mobile",
                                                  "data-on": "true",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[1]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            1,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            1,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        1,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[2]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Browser",
                                                  "data-on": "true",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[2]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            2,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            2,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        2,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _c("tr", [
                                    _c("td", { staticClass: "w-10" }, [
                                      _vm._v("COMPUTER"),
                                    ]),
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-justify-end text-center",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[0]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Email",
                                                  "data-on": true,
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[0]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            0,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            0,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        0,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[1]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Mobile",
                                                  "data-on": "true",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[1]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            1,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            1,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        1,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[2]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Browser",
                                                  "data-on": "true",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[2]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            2,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            2,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        2,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-justify-end text-center",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[0]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Email",
                                                  "data-on": true,
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[0]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            0,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            0,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        0,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[1]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Mobile",
                                                  "data-on": "true",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[1]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            1,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            1,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        1,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[2]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Browser",
                                                  "data-on": "true",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[2]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            2,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            2,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        2,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-justify-end text-center",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[0]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Email",
                                                  "data-on": true,
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[0]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            0,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            0,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        0,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[1]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Mobile",
                                                  "data-on": "true",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[1]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            1,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            1,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        1,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2],
                                                    expression:
                                                      "userSettingsNotifications.backupNotification[2]",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  "data-caption-position":
                                                    "right",
                                                  "data-role": "checkbox",
                                                  "data-caption": "Browser",
                                                  "data-on": "true",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm
                                                      .userSettingsNotifications
                                                      .backupNotification[2]
                                                  )
                                                    ? _vm._i(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                        null
                                                      ) > -1
                                                    : _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            2,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm
                                                              .userSettingsNotifications
                                                              .backupNotification,
                                                            2,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification,
                                                        2,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("tr", [
                        _vm._m(1),
                        _c("td", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-justify-end text-center",
                            },
                            [
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.userSettingsNotifications
                                          .backupNotification[0],
                                      expression:
                                        "userSettingsNotifications.backupNotification[0]",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "right",
                                    "data-role": "checkbox",
                                    "data-caption": "Email",
                                    "data-on": true,
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.userSettingsNotifications
                                        .backupNotification[0]
                                    )
                                      ? _vm._i(
                                          _vm.userSettingsNotifications
                                            .backupNotification[0],
                                          null
                                        ) > -1
                                      : _vm.userSettingsNotifications
                                          .backupNotification[0],
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.userSettingsNotifications
                                            .backupNotification[0],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              0,
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              0,
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.userSettingsNotifications
                                            .backupNotification,
                                          0,
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.userSettingsNotifications
                                          .backupNotification[1],
                                      expression:
                                        "userSettingsNotifications.backupNotification[1]",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "right",
                                    "data-role": "checkbox",
                                    "data-caption": "Mobile",
                                    "data-on": "true",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.userSettingsNotifications
                                        .backupNotification[1]
                                    )
                                      ? _vm._i(
                                          _vm.userSettingsNotifications
                                            .backupNotification[1],
                                          null
                                        ) > -1
                                      : _vm.userSettingsNotifications
                                          .backupNotification[1],
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.userSettingsNotifications
                                            .backupNotification[1],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              1,
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              1,
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.userSettingsNotifications
                                            .backupNotification,
                                          1,
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.userSettingsNotifications
                                          .backupNotification[2],
                                      expression:
                                        "userSettingsNotifications.backupNotification[2]",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "right",
                                    "data-role": "checkbox",
                                    "data-caption": "Browser",
                                    "data-on": "true",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.userSettingsNotifications
                                        .backupNotification[2]
                                    )
                                      ? _vm._i(
                                          _vm.userSettingsNotifications
                                            .backupNotification[2],
                                          null
                                        ) > -1
                                      : _vm.userSettingsNotifications
                                          .backupNotification[2],
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.userSettingsNotifications
                                            .backupNotification[2],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              2,
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              2,
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.userSettingsNotifications
                                            .backupNotification,
                                          2,
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ]),
                        _c("td", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-justify-end text-center",
                            },
                            [
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.userSettingsNotifications
                                          .backupNotification[0],
                                      expression:
                                        "userSettingsNotifications.backupNotification[0]",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "right",
                                    "data-role": "checkbox",
                                    "data-caption": "Email",
                                    "data-on": true,
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.userSettingsNotifications
                                        .backupNotification[0]
                                    )
                                      ? _vm._i(
                                          _vm.userSettingsNotifications
                                            .backupNotification[0],
                                          null
                                        ) > -1
                                      : _vm.userSettingsNotifications
                                          .backupNotification[0],
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.userSettingsNotifications
                                            .backupNotification[0],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              0,
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              0,
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.userSettingsNotifications
                                            .backupNotification,
                                          0,
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.userSettingsNotifications
                                          .backupNotification[1],
                                      expression:
                                        "userSettingsNotifications.backupNotification[1]",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "right",
                                    "data-role": "checkbox",
                                    "data-caption": "Mobile",
                                    "data-on": "true",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.userSettingsNotifications
                                        .backupNotification[1]
                                    )
                                      ? _vm._i(
                                          _vm.userSettingsNotifications
                                            .backupNotification[1],
                                          null
                                        ) > -1
                                      : _vm.userSettingsNotifications
                                          .backupNotification[1],
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.userSettingsNotifications
                                            .backupNotification[1],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              1,
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              1,
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.userSettingsNotifications
                                            .backupNotification,
                                          1,
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.userSettingsNotifications
                                          .backupNotification[2],
                                      expression:
                                        "userSettingsNotifications.backupNotification[2]",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "right",
                                    "data-role": "checkbox",
                                    "data-caption": "Browser",
                                    "data-on": "true",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.userSettingsNotifications
                                        .backupNotification[2]
                                    )
                                      ? _vm._i(
                                          _vm.userSettingsNotifications
                                            .backupNotification[2],
                                          null
                                        ) > -1
                                      : _vm.userSettingsNotifications
                                          .backupNotification[2],
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.userSettingsNotifications
                                            .backupNotification[2],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              2,
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              2,
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.userSettingsNotifications
                                            .backupNotification,
                                          2,
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ]),
                        _c("td", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-justify-end text-center",
                            },
                            [
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.userSettingsNotifications
                                          .backupNotification[0],
                                      expression:
                                        "userSettingsNotifications.backupNotification[0]",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "right",
                                    "data-role": "checkbox",
                                    "data-caption": "Email",
                                    "data-on": true,
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.userSettingsNotifications
                                        .backupNotification[0]
                                    )
                                      ? _vm._i(
                                          _vm.userSettingsNotifications
                                            .backupNotification[0],
                                          null
                                        ) > -1
                                      : _vm.userSettingsNotifications
                                          .backupNotification[0],
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.userSettingsNotifications
                                            .backupNotification[0],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              0,
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              0,
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.userSettingsNotifications
                                            .backupNotification,
                                          0,
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.userSettingsNotifications
                                          .backupNotification[1],
                                      expression:
                                        "userSettingsNotifications.backupNotification[1]",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "right",
                                    "data-role": "checkbox",
                                    "data-caption": "Mobile",
                                    "data-on": "true",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.userSettingsNotifications
                                        .backupNotification[1]
                                    )
                                      ? _vm._i(
                                          _vm.userSettingsNotifications
                                            .backupNotification[1],
                                          null
                                        ) > -1
                                      : _vm.userSettingsNotifications
                                          .backupNotification[1],
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.userSettingsNotifications
                                            .backupNotification[1],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              1,
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              1,
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.userSettingsNotifications
                                            .backupNotification,
                                          1,
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.userSettingsNotifications
                                          .backupNotification[2],
                                      expression:
                                        "userSettingsNotifications.backupNotification[2]",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    "data-caption-position": "right",
                                    "data-role": "checkbox",
                                    "data-caption": "Browser",
                                    "data-on": "true",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.userSettingsNotifications
                                        .backupNotification[2]
                                    )
                                      ? _vm._i(
                                          _vm.userSettingsNotifications
                                            .backupNotification[2],
                                          null
                                        ) > -1
                                      : _vm.userSettingsNotifications
                                          .backupNotification[2],
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.userSettingsNotifications
                                            .backupNotification[2],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              2,
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.userSettingsNotifications
                                                .backupNotification,
                                              2,
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.userSettingsNotifications
                                            .backupNotification,
                                          2,
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                      _c(
                        "tr",
                        {
                          staticClass: "content pos-relative",
                          attrs: {
                            "data-role": "collapse",
                            "data-toggle-element": "#id_group_2",
                          },
                        },
                        [
                          _c(
                            "td",
                            { staticClass: "p-0", attrs: { colspan: "4" } },
                            [
                              _c(
                                "table",
                                { staticClass: "table bg-light rounded" },
                                [
                                  _c("thead"),
                                  _c("tbody", [
                                    _c("tr", [
                                      _c("td", { staticClass: "w-10" }, [
                                        _vm._v("COMPUTER"),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-justify-end text-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[0]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Email",
                                                    "data-on": true,
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[0],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[0],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              0,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              0,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          0,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[1]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Mobile",
                                                    "data-on": "true",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[1],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[1],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              1,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              1,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          1,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[2]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Browser",
                                                    "data-on": "true",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[2],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[2],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              2,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              2,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          2,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-justify-end text-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[0]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Email",
                                                    "data-on": true,
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[0],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[0],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              0,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              0,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          0,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[1]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Mobile",
                                                    "data-on": "true",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[1],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[1],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              1,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              1,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          1,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[2]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Browser",
                                                    "data-on": "true",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[2],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[2],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              2,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              2,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          2,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-justify-end text-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[0]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Email",
                                                    "data-on": true,
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[0],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[0],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              0,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              0,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          0,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[1]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Mobile",
                                                    "data-on": "true",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[1],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[1],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              1,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              1,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          1,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[2]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Browser",
                                                    "data-on": "true",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[2],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[2],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              2,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              2,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          2,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _c("tr", [
                                      _c("td", { staticClass: "w-10" }, [
                                        _vm._v("COMPUTER"),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-justify-end text-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[0]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Email",
                                                    "data-on": true,
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[0],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[0],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              0,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              0,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          0,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[1]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Mobile",
                                                    "data-on": "true",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[1],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[1],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              1,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              1,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          1,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[2]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Browser",
                                                    "data-on": "true",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[2],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[2],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              2,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              2,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          2,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-justify-end text-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[0]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Email",
                                                    "data-on": true,
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[0],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[0],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              0,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              0,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          0,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[1]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Mobile",
                                                    "data-on": "true",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[1],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[1],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              1,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              1,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          1,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[2]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Browser",
                                                    "data-on": "true",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[2],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[2],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              2,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              2,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          2,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-justify-end text-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[0]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Email",
                                                    "data-on": true,
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[0],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[0],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              0,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              0,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          0,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[1]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Mobile",
                                                    "data-on": "true",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[1],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[1],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              1,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              1,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          1,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[2]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Browser",
                                                    "data-on": "true",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[2],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[2],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              2,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              2,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          2,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _c("tr", [
                                      _c("td", { staticClass: "w-10" }, [
                                        _vm._v("COMPUTER"),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-justify-end text-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[0]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Email",
                                                    "data-on": true,
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[0],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[0],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              0,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              0,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          0,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[1]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Mobile",
                                                    "data-on": "true",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[1],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[1],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              1,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              1,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          1,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[2]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Browser",
                                                    "data-on": "true",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[2],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[2],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              2,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              2,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          2,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-justify-end text-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[0]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Email",
                                                    "data-on": true,
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[0],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[0],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              0,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              0,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          0,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[1]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Mobile",
                                                    "data-on": "true",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[1],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[1],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              1,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              1,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          1,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[2]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Browser",
                                                    "data-on": "true",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[2],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[2],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              2,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              2,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          2,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-justify-end text-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[0]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Email",
                                                    "data-on": true,
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[0]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[0],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[0],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[0],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              0,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              0,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          0,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[1]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Mobile",
                                                    "data-on": "true",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[1]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[1],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[1],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[1],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              1,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              1,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          1,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                      expression:
                                                        "userSettingsNotifications.backupNotification[2]",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "data-caption-position":
                                                      "right",
                                                    "data-role": "checkbox",
                                                    "data-caption": "Browser",
                                                    "data-on": "true",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm
                                                        .userSettingsNotifications
                                                        .backupNotification[2]
                                                    )
                                                      ? _vm._i(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[2],
                                                          null
                                                        ) > -1
                                                      : _vm
                                                          .userSettingsNotifications
                                                          .backupNotification[2],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification[2],
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              2,
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm
                                                                .userSettingsNotifications
                                                                .backupNotification,
                                                              2,
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm
                                                            .userSettingsNotifications
                                                            .backupNotification,
                                                          2,
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "row pt-4" }, [
                _c("div", { staticClass: "cell d-flex flex-justify-end" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button primary",
                      attrs: { type: "button" },
                      on: { click: _vm.saveSettings },
                    },
                    [_vm._v(_vm._s(_vm.$t("Save settings")))]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", { staticClass: "w-10", attrs: { id: "id_group_1" } }, [
      _c("label", { staticClass: "flat-button text-bold" }, [
        _vm._v(" Group "),
        _c("span", { staticClass: "mif-arrow-drop-down ml-2" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", { attrs: { id: "id_group_2" } }, [
      _c("label", { staticClass: "flat-button text-bold" }, [
        _vm._v(" Group "),
        _c("span", { staticClass: "mif-arrow-drop-down ml-2" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }