<template>
	<div v-if="friendlyObj">
		<div v-for="(prop) in Object.keys(friendlyObj)" class="row">
			<label>{{ $t(prop) }} :</label> <span class="text-bold pl-2">{{ friendlyObj[prop] }}</span>
		</div>
	</div>
</template>
<script>
import { getFriendlyObject } from "../../../utils/PropertiesLabels";
export default {
	name: "ObjectView",
	props: {
		obj: {
			type: Object,
			//required: true
		},
		withCopyButton: {
			type: Boolean,
			default: false
		},

	},
	data() {
		return {
			friendlyObj: null,
		}
	},
	created() {
		this.friendlyObj=getFriendlyObject(this.obj)[1];
	},
	methods: {

	}
}
</script>